import React, { FunctionComponent } from 'react';
import {
  Card, CardBody, Col, Row,
} from 'reactstrap';
import Section from '../../components/Section';

const Tutorials: FunctionComponent = () => (

  <div className="animated fadeIn mb-3">
    <Row className="mb-5">
      <Col md={6}>
        <Card>
          <CardBody>
            <Section title="Portal Overview">
              <div className="embed-responsive embed-responsive-16by9">
                <iframe
                  title="Portal Overview"
                  src="https://player.vimeo.com/video/439375356"
                  width="640"
                  height="360"
                  frameBorder="0"
                  allow="autoplay; fullscreen"
                  allowFullScreen
                />
              </div>
            </Section>
          </CardBody>
        </Card>

      </Col>
      <Col md={6}>
        <Card>
          <CardBody>
            <Section title="Contacts">
              <div className="embed-responsive embed-responsive-16by9">
                <iframe
                  title="Contacts"
                  src="https://player.vimeo.com/video/439375611"
                  width="640"
                  height="360"
                  frameBorder="0"
                  allow="autoplay; fullscreen"
                  allowFullScreen
                />
              </div>
            </Section>
          </CardBody>
        </Card>
      </Col>
    </Row>
    <Row className="mb-5">
      <Col md={6}>
        <Card>
          <CardBody>
            <Section title="Billing">
              <div className="embed-responsive embed-responsive-16by9">
                <iframe
                  title="Billing"
                  src="https://player.vimeo.com/video/439375785"
                  width="640"
                  height="360"
                  frameBorder="0"
                  allow="autoplay; fullscreen"
                  allowFullScreen
                />
              </div>
            </Section>
          </CardBody>
        </Card>

      </Col>
      <Col md={6}>
        <Card>
          <CardBody>
            <Section title="Services">
              <div className="embed-responsive embed-responsive-16by9">
                <iframe
                  title="Services"
                  src="https://player.vimeo.com/video/439375916"
                  width="640"
                  height="360"
                  frameBorder="0"
                  allow="autoplay; fullscreen"
                  allowFullScreen
                />
              </div>
            </Section>
          </CardBody>
        </Card>

      </Col>
    </Row>
    <Row className="mb-5">
      <Col md={6}>
        <Card>
          <CardBody>
            <Section title="Domains">
              <div className="embed-responsive embed-responsive-16by9">
                <iframe
                  title="Domains"
                  src="https://player.vimeo.com/video/439375132"
                  width="640"
                  height="360"
                  frameBorder="0"
                  allow="autoplay; fullscreen"
                  allowFullScreen
                />
              </div>
            </Section>
          </CardBody>
        </Card>

      </Col>
      <Col md={6}>
        <Card>
          <CardBody>
            <Section title="Orders">
              <div className="embed-responsive embed-responsive-16by9">
                <iframe
                  title="Orders"
                  src="https://player.vimeo.com/video/439375232"
                  width="640"
                  height="360"
                  frameBorder="0"
                  allow="autoplay; fullscreen"
                  allowFullScreen
                />
              </div>
            </Section>
          </CardBody>
        </Card>
      </Col>
    </Row>
    <Row className="mb-5">
      <Col md={6}>
        <Card>
          <CardBody>
            <Section title="Cases">
              <div className="embed-responsive embed-responsive-16by9">
                <iframe
                  title="Cases"
                  src="https://player.vimeo.com/video/439418902"
                  width="640"
                  height="360"
                  frameBorder="0"
                  allow="autoplay; fullscreen"
                  allowFullScreen
                />
              </div>
            </Section>
          </CardBody>
        </Card>

      </Col>
      <Col md={6}>
        <Card>
          <CardBody>
            <Section title="Monitoring">
              <div className="embed-responsive embed-responsive-16by9">
                <iframe
                  title="Monitoring"
                  src="https://player.vimeo.com/video/439397184"
                  width="640"
                  height="360"
                  frameBorder="0"
                  allow="autoplay; fullscreen"
                  allowFullScreen
                />
              </div>
            </Section>
          </CardBody>
        </Card>

      </Col>
    </Row>
  </div>
);

export default Tutorials;
