import {AxiosResponse} from 'axios';
import { apiRequest } from '../Api/ApiRequest';
import {IUserState} from "../../store/user/types";

export function getIdToken(): string|null {

  return localStorage.getItem('hso-api-token');

}

export function clearToken() {

  localStorage.removeItem('hso-api-token');

}

export function isLoggedIn(): boolean {

  const idToken = getIdToken();
  return !!idToken;

}

export function api_getAuthStatus(token: string): Promise<AxiosResponse<IUserState>> {

  return apiRequest({
    url: '/auth?include=contact,favourites,settings,weatherMaps',
    method: 'get',
    headers: { Authorization: `Bearer ${token}` },
  }).then(
    resp => resp,
  ).catch(
    error => error,
  );

}

export function api_doLogout(): Promise<AxiosResponse> {

  return apiRequest({
    url: '/auth',
    method: 'delete',
    headers: { Authorization: `Bearer ${localStorage.getItem('hso-api-token')}` },
  }).then(
    resp => resp,
  ).catch(
    error => error,
  );

}

export function checkPermission(needle: string, haystack: string[]): boolean {

  return haystack.includes(needle);

}

export function resetPassword(currentPassword: string, newPassword: string): Promise<AxiosResponse> {

  return apiRequest({
    url: '/auth/reset-password',
    method: 'post',
    headers: { Authorization: `Bearer ${localStorage.getItem('hso-api-token')}` },
    data: { currentPassword, newPassword },
  }).then(
    resp => resp,
  ).catch(
    error => error,
  );

}

export function api_resetPasswordWithToken(token: string, newPassword: string): Promise<AxiosResponse> {

  return apiRequest({
    url: '/auth/reset-password',
    method: 'post',
    headers: { Authorization: `Bearer ${localStorage.getItem('hso-api-token')}` },
    data: { newPassword, token },
  }).then(
    resp => resp,
  ).catch(
    error => error,
  );

}

export function api_forgotPassword(username: string): Promise<AxiosResponse> {

  return apiRequest({
    url: '/auth/forgot-password',
    method: 'post',
    data: { username },
  }).then(
    resp => resp,
  ).catch(
    error => error,
  );

}

export function api_forgotUsername(email: string): Promise<AxiosResponse> {

  return apiRequest({
    url: '/auth/forgot-username',
    method: 'post',
    data: { email },
  }).then(
    resp => resp,
  ).catch(
    error => error,
  );

}
