import React from 'react';
import numeral from 'numeral';
import moment from 'moment';
import Section from '../../Section';
import SimpleTable from '../../Table/SimpleTable';
import ArrowLink from '../../Buttons/ArrowLink';
import { TBill } from '../../../store/billing/types';
import SimpleLoader from '../../SimpleLoader';
import NoData from '../NoData';

type Props = {
  bills: Array<TBill>;
  loading: boolean;
};
const BillingTable: React.FC<Props> = ({ bills, loading }) => {

  const rows = bills.map((bill) => (
    <tr key={`Account Bill${bill.id}`} className="text-light pl-1 pr-2 b-t-1">
      <td className="pl-4">
        #
        {bill.id}
      </td>
      <td className="pr-4 b-r-1 b-l-1">{moment(bill.date).format('YYYY-MM-DD')}</td>
      <td className="pr-4 ">
        £
        {numeral(bill.totalValue).format('0,0.00')}
      </td>
    </tr>
  ));
  const header = (
    <tr className="text-light font-weight-bold h5">
      <th>Invoice</th>
      <th className="b-r-1 b-l-1">Date</th>
      <th>Total</th>
    </tr>
  );
  return (
    <div>
      <Section
        title="Latest Bills"
        children={(
          <SimpleLoader loading={loading}>
            {bills.length < 1 && (<NoData text="No bills at present" />)}
            {bills.length > 0 && (
            <SimpleTable
              padded
              rows={rows}
              withHeader
              header={header}
              bordered={false}
              link={(
                <ArrowLink
                  url="/billing"
                  text="View all bills"
                />
)}
            />
            )}
          </SimpleLoader>
              )}
      />
    </div>
  );

};
export default BillingTable;
