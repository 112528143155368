import {
  ISystemState,
  SystemActionTypes,
  SET_SYSTEM_ERROR,
  RESET_SYSTEM_ERROR,
  SYSTEM_SET_SUCCESS_MESSAGE,
  SYSTEM_RESET_SUCCESS_MESSAGE,
  SYSTEM_ERROR_EMAIL_SENT,
  SYSTEM_TOGGLE_STATUS_LOADING,
  SYSTEM_SET_CONFIRMATION,
  SYSTEM_RESET_CONFIRMATION,
  SYSTEM_SET_FORBIDDEN_ERROR,
  SYSTEM_SET_PARTNER_CUSTOMER,
  SYSTEM_RESET_PARTNER_CUSTOMER,
  SYSTEM_TOGGLE_TUTORIAL_MODAL,
  SYSTEM_RESET_FORBIDDEN_ERROR,
  SYSTEM_SET_CONNECTION_ERROR,
  SYSTEM_RESET_CONNECTION_ERROR,
} from './types';

const initialState: ISystemState = {
  status: 'OK',
  loading: false,
  errorEmailSent: false,
  hideOverviewModal: true,
  success: {
    isSet: false,
    message: '',
  },
  errorId: '',
  forbiddenError: false,
  connectionError: false,
  confirmation: {
    text: 'Are you sure?',
    isOpen: false,
    color: 'danger',
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    cancel: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    proceed: () => {},
  },
  activePartnerCustomer: {
    name: '',
    accountNumber: '',
    accountId: '',
  },
};
export default function system(state = initialState, action: SystemActionTypes): ISystemState {

  switch (action.type) {
    case SYSTEM_SET_FORBIDDEN_ERROR:
      return {
        ...state,
        forbiddenError: true,
      };
    case SYSTEM_RESET_FORBIDDEN_ERROR:
      return {
        ...state,
        forbiddenError: false,
      };
    case SYSTEM_SET_CONNECTION_ERROR:
      return {
        ...state,
        connectionError: true,
      };
    case SYSTEM_RESET_CONNECTION_ERROR:
      return {
        ...state,
        connectionError: false,
      };
    case SYSTEM_TOGGLE_STATUS_LOADING:
      return {
        ...state,
        loading: !state.loading,
      };
    case SYSTEM_ERROR_EMAIL_SENT: {

      return {
        ...state,
        errorEmailSent: true,
      };
    
    }
    case SYSTEM_SET_SUCCESS_MESSAGE:
      return {
        ...state,
        success: {
          isSet: true,
          message: action.message,
        },
      };
    case SYSTEM_RESET_SUCCESS_MESSAGE:
      return {
        ...state,
        success: {
          isSet: false,
          message: '',
        },
      };
    case SET_SYSTEM_ERROR:
      return {
        ...state,
        errorId: action.errorId,
      };
    case RESET_SYSTEM_ERROR: {

      return {
        ...state,
        errorId: initialState.errorId,
      };
    
    }
    case SYSTEM_SET_CONFIRMATION: {

      return {
        ...state,
        confirmation: action.confirmation,
      };
    
    }
    case SYSTEM_RESET_CONFIRMATION: {

      return {
        ...state,
        confirmation: { ...initialState.confirmation },
      };
    
    }
    case SYSTEM_SET_PARTNER_CUSTOMER: {

      return {
        ...state,
        activePartnerCustomer: action.customer,
      };
    
    }
    case SYSTEM_RESET_PARTNER_CUSTOMER: {

      return {
        ...state,
        activePartnerCustomer: initialState.activePartnerCustomer,
      };
    
    }
    case SYSTEM_TOGGLE_TUTORIAL_MODAL: {

      return {
        ...state,
        hideOverviewModal: !state.hideOverviewModal,
      };
    
    }
    default:
      return {
        ...state,
      };
  }

}
