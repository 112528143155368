import {
  Button, Modal, ModalBody, ModalFooter,
} from 'reactstrap';
import React from 'react';
import Section from '../../Section';
import SelectInput from '../../Inputs/SelectInput';

type TOwnProps = {
  text: string;
  isOpen: boolean;
  cancel: () => any;
  proceed: (roleId: number) => any;

};
const PortalInviteModal: React.FC<TOwnProps> = (props) => {

  const {
    isOpen, cancel, proceed, text,
  } = props;
  const [selectValue, updateValue] = React.useState<any>();
  const [error, updateError] = React.useState<boolean>(false);
  const updateSelect = (event) => {

    if (event.target.value === undefined || event.target.value === '1') {

      updateError(true);
    
    } else {

      updateError(false);
    
    }
    updateValue(event.target.value);
  
  };
  const handleCancel = () => {

    updateValue(1);
    cancel();
  
  };
  const handleProceed = () => {

    if (selectValue !== 1 && selectValue !== undefined && !error) {

      proceed(selectValue);
    
    } else {

      updateError(true);
    
    }
  
  };
  return (
    <Modal isOpen={isOpen} toggle={handleCancel} backdrop="static">
      <ModalBody>
        <Section title="Are you sure?">
          <SelectInput
            id="Portal User Type"
            value={selectValue}
            onChange={updateSelect}
            label="Portal User Role"
            options={[
              { label: 'Select user role', value: 1 },
              { label: 'Administrator', value: 864640000 },
              { label: 'Technical User', value: 864640001 },
              { label: 'Read Only', value: 864640002 },
              { label: 'Support User', value: 864640003 },
            ]}
          />
          {error && <p className="text-danger">Please select a user role</p>}
          <p className="text-light">{text}</p>
        </Section>
      </ModalBody>
      <ModalFooter className="border-top-0">
        <Button color="primary" className="text-white" onClick={handleProceed}>Proceed</Button>
        {' '}
        <Button color="warning" onClick={handleCancel}>Cancel</Button>
      </ModalFooter>
    </Modal>
  );

};
export default PortalInviteModal;
