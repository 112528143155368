import { TCase } from '../case/types';

export const CASES_SET_CASES = 'CASES_SET_CASES';
export const CASES_SET_SEARCH_STRING = 'CASES_SET_SEARCH_STRING';
export const CASES_TOGGLE_LOADING = 'CASES_TOGGLE_LOADING';
export const CASES_TOGGLE_NEW_CASE_DROPDOWN = 'CASES_TOGGLE_NEW_CASE_DROPDOWN';
export const CASES_RESET_STORE = 'CASES_RESET_STORE';
export const CASES_SET_FILTER_STRING = 'CASES_SET_FILTER_STRING';
export const CASES_TOGGLE_FILTER_DROPDOWN = 'CASES_TOGGLE_FILTER_DROPDOWN';
export const CASES_ADD_CASE_TO_CASES = 'CASES_ADD_CASE_TO_CASES';
export const CASES_RESOLVE_CASE = 'CASES_RESOLVE_CASE';
export const CASES_ESCALATE_CASE = 'CASES_ESCALATE_CASE';

export interface IAddCaseToCasesAction {
  type: typeof CASES_ADD_CASE_TO_CASES;
  theCase: TCase;

}
export interface ITogglCasesFilterDropdownAction {
  type: typeof CASES_TOGGLE_FILTER_DROPDOWN;
}

export interface ICasesSetFilter {
  type: typeof CASES_SET_FILTER_STRING;
  payload: string;

}

export interface ICasesState {
  loading: boolean;
  openNewCaseDropdown: boolean;
  searchString: string;
  cases: TCase[];
  openFilterCasesDropdown: boolean;
  visibleState: string;

}

export interface ICasesResetStore {
  type: typeof CASES_RESET_STORE;
}

interface ISetAccountCasesAction {
  type: typeof CASES_SET_CASES;
  cases: TCase[];
}

interface ISetAccountCasesSearchAction {
  type: typeof CASES_SET_SEARCH_STRING;
  search: string;
}

interface IToggleAccountCasesLoadingAction {
  type: typeof CASES_TOGGLE_LOADING;
}

interface IToggleNewCasesDropdownAction {
  type: typeof CASES_TOGGLE_NEW_CASE_DROPDOWN;
}

interface IResolveCaseAction {
  type: typeof CASES_RESOLVE_CASE;
  id: string;
}

interface IEscalateCaseAction {
  type: typeof CASES_ESCALATE_CASE;
  id: string;
}
export type CasesActionTypes =
    IAddCaseToCasesAction |
    ISetAccountCasesAction |
    ISetAccountCasesSearchAction |
    IToggleAccountCasesLoadingAction |
    IToggleNewCasesDropdownAction |
    ICasesResetStore |
    ICasesSetFilter |
    ITogglCasesFilterDropdownAction |
    IEscalateCaseAction |
    IResolveCaseAction;
