import { apiRequest } from '../Api/ApiRequest';

export function api_getBroadbandService(id) {

  return apiRequest({
    url: `/services/broadband/${id}`,
    method: 'get',
    headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
  }).then(
    resp => resp,
  ).catch(
    error => error,
  );

}

export function api_getBroadbandServiceUsage(id, month, year) {

  return apiRequest({
    url: `/services/broadband/${id}/usage?month=${month}&year=${year}`,
    method: 'get',
    headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
  }).then(
    resp => resp,
  ).catch(
    error => error,
  );

}
