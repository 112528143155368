import { CONTACT_UPDATE_CONTACT } from '../contact/types';

export const SERVICE_DNS_SET_ZONES = 'SERVICE_DNS_SET_ZONES';
export const SERVICE_DNS_ADD_ZONE = 'SERVICE_DNS_ADD_ZONE';
export const SERVICE_DNS_SET_SEARCH_STRING = 'SERVICE_DNS_SET_SEARCH_STRING';
export const SERVICE_DNS_TOGGLE_LOADING = 'SERVICE_DNS_TOGGLE_LOADING';
export const SERVICE_DNS_TOGGLE_NEW_ZONE_MODAL = 'SERVICE_DNS_TOGGLE_NEW_ZONE_MODAL';
export const SERVICE_DNS_UPDATE_ZONE_DATA = 'SERVICE_DNS_UPDATE_ZONE_DATA';
export const SERVICE_DNS_RESET_NEW_ZONE = 'SERVICE_DNS_RESET_NEW_ZONE';

export interface IServiceDNSState {
  loading: boolean;
  searchString: string;
  showNewZoneModal: boolean;
  zones: TDNSZone[];
  zone: TDNSZone;
  filtered: TDNSZone[];
}
export type TDNSZone = {
  id: number;
  name: string;
  type: string;
  notifiedSerial: number;
  master: string;
};

export interface ISetDNSZonesAction {
  type: typeof SERVICE_DNS_SET_ZONES;
  zones: TDNSZone[];
}

export interface IAddDNSZoneAction {
  type: typeof SERVICE_DNS_ADD_ZONE;
  zone: TDNSZone;
}

export interface IToggleDNSZonesLoadingAction {
  type: typeof SERVICE_DNS_TOGGLE_LOADING;
}

export interface IToggleDNSNewZoneModalAction {
  type: typeof SERVICE_DNS_TOGGLE_NEW_ZONE_MODAL;
}

export interface ISetDNSZoneSearchAction {
  type: typeof SERVICE_DNS_SET_SEARCH_STRING;
  search: string;
}

export interface IUpdateDNSZoneDataAction {
  type: typeof SERVICE_DNS_UPDATE_ZONE_DATA;
  data: object;
}

export interface IResetNewZoneAction {
  type: typeof SERVICE_DNS_RESET_NEW_ZONE;
}

export type ServiceDNSActionTypes = ISetDNSZonesAction | ISetDNSZoneSearchAction | IToggleDNSZonesLoadingAction |
IToggleDNSNewZoneModalAction | IUpdateDNSZoneDataAction | IAddDNSZoneAction | IResetNewZoneAction;
