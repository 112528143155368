/* eslint-disable */
import React, { Component } from 'react';
import {Editor, EditorState, RichUtils, convertToRaw} from 'draft-js';
import draftToHtml from 'draftjs-to-html';


export default class TextEditor extends Component {
  constructor(props) {

    super(props);
    this.state = {editorState: EditorState.createEmpty()};
    this.focus = () => this.refs.editor.focus();
    this.onChange = (editorState) => {

      this.setState({editorState});
      const rawContentState = convertToRaw(editorState.getCurrentContent());
      const markup = draftToHtml(
        rawContentState
      );
      this.props.callback(markup);
        
    };
    this.handleKeyCommand = (command) => this._handleKeyCommand(command);
    this.onTab = (e) => this._onTab(e);
    this.toggleBlockType = (type) => this._toggleBlockType(type);
    this.toggleInlineStyle = (style) => this._toggleInlineStyle(style);
    
  }

  _handleKeyCommand(command) {

    const {editorState} = this.state;
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {

      this.onChange(newState);
      return true;
        
    }
    return false;
    
  }

  _onTab(e) {

    const maxDepth = 4;
    this.onChange(RichUtils.onTab(e, this.state.editorState, maxDepth));
    
  }

  _toggleBlockType(blockType) {

    this.onChange(
      RichUtils.toggleBlockType(
        this.state.editorState,
        blockType
      )
    );
    
  }

  _toggleInlineStyle(inlineStyle) {

    this.onChange(
      RichUtils.toggleInlineStyle(
        this.state.editorState,
        inlineStyle
      )
    );
    
  }

  render() {

    const {editorState} = this.state;
    // If the user changes block type before entering any text, we can
    // either style the placeholder or hide it. Let's just hide it now.
    let className = 'RichEditor-editor';
    const contentState = editorState.getCurrentContent();
    if (!contentState.hasText()) {

      if (contentState.getBlockMap().first().getType() !== 'unstyled') {

        className += ' RichEditor-hidePlaceholder';
            
      }
        
    }
    return (
            <div className={this.props.color ? `RichEditor-root ${this.props.color}` : 'RichEditor-root'}>
                <div className="clearfix">
                    <InlineStyleControls
                        editorState={editorState}
                        onToggle={this.toggleInlineStyle}
                    />
                    <BlockStyleControls
                        editorState={editorState}
                        onToggle={this.toggleBlockType}
                    />
                </div>
                <div className={className} onClick={this.focus}>
                    <Editor
                        blockStyleFn={getBlockStyle}
                        customStyleMap={styleMap}
                        editorState={editorState}
                        handleKeyCommand={this.handleKeyCommand}
                        onChange={this.onChange}
                        onTab={this.onTab}
                        ref="editor"
                        spellCheck
                    />
                </div>
            </div>
    );
    
  }
}
// Custom overrides for "code" style.
const styleMap = {
  CODE: {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2,
  },
};
function getBlockStyle(block) {

  switch (block.getType()) {
    case 'blockquote': return 'RichEditor-blockquote';
    default: return null;
  }

}
class StyleButton extends React.Component {
  constructor() {

    super();
    this.onToggle = (e) => {

      e.preventDefault();
      this.props.onToggle(this.props.style);
        
    };
    
  }

  render() {

    let className = 'RichEditor-styleButton';
    if (this.props.active) {

      className += ' RichEditor-activeButton';
        
    }
    return (
            <span className={className} onMouseDown={this.onToggle} dangerouslySetInnerHTML={{__html:this.props.label}} />
    );
    
  }
}
const BLOCK_TYPES = [
  {label: '<i class="fa fa-header fa-fw"></i>', style: 'header-one'},
  {label: '<i class="fa fa-list-ul fa-fw"></i>', style: 'unordered-list-item'},
  {label: '<i class="fa fa-list-ol fa-fw"></i>', style: 'ordered-list-item'},
  {label: '<i class="fa fa-code fa-fw"></i>', style: 'code-block'},
  {label: '<i class="fa fa-quote-left fa-fw"></i>', style: 'blockquote'},
];
const BlockStyleControls = (props) => {

  const {editorState} = props;
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();
  return (
        <div className="RichEditor-controls">
            {BLOCK_TYPES.map((type) =>
                <StyleButton
                    key={type.label}
                    active={type.style === blockType}
                    label={type.label}
                    onToggle={props.onToggle}
                    style={type.style}
                />)}
        </div>
  );

};
const INLINE_STYLES = [
  {label: '<i class="fa fa-bold fa-fw"></i>', style: 'BOLD'},
  {label: '<i class="fa fa-italic fa-fw"></i>', style: 'ITALIC'},
  {label: '<i class="fa fa-underline fa-fw"></i>', style: 'UNDERLINE'},
  {label: '<i class="fa fa-text-width fa-fw"></i>', style: 'CODE'},
];
const InlineStyleControls = (props) => {

  const currentStyle = props.editorState.getCurrentInlineStyle();
  return (
        <div className="RichEditor-controls">
            {INLINE_STYLES.map(type =>
                <StyleButton
                    key={type.label}
                    active={currentStyle.has(type.style)}
                    label={type.label}
                    onToggle={props.onToggle}
                    style={type.style}
                />)}
        </div>
  );

};