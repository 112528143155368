import { AxiosResponse } from 'axios';
import fileDownload from 'js-file-download';
import {
  DOCUMENTS_SET_DOCUMENTS,
  DOCUMENTS_SET_SEARCH_STRING,
  IDocumentsSetDocuments,
  IDocumentsSetSearchString,
} from './types';
import { api_downloadDocument, api_getDocuments } from '../../utils/Documents';
import { defaultErrorFeedback } from '../../actions/feedback';

const setDocuments = (data): IDocumentsSetDocuments => ({
  type: DOCUMENTS_SET_DOCUMENTS,
  data,
});

export const getDocuments = () => (dispatch) => api_getDocuments().then((result) => {

  if (result.status === 200) {

    dispatch(setDocuments(result.data));
  
  } else {

    dispatch(defaultErrorFeedback());
  
  }

});

export const setDocumentsSearchString = (searchString): IDocumentsSetSearchString => ({
  type: DOCUMENTS_SET_SEARCH_STRING,
  searchString,
});

export const downloadDocument = (documentId) => (dispatch) => api_downloadDocument(documentId).then((result: AxiosResponse) => {

  if (result.status === 200) {

    const splitContentDisposition = result.headers['content-disposition'].replaceAll('"', '').split('=');
    fileDownload(result.data, splitContentDisposition[1]);
    return true;
  
  }

  dispatch(defaultErrorFeedback());
  return false;

});
