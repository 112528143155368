import React from 'react';

interface IProps {
  cssClass?: string;
  cssClassTop?: string;
  title?: string;
}
const CloudDown: React.FC<IProps> = ({ cssClass, cssClassTop, title }) => (
  <svg
    className={cssClassTop || 'cls-2'}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 25.49 23.89"
  >
    <title>{title || ''}</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Design">
        <path
          className={cssClass || 'cls-2'}
          d="M20.88,6.71a4.4,4.4,0,0,1,3.27,1.37,4.55,4.55,0,0,1,1.34,3.3A4.66,4.66,0,0,1,20.82,16H15.08v-.91h5.74A3.61,3.61,0,0,0,23.5,14a3.66,3.66,0,0,0,1.08-2.64A3.71,3.71,0,0,0,23.47,8.7a3.59,3.59,0,0,0-2.65-1.13H20V6.43A5.21,5.21,0,0,0,18.29,2.5,5.69,5.69,0,0,0,14.22.91a5.57,5.57,0,0,0-3,.85A5.63,5.63,0,0,0,9.1,4.1l-.34.79L8,4.49a2.48,2.48,0,0,0-1.13-.28,2.34,2.34,0,0,0-1.62.65,2.81,2.81,0,0,0-.89,1.57l-.05.51-.52.17A4.32,4.32,0,0,0,1.68,8.59a3.79,3.79,0,0,0-.77,2.33,4.1,4.1,0,0,0,1.22,3,4,4,0,0,0,3,1.25H10.3V16H5.12a5,5,0,0,1-3.61-1.48A4.81,4.81,0,0,1,0,11,4.69,4.69,0,0,1,1,8.05a5.08,5.08,0,0,1,2.5-1.79,3.6,3.6,0,0,1,1.17-2,3.13,3.13,0,0,1,2.19-.85,3.25,3.25,0,0,1,1.48.34A6.12,6.12,0,0,1,10.72,1a6.63,6.63,0,0,1,3.56-1A6.5,6.5,0,0,1,19,1.91a6.21,6.21,0,0,1,2,4.63V6.6ZM9.16,19.06l3.13,3.07V8.36h.91V22.13l3.13-3.07.68.68-4.27,4.15L8.53,19.74Z"
        />
      </g>
    </g>
  </svg>
);

export default CloudDown;
