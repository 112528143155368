import { AxiosResponse } from 'axios';
import {
  IResetVeeamBackupDataAction,
  ISetVeeamBackupDataAction,
  SERVICE_VEEAM_BACKUP_RESET_DATA,
  SERVICE_VEEAM_BACKUP_SET_DATA, TServiceVeeamBackup,
} from './types';
import { api_getVeeamBackupService } from '../../utils/VeeamBackup/VeeamBackup';
import { setSystemError } from '../system/actions';
import { defaultErrorFeedback } from '../../actions/feedback';

export function resetServiceVeeamBackupData(): IResetVeeamBackupDataAction {

  return {
    type: SERVICE_VEEAM_BACKUP_RESET_DATA,
  };

}

function setVeeamBackupData(data: TServiceVeeamBackup): ISetVeeamBackupDataAction {

  return {
    type: SERVICE_VEEAM_BACKUP_SET_DATA,
    data,
  };

}

export function getVeeamBackupService(id: string, include: Array<string> = []): (dispatch) => Promise<boolean | TServiceVeeamBackup> {

  return (dispatch): Promise<boolean | TServiceVeeamBackup> => api_getVeeamBackupService(id, include).then((result: AxiosResponse): boolean | TServiceVeeamBackup => {

    if (result.status === 200) {

      dispatch(setVeeamBackupData(result.data));
      return result.data;
    
    }
    if (result?.data?.error?.id) {

      dispatch(setSystemError(result.data.error.id));
    
    } else {

      dispatch(defaultErrorFeedback());
    
    }
    return false;
  
  });

}
