import React, { FunctionComponent, PropsWithChildren } from 'react';
import Loadable from 'react-loading-overlay';

type SimpleLoader = {
  loading: boolean;
  spinner?: boolean;
  color?: string;
  background?: string;
  text?: string;
  styles?: TStyles;
};
type TStyles = {
  spinner?: (base: any) => any;
  overlay?: (base: any) => any;
  content?: (base: any) => any;
  wrapper?: (base: any) => any;
};

Loadable.propTypes = undefined;
const SimpleLoader: FunctionComponent<SimpleLoader & PropsWithChildren> = (props) => {

  const {
    loading, spinner, color, background, children, text,
  } = props;
  return (
    <Loadable
      active={loading}
      spinner={spinner || true}
      color={color || 'rgb(84,135,168)'}
      background={background || 'rgba(255,255,255, 0.8)'}
      text={text || ''}
    >
      {children}
    </Loadable>
  );

};
export default SimpleLoader;
