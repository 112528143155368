import React, { FunctionComponent } from 'react';
import { Col, Row } from 'reactstrap';
import Graph from '../../../SvgIcons/Graph';

type TOwnProps = {
  title: string;
  value: number;
  measurementUnit? : string;
  clickHandler?: (event: React.MouseEvent<HTMLDivElement>) => void;
};

const TextWidget: FunctionComponent<TOwnProps> = (props) => {

  const {
    title, measurementUnit, value, clickHandler,
  } = props;
  return (
    <div className="shadow-sm px-2 pt-3 bg-white w-100 h-100" style={{ borderRadius: '10px' }}>
      <Row className="d-flex h-100">
        <Col className="d-flex flex-column justify-content-between align-items-center">
          <h5 className="text-light text-uppercase font-xl text-center mb-0">
            {title}
          </h5>
          <div className=" monitoring-widget__latency pt-0 d-flex justify-content-center align-items-center">
            <div
              className="font-weight-bold text-dark monitoring-widget__latency__number pt-0 text-left"
            >
              {value}
              {measurementUnit
                                && <span className="text-light monitoring-widget__latency__ms">{measurementUnit}</span>}

            </div>
          </div>

          {clickHandler
            ? (
              <div className="d-flex justify-content-center" onClick={clickHandler}>
                <Graph />
              </div>
            )
            : <div className="d-flex justify-content-center" />}

        </Col>
      </Row>
    </div>
  );

};

export default TextWidget;
