import React, { useEffect, useState } from 'react';
import {
  Button, Col, FormGroup, Row,
} from 'reactstrap';
import isEmpty from 'validator/lib/isEmpty';
import toastr from 'toastr';
import SimpleLoader from '../../../components/SimpleLoader';
import { TDNSSec } from '../../../store/domain/types';
import SelectInput, { ISelectInputOptions } from '../../../components/Inputs/SelectInput';
import TextInput from '../../../components/Inputs/TextInput';
import SaveIconButton from '../../../components/Buttons/SaveIconButton';
import DeleteIconButton from '../../../components/Buttons/DeleteIconButton';
import { IFormError } from '../../../components/Forms/types';
import { checkPermission } from '../../../utils/Auth/AuthService';
import { IUserState } from '../../../store/user/types';

type TOwnProps = {
  data: Array<TDNSSec>;
  newDNSSecData: TDNSSec;
  getDNSSecData(): Promise<boolean>;
  handleFormInput(id: string, value: string): () => any;
  handleFormSubmit(alg: number, keyTag: number, digestType: number, digest: string): Promise<boolean>;
  handleFormReset(): Promise<boolean>;
  handleDelete(dnsSec: TDNSSec): Promise<boolean>;
  user: IUserState;
};
type TError = {
  algorithm?: IFormError;
  keyTag?: IFormError;
  digestType?: IFormError;
  digest?: IFormError;
};

const DomainDNSSEC: React.FC<TOwnProps> = ({
  data,
  newDNSSecData,
  getDNSSecData,
  handleFormReset,
  handleFormInput,
  handleFormSubmit,
  handleDelete,
  user,
}) => {

  const [newDNSSecForm, setNewDNSSecForm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState<TError>({});

  const getData = () => {

    setLoading(true);
    getDNSSecData().then(() => {

      setLoading(false);
    
    });
  
  };

  useEffect(() => {

    getData();
  
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    setErrors({});
    let { value } = event.target;
    if (event.target.id === 'keyTag' && value !== '') {

      if (value > '65535') {

        value = '65535';
      
      } else if (value < '0') {

        value = '0';
      
      }
    
    }
    handleFormInput(event.target.id, value);
  
  };

  const loadNew = () => {

    setNewDNSSecForm(true);
  
  };

  const validated = () => {

    const errorsArr = {};
    Object.keys(newDNSSecData).forEach((key) => {

      if (isEmpty(newDNSSecData[key])) {

        errorsArr[key] = {
          id: key,
          message: 'The field is required.',
        };
      
      }
      if (key === 'digest' && !/[0-9A-Fa-f]{6}/g.test(newDNSSecData[key])) {

        errorsArr[key] = {
          id: key,
          message: 'Field must be hexadecimal.',
        };
      
      }
    
    });
    setErrors(errorsArr);
    return Object.entries(errorsArr).length === 0;
  
  };

  const saveNewDNSSec = () => {

    if (!checkPermission('domains.write', user.permissions)) {

      toastr.error('Unfortunately you are not authorized to do this!');
      return;
    
    }
    if (validated()) {

      setLoading(true);
      handleFormSubmit(newDNSSecData.algorithm, newDNSSecData.keyTag, newDNSSecData.digestType, newDNSSecData.digest)
        .then(() => {

          handleFormReset();
          setNewDNSSecForm(false);
          setLoading(false);
        
        });
    
    }
  
  };

  const deleteDNSSec = (index) => {

    if (!checkPermission('domains.write', user.permissions)) {

      toastr.error('Unfortunately you are not authorized to do this!');
      return;
    
    }
    setLoading(true);
    handleDelete(data[index])
      .then(() => {

        setLoading(false);
      
      });
  
  };

  const algorithmOptions: Array<ISelectInputOptions> = [
    { label: '', value: '' },
    { label: '3 (DSA)', value: 3 },
    { label: '5 (RSASHA1)', value: 5 },
    { label: '6 (DSA-NSEC3-SHA1)', value: 6 },
    { label: '7 (RSASHA1-NSEC3-SHA1)', value: 7 },
    { label: '8 (RSASHA256)', value: 8 },
    { label: '10 (RSASHA512)', value: 10 },
    { label: '12 (ECC-GOST)', value: 12 },
    { label: '13 (ECDSAP256SHA256)', value: 13 },
    { label: '14 (ECDSAP384SHA384)', value: 14 },
  ];

  const digestTypeOptions: Array<ISelectInputOptions> = [
    { label: '', value: '' },
    { label: '1 (SHA-1)', value: 1 },
    { label: '2 (SHA-256)', value: 2 },
  ];

  return (
    <Row>
      <Col>
        <SimpleLoader
          loading={loading}
          text="Loading DNSSEC data..."
          styles={{ wrapper: (base) => ({ ...base, minHeight: '100px' }) }}
        >
          {!loading ? (
            <>
              {data.map((dnssec, index) => (
                <Row key={index}>
                  <Col>
                    <Row>
                      <Col sm={4} lg={2}>
                        <TextInput
                          label="Algorithm"
                          id="algorithm"
                          value={dnssec.algorithm}
                          readOnly
                          horizontal={false}
                        />
                      </Col>
                      <Col sm={4} lg={2}>
                        <TextInput
                          label="Key Tag"
                          type="number"
                          id="keyTag"
                          min={0}
                          max={65535}
                          value={dnssec.keyTag}
                          readOnly
                          horizontal={false}
                        />
                      </Col>
                      <Col sm={4} lg={2}>
                        <TextInput
                          label="Digest Type"
                          id="digestType"
                          value={dnssec.digestType}
                          readOnly
                          horizontal={false}
                        />
                      </Col>
                      <Col>
                        <TextInput label="Digest" id="digest" value={dnssec.digest} readOnly horizontal={false} />
                      </Col>
                    </Row>
                  </Col>
                  {checkPermission('domains.write', user.permissions) ? (
                    <Col sm={1}>
                      <FormGroup>
                        <DeleteIconButton onClick={() => deleteDNSSec(index)} />
                      </FormGroup>
                    </Col>
                  ) : ''}
                  {index !== data.length - 1 || newDNSSecForm ? (
                    <Col xs={12}>
                      <hr />
                    </Col>
                  ) : ''}
                </Row>
              ))}
              {checkPermission('domains.write', user.permissions) ? (
                data.length === 0 || newDNSSecForm ? (
                  <Row>
                    <Col>
                      <Row>
                        <Col sm={4} lg={2}>
                          <SelectInput
                            label="Algorithm"
                            id="algorithm"
                            options={algorithmOptions}
                            value={newDNSSecData.algorithm}
                            onChange={handleChange}
                            horizontal={false}
                            error={errors.algorithm}
                          />
                        </Col>
                        <Col sm={4} lg={2}>
                          <TextInput
                            label="Key Tag"
                            type="number"
                            id="keyTag"
                            min={0}
                            max={65535}
                            value={newDNSSecData.keyTag}
                            readOnly={false}
                            onChange={handleChange}
                            horizontal={false}
                            error={errors.keyTag}
                          />
                        </Col>
                        <Col sm={4} lg={2}>
                          <SelectInput
                            label="Digest Type"
                            id="digestType"
                            value={newDNSSecData.digestType}
                            options={digestTypeOptions}
                            onChange={handleChange}
                            horizontal={false}
                            error={errors.digestType}
                          />
                        </Col>
                        <Col>
                          <TextInput
                            label="Digest"
                            id="digest"
                            value={newDNSSecData.digest}
                            readOnly={false}
                            onChange={handleChange}
                            horizontal={false}
                            error={errors.digest}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col sm={1}>
                      <FormGroup>
                        <SaveIconButton onClick={saveNewDNSSec} />
                      </FormGroup>
                      {data.length ? (
                        <FormGroup>
                          <DeleteIconButton onClick={() => setNewDNSSecForm(false)} />
                        </FormGroup>
                      ) : ''}
                    </Col>
                  </Row>
                ) : (
                  <FormGroup row className="mt-3">
                    <Col md={2} className="pr-0">
                      <Button outline color="danger" onClick={loadNew}>
                        <i className="glyphicons glyphicons-plus" />
                      </Button>
                    </Col>
                  </FormGroup>
                )
              ) : ''}
            </>
          ) : ''}
        </SimpleLoader>
      </Col>
    </Row>
  );

};
export default DomainDNSSEC;
