import {
  IOrderState,
  ORDER_SET_DATA,
  ORDER_SET_ORDER_ACTIVITIES, ORDER_SET_ORDER_ACTIVITIES_TOGGLE_STATE,
  ORDER_TOGGLE_ACTIVITY,
  ORDER_TOGGLE_LOADING, ORDER_TOGGLE_LOADING_ACTIVITIES,
  OrderActionTypes,
  ORDER_RESET_DATA, ORDER_SET_ACTIVITY_CONTENT, ORDER_TOGGLE_LOADING_ACTIVITY_CONTENT,
} from './types';

const initialState: IOrderState = {
  loading: false,
  loadingActivities: false,
  data: {
    orderNumber: '',
    name: '',
    status: '',
    createdOn: '',
    modifiedOn: '',
    orderType: '',
    lineItems: [],
    projectManager: {
      firstName: '',
      lastName: '',
      name: '',
      email: '',
      telephone: '',
    },
  },
  activities: [],
};

export function orderReducer(state = initialState, action: OrderActionTypes) {

  switch (action.type) {
    case ORDER_TOGGLE_LOADING_ACTIVITIES:
      return {
        ...state,
        loadingActivities: !state.loadingActivities,
      };
    case ORDER_SET_DATA:
      return {
        ...state,
        data: action.data,
      };
    case ORDER_TOGGLE_LOADING:
      return {
        ...state,
        loading: !state.loading,
      };
    case ORDER_SET_ORDER_ACTIVITIES: {

      return {
        ...state,
        activities: action.activities,
      };
    
    }
    case ORDER_RESET_DATA:
      return {
        ...initialState,
      };

    case ORDER_TOGGLE_ACTIVITY: {

      const { activities } = state;
      const { id } = action;

      const updatedActivities = activities.map((activity) => {

        if (activity.id === id) {

          return { ...activity, showFullMessage: !activity.showFullMessage };
        
        }

        return activity;
      
      });

      return {
        ...state,
        activities: updatedActivities,
      };
    
    }
    case ORDER_TOGGLE_LOADING_ACTIVITY_CONTENT:
      return {
        ...state,
        activities: state.activities.map((activity) => {

          if (activity.id === action.id) {

            return { ...activity, loadingContent: !activity.loadingContent };
          
          }

          return activity;
        
        }),
      };
    case ORDER_SET_ACTIVITY_CONTENT:
      const updateActivities = state.activities.map((activity) => {

        if (activity.id === action.id) {

          return { ...activity, content: action.data };
        
        }

        return activity;
      
      });
      return {
        ...state,
        activities: updateActivities,
      };

    case ORDER_SET_ORDER_ACTIVITIES_TOGGLE_STATE: {

      const { activities } = state;
      const updatedActivities = activities.map((activity) => Object.assign(activity, { showFullMessage: false }));
      return {
        ...state,
        activities: updatedActivities,
      };
    
    }

    default:
      return {
        ...state,
      };
  }

}
