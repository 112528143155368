import {
  DASHBOARD_ADD_FAVOURITE,
  DASHBOARD_RESET,
  DASHBOARD_SET_BILLS,
  DASHBOARD_SET_OPEN_CASES,
  DASHBOARD_TOGGLE_LOADING,
  DashboardActionTypes,
  IDashboardState,
  DASHBOARD_TOGGLE_LOADING_SUMMARY,
  DASHBOARD_TOGGLE_LOADING_BILLS,
  DASHBOARD_TOGGLE_LOADING_CASES,
  DASHBOARD_TOGGLE_LOADING_FAVOURITES, DASHBOARD_REMOVE_FAVOURITE,
} from './types';
import { SYSTEM_SET_PARTNER_CUSTOMER, SystemActionTypes, SYSTEM_RESET_PARTNER_CUSTOMER } from '../system/types';
import { USER_REMOVE_FAVOURITE, UserActionTypes } from '../user/types';

const initialState: IDashboardState = {
  loading: false,
  loadingFavourites: false,
  loadingCases: false,
  loadingBills: false,
  loadingSummary: false,
  favourites: [],
  activity: [],
  bills: [],
  cases: [],
};
export function dashboardReducer(state = initialState, action: DashboardActionTypes | SystemActionTypes | UserActionTypes): IDashboardState {

  switch (action.type) {
    case SYSTEM_RESET_PARTNER_CUSTOMER: {

      const { favourites } = state;
      const newFavourites = favourites.map((favourite) => ({ ...favourite, hidden: false }));
      return {
        ...state,
        favourites: [...newFavourites],
      };
    
    }
    case SYSTEM_SET_PARTNER_CUSTOMER: {

      const { favourites } = state;
      const newFavourites = favourites.map((favourite) => {

        const hidden = action.userFavourites.filter((userFavourite) => favourite.id === userFavourite.itemId && userFavourite.accountId === action.customer.accountId).length === 0;
        return { ...favourite, hidden };
      
      });
      return {
        ...state,
        favourites: [...newFavourites],
      };
    
    }
    case DASHBOARD_TOGGLE_LOADING_SUMMARY:
      return {
        ...state,
        loadingSummary: !state.loadingSummary,
      };
    case DASHBOARD_TOGGLE_LOADING_BILLS:
      return {
        ...state,
        loadingBills: !state.loadingBills,
      };
    case DASHBOARD_TOGGLE_LOADING_CASES:
      return {
        ...state,
        loadingCases: !state.loadingCases,
      };
    case DASHBOARD_TOGGLE_LOADING_FAVOURITES:
      return {
        ...state,
        loadingFavourites: !state.loadingFavourites,
      };
    case DASHBOARD_ADD_FAVOURITE:
      const { favourites: oldFavourites } = state;
      const newFavourites = oldFavourites.filter((favourite) => favourite.id !== action.widget.id);
      return {
        ...state,
        favourites: [...newFavourites, action.widget],
      };
    case DASHBOARD_REMOVE_FAVOURITE: {

      // eslint-disable-next-line @typescript-eslint/no-shadow
      const newFavourites = state.favourites.filter(((fav) => fav.id !== action.itemId));
      return {
        ...state,
        favourites: [...newFavourites],
      };
    
    }
    case DASHBOARD_TOGGLE_LOADING:
      return {
        ...state,
        loading: !state.loading,
      };
    case DASHBOARD_SET_OPEN_CASES:
      return {
        ...state,
        cases: action.data,
      };
    case DASHBOARD_SET_BILLS:
      return {
        ...state,
        bills: action.bills,
      };
    case DASHBOARD_RESET:
      return {
        ...initialState,
        favourites: [...state.favourites],
      };
    case USER_REMOVE_FAVOURITE:
      const favourites = state.favourites.filter((fav) => fav.id !== action.itemId);
      return {
        ...initialState,
        favourites: [...favourites],
      };
    default:
      return state;
  }

}
