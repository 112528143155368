import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Button, Col, Modal, ModalBody, ModalFooter, Row,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import Section from '../../../components/Section';
import SearchInput from '../../../components/Inputs/SearchInput';
import { IServiceDNSState } from '../../../store/serviceDNS/types';
import {
  createDNSZone,
  getAccountDNSZones,
  resetDSNNewZone,
  setDNSZoneSearchString,
  toggleDNSNewZoneModal,
  toggleDNSZonesLoading,
  updateDNSZoneData,
} from '../../../store/serviceDNS/actions';
import ComplexTable from '../../../components/Table/ComplexTable';
import SelectInput from '../../../components/Inputs/SelectInput';
import TextInput from '../../../components/Inputs/TextInput';
import { successFeedback } from '../../../actions/feedback';
import SimpleLoader from '../../../components/SimpleLoader';
import { IUserState } from '../../../store/user/types';
import { checkPermission } from '../../../utils/Auth/AuthService';
import { AppDispatch } from '../../../configureStore';

type TOwnProps = {
  dispatch: AppDispatch;
  dns: IServiceDNSState;
  user: IUserState;
};

const zoneTypes = [
  { value: 'MASTER', label: 'Master' },
  { value: 'SLAVE', label: 'Slave' },
];

const DNS: React.FC<TOwnProps> = (props) => {

  const {
    zones,
    zone,
    loading,
    showNewZoneModal,
    filtered,
    searchString,
  } = props.dns;
  const { user } = props;

  useEffect(() => {

    props.dispatch(toggleDNSZonesLoading());
    props
      .dispatch(getAccountDNSZones())
      .then(() => props.dispatch(toggleDNSZonesLoading()));
  
  }, []);
  const onChangeSearch = (e) => {

    const searchString = e.target.value.toString().toLowerCase();
    props.dispatch(setDNSZoneSearchString(searchString));
  
  };

  const toggleNewZone = () => {

    if (!showNewZoneModal) {

      props.dispatch(resetDSNNewZone());
    
    }
    props.dispatch(toggleDNSNewZoneModal());
  
  };

  const handleNewZoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    props.dispatch(
      updateDNSZoneData({ [event.target.id]: event.target.value }),
    );
  
  };

  const createNewZone = () => {

    if (zone.name.length > 0) {

      props.dispatch(toggleDNSZonesLoading());
      props.dispatch(createDNSZone(zone)).then((success) => {

        if (success) {

          props.dispatch(successFeedback('Zone successfully created'));
          props.dispatch(toggleDNSNewZoneModal());
        
        }
        props.dispatch(toggleDNSZonesLoading());
      
      });
    
    }
  
  };
  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        filterable: false,
      },
      {
        Header: 'Type',
        accessor: 'type',
      },
      {
        Header: '',
        filterable: false,
        sortable: false,
        accessor: 'id',
        width: 50,
        Cell: (props) => (
          <Link
            className="grey-icon"
            to={`/services/dns/${props.value}`}
          >
            <i className="fa fa-search grey-icon__inner" />
          </Link>
        ),
      },
    ],
    [],
  );

  return (
    <div className="animated fadeIn mb-3">
      <Section title="DNS Management">
        <Row className="mb-4">
          <Col md="5">
            <SearchInput value={searchString} onChange={onChangeSearch} />
          </Col>
          {checkPermission('dns.write', user.permissions)
          && (
          <Col>
            <Button
              color="dark"
              className="text-white"
              onClick={toggleNewZone}
            >
              New Zone
            </Button>
          </Col>
          )}
        </Row>
        <Row className="mt-2 mr-0 ml-0">
          <Col className="p-4 bg-white rounded" md={10} sm={12}>
            <SimpleLoader loading={loading}>
              <ComplexTable
                columns={columns}
                data={searchString ? filtered : zones}
              />
            </SimpleLoader>
          </Col>
        </Row>
      </Section>
      <Modal
        isOpen={showNewZoneModal}
        toggle={toggleNewZone}
        color="warning"
        backdrop="static"
      >
        <SimpleLoader loading={loading}>
          <ModalBody>
            <Section title="New Zone">
              <SelectInput
                label="Type"
                id="type"
                options={zoneTypes}
                value={zone.type}
                onChange={handleNewZoneChange}
              />
              <TextInput
                label="Name"
                id="name"
                value={zone.name}
                readOnly={false}
                onChange={handleNewZoneChange}
              />
            </Section>
          </ModalBody>
          <ModalFooter className="border-top-0">
            <Button
              color="primary"
              className="text-white"
              onClick={createNewZone}
            >
              Yes
            </Button>
            {' '}
            <Button color="warning" onClick={toggleNewZone}>
              Cancel
            </Button>
          </ModalFooter>
        </SimpleLoader>
      </Modal>
    </div>
  );

};

function mapStateToProps({ serviceDNS, user }) {

  return {
    dns: serviceDNS,
    user,
  };

}
export default connect(mapStateToProps)(DNS);
