import { apiRequest } from '../Api/ApiRequest';

// eslint-disable-next-line @typescript-eslint/naming-convention
export function api_getReports() {

  return apiRequest({
    url: '/reports',
    method: 'get',
    headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
  }).then(
    (resp) => resp,
  ).catch(
    (error) => error,
  );

}

// eslint-disable-next-line @typescript-eslint/naming-convention
export function api_deleteReport(id) {

  return apiRequest({
    url: `/reports/${id}`,
    method: 'delete',
    headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
  }).then(
    (resp) => resp,
  ).catch(
    (error) => error,
  );

}
