import React from 'react';
import Select from 'react-select';

export const MultiSelect = (props) => {

  const newStyles = {
    control: (styles, state) => ({
      ...styles,
      backgroundColor: state.isDisabled ? '#e4e6eb' : 'white',
      borderColor: '#e4e6eb',
    }),
    placeholder: (styles) => ({
      ...styles,
      color: '#9DA0A7',
    }),
    valueContainer: (styles, state) => ({
      ...styles,
      padding: props.size === 'sm' ? '0 8px' : '2px 8px',
      backgroundColor: state.isDisabled ? '#e4e6eb' : 'white',
    }),
    clearIndicator: (styles) => ({
      ...styles,
      padding: props.size === 'sm' ? '0 8px' : '2px 8px',
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      padding: props.size === 'sm' ? '0 8px' : '2px 8px',
    }),
    loadingIndicator: (styles) => ({
      ...styles,
      padding: props.size === 'sm' ? '0 8px' : '2px 8px',
    }),
    menu: (styles) => ({
      ...styles,
      zIndex: 3, // Without this menu will be overlapped by other fields
    }),
    container: (base) => ({
      ...base,
      flex: 1,
    }),
  };
  return <Select styles={newStyles} {...props} />;

};
