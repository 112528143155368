export const DOCUMENTS_SET_DOCUMENTS = 'DOCUMENTS_SET_DOCUMENTS';
export const DOCUMENTS_SET_SEARCH_STRING = 'DOCUMENTS_SET_SEARCH_STRING';

export type TDocument = {
  id: number;
  name: string;
  type: string;
  updatedAt: string;
};

export interface IDocumentsState {
  documents: TDocument[];
  filtered: TDocument[];
  searchString: string;
}

export interface IDocumentsSetDocuments {
  type: typeof DOCUMENTS_SET_DOCUMENTS;
  data: TDocument[];
}

export interface IDocumentsSetSearchString {
  type: typeof DOCUMENTS_SET_SEARCH_STRING;
  searchString: string;
}

export type DocumentsActionTypes = IDocumentsSetDocuments | IDocumentsSetSearchString;
