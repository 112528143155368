import React, { useState } from 'react';
import {
  Card, CardBody, Label, Alert, Row, Col, FormGroup,
} from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import LaddaButton, { ZOOM_OUT } from 'react-ladda';
import toastr from 'toastr';
import { resetPassword } from '../../utils/Auth/AuthService';
import Section from '../../components/Section';
import { PASSWORD_POLICY_REGEX } from '../../helpers/password';

const ResetPassword = () => {

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('');
  const [processing, setProcessing] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);
  const [error, setError] = useState(null);
  const handleSubmit = () => {

    setProcessing(true);
    setError(null);
    resetPassword(currentPassword, newPassword).then((result) => {

      if (result.status === 200) {

        if (result.data.error) {

          setError(result.data.error);
        
        } else {

          setHasChanged(true);
          setCurrentPassword('');
          setNewPassword('');
          setNewPasswordConfirm('');
        
        }
      
      } else {

        toastr.error('There was an error with your request!');
      
      }
      setProcessing(false);
    
    });
  
  };
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleInvalidSubmit = () => {};
  const handleInput = (handler, event) => {

    handler(event.target.value);
  
  };
  if (hasChanged) {

    return (
      <div className="animated fadeIn">
        <Card>
          <CardBody>
            <Alert className="alert-success">
              Your password has been changed successfully.
            </Alert>
          </CardBody>
        </Card>
      </div>
    );
  
  }
  return (
    <div className="animated fadeIn">
      <Row>
        <Col md={2} sm={0} />
        <Col md={8} sm={12}>
          <Card style={{ borderRadius: '10px' }}>
            <CardBody>
              <Section title="Reset Password">
                {error && <Alert className="alert-danger">{error}</Alert>}
                <AvForm
                  onValidSubmit={handleSubmit}
                  onInvalidSubmit={handleInvalidSubmit}
                >
                  <Row>
                    <Col sm={12}>
                      <div>
                        <FormGroup className="row">
                          <Label
                            className="col-sm-4 col-form-label"
                            for="currentPassword"
                          >
                            Current Password
                          </Label>
                          <div className="col-sm-8">
                            <AvField
                              required
                              onChange={handleInput.bind(null, setCurrentPassword)}
                              name="currentPassword"
                              id="currentPassword"
                              className="form-control"
                              type="password"
                              value={currentPassword}
                            />
                          </div>
                        </FormGroup>
                        <FormGroup className="row">
                          <Label
                            className="col-sm-4 col-form-label"
                            for="firstName"
                          >
                            New Password
                          </Label>
                          <div className="col-sm-8">
                            <AvField
                              required
                              onChange={handleInput.bind(null, setNewPassword)}
                              name="newPassword"
                              id="newPassword"
                              className="form-control"
                              type="password"
                              validate={{
                                pattern: {
                                  value: PASSWORD_POLICY_REGEX,

                                },
                              }}
                              value={newPassword}
                            />
                          </div>
                        </FormGroup>
                        <FormGroup className="row">
                          <Label
                            className="col-sm-4 col-form-label"
                            for="newPasswordConfirm"
                          >
                            Confirm New Password
                          </Label>
                          <div className="col-sm-8">
                            <AvField
                              required
                              onChange={handleInput.bind(null, setNewPasswordConfirm)}
                              name="newPasswordConfirm"
                              id="newPasswordConfirm"
                              className="form-control"
                              type="password"
                              value={newPasswordConfirm}
                              validate={{ match: { value: 'newPassword' } }}
                            />
                          </div>
                        </FormGroup>
                        <Row>
                          <Col>
                            <small>
                              Must be between 14 to 64 characters long, contain
                              at least one (1) character from each of the following
                              categories:
                            </small>
                            <ul className="small pl-3">
                              <li>Uppercase letter (A-Z)</li>
                              <li>Lowercase letter (a-z)</li>
                              <li>Digit (0-9)</li>
                              <li>Special Characters (!@%#$&)</li>
                            </ul>
                          </Col>
                        </Row>
                        <FormGroup className="float-right">
                          <LaddaButton
                            className="btn btn-primary btn-ladda px-4"
                            loading={processing}
                            data-color="blue"
                            data-style={ZOOM_OUT}
                          >
                            Submit
                          </LaddaButton>
                        </FormGroup>
                      </div>
                    </Col>
                  </Row>
                </AvForm>
              </Section>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );

};
export default ResetPassword;
