import { api_escalateCase, api_getCase, api_resolveCase } from '../../utils/Case/Case';
import {
  CASE_GET_CASE,
  CASE_TOGGLE_LOADING,
  CASE_ASSIGN_ACTIVITY_IS_OPEN_FIELDS,
  CASE_TOGGLE_ACTIVITY_IS_OPEN,
  CASE_SET_SHOW_REPLY_FORM,
  CASE_SET_REPLY_VALUE,
  ISetCaseReplyValue,
  ISetCaseShowReplyForm,
  IAssignActivityIsOpenFields,
  ISetCaseAction,
  IToggleCaseLoadingAction,
  IToggleActivity,
  ISetCaseReplyProcessing,
  ISetCaseDescError,
  CASE_SET_REPLY_PROCESSING,
  CASE_SET_DESC_ERROR,
  CASE_UPDATE_RESOLVE_REASON,
  CASE_UPDATE_ESCALATION_NOTE,
  IUpdateResolveReason,
  IUpdateEscalationNote,
  CASE_TOGGLE_MODAL,
  CASE_ESCALATION_TOGGLE_MODAL,
  CASE_SET_RESOLVE_MODAL_ERROR_TEXT,
  CASE_SET_ESCALATION_MODAL_ERROR_TEXT,
  IUpdateResolveReasonDropdown,
  CASE_UPDATE_RESOLVE_REASON_DROPDOWN,
  TOGGLE_CASE_REASON_DROPDOWN,
  ITogglCaseReasonDropdownAction,
  IToggleCaseModalLoadingAction,
  CASE_TOGGLE_MODAL_LOADING,
  CASE_TOGGLE_ESCALATION_MODAL_LOADING,
  CASE_RESOLVE_CASE,
  CASE_ESCALATE_CASE,
  IToggleCaseEscalationModalLoadingAction,
  CASE_SET_ACTIVITY,
  CASE_TOGGLE_ACTIVITY_CONTENT_LOADING,
  IToggleCaseActivityContentLoading,
} from './types';

import { defaultErrorFeedback, errorFeedback } from '../../actions/feedback';
import { setSystemError } from '../system/actions';
import { api_getCaseActivity, createCase } from '../../utils/Cases/Cases';
import { addCaseToCases } from '../cases/actions';

export const setCaseReplyValue = (value): ISetCaseReplyValue => ({ type: CASE_SET_REPLY_VALUE, value });
export const setCaseReplyFormOpen = (): ISetCaseShowReplyForm => ({ type: CASE_SET_SHOW_REPLY_FORM });
export const setReplyProcessing = (payload): ISetCaseReplyProcessing => ({ type: CASE_SET_REPLY_PROCESSING, payload });
export const setDescError = (payload): ISetCaseDescError => ({ type: CASE_SET_DESC_ERROR, payload });
export const caseUpdateResolveReason = (payload): IUpdateResolveReason => ({ type: CASE_UPDATE_RESOLVE_REASON, payload });
export const caseUpdateEscalationNote = (payload): IUpdateEscalationNote => ({ type: CASE_UPDATE_ESCALATION_NOTE, payload });
export const caseToggleModal = () => ({ type: CASE_TOGGLE_MODAL });
export const caseEscalationToggleModal = () => ({ type: CASE_ESCALATION_TOGGLE_MODAL });
export const caseResolve = () => ({ type: CASE_RESOLVE_CASE });
export const caseEscalate = () => ({ type: CASE_ESCALATE_CASE });
export const caseResolveSetErrorText = (payload) => ({ type: CASE_SET_RESOLVE_MODAL_ERROR_TEXT, payload });
export const caseEscalationSetErrorText = (payload) => ({ type: CASE_SET_ESCALATION_MODAL_ERROR_TEXT, payload });

export const setCaseResolveReasonDropdown = (payload): IUpdateResolveReasonDropdown => ({ type: CASE_UPDATE_RESOLVE_REASON_DROPDOWN, payload });
export const toggleCaseReasonDropdownOpen = (): ITogglCaseReasonDropdownAction => ({ type: TOGGLE_CASE_REASON_DROPDOWN });

export function updateCaseResolved(caseId, resolveReason, dropdownValue) {

  return (dispatch) => api_resolveCase(caseId, resolveReason, dropdownValue).then((result) => {

    if (result.status === 200) {

      dispatch(caseResolve());
      return true;
    
    }

    dispatch(setSystemError('Could not resolve case'));
  
  });

}
export function updateCaseEscalated(caseId, escalationNote) {

  return (dispatch) => api_escalateCase(caseId, escalationNote).then((result) => {

    if (result.status === 200) {

      return true;
    
    }
    dispatch(errorFeedback('Could not Escalate case'));
    return false;
  
  });

}
function setCase(data): ISetCaseAction {

  return {
    type: CASE_GET_CASE,
    data,
  };

}
export function toggleCaseLoading(): IToggleCaseLoadingAction {

  return {
    type: CASE_TOGGLE_LOADING,
  };

}

export const toggleCaseModalLoading = (): IToggleCaseModalLoadingAction => ({ type: CASE_TOGGLE_MODAL_LOADING });
export const toggleCaseEscalationModalLoading = (): IToggleCaseEscalationModalLoadingAction => ({ type: CASE_TOGGLE_ESCALATION_MODAL_LOADING });
export function getCase(caseId) {

  return (dispatch) => api_getCase(caseId).then((result) => {

    if (result.status === 200) {

      dispatch(setCase(result.data));
    
    } else if (result.data.error && result.data.error.id) {

      dispatch(errorFeedback(`Could not resolve case  id ${result.data.error.id}`));
    
    } else {

      dispatch(defaultErrorFeedback());
    
    }
  
  });

}
export function assignActivityIsOpenFields(): IAssignActivityIsOpenFields {

  return {
    type: CASE_ASSIGN_ACTIVITY_IS_OPEN_FIELDS,
  };

}
export function toggleActivity(id): IToggleActivity {

  return {
    type: CASE_TOGGLE_ACTIVITY_IS_OPEN,
    id,
  };

}

export function toggleActivityContentLoading(id): IToggleCaseActivityContentLoading {

  return {
    type: CASE_TOGGLE_ACTIVITY_CONTENT_LOADING,
    id,
  };

}
const setCaseActivity = (data) => ({
  type: CASE_SET_ACTIVITY,
  data,
});

export const createNewCase = (object) => (dispatch) => createCase(object).then((result) => {

  if (result.status === 200) {

    dispatch(addCaseToCases({ ...result.data, ticketNumber: result.data.caseId }));
    return result.data;
  
  }

  return false;

});

export const getCaseActivity = (caseId, activityId) => (dispatch) => api_getCaseActivity(caseId, activityId).then((result) => {

  if (result.status === 200) {

    dispatch(setCaseActivity(result.data));
    return true;
  
  }

  return false;

});
