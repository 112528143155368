import { TContact } from '../contact/types';
import { IMonitoredDeviceRevertDesc, IMonitoredItemDescSave } from '../monitoredItem/types';
import { TSystemSettings } from '../system/types';
import {TWeatherMapsList} from "../weatherMaps/types";

export const USER_SET_DATA = 'USER_SET_DATA';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_ADD_FAVOURITE = 'USER_ADD_FAVOURITE';
export const USER_REMOVE_FAVOURITE = 'USER_REMOVE_FAVOURITE';
export const USER_UPDATE_FAVOURITE_ACCOUNT = 'USER_UPDATE_FAVOURITE_ACCOUNT';
export const USER_SET_AUTHENTICATION = 'USER_SET_AUTHENTICATION';
export const SOCKET_LOGOUT = 'SOCKET_LOGOUT';

export const USER_TYPE_ADMINISTRATOR = 864640000;
export const USER_TYPE_TECHNICAL_USER = 864640001;
export const USER_TYPE_READ_ONLY = 864640002;
export const USER_TYPE_SUPPORT_USER = 864640003;

export interface IUserState {
  authentication: TAuthentication;
  username: string;
  email: string;
  lastLogin: string;
  active: boolean;
  role: number;
  permissions: TUserPermission[];
  contact: TContact;
  favourites: TFavourite[];
  settings: TSystemSettings;
  weatherMaps?: TWeatherMapsList
}
type TAuthentication = {
  token: string;
  refresh: string;
  loggedOut: boolean;
};
export type TFavourite = {
  itemId: string;
  name: string;
  description: string;
  type: ('device' | 'port');
  createdAt: Record<string, any>;
  accountId: string;
  tempDesc: string;
};

export type TUserPermission = string;

export type TPortalSetting = {
  name: string;
  value: string;
};
export interface IUserAddFavouriteAction {
  itemId: string;
  itemName: string;
  itemLocation: string;
  type: typeof USER_ADD_FAVOURITE;
}
export interface IUserUpdateFavouriteAccountAction {
  type: typeof USER_UPDATE_FAVOURITE_ACCOUNT;
  itemId: string;
  accountId: string;
}
export interface IUserRemoveFavouriteAction {
  itemId: string;
  type: typeof USER_REMOVE_FAVOURITE;
}
export interface IUserSetDataAction {
  type: typeof USER_SET_DATA;
  data: object;
}
export interface IUserLogoutAction {
  type: typeof USER_LOGOUT;
}
export interface IUserSocketLogoutAction {
  type: typeof SOCKET_LOGOUT;
}
export interface IUserSetAuthenticationAction {
  type: typeof USER_SET_AUTHENTICATION;
  refresh: string;
  token: string;
  loggedOut: boolean;
}

export type UserActionTypes = IUserSetDataAction | IUserLogoutAction | IUserAddFavouriteAction |
IUserRemoveFavouriteAction | IUserSetAuthenticationAction | IMonitoredItemDescSave | IMonitoredDeviceRevertDesc |
IUserUpdateFavouriteAccountAction;
