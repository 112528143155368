import React, { FunctionComponent, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Row, Button } from 'reactstrap';
import ReactTooltip from 'react-tooltip';
import { IUserState } from '../../store/user/types';
import Section from '../../components/Section';
import SearchInput from '../../components/Inputs/SearchInput';
import { IMonitoringState } from '../../store/monitoring/types';
import {
  getMonitoredItems,
  setMonitoringSearchString,
  toggleMonitoredItemsLoading,
  resetMonitoringStore,
  toggleMonitoredItemsStatusLoading,
  getMonitoredItemStatuses,
} from '../../store/monitoring/actions';
import ComplexTable from '../../components/Table/ComplexTable';
import SimpleLoader from '../../components/SimpleLoader';
import Refresh from '../../components/SvgIcons/Refresh';
import FavouriteStar from '../../components/FavouriteStar';
import { favouriteItem, unFavouriteItem, getAuthStatus } from '../../store/user/actions';
import { ACCOUNT_TYPE_RESALE_PARTNER, IAccountState } from '../../store/account/types';
import SearchIcon from '../../components/SvgIcons/SearchIcon';
import { ISystemState } from '../../store/system/types';
import { AppDispatch } from '../../configureStore';

type TOwnProps = {
  user: IUserState;
  monitoring: IMonitoringState;
  dispatch: AppDispatch;
  account: IAccountState;
  system: ISystemState;
};
const Monitoring: FunctionComponent<TOwnProps> = (props) => {

  const { dispatch, user, account } = props;
  const {
    items, filtered, loading, loadingStatuses, searchString,
  } = props.monitoring;
  useEffect(() => {

    if (items.length === 0 || account.accountTypeCode === ACCOUNT_TYPE_RESALE_PARTNER) refreshStore();
  
  }, []);
  const refreshStore = () => {

    dispatch(resetMonitoringStore());
    dispatch(toggleMonitoredItemsLoading());
    dispatch(getMonitoredItems()).then(() => {

      dispatch(toggleMonitoredItemsLoading());
      dispatch(toggleMonitoredItemsStatusLoading());
      dispatch(getMonitoredItemStatuses()).then(() => dispatch(toggleMonitoredItemsStatusLoading()));
    
    });
  
  };
  const onChangeSearch = (e) => {

    const searchString = e.target.value.toString().toLowerCase();
    dispatch(setMonitoringSearchString(searchString));
  
  };

  const userFavorite = (itemId) => user.favourites.filter((favourite) => itemId === favourite.itemId).length !== 0;
  const columns = [
    {
      Header: 'Status',
      accessor: 'available',
      width: 100,
      Cell: (props) => (
        <div className="d-flex justify-content-center">
          <ReactTooltip />
          <SimpleLoader
            loading={loadingStatuses}
            styles={{
              spinner: (base) => ({
                ...base,
                width: '25px',
                marginLeft: '10px',
              }),
            }}
          >
            {props.value
                                && (
                                <div
                                  data-tip="Available"
                                  style={{
                                    height: '20px', width: '20px', borderRadius: '10px', marginLeft: '15px',
                                  }}
                                  className="bg-success"
                                />
                                )}
            {props.value !== undefined && !props.value
                                && (
                                <div
                                  data-tip="Offline"
                                  style={{
                                    height: '20px', width: '20px', borderRadius: '10px', marginLeft: '15px',
                                  }}
                                  className="bg-danger"
                                />
                                )}
          </SimpleLoader>
        </div>
      ),
    },
    {
      Header: 'Location',
      accessor: 'location',
      minWidth: 250,
      Cell: (props) => <div style={{ minWidth: '450px', display: 'inline' }}>{props.value}</div>,
    },
    {
      Header: 'Related Service',
      accessor: 'services',
      minWidth: 400,
      Cell: (props) => (
        <div>
          {props.value.map((service) => (service.url
            ? <p key={service.id}><Link to={service.url}>{service.name}</Link></p>
            : <p key={service.id}>{service.name}</p>))}
        </div>
      ),
    },
    {
      Header: 'Host',
      accessor: 'name',
      width: 150,
    },
    {
      Header: 'Description',
      accessor: 'description',
      minWidth: 200,
    },
    {
      Header: 'Type',
      accessor: 'type',
      maxWidth: 100,
    },
    {
      width: 100,
      sortable: false,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      Header: () => {},
      accessor: 'id',
      Cell: (props) => {

        const {
          deviceType, type, id, name, location,
        } = props.original;

        const action = userFavorite(id) ? () => dispatch(unFavouriteItem(id, name, location)) : () => {

          dispatch(favouriteItem(id, name, location));
        
        };
        return (
          <div style={{ height: '40px' }}>
            <Link className="grey-icon rounded float-right" to={`/monitoring/${props.value}`}>
              <SearchIcon title="details" />
            </Link>
            {(type === 'port'
                            || (type === 'device'
                                && deviceType !== 'zyxel'
                                && deviceType !== 'mitel'
                                && deviceType !== 'linux'
                                && deviceType !== 'windows'
                                && deviceType !== 'vmware'
                                && deviceType !== 'mx'))
                            && <FavouriteStar filled={userFavorite(id)} onClick={action} />}
          </div>
        );
      
      },
    },
  ];
  return (
    <div className="animated fadeIn mb-3">
      <Section title="Monitoring">
        <Row className="mb-4">
          <Col md="5">
            <SearchInput value={searchString} onChange={onChangeSearch} />
          </Col>
          <Col md="7" className="d-flex justify-content-end">
            <Button className="pt-1 px-1" color="primary" onClick={() => refreshStore()}>
              <Refresh title="Refresh" cssClassTop="refresh-svg" />
            </Button>
          </Col>
        </Row>
        <Row className="mt-2 mr-0 ml-0 mb-5">
          <Col className="p-4 bg-white rounded">
            <SimpleLoader loading={loading} text="Loading your monitored items...">
              <ComplexTable
                defaultSorted={{ id: 'available', desc: false }}
                tableClass="monitoring-table"
                columns={columns}
                data={searchString ? filtered : items}
              />
            </SimpleLoader>
          </Col>
        </Row>
      </Section>
    </div>
  );

};
function mapStateToProps({
  user, monitoring, account, system,
}) {

  return {
    monitoring,
    user,
    account,
    system,
  };

}
export default connect(mapStateToProps)(Monitoring);
