import React, { FunctionComponent } from 'react';
import { Alert, Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';

type TOwnProps = {
  accountManagerEmail: string;
  status: string;
  referenceNumber?: string;
};
const NewOrderStatusMessage: FunctionComponent<TOwnProps> = ({ accountManagerEmail, status, referenceNumber }) => {

  const color = (): string => {

    switch (status) {
      case 'failed':
        return 'danger';
      case 'completed':
        return 'success';
      default:
        return 'warning';
    }
  
  };

  return (
    <Row className="p-4 bg-white rounded">
      <Col>
        <Alert color={color()} className="mt-4 pt-4 text-center">
          {status === 'failed' ? (
            <p>
              There was an issue with your order.
              {' '}
              <a href={`mailto:${accountManagerEmail}`}>
                Please
                contact your
                account manager.
              </a>
            </p>
          ) : ''}
          {status === 'purchase token failed' ? (
            <>
              <p>Unfortunately you do not have enough credit on your account to complete this purchase.</p>
              <p>
                Please
                <a href={`mailto:${accountManagerEmail}`}>contact your account manager</a>
                {' '}
                for
                further information.
              </p>
            </>
          ) : ''}
          {status === 'completed with errors' ? (
            <p>
              Your order completed with errors.
              {' '}
              <a
                href={`mailto:${accountManagerEmail}`}
              >
                Please contact your account manager.
              </a>
            </p>
          ) : ''}
          {status === 'completed' ? (
            <p>
              <strong>
                Your reference number:
                {referenceNumber}
              </strong>
              <br />
              Your order is now waiting to be signed. Please check your inbox or
              {' '}
              <Link to="/signatures">
                click
                here
              </Link>
              {' '}
              to see all signatures.
              <br />
              <i className="fa fa-check-circle font-5xl mt-2" />
            </p>
          ) : ''}
        </Alert>
      </Col>
    </Row>
  );

};
export default NewOrderStatusMessage;
