export const SERVICE_ORDERS_ADD_ITEM = 'SERVICE_ORDERS_ADD_ITEM';
export const SERVICE_ORDERS_REMOVE_ITEM = 'SERVICE_ORDERS_REMOVE_ITEM';
export const SERVICE_ORDERS_SET_FIELD = 'SERVICE_ORDERS_SET_FIELD';
export const SERVICE_ORDERS_RESET = 'SERVICE_ORDERS_RESET';
export const SERVICE_ORDERS_SET_CURRENT = 'SERVICE_ORDERS_SET_CURRENT';
export const SERVICE_ORDERS_RESET_CURRENT = 'SERVICE_ORDERS_RESET_CURRENT';
export const SERVICE_ORDERS_SET_CURRENT_FIELD = 'SERVICE_ORDERS_SET_CURRENT_FIELD';
export const SERVICE_ORDERS_SINGLE_ONLY_ITEMS = ['HSO50-211'];
export const SERVICE_ORDERS_CONTRACT_LENGTHS = [12, 24, 36, 48, 60];

export type TDomainServiceOrderItem = {
  name: string;
  action: 'register' | 'renew' | 'transfer';
  years?: number;
  amount?: number;
};

type TPreSalesServiceOrderItemCommon = {
  id: number;
  name?: string;
  contractLength: number;
  install?: number;
  rental?: number;
  amount?: number;
  productCode?: string;
};

export type TUnitServiceOrderItem = TPreSalesServiceOrderItemCommon & {
  type: 'unit';
  quantity: number | null;
  unit: string;
};

export type TStandardServiceOrderItem = TPreSalesServiceOrderItemCommon & {
  type: 'standard';
};

export type TPreSalesServiceOrderItem = TStandardServiceOrderItem | TUnitServiceOrderItem;

export type TServiceOrderItem = TDomainServiceOrderItem | TPreSalesServiceOrderItem;

type TServiceOrderStateCommon = {
  items: Array<TPreSalesServiceOrderItem>;
  purchaseToken: string;
  referenceNumber?: string;
  status: 'in progress' | 'completed with errors' | 'completed' | 'purchase token failed' | 'failed';
};

export type TDomainServiceOrderState = TServiceOrderStateCommon & {
  type: 'domain';
  contact: any;
};

export type TPreSalesServiceOrderState = TServiceOrderStateCommon & {
  type: 'services';
  billingContactId: string;
  signatoryContactId: string;
  currentItem?: TPreSalesServiceOrderItem;
  originalCurrentItem?: TPreSalesServiceOrderItem;
};

export type TServiceOrderState = TDomainServiceOrderState | TPreSalesServiceOrderState;

export type TServiceOrderFailedItem = {
  name: string;
  id: string | number;
};

export interface ISetServiceOrderFieldAction {
  type: typeof SERVICE_ORDERS_SET_FIELD;
  field: string;
  value: any;
}

export interface IAddServiceOrderItemAction {
  type: typeof SERVICE_ORDERS_ADD_ITEM;
  data: TPreSalesServiceOrderItem;
}

export interface IRemoveServiceOrderItemAction {
  type: typeof SERVICE_ORDERS_REMOVE_ITEM;
  index: number;
}

export interface IResetServiceOrder {
  type: typeof SERVICE_ORDERS_RESET;
}

export interface ISetCurrentServiceOrderItemAction {
  type: typeof SERVICE_ORDERS_SET_CURRENT;
  data: TPreSalesServiceOrderItem;
}

export interface IResetCurrentServiceOrderItemAction {
  type: typeof SERVICE_ORDERS_RESET_CURRENT;
}

export interface ISetCurrentServiceOrderItemFieldAction {
  type: typeof SERVICE_ORDERS_SET_CURRENT_FIELD;
  field: string;
  value: string;
}

export type ServiceOrderActionTypes =
  ISetServiceOrderFieldAction
  | IAddServiceOrderItemAction
  | IRemoveServiceOrderItemAction
  | IResetServiceOrder
  | ISetCurrentServiceOrderItemAction
  | IResetCurrentServiceOrderItemAction
  | ISetCurrentServiceOrderItemFieldAction;
