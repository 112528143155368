import { AxiosResponse } from 'axios';
import { Moment } from 'moment';
import {
  ISetBroadbandUsageDatesAction, SERVICE_BROADBAND_RESET_DATA,
  SERVICE_BROADBAND_SET_DATA,
  SERVICE_BROADBAND_SET_USAGE,
  SERVICE_BROADBAND_SET_USAGE_BAR_CHART,
  SERVICE_BROADBAND_SET_USAGE_DATES,
  SERVICE_BROADBAND_SET_USAGE_PIE_CHART,
  SERVICE_BROADBAND_TOGGLE_LOADING,
  SERVICE_BROADBAND_TOGGLE_USAGE_LOADING,
  TBroadbandUsageBarChart,
  TBroadbandUsagePieChart,
  TServiceBroadband,
} from './types';
import { api_getBroadbandService, api_getBroadbandServiceUsage } from '../../utils/Broadband/Broadband';
import { defaultErrorFeedback, errorFeedback } from '../../actions/feedback';
import { setSystemError } from '../system/actions';

function setBroadbandData(data: TServiceBroadband) {

  return {
    type: SERVICE_BROADBAND_SET_DATA,
    data,
  };

}
function setBroadbandUsage(data: TServiceBroadband) {

  return {
    type: SERVICE_BROADBAND_SET_USAGE,
    data,
  };

}
function setBroadbandUsagePieChart(data: TBroadbandUsagePieChart) {

  return {
    type: SERVICE_BROADBAND_SET_USAGE_PIE_CHART,
    data,
  };

}
function setBroadbandUsageBarChart(data: TBroadbandUsageBarChart) {

  return {
    type: SERVICE_BROADBAND_SET_USAGE_BAR_CHART,
    data,
  };

}
export function toggleServiceBroadbandLoading() {

  return {
    type: SERVICE_BROADBAND_TOGGLE_LOADING,
  };

}
export function resetServiceBroadbandData() {

  return {
    type: SERVICE_BROADBAND_RESET_DATA,
  };

}
export function toggleServiceBroadbandUsageLoading() {

  return {
    type: SERVICE_BROADBAND_TOGGLE_USAGE_LOADING,
  };

}
export function setServiceBroadbandUsageDates(currentDate: Moment, prevMonth: Moment, nextMonth: Moment): ISetBroadbandUsageDatesAction {

  return {
    type: SERVICE_BROADBAND_SET_USAGE_DATES,
    currentDate,
    prevMonth,
    nextMonth,
  };

}
export function getBroadbandService(id) {

  return (dispatch) => api_getBroadbandService(id).then((result: AxiosResponse) => {

    if (result.status === 200) {

      dispatch(setBroadbandData(result.data));
      return true;
    
    }
    if (result.data.error && result.data.error.id) {

      dispatch(setSystemError(result.data.error.id));
    
    } else {

      dispatch(defaultErrorFeedback());
    
    }
    return false;
  
  });

}
export function getBroadbandServiceUsage(id, month, year) {

  return (dispatch) => api_getBroadbandServiceUsage(id, month, year).then((result: AxiosResponse) => {

    if (result.status === 200) {

      const labels: number[] = [];
      const download: number[] = [];
      const upload: number[] = [];
      let totalUpload = 0;
      let totalDownload = 0;
      result.data.forEach((day) => {

        labels.push(day.day);
        download.push(day.download);
        upload.push(day.upload);
        totalUpload += day.upload;
        totalDownload += day.download;
      
      });
      dispatch(setBroadbandUsage(result.data));
      dispatch(setBroadbandUsageBarChart({ labels, download, upload }));
      dispatch(setBroadbandUsagePieChart({ upload: totalUpload, download: totalDownload }));
    
    } else {

      dispatch(errorFeedback('Could not fetch service data'));
    
    }
  
  });

}
