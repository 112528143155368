import React, { JSX, useEffect, FunctionComponent } from 'react';
import { connect } from 'react-redux';
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  Button,
  InputGroup,
  Input,
  Form,
  InputGroupAddon,
} from 'reactstrap';
import ReactTable from 'react-table-6';
import Cart from './DomainCart';
import { addToDomainCart } from '../../../actions/domainCart';
import { getTerms } from '../../../helpers/domains/domains';
import {
  lookupDomains,
  resetLookupResults,
  setDomainLookupDomainYear,
  setDomainLookupSearchString,
  toggleDomainLookupLoading,
} from '../../../store/domainLookup/actions';
import { resetDomainCheckout } from '../../../actions/domainCheckout';
import SimpleLoader from '../../../components/SimpleLoader';
import { checkPermission } from '../../../utils/Auth/AuthService';
import { IDomainsState } from '../../../store/domains/types';
import { IUserState } from '../../../store/user/types';
import { TDomain } from '../../../store/domain/types';
import { IDomainLookupState } from '../../../store/domainLookup/types';
import { useQuery } from '../../../helpers/QueryString';
import { AppDispatch } from '../../../configureStore';

type TProps = {
  dispatch: AppDispatch;
  cart: IDomainsState[];
  domainLookup: IDomainLookupState;
  domains: TDomain[];
  user: IUserState;
};
const DomainLookup: FunctionComponent<TProps> = ({
  dispatch, cart, domainLookup, domains, user,
}): JSX.Element => {

  const queryParams = useQuery();
  const searchParam = queryParams.get('search');

  const handleInput = (e) => {

    dispatch(setDomainLookupSearchString(e.target.value));
  
  };

  const search = (searchString = '') => {

    dispatch(resetLookupResults());
    dispatch(toggleDomainLookupLoading());
    dispatch(lookupDomains(searchString || domainLookup.searchString))
      .then(() => {

        dispatch(toggleDomainLookupLoading());
      
      });
  
  };

  useEffect(() => {

    if (searchParam !== null) {

      search(searchParam);
      dispatch(setDomainLookupSearchString(queryParams.get('search')));
    
    }
  
  }, [searchParam]);

  const handleSubmit = (e) => {

    e.preventDefault();
    search();
  
  };

  const handleAddToCart = (domainName, actionType) => {

    const domain = domainLookup.results[domainName];
    dispatch(resetDomainCheckout());
    dispatch(
      addToDomainCart({
        ...domain,
        id: domain.name,
        action: actionType,
        years: domain.years,
      }),
    );
  
  };

  const handleYearSelect = (domainName, e) => {

    dispatch(setDomainLookupDomainYear(domainName, e.target.value));
  
  };

  const checkCart = (id) => cart[id];

  const { results, loading, searchString } = domainLookup;
  const columns = [
    {
      filterable: false,
      Header: 'Domain',
      accessor: 'name',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ value }) => (
        <div className="text-primary">
          <strong>{value}</strong>
        </div>
      ),
    },
    {
      filterable: false,
      Header: 'Status',
      accessor: 'status',
      Cell: ({ value }) => (value === 'available' ? value : 'Not available'),
    },
    {
      filterable: false,
      Header: 'Price (£/year)',
      Cell: ({ original }) => (original.status === 'available'
        ? original.tld.price.register
        : original.tld.price.transfer),
    },
    {
      filterable: false,
      Header: 'Term',
      accessor: 'status',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ original, value }) => (value === 'available' ? (
        <Input
          type="select"
          className="form-control"
          value={original.years}
          onChange={handleYearSelect.bind(null, original.name)}
        >
          {getTerms(original, original.tld).map(
            (item, index) => (
              <option key={`option${index}`} value={item}>
                {item + (item === 1 ? ' year' : ' years')}
              </option>
            ),
          )}
        </Input>
      ) : (
        ''
      )),
    },
    {
      Header: '',
      filterable: false,
      sortable: false,
      accessor: 'status',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ original, value }) => {

        if (!checkPermission('domains.write', user.permissions)) {

          return <div />;
        
        }
        if (checkCart(original.name)) {

          return (
            <Button
              size="sm"
              outline
              color="secondary float-right"
              disabled
            >
              In cart
            </Button>
          );
        
        }
        const ownDomain = domains.filter((domain) => domain.name === original.name);
        if (ownDomain.length) {

          return (
            <div className="text-primary float-right">
              <strong>You already own this!</strong>
            </div>
          );
        
        }
        return value === 'available' ? (
          <Button
            size="sm"
            outline
            color="success float-right"
            onClick={handleAddToCart.bind(
              null,
              original.name,
              'register',
            )}
          >
            Register
          </Button>
        ) : (
          <Button
            size="sm"
            outline
            color="warning float-right"
            onClick={handleAddToCart.bind(
              null,
              original.name,
              'transfer',
            )}
          >
            Transfer
          </Button>
        );
      
      },
    },
  ];
  return (
    <div className="animated fadeIn">
      <SimpleLoader loading={loading}>
        <Row className="d-flex flex-xl-row flex-column-reverse row" style={{ minHeight: '400px' }}>
          <Col>
            <Card>
              <CardBody
                className="d-flex flex-column justify-content-center align-items-center"
              >
                <CardTitle>Domain Lookup</CardTitle>
                <Col md={6} className="mb-5 mt-3">
                  <Form onSubmit={handleSubmit}>
                    <InputGroup>
                      <Input
                        className="form-control-lg"
                        value={searchString}
                        placeholder="search for your next domain here"
                        onChange={handleInput}
                      />
                      <InputGroupAddon addonType="append">
                        <Button
                          color="primary"
                          className="text-white"
                          type="submit"
                          onClick={handleSubmit}
                        >
                          <CardTitle className="mb-0">Search</CardTitle>
                        </Button>
                      </InputGroupAddon>
                    </InputGroup>
                  </Form>
                </Col>
                {Object.keys(domainLookup).length > 0 && (
                  <Col className="mt-3">
                    <ReactTable
                      getTheadProps={() => ({
                        style: { border: 'none' },
                      })}
                      getTheadTrProps={() => ({
                        style: { border: 'none' },
                      })}
                      getTheadThProps={() => ({
                        style: { border: 'none' },
                      })}
                      getTdProps={() => ({
                        style: { border: 'bottom', whiteSpace: 'unset' },
                      })}
                      className="animated fadeIn"
                      noDataText="No available domains"
                      data={Object.keys(results).map((key) => results[key])}
                      columns={columns}
                      minRows={2}
                      filterable={false}
                      showPagination={false}
                    />
                  </Col>
                )}
              </CardBody>
            </Card>
          </Col>
          {Object.keys(cart).length > 0 && (
            <Col xl={3}>
              <Cart />
            </Col>
          )}
        </Row>
      </SimpleLoader>
    </div>
  );

};

function mapStateToProps({
  domainCart, domainLookup, domains, user,
}) {

  return {
    cart: domainCart,
    domainLookup,
    domains: domains.domains,
    user,
  };

}

export default connect(mapStateToProps)(DomainLookup);
