import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Col, Row } from 'reactstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { Pie } from 'react-chartjs-2';
import * as filesize from 'filesize';
import Section from '../../../components/Section';
import SimpleLoader from '../../../components/SimpleLoader';
import TextInput from '../../../components/Inputs/TextInput';
import { TServiceVeeamBackup } from '../../../store/serviceVeeamBackup/types';
import { getVeeamBackupService, resetServiceVeeamBackupData } from '../../../store/serviceVeeamBackup/actions';
import { IUserState } from '../../../store/user/types';
import { checkPermission } from '../../../utils/Auth/AuthService';
import { TPriceList, TPriceListItem } from '../../../store/priceList/types';
import { TPreSalesServiceOrderState } from '../../../store/serviceOrder/types';
import NewServiceOrderItemModal from '../../../components/Modals/ServiceOrders/NewServiceOrderItemModal';
import { AppDispatch } from '../../../configureStore';

type TParams = { id: string };
type TOwnProps = {
  dispatch: AppDispatch;
  user: IUserState;
  service: TServiceVeeamBackup;
  priceList: TPriceList;
  serviceOrder: TPreSalesServiceOrderState;
};
type TProps = TOwnProps;
const VeeamBackup: React.FC<TProps> = ({
  user, service, dispatch, priceList,
}) => {

  const [loading, setLoading] = useState<boolean>(true);
  const [priceListItem, setPriceListItem] = useState<TPriceListItem | null>(null);
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {

    if (params.id) {

      dispatch(getVeeamBackupService(params.id, ['billing']))
        .then((result) => {

          if (result) {

            setLoading(false);
          
          }
        
        });
    
    }

    return () => {

      dispatch(resetServiceVeeamBackupData());
    
    };
  
  }, []);

  const freeStorageQuota = () => service.storageQuota - service.usedStorageQuota;

  const pieChart = {
    datasets: [{
      data: [service.usedStorageQuota, freeStorageQuota()],
      backgroundColor: ['#FF9E1B', '#6BA4B8'],
    }],
    labels: ['Usage', 'Free'],
  };

  const canUpgradeQuota = (): boolean => checkPermission('orders.write', user.permissions)
    && Boolean(priceList && priceList.items.find((item) => item.productCode === 'HSO50-211'));

  const toggleUpgradeWizard = () => setPriceListItem(priceList.items.find((item) => item.productCode === 'HSO50-211') || null);

  return (
    <div className="animated fadeIn mb-5">
      <SimpleLoader loading={loading}>
        <Row>
          <Col>
            <Section title="hSo Cloud Connect Veeam Backup">
              <Row>
                <Col lg={10} xl={8} className="d-flex">
                  <div
                    className="bg-white rounded shadow-sm pt-4 pl-4 pb-4 pr-3 d-flex flex-column w-100"
                    style={{ borderRadius: '10px' }}
                  >
                    <Row>
                      <Col md={6}>
                        <div className="text-light h2 mb-3">Cloud Repository Storage</div>
                        <TextInput
                          id="usage"
                          label="Usage"
                          value={filesize(service.usedStorageQuota)}
                          readOnly
                          textAlignRight
                          textColor="warning"
                        />
                        <TextInput
                          id="free"
                          label="Free"
                          value={filesize(freeStorageQuota())}
                          readOnly
                          textAlignRight
                          textColor="primary"
                        />
                        <TextInput
                          id="quantity"
                          label="Total Quota"
                          value={filesize(service.storageQuota)}
                          readOnly
                          textAlignRight
                        />
                        <TextInput
                          id="vms"
                          label="Number of VMs in Cloud Repository"
                          value={service.vms}
                          readOnly
                          textAlignRight
                        />
                      </Col>
                      <Col md={6} className="chart-wrapper" style={{ height: `${30}vh`, marginTop: `${20}px` }}>
                        <Pie
                          data={pieChart}
                          options={{
                            maintainAspectRatio: false,
                            legend: {
                              display: false,
                            },
                            tooltips: {
                              callbacks: {
                                label(tooltipItems, data) {

                                  const label = tooltipItems.index === 0 ? 'Usage' : 'Free';
                                  return `${label}: ${filesize(data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index])}`;
                                
                                },
                              },
                            },
                          }}
                        />
                      </Col>
                    </Row>
                    <div className="mt-4 d-flex justify-content-end">
                      {canUpgradeQuota() ? (
                        <Button color="warning" className="mr-4" onClick={toggleUpgradeWizard}>Increase Quota</Button>
                      ) : ''}
                      <a
                        className="btn btn-secondary text-white"
                        href="https://vac.hso-group.net:1280/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Veeam Portal
                      </a>
                    </div>
                  </div>
                </Col>
              </Row>
            </Section>
          </Col>
        </Row>
      </SimpleLoader>
      {priceListItem !== null ? (
        <NewServiceOrderItemModal
          priceListItem={priceListItem}
          service={service}
          close={() => setPriceListItem(null)}
          onSubmitted={() => navigate('/orders/new')}
        />
      ) : ''}
    </div>
  );

};

function mapStateToProps({
  user, serviceVeeamBackup, priceList, serviceOrder,
}) {

  return {
    user,
    service: serviceVeeamBackup.data,
    priceList: priceList.data,
    serviceOrder,
  };

}

export default connect(mapStateToProps)(VeeamBackup);
