import React from 'react';

interface IProps {
  cssClass?: string;
  cssClassTop?: string;
  title?: string;
}
const DashboardIcon: React.FC<IProps> = ({ cssClassTop, cssClass, title }) => (
  <svg
    className={cssClassTop || 'cls-1'}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 23 23"
  >
    <title>{title || ''}</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Design">
        <path
          className={cssClass || 'cls-1'}
          d="M19.63,3.37a11.49,11.49,0,0,0-16.26,0h0A11.43,11.43,0,0,0,0,11.5H0a11.43,11.43,0,0,0,2.3,6.9.68.68,0,0,0,.54.27H20.16a.68.68,0,0,0,.54-.27A11.43,11.43,0,0,0,23,11.5h0a11.43,11.43,0,0,0-3.37-8.13Zm.19,14H3.18a10.07,10.07,0,0,1-1.81-5.15h.75a.67.67,0,0,0,0-1.34H1.37a10.13,10.13,0,0,1,2.49-6l.53.52a.67.67,0,1,0,1-1l-.52-.53a10.13,10.13,0,0,1,6-2.49v.75a.67.67,0,0,0,1.34,0V1.37a10.13,10.13,0,0,1,6,2.49l-.52.53a.67.67,0,1,0,.95,1l.53-.52a10.13,10.13,0,0,1,2.49,6h-.75a.67.67,0,0,0,0,1.34h.75a10.07,10.07,0,0,1-1.81,5.15Z"
        />
        <path
          className={cssClass || 'cls-1'}
          d="M15.83,21.65H7.17a.68.68,0,0,0,0,1.35h8.66a.68.68,0,0,0,0-1.35Z"
        />
        <path
          className={cssClass || 'cls-1'}
          d="M15.72,7.28a.66.66,0,0,0-1,0L13,9.07a2.84,2.84,0,1,0,1,1l1.79-1.8a.66.66,0,0,0,0-1ZM11.5,13A1.49,1.49,0,1,1,13,11.5,1.49,1.49,0,0,1,11.5,13Z"
        />
      </g>
    </g>
  </svg>
);
export default DashboardIcon;
