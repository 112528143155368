import React from 'react';
import {
  Col,
} from 'reactstrap';
import { MonitoredDeviceWidget, MonitoredPortWidget } from '../../store/monitoring/types';
import DashMonitoringItemsCombined from '../Widgets/DashMonitoringItemsCombined/index';

type TMonitoringFavourites = {
  favourites: (MonitoredDeviceWidget | MonitoredPortWidget)[];
  deleteFavourite: (id: string, name: string, location: string) => any;
};
function isDevice(widget: MonitoredDeviceWidget | MonitoredPortWidget): widget is MonitoredDeviceWidget {

  return (widget as MonitoredDeviceWidget).latency !== undefined;

}
function isPort(widget: MonitoredDeviceWidget | MonitoredPortWidget): widget is MonitoredPortWidget {

  return (widget as MonitoredPortWidget).inBound !== undefined;

}
function isOffline(widget: MonitoredDeviceWidget | MonitoredPortWidget): widget is MonitoredPortWidget | MonitoredPortWidget {

  return (widget as MonitoredPortWidget | MonitoredPortWidget).available !== true;

}
function isActive(widget: MonitoredDeviceWidget | MonitoredPortWidget): widget is MonitoredPortWidget | MonitoredPortWidget {

  return !!(widget as MonitoredPortWidget | MonitoredPortWidget).favouriteActive;

}
const MonitoringFavourites: React.FC<TMonitoringFavourites> = (props) => {

  const { favourites, deleteFavourite } = props;

  return (

    <>
      {favourites.map((widget, index) => {

        if (!widget.hidden) {

          if (widget.error) {

            return (
              <Col lg={6} md={6} sm={6} className="mb-4 d-flex col-xl-3 col-xxl-3" key={`favourite${index}`}>
                <DashMonitoringItemsCombined WType="hasError" widget={widget} index={index} deleteWidget={deleteFavourite} />
              </Col>
            );
          
          }
          if (!isActive(widget)) {

            return (
              <Col lg={6} md={6} sm={6} xs={12} className="mb-4 d-flex col-xl-3 col-xxl-3" key={`favourite${index}`}>
                <DashMonitoringItemsCombined WType="isInactive" widget={widget} index={index} deleteWidget={deleteFavourite} />
              </Col>
            );
          
          }
          if (isOffline(widget)) {

            return (
              <Col lg={6} md={6} sm={6} xs={12} className="mb-4 d-flex col-xl-3 col-xxl-3" key={`favourite${index}`}>
                <DashMonitoringItemsCombined WType="isOffline" widget={widget} index={index} deleteWidget={deleteFavourite} />

              </Col>
            );
          
          }
          if (isDevice(widget)) {

            return (
              <Col lg={6} md={6} sm={6} xs={12} className="mb-4 d-flex col-xl-3 col-xxl-3" key={`favourite${index}`}>
                <DashMonitoringItemsCombined WType="isDevice" widget={widget} index={index} deleteWidget={deleteFavourite} />
              </Col>
            );
          
          }
          if (isPort(widget)) {

            return (
              <Col key={`favourite${index}`} lg={6} md={6} sm={6} xs={12} className="mb-4 d-flex col-xl-3 col-xxl-3">
                <DashMonitoringItemsCombined index={index} WType="isPort" widget={widget} deleteWidget={deleteFavourite} />
              </Col>
            );
          
          }
        
        }
      
      })}
    </>
  );

};
export default MonitoringFavourites;
