import {
  IServicesState,
  SERVICES_SET_SEARCH_STRING,
  SERVICES_SET_SERVICE_GROUPS,
  SERVICES_SET_SERVICES,
  SERVICES_TOGGLE_LOADING,
  SERVICES_RESET_STORE,
  SERVICES_TOGGLE_FILTER_DROPDOWN_OPEN,
  SERVICES_SET_FILTER_STATE,
  ServicesActionTypes,
  TServiceGroup,
  SERVICES_SET_CUSTOMER_SERVICE_GROUPS,
} from './types';

const initialState: IServicesState = {
  loading: false,
  searchString: '',
  filtered: [],
  services: [],
  serviceGroups: [],
  customerServiceGroups: [],
  visibleState: null,
  openFilterServicesDropdown: false,
};
export function servicesReducer(state = initialState, action: ServicesActionTypes) {

  switch (action.type) {
    case SERVICES_SET_FILTER_STATE:
      return {
        ...state,
        visibleState: action.group,
      };
    case SERVICES_TOGGLE_FILTER_DROPDOWN_OPEN:
      return {
        ...state,
        openFilterServicesDropdown: !state.openFilterServicesDropdown,
      };
    case SERVICES_TOGGLE_LOADING:
      return {
        ...state,
        loading: !state.loading,
      };
    case SERVICES_SET_SEARCH_STRING:
      return {
        ...state,
        searchString: action.search,
      };
    case SERVICES_SET_SERVICES:
      return {
        ...state,
        services: action.services,
      };
    case SERVICES_SET_SERVICE_GROUPS:
      return {
        ...state,
        serviceGroups: action.serviceGroups,
      };
    case SERVICES_SET_CUSTOMER_SERVICE_GROUPS:
      const customerServiceGroups: TServiceGroup[] = [];
      state.serviceGroups.map((group1: TServiceGroup) => {

        state.services.map((group2) => {

          if (group1.id === group2.group && !customerServiceGroups.includes(group1)) {

            customerServiceGroups.push(group1);
          
          }
        
        });
      
      });
      return {
        ...state,
        customerServiceGroups,
      };
    case SERVICES_RESET_STORE:
      return {
        ...initialState,
      };
    default:
      return {
        ...state,
      };
  }

}
