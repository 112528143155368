import React, { useState } from 'react';
import { Alert } from 'reactstrap';

interface IAlert {
  text: string;
  color: string;
}
const DismissableMessage: React.FC<IAlert> = ({ text, color }) => {

  const [visible, setVisible] = useState(true);

  const onDismiss = () => setVisible(false);

  return (
    <Alert color={color} isOpen={visible} toggle={onDismiss}>
      {text}
    </Alert>
  );

};

export default DismissableMessage;
