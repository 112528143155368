/* eslint-disable */
import React, { FunctionComponent, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Routes, Route, Navigate, useLocation, useParams, Outlet } from 'react-router-dom'
import Moment from 'moment'
import momentLocalizer from 'react-widgets-moment'
import { CardTitle, Fade, Nav } from 'reactstrap'
import Sidebar from '../../components/Sidebar'
import Breadcrumb from '../../components/Breadcrumb'
import Aside from '../../components/Aside'
import Footer from '../../components/Footer'
import { GenericNotFound, ForbiddenError, ConnectionError } from '../../components/Errors/Errors'
import Case from '../Case'
import NewCase from '../../views/Cases/NewCase'
import ResetPassword from '../ResetPassword'
import { SpinnerInfo } from '../../components/Spinner/Spinner'
import Domain from '../../views/Services/Domains/Domain'
import DismissableMessage from '../../components/DismissableMessage'
import Dashboard from '../Dashboard'
import Contact from '../Contact'
import Contacts from '../Contacts'
import Services from '../Services/mapper'
import Orders from '../Orders'
import Order from '../Order'
import Cases from '../Cases'
import Monitoring from '../Monitoring'
import Domains from '../Domains'
import Header from '../Header/mapper'
import MonitoredItem from '../MonitoredItem'
import { getAuthStatus, updatePortalSetting } from '../../store/user/actions'
import DNSZone from '../../views/Services/DNS/DNSZone'
import DNS from '../Service/DNS'
import DomainLookup from '../../views/Services/Domains/DomainLookup'
import DomainCheckout from '../DomainCheckout'
import Signatures from '../Signatures'
import { getAccountSummary } from '../../store/account/actions'
import {
    resetSystemError,
    toggleSystemStatusLoading,
    toggleTutorialModal,
    resetSystemForbiddenError,
    resetSystemConnectionError,
} from '../../store/system/actions'
import ErrorGeneric from '../../components/ErrorGeneric'
import Broadband from '../Service/Broadband'
import VeeamBackup from '../Service/VeeamBackup'
import Billing from '../Billing'
import ConfirmationModal from '../../components/Modals/Confirmation'
import ServiceConnectivity from '../Service/Connectivity'
import Pay from '../Pay'
import Tutorial from '../../components/Modals/Tutorial'
import Tutorials from '../Tutorials'
import { createNewCase } from '../../store/case/actions'
import Documents from '../Documents'
import { successFeedback } from '../../actions/feedback'
import { addListeners, authenticateSocket } from '../../store/socketio/actions'
import WeatherMaps from '../WeatherMaps'
import WeatherMap from '../WeatherMap'
import { getAccountContacts } from '../../store/contacts/actions'
import Reports from '../Reports'
import { getAccountPriceList } from '../../store/priceList/actions'
import NewOrder from '../NewOrder'
import { checkPermission } from '../../utils/Auth/AuthService'
import { IUserState } from '../../store/user/types'
import { ISystemState } from '../../store/system/types'
import { Dispatch } from 'redux'
import { AppDispatch } from '../../configureStore'

const jwtDecode = require('jwt-decode')

Moment.locale('en')
momentLocalizer()
// @ts-ignore
CardTitle.defaultProps.tag = 'h5'

type TProps = {
    dispatch: AppDispatch;
    user: IUserState;
    system: ISystemState;
}
const Full: FunctionComponent<TProps> = ({ dispatch, user, system }: TProps): JSX.Element => {

    const [dropdownOpen, setDropdownOpen] = useState<boolean>(false)
    const [data, setData] = useState({ parent_company: {} })
    const [loaded, setLoaded] = useState<boolean>(false)
    const [loggedOut, setLoggedOut] = useState<boolean>(false)

    const location = useLocation()
    const params = useParams()

    useEffect(() => {
        setLoggedOut(false)

        dispatch(getAuthStatus(localStorage.getItem('hso-api-token'))).then(
            (result) => {
                if (result) {
                    dispatch(addListeners(['CONSUMERACTION']))
                    dispatch(authenticateSocket({ jwt: localStorage.getItem('hso-api-token') }))

                    document.title = process.env.REACT_APP_ENV !== 'prod' ? 'hSo:Portal ' + `(${process.env.REACT_APP_ENV})` : 'hSo:Portal'
                    setLoaded(true)
                    dispatch(getAccountSummary()).then(() => {
                        setLoaded(true)
                    })
                    if (checkPermission('contacts.read', user.permissions)) {
                        dispatch(getAccountContacts())
                    }
                    dispatch(getAccountPriceList())
                }


            },
        )

    }, [])

    useEffect(() => {

        onRouteChanged()

    }, [location.pathname])


    const createCaseFromError = (error) => {
        dispatch(toggleSystemStatusLoading())
        dispatch(createNewCase({
            title: 'Customer Portal Error',
            priority: 'medium',
            category: 'non_service_affecting',
            formData: error,
        })).then((result) => {
            if (result) {
                dispatch(successFeedback('Error submitted!'))
            }
            dispatch(toggleSystemStatusLoading())
        })
    }

    const onRouteChanged = (): void => {

        dispatch(resetSystemError())
        dispatch(resetSystemForbiddenError())
        dispatch(resetSystemConnectionError())

    }

    const handlePortalOverviewCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(updatePortalSetting(
            [{ name: 'hideTutorialModal', value: (event.target.checked ? '1' : '0') }],
        ))
    }

    if (!loaded) {
        return (
            <div className='app flex-row align-items-center animated fadeIn'>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <SpinnerInfo />
                    </div>
                </div>
            </div>
        )
    }

    if (!user.username || loggedOut) {

        return <Navigate to='/login' />

    }

    return (
        <div className='app'>
            <Header />
            <div className='app-body'>
                <Sidebar />
                <main className='main'>
                    <Breadcrumb />
                    <div className='container-fluid'>
                        {system.success.isSet &&
                            <Fade tag='h5' className='mt-3'>
                                <DismissableMessage color='success' text={system.success.message} />
                            </Fade>
                        }
                        {system.connectionError ? (
                            <ConnectionError />
                        ) : ''}
                        {system.errorId !== '' && (
                            <ErrorGeneric
                                loading={system.loading}
                                errorEmailSent={system.errorEmailSent}
                                error={`Reference: ${system.errorId}`}
                                createCaseFromError={createCaseFromError}
                            />)}
                        {system.forbiddenError && (
                            <ForbiddenError />)}
                        {system.status === 'OK' && system.errorId === '' && !system.forbiddenError && !system.connectionError && (
                            <Outlet/>
                        )}
                    </div>
                </main>
                <Aside />
            </div>
            <Footer />
            <ConfirmationModal confirmation={system.confirmation} />
            <Tutorial
                isOpen={!system.hideOverviewModal}
                cancel={() => dispatch(toggleTutorialModal())}
                update={handlePortalOverviewCheckbox}
            />
        </div>
    )
}

function mapStateToProps({ system, user }) {

    return {
        system,
        user,
    }

}

export default connect(mapStateToProps)(Full)
