/* eslint-disable */
import React from 'react';
import {PropTypes} from 'prop-types';
import {Card, CardHeader, CardBody, Button, FormGroup, Label, Alert, Row, Col, Input} from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import {DateTimePicker} from 'react-widgets';
import Moment from 'moment';
import momentLocalizer from 'react-widgets-moment';
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import {connect} from 'react-redux';
import {createNewCase} from '../../store/case/actions';
import Section from '../../components/Section';

Moment.locale('en');
momentLocalizer();
const faultOptions = {
  diallingFault: 'I have problems dialling specific numbers',
  inboundFault: "I can't receive inbound calls",
  outboundFault: "I can't make outbound call",
  callQualityFault: "I'm experiencing poor call quality",
  hearingFault: "The caller can hear me or I can't hear the caller",
  groupFault: 'I have Ring/Hunt/Pickup group issues'

};
class VoiceFault extends React.Component{

  static contextTypes = {
    router: PropTypes.shape({
      navigate: PropTypes.func.isRequired,
      staticContext: PropTypes.object
    }).isRequired
  };

  constructor(props) {

    super(props);
    this.state = {
      formData: {},
      faultStartDate: new Date(),
      processing: false,
      error: false
    }
    ;
    this.handleInput = this.handleInput.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDate = this.handleDate.bind(this);
    this.handleFaultType = this.handleFaultType.bind(this);
    this.handleNestedInput = this.handleNestedInput.bind(this);

  }

  handleInput(event, value){

    event.persist();
    this.setState((prevState) => {

      return {
        formData: Object.assign(prevState.formData, {[event.target.id] : {
          question: event.target.parentNode.previousSibling.textContent,
          value
        }})
      };

    });

  }

  handleNestedInput(key, event, value){

    event.persist();
    this.setState((prevState) => {

      return {
        formData: Object.assign(prevState.formData, {
          [key] : Object.assign(prevState.formData[key], {
            [event.target.id] : {
              question: event.target.parentNode.previousSibling.textContent,
              value
            }
          })
        })
      };

    });

  }

  handleDate(date, id, field){

    this.setState((prevState) => {

      return {
        formData: Object.assign(prevState.formData, {
          [id] : {
            question: field,
            value: date
          }
        })
      };

    });

  }

  handleFaultType(event){

    event.persist();
    this.setState((prevState) => {

      return {
        formData: Object.assign(prevState.formData, {
          [event.target.id] : this.state.formData[event.target.id] ? null : {}
        }),
      };

    });

  }

  handleSubmit(){

    if (this.props.partnerCustomer === ''  &&  this.props.isResalePartner) {

      this.props.setCustomerSelectError();
      return false;

    }
    this.setState({processing: true});
    const title = this.state.formData.title.value;
    let theHTML = '<h2>Voice Fault</h2><br>';
    theHTML += `<h6>Title</h6><p>${  title  }</p>`;
    delete this.state.formData.title;

    if(!this.state.formData.faultStartDate){

      theHTML += `<h6>When did the fault start happening?</h6><p>${  this.state.faultStartDate  }</p>`;

    }

    for (const prop in this.state.formData) {

      if(
        !this.state.formData.hasOwnProperty(prop) || faultOptions[prop]
      ) continue;
      theHTML += `<h6>${ this.state.formData[prop].question  }</h6><p>${  this.state.formData[prop].value  }</p>`;

    }

    for (const prop in faultOptions) {

      if(
        !this.state.formData.hasOwnProperty(prop) || !this.state.formData[prop]
      ) continue;
      theHTML += `<h4>${  faultOptions[prop]  }</h4>`;
      const nestedProps = this.state.formData[prop];
      for(const nestedProp in nestedProps){

        if(!nestedProps.hasOwnProperty(nestedProp)) continue;
        theHTML += `<h6>${ nestedProps[nestedProp].question  }</h6><p>${  nestedProps[nestedProp].value  }</p>`;

      }

    }
    this.props.dispatch(createNewCase({
      title,
      priority: this.state.formData.priority.value,
      category: 'service_affecting',
      formData: theHTML,
      partnerCustomerId: this.props.partnerCustomer
    })).then((result) => {

      if(result.caseId){

        this.props.navigate('/cases');

      }
      this.setState({error:true});
      window.scrollTo(0, 0);

    });

  }

  render() {

    return (
            <div>
                {this.state.error &&
                <Alert color="danger">
                    <strong>Oh snap!</strong> Something went seriously wrong with this one!
                </Alert>
                }
                <Section title="Voice Fault">
                    <Row className="mx-0">
                        <Col className="p-4 bg-white rounded">
                        <AvForm onValidSubmit={this.handleSubmit} onInvalidSubmit={this.props.onInvalidSubmit}>
                            <FormGroup>
                                <Label for="title">Title</Label>
                                <AvField type="text" name="title" id="title" placeholder="Title of the case" required onChange={this.handleInput} validate={{maxLength: {value: 200}}}/>
                            </FormGroup>
                            <FormGroup>
                                <Label for="priority">Priority</Label>
                                <AvField type="select" name="priority" id="priority" required onChange={this.handleInput}>
                                    <option>-- Select</option>
                                    <option value="low">Low</option>
                                    <option value="medium">Medium</option>
                                    <option value="high">High</option>
                                </AvField>
                            </FormGroup>
                            <FormGroup className="clearfix">
                                <div>
                                    <Label className="clearfix">When did the fault start happening?</Label>
                                </div>
                                <DateTimePicker step="60" defaultValue={this.state.faultStartDate} format="DD-MM-YYYY hA"  max={new Date()} onChange={(theDate, dateFormatted) => {

                                  this.handleDate(dateFormatted, 'faultStartDate', 'When did the fault start happening?');

                                }}/>
                            </FormGroup>
                            <FormGroup>
                                <Label for="faultInfo">What is the fault?</Label>
                                <AvField type="textarea" rows="6" id="faultInfo" name="faultInfo" required onChange={this.handleInput}/>
                            </FormGroup>
                            <FormGroup>
                                <Label for="faultType">Is the fault constant or intermittent?</Label>
                                <AvField type="select" name="faultType" id="faultType" required onChange={this.handleInput}>
                                    <option>-- Select</option>
                                    <option value="intermittent">Intermittent</option>
                                    <option value="constant">Constant</option>
                                </AvField>
                            </FormGroup>
                            <FormGroup>
                                <label className="form-check text-dark homeage-to-the-square-checkbox">
                                    <Input
                                        checked={this.state.formData.diallingFault}
                                        className="form-check-input"
                                        type="checkbox" id="diallingFault"
                                        onChange={this.handleFaultType}/>
                                    <span className="checkmark" />
                                    <label className="form-check-label" htmlFor="diallingFault">{faultOptions.diallingFault}</label>
                                </label>
                            </FormGroup>
                            {this.state.formData.diallingFault && <DiallingFault handleInput={this.handleNestedInput.bind(null, 'diallingFault')}/>}
                            <FormGroup>
                                <label className="form-check text-dark homeage-to-the-square-checkbox">
                                    <Input
                                        checked={this.state.formData.inboundFault}
                                        className="form-check-input"
                                        type="checkbox" id="inboundFault"
                                        onChange={this.handleFaultType}/>
                                    <span className="checkmark" />
                                    <label className="form-check-label" htmlFor="inboundFault">{faultOptions.inboundFault}</label>
                                </label>
                            </FormGroup>
                            {this.state.formData.inboundFault && <InboundFault handleInput={this.handleNestedInput.bind(null, 'inboundFault')}/>}
                            <FormGroup>
                                <label className="form-check text-dark homeage-to-the-square-checkbox">
                                    <Input
                                        checked={this.state.formData.outboundFault}
                                        className="form-check-input"
                                        type="checkbox" id="outboundFault"
                                        onChange={this.handleFaultType}/>
                                    <span className="checkmark" />
                                    <label className="form-check-label" htmlFor="outboundFault">{faultOptions.outboundFault}</label>
                                </label>
                            </FormGroup>
                            {this.state.formData.outboundFault && <OutboundFault handleInput={this.handleNestedInput.bind(null, 'outboundFault')}/>}
                            <FormGroup>
                                <label className="form-check text-dark homeage-to-the-square-checkbox">
                                    <Input
                                        checked={this.state.formData.callQualityFault}
                                        className="form-check-input"
                                        type="checkbox" id="callQualityFault"
                                        onChange={this.handleFaultType}/>
                                    <span className="checkmark" />
                                    <label className="form-check-label" htmlFor="callQualityFault">{faultOptions.callQualityFault}</label>
                                </label>
                            </FormGroup>
                            {this.state.formData.callQualityFault && <CallQualityFault handleInput={this.handleNestedInput.bind(null, 'callQualityFault')}/>}
                            <FormGroup>
                                <label className="form-check text-dark homeage-to-the-square-checkbox">
                                    <Input
                                        checked={this.state.formData.hearingFault}
                                        className="form-check-input"
                                        type="checkbox" id="hearingFault"
                                        onChange={this.handleFaultType}/>
                                    <span className="checkmark" />
                                    <label className="form-check-label" htmlFor="hearingFault">{faultOptions.hearingFault}</label>
                                </label>
                            </FormGroup>
                            {this.state.formData.hearingFault && <HearingFault handleInput={this.handleNestedInput.bind(null, 'hearingFault')}/>}
                            <FormGroup>
                                <label className="form-check text-dark homeage-to-the-square-checkbox">
                                    <Input
                                        checked={this.state.formData.groupFault}
                                        className="form-check-input"
                                        type="checkbox" id="groupFault"
                                        onChange={this.handleFaultType}/>
                                    <span className="checkmark" />
                                    <label className="form-check-label" htmlFor="groupFault">{faultOptions.groupFault}</label>
                                </label>
                            </FormGroup>
                            {this.state.formData.groupFault && <GroupFault handleInput={this.handleNestedInput.bind(null, 'groupFault')}/>}
                            <div className="clearfix" style={{marginTop: `${10  }px`, marginBottom: `${20  }px`}}>
                                {/* <Button type="submit" color="primary" className="float-right" style={{minWidth: 113 + 'px', minHeight: 35 + 'px'}}
                                        disabled={this.state.processing}>{this.state.processing ?
                                    <div style={{marginTop: 2 + 'px'}}><Loader color="#ffffff" size="8px" margin="0px"/></div> : "Create Case"}
                                    </Button> */}

                                <LaddaButton
                                    style={{minWidth: `${113}px`, minHeight: `${35}px`}}
                                    className="btn btn-primary btn-ladda px-4 float-right"
                                    disabled={this.state.processing}
                                    loading={this.state.processing}
                                    data-color="blue"
                                    data-style={ZOOM_OUT}
                                >
                                    Create Case
                                </LaddaButton>
                            </div>
                        </AvForm>
                        </Col>
                    </Row>
                </Section>
            </div>

    );

  }

}


const DiallingFault = (props) => {

  return (
        <div className="animated fadeIn">
            <FormGroup>
                <Label for="dialling_CallsAffected">Which calls are affected?</Label>
                <AvField type="select" name="dialling_CallsAffected" id="dialling_CallsAffected" required onChange={props.handleInput}>
                    <option>-- Select</option>
                    <option value="internal">Internal</option>
                    <option value="external">External</option>
                    <option value="all">All</option>
                </AvField>
            </FormGroup>
            <FormGroup>
                <Label for="dialling_Extensions">From which extension(s)?</Label>
                <AvField type="textarea" rows="6" id="dialling_Extensions" name="dialling_Extensions" onChange={props.handleInput}/>
            </FormGroup>
            <FormGroup>
                <Label for="dialling_Numbers">Which number/s cannot be dialled?</Label>
                <AvField type="textarea" rows="6" id="dialling_Numbers" name="dialling_Numbers" onChange={props.handleInput}/>
            </FormGroup>
            <FormGroup>
                <Label for="dialling_Attempts">How many attempts have been tried?</Label>
                <AvField type="text" id="dialling_Attempts" name="dialling_Attempts" onChange={props.handleInput}/>
            </FormGroup>
            <FormGroup>
                <Label for="dialling_Timestamps">Time Stamps</Label>
                <AvField type="textarea" rows="6" id="dialling_Timestamps" name="dialling_Timestamps" placeholder="source, destination, time and how long did the call last" onChange={props.handleInput}/>
            </FormGroup>
        </div>
  );

};

const InboundFault = (props) => {

  return (
        <div>
            <FormGroup>
                <Label for="inbound_ToExtensions">To which extension(s)?</Label>
                <AvField type="textarea" rows="6" id="inbound_ToExtensions" name="inbound_ToExtensions" onChange={props.handleInput}/>
            </FormGroup>
            <FormGroup>
                <Label for="inbound_InternalCalls">Can you get internal calls OK?</Label>
                <AvField type="select" name="inbound_InternalCalls" id="inbound_InternalCalls" onChange={props.handleInput}>
                    <option>-- Select</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                </AvField>
            </FormGroup>
            <FormGroup>
                <Label for="inbound_OutboundCalls">Can outbound calls be made?</Label>
                <AvField type="select" name="inbound_OutboundCalls" id="inbound_OutboundCalls" onChange={props.handleInput}>
                    <option>-- Select</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                </AvField>
            </FormGroup>
            <FormGroup>
                <Label for="inbound_Attempts">Do you know what the calling party is experiencing?</Label>
                <AvField type="text" id="inbound_Attempts" name="inbound_Attempts" onChange={props.handleInput} placeholder="busy, signal, engaged etc"/>
            </FormGroup>
            <FormGroup>
                <Label for="inbound_LandlineMobile">Is the problem experienced from landlines and mobiles?</Label>
                <AvField type="select" name="inbound_LandlineMobile" id="inbound_LandlineMobile" onChange={props.handleInput}>
                    <option>-- Select</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                </AvField>
            </FormGroup>
            <FormGroup>
                <Label for="inbound_Timestamps">Time Stamps</Label>
                <AvField type="textarea" rows="6" id="inbound_Timestamps" name="inbound_Timestamps" onChange={props.handleInput} placeholder="source, destination, time and how long did the call last" />
            </FormGroup>
        </div>
  );

};

const OutboundFault = (props) => {

  return (
        <div>
            <FormGroup>
                <Label for="outbound_ToExtensions">From which extension(s)?</Label>
                <AvField type="textarea" rows="6" id="outbound_ToExtensions" name="outbound_ToExtensions" onChange={props.handleInput}/>
            </FormGroup>
            <FormGroup>
                <Label for="outbound_InternalCalls">Can internal numbers be dialled OK?</Label>
                <AvField type="select" name="outbound_InternalCalls" id="outbound_InternalCalls" onChange={props.handleInput}>
                    <option>-- Select</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                </AvField>
            </FormGroup>
            <FormGroup>
                <Label for="outbound_InboundCalls">Can inbound calls be received?</Label>
                <AvField type="select" name="outbound_InboundCalls" id="outbound_InboundCalls" onChange={props.handleInput}>
                    <option>-- Select</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                </AvField>
            </FormGroup>
            <FormGroup>
                <Label for="outbound_Attempts">How far does the call get before failing?</Label>
                <AvField type="text" id="outbound_Attempts" name="outbound_Attempts" onChange={props.handleInput} placeholder="busy, signal, engaged etc"/>
            </FormGroup>
            <FormGroup>
                <Label for="outbound_Timestamps">Time Stamps</Label>
                <AvField type="textarea" rows="6" id="outbound_Timestamps" name="outbound_Timestamps" onChange={props.handleInput} placeholder="source, destination, time and how long did the call last" />
            </FormGroup>
            <FormGroup>
                <Label for="outbound_AffectedCalls">Are calls to all numbers affected? <small>local, national, mobiles etc</small></Label>
                <AvField type="select" name="outbound_AffectedCalls" id="outbound_AffectedCalls" onChange={props.handleInput}>
                    <option>-- Select</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                </AvField>
            </FormGroup>
        </div>
  );

};

const CallQualityFault = (props) => {

  return (
        <div>
            <FormGroup>
                <Label for="quality_InboundCalls">Can inbound calls be received?</Label>
                <AvField type="select" name="quality_InboundCalls" id="quality_InboundCalls" onChange={props.handleInput}>
                    <option>-- Select</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                </AvField>
            </FormGroup>
            <FormGroup>
                <Label for="quality_Extensions">Extension number/s affected?</Label>
                <AvField type="textarea" rows="6" id="quality_Extensions" name="quality_Extensions" onChange={props.handleInput}/>
            </FormGroup>
            <FormGroup>
                <Label for="quality_heard">What is heard on the call?</Label>
                <AvField type="textarea" rows="6" id="quality_heard" name="quality_heard" placeholder="crackling, hissing, static etc" onChange={props.handleInput}/>
            </FormGroup>
            <FormGroup>
                <Label for="quality_all">Are all calls of poor quality?</Label>
                <AvField type="select" name="quality_all" id="quality_all" onChange={props.handleInput}>
                    <option>-- Select</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                </AvField>
            </FormGroup>
            <FormGroup>
                <Label for="quality_affected">Which calls are affected?</Label>
                <AvField type="select" name="quality_affected" id="quality_affected" onChange={props.handleInput}>
                    <option>-- Select</option>
                    <option value="all">All</option>
                    <option value="inbound">Inbound</option>
                    <option value="outbound">Outbound</option>
                </AvField>
            </FormGroup>
            <FormGroup>
                <Label for="quality_headset">Have connections to the handset been checked?</Label>
                <AvField type="select" name="quality_headset" id="quality_headset" onChange={props.handleInput}>
                    <option>-- Select</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                </AvField>
            </FormGroup>
            <FormGroup>
                <Label for="quality_reboot">Has the device (handset/computer) been rebooted?</Label>
                <AvField type="select" name="quality_reboot" id="quality_reboot" onChange={props.handleInput}>
                    <option>-- Select</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                </AvField>
            </FormGroup>
            <FormGroup>
                <Label for="quality_Timestamps">Time Stamps</Label>
                <AvField type="textarea" rows="6" id="quality_Timestamps" name="quality_Timestamps" onChange={props.handleInput} placeholder="source, destination, time and how long did the call last" />
            </FormGroup>
        </div>
  );

};

const HearingFault = (props) => {

  return (
        <div>
            <FormGroup>
                <Label for="hearing_all">Which calls are affected?</Label>
                <AvField type="select" name="hearing_all" id="hearing_all" onChange={props.handleInput}>
                    <option value="">-- Select</option>
                    <option value="all">All</option>
                    <option value="internal">Internal</option>
                    <option value="external">External</option>
                </AvField>
            </FormGroup>
            <FormGroup>
                <Label for="hearing_direction">Direction of call?</Label>
                <AvField type="select" name="hearing_direction" id="hearing_direction" onChange={props.handleInput}>
                    <option value="">-- Select</option>
                    <option value="all">All</option>
                    <option value="inbound">Inbound</option>
                    <option value="outbound">Outbound</option>
                </AvField>
            </FormGroup>
            <FormGroup>
                <Label for="hearing_recipient">Can the call originator hear the recipient?</Label>
                <AvField type="select" name="hearing_recipient" id="hearing_recipient" onChange={props.handleInput}>
                    <option value="">-- Select</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                </AvField>
            </FormGroup>
            <FormGroup>
                <Label for="hearing_originator">Can the call recipient hear the originator?</Label>
                <AvField type="select" name="hearing_originator" id="hearing_originator" onChange={props.handleInput}>
                    <option>-- Select</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                </AvField>
            </FormGroup>
            <FormGroup>
                <Label for="hearing_extension">Extension number/s affected?</Label>
                <AvField type="textarea" rows="6" id="hearing_extension" name="hearing_extension" onChange={props.handleInput}/>
            </FormGroup>
            <FormGroup>
                <Label for="hearing_Timestamps">Time Stamps</Label>
                <AvField type="textarea" rows="6" id="hearing_Timestamps" name="hearing_Timestamps" onChange={props.handleInput} placeholder="source, destination, time and how long did the call last" />
            </FormGroup>
        </div>
  );

};

const GroupFault = (props) => {

  return (
        <div>
            <FormGroup>
                <Label for="group_extension">What extension has the issue?</Label>
                <AvField type="textarea" rows="6" id="group_extension" name="group_extension" onChange={props.handleInput}/>
            </FormGroup>
            <FormGroup>
                <Label for="group_should">Which Group should they be in?</Label>
                <AvField type="textarea" rows="6" id="group_should" name="group_should" onChange={props.handleInput}/>
            </FormGroup>
            <FormGroup>
                <Label for="group_similar">Who has a similar setup which works?</Label>
                <AvField type="textarea" rows="6" id="group_similar" name="group_similar" onChange={props.handleInput}/>
            </FormGroup>
        </div>
  );

};


function mapStateToProps ({ user }) {

  return {
    user
  };

}
export default connect(mapStateToProps)(VoiceFault);
