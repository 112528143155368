import { IPriceListState, PRICE_LIST_SET_PRICE_LIST, PriceListActionTypes } from './types';

export const initialState: IPriceListState = {
  data: null,
};
export function priceListReducer(state: IPriceListState = initialState, action: PriceListActionTypes) {

  switch (action.type) {
    case PRICE_LIST_SET_PRICE_LIST:
      return {
        ...state,
        data: action.data,
      };
    default:
      return state;
  }

}
