import moment from 'moment';
import {
  IUserState,
  USER_ADD_FAVOURITE,
  USER_REMOVE_FAVOURITE,
  USER_SET_DATA,
  USER_UPDATE_FAVOURITE_ACCOUNT,
  UserActionTypes,
} from './types';
import { initialState as contactInitialState } from '../contact/reducers';
import { MONITORED_ITEM_DESC_SAVE, MONITORED_ITEM_REVERT_DESC } from '../monitoredItem/types';

const initialState: IUserState = {
  username: '',
  email: '',
  lastLogin: '',
  active: false,
  role: 0,
  permissions: [],
  contact: { ...contactInitialState.data },
  favourites: [],
  authentication: {
    token: '',
    refresh: '',
    loggedOut: false,
  },
  settings: {
    hideTutorialModal: true,
  },
};

export function userReducer(state = initialState, action: UserActionTypes) {

  switch (action.type) {
    case MONITORED_ITEM_DESC_SAVE: {

      const { favourites } = state;
      const newFavourites = favourites.map((favourite) => {

        if (favourites.length > 0) {

          if (action.deviceId === favourite.itemId) {

            return { ...favourite, description: action.description, tempDesc: favourite.description };
          
          }
        
        }
        return favourite;
      
      });
      return {
        ...state,
        favourites: [...newFavourites],
      };
    
    }
    case MONITORED_ITEM_REVERT_DESC: {

      const { favourites } = state;
      const newFavourites = favourites.map((favourite) => {

        if (favourites.length > 0) {

          if (action.deviceId === favourite.itemId) {

            return { ...favourite, description: favourite.tempDesc || '' };
          
          }
        
        }
        return favourite;
      
      });
      return {
        ...state,
        favourites: [...newFavourites],
      };
    
    }

    case USER_SET_DATA:
      return {
        ...state,
        ...action.data,
      };
    case USER_REMOVE_FAVOURITE:
      const newFavourites = state.favourites.filter(((fav) => fav.itemId !== action.itemId));
      return {
        ...state,
        favourites: [...newFavourites],
      };
    case USER_ADD_FAVOURITE:
      const { itemId, itemName: name, itemLocation: location } = action;
      return {
        ...state,
        favourites: [...state.favourites, {
          itemId, name, location, active: true, createdAt: moment().format(),
        }],
      };
    case USER_UPDATE_FAVOURITE_ACCOUNT:
      const favourites = state.favourites.map((favorite) => {

        if (favorite.itemId === action.itemId) {

          return { ...favorite, accountId: action.accountId };
        
        }
        return favorite;
      
      });
      return {
        ...state,
        favourites,
      };
    default:
      return state;
  }

}
