import { FunctionComponent } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import * as React from 'react';
import { IFormError } from '../../Forms/types';

type Props = {
  label: string;
  options: Array<ICheckBoxOption>;
  readOnly: boolean;
  horizontal: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => any;
  error?: IFormError;
};

export interface ICheckBoxOption {
  id: string | number;
  name: string | number;
  checked: boolean;
}

const CheckBoxList: FunctionComponent<Props> = (props) => {

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    if (props.onChange) {

      props.onChange(event);
    
    }
  
  };

  return (
    <FormGroup className="row">
      <Label className="col-md-4 text-light font-weight-bold" for={props.label} style={{ fontSize: '16px' }}>{props.label}</Label>
      <div className="col-md-8">
        {!props.readOnly && props.horizontal && props.options.map((option, index) => {

          const id = `${option.id}`;
          return (
            <label className="text-light homeage-to-the-square-checkbox" key={`${option.id}${index}`}>
              <input checked={option.checked} className="form-check-input" type="checkbox" id={id} onChange={handleChange} />
              <span className="checkmark" />
              <label className="form-check-label" htmlFor={`${option.id}${index}`}>{option.name}</label>
            </label>
          );
        
        })}
        {!props.readOnly && !props.horizontal && props.options.map((option, index) => {

          const id = `${option.id}`;
          return (
            <label className="form-check text-light homeage-to-the-square-checkbox" key={`${option.id}${index}`}>
              <Input
                checked={option.checked}
                className={`form-check-input${props.error ? ' is-invalid' : ''}`}
                type="checkbox"
                id={id}
                onChange={handleChange}
              />
              <span className="checkmark" />
              <label className="form-check-label" htmlFor={`${option.id}${index}`}>{option.name}</label>
            </label>
          );
        
        })}
        {props.readOnly && props.horizontal
                    && (
                    <div className="d-flex flex-row justify-content-start flex-wrap">
                      {props.readOnly && props.options.map((option, index) => {

                        if (option.checked) {

                          return <div key={props.label + index} className="form-control border border-info pl-3 pr-3 pt-2 text-info mr-2 mb-2" style={{ borderRadius: '5px', width: 'auto', whiteSpace: 'nowrap' }}>{option.name}</div>;
                        
                        }
                      
                      })}
                    </div>
                    )}
        {props.readOnly && !props.horizontal
                    && (
                    <div className="d-flex flex-column">
                      {props.readOnly && props.options.map((option, index) => {

                        if (option.checked) {

                          return <p style={{ fontSize: '18px' }} key={props.label + index} className="form-control border-0 text-light m-0 pl-0">{option.name}</p>;
                        
                        }
                      
                      })}
                    </div>
                    )}
        {props.error
                  && (
                  <div className="text-danger">
                    {props.error.message}
                  </div>
                  )}
      </div>
    </FormGroup>
  );

};

export default CheckBoxList;
