export const ADDJTISUB = 'ADDJTISUB';
export const SAVEJTISUBS = 'SAVEJTISUBS';
export const DELJTISUB = 'DELJTISUB';
export const DELALLJTISUBS = 'DELALLJTISUBS';
export const RESETJTIDATA = 'RESETJTIDATA';

export const JTISTATUS = 'JTISTATUS';
export const JTIINGRESS = 'JTIINGRESS';
export const JTIEGRESS = 'JTIEGRESS';
export const JTIINGRESSERRORS = 'JTIINGRESSERRORS';
export const JTIEGRESSERRORS = 'JTIEGRESSERRORS';
