import {
  CASE_ASSIGN_ACTIVITY_IS_OPEN_FIELDS,
  CASE_ESCALATE_CASE,
  CASE_ESCALATION_TOGGLE_MODAL,
  CASE_GET_CASE,
  CASE_RESOLVE_CASE,
  CASE_SET_ACTIVITY,
  CASE_SET_DESC_ERROR,
  CASE_SET_ERRORS,
  CASE_SET_ESCALATION_MODAL_ERROR_TEXT,
  CASE_SET_REPLY_PROCESSING,
  CASE_SET_REPLY_VALUE,
  CASE_SET_RESOLVE_MODAL_ERROR_TEXT,
  CASE_SET_SHOW_REPLY_FORM,
  CASE_TOGGLE_ACTIVITY_CONTENT_LOADING,
  CASE_TOGGLE_ACTIVITY_IS_OPEN,
  CASE_TOGGLE_ESCALATION_MODAL_LOADING,
  CASE_TOGGLE_LOADING,
  CASE_TOGGLE_MODAL,
  CASE_TOGGLE_MODAL_LOADING,
  CASE_UPDATE_ESCALATION_NOTE,
  CASE_UPDATE_RESOLVE_REASON,
  CASE_UPDATE_RESOLVE_REASON_DROPDOWN,
  CaseActionTypes,
  ICaseState,
  TOGGLE_CASE_REASON_DROPDOWN,
} from './types';

export const initialState: ICaseState = {
  loading: false,
  readOnly: true,
  showReplyForm: false,
  replyValue: '',
  descError: false,
  replyProcessing: false,
  reasonDropdownOpen: false,
  caseModalLoading: false,
  escalationcaseModalLoading: false,

  data: {
    caseId: '',
    id: '',
    ticketNumber: '',
    title: '',
    status: '',
    state: '',
    escalationState: '',
    createdOn: new Date(),
    modifiedOn: new Date(),
    origin: '',
    priority: '',
    openedBy: '',
    activities: [],
    assignedTo: '',
    resolveReason: '',
    escalationNote: '',
    modalOpen: false,
    escalationModalOpen: false,
    resolveModalErrorText: '',
    escalateModalErrorText: '',
    resolveReasonDropdown: 0,
    caseWorker: {
      firstName: '',
      lastName: '',
      name: '',
      email: '',
      phone: null,
    },
  },
  errors: [],
};
export function caseReducer(state = initialState, action: CaseActionTypes) {

  switch (action.type) {
    case CASE_TOGGLE_MODAL_LOADING: {

      return {
        ...state,
        caseModalLoading: !state.caseModalLoading,

      };
    
    }

    case CASE_TOGGLE_ESCALATION_MODAL_LOADING: {

      return {
        ...state,
        escalationcaseModalLoading: !state.escalationcaseModalLoading,
      };
    
    }
    case CASE_RESOLVE_CASE: {

      return {
        ...state,
        data: {
          ...state.data, state: 'Resolved',
        },
      };
    
    }

    case CASE_ESCALATE_CASE: {

      return {
        ...state,
        data: {
          ...state.data, escalationState: 'Escalated',
        },
      };
    
    }
    case TOGGLE_CASE_REASON_DROPDOWN:

      return {
        ...state,
        reasonDropdownOpen: !state.reasonDropdownOpen,
      };

    case CASE_SET_RESOLVE_MODAL_ERROR_TEXT:
      return {
        ...state,
        data: {
          ...state.data, resolveModalErrorText: action.payload,
        },
      };

    case CASE_SET_ESCALATION_MODAL_ERROR_TEXT:
      return {
        ...state,
        data: {
          ...state.data, escalateModalErrorText: action.payload,
        },
      };
    case CASE_TOGGLE_MODAL:
      return {
        ...state,
        data: {
          ...state.data, modalOpen: !state.data.modalOpen, resolveModalErrorText: '', resolveReason: '',
        },
      };
    case CASE_ESCALATION_TOGGLE_MODAL:
      return {
        ...state,
        data: {
          ...state.data, escalationModalOpen: !state.data.escalationModalOpen, escalationNote: '', escalateModalErrorText: '',
        },
      };
    case CASE_UPDATE_RESOLVE_REASON:
      return {
        ...state,
        data: { ...state.data, resolveReason: action.payload, resolveModalErrorText: '' },
      };
    case CASE_UPDATE_ESCALATION_NOTE:
      return {
        ...state,
        data: { ...state.data, escalationNote: action.payload, escalateModalErrorText: '' },
      };
    case CASE_UPDATE_RESOLVE_REASON_DROPDOWN:
      return {
        ...state,
        data: { ...state.data, resolveReasonDropdown: action.payload, resolveModalErrorText: '' },
      };
    case CASE_GET_CASE:
      return {
        ...state,
        data: action.data,
        dataOriginal: action.data,
      };
    case CASE_SET_ERRORS:
      return {
        ...state,
        errors: [...action.errors],
      };
    case CASE_TOGGLE_LOADING:
      return {
        ...state,
        loading: !state.loading,
      };
    case CASE_ASSIGN_ACTIVITY_IS_OPEN_FIELDS:
      return {
        ...state,
        data: { ...state.data, activities: state.data.activities.map((activity) => ({ ...activity, isOpen: false })) },
      };
    case CASE_TOGGLE_ACTIVITY_IS_OPEN:
      const { data } = state;
      const { activities } = data;
      const { id } = action;
      const updatedActivities = activities.map((activity) => {

        if (activity.id === id) {

          return { ...activity, isOpen: !activity.isOpen };
        
        }

        return activity;
      
      });
      return {
        ...state,
        data: { ...state.data, activities: [...updatedActivities] },
      };
    case CASE_TOGGLE_ACTIVITY_CONTENT_LOADING:
      return {
        ...state,
        data: {
          ...state.data,
          activities: state.data.activities.map((activity) => {

            if (activity.id === action.id) {

              return { ...activity, contentLoading: !activity.contentLoading };
            
            }

            return activity;
          
          }),
        },
      };
    case CASE_SET_SHOW_REPLY_FORM:
      return {
        ...state, showReplyForm: !state.showReplyForm,
      };
    case CASE_SET_REPLY_VALUE:
      return {
        ...state, replyValue: action.value,
      };
    case CASE_SET_DESC_ERROR:
      return {
        ...state, descError: action.payload,
      };
    case CASE_SET_REPLY_PROCESSING:
      return {
        ...state,
        replyProcessing: action.payload,
      };
    case CASE_SET_ACTIVITY:
      return {
        ...state,
        data: {
          ...state.data,
          activities: state.data.activities.map((activity) => {

            if (activity.id === action.data.id) {

              return { ...activity, ...action.data };
            
            }

            return activity;
          
          }),
        },
      };
    default:
      return state;
  }

}
