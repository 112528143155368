import { Dispatch } from 'react';

export const WEATHERMAP_SUBSCRIBE = 'WEATHERMAP_SUBSCRIBE';
export const WEATHERMAP_STOP = 'WEATHERMAP_STOP';
export const WEATHERMAP_SET_WEATHERMAP = 'WEATHERMAP_SET_WEATHERMAP';
export const WEATHERMAP_RESET_WEATHERMAP = 'WEATHERMAP_RESET_WEATHERMAP';
export const WEATHERMAP_SET_TRAFFIC = 'WEATHERMAP_SET_TRAFFIC';
export const WEATHERMAP_SET_EDGE = 'WEATHERMAP_SET_EDGE';
export const WEATHERMAP_SET_EDGE_HISTORY = 'WEATHERMAP_SET_EDGE_HISTORY';

export type TWeatherMapTraffic = {
  [path: string]: number;
};
export type TWeatherMapEdgeHistory = {
  label: 'in' | 'out';
  data: number[][];
}[];

export interface IWeatherMapState {
  weatherMap: IWeatherMap;
  traffic: TWeatherMapTraffic;
  timestamp: string;
  edge: Partial<IWeatherMapEdge>;
}

export interface IWeatherMapNode {
  id: string;
  label_dx?: string;
  label_dy?: string;
  label_position: string;
  name: string;
  type: string;
  x: string;
  y: string;
  monitoredItemId: string | null;
  location: string;
}

type NodesList = IWeatherMapNode[];

export interface IWeatherMapEdge {
  source: string;
  target: string;
  capacity: string;
  source_int: string;
  data_source: string;
  source_name?: string;
  target_name?: string;
  history?: TWeatherMapEdgeHistory;
}

type TEdgesList = IWeatherMapEdge[];

export interface IWeatherMap {
  id: string;
  name: string;
  description: string;
  nodes: NodesList;
  edges: TEdgesList;
}

export interface IWeatherMapSubscribeWeatherMapAction {
  type: typeof WEATHERMAP_SUBSCRIBE;
  payload: {
    mapId: string;
  };
}

export interface IWeatherMapUnsubscribeWeatherMapAction {
  type: typeof WEATHERMAP_STOP;
}

export interface IWeatherMapSetWeatherMapAction {
  type: typeof WEATHERMAP_SET_WEATHERMAP;
  data: IWeatherMap;
}

export interface IWeatherMapResetWeatherMapAction {
  type: typeof WEATHERMAP_RESET_WEATHERMAP;
}

export interface IWeatherMapSetWeatherMapTrafficAction {
  type: typeof WEATHERMAP_SET_TRAFFIC;
  traffic: TWeatherMapTraffic;
  timestamp: string;
}

export interface IWeatherMapSetWeatherMapEdgeAction {
  type: typeof WEATHERMAP_SET_EDGE;
  edge: Partial<IWeatherMapEdge>;
}

export interface IWeatherMapSetWeatherMapEdgeHistoryAction {
  type: typeof WEATHERMAP_SET_EDGE_HISTORY;
  history: TWeatherMapEdgeHistory;
}

export type TWeatherMapSubscribeWeatherMap = (mapId: string) => IWeatherMapSubscribeWeatherMapAction;

export type TWeatherMapUnsubscribeWeatherMap = () => IWeatherMapUnsubscribeWeatherMapAction;

export type TWeatherMapGetWeatherMap = (id: string) => (dispatch: Dispatch<any>) => Promise<IWeatherMap | null>;

export type TWeatherMapSetWeatherMap = (data: IWeatherMap) => IWeatherMapSetWeatherMapAction;

export type TWeatherMapResetWeatherMap = () => IWeatherMapResetWeatherMapAction;

export type TWeatherMapGetWeatherMapTraffic = (id: string) => (dispatch: Dispatch<any>) => Promise<boolean>;

export type TWeatherMapSetWeatherMapTraffic = (traffic: TWeatherMapTraffic, timestamp: string) => IWeatherMapSetWeatherMapTrafficAction;

export type TWeatherMapGetWeatherMapEdge = (id: string, edgeId: string) => (dispatch: Dispatch<any>) => Promise<boolean>;

export type TWeatherMapSetWeatherMapEdge = (edge: Partial<IWeatherMapEdge>) => IWeatherMapSetWeatherMapEdgeAction;

export type TWeatherMapSetWeatherMapEdgeHistory = (history: TWeatherMapEdgeHistory) => IWeatherMapSetWeatherMapEdgeHistoryAction;

export type TWeatherMapActionTypes =
  IWeatherMapSetWeatherMapAction
  | IWeatherMapSetWeatherMapTrafficAction
  | IWeatherMapSetWeatherMapEdgeAction
  | IWeatherMapSetWeatherMapEdgeHistoryAction
  | IWeatherMapResetWeatherMapAction;
