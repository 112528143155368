import {
  Button, Modal, ModalBody, ModalFooter, ModalHeader, FormGroup, Label, Input, Form, DropdownItem, DropdownMenu, DropdownToggle, ButtonDropdown,
} from 'reactstrap';
import React from 'react';
import Section from '../../Section';
import SimpleLoader from '../../SimpleLoader';

type TOwnProps = {

  isOpen: boolean;
  caseModalLoading: boolean;
  cancel: () => any;
  proceed: () => any;
  changeHandler: ((event: React.ChangeEvent<HTMLInputElement>) => void) | undefined;
  onResolutionTypeChange: (payload: string | number) => any;
  resolveReasonValue: string;
  error: string;
  reasonDropdownOpen: boolean;
  toggleReasonDropdownOpen: () => any;
  resolveReasonDropdown: number;
};
const ResolveCaseModal: React.FC<TOwnProps> = (props) => {

  const {
    isOpen, cancel, proceed, changeHandler, resolveReasonValue, onResolutionTypeChange, error, reasonDropdownOpen, toggleReasonDropdownOpen, resolveReasonDropdown, caseModalLoading,
  } = props;
  const handleCancel = () => {

    cancel();
  
  };
  const handleProceed = () => {

    proceed();
  
  };
  const categories = {
    200021: 'Environmental', 200027: 'Hardware', 5: 'Operational error', 200028: 'Software/Config',
  };

  return (
    <Modal isOpen={isOpen} toggle={handleCancel} className="modal-warning" backdrop="static">
      <SimpleLoader loading={caseModalLoading}>
        <ModalBody>
          <Section title="Resolve case">
            <Form>
              Please select the category that best describes your case.
              <div>
                <ButtonDropdown className="my-2" isOpen={reasonDropdownOpen} toggle={() => toggleReasonDropdownOpen()}>
                  <DropdownToggle caret color="primary">
                    <span className="d-inline-block" style={{ width: '120px' }}>
                      {resolveReasonDropdown > 0 ? categories[resolveReasonDropdown] : 'Select category'}
                    </span>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={() => {

                      onResolutionTypeChange(200021);
                    
                    }}
                    >
                      Environmental
                    </DropdownItem>
                    <DropdownItem onClick={() => {

                      onResolutionTypeChange(200027);
                    
                    }}
                    >
                      Hardware
                    </DropdownItem>
                    <DropdownItem onClick={() => {

                      onResolutionTypeChange(5);
                    
                    }}
                    >
                      Operational error
                    </DropdownItem>
                    <DropdownItem onClick={() => {

                      onResolutionTypeChange(200028);
                    
                    }}
                    >
                      Software/Config
                    </DropdownItem>
                  </DropdownMenu>
                </ButtonDropdown>
              </div>
              <FormGroup>
                <Label for="resolveCase">Please describe how the case is resolved</Label>
                <Input type="textarea" name="text" id="resolveCase" value={resolveReasonValue} onChange={changeHandler} />
                <p className="text-danger">{error}</p>
              </FormGroup>
            </Form>
          </Section>
        </ModalBody>
        <ModalFooter className="border-top-0">
          <Button color="warning" onClick={handleCancel}>Cancel</Button>
          <Button color="primary" className="text-white" onClick={handleProceed}>Resolve case</Button>
          {' '}
        </ModalFooter>
      </SimpleLoader>
    </Modal>
  );

};
export default ResolveCaseModal;
