import { REMOVE_FROM_DOMAIN_CART, ADD_TO_DOMAIN_CART, UPDATE_DOMAIN_CART, RESET_DOMAIN_CART } from '../actions/domainCart';

export default function domainCart(state = {}, action) {

  const newState = { ...state };
  switch (action.type) {
    case ADD_TO_DOMAIN_CART:
      newState[action.item.id] = action.item;
      return newState;
    case RESET_DOMAIN_CART:
      return {};
    case REMOVE_FROM_DOMAIN_CART:
      delete newState[action.id];
      return newState;
    case UPDATE_DOMAIN_CART:
      newState[action.id] = {
        ...newState[action.id],
        [action.attr]: action.value,
      };
      return newState;
    default:
      return state;
  }

}
