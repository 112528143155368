import React, { FunctionComponent } from 'react';
import {
  Col, Row, FormGroup, Input,
} from 'reactstrap';
import { MonitoredDeviceWidget } from '../../../store/monitoring/types';
import TextInput from '../../Inputs/TextInput';
import SimpleLoader from '../../SimpleLoader';

type TOwnProps = {
  numberOfCpus: number;
  memorySize: number;
  powerState: number;
};
const MonitoringVM: FunctionComponent<TOwnProps> = (props) => {

  const {
    numberOfCpus,
    memorySize,
    powerState,
  } = props;

  const resolvePowerState = (powerState) => {

    switch (Number(powerState)) {
      case 0:
        return 'Off';
      case 1:
        return 'On';
      case 2:
      default:
        return 'Suspended';
    }
  
  };

  const formatBytes = (bytes, decimals = 2) => {

    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / (k ** i)).toFixed(dm))} ${sizes[i]}`;
  
  };

  return (
    <div
      className="shadow-sm pt-3 bg-white d-flex flex-column monitoring-widget w-100 h-100"
      style={{ borderRadius: '10px' }}
    >
      <Row className="mx-0  mb-3 px-2">
        <Col className="d-flex flex-column light-border--bottom pb-2">
          <h5 className="text-light text-uppercase font-xl text-left mb-0 ">
            Number of CPUs
          </h5>
          <div className="pt-0">
            <div className="font-weight-bold text-dark pt-0 text-left">
              {numberOfCpus}
              <span className="text-light monitoring-widget__latency__ms" />
            </div>
          </div>
        </Col>
      </Row>
      <Row className="mx-0  mb-3 px-2">
        <Col className="d-flex flex-column light-border--bottom pb-2">
          <h5 className="text-light text-uppercase font-xl text-left mb-0 ">
            Memory Size
          </h5>
          <div className="pt-0">
            <div className="font-weight-bold text-dark pt-0 text-left">
              {formatBytes(memorySize)}
              <span className="text-light monitoring-widget__latency__ms" />
            </div>
          </div>
        </Col>
      </Row>
      <Row className="mx-0  mb-3 px-2">
        <Col className="d-flex flex-column ">
          <h5 className="text-light text-uppercase font-xl text-left mb-0 ">
            Power State
          </h5>
          <div className=" pt-0">
            <div className="font-weight-bold text-dark pt-0 text-left">
              {resolvePowerState(powerState)}
              <span className="text-light monitoring-widget__latency__ms" />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );

};
export default MonitoringVM;
