import { api_getOrder, api_getOrderActivities, api_getOrderActivityContent } from '../../utils/Orders/Orders';
import {
  ORDER_SET_DATA,
  ORDER_SET_ORDER_ACTIVITIES, ORDER_SET_ORDER_ACTIVITIES_TOGGLE_STATE, ORDER_TOGGLE_ACTIVITY,
  ORDER_TOGGLE_LOADING,
  ORDER_TOGGLE_LOADING_ACTIVITIES,
  ORDER_RESET_DATA, ORDER_SET_ACTIVITY_CONTENT, ORDER_TOGGLE_LOADING_ACTIVITY_CONTENT,
} from './types';
import { setSystemError } from '../system/actions';
import { defaultErrorFeedback } from '../../actions/feedback';

export const setActivitiesState = () => ({
  type: ORDER_SET_ORDER_ACTIVITIES_TOGGLE_STATE,
});
export const toggleActivityState = (id) => ({
  type: ORDER_TOGGLE_ACTIVITY,
  id,
});

const setOrderData = (data) => ({
  type: ORDER_SET_DATA,
  data,
});

const setOrderActivityContent = (id, data) => ({
  type: ORDER_SET_ACTIVITY_CONTENT,
  id,
  data,
});
export function toggleOrderLoading() {

  return {
    type: ORDER_TOGGLE_LOADING,
  };

}
export const toggleOrderActivitiesLoading = () => ({
  type: ORDER_TOGGLE_LOADING_ACTIVITIES,
});
const setOrderActivities = (activities) => ({
  type: ORDER_SET_ORDER_ACTIVITIES,
  activities,
});
export const resetOrderState = () => ({ type: ORDER_RESET_DATA });
export const getOrderData = (orderNumberOrId) => (dispatch) => api_getOrder(orderNumberOrId).then(
  (result) => {

    if (result.status === 200) {

      dispatch(setOrderData(result.data));
    
    } else if (result.data.error && result.data.error.id) {

      dispatch(setSystemError(result.data.error.id));
    
    } else {

      dispatch(defaultErrorFeedback());
    
    }
  
  },
);
export const getOrderActivities = (orderNumberOrId) => (dispatch) => api_getOrderActivities(orderNumberOrId).then(
  (result) => {

    if (result.status === 200) {

      dispatch(setOrderActivities(result.data));
    
    } else if (result.data.error && result.data.error.id) {

      dispatch(setSystemError(result.data.error.id));
    
    } else {

      dispatch(defaultErrorFeedback());
    
    }
  
  },
);

export const getOrderActivityContent = (orderIdOrNumber, id) => (dispatch) => api_getOrderActivityContent(orderIdOrNumber, id).then(
  (result) => {

    if (result.status === 200) {

      dispatch(setOrderActivityContent(id, result.data.content));
    
    } else if (result.data.error && result.data.error.id) {

      dispatch(setSystemError(result.data.error.id));
    
    } else {

      dispatch(defaultErrorFeedback());
    
    }
  
  },
);
export const toggleOrderActivityContentLoading = (id) => ({
  type: ORDER_TOGGLE_LOADING_ACTIVITY_CONTENT,
  id,
});
