import React from 'react';
import { Button, Input } from 'reactstrap';
import ReactTable from 'react-table-6';
import { checkPermission } from '../../../../utils/Auth/AuthService';

const SOATable = ({
  data,
  handleInput,
  handleUpdate,
  handleDelete,
  updating,
  user,
}) => {

  const records = [
    { name: 'Root Nameserver', value: data.rns },
    { name: 'Root Mail Box', value: data.mbox },
    { name: 'Refresh', value: '10 Minutes' },
    { name: 'Retry', value: '5 Minutes' },
    { name: 'TTL', value: '5 minutes' },
    { name: 'Serial', value: data.serial },
    { name: 'Expire', value: data.expire },
    { name: 'Minimum', value: data.minimum },
  ];
  const columns = [
    {
      Header: '',
      accessor: 'name',
      sortable: false,
      style: { whiteSpace: 'unset' },
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: props => <div className="text-primary">{props.value}</div>,
      // eslint-disable-next-line react/no-unstable-nested-components
      Footer: () => (
        <Button
          color="danger"
          outline
          className="float-left"
          onClick={handleDelete}
          disabled={!checkPermission('dns.write', user.permissions)}
        >
          Delete Zone
        </Button>
      ),
    },
    {
      Header: '',
      accessor: 'value',
      sortable: false,
      style: { whiteSpace: 'unset' },
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: props => {

        if (props.original.name === 'Expire') {

          return <ExpireSelect handleInput={handleInput} value={props.value} />;

        }
        if (props.original.name === 'Minimum') {

          return (
            <MinimumSelect handleInput={handleInput} value={props.value} />
          );

        }
        return props.value;

      },
      // eslint-disable-next-line react/no-unstable-nested-components
      Footer: () => (
        <Button
          color="warning"
          outline
          className="float-right"
          onClick={handleUpdate}
          disabled={!checkPermission('dns.write', user.permissions)}
        >
          Update
        </Button>
      ),
    },
  ];
  return (
    <ReactTable
      getTheadProps={() => ({
        style: { border: 'none' },
      })}
      getTheadTrProps={() => ({
        style: { border: 'none' },
      })}
      getTheadThProps={() => ({
        style: { border: 'none' },
      })}
      getTdProps={() => ({
        style: { border: 'none' },
      })}
      style={{ border: 'none' }}
      className="animated fadeIn"
      loading={updating}
      loadingText="Processing..."
      data={records}
      columns={columns}
      minRows="2"
      defaultPageSize={10}
      showPagination={false}
      filterable={false}
    />
  );

};

const ExpireSelect = ({ handleInput, value }) => (
  <Input type="select" id="expire" value={value} onChange={handleInput}>
    <option value={604800}>1 week</option>
    <option value={864000}>10 days</option>
    <option value={1209600}>2 weeks</option>
  </Input>
);
const MinimumSelect = ({ handleInput, value }) => (
  <Input type="select" id="minimum" value={value} onChange={handleInput}>
    <option value="300">5 minutes</option>
    <option value="1200">20 minutes</option>
    <option value="3600">1 hour</option>
    <option value="10800">3 hours</option>
    <option value="21600">6 hours</option>
    <option value="43200">12 hours</option>
    <option value="86400">1 day</option>
    <option value="172800">2 days</option>
    <option value="259200">3 days</option>
    <option value="604800">1 week</option>
  </Input>
);

export default SOATable;
