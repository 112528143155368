import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment, { Moment } from 'moment';
import { Button } from 'reactstrap';
import { DateTimePicker } from 'react-widgets';
import { IMonitoredItemState } from '../../store/monitoredItem/types';
import {
  getMonitoredDeviceItemHistory,
  getMonitoredItem,
  getMonitoredItemGraph,
  resetMonitoredItemPopUpGraph,
  resetMonitoredItemState,
  setMonitoredItemGraphDateRange,
  setPopUpGraphData,
  toggleMonitoredItemGraphLoading,
  toggleMonitoredItemLoading,
  toggleMonitoredItemPopUpGraph,
  toggleMonitoredItemPopUpGraphLoading,
  onDescChangeMonitoredItem,
  toggleDescEditMonitoredItem,
  saveMonitoredItemDesc,
  toggleGraphOpen,
  getMonitoredItemNTShowRouteDetails,
  getMonitoredItemNTShowRouteData,
  getMonitoredItemNTTraceRouteData,
  getMonitoredItemNTPingData,
  resetMonitoredItemNTPingData,
  resetMonitoredItemNTTraceRouteData,
  resetMonitoredItemNTShowRouteData,
  resetMonitoredItemNTShowRouteDetails,
} from '../../store/monitoredItem/actions';
import PopUpGraph from './PopUpGraph';
import MonitoredDevice from './MonitoredDevice';
import SimpleLoader from '../../components/SimpleLoader';
import MonitoredPort from './MonitoredPort';
import EditableHeaderSection from '../../components/EditableHeaderSection';
import { IUserState } from '../../store/user/types';
import { checkPermission } from '../../utils/Auth/AuthService';
import DeviceUsageReportWizard from '../../components/Modals/DeviceUsageReportWizard';
import { IAccountState } from '../../store/account/types';
import { AppDispatch } from '../../configureStore';

type TOwnProps = {
  id: string;
  monitoredItem: IMonitoredItemState;
  dispatch: AppDispatch;
  user: IUserState;
  account: IAccountState;
};
type Props = TOwnProps;
const availablePopUpGraphs = {
  latency: 'Latency',
  cpu: 'CPU Load',
  cpuUtil: 'CPU Load',
  memoryUse: 'Memory',
  memoryFree: 'Free Memory',
  memoryUsed: 'Memory',
  cpuRE: 'RE CPU Load',
  memoryRE: 'RE Memory',
  cpuSPU: 'SPU Load',
  memorySPU: 'SPU Memory',
  flowSessions: 'Session Utilisation',
};
const MonitoredItem: React.FC<Props> = (props) => {

  const { loading, item, popUpGraph } = props.monitoredItem;
  const { graphs } = item;
  const { id, user, account } = props;
  const { descriptionEditable, descriptionFormField } = item;
  const [showReportModal, setShowReportModal] = useState(false);

  const getGraph = (itemId, graphId, from: Moment, to: Moment) => {

    const start = from.format('X');
    const end = to.format('X');
    props.dispatch(toggleMonitoredItemGraphLoading(graphId));
    props.dispatch(getMonitoredItemGraph(itemId, graphId, start, end)).then(
      () => props.dispatch(toggleMonitoredItemGraphLoading(graphId)),
    );
  
  };
  useEffect(() => {

    props.dispatch(resetMonitoredItemState());
    props.dispatch(toggleMonitoredItemLoading());
    props.dispatch(getMonitoredItem(id, ['networkToolsDeviceDetails'])).then((result) => {

      props.dispatch(toggleMonitoredItemLoading());
      if (result && result.data.graphs.length < 5) {

        result.data.graphs.map((graph) => {

          getGraph(result.data.id, graph.id, moment().startOf('day'), moment());
        
        });
      
      }
    
    });

    return () => {

      props.dispatch(resetMonitoredItemState());
    
    };
  
  }, []);
  const resolveGraphName = (key) => {

    if (key.indexOf('disk') !== -1) {

      return key.replace('Used', '').replace('disk', 'Disk ');
    
    }
    return availablePopUpGraphs[key] ? availablePopUpGraphs[key] : key;
  
  };
  const getPopUpGraphData = (graphId: string, multiplier = 1, startDate?: number, endDate?: number) => {

    props.dispatch(toggleMonitoredItemPopUpGraphLoading());
    props.dispatch(getMonitoredDeviceItemHistory(item.id, graphId, multiplier, startDate || popUpGraph.startDate, endDate || popUpGraph.endDate)).then(() => {

      props.dispatch(toggleMonitoredItemPopUpGraphLoading());
    
    });
  
  };
  const togglePopUpGraph = () => {

    props.dispatch(toggleMonitoredItemPopUpGraph());
  
  };
  const graphHandler = (key, multiplier, format) => {

    props.dispatch(resetMonitoredItemPopUpGraph());
    const title = resolveGraphName(key);
    props.dispatch(setPopUpGraphData('title', title));
    props.dispatch(setPopUpGraphData('id', key));
    props.dispatch(setPopUpGraphData('multiplier', multiplier));
    if (typeof format === 'string') {

      props.dispatch(setPopUpGraphData('format', format));
    
    }
    getPopUpGraphData(key, multiplier);
    togglePopUpGraph();
  
  };
  const popUpTrackerHandler = (tracker) => {

    props.dispatch(setPopUpGraphData('tracker', tracker));
  
  };

  const popUpDateHandler = (startDate, endDate) => {

    props.dispatch(setPopUpGraphData('startDate', startDate));
    props.dispatch(setPopUpGraphData('endDate', endDate));
    getPopUpGraphData(popUpGraph.id, popUpGraph.multiplier, startDate, endDate);
  
  };
  const resetPopupGraph = () => {

    props.dispatch(resetMonitoredItemPopUpGraph());
  
  };
  const graphDateRangeHandler = (graphId: string, event: React.ChangeEvent<HTMLInputElement>, picker: DateTimePicker) => {

    if (event.type === 'apply') {

      props.dispatch(setMonitoredItemGraphDateRange(graphId, picker.startDate, picker.endDate));
      getGraph(item.id, graphId, picker.startDate, picker.endDate);
    
    }
  
  };
  const onDescChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    if (event.target.value.length <= 100) {

      props.dispatch(onDescChangeMonitoredItem(event.target.value));
    
    }
  
  };
  const saveDesc = () => {

    props.dispatch(saveMonitoredItemDesc(item.id, item.descriptionFormField));
    props.dispatch(toggleDescEditMonitoredItem());
  
  };
  const toggleEditDesc = () => {

    props.dispatch(toggleDescEditMonitoredItem());
  
  };

  const graphToggleHandler = (graphId) => {

    const graphInReduxStore = graphs.filter((graph) => graph.id === graphId)[0];
    if (graphInReduxStore && !graphInReduxStore.isOpen) {

      getGraph(id, graphId, graphInReduxStore.startDate, graphInReduxStore.endDate);
    
    }
    props.dispatch(toggleGraphOpen(graphId));
  
  };

  const getNetworkToolsPingData = (destination: string, vrf?: string) => {

    props.dispatch(resetMonitoredItemNTPingData());
    return props.dispatch(getMonitoredItemNTPingData(id, destination, vrf));
  
  };

  const getNetworkToolsTraceRouteData = (destination: string, vrf?: string) => {

    props.dispatch(resetMonitoredItemNTTraceRouteData());
    return props.dispatch(getMonitoredItemNTTraceRouteData(id, destination, vrf));
  
  };

  const getNetworkToolsShowRouteData = (destination: string, protocol: number, vrf?: string) => {

    props.dispatch(resetMonitoredItemNTShowRouteData());
    return props.dispatch(getMonitoredItemNTShowRouteData(id, destination, protocol, vrf));
  
  };

  const getNetworkToolsShowRouteDetails = (destination: string, protocol: number, vrf?: string) => {

    props.dispatch(resetMonitoredItemNTShowRouteDetails());
    return props.dispatch(getMonitoredItemNTShowRouteDetails(id, destination, protocol, vrf));
  
  };

  const toggleUsageReportModal = () => {

    setShowReportModal(!showReportModal);
  
  };

  const getReportButton = () => {

    if (account.serviceGroups.includes('Advanced Monitoring') && checkPermission('reports.write', user.permissions)) {

      return [<Button onClick={toggleUsageReportModal} key="report-button" className="float-right mt-2" color="primary">Create Report</Button>];
    
    }
    return [];
  
  };

  return (
    <div className="animated fadeIn mb-1">
      <SimpleLoader loading={loading}>
        {item.type === 'port'
        && (
        <EditableHeaderSection
          buttons={getReportButton()}
          item={{ name: item.name, description: item.description || '' }}
          descriptionEditable={descriptionEditable}
          descriptionFormField={descriptionFormField}
          toggleEditDesc={toggleEditDesc}
          saveDesc={saveDesc}
          onDescChange={onDescChange}
          userCanEdit={checkPermission('monitoring.write', user.permissions)}
          title="title"
        >
          <MonitoredPort
            item={item}
            dateHandler={graphDateRangeHandler}
          />
        </EditableHeaderSection>
        )}
        {item.type === 'device'
        && (
        <EditableHeaderSection
          item={{ name: item.name, description: item.description || '' }}
          descriptionEditable={descriptionEditable}
          descriptionFormField={descriptionFormField}
          toggleEditDesc={toggleEditDesc}
          saveDesc={saveDesc}
          onDescChange={onDescChange}
          userCanEdit={checkPermission('monitoring.write', user.permissions)}
          title="title"
          buttons={getReportButton()}
        >
          <MonitoredDevice
            user={user}
            item={item}
            dateHandler={graphDateRangeHandler}
            popupGraphHandler={graphHandler}
            toggleGraphOpen={graphToggleHandler}
            getNTPingData={getNetworkToolsPingData}
            getNTTraceRouteData={getNetworkToolsTraceRouteData}
            getNTShowRouteData={getNetworkToolsShowRouteData}
            getNTShowRouteDetails={getNetworkToolsShowRouteDetails}
          />
          <PopUpGraph
            graphHandler={togglePopUpGraph}
            details={popUpGraph}
            getData={getPopUpGraphData}
            trackerHandler={popUpTrackerHandler}
            dateHandler={popUpDateHandler}
            reset={resetPopupGraph}
          />
        </EditableHeaderSection>
        )}
      </SimpleLoader>
      <DeviceUsageReportWizard
        cancel={toggleUsageReportModal}
        isOpen={showReportModal}
      />
    </div>
  );

};

function mapStateToProps({ account, user, monitoredItem }) {

  return {
    account,
    user,
    monitoredItem,
  };

}

export default connect(mapStateToProps)(MonitoredItem);
