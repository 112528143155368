import moment from 'moment';
import validator from 'validator';
import ipRegex from 'ip-regex';
import { isValid } from 'postcode';

export const getRegistrar = (domainName) => {

  const regex = new RegExp('uk$');
  if (regex.test(domainName)) {

    return 'nominet';

  }
  return 'opensrs';

};
export const getTerms = (domain, tld) => {

  if (domain.action === 'transfer') {

    return [1];

  }
  let { maxPeriod } = tld;
  if ((!domain.action || domain.action === 'renew') && moment(domain.expireAt).isAfter(moment())) {

    const diffInYears = moment(domain.expireAt).diff(moment(), 'years');
    maxPeriod = maxPeriod > 1 ? (maxPeriod - diffInYears - 1) : 1;

  }

  const range = maxPeriod - tld.minPeriod + 1;
  return Array.from(new Array(range), (x, i) => i + tld.minPeriod);

};
export const isExpired = (domain) => moment(domain.expireAt).isBefore(moment());
export const isExpiring = (domain) => moment().diff(domain.expireAt, 'days') > -90 && !isExpired(domain);
export const validateContact = (contact) => {

  const errors = {};
  Object.keys(contact).map((key) => {

    const value = contact[key];
    if (key !== 'addressLine2' && !value) {

      errors[key] = true;

    } else if (key === 'email' && !validator.isEmail(value)) {

      errors[key] = true;

    } else if (key === 'phone' && !validator.matches(value, /^\+[0-9]{1,3}\.[0-9]+$/)) {

      errors[key] = true;

    } else if (key === 'postcode' && ((contact.country === 'GB' && !isValid(value)) || !value)) {

      errors[key] = true;

    }

  });
  return errors;

};
export const validateGlueRecord = (record) => {

  const errors = {};
  if (!record.sub) {

    errors.sub = 'Invalid sub domain';

  }
  if (record.ipv4 && !ipRegex.v4({ exact: true }).test(record.ipv4)) {

    errors.ipv4 = 'Invalid IPv4';

  }
  if (record.ipv6 && !ipRegex.v6({ exact: true }).test(record.ipv6)) {

    errors.ipv6 = 'Invalid IPv6';

  }
  if (!record.ipv6 && !record.ipv4) {

    errors.ipv4 = 'Must provide a valid IPv4 or IPv6 address';
    errors.ipv6 = 'Must provide a valid IPv4 or IPv6 address';

  }
  return errors;

};
export const getNameservers = (domain, nameservers) => {

  const result = {};
  nameservers.map((nameserver, index) => {

    const regEx = new RegExp(`\\b${domain}\\b$`);
    if (!regEx.test(nameserver.name)) {

      result[`Nameserver ${index + 1}`] = nameserver;

    }

  });

  if (Object.keys(result).length < 4) {

    for (let i = Object.keys(result).length + 1; i <= 4; i++) { // eslint-disable-line no-plusplus

      result[`Nameserver ${i}`] = { name: '', ipv4: null, ipv6: null, active: false };

    }

  }
  return result;

};
export const getGlueRecords = (domain, nameservers) => {

  const result = [];
  nameservers.map((nameserver) => {

    const regEx = new RegExp(`\\b${domain}\\b$`);
    if (regEx.test(nameserver.name)) {

      nameserver.sub = nameserver.name.replace(`.${domain}`, '');
      nameserver.changed = false;
      result.push(nameserver);

    }

  });
  return result;

};
