import React from 'react';
import { Col, Row } from 'reactstrap';
import { DateTimePicker } from 'react-widgets';
import Section from '../../components/Section';
import { TMonitoredItem } from '../../store/monitoredItem/types';
import OneUpOneDown from '../../components/Widgets/OneUpOneDown';
import ButtonLink from '../../components/Buttons/ButtonLink';
import TrafficGraph from '../../components/TrafficGraph';

type TOwnProps = {
  item: TMonitoredItem;
  dateHandler: (graphId: string, event: React.ChangeEvent<HTMLInputElement>, picker: DateTimePicker) => any;
};
const MonitoredPort: React.FC<TOwnProps> = (props) => {

  const { item } = props;
  return (
    <>
      <Row className="d-flex">
        <Col md={4}>
          <OneUpOneDown upTitle="Location" upValue={item.location} downTitle="Device" downValue={`${item.host}`} />
        </Col>
        <Col md={4}>
          <OneUpOneDown
            upTitle="Last 5 min average inbound"
            upValue={`${item.outBound}`}
            downTitle="Last 5 min average outbound"
            downValue={`${item.inBound}`}
          />
        </Col>
        <Col><ButtonLink color="danger" text="Report Fault" url="/cases/new/generic" /></Col>
      </Row>
      {item.graphs.map((graph) => (
        <div className="mt-3" key={graph.name}>
          <Section title={`${item.port} - ${graph.name}`}>
            {
                graph.dataIn && (
                <Row key={graph.id}>
                  <Col>
                    <TrafficGraph
                      name={graph.name}
                      loading={graph.loading}
                      dateRangeHandler={props.dateHandler.bind(null, graph.id)}
                      startDate={graph.startDate}
                      endDate={graph.endDate}
                      dataIn={graph.dataIn}
                      dataOut={graph.dataOut}
                    />
                  </Col>
                </Row>
                )
              }
          </Section>
        </div>
      ))}
    </>
  );

};
export default MonitoredPort;
