import React, { FunctionComponent, ReactChild } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import { IFormError } from '../../Forms/types';

type Props = {
  label: ReactChild;
  id: string;
  name?: string;
  options: Array<ISelectInputOptions>;
  value: string | number;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => any;
  cssClass?: string;
  disabled?: boolean;
  error?: IFormError;
  horizontal?: boolean;
};

export interface ISelectInputOptions {
  value: string | number;
  label: string | number;
}

const SelectInput: FunctionComponent<Props> = (props) => {

  const { disabled } = props;
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    props.onChange(event);
  
  };
  const horizontal = typeof props.horizontal === 'undefined' ? true : props.horizontal;
  return (
    <FormGroup className="row">
      <Label className={`col-12 ${!horizontal ? '' : 'col-md-4'} text-light col-form-label font-weight-bold`} for={props.id} style={{ fontSize: '16px' }}>{props.label}</Label>
      <div className={`col-12 ${!horizontal ? '' : 'col-md-8'}`}>

        <Input
          disabled={disabled}
          className={`text-light mb-0${props.error ? ' is-invalid' : ''}`}
          id={props.id}
          type="select"
          name={props.name || props.id}
          onChange={handleChange}
          value={props.value}
        >
          {props.options.map((option) => <option key={`${props.value}-${option.label}`} value={option.value}>{option.label}</option>)}
        </Input>
        {props.error
                && (
                <div className="invalid-feedback">
                  {props.error.message}
                </div>
                )}

      </div>
    </FormGroup>
  );

};

export default SelectInput;
