import { apiRequest } from '../Api/ApiRequest';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const api_getPriceList = () => apiRequest({
  url: 'price-list',
  method: 'get',
  headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
}).then(
  (resp) => resp,
).catch(
  (error) => error,
);
