import React, { FunctionComponent } from 'react';
import {
  InputGroup, Button, InputGroupAddon, Input,
} from 'reactstrap';
import SearchIcon from '../../SvgIcons/SearchIcon';

type Props = {
  value: string | number;
  placeHolder?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => any;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => any;
  hasButton?: boolean;
};
const SearchInput: FunctionComponent<Props> = (props) => {

  const {
    onChange, value, placeHolder, onClick, hasButton,
  } = props;
  return (
    <InputGroup>
      <Input
        value={value}
        placeholder={placeHolder || 'Search...'}
        onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {

          if (onChange) {

            onChange(event);
          
          }
        
        }}
      />
      {hasButton && (
      <InputGroupAddon addonType="append">
        <Button
          style={{ cursor: onClick ? 'pointer' : 'default' }}
          color="primary"
          onClick={(event: React.MouseEvent<HTMLButtonElement>): void => {

            if (onClick) {

              onClick(event);
            
            }
          
          }}
        >
          <SearchIcon cssClass="" title="Search" />
        </Button>
      </InputGroupAddon>
      )}

    </InputGroup>
  );

};
export default SearchInput;
