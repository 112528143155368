import { Manager } from 'socket.io-client';
import { errorMessages, isArray, isString } from './helpers';

class SocketIOClient {

  constructor() {

    this.manager = null;

  }

  connect(url, opts) {

    const socketDefaults = {
      auth: true,
      reconnection: true,
      reconnectionDelay: 1000,
    };

    const defaults = { ...socketDefaults, ...opts };

    if (url && defaults.auth) {

      this.manager = new Manager(url, defaults);
      return true;

    }

  }

  on(eventName, callback) {

    if (this.manager) {

      console.log(this.manager);
      this.manager.socket('/').on(eventName, (data) => {

        callback(data);

      });

    }

  }

  emit(eventName, data) {

    if (this.manager) {

      this.manager.socket('/').emit(eventName, data);

    }

  }

  removeListener(eventName) {

    if (!this.manager) {

      return;

    }

    if (isString(eventName)) {

      this.manager.socket('/').off(eventName);

    } else if (isArray(eventName)) {

      eventName.forEach((e) => {

        if (this.manager) {

          this.manager.socket('/').off(e);

        }

      });

    } else {

      console.error(errorMessages.SHOULD_BE_AN_ARRAY_OR_STRING);

    }

  }

  removeAllListeners() {

    if (this.manager) {

      this.manager.socket('/').offAny();

    }

  }

}

export default SocketIOClient;
