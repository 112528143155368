import { apiRequest } from '../Api/ApiRequest';

export function api_getMonitoredItemsList() {

  return apiRequest({
    url: '/monitoring?include=services',
    method: 'get',
    headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
  }).then(
    resp => resp,
  ).catch(
    error => error,
  );

}
export function api_getMonitoredItemStatuses() {

  return apiRequest({
    url: '/monitoring/status',
    method: 'get',
    headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
  }).then(
    resp => resp,
  ).catch(
    error => error,
  );

}
export function api_getMonitoredItem(id, include = []) {

  return apiRequest({
    url: `/monitoring/${id}${include ? `?include=${include.join(',')}` : ''}`,
    method: 'get',
    headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
  }).then(
    resp => resp,
  ).catch(
    error => error,
  );

}

export function api_getMonitoredItemUplink(id) {

  return apiRequest({
    url: `/monitoring/${id}/uplink`,
    method: 'get',
    headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
  }).then(
    resp => resp,
  ).catch(
    error => error,
  );

}
export function getMonitoredGraphData(deviceId, graphID, from, to) {

  return apiRequest({
    url: `/monitoring/${deviceId}/graph/${graphID}/${from}/${to}`,
    method: 'get',
    headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
  }).then(
    resp => resp,
  ).catch(
    error => error,
  );

}
export function api_getMonitoredGraphData(itemId, graphId, from, to) {

  return apiRequest({
    url: `/monitoring/${itemId}/graph/${graphId}/${from}/${to}`,
    method: 'get',
    headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
  }).then(
    resp => resp,
  ).catch(
    error => error,
  );

}
export function api_getMonitoredItemHistory(deviceId, itemId, from, to) {

  return apiRequest({
    url: `/monitoring/${deviceId}/item/${itemId}/${from}/${to}`,
    method: 'get',
    headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
  }).then(
    resp => resp,
  ).catch(
    error => error,
  );

}
export function api_updateMonitoredDeviceDesc(deviceId, description) {

  return apiRequest({
    url: `/monitoring/${deviceId}/description`,
    method: 'put',
    headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
    data: { description },
  }).then(
    resp => resp,
  ).catch(
    error => error,
  );

}

export function api_removeFavourite(itemId) {

  return apiRequest({
    url: `/monitoring/${itemId}/favourite`,
    method: 'delete',
    headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
  }).then(
    resp => resp,
  ).catch(
    error => error,
  );

}
export function api_addFavourite(itemId) {

  return apiRequest({
    url: `/monitoring/${itemId}/favourite`,
    method: 'put',
    headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
  }).then(
    resp => resp,
  ).catch(
    error => error,
  );

}

export function api_getNetworkToolsPing(itemId, destination, vrf) {

  return apiRequest({
    url: `/monitoring/${itemId}/network-tools/ping`,
    method: 'get',
    headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
    params: {
      destination,
      vrf,
    },
  }).then(
    resp => resp,
  ).catch(
    error => error,
  );

}

export function api_getNetworkToolsTraceRoute(itemId, destination, vrf) {

  return apiRequest({
    url: `/monitoring/${itemId}/network-tools/trace-route`,
    method: 'get',
    headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
    params: {
      destination,
      vrf,
    },
  }).then(
    resp => resp,
  ).catch(
    error => error,
  );

}

export function api_getNetworkToolsShowRoute(itemId, destination, protocol, vrf) {

  return apiRequest({
    url: `/monitoring/${itemId}/network-tools/show-route`,
    method: 'get',
    headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
    params: {
      destination,
      protocol,
      vrf,
    },
  }).then(
    resp => resp,
  ).catch(
    error => error,
  );

}

export function api_getNetworkToolsShowRouteDetails(itemId, destination, protocol, vrf) {

  return apiRequest({
    url: `/monitoring/${itemId}/network-tools/show-route-details`,
    method: 'get',
    headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
    params: {
      destination,
      protocol,
      vrf,
    },
  }).then(
    resp => resp,
  ).catch(
    error => error,
  );

}

export function api_createMonitoredDeviceReport(deviceId, config) {

  return apiRequest({
    url: `/monitoring/${deviceId}/reports`,
    method: 'post',
    headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
    data: config,
  }).then(
    resp => resp,
  ).catch(
    error => error,
  );

}

export function api_updateDeviceReport(deviceId, id, data) {

  return apiRequest({
    url: `/monitoring/${deviceId}/reports/${id}`,
    method: 'put',
    headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
    data,
  }).then(
    resp => resp,
  ).catch(
    error => error,
  );

}

export function api_downloadMonitoredDeviceReport(deviceId, id) {

  return apiRequest({
    url: `/monitoring/${deviceId}/reports/${id}/download`,
    method: 'get',
    responseType: 'blob',
    headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
  }).then(
    resp => resp,
  ).catch(
    error => error,
  );

}
