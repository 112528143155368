import {
  IConfirmation,
  IResetSystemErrorAction,
  IResetSystemSetSuccessMessageAction,
  ISetSystemErrorAction,
  ISystemReSetConfirmationAction,
  ISystemResetConnectionError,
  ISystemResetForbiddenError,
  ISystemResetPartnerCustomerAction,
  ISystemSetConfirmationAction,
  ISystemSetConnectionError,
  ISystemSetForbiddenError,
  ISystemSetPartnerCustomerAction,
  ISystemSetSettingsAction,
  ISystemSetSuccessMessageAction,
  ISystemToggleStatusLoadingAction,
  RESET_SYSTEM_ERROR,
  SET_SYSTEM_ERROR,
  SYSTEM_ERROR_EMAIL_SENT,
  SYSTEM_RESET_CONFIRMATION,
  SYSTEM_RESET_CONNECTION_ERROR,
  SYSTEM_RESET_FORBIDDEN_ERROR,
  SYSTEM_RESET_PARTNER_CUSTOMER,
  SYSTEM_RESET_SUCCESS_MESSAGE,
  SYSTEM_SET_CONFIRMATION,
  SYSTEM_SET_CONNECTION_ERROR,
  SYSTEM_SET_FORBIDDEN_ERROR,
  SYSTEM_SET_PARTNER_CUSTOMER,
  SYSTEM_SET_SETTINGS,
  SYSTEM_SET_SUCCESS_MESSAGE,
  SYSTEM_TOGGLE_STATUS_LOADING,
  SYSTEM_TOGGLE_TUTORIAL_MODAL, TSystemSettings,
} from './types';
import { api_triggerPortalErrorEmail } from '../../utils/Errors';
import { defaultErrorFeedback } from '../../actions/feedback';
import { TAccountCustomer } from '../account/types';
import { TFavourite } from '../user/types';

export const setSystemError = (errorId): ISetSystemErrorAction => ({ type: SET_SYSTEM_ERROR, errorId });
export const resetSystemError = (): IResetSystemErrorAction => ({ type: RESET_SYSTEM_ERROR });
export const setSystemSuccessMessage = (message): ISystemSetSuccessMessageAction => ({ type: SYSTEM_SET_SUCCESS_MESSAGE, message });
export const resetSystemSuccessMessage = (): IResetSystemSetSuccessMessageAction => ({ type: SYSTEM_RESET_SUCCESS_MESSAGE });
export const toggleSystemStatusLoading = (): ISystemToggleStatusLoadingAction => ({ type: SYSTEM_TOGGLE_STATUS_LOADING });
export const setSystemForbiddenError = (): ISystemSetForbiddenError => ({ type: SYSTEM_SET_FORBIDDEN_ERROR });
export const resetSystemForbiddenError = (): ISystemResetForbiddenError => ({ type: SYSTEM_RESET_FORBIDDEN_ERROR });
export const setSystemConnectionError = (): ISystemSetConnectionError => ({ type: SYSTEM_SET_CONNECTION_ERROR });
export const resetSystemConnectionError = (): ISystemResetConnectionError => ({ type: SYSTEM_RESET_CONNECTION_ERROR });

export const setSystemPartnerCustomer = (customer: TAccountCustomer, userFavourites: TFavourite[]): ISystemSetPartnerCustomerAction => ({ type: SYSTEM_SET_PARTNER_CUSTOMER, customer, userFavourites });

export const resetSystemPartnerCustomer = (): ISystemResetPartnerCustomerAction => ({ type: SYSTEM_RESET_PARTNER_CUSTOMER });
export const setSystemConfirmation = (confirmation: IConfirmation): ISystemSetConfirmationAction => ({ type: SYSTEM_SET_CONFIRMATION, confirmation });
export const resetSystemConfirmation = (): ISystemReSetConfirmationAction => ({ type: SYSTEM_RESET_CONFIRMATION });

export function toggleTutorialModal() {

  return {
    type: SYSTEM_TOGGLE_TUTORIAL_MODAL,
  };

}

export function setSystemSettings(settings: TSystemSettings): ISystemSetSettingsAction {

  return {
    type: SYSTEM_SET_SETTINGS,
    settings,
  };

}

export const sendErrorReportEmail = (errorId) => (dispatch) => api_triggerPortalErrorEmail(errorId).then((result) => {

  if (result.status === 200) {

    dispatch({ type: SYSTEM_ERROR_EMAIL_SENT });
  
  } else {

    dispatch(defaultErrorFeedback());
  
  }

});
