import { useNavigate } from 'react-router-dom'
import { api_doLogout, api_getAuthStatus } from '../../utils/Auth/AuthService'
import { api_removeFavourite, api_addFavourite } from '../../utils/Monitoring/Monitoring'
import {
    IUserLogoutAction,
    IUserSetDataAction,
    USER_LOGOUT,
    USER_SET_DATA,
    IUserAddFavouriteAction,
    IUserRemoveFavouriteAction,
    USER_REMOVE_FAVOURITE,
    USER_ADD_FAVOURITE,
    USER_SET_AUTHENTICATION,
    IUserSetAuthenticationAction,
    USER_UPDATE_FAVOURITE_ACCOUNT,
    IUserUpdateFavouriteAccountAction, TPortalSetting, SOCKET_LOGOUT, IUserSocketLogoutAction,
} from './types'
import { errorFeedback } from '../../actions/feedback'
import { setSystemSettings, toggleTutorialModal } from '../system/actions'
import { api_updatePortalSetting, getSettingValue } from '../../utils/User'
import { authenticateSocket } from '../socketio/actions'
import { setWeatherMaps } from '../weatherMaps/actions'
import axios from 'axios'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const jwtDecode = require('jwt-decode')

export function setUserData(data): IUserSetDataAction {

    return {
        type: USER_SET_DATA,
        data,
    }

}

function userLogout(): IUserLogoutAction {

    return {
        type: USER_LOGOUT,
    }

}

const socketLogout = (): IUserSocketLogoutAction => ({
    type: SOCKET_LOGOUT,
})

export const logout = () => {

    return async (dispatch) => {

        if (!localStorage.getItem('hso-api-id-token')) {

            axios.get(`${process.env.REACT_APP_HSO_ID_URL}/auth/bclogout`, {
                headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
            })
                .catch(err => err)

        }

        dispatch(userLogout())
        dispatch(socketLogout())

        localStorage.removeItem('hso-api-token')
        localStorage.removeItem('hso-api-id-token')

    }

}

export function setUserAuth(token, refresh, loggedOut): IUserSetAuthenticationAction {

    return {
        type: USER_SET_AUTHENTICATION,
        token,
        refresh,
        loggedOut,
    }

}

export function refreshTokens() {

    return (dispatch) => axios.post(`${process.env.REACT_APP_HSO_ID_URL}/token/`, new URLSearchParams({
        grant_type: 'refresh_token',
        refresh_token: localStorage.getItem('hso-api-refresh-token') || '',
        client_id: process.env.REACT_APP_HSO_ID_CLIENT_ID || ''
    }))
        .then((result) => {

            if (result.status === 200) {

                localStorage.setItem('hso-api-token', result.data.access_token)
                localStorage.setItem('hso-api-refresh-token', result.data.refresh_token)
                const tokenDecoded = jwtDecode(result.data.access_token)
                dispatch(setUserData({
                    permissions: tokenDecoded.scopes,
                }))
                dispatch(setUserAuth(result.data.access_token, result.data.refresh_token, false))
                dispatch(authenticateSocket({ jwt: localStorage.getItem('hso-api-token') }))

                return true

            }

            dispatch(errorFeedback(`Could not refresh token ${result.status}`))
            dispatch(logout())

            return false

        })

}

export function getAuthStatus(token) {

    return (dispatch) => api_getAuthStatus(token)
        .then((result) => {

            if (result.status === 200) {

                const tok = jwtDecode(token)

                dispatch(setUserData({ ...result.data, permissions: tok.scopes }))

                if (result.data.weatherMaps && result.data.weatherMaps.length > 0) {

                    dispatch(setWeatherMaps(result.data.weatherMaps))

                }
                if (!getSettingValue(result.data.settings, 'hideTutorialModal')) {

                    dispatch(toggleTutorialModal())

                }
                return true

            }

            return false

        })

}

export function favouriteItem(itemId, itemName, itemLocation) {

    return (dispatch) => {

        dispatch(addFavouriteToUser(itemId, itemName, itemLocation))
        return api_addFavourite(itemId)
            .then((result) => {

                if (result.status !== 200) {

                    dispatch(removeFavouriteFromUser(itemId))
                    dispatch(errorFeedback('Sorry we weren\'t able to favourite this item'))

                } else {

                    dispatch(updateUserFavouriteAccount(itemId, result.data.accountId))

                }

            })

    }

}

export function unFavouriteItem(itemId: string, itemName: string, itemLocation: string) {

    return (dispatch) => {

        dispatch(removeFavouriteFromUser(itemId))
        return api_removeFavourite(itemId)
            .then((result) => {

                if (result.status !== 200) {

                    dispatch(addFavouriteToUser(itemId, itemName, itemLocation))
                    dispatch(errorFeedback('Sorry we weren\'t able to un-favourite this item'))

                }

            })

    }

}

export function updatePortalSetting(settings: TPortalSetting[]) {

    return () => api_updatePortalSetting(settings)

}

export const addFavouriteToUser = (itemId, itemName, itemLocation): IUserAddFavouriteAction => ({
    type: USER_ADD_FAVOURITE, itemId, itemName, itemLocation,
})
export const updateUserFavouriteAccount = (itemId, accountId): IUserUpdateFavouriteAccountAction => ({ type: USER_UPDATE_FAVOURITE_ACCOUNT, itemId, accountId })
export const removeFavouriteFromUser = (itemId): IUserRemoveFavouriteAction => ({ type: USER_REMOVE_FAVOURITE, itemId })
