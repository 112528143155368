import React, { FunctionComponent } from 'react';
import EmailLink from '../../EmailLink';
import PhoneLink from '../../PhoneLink';
import { Gravatar } from '../../Gravatar/Gravatar';
import SimpleLoader from '../../SimpleLoader';

type ContactCard = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  jobTitle: string;
  dropShadow: boolean;
  loading?: boolean;
  emailSubject? : string;

};

const ContactCard: FunctionComponent<ContactCard> = (props) => {

  const {
    firstName, lastName, jobTitle, email, phone, dropShadow, loading, emailSubject,
  } = props;
  return (
    <SimpleLoader loading={loading !== undefined ? loading : false}>
      <div
        className={`d-flex flex-row justify-content-between p-2 bg-white ${dropShadow ? 'shadow-sm' : ''}`}
        style={{ borderRadius: '10px' }}
      >
        <div className="p-2 flex-shrink-1">

          <Gravatar className="rounded-circle shadow" size={120} email={email} />

        </div>
        <div className="pl-3 pt-4 pr-5 w-100">
          <h5 className="pb-0 mb-0 font-weight-bold">
            {firstName}
            {' '}
            {lastName}
          </h5>
          <p data-testid="jobtitle" className="text-light font-weight-bold mb-2">
            {jobTitle}
          </p>
          <p>
            <span className="mt-n1 d-inline-block font-weight-bold">
              Contact {firstName}
            </span>
            <EmailLink subject={emailSubject || ''} email={email} />
            <PhoneLink phone={phone} />
          </p>
        </div>
      </div>

    </SimpleLoader>

  );

};

export default ContactCard;
