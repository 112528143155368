import React, { FunctionComponent } from 'react';
import {
  Row, Col, Input, Button, FormGroup, Label,
} from 'reactstrap';

type IOwnProps = {
  title: string;
  item: IItem;
  descriptionEditable: boolean;
  descriptionFormField: string;
  toggleEditDesc: () => any;
  saveDesc: () => any;
  onDescChange: (event: React.ChangeEvent<HTMLInputElement>) => any;
  userCanEdit: boolean;
  buttons?: Array<JSX.Element>;
};
type IItem = {
  name: string;
  description: string;
};
const Header: FunctionComponent<IOwnProps> = ({
  item, descriptionEditable, descriptionFormField, toggleEditDesc, saveDesc, onDescChange, userCanEdit, buttons,
}) => (

  <Row>
    <Col md={10}>
      <FormGroup className="row">
        <Col>
          <Label
            className="col-form-label font-weight-bold font-2xl text-dark d-flex justify-content-start"
            for={item.name}
          >
            {item.name}
            <span className="ml-2">
              {item.description && !descriptionEditable ? (` - ${item.description}`)
                : descriptionEditable

                  ? (
                    <Input

                      autoFocus
                      placeholder="Description"
                      className="ml-2"
                      value={descriptionFormField || ''}
                      onChange={(event) => onDescChange(event)}
                    />
                  )
                  : ''}
            </span>
            {userCanEdit && !descriptionEditable
                                && (
                                <Button
                                  onClick={() => toggleEditDesc()}
                                  className="btn btn-transparent py-2 px-2 float-right text-info"
                                >
                                  <i title="Edit" className="fa fa-pencil" />
                                </Button>
                                )}
            {userCanEdit && descriptionEditable
                                && (
                                <div className="ml-3">
                                  <Button
                                    onClick={() => toggleEditDesc()}
                                    className="btn btn-transparent py-2 px-2 float-right text-info"
                                  >
                                    <i title="Cancel" className="fa fa-arrow-left" />
                                  </Button>
                                  <Button
                                    onClick={() => saveDesc()}
                                    className="btn btn-transparent  py-2 px-2 float-right text-info"
                                  >
                                    <i title="Save" className="fa fa-save" />
                                  </Button>
                                </div>
                                )}
          </Label>
          <div style={{ height: '5px', width: '35px' }} className="bg-warning" />
        </Col>
      </FormGroup>
    </Col>
    <Col>
      {buttons !== undefined
                    && buttons.map((button) => button)}
    </Col>

  </Row>
);
export default Header;
