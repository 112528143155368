export const SET_DOMAIN_CHECKOUT_STEP_STATUS = 'SET_DOMAIN_CHECKOUT_STEP_STATUS';
export const SET_DOMAIN_CHECKOUT_STEP = 'SET_DOMAIN_CHECKOUT_STEP';
export const RESET_DOMAIN_CHECKOUT = 'RESET_DOMAIN_CHECKOUT';
export const INIT_DOMAIN_CHECKOUT_CONTACT = 'INIT_DOMAIN_CHECKOUT_CONTACT';
export const UPDATE_DOMAIN_CHECKOUT_CONTACT = 'UPDATE_DOMAIN_CHECKOUT_CONTACT';
export const UPDATE_DOMAIN_CHECKOUT_CONTACT_PHONE = 'UPDATE_DOMAIN_CHECKOUT_CONTACT_PHONE';
export const SET_DOMAIN_CHECKOUT_STEPS = 'SET_DOMAIN_CHECKOUT_STEPS';
export const UPDATE_DOMAIN_CHECKOUT_STEP = 'UPDATE_DOMAIN_CHECKOUT_STEP';
export const SET_DOMAIN_CHECKOUT_ERRORS = 'SET_DOMAIN_CHECKOUT_ERRORS';
export const TOGGLE_DOMAIN_CHECKOUT_TERMS = 'TOGGLE_DOMAIN_CHECKOUT_TERMS';
export const SET_DOMAIN_CHECKOUT_TO_CARD_PAYMENT = 'SET_DOMAIN_CHECKOUT_TO_CARD_PAYMENT';
export const UPDATE_DOMAIN_CHECKOUT_CARD_DETAILS = 'UPDATE_DOMAIN_CHECKOUT_CARD_DETAILS';
export const TOGGLE_DOMAIN_CHECKOUT_ORDER_IN_PROGRESS = 'TOGGLE_DOMAIN_CHECKOUT_ORDER_IN_PROGRESS';
export const SET_DOMAIN_CHECKOUT_CARD_REDIRECT = 'SET_DOMAIN_CHECKOUT_CARD_REDIRECT';
export const UPDATE_DOMAIN_CHECKOUT_ORDER_NUMBER = 'UPDATE_DOMAIN_CHECKOUT_ORDER_NUMBER';
export const SET_DOMAIN_CHECKOUT_PURCHASE_TOKEN = 'SET_DOMAIN_CHECKOUT_PURCHASE_TOKEN';
export const SET_DOMAIN_CHECKOUT_STATUS = 'SET_DOMAIN_CHECKOUT_STATUS';
export const SET_DOMAIN_CHECKOUT_FAILED_ITEMS = 'SET_DOMAIN_CHECKOUT_FAILED_ITEMS';
export const SET_DOMAIN_CHECKOUT_COMPLETED_ITEMS = 'SET_DOMAIN_CHECKOUT_COMPLETED_ITEMS';

export function updateDomainCheckoutStatus(status) {

  return {
    type: SET_DOMAIN_CHECKOUT_STATUS,
    status,
  };

}

export function initDomainCheckoutContact(user, account) {

  return {
    type: INIT_DOMAIN_CHECKOUT_CONTACT,
    user,
    account,
  };

}

export function updateDomainCheckoutContact(attr, value) {

  return {
    type: UPDATE_DOMAIN_CHECKOUT_CONTACT,
    attr,
    value,
  };

}
export function updateDomainCheckoutContactPhone(phone) {

  return {
    type: UPDATE_DOMAIN_CHECKOUT_CONTACT_PHONE,
    phone,
  };

}

export function updateDomainCheckoutOrderNumber(orderNumber) {

  return {
    type: UPDATE_DOMAIN_CHECKOUT_ORDER_NUMBER,
    orderNumber,
  };

}

export function setDomainCheckoutStep(step, buttonText) {

  return {
    type: SET_DOMAIN_CHECKOUT_STEP,
    step,
    buttonText,
  };

}
export function setDomainCheckoutSteps(steps) {

  return {
    type: SET_DOMAIN_CHECKOUT_STEPS,
    steps,
  };

}

export function resetDomainCheckout() {

  return {
    type: RESET_DOMAIN_CHECKOUT,
  };

}

export function updateDomainCheckoutStep(index, attr, value) {

  return {
    type: UPDATE_DOMAIN_CHECKOUT_STEP,
    index,
    attr,
    value,
  };

}

export function setDomainCheckoutErrors(errors) {

  return {
    type: SET_DOMAIN_CHECKOUT_ERRORS,
    errors,
  };

}
export function setDomainCheckoutFailedItems(failedItems) {

  return {
    type: SET_DOMAIN_CHECKOUT_FAILED_ITEMS,
    failedItems,
  };

}

export function setDomainCheckoutCompletedItems(completedItems) {

  return {
    type: SET_DOMAIN_CHECKOUT_COMPLETED_ITEMS,
    completedItems,
  };

}

export function toggleDomainCheckoutTerms() {

  return {
    type: TOGGLE_DOMAIN_CHECKOUT_TERMS,
  };

}
export function toggleDomainCheckoutOrderInProgress() {

  return {
    type: TOGGLE_DOMAIN_CHECKOUT_ORDER_IN_PROGRESS,
  };

}

export function setDomainCheckoutToCardPayment() {

  return {
    type: SET_DOMAIN_CHECKOUT_TO_CARD_PAYMENT,
  };

}

export function setDomainCheckoutCardRedirect(redirect) {

  return {
    type: SET_DOMAIN_CHECKOUT_CARD_REDIRECT,
    redirect,
  };

}

export function setDomainCheckoutPurchaseToken(token) {

  return {
    type: SET_DOMAIN_CHECKOUT_PURCHASE_TOKEN,
    token,
  };

}
