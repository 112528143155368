import React, { FunctionComponent, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import {
  ButtonDropdown, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, Button,
} from 'reactstrap';
import moment from 'moment';
import ComplexTable from '../../components/Table/ComplexTable';
import { IUserState } from '../../store/user/types';
import Section from '../../components/Section';
import SearchInput from '../../components/Inputs/SearchInput';
import { ICasesState } from '../../store/cases/types';
import {
  getAccountCases,
  setCasesSearchString,
  toggleAccountCasesLoading,
  toggleNewCasesDropdown,
  resetCasesStore,
  toggleCasesFilterDropdown,
  setCasesFilter,
} from '../../store/cases/actions';
import { checkPermission } from '../../utils/Auth/AuthService';

import SearchIcon from '../../components/SvgIcons/SearchIcon';
import SimpleLoader from '../../components/SimpleLoader';
import Refresh from '../../components/SvgIcons/Refresh';
import { ACCOUNT_TYPE_RESALE_PARTNER, IAccountState } from '../../store/account/types';
import CloudDown from '../../components/SvgIcons/CloudDown';
import { AppDispatch } from '../../configureStore';

type TOwnProps = {
  user: IUserState;
  cases: ICasesState;
  dispatch: AppDispatch;
  account: IAccountState;
};
type TProps = TOwnProps;
const Cases: FunctionComponent<TProps> = (props) => {

  const { dispatch, account } = props;
  const {
    cases, loading, searchString, openNewCaseDropdown, openFilterCasesDropdown, visibleState,
  } = props.cases;
  const { user } = props;
  const navigate = useNavigate();

  useEffect(() => {

    if (cases.length === 0 || account.accountTypeCode === ACCOUNT_TYPE_RESALE_PARTNER) refreshStore();
  
  }, []);
  const refreshStore = () => {

    dispatch(resetCasesStore());
    dispatch(toggleAccountCasesLoading());
    dispatch(getAccountCases()).then(() => dispatch(toggleAccountCasesLoading()));
  
  };
  const onChangeSearch = (e) => {

    const searchString = e.target.value.toString().toLowerCase();
    dispatch(setCasesSearchString(searchString));
  
  };
  const toggleNewCase = () => {

    dispatch(toggleNewCasesDropdown());
  
  };
  const togglFilterCases = () => {

    dispatch(toggleCasesFilterDropdown());
  
  };
  const filtered = (item) => String(item.title).toLowerCase().indexOf(searchString.toLowerCase()) !== -1
            || String(item.ticketNumber).toLowerCase().indexOf(searchString.toLowerCase()) !== -1
            || String(item.state).toLowerCase().indexOf(searchString.toLowerCase()) !== -1
            || String(item.status).toLowerCase().indexOf(searchString.toLowerCase()) !== -1
            || String(item.createdOn).toLowerCase().indexOf(searchString.toLowerCase()) !== -1
            || String(item.modifiedOn).toLowerCase().indexOf(searchString.toLowerCase()) !== -1;

  const getVisibleCases = () => {

    const visibleCases = cases.filter((theCase) => {

      switch (visibleState) {
        case 'Open':
          if (searchString) {

            return theCase.state !== 'Canceled' && theCase.state !== 'Resolved' && filtered(theCase);
          
          }
          return theCase.state !== 'Canceled' && theCase.state !== 'Resolved';
        case 'Canceled':
          if (searchString) {

            return theCase.state !== 'Active' && theCase.state !== 'Resolved' && filtered(theCase);
          
          }
          return theCase.state !== 'Active' && theCase.state !== 'Resolved';
        case 'Active':
          if (searchString) {

            return theCase.state !== 'Resolved' && theCase.state !== 'Canceled' && filtered(theCase);
          
          }
          return theCase.state !== 'Resolved' && theCase.state !== 'Canceled';
        case 'Resolved':
          if (searchString) {

            return theCase.state === 'Resolved' && filtered(theCase);
          
          }
          return theCase.state === 'Resolved';
        case 'All':
          if (searchString) {

            return filtered(theCase);
          
          }
          return true;
        default:
          return true;
      }
    
    });
    return visibleCases;
  
  };
  const columns = [
    {
      Header: 'Case Number',
      accessor: 'ticketNumber',
      width: 150,
    },
    {
      Header: 'Title',
      accessor: 'title',
      minWidth: 500,
    },
    {
      Header: 'State',
      accessor: 'state',
    },
    {
      Header: 'Status',
      accessor: 'status',
    },
    {
      Header: 'Date Created',
      accessor: 'createdOn',
      Cell: (props) => (props.value ? moment(props.value).format('DD/MM/YYYY') : <></>),
    },
    {
      Header: 'Date Modified',
      accessor: 'modifiedOn',
      Cell: (props) => (props.value ? moment(props.value).format('DD/MM/YYYY') : <></>),
    },
    {
      Header: '',
      filterable: false,
      sortable: false,
      accessor: 'id',
      width: 50,
      Cell: (props) => (
        <Link className="grey-icon rounded" to={`/cases/${props.original.ticketNumber}`}>
          <SearchIcon title="details" />
        </Link>
      ),
    },
  ];
  return (
    <div className="animated fadeIn mb-3">
      <Section title="Cases">
        <Row className="mb-4">
          <Col className="d-flex flex-row" md={6}>
            <SearchInput value={searchString} onChange={onChangeSearch} />
            <ButtonDropdown isOpen={openFilterCasesDropdown} toggle={togglFilterCases} className="ml-2 d-flex justify-content-space-between">
              <DropdownToggle caret color="primary">
                <span className="d-inline-block" style={{ width: '70px' }}>{visibleState !== '' ? visibleState : 'All'}</span>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => {

                  props.dispatch(setCasesFilter('Resolved'));
                
                }}
                >
                  Resolved
                </DropdownItem>
                <DropdownItem onClick={() => {

                  props.dispatch(setCasesFilter('Active'));
                
                }}
                >
                  Active
                </DropdownItem>
                <DropdownItem onClick={() => {

                  props.dispatch(setCasesFilter('Canceled'));
                
                }}
                >
                  Cancelled
                </DropdownItem>
                <DropdownItem onClick={() => {

                  props.dispatch(setCasesFilter('All'));
                
                }}
                >
                  All
                </DropdownItem>
              </DropdownMenu>
            </ButtonDropdown>
          </Col>
          <Col md="6" className="d-flex justify-content-end">
            {checkPermission('cases.write', user.permissions)
                            && (
                            <ButtonDropdown isOpen={openNewCaseDropdown} toggle={toggleNewCase} className="mr-2">
                              <DropdownToggle caret color="primary">
                                New Case
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem onClick={() => {

                                  navigate('/cases/new/generic');
                                
                                }}
                                >
                                  Generic Case
                                </DropdownItem>
                                <DropdownItem header>Fault Reporting</DropdownItem>
                                <DropdownItem onClick={() => {

                                  navigate('/cases/new/adslfault');
                                
                                }}
                                >
                                  ADSL Fault
                                </DropdownItem>
                                <DropdownItem onClick={() => {

                                  navigate('/cases/new/voicefault');
                                
                                }}
                                >
                                  Voice Fault
                                </DropdownItem>
                                <DropdownItem header>Change Requests</DropdownItem>
                                <DropdownItem onClick={() => {

                                  navigate('/cases/new/voicechange');
                                
                                }}
                                >
                                  Voice Change
                                </DropdownItem>
                                <DropdownItem onClick={() => {

                                  navigate('/cases/new/vpn');
                                
                                }}
                                >
                                  New VPN
                                </DropdownItem>
                                <DropdownItem onClick={() => {

                                  navigate('/cases/new/change');
                                
                                }}
                                >
                                  Other Change
                                </DropdownItem>
                              </DropdownMenu>
                            </ButtonDropdown>
                            )}
            <CSVLink className="pt-1 px-1 btn btn-warning" color="warning" data={getVisibleCases()} filename="cases.csv">
              <CloudDown cssClassTop="refresh-svg" title="Download as CSV" />
            </CSVLink>
            <Button className="pt-1 px-1 ml-2" color="primary" onClick={() => refreshStore()}>
              <Refresh title="Refresh" cssClassTop="refresh-svg" />
            </Button>
          </Col>
        </Row>
        <Row className="mt-2 mr-0 ml-0">
          <Col className="p-4 bg-white rounded">
            <SimpleLoader loading={loading}>
              <ComplexTable
                columns={columns}
                data={getVisibleCases()}
                defaultSorted={{
                  id: 'modifiedOn',
                  desc: true,
                }}
              />
            </SimpleLoader>
          </Col>
        </Row>
      </Section>
    </div>
  );

};
function mapStateToProps({ user, cases, account }) {

  return {
    cases,
    user,
    account,
  };

}
export default connect(mapStateToProps)(Cases);
