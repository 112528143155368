/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import md5 from 'blueimp-md5';

class Gravatar extends Component {

  render() {

    const {size} = this.props;
    const {email} = this.props;
    const {className} = this.props;
    const {rating} = this.props;
    const defaultImg = this.props.default;

    const params = [];
    params.push(`r=${rating}`);
    params.push(`d=${defaultImg}`);
    params.push(`s=${size}`);

    const url = `//www.gravatar.com/avatar/${ md5(email)  }?${  params.join('&')}`;

    return (
            <img src={url} className={className} alt={email}/>
    );
    
  }

}
export {Gravatar};

Gravatar.defaultProps = {
  email: PropTypes.string.isRequired,
  size: PropTypes.number,
  className: PropTypes.string,
  rating: PropTypes.oneOf(['g', 'pg', 'r', 'x']),
  default: PropTypes.oneOf(
    ['404', 'mm', 'identicon', 'monsterid', 'wavatar', 'retro', 'blank']
  ),
};

Gravatar.defaultProps = {
  size: 80,
  rating: 'pg',
  default: 'mm'
};

