import { api_getMonitoredItemsList, api_getMonitoredItemStatuses } from '../../utils/Monitoring/Monitoring';
import {
  MONITORING_SET_MONITORED_ITEMS,
  MONITORING_SET_SEARCH_STRINGS,
  MONITORING_TOGGLE_LOADING,
  IMonitoringResetStore,
  MONITORING_RESET_STORE,
  MONITORING_TOGGLE_STATUS_LOADING,
  MONITORING_SET_MONITORED_ITEM_STATUSES,
} from './types';
import { setSystemError } from '../system/actions';
import { defaultErrorFeedback } from '../../actions/feedback';

export const resetMonitoringStore = (): IMonitoringResetStore => ({ type: MONITORING_RESET_STORE });
function setMonitoredItems(items) {

  return {
    type: MONITORING_SET_MONITORED_ITEMS,
    items,
  };

}
function setMonitoredItemStatuses(items) {

  return {
    type: MONITORING_SET_MONITORED_ITEM_STATUSES,
    items,
  };

}
export function toggleMonitoredItemsLoading() {

  return {
    type: MONITORING_TOGGLE_LOADING,
  };

}
export function toggleMonitoredItemsStatusLoading() {

  return {
    type: MONITORING_TOGGLE_STATUS_LOADING,
  };

}
export function setMonitoringSearchString(search: string) {

  return {
    type: MONITORING_SET_SEARCH_STRINGS,
    search,
  };

}
export function getMonitoredItems() {

  return (dispatch) => api_getMonitoredItemsList().then((result) => {

    if (result.status === 200) {

      dispatch(setMonitoredItems(result.data));
    
    } else if (result.data.error && result.data.error.id) {

      dispatch(setSystemError(result.data.error.id));
    
    } else {

      dispatch(defaultErrorFeedback());
    
    }
  
  });

}
export function getMonitoredItemStatuses() {

  return (dispatch) => api_getMonitoredItemStatuses().then((result) => {

    if (result.status === 200) {

      dispatch(setMonitoredItemStatuses(result.data));
    
    } else {

      dispatch(defaultErrorFeedback());
    
    }
  
  });

}
