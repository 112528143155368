import moment from 'moment';
import {
  BroadbandReducerActions,
  IServiceBroadbandState, SERVICE_BROADBAND_RESET_DATA,
  SERVICE_BROADBAND_SET_DATA,
  SERVICE_BROADBAND_SET_USAGE,
  SERVICE_BROADBAND_SET_USAGE_BAR_CHART,
  SERVICE_BROADBAND_SET_USAGE_DATES,
  SERVICE_BROADBAND_SET_USAGE_PIE_CHART,
  SERVICE_BROADBAND_TOGGLE_LOADING,
  SERVICE_BROADBAND_TOGGLE_USAGE_LOADING,
} from './types';

const initialState: IServiceBroadbandState = {
  loading: false,
  data: {
    id: '',
    name: '',
    status: '',
    productCode: '',
    transferLimit: 0,
    pstn: '',
    username: '',
    bearer: '',
    bandwidth: '',
    ips: {},
  },
  usage: {
    loading: false,
    currentDate: moment(),
    nextMonth: moment().add(1, 'months'),
    prevMonth: moment().subtract(1, 'months'),
    data: [],
    barChart: {
      labels: [],
      download: [],
      upload: [],
    },
    pieChart: {
      upload: 0,
      download: 0,
    },
  },
};

export function serviceBroadbandReducer(state = initialState, action: BroadbandReducerActions) {

  switch (action.type) {
    case SERVICE_BROADBAND_SET_DATA:
      return {
        ...state,
        data: action.data,
      };
    case SERVICE_BROADBAND_TOGGLE_LOADING:
      return {
        ...state,
        loading: !state.loading,
      };
    case SERVICE_BROADBAND_SET_USAGE:
      return {
        ...state,
        usage: { ...state.usage, data: action.data },
      };
    case SERVICE_BROADBAND_TOGGLE_USAGE_LOADING:
      return {
        ...state,
        usage: { ...state.usage, loading: !state.usage.loading },
      };
    case SERVICE_BROADBAND_SET_USAGE_BAR_CHART:
      return {
        ...state,
        usage: { ...state.usage, barChart: action.data },
      };
    case SERVICE_BROADBAND_SET_USAGE_PIE_CHART:
      return {
        ...state,
        usage: { ...state.usage, pieChart: action.data },
      };
    case SERVICE_BROADBAND_SET_USAGE_DATES:
      return {
        ...state,
        usage: {
          ...state.usage, currentDate: action.currentDate, prevMonth: action.prevMonth, nextMonth: action.nextMonth,
        },
      };
    case SERVICE_BROADBAND_RESET_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }

}
