/* eslint-disable */
import React from 'react';
import {PropTypes} from 'prop-types';
import {
  CardText,
  FormGroup,
  Label,
  FormFeedback,
  Row,
  Col,
  Input
} from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import {DateTimePicker} from 'react-widgets';
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import TextEditor from '../../components/TextEditor/TextEditor';
import Section from '../../components/Section';

const OtherChange = (props) => {


  return (
        <Section title="Change Request">
            <Row className="mx-0">
                <Col className="p-4 bg-white rounded">
                <AvForm onValidSubmit={props.handleValidSubmit} onInvalidSubmit={props.handleInvalidSubmit}>
                    <FormGroup>
                        <Label for="caseTitle">Title</Label>
                        <AvField type="text" name="caseTitle" id="caseTitle" placeholder="Title of the case" required validate={{maxLength: {value: 200}}}/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="caseCategory">Change Category</Label>
                        <AvField type="select" name="caseCategory" id="caseCategory" required>
                            <option value="">-- Select</option>
                            <option value="Minor">Minor</option>
                            <option value="Major">Major</option>
                        </AvField>
                    </FormGroup>
                    <FormGroup>
                        <Label for="casePriority">Priority</Label>
                        <AvField type="select" name="casePriority" id="casePriority" required>
                            <option value="">-- Select</option>
                            <option value="low">Low</option>
                            <option value="medium">Medium</option>
                            <option value="high">High</option>
                        </AvField>
                    </FormGroup>
                    <CardText>Preferred Implementation</CardText>
                    <FormGroup>
                        <label className="form-check text-dark homeage-to-the-square-checkbox">
                            <Input
                                className="form-check-input"
                                type="checkbox" id="asap"
                                onChange={props.inputOnChange}/>
                            <span className="checkmark" />
                            <label className="form-check-label" htmlFor="asap">ASAP</label>
                        </label>
                    </FormGroup>
                    <CardText>or please specify</CardText>
                    <FormGroup>
                        <DateTimePicker disabled={props.implTimeDisabled} defaultValue={props.implTime} min={new Date()} onChange={props.datetimeOnChange} />
                    </FormGroup>
                    <FormGroup className="clearfix">
                        <Label for="caseReasonForChange">Reason for Change</Label>
                        <AvField type="text" name="caseReasonForChange" id="caseReasonForChange" required />
                    </FormGroup>
                    <FormGroup className="clearfix">
                        <Label for="caseServicesImpacted">Services Impacted (if any)</Label>
                        <AvField type="text" name="caseServicesImpacted" id="caseServicesImpacted" />
                    </FormGroup>
                    <FormGroup>
                        <Label for="caseBusinessImpact">Business Impact (if any)</Label>
                        <AvField type="text" name="caseBusinessImpact" id="caseBusinessImpact" />
                    </FormGroup>
                    <FormGroup>
                        <Label for="caseSite">Site (if applicable)</Label>
                        <AvField type="text" name="caseSite" id="caseSite" placeholder="The site relevant to this change if known" />
                    </FormGroup>
                    <FormGroup color={props.descError ? 'danger' : null}>
                        <Label>Description</Label>
                        <TextEditor callback={props.callback} color={props.descError ? 'danger' : null}/>
                        {props.descError && <FormFeedback>Description is a required field</FormFeedback>}
                    </FormGroup>
                    <div className="clearfix" style={{marginTop: `${10}px`, marginBottom: `${20}px`}}>
                        <LaddaButton
                            style={{minWidth: `${113}px`, minHeight: `${35}px`}}
                            className="btn btn-primary btn-ladda px-4 float-right"
                            disabled={props.processing}
                            loading={props.processing}
                            data-color="blue"
                            data-style={ZOOM_OUT}
                        >
                            Create Case
                        </LaddaButton>
                    </div>
                </AvForm>
                </Col>
            </Row>
        </Section>
  );

};

OtherChange.propTypes = {
  handleValidSubmit: PropTypes.func.isRequired,
  callback: PropTypes.func.isRequired,
  descError: PropTypes.bool.isRequired,
  processing: PropTypes.bool.isRequired,

};
export default OtherChange;
