import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

type TProps = {
  url: string;
  text: string;
};
const ArrowLink: FunctionComponent<TProps> = ({ url, text }) => (
  <Link to={url} className="text-light text-uppercase ">
    {text}
    {' '}
    <i
      style={{ WebkitTextStroke: '2px white' }}
      className="fa fa-arrow-right"
    />
  </Link>
);

export default ArrowLink;
