/* eslint-disable */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {
  CardTitle,
  TabPane,
  TabContent,
  Button,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import classnames from 'classnames';
import {updatedDiff} from 'deep-object-diff';
import DomainContact from './DomainContact';
import Nameservers from './Nameservers';
import { isExpired, isExpiring, validateContact } from '../../../helpers/domains/domains'
import DomainCart from './DomainCart';
import {
  DOMAIN_SET_DOMAIN_CONTACT,
  getDNSSecData,
  getDomainData,
  resetDomain,
  restoreDomainContact,
  restoreDomainInfo,
  setDomainActiveTab,
  setDomainErrors,
  setDomainLoadingText,
  createDomainDNSSec,
  toggleDomainAutoRenew,
  toggleDomainLoading,
  updateDNSSecFormField,
  updateDomainAutoRenew,
  updateDomainContact,
  updateDomainContactField,
  deleteDomainDNSSec,
  resetDNSSecForm
} from '../../../actions/domain';
import SimpleLoader from '../../../components/SimpleLoader';
import DomainInfo from './DomainInfo';
import DomainRelease from './DomainRelease';
import DomainRenew from './DomainRenew';
import Section from '../../../components/Section';
import {checkPermission} from '../../../utils/Auth/AuthService';
import toastr from "toastr";
import DomainDNSSEC from "./DomainDNSSEC";
import {useParams} from "react-router-dom";

class Domain extends Component {
  static contextTypes = {
    router: PropTypes.shape({
      history: PropTypes.shape({
        push: PropTypes.func.isRequired,
        replace: PropTypes.func.isRequired
      }).isRequired,
      staticContext: PropTypes.object
    }).isRequired
  };

  componentDidMount() {

    this.getAccountDomain();

  }

  toggle = (tab) => {

    const {activeTab} = this.props.domain;
    if (activeTab !== tab) {

      this.props.dispatch(setDomainActiveTab(tab));

    }

  };

  getAccountDomain = () =>{

    this.props.dispatch(resetDomain());
    this.props.dispatch(setDomainErrors());
    this.props.dispatch(setDomainLoadingText('Loading domain info...'));
    this.props.dispatch(toggleDomainLoading());
    this.props.dispatch(getDomainData(this.props.params.DomainID)).then(() => {

      this.props.dispatch(toggleDomainLoading());

    });

  };

  toggleAutoRenew = () => {

    this.props.dispatch(toggleDomainAutoRenew());

  };

  updateAutoRenew = () => {

      if(!checkPermission('domains.write', this.props.user.permissions)){
          toastr.error('Unfortunately you are not authorized to do this!');
      }
      else{
          const {infoEdited} = this.props.domain;
          this.props.dispatch(setDomainLoadingText('Updating domain...'));
          this.props.dispatch(toggleDomainLoading());
          this.props.dispatch(updateDomainAutoRenew(infoEdited.id, infoEdited.autoRenew)).then(() => {

              this.props.dispatch(toggleDomainLoading());

          });
      }


  };

  handlePhoneInput = (phone) => {

    const addDot = (str) => {

      const stringWithDot  =`${str   }.`;
      return stringWithDot;

    };
    this.props.dispatch(updateDomainContactField('phone', phone.replace(/\+[0-9]{1,3}\s*/, addDot).split(' ').join('')));

  };

  handleContactInput = (event) =>{

    this.props.dispatch(updateDomainContactField(event.target.id, event.target.value ));

  };

  handleCountrySelect = (value) => {

    this.props.dispatch(updateDomainContactField('country', value ));

  };

  handleCountySelect = (value) => {

    this.props.dispatch(updateDomainContactField('county', value ));

  };

  updateContact = () =>{
      if(!checkPermission('domains.write', this.props.user.permissions)){
          toastr.error('Unfortunately you are not authorized to do this!');
      }
      else{
          this.props.dispatch(setDomainLoadingText('Updating contact..'));
          this.props.dispatch(toggleDomainLoading());
          const {info, contactEdited} = this.props.domain;
          const errors = validateContact(contactEdited);
          this.props.dispatch(setDomainErrors(errors));
          if(Object.keys(errors).length === 0){

              this.props.dispatch(updateDomainContact(info.id, contactEdited)).then((result) => {

                  if(result){

                      this.props.dispatch({type: DOMAIN_SET_DOMAIN_CONTACT, contact: this.props.domain.contactEdited});

                  }
                  this.props.dispatch(toggleDomainLoading());

              });

          } else{

              this.props.dispatch(toggleDomainLoading());

          }
      }


  };

  checkCart = (id) => {

    return this.props.cart[id] !== undefined;

  };

  restoreInfo = () => {

    this.props.dispatch(restoreDomainInfo());

  };

  restoreContact= () => {

    this.props.dispatch(restoreDomainContact());

  };

  getDNSSec = () => {

    return this.props.dispatch(getDNSSecData(this.props.domain.info.id))

  }

  handleDNSSecFormInput = (id, value) => {

    return this.props.dispatch(updateDNSSecFormField(id, value))

  }

  handleDNSSecFormReset = () => {

    return this.props.dispatch(resetDNSSecForm())

  }

  handleDNSSecFormSubmit = (alg, keyTag, digestType, digest) => {

    return this.props.dispatch(createDomainDNSSec(this.props.domain.info.id, alg, keyTag, digestType, digest))

  }

  handleDNSSecDelete = (dnsSec) => {

    return this.props.dispatch(deleteDomainDNSSec(this.props.domain.info.id, dnsSec))

  }

  render(){

    const {info, infoEdited, contact, contactEdited, loading, errors, activeTab, loadingText, dnsSec, newDNSSec} = this.props.domain;
    const expiring = isExpiring(info);
    const expired = isExpired(info);
    const cartItems = Object.keys(this.props.cart).length;
    const colWidth = cartItems ? 8 : 6;
    const contactChanged = Object.keys(updatedDiff(contact, contactEdited)).length > 0;
    return (
            <div className="animated fadeIn">
                <SimpleLoader loading={loading} text={loadingText}>
                    <Section title={info.name}>
                        <Row>
                        <Col>
                            <Nav tabs>
                                <NavItem>
                                    <NavLink href="#"
                                             className={classnames({active: activeTab === '1'})}
                                             onClick={() => {

                                               this.toggle('1');

                                             }}><CardTitle className="mb-0">Info</CardTitle></NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink href="#"
                                             className={classnames({active: activeTab === '2'})}
                                             onClick={() => {

                                               this.toggle('2');

                                             }}><CardTitle className="mb-0">Contact</CardTitle></NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink href="#"
                                             className={classnames({active: activeTab === '3'})}
                                             onClick={() => {

                                               this.toggle('3');

                                             }}><CardTitle className="mb-0">Nameservers</CardTitle></NavLink>
                                </NavItem>

                                {checkPermission('orders.write', this.props.user.permissions) &&
                                <NavItem>
                                    <NavLink href="#"
                                             className={classnames({active: activeTab === '4'})}
                                             onClick={() => {

                                                 this.toggle('4');

                                             }}><CardTitle className="mb-0">Release</CardTitle></NavLink>
                                </NavItem>
                                }
                                {(expiring || expired ) && checkPermission('orders.write', this.props.user.permissions) &&
                                <NavItem>
                                    <NavLink href="#"
                                             className={classnames({active: activeTab === '5'})}
                                             onClick={() => {

                                               this.toggle('5');

                                             }}><CardTitle className="mb-0">Renew</CardTitle></NavLink>
                                </NavItem>
                                }
                                <NavItem>
                                    <NavLink href="#"
                                             className={classnames({active: activeTab === '6'})}
                                             onClick={() => this.toggle('6')}>
                                      <CardTitle className="mb-0">DNSSEC</CardTitle></NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={activeTab} className="border-bottom-0 border-left-0 border-right-0">
                                <TabPane tabId="1">
                                    <Row>
                                        <Col className="p-3" sm={12} md={colWidth}>
                                            {Object.keys(info).length > 0 && <DomainInfo
                                                domain={info}
                                                domainEdited={infoEdited}
                                                account={this.props.account}
                                                toggleRenew={this.toggleAutoRenew}
                                                save={this.updateAutoRenew}
                                                restore={this.restoreInfo}
                                            />
                                            }
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tabId="2">
                                    <Row>
                                        <Col className="p-3" sm={12} md={colWidth}>
                                            <DomainContact
                                                contactEdited={contactEdited}
                                                errors={errors}
                                                handleInput={this.handleContactInput}
                                                handleCountrySelect={this.handleCountrySelect}
                                                handleCountySelect={this.handleCountySelect}
                                                handlePhoneInput={this.handlePhoneInput}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col md="4">
                                            <Button className={contactChanged ? 'mr-3' : ''} disabled={!contactChanged} onClick={this.updateContact}>Update</Button>
                                            {contactChanged && <Button outline color="warning" onClick={this.restoreContact}>Cancel</Button>}
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tabId="3">
                                    <Nameservers/>
                                </TabPane>
                              {checkPermission('orders.write', this.props.user.permissions) &&
                              <>
                                <TabPane tabId="4">
                                  <DomainRelease/>
                                </TabPane>
                                <TabPane tabId="5">
                                  <DomainRenew/>
                                </TabPane>
                              </>
                              }
                              <TabPane tabId="6">
                                {activeTab === '6' ? (
                                  <DomainDNSSEC
                                    data={dnsSec}
                                    newDNSSecData={newDNSSec}
                                    getDNSSecData={this.getDNSSec}
                                    handleDelete={this.handleDNSSecDelete}
                                    handleFormInput={this.handleDNSSecFormInput}
                                    handleFormSubmit={this.handleDNSSecFormSubmit}
                                    handleFormReset={this.handleDNSSecFormReset}
                                    user={this.props.user}/>
                                ) : ''}
                              </TabPane>

                            </TabContent>
                        </Col>
                        {cartItems > 0 && <Col md={3} sm={12}><DomainCart/></Col>}
                    </Row>
                    </Section>
                </SimpleLoader>
            </div>
    );

  }
}
const DomainWrapper = (props) => {
    const params = useParams();
    return <Domain params={params} {...props}/>
}
function mapStateToProps ({ domainCart, user, account, domain }) {

  return {
    cart: domainCart,
    user,
    domain,
    account
  };

}
export default connect(mapStateToProps)(DomainWrapper);
