import moment from 'moment';
import {
  IMonitoredItemState,
  MONITORED_ITEM_RESET_POPUP_GRAPH,
  MONITORED_ITEM_RESET_STATE,
  MONITORED_ITEM_SET_GRAPH_DATA,
  MONITORED_ITEM_SET_GRAPH_DATE_RANGE,
  MONITORED_ITEM_SET_MONITORED_ITEM,
  MONITORED_ITEM_SET_POPUP_GRAPH_DATA,
  MONITORED_ITEM_TOGGLE_GRAPH_LOADING,
  MONITORED_ITEM_TOGGLE_LOADING,
  MONITORED_ITEM_TOGGLE_POPUP_GRAPH,
  MONITORED_ITEM_TOGGLE_POPUP_GRAPH_LOADING,
  MonitoredITemActionTypes,
  MONITORED_ITEM_TOGGLE_DESC_EDIT,
  MONITORED_ITEM_SET_DESCRIPTION_FORM,
  MONITORED_ITEM_DESC_SAVE,
  MONITORED_ITEM_TOGGLE_GRAPH_OPEN,
  MONITORED_ITEM_REVERT_DESC,
  MONITORED_ITEM_NT_SET_PING_DATA,
  MONITORED_ITEM_NT_SET_TRACE_ROUTE_DATA,
  MONITORED_ITEM_NT_SET_SHOW_ROUTE_DATA, MONITORED_ITEM_NT_SET_SHOW_ROUTE_DETAILS,
} from './types';

const initialState: IMonitoredItemState = {
  loading: false,
  item: {
    id: '',
    type: '',
    location: '',
    name: '',
    template: '',
    available: false,
    error: '',
    cpu: 0,
    latency: 0,
    uptime: 0,
    memoryUse: 0,
    graphs: [],
    services: [],
    description: '',
    descriptionEditable: false,
    descriptionFormField: '',
    tempDesc: '',
    networkToolsData: {},
  },
  popUpGraph: {
    loading: false,
    id: '',
    title: '',
    isOpen: false,
    tracker: null,
    max: 0,
    multiplier: 1,
    startDate: moment().startOf('day'),
    endDate: moment(),
    history: [],
  },
};
export function monitoredItemReducer(state = initialState, action: MonitoredITemActionTypes) {

  switch (action.type) {
    case MONITORED_ITEM_REVERT_DESC: {

      return {
        ...state,
        item: {
          ...state.item,
          description: state.item.tempDesc,
        },
      };
    
    }
    case MONITORED_ITEM_TOGGLE_GRAPH_OPEN: {

      const { item } = state;
      const { graphs } = item;
      const { payload } = action;
      const updatedGraphs = graphs.map((graph) => {

        if (graph.id === payload) {

          return { ...graph, isOpen: !graph.isOpen };
        
        }

        return graph;
      
      });
      return {
        ...state,
        item: { ...state.item, graphs: [...updatedGraphs] },
      };
    
    }
    case MONITORED_ITEM_DESC_SAVE: {

      return {
        ...state,
        item: {
          ...state.item,
          description: state.item.descriptionFormField,
          tempDesc: state.item.description,
        },
      };
    
    }
    case MONITORED_ITEM_TOGGLE_DESC_EDIT: {

      if (!state.item.descriptionEditable) {

        return {
          ...state,
          item: { ...state.item, descriptionEditable: true, descriptionFormField: state.item.description },
        };
      
      }
      return {
        ...state,
        item: { ...state.item, descriptionEditable: false },
      };
    
    }
    case MONITORED_ITEM_SET_DESCRIPTION_FORM:
      return {
        ...state,
        item: {
          ...state.item,
          descriptionFormField: action.payload,
        },
      };
    case MONITORED_ITEM_SET_MONITORED_ITEM:
      const graphs = action.item.graphs.map((graph) => ({
        ...graph,
        loading: false,
        dataIn: { label: 'In', data: [] },
        dataOut: { label: 'Out', data: [] },
        startDate: moment().startOf('day'),
        endDate: moment(),
        error: false,
        isOpen: false,
      }));
      return {
        ...state,
        item: { ...action.item, graphs },
      };
    case MONITORED_ITEM_RESET_POPUP_GRAPH:
      return {
        ...state,
        popUpGraph: { ...initialState.popUpGraph },
      };
    case MONITORED_ITEM_TOGGLE_LOADING:
      return {
        ...state,
        loading: !state.loading,
      };
    case MONITORED_ITEM_RESET_STATE:
      return {
        ...initialState,
      };
    case MONITORED_ITEM_TOGGLE_POPUP_GRAPH:
      return {
        ...state,
        popUpGraph: { ...state.popUpGraph, isOpen: !state.popUpGraph.isOpen },
      };
    case MONITORED_ITEM_SET_POPUP_GRAPH_DATA:
      return {
        ...state,
        popUpGraph: { ...state.popUpGraph, [action.key]: action.value },
      };
    case MONITORED_ITEM_TOGGLE_POPUP_GRAPH_LOADING:
      return {
        ...state,
        popUpGraph: { ...state.popUpGraph, loading: !state.popUpGraph.loading },
      };
    case MONITORED_ITEM_SET_GRAPH_DATE_RANGE:
      const graphsMapped = state.item.graphs.map((graph) => {

        if (graph.id === action.graphId) {

          return { ...graph, startDate: action.startDate, endDate: action.endDate };
        
        }
        return { ...graph };
      
      });
      return {
        ...state,
        item: { ...state.item, graphs: graphsMapped },
      };
    case MONITORED_ITEM_SET_GRAPH_DATA:
      const graphsUpdated = state.item.graphs.map((graph) => {

        if (graph.id === action.graphId) {

          graph.dataIn = action.dataIn;
          graph.dataOut = action.dataOut;
        
        }
        return graph;
      
      });
      return {
        ...state,
        item: { ...state.item, graphs: [...graphsUpdated] },
      };
    case MONITORED_ITEM_TOGGLE_GRAPH_LOADING:
      const graphsLoading = state.item.graphs.map((graph) => {

        if (graph.id === action.graphId) {

          graph.loading = !graph.loading;
        
        }
        return graph;
      
      });
      return {
        ...state,
        item: { ...state.item, graphs: [...graphsLoading] },
      };
    case MONITORED_ITEM_NT_SET_PING_DATA:
      return {
        ...state,
        item: {
          ...state.item,
          networkToolsData: {
            ...state.item.networkToolsData,
            pingData: action.data,
          },
        },
      };
    case MONITORED_ITEM_NT_SET_TRACE_ROUTE_DATA:
      return {
        ...state,
        item: {
          ...state.item,
          networkToolsData: {
            ...state.item.networkToolsData,
            traceRouteData: action.data,
          },
        },
      };
    case MONITORED_ITEM_NT_SET_SHOW_ROUTE_DATA:
      return {
        ...state,
        item: {
          ...state.item,
          networkToolsData: {
            ...state.item.networkToolsData,
            showRouteData: action.data,
          },
        },
      };
    case MONITORED_ITEM_NT_SET_SHOW_ROUTE_DETAILS:
      return {
        ...state,
        item: {
          ...state.item,
          networkToolsData: {
            ...state.item.networkToolsData,
            showRouteDetails: action.data,
          },
        },
      };
    default:
      return {
        ...state,
      };
  }

}
