import React, { FunctionComponent } from 'react';
import { Col } from 'reactstrap';
import ArrowLink from '../../Buttons/ArrowLink';
import SimpleLoader from '../../SimpleLoader';

type Callout = {
  color: string;
  title: string;
  value: string | number;
  link: string;
  loading?: boolean;
};

const CalloutWidget: FunctionComponent<Callout> = (props) => {

  const {
    color, title, value, link, loading,
  } = props;

  return (
    <div
      style={{ borderLeftWidth: '10px', borderRadius: '10px' }}
      className={`shadow-sm pr-5 pl-5 py-4 callout callout-${color} bg-white d-flex flex-column w-100`}
    >
      <SimpleLoader loading={loading !== undefined ? loading : false}>
        <h5 style={{ letterSpacing: '2px', fontWeight: 600 }} className="text-uppercase text-light">
          {title}
        </h5>

        <div className="d-flex flex-row justify-content-between">
          <div
            data-testid="CalloutValue"
            style={{ fontSize: '80px' }}
            className="h4 mb-0 text-dark font-weight-bold"
          >
            {value}
          </div>

          <div className="mt-auto">
            <div data-testid="CalloutLink" className="d-block mb-3">
              <ArrowLink url={link} text="view all" />
            </div>
          </div>
        </div>
      </SimpleLoader>
    </div>
  );

};

export default CalloutWidget;
