import { useCallback, useRef, useState } from 'react'
import axios, { AxiosResponse } from 'axios'

const OAUTH_STATE_KEY = 'react-use-oauth2-state-key'
const POPUP_HEIGHT = 700
const POPUP_WIDTH = 600
const OAUTH_RESPONSE = 'react-use-oauth2-response'

// https://medium.com/@dazcyril/generating-cryptographic-random-state-in-javascript-in-the-browser-c538b3daae50
const generateState = () => {

    const validChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    const array: Uint8Array = new Uint8Array(40);
    window.crypto.getRandomValues(array)
    return String.fromCharCode.apply(null, Array.from(array).map((x) => validChars.codePointAt(x % validChars.length) || 0))

}

const saveState = (state: string) => {

    sessionStorage.setItem(OAUTH_STATE_KEY, state)

}

const removeState = () => {

    sessionStorage.removeItem(OAUTH_STATE_KEY)

}

const openPopup = (url) => {

    // To fix issues with window.screen in multi-monitor setups, the easier option is to
    // center the pop-up over the parent window.
    const top = ((window.outerHeight / 2) + window.screenY) - (POPUP_HEIGHT / 2)
    const left = ((window.outerWidth / 2) + window.screenX) - (POPUP_WIDTH / 2)
    return window.open(
        url,
        'OAuth2 Popup',
        `height=${POPUP_HEIGHT},width=${POPUP_WIDTH},top=${top},left=${left}`,
    )

}

const closePopup = (popupRef) => {

    popupRef.current?.close()

}

const cleanup = (intervalRef, popupRef, handleMessageListener) => {

    clearInterval(intervalRef.current)
    closePopup(popupRef)
    removeState()
    window.removeEventListener('message', handleMessageListener)

}

const enhanceAuthorizeUrl = (authorizeUrl, clientId, redirectUri, scope, state) => {

    return `${authorizeUrl}?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&state=${state}`

}

type TProps = {
    authorizeUrl: string,
    clientId: string,
    redirectUri: string,
    scope: string,
    exchangeCodeForTokenServerURL: string,
    responseType: string,
    onSuccess: (result: TAuthTokenResult) => any
    onError: (error: string) => any
}

export type TAuthTokenResult = {
    id_token: string,
    access_token: string,
    refresh_token: string,
}

export const useOAuth2 = ({ authorizeUrl, clientId, redirectUri, scope, exchangeCodeForTokenServerURL, responseType, onSuccess, onError }: TProps) => {

    const popupRef = useRef<Window|null>(null)
    const intervalRef = useRef<any>()

    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string|null>(null)
    const [data, setData] = useState<TAuthTokenResult|null>(null)

    const getAuth = useCallback(() => {

        // 1. Init
        setLoading(true)
        setError(null)

        // 2. Generate and save state
        const state = generateState()
        saveState(state)

        // 3. Open popup
        popupRef.current = openPopup(
            enhanceAuthorizeUrl(authorizeUrl, clientId, redirectUri, scope, state),
        )

        // 4. Register message listener
        async function handleMessageListener(message) {

            try {

                const type = message && message.data && message.data.type
                if (type === OAUTH_RESPONSE) {

                    const errorMaybe = message && message.data && message.data.error
                    if (errorMaybe) {

                        setLoading(false)
                        setError(errorMaybe || 'Unknown Error')

                    } else {

                        const code = message && message.data && message.data.payload && message.data.payload.code
                        const response: AxiosResponse<TAuthTokenResult> = await axios.post(exchangeCodeForTokenServerURL, new URLSearchParams({
                            grant_type: 'authorization_code',
                            client_id: clientId,
                            code,
                            redirect_uri: redirectUri
                        }));
                        if (response.status !== 200) {

                            setLoading(false);
                            setError('Failed to exchange code for token');
                            onError(response.data.toString())

                        } else {

                            const payload = response.data;
                            setLoading(false);
                            setError(null);
                            setData(payload);
                            onSuccess(payload)

                        }

                    }

                }

            } catch (genericError: any) {

                console.error(genericError)
                setLoading(false)
                setError(genericError.toString())

            } finally {

                // Clear stuff ...
                cleanup(intervalRef, popupRef, handleMessageListener)

            }

        }

        window.addEventListener('message', handleMessageListener)

        // 5. Begin interval to check if popup was closed forcefully by the user
        intervalRef.current = setInterval(() => {

            const popupClosed = !popupRef.current || !popupRef.current.window || popupRef.current.window.closed
            if (popupClosed) {

                // Popup was closed before completing auth...
                setLoading(false)
                console.warn('Warning: Popup was closed before completing authentication.')
                clearInterval(intervalRef.current)
                removeState()
                window.removeEventListener('message', handleMessageListener)

            }

        }, 250)

        // Remove listener(s) on unmount
        return () => {

            window.removeEventListener('message', handleMessageListener)
            if (intervalRef.current) clearInterval(intervalRef.current)

        }

    }, []);

    return {
        data,
        loading,
        error,
        getAuth,
        popupRef
    }

}
