import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import fileDownload from 'js-file-download';
import { AxiosResponse } from 'axios';
import numeral from 'numeral';
import ComplexTable from '../../components/Table/ComplexTable';
import { IUserState } from '../../store/user/types';
import { IBillingState } from '../../store/billing/types';
import { checkPermission } from '../../utils/Auth/AuthService';
import {
  getAccountBills,
  setArtifactModalBill, setBillingSearchString,
  toggleArtifactsModal,
  toggleBillingLoading,
} from '../../store/billing/actions';
import { setSystemForbiddenError } from '../../store/system/actions';
import Section from '../../components/Section';
import Download from '../../components/SvgIcons/Download';
import SimpleLoader from '../../components/SimpleLoader';
import { api_downloadBillArtifacts } from '../../utils/Billing';
import { defaultErrorFeedback, errorFeedback } from '../../actions/feedback';
import BillArtifactsModal from '../../components/Modals/BillArtifacts';
import SearchInput from '../../components/Inputs/SearchInput';
import { IAccountState } from '../../store/account/types';
import ButtonLink from '../../components/Buttons/ButtonLink';
import { AppDispatch } from '../../configureStore';

type TOwnProps = {
  user: IUserState;
  account: IAccountState;
  billing: IBillingState;
  dispatch: AppDispatch;
};

const Billing: React.FC<TOwnProps> = (props) => {

  const { billing, account, dispatch } = props;
  useEffect(() => {

    if (checkPermission('billing.read', props.user.permissions) || checkPermission('billing.write', props.user.permissions)) {

      getBills();
    
    } else {

      props.dispatch(setSystemForbiddenError());
    
    }
  
  }, []);
  const getBills = () => {

    dispatch(toggleBillingLoading());
    dispatch(getAccountBills()).then(() => dispatch(toggleBillingLoading()));
  
  };

  const download = (id: number, documentId: number) => {

    dispatch(toggleBillingLoading());
    api_downloadBillArtifacts(id, documentId).then((result: AxiosResponse) => {

      if (result.status === 200) {

        const splitContentDisposition = result.headers['content-disposition'].split('=');
        fileDownload(result.data, splitContentDisposition[1]);
      
      } else {

        dispatch(defaultErrorFeedback());
      
      }
      dispatch(toggleBillingLoading());
    
    });
  
  };
  const onChangeSearch = (event) => {

    const searchString = event.target.value.toString().toLowerCase();
    dispatch(setBillingSearchString(searchString));
  
  };
  const toggleModal = () => {

    dispatch(toggleArtifactsModal());
  
  };
  const setModalBill = (id) => {

    dispatch(setArtifactModalBill(id));
    toggleModal();
  
  };

  const columns = [
    {
      Header: 'Invoice',
      accessor: 'id',
    },
    {
      Header: 'Date',
      accessor: 'date',
    },
    {
      Header: 'Total',
      accessor: 'totalValue',
      Cell: (props) => `£${numeral(props.value).format('0,0.00')}`,
    },
    {
      Header: '',
      filterable: false,
      sortable: false,
      accessor: 'id',
      width: 100,
      Cell: (props) => {

        if (props.original && props.original.artifacts.length > 0) {

          return (
            <div className="d-flex flex-row" style={{ cursor: 'pointer' }}>
              <div onClick={setModalBill.bind(null, props.value)}>
                <Download title="Download" />
              </div>
              {account.cardPayer && !props.original.payment.paid
                            && (
                            <div className="ml-2">
                              <ButtonLink url={`/billing/pay?invoice=${props.value}`} text="Pay" size="sm" />
                            </div>
                            )}
            </div>
          );
        
        }
        return <></>;
      
      },
    },
  ];

  return (
    <div className="animated fadeIn mb-3">
      <Row>
        <Col>
          <Section title="Your Bills">
            <Row className="mb-4">
              <Col md={6}>
                <SearchInput value={billing.searchString} onChange={onChangeSearch} />
              </Col>
            </Row>

            <SimpleLoader loading={billing.loading}>
              <Row className="d-flex ml-0 mr-0">
                <Col className="bg-white rounded p-4">
                  <ComplexTable columns={columns} data={billing.searchString ? billing.filtered : billing.bills} defaultSorted={{ id: 'date', desc: true }} />
                </Col>
              </Row>
            </SimpleLoader>
          </Section>
        </Col>
      </Row>
      <BillArtifactsModal
        isOpen={billing.artifactModal.show}
        loading={billing.loading}
        cancel={toggleModal}
        bill={billing.artifactModal.bill}
        handleDownload={download}
      />
    </div>
  );

};

function mapStateToProps({ user, billing, account }) {

  return {
    billing,
    user,
    account,
  };

}
export default connect(mapStateToProps)(Billing);
