import React, { FunctionComponent } from 'react';
import { Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { MonitoredDeviceWidget } from '../../../store/monitoring/types';

type TOwnProps = {
  upTitle: string;
  upValue: string;
  upValueUrl?: string;
  downTitle: string;
  downValue: string;
  downValueUrl?: string;

};

const OneUpOneDown: FunctionComponent<TOwnProps> = (props) => {

  const {
    upTitle, upValue, upValueUrl, downTitle, downValue, downValueUrl,
  } = props;
  return (
    <div
      className="shadow-sm pt-3 bg-white d-flex flex-column monitoring-widget w-100 h-100"
      style={{ borderRadius: '10px' }}
    >
      <Row className="mx-0  mb-3 px-2">
        <Col className="d-flex flex-column light-border--bottom pb-2">
          <h5 className="text-light text-uppercase font-xl text-left">
            {upTitle}
          </h5>
          <div className="pt-0">
            <div className="font-weight-bold text-dark pt-0 text-left">
              {upValueUrl ? (<Link to={upValueUrl}>{upValue}</Link>) : upValue}
            </div>
          </div>
        </Col>
      </Row>
      <Row className="mx-0  mb-3 px-2">
        <Col className="d-flex flex-column ">
          <h5 className="text-light text-uppercase font-xl text-left">
            {downTitle}
          </h5>
          <div className=" pt-0">
            <div className="font-weight-bold text-dark pt-0 text-left">
              {downValueUrl ? (<Link to={downValueUrl}>{downValue}</Link>) : downValue}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );

};
export default OneUpOneDown;
