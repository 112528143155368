import thunk from 'redux-thunk';
import { applyMiddleware } from 'redux';
import { createStateSyncMiddleware } from 'redux-state-sync';
import { PERSIST, PURGE, REHYDRATE } from 'redux-persist/es/constants';
import activityLogger from './activityLogger';
import partnerCustomer from './partnerCustomer';
import { AUTHENTICATE_SOCKET, JOIN_ROOMS, LEAVE_ROOM, PING } from '../store/socketio/types';
import { SOCKET_LOGOUT } from '../store/user/types';
import { ADDJTISUB, DELALLJTISUBS, DELJTISUB } from '../store/JTI/types';
import { WEATHERMAP_SUBSCRIBE } from '../store/weatherMap/types';
import SocketIOClient from './socketio/client';
import createSocketMiddleware from './socketio/middleware';

const config = {
  broadcastChannelOption: { type: 'localstorage' },
  predicate: action => ![PERSIST, PURGE, REHYDRATE].includes(action.type) && !action.type.startsWith('SERVICE'),
};

export const socketIOClient = new SocketIOClient();
socketIOClient.connect(process.env.REACT_APP_SOCKETIO);

// a list of actions to send to socketIO
const socketMiddleware = createSocketMiddleware(socketIOClient.manager.socket('/'), [
  AUTHENTICATE_SOCKET, SOCKET_LOGOUT,
  JOIN_ROOMS, LEAVE_ROOM,
  PING,
  WEATHERMAP_SUBSCRIBE,
  ADDJTISUB, DELALLJTISUBS, DELJTISUB,
]);

export default applyMiddleware(createStateSyncMiddleware(config), thunk, socketMiddleware, activityLogger, partnerCustomer);
