import React, { FunctionComponent, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import numeral from 'numeral';
import {
  Alert, CardTitle, Col, Row,
} from 'reactstrap';
import queryString from 'query-string';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import CardPayment from '../../components/Forms/CardPayment';
import { ICardPaymentState, TBrowserDetails, TCardErrors } from '../../store/cardPayment/types';
import Section from '../../components/Section';
import {
  getCardPaymentBill,
  processCardPayment,
  setBillToPaid,
  setCardErrors,
  setCardPaymentError,
  setCardPaymentRedirect,
  toggleCardPaymentLoading,
  toggleCardPaymentProcessing,
  updateCardAddress,
  updateCardDetails,
} from '../../store/cardPayment/actions';
import { validateCardDetails } from '../../helpers/creditcards';
import SimpleLoader from '../../components/SimpleLoader';
import { setSystemError } from '../../store/system/actions';
import TextInput from '../../components/Inputs/TextInput';
import { setDomainCheckoutCardRedirect } from '../../actions/domainCheckout';
import { IAccountState } from '../../store/account/types';
import { AppDispatch } from '../../configureStore';

type TPay = {
  cardPayment: ICardPaymentState;
  account: IAccountState;
  dispatch: AppDispatch;
};
type TParams = { invoice?: string };
type Props = TPay;

const Pay: FunctionComponent<Props> = (props) => {

  const location = useLocation();

  const { cardPayment, account, dispatch } = props;
  const {
    cardErrors, cardDetails, cardAddress, error, loading, processing, bill, cardRedirect, showThankYou,
  } = cardPayment;
  const queryParams = queryString.parse(location.search) as TParams;

  useEffect(() => {

    if (!queryParams.invoice) {

      dispatch(setSystemError('missing invoice id'));
    
    } else {

      dispatch(toggleCardPaymentLoading());
      dispatch(getCardPaymentBill(queryParams.invoice)).then(() => {

        dispatch(toggleCardPaymentLoading());
      
      });
      dispatch(updateCardAddress('addressLine1', account.addressLine1 || ''));
      dispatch(updateCardAddress('addressLine2', account.addressLine2 || ''));
      dispatch(updateCardAddress('city', account.city || ''));
      dispatch(updateCardAddress('postcode', account.postcode || ''));
    
    }
  
  }, []);

  const handleInput = (field: string, value: string | boolean) => {

    dispatch(updateCardDetails(field, value));
  
  };

  const handleAddressInput = (field: string, value: string) => {

    dispatch(updateCardAddress(field, value));
  
  };

  const getBrowserDetails = (): TBrowserDetails => {

    let colorDepth: number;
    const allowedColorDepths = [1, 4, 8, 15, 16, 24, 32, 48];
    if (allowedColorDepths.includes(window.screen.colorDepth)) {

      colorDepth = window.screen.colorDepth;
    
    } else {

      colorDepth = 1;
      for (let i = 0; i < allowedColorDepths.length; i++) {

        if (window.screen.colorDepth >= allowedColorDepths[i] && (!allowedColorDepths[i + 1] || window.screen.colorDepth < allowedColorDepths[i + 1])) {

          colorDepth = allowedColorDepths[i];
        
        }
      
      }
    
    }
    return {
      javascriptEnabled: true,
      javaEnabled: navigator.javaEnabled(),
      colorDepth,
      screenHeight: window.screen.height,
      screenWidth: window.screen.width,
      tz: new Date().getTimezoneOffset(),
      language: window.navigator.language,
    };
  
  };

  const processPayment = () => {

    dispatch(toggleCardPaymentProcessing());
    validateCardDetails(cardDetails, cardAddress).then((errors: TCardErrors) => {

      dispatch(setCardErrors(errors));
      if (Object.keys(errors).length > 0) {

        dispatch(setCardErrors(errors));
        dispatch(toggleCardPaymentProcessing());
      
      } else {

        dispatch(setCardPaymentError(''));
        dispatch(processCardPayment(bill, cardDetails, cardAddress, getBrowserDetails())).then((result) => {

          if (result && result.success) {

            dispatch(setBillToPaid());
            dispatch(toggleCardPaymentProcessing());
          
          } else if (result && result.redirect) {

            dispatch(setCardPaymentRedirect({ ...result.redirect }));
            dispatch(setDomainCheckoutCardRedirect({ ...result.redirect }));
          
          } else {

            dispatch(setCardPaymentError('Payment failed.'));
            dispatch(toggleCardPaymentProcessing());
          
          }
        
        });
      
      }
    
    });
  
  };

  return (
    <div className="animated fadeIn mb-3">
      <Section title="Card Payment">
        <SimpleLoader loading={loading} text="Loading invoice details...">
          {bill.payment.paid
                && (
                <Alert color="success">
                  {showThankYou ? 'Thank you for your payment' : `This invoice was paid on ${moment(bill.payment.date).format('MM/DD/YYYY')}`}
                </Alert>
                )}
          {error && <Alert color="danger">{error}</Alert>}
          <Row className="mb-3">
            <Col md="6">
              {processing
                        && (
                        <Row>
                          <Col>
                            <div className="pt-4 bg-white rounded mx-0  mb-3 px-4 pb-4" style={{ minHeight: '150px' }}>
                              <SimpleLoader loading={processing} text="Card payment in progress, please do not navigate away from this page..." />
                            </div>
                          </Col>
                        </Row>
                        )}
              {cardRedirect.md || cardRedirect.threeDSSessionData ? (
                <div className="embed-responsive embed-responsive-21by9" style={{ minHeight: '500px' }}>
                  <iframe
                    title="3d Secure"
                    sandbox="allow-same-origin allow-forms allow-scripts"
                    className="embed-responsive-item"
                    src="/card3dSecure"
                    data-url="test"
                  />
                </div>
              ) : ''}
              {!processing
                        && (
                        <div className="pt-4 bg-white rounded mx-0  mb-3 px-4 pb-4">
                          <CardPayment
                            cardDetails={cardDetails}
                            cardAddress={cardAddress}
                            handlePay={processPayment}
                            handleInput={handleInput}
                            handleAddressInput={handleAddressInput}
                            errors={cardErrors}
                            disabled={bill.payment.paid}
                          />
                        </div>
                        )}
            </Col>
            <Col md={6}>
              <div className="pt-4 bg-white rounded mx-0  mb-3 px-4 pb-4">
                <TextInput label="Invoice" id="billId" value={bill.id} readOnly />
                <TextInput label="Tax Date" id="billDate" value={bill.date} readOnly />
                <TextInput
                  label="Amount"
                  id="billValue"
                  value={`£${numeral(bill.totalValue).format('0,0.00')}`}
                  readOnly
                />
                {bill.credit > 0
                            && (
                            <>
                              <TextInput
                                label="In Credit"
                                id="billCredit"
                                value={`£${numeral(bill.credit).format('0,0.00')}`}
                                readOnly
                              />
                              <TextInput
                                label="To Pay"
                                id="toPay"
                                value={`£${numeral(bill.toPay).format('0,0.00')}`}
                                readOnly
                              />
                            </>
                            )}
              </div>
            </Col>
          </Row>

        </SimpleLoader>
      </Section>
    </div>

  );

};

function mapStateToProps({ cardPayment, user, account }) {

  return {
    cardPayment,
    user,
    account,
  };

}
export default connect(mapStateToProps)(Pay);
