import React, { FunctionComponent, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Col, Row, Button } from 'reactstrap';
import moment from 'moment';
import ComplexTable from '../../components/Table/ComplexTable';
import { IUserState } from '../../store/user/types';
import Section from '../../components/Section';
import SearchInput from '../../components/Inputs/SearchInput';
import { IOrdersState } from '../../store/orders/types';
import {
  getAccountOrders, setOrdersSearchString, toggleOrdersLoading, resetOrdersStore,
} from '../../store/orders/actions';
import SearchIcon from '../../components/SvgIcons/SearchIcon';
import SimpleLoader from '../../components/SimpleLoader';
import Refresh from '../../components/SvgIcons/Refresh';
import { ACCOUNT_TYPE_RESALE_PARTNER, IAccountState } from '../../store/account/types';
import { TPriceList } from '../../store/priceList/types';
import { AppDispatch } from '../../configureStore';

type TOwnProps = {
  user: IUserState;
  priceList?: TPriceList;
  orders: IOrdersState;
  dispatch: AppDispatch;
  account: IAccountState;
};
const Orders: FunctionComponent<TOwnProps> = (props) => {

  const { dispatch, account } = props;
  const {
    orders, filtered, loading, searchString,
  } = props.orders;

  useEffect(() => {

    if (orders.length === 0 || account.accountTypeCode === ACCOUNT_TYPE_RESALE_PARTNER) {

      refreshStore();
    
    }
  
  }, []);

  const navigate = useNavigate();

  const refreshStore = () => {

    dispatch(resetOrdersStore());
    dispatch(toggleOrdersLoading());
    dispatch(getAccountOrders()).then(() => dispatch(toggleOrdersLoading()));
  
  };
  const onChangeSearch = (e) => {

    const searchString = e.target.value.toString().toLowerCase();
    dispatch(setOrdersSearchString(searchString));
  
  };
  const columns = [
    {
      Header: 'Order Number',
      accessor: 'orderNumber',
      width: 200,
    },
    {
      Header: 'Description',
      accessor: 'name',
      minWidth: 500,
    },
    {
      Header: 'Order Status',
      accessor: 'status',
      width: 200,
    },
    {
      Header: 'Date Created',
      accessor: 'createdOn',
      Cell: (props) => (props.value ? moment(props.value).format('DD/MM/YYYY') : <></>),
      width: 150,
    },
    {
      Header: '',
      accessor: 'id',
      width: 50,
      filterable: false,
      sortable: false,
      Cell: (props) => (
        <Link className="grey-icon rounded" to={`/orders/${props.original.orderNumber}`}>
          <SearchIcon title="details" />
        </Link>
      ),
    },
  ];
  return (
    <div className="animated fadeIn mb-3">
      <Section title="Orders">
        <Row className="mb-4">
          <Col md="5">
            <SearchInput value={searchString} onChange={onChangeSearch} />
          </Col>
          <Col md="7" className="d-flex justify-content-end">
            {props.priceList?.items.length ? (
              <Button className="mr-2" color="primary" onClick={() => navigate('/orders/new')}>
                New Order
              </Button>
            ) : ''}
            <Button className="pt-1 px-1" color="primary" onClick={() => refreshStore()}>
              <Refresh title="Refresh" cssClassTop="refresh-svg" />
            </Button>
          </Col>
        </Row>
        <Row className="mt-2 mr-0 ml-0">
          <Col className="p-4 bg-white rounded">
            <SimpleLoader loading={loading} text="Loading your orders...">
              <ComplexTable
                columns={columns}
                data={searchString ? filtered : orders}
                defaultSorted={{ id: 'createdOn', desc: true }}
              />
            </SimpleLoader>
          </Col>
        </Row>
      </Section>
    </div>
  );

};
function mapStateToProps({
  priceList, orders, user, account,
}) {

  return {
    priceList: priceList.data,
    orders,
    user,
    account,
  };

}
export default connect(mapStateToProps)(Orders);
