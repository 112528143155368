import React, { FunctionComponent } from 'react';
// import Gauge from 'react-svg-gauge';
import { Col, Row } from 'reactstrap';
import { Sparklines, SparklinesLine, SparklinesCurve } from 'react-sparklines';
import ReactTooltip from 'react-tooltip';
import ArrowLink from '../../Buttons/ArrowLink';
import {
  MonitoredDeviceWidget,
  MonitoredPortWidget,
} from '../../../store/monitoring/types';
import CloudDown from '../../SvgIcons/CloudDown';
import CloudUp from '../../SvgIcons/CloudUp';
import { formatData } from '../../../helpers/FormatData/formatData';
import SimpleLoader from '../../SimpleLoader';

type TOwnProps = {
  widget: MonitoredDeviceWidget | MonitoredPortWidget;
  index: number;
  deleteWidget: (id: string, name: string, location: string) => any;
  WType: 'isDevice' | 'isPort' | 'isOffline' | 'isInactive' | 'hasError';
};

const MonitoredDevice: FunctionComponent<TOwnProps> = (props) => {

  const { WType } = props;
  const {
    id,
    name,
    latency,
    cpu,
    location,
    link,
    description,
    uplink,
    uplinkError,
    loading,
  } = props.widget;

  const locationToUse = () => {

    if (description) return description;
    if (location) return location;
    return 'Unknown location';
  
  };
  const handleDelete = (event: React.MouseEvent<HTMLElement>) => {

    props.deleteWidget(id, name, location);
  
  };

  const renderWidgetHeader = () => (
    <Row className="mx-0  light-border light-border--bottom pt-1">
      <ReactTooltip />
      <Col className="d-flex flex-column" data-tip={locationToUse()}>
        <h6
          data-testid="location"
          className="text-uppercase font-weight-bold truncate text-center"
          style={{ letterSpacing: '0px' }}
        >
          {(['isDevice', 'isPort', 'isOffline', 'isInactive'].includes(WType))
                        && (
                        <>
                          {' '}
                          {locationToUse()}
                        </>
                        )}
          {(WType === 'hasError')
                        && <>   Unable to reach device</>}
        </h6>
      </Col>
    </Row>
  );

  const renderWidgetFooter = (handleDelete) => (
    <Row className="light-border light-border--top pt-2">
      <Col>
        <div className="ml-2 mt-n3">
          <i
            style={{ cursor: 'pointer' }}
            onClick={handleDelete}
            className="text-danger fa fa-trash pl-1 pt-3"
          />
        </div>
      </Col>
      {WType !== 'isInactive' ? (
        <Col>
          <div className="float-right mr-2" data-testid="link">
            <ArrowLink url={link} text="view" />
          </div>
        </Col>
      ) : ''}
    </Row>
  );

  const renderRedIconRow = () => (
    <Row className="m-0 p-2">
      <Col
        style={{ height: '165px' }}
        className="d-flex flex-column align-items-grow justify-content-center"
      >
        <div className="text-light  text-center">
          <div>
            <div>
              <i
                className={`fa ${(WType === 'hasError' || WType === 'isInactive') ? 'fa-exclamation-triangle ' : (WType === 'isOffline' ? 'fa-exclamation-circle' : '')} fa-4x text-danger`}
              />
            </div>
            {WType === 'hasError' && (
            <>
              <strong className="font-lg">Error</strong>
              <div>
                <small>Please contact support</small>
              </div>
            </>
            )}
            {WType === 'isOffline' && (
            <>
              <strong className="font-lg">Error</strong>
              <div>Offline</div>
            </>
            )}
            {WType === 'isInactive' && (
            <>
              <strong className="font-lg">Unavailable</strong>
              <div>This device may not be available any more.</div>
            </>
            )}
          </div>
        </div>
      </Col>
    </Row>
  );
  const renderSparkLines = () => (
    <Row className="m-0 p-2 monitoring-widget__latency__number">
      <Col className="d-flex flex-column">
        {uplinkError && (
        <div
          className="text-center text-light flex-column justify-content-around d-flex align-items-center"
        >
          <div>
            <div className="d-flex flex-column align-items-center">
              <i className="fa fa-exclamation-triangle fa-4x text-danger" />
            </div>
            <small>
              Unable to get upload and download data please contact
              Support
            </small>
          </div>
        </div>
        )}
        {!uplinkError && (
        <>
          <Row>
            <Col className="text-center text-nowrap">
              <span>
                Usage:
                {uplink && uplink.currentUsage}
              </span>
            </Col>
          </Row>
          <div className="mb-3 mt-1">
            <CloudDown
              cssClassTop="monitoring-widget-cloud-down"
              cssClass="monitoring-widget-cloud-down"
            />
            <span className="float-right" style={{ color: '#FF9E1B' }}>
              {formatData(
                uplink ? uplink.lastHour[0].data.slice(-1)[0][1] : 0,
              )}
            </span>
            <Sparklines
              data={
                    uplink && uplink.lastHour[0].data.map((point) => point[1])
                  }
            >
              <SparklinesCurve color="rgb(255, 158, 27)" />
            </Sparklines>
          </div>
          <div className="mb-2">
            <CloudUp
              cssClassTop="monitoring-widget-cloud-up"
              cssClass="monitoring-widget-cloud-up"
            />
            <span className="float-right" style={{ color: '#6BA4B8' }}>
              {formatData(
                uplink ? uplink.lastHour[1].data.slice(-1)[0][1] : 0,
              )}
            </span>
            <Sparklines
              data={
                    uplink && uplink.lastHour[1].data.map((point) => point[1])
                  }
            >
              <SparklinesCurve
                color="rgb(107, 164, 184)"
                style={{ fill: '#6ba4b8' }}
              />
            </Sparklines>
          </div>
        </>
        )}
      </Col>
    </Row>

  );

  const renderLatencyAndCpu = () => (
    <Row className="mx-0  mb-3 px-2 light-border light-border--top py-2">
      <Col
        md={6}
        className="d-flex flex-column light-border--bottom pb-2 align-items-center"
      >
        <div className="text-light text-uppercase text-left mb-0">latency</div>
        <div className=" monitoring-widget__latency pt-0">
          <div
            className="font-weight-bold text-dark monitoring-widget__latency__number pt-0 text-left"
            data-testid="latency"
          >
            {latency}
            <span className="text-light monitoring-widget__latency__ms">
              MS
            </span>
          </div>
        </div>
      </Col>
      <Col
        md={6}
        className="d-flex flex-column light-border--bottom pb-2 align-items-center "
      >
        <div className="text-light text-uppercase  text-left mb-0">cpu</div>
        <div className=" monitoring-widget__latency pt-0">
          <div
            data-testid="cpu"
            className="font-weight-bold text-dark monitoring-widget__latency__number pt-0 text-left"
          >
            {cpu}
            <span className="text-light monitoring-widget__latency__ms">
              %
              {' '}
            </span>
          </div>
        </div>
      </Col>
    </Row>
  );

  const renderWidgetNameRow = (twoLines = false) => (
    <Row className="m-0">
      <ReactTooltip />
      <Col className="d-flex justify-content-center" data-tip={name}>
        <h5
          className="font-weight-bold text-uppercase truncate"
          data-testid="name"
          style={{ letterSpacing: '0px' }}
        >
          {name}
        </h5>
      </Col>
    </Row>
  );

  return (
    <SimpleLoader loading={loading}>
      <div
        className="shadow-sm pt-3 bg-white d-flex flex-column monitoring-widget w-100"
        style={{ borderRadius: '10px' }}
      >
        {renderWidgetHeader()}
        <div className="monitoring-widget__middle d-flex flex-column justify-content-between">
          { (WType === 'isDevice' || WType === 'isPort') && renderSparkLines() }
          { WType === 'isDevice' && renderLatencyAndCpu() }
          { (WType === 'isOffline' || WType === 'hasError' || WType === 'isInactive') && renderRedIconRow() }

          {renderWidgetNameRow()}

        </div>

        {renderWidgetFooter(handleDelete)}
      </div>
    </SimpleLoader>
  );

};

export default MonitoredDevice;
