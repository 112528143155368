import { HEADER_TOGGLE_DROPDOWN, HeaderActionTypes, IHeaderState } from './types';

const initialState: IHeaderState = {
  dropdownOpen: false,
};

export function headerReducer(state = initialState, action: HeaderActionTypes) {

  switch (action.type) {
    case HEADER_TOGGLE_DROPDOWN:
      return {
        ...state,
        dropdownOpen: !state.dropdownOpen,
      };

    default:
      return {
        ...state,
      };
  }

}
