import React, { FunctionComponent } from 'react';
import { Table } from 'reactstrap';

interface IOwnProps {
  rows: Array<JSX.Element>;
  withHeader?: boolean;
  link?: JSX.Element;
  bordered?: boolean;
  header?: JSX.Element;
  padded?: boolean;

}
const SimpleTable: FunctionComponent<IOwnProps> = ({
  rows, header, withHeader, bordered, link, padded,
}) => {

  const borderless = !bordered;
  const addPadding = padded === true;
  return (
    <div className={`d-flex flex-column flex-grow-1 bg-white shadow-sm${addPadding ? ' p-4' : ''}`} style={{ borderRadius: '10px' }}>
      <Table borderless={borderless} bordered={bordered} className="mb-0">
        {withHeader
                    && (
                    <thead>
                      {header}
                    </thead>
                    )}
        <tbody>
          {rows.map((row) => row)}
        </tbody>
      </Table>
      <div className="align-self-end">{link}</div>
    </div>
  );

};

export default SimpleTable;
