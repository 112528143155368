import { api_getAccountDomains } from '../../utils/Domains/Domains';
import {
  DOMAINS_SET_ACCOUNT_DOMAINS,
  DOMAINS_SET_LOOKUP_STRING,
  DOMAINS_SET_SEARCH_STRING,
  DOMAINS_TOGGLE_LOADING,
  DOMAINS_RESET_STORE,
  IDomainsResetStore,
} from './types';
import { setSystemError } from '../system/actions';
import { defaultErrorFeedback } from '../../actions/feedback';

function setAccountDomains(domains) {

  return {
    type: DOMAINS_SET_ACCOUNT_DOMAINS,
    domains,
  };

}
export const resetDomainsStore = (): IDomainsResetStore => ({ type: DOMAINS_RESET_STORE });
export function toggleDomainsLoading() {

  return {
    type: DOMAINS_TOGGLE_LOADING,
  };

}
export function setDomainSearchString(searchString) {

  return {
    type: DOMAINS_SET_SEARCH_STRING,
    searchString,
  };

}
export function setDomainLookupString(lookupString) {

  return {
    type: DOMAINS_SET_LOOKUP_STRING,
    lookupString,
  };

}
export function getAccountDomains() {

  return (dispatch) => api_getAccountDomains().then((result) => {

    if (result.status === 200) {

      dispatch(setAccountDomains(result.data));
    
    } else if (result.data.error && result.data.error.id) {

      dispatch(setSystemError(result.data.error.id));
    
    } else {

      dispatch(defaultErrorFeedback());
    
    }
  
  });

}
