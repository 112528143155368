import { apiRequest } from '../Api/ApiRequest';

export function api_getCaseList(state) {

  return apiRequest({
    url: `/cases${state !== undefined ? `?state=${state}` : ''}`,
    method: 'get',
    headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
  }).then(
    resp => resp,
  ).catch(
    error => error,
  );

}

export function getCase(props) {

  return apiRequest({
    url: `/cases/${props}`,
    method: 'get',
    headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
  }).then(
    resp => resp.data,
  ).catch(
    error => error,
  );

}

export function updateCase(caseid, update) {

  return apiRequest({
    url: `/cases/${caseid}/reply`,
    method: 'post',
    headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
    data: {
      updateText: update,
    },
  }).then(
    resp => resp.data,
  ).catch(
    error => error,
  );

}

export function createCase(props) {

  return apiRequest({
    url: '/cases',
    method: 'post',
    headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
    data: props,
  }).then(
    resp => resp,
  ).catch(
    error => error,
  );

}

export function api_getCaseActivity(caseId, activityId) {

  return apiRequest({
    url: `/cases/${caseId}/activities/${activityId}`,
    method: 'get',
    headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
  }).then(
    resp => resp,
  ).catch(
    error => error,
  );

}
