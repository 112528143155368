import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { IReport, IReportsState } from '../../store/reports/types';
import { deleteReport, getReports } from '../../store/reports/actions';
import SimpleLoader from '../../components/SimpleLoader';
import Section from '../../components/Section';
import DeviceUsageReportWizard from '../../components/Modals/DeviceUsageReportWizard';
import ConfirmationModal from '../../components/Modals/Confirmation';
import { checkPermission } from '../../utils/Auth/AuthService';
import { IUserState } from '../../store/user/types';
import { setSystemForbiddenError } from '../../store/system/actions';
import { AppDispatch } from '../../configureStore';

type TOwnProps = {
  reports: IReportsState;
  user: IUserState;
  dispatch: AppDispatch;
};
const Reports: React.FC<TOwnProps> = (props) => {

  const { reports, dispatch, user } = props;
  const [loading, setLoading] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [deleting, setDeleting] = useState<Array<number>>([]);
  const [toDelete, setToDelete] = useState <number>(0);
  const [report, setReport] = useState<IReport | undefined>(undefined);

  useEffect(() => {

    if (checkPermission('reports.write', user.permissions)) {

      setLoading(true);
      dispatch(getReports()).then(() => {

        setLoading(false);
      
      });
    
    } else {

      props.dispatch(setSystemForbiddenError());
    
    }
  
  }, []);

  const toggleUsageReportModal = () => {

    if (showReportModal) {

      setReport(undefined);
    
    }
    setShowReportModal(!showReportModal);
  
  };

  const remove = (id) => {

    setShowConfirmModal(false);
    setDeleting([...deleting, id]);
    dispatch(deleteReport(id)).then(() => {

      setDeleting(deleting.filter((item) => item !== id));
    
    });
  
  };

  const isDeleting = (id) => deleting.includes(id);

  return (
    <>
      <SimpleLoader loading={loading !== undefined ? loading : false}>
        <Row>
          {reports.list.map((report) => (
            <Col md={3} key={`report-${report.id}`}>
              <SimpleLoader loading={isDeleting(report.id)}>
                <div className="p-4 bg-white mb-3" style={{ borderRadius: '10px' }}>
                  <Section title={report.name}>
                    <p>
                      Scheduled:
                      <span className="text-light">{report.scheduled ? report.frequency : 'no' }</span>
                    </p>
                    <p className="mb-0">
                      Created By:
                      <span className="text-light">{report.user.username}</span>
                    </p>
                    <div className="d-flex justify-content-end">
                      <Button
                        color="transparent"
                        className="text-danger"
                        onClick={() => {

                          setShowConfirmModal(true);
                          setToDelete(report.id);
                        
                        }}
                      >
                        <i className="fa fa-trash" />
                      </Button>
                      <Button
                        color="transparent"
                        className="text-info"
                        onClick={() => {

                          setReport(report);
                          toggleUsageReportModal();
                        
                        }}
                      >
                        <i className="fa fa-pencil" />
                      </Button>
                    </div>
                  </Section>
                </div>
              </SimpleLoader>
            </Col>
          ))}
        </Row>
      </SimpleLoader>
      <DeviceUsageReportWizard
        cancel={toggleUsageReportModal}
        report={report}
        isOpen={showReportModal}
      />
      <ConfirmationModal confirmation={{
        text: 'This action will permanently delete this report.',
        isOpen: showConfirmModal,
        color: 'danger',
        cancel: () => setShowConfirmModal(false),
        proceed: () => remove(toDelete),
      }}
      />
    </>

  );

};

function mapStateToProps({ reports, user }) {

  return {
    reports,
    user,
  };

}
export default connect(mapStateToProps)(Reports);
