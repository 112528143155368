import { apiRequest } from '../Api/ApiRequest';

export function placeOrder(type, orderDetails, purchaseToken) {

  return apiRequest({
    url: '/services/orders',
    method: 'post',
    headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
    data: {
      type,
      ...orderDetails,
      purchaseToken,
    },
  }).then(
    resp => resp,
  ).catch(
    error => error,
  );

}
