import React, { useEffect, useState } from 'react';
import {
  Button, Col, Form, Row,
} from 'reactstrap';
import { IFormError } from '../../Forms/types';
import SimpleLoader from '../../SimpleLoader';
import TextInput from '../../Inputs/TextInput';
import { SERVICE_ORDERS_CONTRACT_LENGTHS, TUnitServiceOrderItem } from '../../../store/serviceOrder/types';
import { formatData } from '../../../helpers/FormatData/formatData';
import Money from '../../../helpers/Money';
import { TPricingData } from '../../../utils/Pricing/Pricing';
import SelectInput from '../../Inputs/SelectInput';

type TOwnProps = {
  item: TUnitServiceOrderItem;
  original: TUnitServiceOrderItem;
  setField: (key: string, value: string) => object;
  getPricing: (data: object) => Promise<boolean | TPricingData>;
  onSubmit: () => any;
};
type TProps = TOwnProps;

const UnitBasedOrderFormWizard: React.FC<TProps> = ({
  item, original, setField, getPricing, onSubmit,
}) => {

  const [loadingPrice, setLoadingPrice] = useState(false);
  const [changedQuota, setChangedQuota] = useState(true);
  const [errors, setErrors] = useState<Array<IFormError>>([]);

  const isUpgrade = !!original.quantity;

  useEffect(() => {

    validate();
  
  }, [item]);

  useEffect(() => {

    setField('name', `${original.name} - ${item.quantity} ${original.unit}`);
  
  }, [item.quantity]);

  const changeInput = (event: React.ChangeEvent<HTMLInputElement>): void => {

    const errorsArr: Array<IFormError> = [];
    if (['quantity', 'contractLength'].includes(event.target.name) && Number(event.target.value) !== item[event.target.name]) {

      setChangedQuota(true);
    
    }
    let { value } = event.target;
    if (event.target.name === 'quantity') {

      value = Number(event.target.value) > 200 ? '200' : event.target.value;
    
    }
    if (item.contractLength && !SERVICE_ORDERS_CONTRACT_LENGTHS.includes(Number(item.contractLength))) {

      errorsArr.push({ id: 'contractLength', message: 'Please enter a valid contract length.' });
    
    }
    setErrors(errorsArr);
    setField(event.target.name, value);
  
  };

  const validate = () => {

    const errorsArr: Array<IFormError> = [];

    if (item.quantity && item.quantity <= Number(formatData(original.quantity, false))) {

      errorsArr.push({ id: 'quantity', message: 'Please enter a number higher than the current quantity.' });
    
    }
    setErrors(errorsArr);
  
  };

  const getPrice = () => {

    setLoadingPrice(true);

    getPricing({
      quantity: item.quantity,
      contractLength: item.contractLength,
    }).then((result) => {

      if (result !== false) {

        setChangedQuota(false);
      
      }

      setLoadingPrice(false);
    
    });
  
  };

  const installFormatted = (): string => Money.format(item.install || 0);
  const rentalFormatted = (): string => Money.format(item.rental || 0);

  const rentalIncrease = (): string => (original.rental && item.rental ? ` (increase of ${Money.format(item.rental - (original.rental || 0))})` : '');

  const submit = () => onSubmit();

  return (
    <Form>
      <Row>
        <Col>
          <TextInput
            label={`${item.unit}`}
            id="unit-quantity"
            name="quantity"
            value={item.quantity ?? ''}
            max={200}
            onChange={changeInput}
            type="number"
            horizontal={false}
            placeholder="200 max."
            error={errors.find((error) => error.id === 'quantity')}
          />
          {!isUpgrade ? (
            <SelectInput
              label="Term"
              id="standard-contractLength"
              name="contractLength"
              value={item.contractLength}
              onChange={changeInput}
              horizontal={false}
              error={errors.find((error) => error.id === 'contractLength')}
              options={SERVICE_ORDERS_CONTRACT_LENGTHS.map((value) => ({ label: value, value }))}
            />
          ) : ''}
        </Col>
        <Col className="text-right">
          <SimpleLoader loading={loadingPrice}>
            {isUpgrade ? (
              <div className="py-1">
                <div>Current</div>
                <div className="font-xl font-weight-bold">
                  {original.quantity}
                  {' '}
                  {item.unit}
                </div>
              </div>
            ) : ''}
            {item.quantity ? (
              <div className="py-1">
                <div>{isUpgrade ? 'Updated' : 'Selected'}</div>
                <div className="font-xl font-weight-bold">
                  {item.quantity}
                  {' '}
                  {item.unit}
                </div>
              </div>
            ) : ''}
            {!changedQuota
              ? (
                <>
                  {item.install !== undefined ? (
                    <div
                      className="py-1"
                      style={{ minHeight: loadingPrice ? '100px' : '', transition: 'min-height 1s ease-out' }}
                    >
                      <div>Non-Recurring</div>
                      <div className="font-xl font-weight-bold">{installFormatted()}</div>
                    </div>
                  ) : ''}
                  {item.rental !== undefined ? (
                    <div
                      className="py-1"
                      style={{ minHeight: loadingPrice ? '100px' : '', transition: 'min-height 1s ease-out' }}
                    >
                      <div>Monthly Recurring</div>
                      <div className="font-xl font-weight-bold">
                        {rentalFormatted()}
                        {' '}
                        / month
                      </div>
                      <div className="grey">{rentalIncrease()}</div>
                    </div>
                  ) : ''}
                </>
              )
              : ''}
          </SimpleLoader>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col xs={6}>
          <Button
            disabled={!!errors.length || !changedQuota}
            onClick={getPrice}
            className="btn-block"
          >
            Get Price
          </Button>
        </Col>
        <Col xs={6}>
          <Button disabled={!item.rental || changedQuota} onClick={submit} className="btn-block">Confirm</Button>
        </Col>
      </Row>
    </Form>
  );

};

export default UnitBasedOrderFormWizard;
