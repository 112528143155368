export const BILLING_SET_BILLS = 'BILLING_SET_DATA';
export const BILLING_TOGGLE_LOADING = 'BILLING_TOGGLE_LOADING';
export const BILLING_TOGGLE_ARTIFACT_MODAL = 'BILLING_TOGGLE_ARTIFACT_MODAL';
export const BILLING_SET_ARTIFACT_MODAL_BILL = 'BILLING_SET_ARTIFACT_MODAL_BILL';
export const BILLING_SET_SEARCH_STRING = 'BILLING_SET_SEARCH_STRING';

export interface IBillingState {
  loading: boolean;
  searchString: string;
  bills: TBill[];
  filtered: TBill[];
  artifactModal: {
    show: boolean;
    bill: TBill;
  };
}
export type TBill = {
  id: number;
  totalValue: number;
  date: string;
  paymentMethod: string;
  artifacts: TBillArtifact[];
  credit: number;
  toPay: number;
  payment: TBillPayment;
};

export type TBillArtifact = {
  id: number;
  name: string;
};

export type TBillPayment = {
  paid: boolean;
  date: string;
};

interface ISetBillingBillsAction {
  type: typeof BILLING_SET_BILLS;
  bills: TBill[];
}

interface IToggleBillingLoadingAction {
  type: typeof BILLING_TOGGLE_LOADING;
}

interface IToggleBillingArtifactModalAction {
  type: typeof BILLING_TOGGLE_ARTIFACT_MODAL;
}

interface ISetArtifactModalBillAction {
  type: typeof BILLING_SET_ARTIFACT_MODAL_BILL;
  id: number;
}

interface ISetBillingSearchStringAction {
  type: typeof BILLING_SET_SEARCH_STRING;
  search: string;
}

export type BillingActions = ISetBillingBillsAction | IToggleBillingLoadingAction | IToggleBillingArtifactModalAction |
ISetArtifactModalBillAction | ISetBillingSearchStringAction;
