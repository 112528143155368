import React from 'react';

interface IProps {
  cssClass?: string;
  cssClassTop?: string;
  title?: string;
}

const GraphCal: React.FC<IProps> = ({ cssClass, cssClassTop, title }) => (
  /* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.56 15.56">
        <defs>
            {/!*<style>.cls-1{fill:#6ba4b8;}</style>*!/}
        </defs>
        <title>{title || ''}</title>
        <g id="Layer_2" data-name="Layer 2" className={cssClassTop || ''}>
            <g id="Design">
                <path className={cssClass || 'cls-1'}
                      d="M13.74,1.22H13V0H11.79V1.22h-8V0H2.55V1.22H1.82A1.82,1.82,0,0,0,0,3v10.7a1.83,1.83,0,0,0,1.82,1.82H13.74a1.83,1.83,0,0,0,1.82-1.82V3A1.82,1.82,0,0,0,13.74,1.22Zm.6,12.52a.6.6,0,0,1-.6.6H1.82a.6.6,0,0,1-.6-.6v-8H14.34Zm0-9.24H1.22V3a.6.6,0,0,1,.6-.61h.73V3.65H3.77V2.43h8V3.65H13V2.43h.73a.6.6,0,0,1,.6.61Z"/>
                <rect className={cssClass || 'cls-1'} x="2.31" y="6.99" width="1.22" height="1.22"/>
                <rect className={cssClass || 'cls-1'} x="4.74" y="6.99" width="1.22" height="1.22"/>
                <rect className={cssClass || 'cls-1'} x="7.17" y="6.99" width="1.22" height="1.22"/>
                <rect className={cssClass || 'cls-1'} x="9.6" y="6.99" width="1.22" height="1.22"/>
                <rect className={cssClass || 'cls-1'} x="12.03" y="6.99" width="1.22" height="1.22"/>
                <rect className={cssClass || 'cls-1'} x="2.31" y="9.42" width="1.22" height="1.22"/>
                <rect className={cssClass || 'cls-1'} x="4.74" y="9.42" width="1.22" height="1.22"/>
                <rect className={cssClass || 'cls-1'} x="7.17" y="9.42" width="1.22" height="1.22"/>
                <rect className={cssClass || 'cls-1'} x="9.6" y="9.42" width="1.22" height="1.22"/>
                <rect className={cssClass || 'cls-1'} x="2.31" y="11.85" width="1.22" height="1.22"/>
                <rect className={cssClass || 'cls-1'} x="4.74" y="11.85" width="1.22" height="1.22"/>
                <rect className={cssClass || 'cls-1'} x="7.17" y="11.85" width="1.22" height="1.22"/>
                <rect className={cssClass || 'cls-1'} x="9.6" y="11.85" width="1.22" height="1.22"/>
                <rect className={cssClass || 'cls-1'} x="12.03" y="9.42" width="1.22" height="1.22"/>
            </g>
        </g>
    </svg> */
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.56 15.56">
    <defs />
    <title>Monitoring - graph cal</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Design">
        <path
          className="cls-1"
          d="M13.74,1.22H13V0H11.79V1.22h-8V0H2.55V1.22H1.82A1.82,1.82,0,0,0,0,3v10.7a1.83,1.83,0,0,0,1.82,1.82H13.74a1.83,1.83,0,0,0,1.82-1.82V3A1.82,1.82,0,0,0,13.74,1.22Zm.6,12.52a.6.6,0,0,1-.6.6H1.82a.6.6,0,0,1-.6-.6v-8H14.34Zm0-9.24H1.22V3a.6.6,0,0,1,.6-.61h.73V3.65H3.77V2.43h8V3.65H13V2.43h.73a.6.6,0,0,1,.6.61Z"
        />
        <rect className="cls-1" x="2.31" y="6.99" width="1.22" height="1.22" />
        <rect className="cls-1" x="4.74" y="6.99" width="1.22" height="1.22" />
        <rect className="cls-1" x="7.17" y="6.99" width="1.22" height="1.22" />
        <rect className="cls-1" x="9.6" y="6.99" width="1.22" height="1.22" />
        <rect className="cls-1" x="12.03" y="6.99" width="1.22" height="1.22" />
        <rect className="cls-1" x="2.31" y="9.42" width="1.22" height="1.22" />
        <rect className="cls-1" x="4.74" y="9.42" width="1.22" height="1.22" />
        <rect className="cls-1" x="7.17" y="9.42" width="1.22" height="1.22" />
        <rect className="cls-1" x="9.6" y="9.42" width="1.22" height="1.22" />
        <rect className="cls-1" x="2.31" y="11.85" width="1.22" height="1.22" />
        <rect className="cls-1" x="4.74" y="11.85" width="1.22" height="1.22" />
        <rect className="cls-1" x="7.17" y="11.85" width="1.22" height="1.22" />
        <rect className="cls-1" x="9.6" y="11.85" width="1.22" height="1.22" />
        <rect className="cls-1" x="12.03" y="9.42" width="1.22" height="1.22" />
      </g>
    </g>
  </svg>

);

export default GraphCal;
