import { apiRequest } from '../Api/ApiRequest';
import { queryStringFromFields } from '../../helpers/QueryString';

export type TPricingData = {
  nonRecurring: number;
  recurring: number;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export const api_getPricing = (priceListItem, data): Promise<any | { data: TPricingData }> => apiRequest({
  url: `/price-list/items/${priceListItem}/pricing${queryStringFromFields(data)}`,
  method: 'get',
  headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
}).then(
  (resp) => resp,
).catch(
  (error) => error,
);
