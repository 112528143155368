export const HEADER_TOGGLE_DROPDOWN = 'HEADER_TOGGLE_DROPDOWN';

export interface IHeaderState {
  dropdownOpen: boolean;
}

export interface IToggleUserDropDown {
  type: typeof HEADER_TOGGLE_DROPDOWN;
}

export type HeaderActionTypes = IToggleUserDropDown;
