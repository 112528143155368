import {
  CASES_SET_CASES,
  CASES_SET_SEARCH_STRING,
  CASES_TOGGLE_LOADING,
  ICasesResetStore,
  CASES_RESET_STORE,
  CASES_SET_FILTER_STRING,
  ICasesSetFilter,
  CASES_TOGGLE_NEW_CASE_DROPDOWN,
  ITogglCasesFilterDropdownAction,
  CASES_TOGGLE_FILTER_DROPDOWN,
  CASES_ADD_CASE_TO_CASES, CASES_RESOLVE_CASE, CASES_ESCALATE_CASE,
} from './types';
import { api_getCaseList } from '../../utils/Cases/Cases';
import { setSystemError } from '../system/actions';
import { defaultErrorFeedback } from '../../actions/feedback';

export const resetCasesStore = (): ICasesResetStore => ({ type: CASES_RESET_STORE });
export const setCasesFilter = (payload): ICasesSetFilter => ({ type: CASES_SET_FILTER_STRING, payload });
export const toggleCasesFilterDropdown = (): ITogglCasesFilterDropdownAction => ({ type: CASES_TOGGLE_FILTER_DROPDOWN });
export function toggleAccountCasesLoading() {

  return {
    type: CASES_TOGGLE_LOADING,
  };

}
export function toggleNewCasesDropdown() {

  return {
    type: CASES_TOGGLE_NEW_CASE_DROPDOWN,
  };

}
export function setCasesSearchString(search) {

  return {
    type: CASES_SET_SEARCH_STRING,
    search,
  };

}
function setAccountCases(cases) {

  return {
    type: CASES_SET_CASES,
    cases,
  };

}
export function getAccountCases() {

  return (dispatch) => api_getCaseList().then((result) => {

    if (result.status === 200) {

      dispatch(setAccountCases(result.data));
    
    } else if (result.data.error && result.data.error.id) {

      dispatch(setSystemError(result.data.error.id));
    
    } else {

      dispatch(defaultErrorFeedback());
    
    }
  
  });

}

export const addCaseToCases = (theCase) => ({ type: CASES_ADD_CASE_TO_CASES, theCase });
export const resolveCaseInCases = (id) => ({ type: CASES_RESOLVE_CASE, id });
export const escalateCaseInCases = (id) => ({ type: CASES_ESCALATE_CASE, id });
