import React, { FunctionComponent } from 'react';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';

type ButtonLink = {
  url: string;
  text: string;
  color?: string;
  textColor?: string;
  size?: 'sm' | 'lg';

};
const ButtonLink: FunctionComponent<ButtonLink> = ({
  text, textColor, url, color, size,
}) => {

  const classes: string[] = [];
  if (textColor) {

    classes.push(`text-${textColor}`);
  
  }
  return <Link to={url}><Button size={size} className={classes.join(',')} color={color}>{text}</Button></Link>;

};

export default ButtonLink;
