/* eslint-disable */
import React, { Component, useEffect, useState } from 'react'
import queryString from 'query-string';
import {connect} from 'react-redux';
import {Alert} from 'reactstrap';
import {SpinnerInfo} from '../../components/Spinner/Spinner';
import {
  setDomainCheckoutCardRedirect,
  setDomainCheckoutPurchaseToken,
  updateDomainCheckoutOrderDetails,
  updateDomainCheckoutStatus
} from '../../actions/domainCheckout';
import {
  setBillToPaid,
  setCardPaymentError,
  setCardPaymentRedirect,
  toggleCardPaymentProcessing
} from "../../store/cardPayment/actions";
import { useQuery } from '../../helpers/QueryString'

const Card3dSecureCallback = ({ dispatch }) => {

  const [invalidToken, setInvalidToken] = useState(false);
  const queryParams = useQuery();

  useEffect(() => {

    if(queryParams.get('failed')){

      dispatch(setCardPaymentError('Payment failed.'));

    } else{
      dispatch(setBillToPaid());

    }
    dispatch(setCardPaymentRedirect({url: '', md: '', pareq: ''}));
    dispatch(setDomainCheckoutCardRedirect({url: '', md: '', pareq: ''}));
    dispatch(toggleCardPaymentProcessing());

  }, []);

  if(invalidToken){

    return <Alert color="danger">Invalid callback token</Alert>;

  }
  return (
      <div>
      </div>
  );

}

function mapStateToProps ({ domainCheckout }) {

  return {
    domainCheckout
  };

}
export default connect(mapStateToProps)(Card3dSecureCallback);
