import React from 'react';

interface IProps {
  cssClass?: string;
  cssClassTop?: string;
  title?: string;
}

const SearchIcon: React.FC<IProps> = ({ cssClass, cssClassTop, title }) => (
  <div className="d-flex align-items-center justify-content-center h-100">
    <i title={`${title || ''}`} className={`${cssClass || ''}  ${cssClassTop || ''} fa fa-search`} />
  </div>
);

export default SearchIcon;
