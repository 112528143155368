import React, { FunctionComponent, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Col, Row, DropdownToggle, ButtonDropdown, DropdownItem, DropdownMenu, Button,
} from 'reactstrap';
import moment from 'moment';
import ComplexTable from '../../components/Table/ComplexTable';
import { IUserState } from '../../store/user/types';
import Section from '../../components/Section';
import SearchInput from '../../components/Inputs/SearchInput';
import { IServicesState, TServiceGroup } from '../../store/services/types';
import {
  getAccountServices,
  getServiceGroups,
  setServicesSearchString,
  toggleServicesLoading,
  resetServicesStore,
  toggleServicesFilterDropdown,
  setServicesFilterAction,
  getCustomerServiceGroups,
} from '../../store/services/actions';
import SearchIcon from '../../components/SvgIcons/SearchIcon';
import SimpleLoader from '../../components/SimpleLoader';
import Refresh from '../../components/SvgIcons/Refresh';
import { checkPermission } from '../../utils/Auth/AuthService';
import { setSystemForbiddenError } from '../../store/system/actions';
import { ACCOUNT_TYPE_RESALE_PARTNER, IAccountState } from '../../store/account/types';
import { AppDispatch } from '../../configureStore';
import MetaButton from "../../components/MetaButton/MetaButton";

type TOwnProps = {
  user: IUserState;
  services: IServicesState;
  dispatch: AppDispatch;
  account: IAccountState;
};
const Services: FunctionComponent<TOwnProps> = (props) => {

  const { dispatch, user, account } = props;
  const {
    services, loading, searchString, serviceGroups, customerServiceGroups, visibleState, openFilterServicesDropdown,
  } = props.services;
  useEffect(() => {

    if (!checkPermission('services.read', user.permissions)) {

      dispatch(setSystemForbiddenError());

    } else if (services.length === 0 || account.accountTypeCode === ACCOUNT_TYPE_RESALE_PARTNER) refreshStore();

  }, []);
  const getData = () => {

    dispatch(toggleServicesLoading());
    dispatch(getServiceGroups()).then((result) => {

      if (result) {

        dispatch(getAccountServices()).then(() => dispatch(toggleServicesLoading())).then((result2) => {

          if (result2) {

            dispatch(getCustomerServiceGroups());

          }

        });

      } else {

        dispatch(toggleServicesLoading());

      }

    });

  };
  const refreshStore = () => {

    dispatch(resetServicesStore());
    getData();

  };
  const onChangeSearch = (e) => {

    const searchString = e.target.value.toString().toLowerCase();
    dispatch(setServicesSearchString(searchString));

  };
  const filtered = (service) => String(service.name).toLowerCase().indexOf(searchString.toLowerCase()) !== -1
            || String(service.productCode).toLowerCase().indexOf(searchString.toLowerCase()) !== -1
            || String(service.orderNumber).toLowerCase().indexOf(searchString.toLowerCase()) !== -1
            || serviceGroups.filter((group) => String(group.name).toLowerCase().indexOf(searchString.toLowerCase()) !== -1 && group.id === service.group).length > 0;
  const getVisibleServices = () => {

    const visibleServices = services.filter((theService) => {

      if (visibleState && visibleState.id) {

        if (searchString) {

          return visibleState.id === theService.group && filtered(theService);

        }
        return visibleState.id === theService.group;

      }
      if (searchString) {

        return filtered(theService);

      }
      return true;

    });
    return visibleServices;

  };
  const toggleFilterCServices = () => {

    dispatch(toggleServicesFilterDropdown());

  };
  const setServicesFilter = (group: TServiceGroup | null) => {

    dispatch(setServicesFilterAction(group));

  };
  const columns = [
    {
      Header: 'Product Code',
      accessor: 'productCode',
      width: 200,
    },
    {
      Header: 'Name',
      accessor: 'name',

    },
    {
      Header: 'Service Group',
      accessor: 'group',
      sortMethod: (a, b, desc) => {

        if (serviceGroups[a - 1].name === serviceGroups[b - 1].name) {

          return 0;

        }
        return serviceGroups[a - 1].name > serviceGroups[b - 1].name ? 1 : -1;

      },

      Cell: (props) => {

        const groupFound = serviceGroups.filter((group) => group.id === props.value);
        return (
          <MetaButton key={props.val} className="mr-2">{groupFound[0].name}</MetaButton>
        );

      },
      width: 200,
    },
    {
      Header: 'Order Number',
      accessor: 'orderNumber',
      Cell: (props) => (
        props.value ? props.value : <></>
      ),
      width: 200,
    },

    {
      Header: 'Active Since',
      accessor: 'goLiveDate',
      Cell: (props) => (
        props.value ? moment(props.value).format('DD/MM/YYYY') : <></>
      ),
      width: 150,
    },
    {
      Header: '',
      accessor: 'id',
      filterable: false,
      sortable: false,
      width: 50,
      Cell: (props) => {

        const { url } = props.original;
        if (url) {

          return (
            <Link className="grey-icon rounded" to={url}>
              <SearchIcon title="details" />
            </Link>
          );

        }
        return '';

      },
    },

  ];
  return (
    <div className="animated fadeIn mb-3">
      <Section title="Services">
        <Row className="mb-4">
          <Col className="d-flex flex-row">
            <SearchInput value={searchString} onChange={onChangeSearch} />
            <ButtonDropdown isOpen={openFilterServicesDropdown} toggle={toggleFilterCServices} className="ml-2 d-flex justify-content-space-between">
              <DropdownToggle caret color="primary">
                <span className="d-inline-block" style={{ minWidth: '90px' }}>{visibleState ? visibleState.name : 'All Service Groups'}</span>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => {

                  setServicesFilter(null);

                }}
                >
                  All Service Groups
                </DropdownItem>
                {customerServiceGroups.map((group) => (
                  <DropdownItem
                    key={group.id}
                    onClick={() => {

                      setServicesFilter(group);

                    }}
                  >
                    {group.name}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </ButtonDropdown>
          </Col>
          <Col md="5" className="d-flex justify-content-end">
            <Button className="pt-1 px-1" color="primary" onClick={getData}>
              <Refresh title="Refresh" cssClassTop="refresh-svg" />
            </Button>
          </Col>
        </Row>
        <Row className="mt-2 mr-0 ml-0">
          <Col className="p-4 bg-white rounded">
            <SimpleLoader loading={loading}>
              <ComplexTable
                columns={columns}
                defaultSorted={{ id: 'goLiveDate', desc: true }}
                data={getVisibleServices()}
              />
            </SimpleLoader>
          </Col>
        </Row>
      </Section>
    </div>
  );

};

export default Services;
