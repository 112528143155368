import {
  WEATHERMAP_SET_WEATHERMAP,
  WEATHERMAP_SET_TRAFFIC,
  WEATHERMAP_SET_EDGE,
  WEATHERMAP_SET_EDGE_HISTORY,
  TWeatherMapActionTypes,
  IWeatherMapState, WEATHERMAP_RESET_WEATHERMAP,
} from './types';

const initialState: IWeatherMapState = {
  weatherMap: {
    id: '',
    name: '',
    description: '',
    nodes: [],
    edges: [],
  },
  traffic: {},
  timestamp: '',
  edge: {},
};

export const weatherMapReducer = (state: IWeatherMapState = initialState, action: TWeatherMapActionTypes): IWeatherMapState => {

  switch (action.type) {
    case WEATHERMAP_SET_WEATHERMAP:
      return {
        ...state,
        weatherMap: action.data,
      };
    case WEATHERMAP_SET_TRAFFIC:
      return {
        ...state,
        traffic: action.traffic,
        timestamp: action.timestamp,
      };
    case WEATHERMAP_SET_EDGE:
      return {
        ...state,
        edge: action.edge,
      };
    case WEATHERMAP_SET_EDGE_HISTORY:
      return {
        ...state,
        edge: {
          ...state.edge,
          ...action.history,
        },
      };
    case WEATHERMAP_RESET_WEATHERMAP:
      return {
        ...initialState,
      };
    default:
      return {
        ...state,
      };
  }

};
