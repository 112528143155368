import { TOrder, ORDER_RESET_DATA } from '../order/types';

export const ORDERS_SET_ORDERS = 'ORDERS_SET_ORDERS';
export const ORDERS_TOGGLE_LOADING = 'ORDERS_TOGGLE_LOADING';
export const ORDERS_SET_SEARCH_STRING = 'ORDERS_SET_SEARCH_STRING';
export const ORDERS_RESET_STORE = 'ORDERS_RESET_STORE';

export interface IOrdersResetStore {
  type: typeof ORDERS_RESET_STORE;
}
export interface IOrdersState {
  loading: boolean;
  searchString: string;
  filtered: TOrder[];
  orders: TOrder[];
}
interface ISetAccountOrdersAction {
  type: typeof ORDERS_SET_ORDERS;
  orders: TOrder[];
}

interface ISetAccountOrdersSearchAction {
  type: typeof ORDERS_SET_SEARCH_STRING;
  search: string;
}

interface IToggleAccountServicesLoadingAction {
  type: typeof ORDERS_TOGGLE_LOADING;
}

export type OrdersActionTypes = ISetAccountOrdersAction | ISetAccountOrdersSearchAction | IToggleAccountServicesLoadingAction | IOrdersResetStore;
