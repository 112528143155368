import React from 'react';

interface IProps {
  cssClass?: string;
  cssClassTop?: string;
  title?: string;
}
const Refresh: React.FC<IProps> = ({ cssClass, cssClassTop, title }) => (
  <svg className={cssClassTop || ''} viewBox="0 0 50 44.5" x="0px" y="0px">
    <title>{title || ''}</title>
    <path
      className={cssClass || ''}
      d="M42.93,33.13A19.79,19.79,0,0,1,25,45a17.91,17.91,0,0,1-4.11-.48c-8-1.9-14.22-9.29-15-17.62l-3.21,3.3a1,1,0,0,1-1.43-1.39l4.83-5a1,1,0,0,1,1.43,0l4.83,5A1,1,0,0,1,11,30.21L8,27.14A18.18,18.18,0,0,0,21.39,42.58C29.27,44.44,37.75,40,41.1,32.33A1,1,0,1,1,42.93,33.13ZM48.7,19.19a1,1,0,0,0-1.41,0L44,22.58C43,14.47,36.95,7.33,29.08,5.47c-8.81-2.08-18.27,2.82-22,11.4a1,1,0,1,0,1.83.8C12.27,10,20.74,5.56,28.62,7.42A18.17,18.17,0,0,1,41.94,22.19l-2.89-3a1,1,0,1,0-1.43,1.39l4.83,5h0a1,1,0,0,0,1.42,0h0l4.83-5A1,1,0,0,0,48.7,19.19Z"
    />
  </svg>
);

export default Refresh;
