import React, { useEffect, useState } from 'react';
import {
  Button, Col, Form, Row,
} from 'reactstrap';
import { IFormError } from '../../Forms/types';
import SimpleLoader from '../../SimpleLoader';
import {
  SERVICE_ORDERS_CONTRACT_LENGTHS,
  TStandardServiceOrderItem,
} from '../../../store/serviceOrder/types';
import Money from '../../../helpers/Money';
import { TPricingData } from '../../../utils/Pricing/Pricing';
import SelectInput from '../../Inputs/SelectInput';

type TOwnProps = {
  item: TStandardServiceOrderItem;
  original: TStandardServiceOrderItem;
  setField: (key: string, value: string) => object;
  getPricing: (data: object) => Promise<boolean | TPricingData>;
  onSubmit: () => any;
};
type TProps = TOwnProps;

const StandardOrderFormWizard: React.FC<TProps> = ({
  item, original, setField, getPricing, onSubmit,
}) => {

  const [loadingPrice, setLoadingPrice] = useState(false);
  const [changedField, setChangedField] = useState(true);
  const [errors, setErrors] = useState<Array<IFormError>>([]);

  useEffect(() => {

    validate();
  
  }, [item]);

  const changeInput = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>): void => {

    if (event.target.name === 'contractLength' && String(event.target.value) !== String(item.contractLength)) {

      setChangedField(true);
    
    }
    setErrors([]);
    setField(event.target.name, event.target.value);
  
  };

  const validate = () => {

    const errorsArr: Array<IFormError> = [];

    if (item.contractLength && !SERVICE_ORDERS_CONTRACT_LENGTHS.includes(Number(item.contractLength))) {

      errorsArr.push({ id: 'contractLength', message: 'Please enter a valid contract length.' });
    
    }
    setErrors(errorsArr);
  
  };

  const getPrice = () => {

    setLoadingPrice(true);

    getPricing({
      contractLength: item.contractLength,
    }).then((result) => {

      if (result !== false) {

        setChangedField(false);
      
      }

      setLoadingPrice(false);
    
    });
  
  };

  const installFormatted = (): string => Money.format(item.install || 0);
  const rentalFormatted = (): string => Money.format(item.rental || 0);

  const submit = () => onSubmit();

  return (
    <Form>
      <Row>
        <Col>
          <SelectInput
            label="Term"
            id="standard-contractLength"
            name="contractLength"
            value={item.contractLength ?? ''}
            onChange={changeInput}
            horizontal={false}
            error={errors[0]}
            options={SERVICE_ORDERS_CONTRACT_LENGTHS.map((value) => ({ label: value, value }))}
          />
        </Col>
        <Col className="text-right">
          <SimpleLoader loading={loadingPrice}>
            <div style={{ minHeight: loadingPrice ? '100px' : '', transition: 'min-height 1s ease-out' }}>
              {item.install !== undefined ? (
                <div className="py-1">
                  <div>Non-Recurring: </div>
                  <div className="font-xl font-weight-bold">{installFormatted()}</div>
                </div>
              ) : ''}
              {item.rental !== undefined ? (
                <div className="py-1">
                  <div>Monthly Recurring: </div>
                  <div className="font-xl font-weight-bold">
                    {rentalFormatted()}
                    {' '}
                    / month
                  </div>
                </div>
              ) : ''}
            </div>
          </SimpleLoader>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col xs={6}>
          <Button
            disabled={!!errors.length || !changedField}
            onClick={getPrice}
            className="btn-block"
          >
            {item.rental && changedField ? 'Change Term' : 'Get Price'}
          </Button>
        </Col>
        <Col xs={6}>
          <Button
            disabled={item.install === undefined || item.rental === undefined || changedField}
            onClick={submit}
            className="btn-block"
          >
            Confirm
          </Button>
        </Col>
      </Row>
    </Form>
  );

};

export default StandardOrderFormWizard;
