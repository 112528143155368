import React from 'react';

interface IProps {
  cssClass?: string;
  cssClassTop?: string;
  title?: string;
}

const ServicesIcon: React.FC<IProps> = ({ cssClass, cssClassTop, title }) => (
  <svg
    className={cssClassTop || 'cls-1'}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 19.53 23.51"
  >
    <defs>{/* <style>.cls-1{fill:#858c91;}</style> */}</defs>
    <title>{title || ''}</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Design">
        <path
          className={cssClass || 'cls-1'}
          d="M10.87,7H1.58A1.58,1.58,0,0,0,0,8.6V21.93a1.58,1.58,0,0,0,1.58,1.58h9.29a1.58,1.58,0,0,0,1.58-1.58V8.6A1.59,1.59,0,0,0,10.87,7Zm.47,14.9a.47.47,0,0,1-.48.48H1.58a.47.47,0,0,1-.48-.48V8.6a.48.48,0,0,1,.48-.48h9.28a.48.48,0,0,1,.48.48Z"
        />
        <path
          className={cssClass || 'cls-1'}
          d="M14.42,3.53H5.13A1.57,1.57,0,0,0,3.55,5.1a.56.56,0,1,0,1.11,0,.47.47,0,0,1,.47-.47h9.29a.48.48,0,0,1,.48.47V18.43a.49.49,0,0,1-.48.48.55.55,0,1,0,0,1.1A1.58,1.58,0,0,0,16,18.43V5.1a1.57,1.57,0,0,0-1.58-1.57Z"
        />
        <path
          className={cssClass || 'cls-1'}
          d="M18,0H8.66A1.58,1.58,0,0,0,7.08,1.58a.55.55,0,1,0,1.1,0,.48.48,0,0,1,.48-.48H18a.48.48,0,0,1,.47.48V14.91a.47.47,0,0,1-.47.47.56.56,0,1,0,0,1.11,1.58,1.58,0,0,0,1.58-1.58V1.58A1.58,1.58,0,0,0,18,0Z"
        />
      </g>
    </g>
  </svg>
);

export default ServicesIcon;
