import { lookupDomain } from '../../utils/Domains/Domains';

export const DOMAIN_LOOKUP_SET_RESULTS = 'DOMAIN_LOOKUP_SET_RESULTS';
export const DOMAIN_LOOKUP_RESET_RESULTS = 'DOMAIN_LOOKUP_RESET_RESULTS';
export const DOMAIN_LOOKUP_TOGGLE_LOADING = 'DOMAIN_LOOKUP_TOGGLE_LOADING';
export const DOMAIN_LOOKUP_SET_SEARCH_STRING = 'DOMAIN_LOOKUP_SET_SEARCH_STRING';
export const DOMAIN_LOOKUP_SET_DOMAIN_YEARS = 'DOMAIN_LOOKUP_SET_DOMAIN_YEARS';

function setLookupResults(domains) {

  return {
    type: DOMAIN_LOOKUP_SET_RESULTS,
    domains,
  };

}

export function resetLookupResults() {

  return {
    type: DOMAIN_LOOKUP_RESET_RESULTS,
  };

}

export function toggleDomainLookupLoading() {

  return {
    type: DOMAIN_LOOKUP_TOGGLE_LOADING,
  };

}

export function setDomainLookupSearchString(search) {

  return {
    type: DOMAIN_LOOKUP_SET_SEARCH_STRING,
    searchString: search,
  };

}

export function setDomainLookupDomainYear(domain, years) {

  return {
    type: DOMAIN_LOOKUP_SET_DOMAIN_YEARS,
    domain,
    years,
  };

}

export function lookupDomains(domain) {

  return (dispatch) => lookupDomain(domain).then((result) => {

    const results = {};
    if (result.status === 200) {

      result.data.forEach((item) => {

        results[item.name] = { ...item, years: 1 };
      
      });
    
    }
    dispatch(setLookupResults(results));
  
  });

}
