import {
  IServiceDNSState, SERVICE_DNS_ADD_ZONE, SERVICE_DNS_RESET_NEW_ZONE,
  SERVICE_DNS_SET_SEARCH_STRING,
  SERVICE_DNS_SET_ZONES,
  SERVICE_DNS_TOGGLE_LOADING,
  SERVICE_DNS_TOGGLE_NEW_ZONE_MODAL,
  SERVICE_DNS_UPDATE_ZONE_DATA,
  ServiceDNSActionTypes,
} from './types';

const initialState: IServiceDNSState = {
  loading: false,
  showNewZoneModal: false,
  searchString: '',
  zones: [],
  zone: {
    id: 0,
    name: '',
    type: 'MASTER',
    notifiedSerial: 0,
    master: '',
  },
  filtered: [],
};

export function serviceDNSReducer(state = initialState, action: ServiceDNSActionTypes) {

  switch (action.type) {
    case SERVICE_DNS_SET_ZONES:
      return {
        ...state,
        zones: action.zones,
      };
    case SERVICE_DNS_ADD_ZONE:
      return {
        ...state,
        zones: [...state.zones, action.zone],
      };
    case SERVICE_DNS_TOGGLE_LOADING:
      return {
        ...state,
        loading: !state.loading,
      };
    case SERVICE_DNS_TOGGLE_NEW_ZONE_MODAL:
      return {
        ...state,
        showNewZoneModal: !state.showNewZoneModal,
      };
    case SERVICE_DNS_UPDATE_ZONE_DATA:
      return {
        ...state,
        zone: { ...state.zone, ...action.data },
      };
    case SERVICE_DNS_SET_SEARCH_STRING:
      const filtered = state.zones.filter((zone) => String(zone.name).toLowerCase().indexOf(action.search.toLowerCase()) !== -1
                    || String(zone.type).toLowerCase().indexOf(action.search.toLowerCase()) !== -1);
      return {
        ...state,
        searchString: action.search,
        filtered,
      };

    case SERVICE_DNS_RESET_NEW_ZONE:
      return {
        ...state,
        zone: initialState.zone,
      };
    default: return { ...state };
  }

}
