import { api_getAccountSummary, api_getSites } from '../../utils/Account/Account';
import { defaultErrorFeedback } from '../../actions/feedback';
import {
  ACCOUNT_SET_ACCOUNT_DATA,
  ACCOUNT_SET_ACCOUNT_SITES,
  ISetAccountDataAction,
  ISetAccountSitesAction,
} from './types';
import { setSystemError } from '../system/actions';

function setAccountSummary(data): ISetAccountDataAction {

  return {
    type: ACCOUNT_SET_ACCOUNT_DATA,
    data,
  };

}

function setAccountSites(sites): ISetAccountSitesAction {

  return {
    type: ACCOUNT_SET_ACCOUNT_SITES,
    sites,
  };

}

export function getAccountSummary() {

  return (dispatch) => api_getAccountSummary().then((result) => {

    if (result.status === 200) {

      dispatch(setAccountSummary(result.data));
    
    } else if (result.data?.error && result.data.error.id) {

      dispatch(setSystemError(result.data.error.id));
    
    } else {

      dispatch(defaultErrorFeedback());
    
    }
  
  });

}

export function getSites() {

  return (dispatch) => api_getSites().then((result) => {

    if (result.status === 200) {

      dispatch(setAccountSites(result.data));
    
    } else if (result.data?.error && result.data.error.id) {

      dispatch(setSystemError(result.data.error.id));
    
    } else {

      dispatch(defaultErrorFeedback());
    
    }
  
  });

}
