import React from 'react';

interface IProps {
  cssClass?: string;
  cssClassTop?: string;
  title?: string;
}

const Star: React.FC<IProps> = ({ cssClass, cssClassTop, title }) => (

  <svg viewBox="0 0 847 1058.75" x="0px" y="0px" fillRule="evenodd" clipRule="evenodd" className={cssClassTop || ''}>
    <title>{title || ''}</title>
    <g>
      <polygon
        className={cssClass || 'cls-1'}
        points="546,295 825,333 621,528 672,805 423,672 175,805 225,528 22,333 301,295 423,41"
      />
    </g>
  </svg>
);

export default Star;
