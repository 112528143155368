import React, { FunctionComponent } from 'react';
import {
  FormGroup, Label, Col, Input,
} from 'reactstrap';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import 'react-phone-input-2/lib/style.css';
import { IDomainContact } from '../../store/domainCheckout/types';

type TOwnProps = {
  contact: IDomainContact;
  contactEdited: IDomainContact;
  handleInput: (event: React.ChangeEvent<HTMLInputElement>) => any;
  handleCountrySelect: (value: string) => any;
  handleCountySelect: (value: string) => any;
  errors: string[] | object;
};
const DomainContact: FunctionComponent<TOwnProps> = ({
  contactEdited, errors, handleInput, handleCountrySelect, handleCountySelect,
}) => {

  const fields = [
    { field: 'firstName', name: 'First Name' },
    { field: 'lastName', name: 'Last Name' },
    { field: 'email', name: 'Email' },
    { field: 'company', name: 'Company' },
    { field: 'addressLine1', name: 'Address Line 1' },
    { field: 'addressLine2', name: 'Address Line 2' },
    { field: 'city', name: 'City' },
    { field: 'county', name: 'County' },
    { field: 'country', name: 'Country' },
    { field: 'postcode', name: 'Postcode' },
    { field: 'phone', name: 'Phone' },
  ];
  return (
    <>
      {fields.map((field, index) => {

        if (field.field === 'country') {

          return (
            <FormGroup row key={field.field + index}>
              <Col md="4">
                <Label className="text-secondary col-form-label"><strong>{field.name}</strong></Label>
              </Col>
              <Col md="8">
                <CountryDropdown
                  valueType="short"
                  priorityOptions={['GB']}
                  value={contactEdited.country ? contactEdited.country : 'GB'}
                  classes={`form-control ${errors[field.field] ? 'is-invalid' : ''}`}
                  onChange={(val) => handleCountrySelect(val)}
                />
                {errors[field.field]
                                && (
                                <div className="invalid-feedback">
                                  Please provide a valid
                                  {' '}
                                  {field.name}
                                </div>
                                )}
              </Col>
            </FormGroup>
          );
        
        }
        if (field.field === 'county') {

          return (
            <FormGroup row key={field.field + index + errors[field.field]}>
              <Col md="4">
                <Label className="text-secondary col-form-label"><strong>{field.name}</strong></Label>
              </Col>
              <Col md="8">
                <RegionDropdown
                  key={`${errors[field.field]}`}
                  value={contactEdited.county ? contactEdited.county : ''}
                  country={contactEdited.country ? contactEdited.country : 'GB'}
                  classes={`form-control ${errors[field.field] ? 'is-invalid' : ''}`}
                  onChange={(val) => handleCountySelect(val)}
                  countryValueType="short"
                />
                {errors[field.field]
                                && (
                                <div className="invalid-feedback">
                                  Please provide a valid
                                  {' '}
                                  {field.name}
                                </div>
                                )}
              </Col>
            </FormGroup>
          );
        
        }
        return (
          <FormGroup row key={field.field + index}>
            <Col md="4">
              <Label
                className="text-secondary col-form-label"
              >
                <strong>{field.name}</strong>
              </Label>
            </Col>
            <Col md="8">
              <>
                {' '}
                <Input
                  className={`form-control ${errors[field.field] ? 'is-invalid' : ''}`}
                  id={field.field}
                  value={contactEdited[field.field] ? contactEdited[field.field] : ''}
                  onChange={(event) => handleInput(event)}
                />
                {field.field === 'phone' && <p>Phone must start with '+'' followed by country code followed by '.' followed by the remaining digits </p>}
                {errors[field.field] && (
                <div className="invalid-feedback">
                  Please provide a valid
                  {' '}
                  {field.name}
                </div>
                )}
              </>

            </Col>
          </FormGroup>
        );
      
      })}
    </>
  );

};
export default DomainContact;
