import React, { FunctionComponent } from 'react';
import {
  Badge, Card, CardBody, Col, Row,
} from 'reactstrap';
import { IWeatherMapEdge, TWeatherMapTraffic } from '../../store/weatherMap/types';
import Section from '../../components/Section';
import { formatbps } from '../../helpers/FormatData/formatData';
import TrafficGraph from '../../components/TrafficGraph';

interface IOwnProps {
  edge: IWeatherMapEdge;
  traffic: TWeatherMapTraffic;
  jtiData; // todo
}

const WeatherMapEdge: FunctionComponent<IOwnProps> = ({ edge, traffic, jtiData }) => {

  const portstates = {};
  if (jtiData[edge.source] !== undefined) {

    const sourceIntArr = edge.source_int.split(',');
    for (let i = 0; i < sourceIntArr.length; i++) {

      if (sourceIntArr.length > 1 && sourceIntArr[i].startsWith('ae')) {

        console.log(`ignoring ${sourceIntArr[i]}`);
      
      } else if (jtiData[edge.source][sourceIntArr[i]] !== undefined) {

        portstates[sourceIntArr[i]] = jtiData[edge.source][sourceIntArr[i]].status.state;
      
      }
    
    }
  
  }

  return (
    <Section title={`${edge.source_name}-${edge.target_name}`}>
      <Row>
        <Col md={3}>
          <Card>
            <CardBody>
              <Row>
                <Col md={6}>
                  {edge.data_source === 'jti' ? (
                    <div>
                      <div className="text-uppercase text-muted font-weight-bold font-xs mb-0">Port Status</div>
                      {Object.keys(portstates).map((key, idx) => (
                        <Row key={idx}>
                          <Col>
                            <span className="h5">
                              {portstates[key] === 'UP' ? (
                                <Badge color="success">UP</Badge>
                              ) : (
                                portstates[key] === 'down' ? (
                                  <Badge color="danger">DOWN</Badge>
                                ) : (
                                  <Badge>UNKNOWN</Badge>
                                )
                              )}
                            </span>
                            {' '}
                            Port
                            {idx + 1}
                          </Col>
                        </Row>
                      ))}
                    </div>
                  ) : ''}
                  <div className="text-uppercase text-muted font-weight-bold font-xs mb-0 mt-2">Capacity</div>
                  <div className="h5">
                    {edge.capacity}
                    bps
                    {Object.keys(portstates).length > 1 ? ' Bundle' : ''}
                  </div>
                  <div className="text-uppercase text-muted font-weight-bold font-xs mb-0 mt-2">Data Source</div>
                  <div className="h5 text-uppercase">{edge.data_source ? edge.data_source : 'Influx'}</div>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <Row>
                <Col md={6}>
                  <div
                    className="text-uppercase text-muted font-weight-bold font-xs mb-0 mt-2 text-right"
                  >
                    {edge.source_name}
                    <i className="icon-control-forward" />
                  </div>
                  <div className="h5 text-right">{formatbps(traffic[`${edge.source}--${edge.target}`])}</div>
                </Col>
                <Col md={6}>
                  <div className="text-uppercase text-muted font-weight-bold font-xs mb-0 mt-2">
                    <i
                      className="icon-control-rewind"
                    />
                    {' '}
                    {edge.target_name}
                  </div>
                  <div className="h5">{formatbps(traffic[`${edge.target}--${edge.source}`])}</div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col md={9}>
          <Card>
            <CardBody>
              {edge.history && edge.history[0].data.length > 0 ? (
                <TrafficGraph
                  dataIn={edge.history[0]}
                  dataOut={edge.history[1]}
                  loading={false}
                  inboundLabel={`${edge.source_name}-${edge.target_name}`}
                  outboundLabel={`${edge.target_name}-${edge.source_name}`}
                />
              ) : ''}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Section>
  );

};

export default WeatherMapEdge;
