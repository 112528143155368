import {
  CASES_SET_CASES,
  CASES_SET_SEARCH_STRING,
  CASES_TOGGLE_LOADING,
  CASES_TOGGLE_NEW_CASE_DROPDOWN,
  CasesActionTypes,
  ICasesState,
  CASES_RESET_STORE,
  CASES_TOGGLE_FILTER_DROPDOWN,
  CASES_SET_FILTER_STRING,
  CASES_ADD_CASE_TO_CASES, CASES_RESOLVE_CASE, CASES_ESCALATE_CASE,
} from './types';

const initialState: ICasesState = {
  loading: false,
  openNewCaseDropdown: false,
  openFilterCasesDropdown: false,
  cases: [],
  searchString: '',
  visibleState: 'Active',
};

export function casesReducer(state = initialState, action: CasesActionTypes) {

  switch (action.type) {
    case CASES_ADD_CASE_TO_CASES:
      return {
        ...state,
        cases: [action.theCase, ...state.cases],
      };
    case CASES_SET_CASES:
      return {
        ...state,
        cases: action.cases,
      };
    case CASES_SET_SEARCH_STRING:
      return {
        ...state,
        searchString: action.search,
      };
    case CASES_SET_FILTER_STRING:
      return {
        ...state,
        visibleState: action.payload,
      };
    case CASES_TOGGLE_LOADING:
      return {
        ...state,
        loading: !state.loading,
      };
    case CASES_TOGGLE_NEW_CASE_DROPDOWN:
      return {
        ...state,
        openNewCaseDropdown: !state.openNewCaseDropdown,
      };
    case CASES_TOGGLE_FILTER_DROPDOWN:
      return {
        ...state,
        openFilterCasesDropdown: !state.openFilterCasesDropdown,
      };
    case CASES_RESET_STORE:
      return {
        ...initialState,
      };
    case CASES_RESOLVE_CASE:
      const cases = state.cases.map((theCase) => {

        if (theCase.ticketNumber === action.id) {

          theCase.state = 'Resolved';
        
        }
        return theCase;
      
      });

      return {
        ...state,
        cases: [...cases],
      };

    case CASES_ESCALATE_CASE:
      const casesEscalation = state.cases.map((theCase) => {

        if (theCase.ticketNumber === action.id) {

          theCase.escalationState = 'Escalated';
        
        }
        return theCase;
      
      });
      return {
        ...state,
        cases: [...casesEscalation],
      };
    default:
      return {
        ...state,
      };
  }

}
