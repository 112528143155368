import {
  CARD_PAYMENT_SET_BILL, CARD_PAYMENT_SET_BILL_PAID,
  CARD_PAYMENT_SET_CARD_ERRORS, CARD_PAYMENT_SET_CARD_REDIRECT,
  CARD_PAYMENT_SET_ERROR,
  CARD_PAYMENT_TOGGLE_LOADING,
  CARD_PAYMENT_TOGGLE_PROCESSING, CARD_PAYMENT_UPDATE_CARD_ADDRESS,
  CARD_PAYMENT_UPDATE_CARD_DETAILS,
  CardPaymentActions,
  ICardPaymentState,
} from './types';

const initialState: ICardPaymentState = {
  loading: false,
  processing: false,
  showThankYou: false,
  error: '',
  bill: {
    id: 0,
    totalValue: 0,
    date: '',
    paymentMethod: '',
    artifacts: [],
    credit: 0,
    toPay: 0,
    payment: {
      paid: false,
      date: '',
    },
  },
  cardDetails: {
    cardType: 'visa',
    cardNumber: '',
    cardHolder: '',
    cardExpirationMonth: '',
    cardExpirationYear: '',
    cardIssueMonth: '',
    cardIssueYear: '',
    cardIssueNumber: '',
    cardCVC: '',
    cardAutoCharge: false,
  },
  cardAddress: {
    addressLine1: '',
    addressLine2: '',
    city: '',
    postcode: '',
    countryCode: 'GB',
  },
  cardRedirect: {
    url: '',
    pareq: '',
    md: '',
    creq: '',
    threeDSSessionData: '',
  },
  cardErrors: {},
};

export function cardPaymentReducer(state: ICardPaymentState = initialState, action: CardPaymentActions) {

  switch (action.type) {
    case CARD_PAYMENT_TOGGLE_LOADING:
      return {
        ...state,
        loading: !state.loading,
      };
    case CARD_PAYMENT_SET_BILL:
      return {
        ...state,
        bill: { ...action.bill, payment: action.bill.payment ? { ...action.bill.payment } : { ...initialState.bill.payment } },
      };
    case CARD_PAYMENT_SET_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case CARD_PAYMENT_UPDATE_CARD_DETAILS:
      return {
        ...state,
        cardDetails: { ...state.cardDetails, [action.field]: action.value },
      };
    case CARD_PAYMENT_UPDATE_CARD_ADDRESS:
      return {
        ...state,
        cardAddress: { ...state.cardAddress, [action.field]: action.value },
      };
    case CARD_PAYMENT_SET_CARD_ERRORS:
      return {
        ...state,
        cardErrors: { ...action.errors },
      };
    case CARD_PAYMENT_TOGGLE_PROCESSING:
      return {
        ...state,
        processing: !state.processing,
      };
    case CARD_PAYMENT_SET_BILL_PAID:
      return {
        ...state,
        showThankYou: true,
        bill: { ...state.bill, payment: { paid: true, date: action.date } },
      };
    case CARD_PAYMENT_SET_CARD_REDIRECT:
      return {
        ...state,
        cardRedirect: { ...action.redirect },
      };
    default:
      return { ...state };
  }

}
