/* eslint-disable */
import React, {Component} from 'react';
import {Col, FormGroup, Input, Label, Button, Row} from 'reactstrap';
import {connect} from 'react-redux';
import numeral from 'numeral';
import {getTerms} from '../../../helpers/domains/domains';
import {addToDomainCart} from '../../../actions/domainCart';
import {resetDomainCheckout} from '../../../actions/domainCheckout';
import {setDomainRenewTotalPrice, setDomainRenewYears} from '../../../actions/domain';


class DomainRenew extends Component {

  handleAddToCart = () =>{

    const {info, tld, renew} = this.props.domain;
    this.props.dispatch(resetDomainCheckout());
    this.props.dispatch(addToDomainCart({
      ...info,
      tld,
      id: info.id,
      action: 'renew',
      years: renew.years,
      total: numeral(tld.price.renew * renew.years).format('0,0.00')
    }));
    
  };

  handleRenewYear = (e) => {

    const {tld} = this.props.domain;
    this.props.dispatch(setDomainRenewYears(e.target.value));
    this.props.dispatch(setDomainRenewTotalPrice(e.target.value * tld.price.renew));
    
  };

  render() {

    const {info, tld, renew} = this.props.domain;
    const totalPrice = renew.totalPrice ? renew.totalPrice : tld.price ? numeral(tld.price.renew * renew.years).format('0,0.00') : 0;
    const cartItems = Object.keys(this.props.cart).length;
    return (
            <Row>
                <Col md={cartItems ? 8 : 6} sm={12}>
                    <FormGroup row>
                        <Col md="3">
                            <Label
                                className="text-secondary col-form-label"><strong>Years to Renew</strong></Label>
                        </Col>
                        <Col xs="12" md="9">
                            <Input type="select" onChange={this.handleRenewYear} value={renew.years}>
                                {tld.maxPeriod && getTerms(info, tld).map((value, index) => {

                                  return <option key={index + 1} value={value}>{value}</option>;
                                
                                })}
                            </Input>
                        </Col>
                    </FormGroup>
                    <FormGroup row >
                        <Col md="3">
                            <Label className="text-secondary col-form-label"><strong>Price</strong></Label>
                        </Col>
                        <Col xs="12" md="9" className="d-flex flex-row-reverse">
                            <p className="form-control border-0 mb-0 text-right">£{tld.price.renew} + VAT</p>
                        </Col>
                    </FormGroup>
                    <FormGroup row >
                        <Col md="3">
                            <Label className="text-secondary col-form-label"><strong>Total</strong></Label>
                        </Col>
                        <Col xs="12" md="9" className="d-flex flex-row-reverse">
                            <p className="form-control border-0 text-right">£{totalPrice} + VAT</p>
                        </Col>
                    </FormGroup>
                    <FormGroup row >
                        <Col className="d-flex flex-row-reverse">
                            <Button color="warning" outline onClick={this.handleAddToCart}>Add To Order</Button>
                        </Col>
                    </FormGroup>
                </Col>
            </Row>
    );
    
  }
}

function mapStateToProps ({ domain, domainCart }) {

  return {
    domain,
    cart: domainCart
  };

}

export default connect(mapStateToProps)(DomainRenew);
