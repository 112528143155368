import {
  ADDJTISUB, DELALLJTISUBS, DELJTISUB, RESETJTIDATA, SAVEJTISUBS,
} from './types';

export function stopAllJTI() {

  return {
    type: DELALLJTISUBS,
  };

}

export function addJTISubs(payload) {

  return {
    type: ADDJTISUB,
    payload,
  };

}

export function saveJTISubs(payload) {

  return {
    type: SAVEJTISUBS,
    payload,
  };

}

export function delJTISubs(payload) {

  return {
    type: DELJTISUB,
    payload,
  };

}

export function resetJTIData() {

  return {
    type: RESETJTIDATA,
  };

}
