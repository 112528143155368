export const JOIN_ROOMS = 'JOIN_ROOMS';
export const AUTHENTICATE_SOCKET = 'authentication';
export const LEAVE_ROOM = 'LEAVE';
export const ADD_LISTENERS_TO = 'ADD_LISTENERS_TO';
export const UPDATE_PROGRESS = 'PROGRESS';
export const SOCKET_DISCONNECT = 'SOCKET_DISCONNECT';
export const SOCKET_CONNECT = 'SOCKET_CONNECT';
export const SET_SOCKET_AUTHENTICATED = 'authenticated';
export const PING = 'PING';

export interface ISocketIOState {
  status: string;
}

export type SocketIOStatusAction = {
  type: typeof SET_SOCKET_AUTHENTICATED | typeof SOCKET_DISCONNECT | typeof SOCKET_CONNECT;
};

export type SocketIOActionTypes = SocketIOStatusAction;
