/* eslint-disable */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Card, CardBody, CardTitle} from 'reactstrap';

class Card3DSecure extends Component {

  componentDidMount() {

    this.inputElement.click();

  }

  render() {

    return (
      <Card>
        <CardTitle>Redirecting to 3D Secure Verification...</CardTitle>
        <CardBody className="text-center">
          <form method="post" action={this.props.domainCheckout.cardRedirect.url}>
            {this.props.domainCheckout.cardRedirect.pareq ? (
              <>
                <input type="hidden" name="PaReq" value={this.props.domainCheckout.cardRedirect.pareq}/>
                <input type="hidden" name="TermUrl" value={`${process.env.REACT_APP_API_URL}/secureCallback`}/>
                <input type="hidden" name="MD" value={this.props.domainCheckout.cardRedirect.md}/>
              </>
            ) : (
              <>
                <input type="hidden" name="creq" value={this.props.domainCheckout.cardRedirect.creq}/>
                <input type="hidden" name="threeDSSessionData"
                       value={this.props.domainCheckout.cardRedirect.threeDSSessionData}/>
              </>
            )}
            <button type="submit" ref={input => this.inputElement = input}>Please click button to Authenticate your
              card
            </button>
          </form>
        </CardBody>
      </Card>

    );

  }

}

function mapStateToProps({domainCheckout}) {

  return {
    domainCheckout
  };

}

export default connect(mapStateToProps)(Card3DSecure);