import { AxiosResponse } from 'axios';
import fileDownload from 'js-file-download';
import { setSystemError } from '../system/actions';
import { defaultErrorFeedback } from '../../actions/feedback';
import { api_downloadSignature, api_getSignatures } from '../../utils/Signature/Signature';
import {
  ISetSignaturesAction,
  ISetSignaturesSearchStringAction,
  SIGNATURES_SET_DATA,
  SIGNATURES_SET_SEARCH_STRING,
  TSignature,
} from './types';

const setSignatures = (data: Array<TSignature>): ISetSignaturesAction => ({
  type: SIGNATURES_SET_DATA,
  data,
});

export const getSignatures = () => (dispatch) => api_getSignatures().then((result: AxiosResponse) => {

  if (result.status === 200) {

    dispatch(setSignatures(result.data));
    return true;
  
  }
  if (result.data.error && result.data.error.id) {

    dispatch(setSystemError(result.data.error.id));
    return false;
  
  }

  dispatch(defaultErrorFeedback());
  return false;

});

export const setSignaturesSearchString = (searchString: string): ISetSignaturesSearchStringAction => ({
  type: SIGNATURES_SET_SEARCH_STRING,
  searchString,
});

export const downloadSignatureDocument = (id: string) => (dispatch) => api_downloadSignature(id).then((result: AxiosResponse) => {

  if (result.status === 200) {

    const splitContentDisposition = result.headers['content-disposition'].split('=');
    fileDownload(result.data, splitContentDisposition[1]);
  
  } else {

    dispatch(defaultErrorFeedback());
  
  }

});
