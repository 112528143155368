import React, { FunctionComponent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { TDocument } from '../../store/documents/types';
import Section from '../../components/Section';
import DocumentList from '../../components/DocumentList';
import { downloadDocument, getDocuments, setDocumentsSearchString } from '../../store/documents/actions';
import { AppDispatch } from '../../configureStore';

type TOwnProps = {
  documents: TDocument[];
  filtered: TDocument[];
  searchString: string;
  dispatch: AppDispatch;
};

const Documents: FunctionComponent<TOwnProps> = ({
  dispatch, documents, filtered, searchString,
}) => {

  const [loading, setLoading] = useState<boolean>(false);

  const getData = () => {

    setLoading(true);
    dispatch(getDocuments()).then(() => setLoading(false));
  
  };

  useEffect(() => {

    getData();
  
  }, []);

  const onChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {

    dispatch(setDocumentsSearchString(event.target.value.toString().toLowerCase()));
  
  };

  const download = (id): Promise<boolean> => dispatch(downloadDocument(id));

  return (
    <div className="animated fadeIn">
      <Row>
        <Col>
          <Section title="Documents">
            <DocumentList
              documents={documents}
              filtered={filtered}
              onChangeSearch={onChangeSearch}
              searchString={searchString}
              loading={loading}
              download={download}
            />
          </Section>
        </Col>
      </Row>
    </div>
  );

};

function mapStateToProps({ documents }) {

  return {
    documents: documents.documents,
    filtered: documents.filtered,
    searchString: documents.searchString,
  };

}

export default connect(mapStateToProps)(Documents);
