import React, { FunctionComponent } from 'react';
import { Col, Row } from 'reactstrap';
import Money from '../../helpers/Money';
import ComplexTable from '../../components/Table/ComplexTable';
import { TServiceOrderItem } from '../../store/serviceOrder/types';

type TOwnProps = {
  data: TServiceOrderItem[];
  handleDelete: (index: number) => any;
};
const NewOrderTable: FunctionComponent<TOwnProps> = ({ data, handleDelete }) => {

  const columns = [
    {
      Header: 'Service',
      accessor: 'productCode',
      width: 200,
    },
    {
      Header: 'Description',
      accessor: 'name',
    },
    {
      Header: 'Term',
      accessor: 'contractLength',
      width: 200,

      Footer: () => <strong>Total</strong>,
    },
    {
      Header: 'Non-recurring',
      accessor: 'install',
      width: 200,

      Cell: ({ value }) => <>{Money.format(value)}</>,
      Footer: (props) => <>{Money.format(props.data.reduce((sum, row) => row.install + sum, 0))}</>,
    },
    {
      Header: 'Monthly Recurring',
      accessor: 'rental',
      width: 200,

      Cell: ({ value }) => <>{Money.format(value)}</>,
      Footer: (props) => <>{Money.format(props.data.reduce((sum, row) => row.rental + sum, 0))}</>,
    },
    {
      filterable: false,
      sortable: false,
      accessor: 'id',
      width: 100,
      Cell: (props) => (
        <div
          className="h4 text-danger float-right"
          style={{ cursor: 'pointer' }}
          /* eslint-disable-next-line no-underscore-dangle */
          onClick={() => handleDelete(props.row._index)}
        >
          <i className="fa fa-trash" />
        </div>
      ),
    },
  ];

  return (
    <Row className="mb-4">
      <Col>
        <div className="p-4 bg-white rounded">
          <ComplexTable
            columns={columns}
            data={data}
          />
        </div>
      </Col>
    </Row>
  );

};

export default NewOrderTable;
