import React from 'react';
import Star from '../SvgIcons/Star';

type TOwnProps = {
  filled: boolean;
  onClick: (event: React.MouseEvent<HTMLElement>) => any;
  cssClass?: string;
  loaderClass?: string;
  servicesStarClass?: string;
};
const FavouriteStar: React.FC<TOwnProps> = (props) => {

  const {
    cssClass,
    servicesStarClass,
    onClick,
    filled,
  } = props;

  const handleOnClick = (event: React.MouseEvent<HTMLElement>) => {

    onClick(event);
  
  };
  return (
    <div className="position-relative">
      <div className="position-absolute">
        <span className={cssClass} role="button" onClick={handleOnClick} style={{ cursor: 'pointer' }}>
          <Star
            cssClassTop={`star ${servicesStarClass || ''}`}
            cssClass={`star  ${filled ? 'star--favourited' : ''}  `}
            title={filled ? 'Remove Favourite' : 'Add to Favourites'}
          />
        </span>
      </div>
    </div>
  );

};
export default FavouriteStar;
