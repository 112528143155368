import React, { FunctionComponent, useEffect, useState } from 'react';
import { Button, Form, Input } from 'reactstrap';
import { ISelectInputOptions } from '../Inputs/SelectInput';

type TOwnProps = {
  options: Array<ISelectInputOptions>;
  defaultEmpty?: string;
  onSubmit: (value: string) => any;
};
const AddSelectInputGroup: FunctionComponent<TOwnProps> = ({ options, defaultEmpty, onSubmit }) => {

  const [value, setValue] = useState<string | undefined>();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    setValue(event.target.value);
  
  };

  const addNew = () => {

    if (value) {

      onSubmit(value);
    
    }
  
  };

  return (
    <Form className="d-flex">
      <Input
        className="text-light md-0 mr-2"
        style={{ width: 300 }}
        type="select"
        onChange={handleChange}
        value={value}
      >
        {defaultEmpty ? <option key={0} value="">{defaultEmpty}</option> : ''}
        {
          options.map((option) => (
            <option
              key={`${value}-${option.label}`}
              value={option.value}
            >
              {option.label}
            </option>
          ))
        }
      </Input>
      <Button color="primary" onClick={addNew}>Add</Button>
    </Form>
  );

};

export default AddSelectInputGroup;
