import React from 'react';
import { Col, Row } from 'reactstrap';
import MonitoringHost from '../../../components/Widgets/MonitoringHost';
import MonitorUpTime from '../../../components/Widgets/Monitoring/MonitorUptime';
import { TMonitoredItem } from '../../../store/monitoredItem/types';
import LinkedServices from '../../../components/Widgets/LinkedServices';
import { IUserState } from '../../../store/user/types';

type TOwnProps = {
  item: TMonitoredItem;
  popUpHandler: (key: string, multiplier: number, format?: string, event?: React.MouseEvent<HTMLDivElement>) => any;
  user: IUserState;
};
const calculateUptime = (seconds: number) => {

  const output = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  };
  if ((seconds / 86400) >= 1) {

    output.days = Math.floor(seconds / 86400);
    seconds -= Math.floor((seconds / 86400)) * 86400;
  
  }
  if ((seconds / 3600) >= 1) {

    output.hours = Math.floor(seconds / 3600);
    seconds -= Math.floor((seconds / 3600)) * 3600;
  
  }
  if ((seconds / 60) >= 1) {

    output.minutes = Math.floor(seconds / 60);
    seconds -= Math.floor((seconds / 60)) * 60;
  
  }
  if (seconds > 0) {

    output.seconds = seconds;
  
  }

  return output;

};

const ZyxelTemplate: React.FC<TOwnProps> = (props) => {

  const { item, user } = props;
  return (
    <Row className="d-flex align-items-stretch">
      <Col className="mb-2" md={4} lg={4}>
        <Row className="h-100">
          <Col className="mb-2" md={12}>
            <MonitoringHost name={item.name} location={item.location} />
          </Col>
          <Col className="mb-2" md={12}>
            <LinkedServices
              user={user}
              services={item.services}
            />
          </Col>
        </Row>
      </Col>
      <Col className="mb-2" sm={12} md={8} lg={8}>
        <Row className="h-100">
          <Col className="mb-2" lg={4} xl={4}>
            <MonitorUpTime {...calculateUptime(item.uptime)} />
          </Col>
        </Row>
      </Col>
    </Row>
  );

};

export default ZyxelTemplate;
