import React from 'react';

interface IEmailLink {
  email: string;
  subject?: string;
}
const EmailLink: React.FC<IEmailLink> = ({ email, subject }) => (
  <a className="position-relative font-xl mx-3" href={`mailto:${email}?${subject ? `subject=${subject}` : ''}`}>
    <i className="icon icon-envelope position-absolute" title="email" style={{ bottom: '0px' }} />
  </a>
);

export default EmailLink;
