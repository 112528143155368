import * as React from 'react';
import { Button } from 'reactstrap';
import { IButtonClickable } from './types';

const DeleteIconButton: React.FC<IButtonClickable> = (props) => {

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {

    props.onClick(event);
  
  };
  return (
    <Button color="danger" onClick={handleClick}><i title="delete" className="fa fa-2x fa-trash" /></Button>
  );

};

export default DeleteIconButton;
