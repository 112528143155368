import {
  CONTACTS_NEW_CONTACT,
  CONTACTS_POPULATE_CONTACT_TYPES,
  CONTACTS_REMOVE_CONTACT,
  CONTACTS_RESET_STORE,
  CONTACTS_SET_CONTACTS,
  CONTACTS_SET_SEARCH_STRING,
  CONTACTS_TOGGLE_LOADING,
  CONTACTS_UPDATE_CONTACT,
  ContactsActionTypes,
  IContactsState,
} from './types';
import { ContactActionTypes } from '../contact/types';

const initialState: IContactsState = {
  loading: false,
  searchString: '',
  filtered: [],
  contacts: [],
  successMessage: null,
};
export function contactsReducer(state = initialState, action: ContactsActionTypes | ContactActionTypes) {

  switch (action.type) {
    case CONTACTS_POPULATE_CONTACT_TYPES: {

      const newContacts = state.contacts.map((contact) => {

        const contactTypeNames = contact.contactTypes.map((val) => {

          const typeFound = action.contactTypes.filter((type) => type.id === val);
          if (typeFound.length > 0) {

            return typeFound[0].name;
          
          }
        
        });
        return { ...contact, contactTypeNames: [...contactTypeNames] };
      
      });

      return {
        ...state,
        contacts: newContacts,
      };
    
    }

    case CONTACTS_REMOVE_CONTACT:
      const newContacts = state.contacts.filter((contact) => contact.id !== action.contact.id);
      return {
        ...state,
        contacts: newContacts,
      };
    case CONTACTS_SET_CONTACTS:

      return {
        ...state,
        contacts: action.contacts,
      };
    case CONTACTS_NEW_CONTACT: {

      const { data } = action;
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const newContacts = [data, ...state.contacts];
      return {
        ...state,
        contacts: [...newContacts],
      };
    
    }
    case CONTACTS_TOGGLE_LOADING:
      return {
        ...state,
        loading: !state.loading,
      };
    case CONTACTS_SET_SEARCH_STRING:
      const filtered = state.contacts.filter((contact) => String(contact.name).toLowerCase().indexOf(action.search.toLowerCase()) !== -1
                    || String(contact.email).toLowerCase().indexOf(action.search.toLowerCase()) !== -1
                    || String(JSON.stringify(contact.contactTypeNames)).toLowerCase().indexOf(action.search.toLowerCase()) !== -1
                    || String(contact.phone).toLowerCase().indexOf(action.search.toLowerCase()) !== -1);

      return {
        ...state,
        searchString: action.search,
        filtered,
      };
    case CONTACTS_UPDATE_CONTACT:
      return {
        ...state,
        contacts: state.contacts.map((contact) => {

          if (contact.id === action.data.id) {

            return { ...contact, ...action.data };
          
          }

          return contact;
        
        }),
      };
    case CONTACTS_RESET_STORE:
      return { ...initialState };
    default: return { ...state };
  }

}
