import { Moment } from 'moment';
import { TMonitoredItemGraph } from '../monitoredItem/types';
import { IServiceBilling } from '../service/types';
import { TTrafficData } from '../monitoring/types';

export const SERVICE_CONNECTIVITY_SET_DATA = 'SERVICE_CONNECTIVITY_SET_DATA';
export const SERVICE_CONNECTIVITY_RESET_DATA = 'SERVICE_CONNECTIVITY_RESET_DATA';
export const SERVICE_CONNECTIVITY_TOGGLE_LOADING = 'SERVICE_CONNECTIVITY_TOGGLE_LOADING';
export const SERVICE_CONNECTIVITY_TOGGLE_GRAPH_DATA_LOADING = 'SERVICE_CONNECTIVITY_TOGGLE_GRAPH_DATA_LOADING';
export const SERVICE_CONNECTIVITY_SET_GRAPH_DATA = 'SERVICE_CONNECTIVITY_SET_GRAPH_DATA';
export const SERVICE_CONNECTIVITY_SET_GRAPH_DATES = 'SERVICE_CONNECTIVITY_SET_GRAPH_DATES';

export interface IServiceConnectivityState {
  loading: boolean;
  data: TServiceConnectivity;

}

export type TServiceConnectivity = {
  id: string;
  name: string;
  type: string;
  aEnd: string;
  aEndLocation: string;
  bEnd: string;
  bEndLocation: string;
  bEndDeviceId: string;
  bearer: string;
  bandwidth: string;
  graphs: TMonitoredItemGraph[];
  billing: IServiceBilling;
  goLiveDate: string;

};

export interface ISetServiceConnectivityDataAction {
  type: typeof SERVICE_CONNECTIVITY_SET_DATA;
  data: TServiceConnectivity;
}

export interface IReSetServiceConnectivityDataAction {
  type: typeof SERVICE_CONNECTIVITY_RESET_DATA;
}

export interface IToggleServiceConnectivityLoadingAction {
  type: typeof SERVICE_CONNECTIVITY_TOGGLE_LOADING;
}

export interface IToggleServiceConnectivityGraphDataLoadingAction {
  type: typeof SERVICE_CONNECTIVITY_TOGGLE_GRAPH_DATA_LOADING;
  graphId;
}

export interface ISetServiceConnectivityGraphDataAction {
  type: typeof SERVICE_CONNECTIVITY_SET_GRAPH_DATA;
  graphId: string;
  dataIn: TTrafficData;
  dataOut: TTrafficData;
}

export interface ISetServiceConnectivityGraphDatesAction {
  type: typeof SERVICE_CONNECTIVITY_SET_GRAPH_DATES;
  graphId;
  startDate: Moment;
  endDate: Moment;
}

export type ServiceConnectivityReducerActions = ISetServiceConnectivityDataAction | IToggleServiceConnectivityLoadingAction |
IToggleServiceConnectivityGraphDataLoadingAction | ISetServiceConnectivityGraphDataAction | IReSetServiceConnectivityDataAction |
ISetServiceConnectivityGraphDatesAction;
