import React, { FunctionComponent, useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import SelectInput, { ISelectInputOptions } from '../../components/Inputs/SelectInput';
import Section from '../../components/Section';
import { TContact } from '../../store/contact/types';
import { TPreSalesServiceOrderState } from '../../store/serviceOrder/types';
import { IFormError } from '../../components/Forms/types';

type TOwnProps = {
  contacts: TContact[];
  data: TPreSalesServiceOrderState;
  setErrors: (errors: IFormError[]) => any;
  errors: IFormError[];
  onChange: (key: string, value: string) => any;
};
const NewOrderContactFields: FunctionComponent<TOwnProps> = ({
  contacts, setErrors, errors, data, onChange,
}) => {

  const validate = (): void => {

    const errorArr: IFormError[] = [];
    const signatory = contacts.find((contact) => contact.id === data.signatoryContactId);
    if (!signatory?.email) {

      errorArr.push({
        id: 'signatoryContactId',
        message: 'Contact does not have an e-mail. Please edit contact to add an email and try again.',
      });
    
    }
    const billingContact = contacts.find((contact) => contact.id === data.billingContactId);
    if (!billingContact?.email) {

      errorArr.push({
        id: 'billingContactId',
        message: 'Contact does not have an e-mail. Please edit contact to add an email and try again.',
      });
    
    }

    setErrors(errorArr);
  
  };

  useEffect(() => {

    validate();
  
  }, [data]);

  const adminOnlyTooltip = (
    <i
      className="ml-1 fa fa-info-circle small"
      data-tip="Only Administrators can be assigned orders."
    />
  );

  const contactOptions: Array<ISelectInputOptions> = contacts.filter((contact: TContact) => contact.portalUser?.roleId === 864640000)
    .map((contact: TContact) => ({
      value: contact.id || '',
      label: contact.name,
    }));

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => onChange(event.target.name, event.target.value);

  return (
    <Section title="Contacts">
      <Row className="mb-4">
        <Col>
          <div className="p-4 bg-white rounded">
            <div style={{ maxWidth: '500px' }}>
              <SelectInput
                label={(
                  <>
                    Order Contact
                    {adminOnlyTooltip}
                  </>
)}
                id="order-contact"
                name="billingContactId"
                options={contactOptions}
                value={data.billingContactId}
                onChange={handleChange}
                horizontal={false}
                error={errors.find(({ id }) => id === 'billingContactId')}
              />
              <SelectInput
                label={(
                  <>
                    Signatory
                    {adminOnlyTooltip}
                  </>
)}
                id="order-contact"
                name="signatoryContactId"
                options={contactOptions}
                value={data.signatoryContactId}
                onChange={handleChange}
                horizontal={false}
                data-tip="Only Admins can be assigned orders."
                error={errors.find(({ id }) => id === 'signatoryContactId')}
              />
            </div>
          </div>
        </Col>
      </Row>
    </Section>
  );

};

export default NewOrderContactFields;
