import React from 'react';

interface IProps {
  cssClass?: string;
  title?: string;
  cssClassTop?: string;
}

const CloudUp: React.FC<IProps> = ({ cssClassTop, cssClass, title }) => (
  <svg
    className={cssClassTop || 'cls-2'}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24.97 21.41"
  >
    <title>{title || ''}</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Design">
        <path
          className={cssClass || 'cls-2'}
          d="M20.46,6.69a4.21,4.21,0,0,1,3.2,1.39A4.7,4.7,0,0,1,25,11.43a4.5,4.5,0,0,1-1.33,3.26,4.37,4.37,0,0,1-3.24,1.36H14.77v-.89H20.4A3.51,3.51,0,0,0,23,14.08a3.65,3.65,0,0,0,1.06-2.65A3.86,3.86,0,0,0,23,8.7,3.4,3.4,0,0,0,20.4,7.53l-.83-.06V6.35a5.24,5.24,0,0,0-1.65-3.93,5.53,5.53,0,0,0-4-1.58A5.22,5.22,0,0,0,11,1.73,5.83,5.83,0,0,0,8.92,4.07l-.34.72L7.8,4.46a2.4,2.4,0,0,0-1.11-.28,2.36,2.36,0,0,0-1.59.61,2.64,2.64,0,0,0-.86,1.56l-.06.51L3.68,7a3.84,3.84,0,0,0-2,1.51A4.17,4.17,0,0,0,.89,11a4.09,4.09,0,0,0,1.2,3A4,4,0,0,0,5,15.16h5.07v.89H5a4.86,4.86,0,0,1-3.57-1.44A4.85,4.85,0,0,1,0,11a5,5,0,0,1,1-3A4.87,4.87,0,0,1,3.4,6.24,3.63,3.63,0,0,1,4.54,4.18a3.1,3.1,0,0,1,2.15-.84,3.26,3.26,0,0,1,1.45.34A6.2,6.2,0,0,1,10.51,1,6.38,6.38,0,0,1,14,0a6.29,6.29,0,0,1,4.6,1.9,6.17,6.17,0,0,1,1.92,4.57v.11ZM9,10.93l-.62-.62,4.13-4.18,4.18,4.18-.67.62-3.07-3V21.41H12V7.92Z"
        />
      </g>
    </g>
  </svg>
);

export default CloudUp;
