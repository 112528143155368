import { TPortalUser } from '../contact/types';

export const REPORTS_SET_LIST = 'REPORTS_SET_LIST';
export const REPORTS_REMOVE_REPORT_FROM_LIST = 'REPORTS_REMOVE_REPORT_FROM_LIST';
export const REPORTS_UPDATE_REPORT_IN_LIST = 'REPORTS_UPDATE_REPORT_IN_LIST';
export const REPORTS_ADD_REPORT_TO_LIST = 'REPORTS_ADD_REPORT_TO_LIST';

export interface IReportsState {
  list: IReport[];
}

export interface IReport {
  id: number;
  name: string;
  scheduled: boolean;
  frequency: ReportConfigFrequency;
  config: ReportConfig;
  createdAt: string;
  updatedAt: string;
  user: TPortalUser;
}

export type TDeviceUsageReportPort = {
  id: string;
  name: string;
  in: boolean;
  out: boolean;
};

type TDeviceUsageReportConfig = {
  ports?: TDeviceUsageReportPort[];
  contacts?: Array<string>;
  deviceId?: string;
};

export type TDeviceUsageReport = {
  id: number;
  name: string;
  scheduled: boolean;
  frequency: ReportConfigFrequency;
  config: TDeviceUsageReportConfig;
  createdAt: string;
  updatedAt: string;
  user: TPortalUser;
};

export type TDeviceUsageReportUpdate = {
  name?: string;
  ports?: TDeviceUsageReportPort[];
  contacts?: Array<string>;
  frequency?: ReportConfigFrequency;
};

export interface ReportConfig {
  frequency: ReportConfigFrequency;
  ports: TDeviceUsageReportPort[];
  contacts: Array<string>;
  deviceId?: string;
}

export enum ReportConfigFrequency {
  oneOff = 'one-off',
  daily = 'daily',
}

interface ISetReportsListAction {
  type: typeof REPORTS_SET_LIST;
  list: IReport[];
}

interface IRemoveReportFromListAction {
  type: typeof REPORTS_REMOVE_REPORT_FROM_LIST;
  id: number;
}
interface IUpdateReportInListAction {
  type: typeof REPORTS_UPDATE_REPORT_IN_LIST;
  report: IReport;
}

export type ReportsActionTypes = ISetReportsListAction | IRemoveReportFromListAction | IUpdateReportInListAction;
