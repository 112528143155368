import React, { FunctionComponent } from 'react';
import { Button, FormGroup, Label } from 'reactstrap';

type TProps = {
  label: string;
  value: string;
  disabled?: boolean;
  color?: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => any;
};

const FormButton: FunctionComponent<TProps> = (props) => {

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {

    props.onClick(event);
  
  };

  return (
    <FormGroup className="row">
      <Label className="col-md-4 text-light col-form-label font-weight-bold" for={props.label} style={{ fontSize: '16px' }}>{props.label}</Label>
      <div className="col-md-8">
        <Button color={props.color} onClick={handleClick}>{props.value}</Button>
      </div>
    </FormGroup>
  );

};

export default FormButton;
