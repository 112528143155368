import numeral from 'numeral';
import { defaultErrorFeedback, successFeedback } from './feedback';
import {
  api_getAccountDomain,
  api_releaseDomain,
  api_requestAuthCode,
  api_updateDomainAutoRenew,
  api_updateDomainContact,
} from '../utils/Domains/Domains';
import { getGlueRecords, getNameservers } from '../helpers/domains/domains';
import { api_assignNameservers, api_createNameserver, api_deleteNameserver } from '../utils/Domains/Nameservers';
import { setSystemConnectionError, setSystemError } from '../store/system/actions';
import { api_createDNSSec, api_deleteDNSSec, api_getDNSSec } from '../utils/Domains/DNSSec';

export const DOMAIN_RESET = 'DOMAIN_RESET';
export const DOMAIN_RESTORE_INFO = 'DOMAIN_RESTORE_INFO';
export const DOMAIN_RESTORE_CONTACT = 'DOMAIN_RESTORE_CONTACT';
export const DOMAIN_RESTORE_NAMESERVERS = 'DOMAIN_RESTORE_NAMESERVERS';
export const DOMAIN_SET_DOMAIN_INFO = 'DOMAIN_SET_DOMAIN_INFO';
export const DOMAIN_SET_DOMAIN_CONTACT = 'DOMAIN_SET_DOMAIN_CONTACT';
export const DOMAIN_SET_DOMAIN_NAMESERVERS = 'DOMAIN_SET_DOMAIN_NAMESERVERS';
export const DOMAIN_SET_DOMAIN_GLUE_RECORDS = 'DOMAIN_SET_DOMAIN_GLUE_RECORDS';
export const DOMAIN_SET_DOMAIN_TLD = 'DOMAIN_SET_DOMAIN_TLD';
export const DOMAIN_SET_RELEASE_TAG = 'DOMAIN_SET_RELEASE_TAG';
export const DOMAIN_SET_RELEASE_SUCCESSFUL = 'DOMAIN_SET_RELEASE_SUCCESSFUL';
export const DOMAIN_SET_ACTIVE_TAB = 'DOMAIN_SET_ACTIVE_TAB';
export const DOMAIN_TOGGLE_LOADING = 'DOMAIN_TOGGLE_LOADING';
export const DOMAIN_SHOW_ERROR_ALERT = 'DOMAIN_SHOW_ERROR_ALERT';
export const DOMAIN_SET_DOMAIN_ERRORS = 'DOMAIN_SET_DOMAIN_ERRORS';
export const DOMAIN_SET_DOMAIN_CONTACT_FIELD = 'DOMAIN_SET_DOMAIN_CONTACT_FIELD';
export const DOMAIN_SET_DOMAIN_LOADING_TEXT = 'DOMAIN_SET_DOMAIN_LOADING_TEXT';
export const DOMAIN_ADD_GLUE_RECORD = 'DOMAIN_ADD_GLUE_RECORD';
export const DOMAIN_UPDATE_NAMESERVER_FIELD = 'DOMAIN_UPDATE_NAMESERVER_FIELD';
export const DOMAIN_TOGGLE_SHOW_NEW_GLUE_RECORD = 'DOMAIN_TOGGLE_SHOW_NEW_GLUE_RECORD';
export const DOMAIN_UPDATE_NEW_GLUE_RECORD_FIELD = 'DOMAIN_UPDATE_NEW_GLUE_RECORD_FIELD';
export const DOMAIN_SET_NEW_GLUE_RECORD_ERRORS = 'DOMAIN_SET_NEW_GLUE_RECORD_ERRORS';
export const DOMAIN_TOGGLE_NEW_GLUE_RECORD_LOADING = 'DOMAIN_TOGGLE_NEW_GLUE_RECORD_LOADING';
export const DOMAIN_DELETE_GLUE_RECORD = 'DOMAIN_DELETE_GLUE_RECORD';
export const DOMAIN_SET_RENEW_YEARS = 'DOMAIN_SET_RENEW_YEARS';
export const DOMAIN_SET_RENEW_TOTAL_PRICE = 'DOMAIN_SET_RENEW_TOTAL_PRICE';
export const DOMAIN_TOGGLE_AUTO_RENEW = 'DOMAIN_TOGGLE_AUTO_RENEW';
export const DOMAIN_SET_DOMAIN_DNSSEC = 'DOMAIN_SET_DOMAIN_DNSSEC';
export const DOMAIN_SET_DOMAIN_DNSSEC_FORM_FIELD = 'DOMAIN_SET_DOMAIN_DNSSEC_FORM_FIELD';
export const DOMAIN_ADD_DOMAIN_DNSSEC = 'DOMAIN_ADD_DOMAIN_DNSSEC';
export const DOMAIN_REMOVE_DOMAIN_DNSSEC = 'DOMAIN_REMOVE_DOMAIN_DNSSEC';
export const DOMAIN_RESET_DOMAIN_DNSSEC_FORM = 'DOMAIN_RESET_DOMAIN_DNSSEC_FORM';

export function resetDomain() {

  return {
    type: DOMAIN_RESET,
  };

}
export function toggleDomainLoading() {

  return {
    type: DOMAIN_TOGGLE_LOADING,
  };

}

export function toggleNewGlueRecordLoading() {

  return {
    type: DOMAIN_TOGGLE_NEW_GLUE_RECORD_LOADING,
  };

}

export function toggleShowNewGlueRecord() {

  return {
    type: DOMAIN_TOGGLE_SHOW_NEW_GLUE_RECORD,
  };

}

export function toggleDomainAutoRenew() {

  return {
    type: DOMAIN_TOGGLE_AUTO_RENEW,
  };

}

export function setDomainErrors(errors = {}) {

  return {
    type: DOMAIN_SET_DOMAIN_ERRORS,
    errors,
  };

}

export function setDomainReleaseTAG(tag) {

  return {
    type: DOMAIN_SET_RELEASE_TAG,
    tag,
  };

}

export function setDomainNewGlueRecordErrors(errors = {}) {

  return {
    type: DOMAIN_SET_NEW_GLUE_RECORD_ERRORS,
    errors,
  };

}

export function updateDomainContactField(id, value) {

  return {
    type: DOMAIN_SET_DOMAIN_CONTACT_FIELD,
    field: { [id]: value },
  };

}

export function updateNewGlueRecordField(id, value) {

  return {
    type: DOMAIN_UPDATE_NEW_GLUE_RECORD_FIELD,
    field: { [id]: value },
  };

}

export function setDomainActiveTab(tab) {

  return {
    type: DOMAIN_SET_ACTIVE_TAB,
    tab,
  };

}

export function setDomainLoadingText(text) {

  return {
    type: DOMAIN_SET_DOMAIN_LOADING_TEXT,
    text,
  };

}

export function addDomainGlueRecord(glueRecord) {

  return {
    type: DOMAIN_ADD_GLUE_RECORD,
    glueRecord,
  };

}

export function updateDomainNameserverField(index, value) {

  return {
    type: DOMAIN_UPDATE_NAMESERVER_FIELD,
    index,
    value,
  };

}

export function removeGlueRecord(index) {

  return {
    type: DOMAIN_DELETE_GLUE_RECORD,
    index,
  };

}

export function setDomainRenewYears(years) {

  return {
    type: DOMAIN_SET_RENEW_YEARS,
    years,
  };

}

export function setDomainRenewTotalPrice(price) {

  return {
    type: DOMAIN_SET_RENEW_TOTAL_PRICE,
    price: numeral(price).format('0,0.00'),
  };

}

export function restoreDomainInfo() {

  return {
    type: DOMAIN_RESTORE_INFO,
  };

}
export function restoreDomainContact() {

  return {
    type: DOMAIN_RESTORE_CONTACT,
  };

}

export function restoreDomainNameservers() {

  return {
    type: DOMAIN_RESTORE_NAMESERVERS,
  };

}

export function getDomainData(domainId) {

  return (dispatch) => api_getAccountDomain(domainId).then((result) => {

    if (result.status === 200) {

      const {
        id, name, createdAt, expireAt, autoRenew, contact, nameservers, tld,
      } = result.data;
      dispatch({
        type: DOMAIN_SET_DOMAIN_INFO,
        info: {
          id, name, createdAt, expireAt, autoRenew,
        },
      });
      dispatch({ type: DOMAIN_SET_DOMAIN_CONTACT, contact });
      dispatch({ type: DOMAIN_SET_DOMAIN_NAMESERVERS, nameservers: getNameservers(name, nameservers) });
      dispatch({ type: DOMAIN_SET_DOMAIN_GLUE_RECORDS, glueRecords: getGlueRecords(name, nameservers) });
      dispatch({ type: DOMAIN_SET_DOMAIN_TLD, tld });
    
    } else if (result.data?.error && result.data.error.id) {

      if (result.data.error.message === 'could not connect to Nominet host') {

        dispatch(setSystemConnectionError());
      
      } else {

        dispatch(setSystemError(result.data.error.id));
      
      }
    
    } else {

      dispatch(defaultErrorFeedback());
    
    }
  
  });

}

export function updateDomainContact(domainId, contact) {

  return (dispatch) => api_updateDomainContact(domainId, contact).then((result) => {

    if (result.status === 200) {

      dispatch(successFeedback('Contact updated!'));
      return true;
    
    }

    dispatch({ type: DOMAIN_SHOW_ERROR_ALERT, text: 'Could not update contact' });
    dispatch(defaultErrorFeedback());
    return false;
  
  });

}

export function assignDomainNameservers(domainId, nameservers) {

  return (dispatch) => api_assignNameservers(domainId, nameservers).then((result) => {

    if (result.status === 200) {

      dispatch(successFeedback('Nameservers updated!'));
      return true;
    
    }

    dispatch({ type: DOMAIN_SHOW_ERROR_ALERT, text: 'Could not update nameservers' });
    dispatch(defaultErrorFeedback());
    return false;
  
  });

}

export function createGlueRecord(domainId, glueRecord) {

  return (dispatch) => api_createNameserver(domainId, glueRecord).then((result) => {

    if (result.status === 200) {

      dispatch(successFeedback('Record created!'));
      dispatch(addDomainGlueRecord(result.data));
      return true;
    
    }

    dispatch({ type: DOMAIN_SHOW_ERROR_ALERT, text: 'Could not create record' });
    dispatch(defaultErrorFeedback());
    return false;
  
  });

}

export function deleteDomainGlueRecord(domainId, glueRecord) {

  return (dispatch) => api_deleteNameserver(domainId, glueRecord).then((result) => {

    if (result.status === 200) {

      dispatch(successFeedback('Record deleted!'));
      return true;
    
    }

    dispatch({ type: DOMAIN_SHOW_ERROR_ALERT, text: 'Could not delete record' });
    dispatch(defaultErrorFeedback());
    return false;
  
  });

}

export function releaseDomain(domainId, tag) {

  return (dispatch) => api_releaseDomain(domainId, tag).then((result) => {

    if (result.status === 200) {

      dispatch({ type: DOMAIN_SET_RELEASE_SUCCESSFUL });
      return true;
    
    }

    dispatch({ type: DOMAIN_SHOW_ERROR_ALERT, text: 'Could not release domain' });
    dispatch(defaultErrorFeedback());
    return false;
  
  });

}

export function requestDomainAuthCode(domainId) {

  return (dispatch) => api_requestAuthCode(domainId).then((result) => {

    if (result.status === 200) {

      dispatch({ type: DOMAIN_SET_RELEASE_SUCCESSFUL });
      return true;
    
    }

    dispatch({ type: DOMAIN_SHOW_ERROR_ALERT, text: 'Could not release domain' });
    dispatch(defaultErrorFeedback());
    return false;
  
  });

}

export function updateDomainAutoRenew(domainId, autoRenew) {

  return (dispatch) => api_updateDomainAutoRenew(domainId, autoRenew).then((result) => {

    if (result.status === 200) {

      dispatch(successFeedback('Domain updated!'));
      return true;
    
    }

    dispatch({ type: DOMAIN_SHOW_ERROR_ALERT, text: 'Could not update domain' });
    dispatch(defaultErrorFeedback());
    return false;
  
  });

}

export function setDomainDNSSec(data) {

  return {
    type: DOMAIN_SET_DOMAIN_DNSSEC,
    data,
  };

}

export function addDomainDNSSec(data) {

  return {
    type: DOMAIN_ADD_DOMAIN_DNSSEC,
    data,
  };

}

export function removeDomainDNSSec(data) {

  return {
    type: DOMAIN_REMOVE_DOMAIN_DNSSEC,
    data,
  };

}

export function getDNSSecData(domainId) {

  return (dispatch) => api_getDNSSec(domainId).then((result) => {

    if (result.status === 200) {

      dispatch(setDomainDNSSec(result.data));
      return true;
    
    }

    dispatch(defaultErrorFeedback());
    return false;
  
  });

}

export function updateDNSSecFormField(id, value) {

  return {
    type: DOMAIN_SET_DOMAIN_DNSSEC_FORM_FIELD,
    id,
    value,
  };

}

export function resetDNSSecForm() {

  return {
    type: DOMAIN_RESET_DOMAIN_DNSSEC_FORM,
  };

}

export function createDomainDNSSec(domainId, algorithm, keyTag, digestType, digest) {

  const data = {
    algorithm, keyTag, digestType, digest,
  };

  return (dispatch) => api_createDNSSec(domainId, data).then((result) => {

    if (result.status === 204) {

      dispatch(addDomainDNSSec(data));
      return true;
    
    }

    dispatch(defaultErrorFeedback());
    return false;
  
  });

}

export function deleteDomainDNSSec(domainId, data) {

  return (dispatch) => api_deleteDNSSec(domainId, data).then((result) => {

    if (result.status === 204) {

      dispatch(removeDomainDNSSec(data));
      return true;
    
    }

    dispatch(defaultErrorFeedback());
    return false;
  
  });

}
