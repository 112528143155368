export const isUkPhoneNumber = (phoneNumber) => {

  const phonePatternUK = /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?#(\d{4}|\d{3}))?$/;

  return phonePatternUK.test(phoneNumber);

};
/* *******************************************************
This will match for
+447222555555 | +44 7222 555 555 | (0722) 5555555 #2222
/* ******************************************************* */

export const hasCountryCode = (phoneNumber) => {

  const countryCodePattern = /[+][1-9]+[0-9].*$/;
  return countryCodePattern.test(phoneNumber);

};

/* *******************************************************
This will match for
+44 | +33 | +44 5555555 | +2222   |  +1
Any number preceded by '+' then 1 number above 0 then 1 number  0 or above 0
/* ******************************************************* */
