export const SHOW_ERROR_FEEDBACK = 'SHOW_ERROR_FEEDBACK';
export const SHOW_SUCCESS_FEEDBACK = 'SHOW_SUCCESS_FEEDBACK';

export function errorFeedback(message) {

  return {
    type: SHOW_ERROR_FEEDBACK,
    message,
  };

}

export function successFeedback(message) {

  return {
    type: SHOW_SUCCESS_FEEDBACK,
    message,
  };

}

export function defaultErrorFeedback() {

  return {
    type: SHOW_ERROR_FEEDBACK,
    message: 'There was an error with your request',
  };

}
