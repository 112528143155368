import {
  IServiceVeeamBackupState,
  SERVICE_VEEAM_BACKUP_RESET_DATA,
  SERVICE_VEEAM_BACKUP_SET_DATA,
  VeeamBackupReducerActions,
} from './types';

const initialState: IServiceVeeamBackupState = {
  data: {
    id: '',
    productCode: '',
    name: '',
    storageQuota: 0,
    usedStorageQuota: 0,
    vms: 0,
    rentalPeriod: 0,
    billing: {
      install: 0,
      rental: 0,
      billedTo: '',
    },
  },
};

export function serviceVeeamBackupReducer(state = initialState, action: VeeamBackupReducerActions) {

  switch (action.type) {
    case SERVICE_VEEAM_BACKUP_SET_DATA:
      return {
        ...state,
        data: action.data,
      };
    case SERVICE_VEEAM_BACKUP_RESET_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }

}
