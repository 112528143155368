import React, { useState } from 'react';
import LaddaButton, { ZOOM_OUT } from 'react-ladda';
import { Col, Row } from 'reactstrap';
import moment from 'moment';
import SearchInput from '../Inputs/SearchInput';
import SimpleLoader from '../SimpleLoader';
import ComplexTable from '../Table/ComplexTable';
import { TDocument } from '../../store/documents/types';
import CloudDown from '../SvgIcons/CloudDown';
import NoData from '../Widgets/NoData';

type TOwnProps = {
  documents: TDocument[];
  filtered: TDocument[];
  searchString: string;
  onChangeSearch: (event: React.ChangeEvent<HTMLInputElement>) => any;
  loading: boolean;
  download(id: string): Promise<boolean>;
};

const DocumentList: React.FC<TOwnProps> = ({
  loading, documents, filtered, searchString, onChangeSearch, download,
}) => {

  const [downloading, setDownloading] = useState<Array<string>>([]);

  const getDocument = (id) => {

    setDownloading((prevState) => prevState.concat(id));
    download(id).then(() => {

      setDownloading((prevState) => prevState.filter((downloadingId) => downloadingId !== id));
    
    });
  
  };

  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Type',
      accessor: 'type',
      width: 300,
    },
    {
      Header: 'Updated At',
      accessor: 'updatedAt',
      width: 300,
      Cell: (props) => moment(props.value).format('DD/MM/YYYY H:m:s'),
    },
    {
      Header: '',
      accessor: 'id',
      width: 120,
      Cell: (props) => (
        <LaddaButton
          className="btn btn-sm btn-primary btn-ladda"
          data-color="blue"
          data-style={ZOOM_OUT}
          loading={downloading === props.value}
          onClick={() => getDocument(props.value)}
        >
          <i className="icon-cloud-download" />
          {' '}
          Download
        </LaddaButton>
      ),
    },
  ];

  return (
    <>
      {documents.length >= 1 ? (
        <Row className="mb-4">
          <Col md={6}>
            <SearchInput value={searchString} onChange={onChangeSearch} />
          </Col>
        </Row>
      ) : ''}

      <SimpleLoader loading={loading}>
        <Row className="d-flex ml-0 mr-0">
          <Col className="bg-white rounded p-4" style={{ minHeight: 100 }}>
            {!loading ? (
              documents.length < 1 ? (
                <NoData text="No documents found." />
              ) : (
                <ComplexTable
                  columns={columns}
                  data={searchString ? filtered : documents}
                  defaultSorted={{ id: 'updatedAt', desc: true }}
                />
              )
            ) : ''}
          </Col>
        </Row>
      </SimpleLoader>
    </>
  );

};

export default DocumentList;
