/* eslint-disable */
import React, { Component }from 'react';
import {
  CardTitle,
  FormGroup,
  Label,
  Row,
  Col,
  Input,
  CardText,
  Button, Modal, ModalHeader, ModalBody, ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText
} from 'reactstrap';
import {connect} from 'react-redux';
import {updatedDiff} from 'deep-object-diff';
import {
  assignDomainNameservers, deleteDomainGlueRecord, DOMAIN_SET_DOMAIN_NAMESERVERS, removeGlueRecord, restoreDomainNameservers,
  setDomainLoadingText,
  toggleDomainLoading,
  toggleShowNewGlueRecord,
  updateDomainNameserverField
} from '../../../actions/domain';
import NewGlueRecord from './NewGlueRecord';
import SimpleLoader from '../../../components/SimpleLoader';
import {checkPermission} from "../../../utils/Auth/AuthService";
import toastr from "toastr";

class Nameservers extends Component {

  handleNameserverInput = (event) =>{

    this.props.dispatch(updateDomainNameserverField(event.target.id, event.target.value));
    
  };

  saveNameservers = () =>{
      if(!checkPermission('domains.write', this.props.user.permissions)){
          toastr.error('Unfortunately you are not authorized to do this!');
          return
      }
      this.props.dispatch(toggleDomainLoading());
      this.props.dispatch(setDomainLoadingText('Updating nameservers...'));
      const {info, nameserversEdited} = this.props.domain;
      const toAssign = Object.keys(nameserversEdited).reduce((carry, nameserver) => {

          if(nameserversEdited[nameserver].name.length >  0){

              carry.push(nameserversEdited[nameserver].name);

          }
          return carry;

      }, []);
      this.props.dispatch(assignDomainNameservers(info.id, toAssign)).then((result) => {

          if(result){

              this.props.dispatch({type: DOMAIN_SET_DOMAIN_NAMESERVERS, nameservers: nameserversEdited});

          }
          this.props.dispatch(toggleDomainLoading());

      });


  };

  restoreNameservers = () => {

    this.props.dispatch(restoreDomainNameservers());
    
  };

  toggleNewGlueRecord = () =>{

    this.props.dispatch(toggleShowNewGlueRecord());
    
  };

  deleteNameserver = (index) =>{
      if(!checkPermission('domains.write', this.props.user.permissions)){
          toastr.error('Unfortunately you are not authorized to do this!');
          return
      }
    const {glueRecords, info} = this.props.domain;
    this.props.dispatch(toggleDomainLoading());
    this.props.dispatch(setDomainLoadingText('Deleting record...'));
    this.props.dispatch(deleteDomainGlueRecord(info.id, glueRecords[index])).then(
      (result) => {

        if(result){

          this.props.dispatch(removeGlueRecord(index));
                
        }
        this.props.dispatch(toggleDomainLoading());
            
      }
    );
    
  };

  render () {

    const {nameservers, nameserversEdited, glueRecords, newGlueRecord} = this.props.domain;
    const changed = Object.keys(updatedDiff(nameservers, nameserversEdited)).length > 0;
    const cartItems = Object.keys(this.props.cart).length;
    const colWidth = cartItems ? 8 : 6;
    return (
            <div>
                <Row className="d-flex">
                    <Col md={colWidth} className="mb-3 mt-3">
                        {Object.keys(nameserversEdited).map((nameserver, index) => {

                          return (
                                <FormGroup row key={`nameserver${  index}`}>
                                    <Col md="3">
                                        <Label className="col-form-label">{nameserver}</Label>
                                    </Col>
                                    <Col xs="12" md="9">
                                        <Input type="text"
                                               id={nameserver}
                                               value={nameserversEdited[nameserver].name}
                                               onChange={this.handleNameserverInput}
                                        />
                                    </Col>
                                </FormGroup>
                          );
                        
                        })}
                        <div className="clearfix">
                            <Button className={changed ? 'mr-3' : ''} disabled={!changed} onClick={this.saveNameservers}>Update</Button>
                            {changed && <Button outline color="warning" onClick={this.restoreNameservers}>Cancel</Button>}
                        </div>
                    </Col>
                </Row>
                <Row className="d-flex mt-3">
                    <Col className="mb-3" md={colWidth}>
                        <CardTitle className="text-primary">Glue Records</CardTitle>
                        <CardText className="text-secondary">To create a nameserver using
                            your domain you must provide a valid host name and IPv4 or IPv6
                            address or both.</CardText>
                        <ListGroup>
                            {Object.keys(glueRecords).map((record, index) => {

                              return (
                                    <ListGroupItem key={`gluerecord${  index}`} style={{minHeight: '100px'}}>
                                        <ListGroupItemHeading className="d-flex justify-content-between">{glueRecords[record].name}
                                            <div className="text-danger" onClick={this.deleteNameserver.bind(null, index)}>
                                                <i className="icon-trash" />
                                            </div>
                                        </ListGroupItemHeading>
                                        {glueRecords[record].ipv4 &&
                                            <ListGroupItemText className="text-warning mb-0">{glueRecords[record].ipv4}</ListGroupItemText>
                                        }
                                        {glueRecords[record].ipv6 &&
                                        <ListGroupItemText className="text-warning mb-0">{glueRecords[record].ipv6}</ListGroupItemText>
                                        }
                                    </ListGroupItem>
                              );
                            
                            })}
                        </ListGroup>
                        {checkPermission('domains.write', this.props.user.permissions) &&
                        <FormGroup row className="mt-3">
                            <Col md={2} className="pr-0">
                                <Button outline color="danger" onClick={this.toggleNewGlueRecord}>
                                    <i className="glyphicons glyphicons-plus" />
                                </Button>
                            </Col>
                        </FormGroup>
                        }

                    </Col>
                </Row>
                <Modal backdrop="static" size="lg" className="modal-warning" fade isOpen={newGlueRecord.show} toggle={this.toggleNewGlueRecord}>
                    <ModalHeader toggle={this.toggleNewGlueRecord}>New Glue Record</ModalHeader>
                    <ModalBody>
                        <SimpleLoader loading={newGlueRecord.loading}>
                        <NewGlueRecord/>
                        </SimpleLoader>
                    </ModalBody>
                </Modal>
            </div>

    );
    
  }
}

function mapStateToProps ({domain, domainCart, user }) {

  return {
    domain,
    cart: domainCart,
    user
  };

}

export default connect(mapStateToProps)(Nameservers);
