import { api_modifyVMState, api_getAccountVPS } from '../utils/VPS/VPS';
import { defaultErrorFeedback } from './feedback';

export const SET_VPS_RECORDS = 'SET_VPS_RECORDS';
export const UPDATE_VPS_RECORD = 'UPDATE_VPS_RECORD';

function setVPSRecords(vpsRecords) {

  return {
    type: SET_VPS_RECORDS,
    vpsRecords,
  };

}

export function updateVPSRecord(vpsRecord) {

  return {
    type: UPDATE_VPS_RECORD,
    vpsRecord,
  };

}

export function getVPSRecords() {

  return (dispatch) => api_getAccountVPS().then((result) => {

    const results = {};
    if (result.status === 200) {

      result.data.forEach((item) => {

        results[item.id] = item;

      });

    } else {

      dispatch(defaultErrorFeedback());

    }
    dispatch(setVPSRecords(results));

  });

}

export function modifyVMState(id, action) {

  return (dispatch) => api_modifyVMState(id, action).then((result) => {

    if (result.status === 200) {

      dispatch(updateVPSRecord(result.data));

    } else {

      dispatch(defaultErrorFeedback());

    }

  });

}
