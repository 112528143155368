import React, { FunctionComponent, JSX, useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { isLoggedIn } from '../../utils/Auth/AuthService';
import { connect } from 'react-redux'
import { TPortalUser } from '../../store/contact/types'

type TProps = {
  user: TPortalUser
}
const PrivateRoute: FunctionComponent<TProps> = ({user}): JSX.Element => {

  const location = useLocation();
  const navigate = useNavigate();

  const authLoggedIn = isLoggedIn();

  useEffect(() => {

    if (!authLoggedIn) {

      navigate('/login', { state: { from: location } });

    }

  }, [authLoggedIn]);

  return (
    <Outlet />
  );

};

function mapStateToProps({ user }) {

  return {
    user,
  };

}

export default connect(mapStateToProps)(PrivateRoute);
