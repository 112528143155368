import React from 'react';

interface IProps {
  cssClass?: string;
  cssClassTop?: string;
  title?: string;
}

const MonitoringIcon: React.FC<IProps> = ({ cssClassTop, cssClass, title }) => (
  <svg
    className={cssClassTop || 'cls-1'}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 23 23.01"
  >
    <defs>{/* <style>.cls-1{fill:#858c91;}</style> */}</defs>
    <title>{title || ''}</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Design">
        <path
          className={cssClass || 'cls-1'}
          d="M19.88,12.08h-9v-9a.65.65,0,0,0-.65-.65A10.27,10.27,0,1,0,20.54,12.74a.67.67,0,0,0-.66-.66ZM10.27,21.7A9,9,0,0,1,9.62,3.8v8.94a.65.65,0,0,0,.65.65h8.94a9,9,0,0,1-8.94,8.31Z"
        />
        <path
          className={cssClass || 'cls-1'}
          d="M23,10.23A10.26,10.26,0,0,0,12.73,0a.65.65,0,0,0-.65.65v9.62a.65.65,0,0,0,.65.65h9.62a.65.65,0,0,0,.65-.65v0Zm-9.62-.61V1.33a9,9,0,0,1,8.29,8.29Z"
        />
      </g>
    </g>
  </svg>
);

export default MonitoringIcon;
