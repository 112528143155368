import { apiRequest } from '../Api/ApiRequest';

export function api_getAccountSummary() {

  return apiRequest({
    url: '/account?include=accountManager,customers',
    method: 'get',
    headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
  }).then(
    resp => resp,
  ).catch(
    error => error,
  );

}

export function api_getSites() {

  return apiRequest({
    url: '/sites',
    method: 'get',
    headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
  }).then(
    resp => resp,
  ).catch(
    error => error,
  );

}

export function registerAccount(token, username, password) {

  return apiRequest({
    url: `${process.env.REACT_APP_ADMIN_API_URL}/register`,
    method: 'post',
    data: { token, password, username },
  }).then(
    resp => resp,
  ).catch(
    error => error,
  );

}

export function registerUser(token, password, username) {

  return apiRequest({
    url: `${process.env.REACT_APP_API_URL}/auth/register`,
    method: 'post',
    data: { token, password, username },
  }).then(
    resp => resp,
  ).catch(
    error => error,
  );

}

export function getBilling() {

  return apiRequest({
    url: '/account/billing',
    method: 'get',
    headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
  }).then(
    resp => resp,
  ).catch(
    error => error,
  );

}

export function getBill(key, fileName, accountNumber) {

  return apiRequest({
    url: `/account/billing/download?key=${key}&fileName=${fileName}&accountNumber=${accountNumber}`,
    method: 'get',
    responseType: 'blob',
    headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
  }).then(
    resp => resp,
  ).catch(
    error => error,
  );

}

export function getInvoice(invoiceId) {

  return apiRequest({
    url: `/account/billing/download?invoiceId=${invoiceId}`,
    method: 'get',
    responseType: 'blob',
    headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
  }).then(
    resp => resp,
  ).catch(
    error => error,
  );

}

export function getProducts() {

  return apiRequest({
    url: '/account/products',
    method: 'get',
    headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
  }).then(
    resp => resp,
  ).catch(
    error => error,
  );

}

export function getAccountStatement() {

  return apiRequest({
    url: '/account/statement',
    method: 'get',
    headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
  }).then(
    resp => resp,
  ).catch(
    error => error,
  );

}
