import {
  ORDERS_SET_ORDERS, ORDERS_SET_SEARCH_STRING, ORDERS_TOGGLE_LOADING, ORDERS_RESET_STORE, IOrdersResetStore,
} from './types';
import { api_getOrderList } from '../../utils/Orders/Orders';
import { defaultErrorFeedback, errorFeedback } from '../../actions/feedback';
import { setSystemError } from '../system/actions';

function setAccountOrders(orders) {

  return {
    type: ORDERS_SET_ORDERS,
    orders,
  };

}
export const resetOrdersStore = (): IOrdersResetStore => ({ type: ORDERS_RESET_STORE });
export function toggleOrdersLoading() {

  return {
    type: ORDERS_TOGGLE_LOADING,
  };

}
export function setOrdersSearchString(search) {

  return {
    type: ORDERS_SET_SEARCH_STRING,
    search,
  };

}
export const getAccountOrders = () => (dispatch) => api_getOrderList().then(
  (result) => {

    if (result.status === 200) {

      dispatch(setAccountOrders(result.data));
    
    } else if (result.data.error && result.data.error.id) {

      dispatch(setSystemError(result.data.error.id));
    
    } else {

      dispatch(defaultErrorFeedback());
    
    }
  
  },
);
