import React, { FunctionComponent } from 'react';
import { Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { TService } from '../../../store/service/types';
import { IUserState } from '../../../store/user/types';
import { checkPermission } from '../../../utils/Auth/AuthService';

type TOwnProps = {
  services: TService[];
  user: IUserState;
};
const LinkedServices: FunctionComponent<TOwnProps> = (props) => {

  const {
    services,
    user,
  } = props;
  return (
    <div
      className="shadow-sm pt-3 bg-white d-flex flex-column monitoring-widget w-100 h-100"
      style={{ borderRadius: '10px' }}
    >
      <Row className="mx-0  mb-3 px-2">
        {checkPermission('services.read', props.user.permissions)
                    && (
                    <Col className="d-flex flex-column pb-2">
                      <h5 className="text-light text-uppercase font-xl text-left mb-0 ">
                        Linked Services
                      </h5>
                      {services.map((service, key) => (
                        <div className="pt-0" key={`linked-service${key}`}>
                          <div
                            className="font-weight-bold text-dark pt-0 text-left"
                          >
                            {service.url ? (<Link to={service.url}>{service.name}</Link>) : service.name}
                            <span
                              className="text-light monitoring-widget__latency__ms"
                            />
                          </div>
                        </div>
                      ))}
                    </Col>
                    )}
      </Row>
    </div>
  );

};
export default LinkedServices;
