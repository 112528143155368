/* eslint-disable */
import React from 'react';
import { PropTypes } from 'prop-types';
import { Card, CardHeader, CardBody, CardText, Button, FormGroup, Label, DropdownItem, ButtonDropdown, DropdownToggle, DropdownMenu, Modal, ModalHeader, ModalBody, ModalFooter, Col, Collapse, Row, Input, Alert } from 'reactstrap';
import { AvForm, AvField, AvInput } from 'availity-reactstrap-validation';
import { DateTimePicker } from 'react-widgets';
import Moment from 'moment';
import momentLocalizer from 'react-widgets-moment-localizer';
import { createNewCase } from './../../store/case/actions'
import Immutable from 'immutable';
import { fromJS } from "immutable";
import LaddaButton, { ZOOM_OUT } from "react-ladda";
import Section from "../../components/Section";
momentLocalizer(Moment);
const ChangeItemFields = {
    newExtension: [{ id: 'existingUsername', name: 'Existing User Name' }, { id: 'newUsername', name: 'New User Name' }, { id: 'phoneUserFull', name: 'Phone User Full Name' }, { id: 'extensionNo', name: 'Extension No.' }, { id: 'handsetModel', name: 'Handset Model' }, { id: 'handsetMac', name: 'Handset MAC Address' }, { id: 'department', name: 'Department' }, { id: 'voiceMail', name: 'Voicemail Request?' }, { id: 'voicemailForw', name: 'Voicemail forwarding email address' }],
    pickupGroup: [{ id: 'existingUsername', name: 'Existing User Name' }, { id: 'phoneUserFull', name: 'Phone User Full Name' }, { id: 'extensionNo', name: 'Extension No.' }, { id: 'currentPickupGroup', name: 'Current Pick Up Group' }, { id: 'newPickupGroup', name: 'New Pickup Group' }],
    ringGroup: [{ id: 'existingUsername', name: 'Existing User Name' }, { id: 'phoneUserFull', name: 'Phone User Full Name' }, { id: 'extensionNo', name: 'Extension No.' }, { id: 'newRingGroup1', name: 'New Ring Group(1)' }, { id: 'newRingGroup2', name: 'New Ring Group(2)' }, { id: 'newRingGroup3', name: 'New Ring Group(3)' }],
    pinReset: [{ id: 'existingUsername', name: 'Existing User Name' }, { id: 'phoneUserFull', name: 'Phone User Full Name' }, { id: 'extensionNo', name: 'Extension No.' }],
    other: [{ id: 'existingUsername', name: 'Existing User Name' }, { id: 'phoneUserFull', name: 'Phone User Full Name' }, { id: 'extensionNo', name: 'Extension No.' }, { id: 'details', name: 'Details' }, { id: 'removeVoicemail', name: 'Remove Voicemail' }],
};
const ChangeItemLabels = {
    newExtension: 'New Extension',
    pickupGroup: 'User assigned Pickup Group',
    ringGroup: 'User assigned Ring Group',
    pinReset: 'PIN Reset',
    other: 'Other (incl. User Deletions)',
};
class VoiceChange extends React.Component {
    static contextTypes = {
        router: PropTypes.shape({
            navigate: PropTypes.func.isRequired,
            staticContext: PropTypes.object
        }).isRequired
    };
    constructor(props) {
        super(props);
        this.state = {
            formData: {},
            processing: false,
            error: false,
            noItemsOnSubmit: false,
            changeItems: [],
            tmpItem: {},
            changeItemErrors: [],
        };
        this.toggle = this.toggle.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.getFields = this.getFields.bind(this);
        this.handleInput = this.handleInput.bind(this);
        this.handleModalInput = this.handleModalInput.bind(this);
        this.handleEditItem = this.handleEditItem.bind(this);
        this.addItem = this.addItem.bind(this);
        this.deleteItem = this.deleteItem.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    toggle() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }
    toggleModal() {
        this.setState({
            modal: !this.state.modal,
            tmpItem: {}
        });
    }
    getFields(type) {
        return ChangeItemFields[type] || [];
    }
    addItem(event) {
        event.persist();
        let changeItems = this.state.changeItems;
        let newItem = Immutable.Map(this.state.tmpItem);
        changeItems.push(newItem.set('type', event.target.id));
        this.setState({
            changeItems: changeItems,
            noItemsOnSubmit: false
        });
    }
    deleteItem(index) {
        let changeItems = this.state.changeItems;
        changeItems.splice(index, 1);
        this.setState({
            changeItems: changeItems,
        });
    }
    handleInput(event, value) {
        event.persist();
        this.setState((prevState) => {
            return {
                formData: Object.assign(prevState.formData, {
                    [event.target.id]: {
                        question: event.target.parentNode.previousSibling.textContent,
                        value: value
                    }
                })
            }
        });
    }
    handleModalInput(event) {
        event.persist();
        this.setState((prevState) => {
            return {
                tmpItem: Object.assign(prevState.tmpItem, {
                    [event.target.id]: {
                        question: event.target.parentNode.previousSibling.textContent,
                        value: event.target.value
                    }
                })
            }
        });
    }
    handleEditItem(index, field, question, event) {
        event.persist();
        let changeItems = this.state.changeItems;
        let changeItem = this.state.changeItems[index];
        changeItems[index] = changeItem.set(field, { question: question, value: event.target.value });
        this.setState({
            changeItems: changeItems
        });
    }
    handleSubmit() {
        const { changeItems } = this.state;
        this.setState({ changeItemErrors: [] })
        if (this.props.partnerCustomer === '' && this.props.isResalePartner) {
            this.props.setCustomerSelectError()
            return false
        }
        if (this.state.changeItems.length === 0) {
            this.setState({ noItemsOnSubmit: true });
            window.scrollTo(0, 0);
            return false;
        }
        if (changeItems.length > 0) {
            const changeItemErrors = changeItems.map(changeItem => {
                const itemType = changeItem.get('type');
                const val = JSON.stringify(changeItems)
                const expectedFields = [...ChangeItemFields[itemType]]
                const emptyFields = expectedFields.filter((expectedField, index) => {
                    const populated = changeItems.filter(entry => {
                        if (entry.get(expectedField.id)) {
                            return entry.get(expectedField.id)
                        }
                        return false
                    })
                    return populated.length === 0
                })
                if (emptyFields.length > 0) {
                    this.setState({ changeItemErrors: [...this.state.changeItemErrors, [...emptyFields]] })
                }
            })
            if (this.state.changeItemErrors.length > 0) {
                window.scrollTo(0, 0);
                return false
            }
        }
        this.setState({ processing: true });
        let title = this.state.formData.caseTitle.value;
        let theHTML = '<h2>Voice Change</h2><br>';
        theHTML += '<h6>Title</h6><p>' + title + '</p>';
        delete this.state.formData.caseTitle;
        for (let prop in this.state.formData) {
            if (!this.state.formData.hasOwnProperty(prop)) continue;
            theHTML += '<h6>' + this.state.formData[prop].question + '</h6><p>' + this.state.formData[prop].value + '</p>';
        }
        if (this.props.implTimeDisabled === false) {
            theHTML += '<h6>Preferred Implementation Time:</h6><p>' + this.props.implTime.toString() + '</p>';
        }
        else {
            theHTML += '<h6>Preferred Implementation Time:</h6><p>ASAP</p>';
        }
        this.state.changeItems.forEach((item, index) => {
            theHTML += '<h4>' + ChangeItemLabels[item.get('type')] + ' - ' + (index + 1) + '</h4>';
            for (let field of ChangeItemFields[item.get('type')]) {
                if (item.get(field.id)) {
                    theHTML += '<h6>' + item.get(field.id).question + '</h6><p>' + item.get(field.id).value + '</p>';
                }
            }
        });
        this.props.dispatch(createNewCase({
            title: title,
            priority: this.state.formData.casePriority.value,
            category: this.state.formData.caseCategory.value === 'Non Service Affecting' ? 'non_service_affecting' : 'service_affecting',
            formData: theHTML
        }))
            .then((result) => {
                if (result.caseId) {
                    this.props.navigate('/cases')
                }
                else {
                    this.setState({ error: true });
                    window.scrollTo(0, 0);
                }
            });
    }
    render() {
        return (
            <Row>
                <Col md={6}>
                    <Section title={"Voice Change"}>
                        <Row className={"mx-0"}>
                            <Col className="p-4 bg-white rounded">
                                <AvForm onValidSubmit={this.handleSubmit} onInvalidSubmit={this.props.onInvalidSubmit}>
                                    <FormGroup>
                                        <Label for="caseTitle">Title</Label>
                                        <AvField type="text" name="caseTitle" id="caseTitle" placeholder="Title of the case" required onChange={this.handleInput} validate={{maxLength: {value: 200}}}/>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="caseCategory">Category</Label>
                                        <AvField type="select" name="caseCategory" id="caseCategory" required onChange={this.handleInput}>
                                            <option value="">-- Select</option>
                                            <option value="Service Affecting">Service Affecting</option>
                                            <option value="Non Service Affecting">Non Service Affecting</option>
                                        </AvField>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="casePriority">Priority</Label>
                                        <AvField type="select" name="casePriority" id="casePriority" required onChange={this.handleInput}>
                                            <option value="">-- Select</option>
                                            <option value="low">Low</option>
                                            <option value="medium">Medium</option>
                                            <option value="high">High</option>
                                        </AvField>
                                    </FormGroup>
                                    <CardText>Preferred Implementation</CardText>
                                    <FormGroup>
                                        <label className="form-check text-dark homeage-to-the-square-checkbox">
                                            <Input
                                                className={"form-check-input"}
                                                type="checkbox" id={"asap"}
                                                onChange={this.props.inputOnChange} />
                                            <span className="checkmark"></span>
                                            <label className="form-check-label" htmlFor={"asap"}>ASAP</label>
                                        </label>
                                    </FormGroup>
                                    <CardText>or please specify</CardText>
                                    <FormGroup>
                                        <DateTimePicker disabled={this.props.implTimeDisabled} defaultValue={this.props.implTime} min={new Date()} onChange={this.props.datetimeOnChange} />
                                    </FormGroup>
                                    <FormGroup>
                                        <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                                            <DropdownToggle caret color="primary">
                                                Add Change Item
                                        </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem id="newExtension" onClick={this.addItem}>New Extension</DropdownItem>
                                                <DropdownItem id="pickupGroup" onClick={this.addItem}>User assigned Pickup Group</DropdownItem>
                                                <DropdownItem id="ringGroup" onClick={this.addItem}>User assigned Ring Group</DropdownItem>
                                                <DropdownItem id="pinReset" onClick={this.addItem}>PIN Reset</DropdownItem>
                                                <DropdownItem id="other" onClick={this.addItem}>Other (incl. User Deletions)</DropdownItem>
                                            </DropdownMenu>
                                        </ButtonDropdown>
                                    </FormGroup>
                                    {this.state.modal && <ChangeItemModal
                                        isOpen={this.state.modal}
                                        toggle={this.toggleModal}
                                        title={ChangeItemLabels[this.state.changeItemType]}
                                        fields={this.getFields(this.state.changeItemType)}
                                        type={this.state.changeItemType}
                                        handleInput={this.handleModalInput}
                                        addItem={this.addItem}
                                    />}
                                    <div className="clearfix" style={{ marginTop: 10 + 'px', marginBottom: 20 + 'px' }}>
                                        {/*<Button type="submit" color="primary" className="float-right" style={{minWidth: 113+'px', minHeight: 35+'px'}} disabled={this.state.processing}>{this.state.processing ? <div style={{marginTop: 2+'px'}}>
                                        <Loader color="#ffffff" size="8px" margin="0px"/></div> : "Create Case"}
                                        </Button>*/}
                                        <LaddaButton
                                            style={{ minWidth: 113 + 'px', minHeight: 35 + 'px' }}
                                            className="btn btn-primary btn-ladda px-4 float-right"
                                            disabled={this.state.processing}
                                            loading={this.state.processing}
                                            data-color="blue"
                                            data-style={ZOOM_OUT}
                                        >
                                            Create Case
                                    </LaddaButton>
                                    </div>
                                </AvForm>
                            </Col>
                        </Row>
                    </Section>
                </Col>
                <Col md="6">
                    {this.state.noItemsOnSubmit &&
                        <Alert color="danger" className={"col-md-12"}>
                            You must have at least one change item!
                    </Alert>
                    }
                    {this.state.changeItems.length > 0 &&
                        this.state.changeItems.map((data, index) => {
                            return (
                                <div key={index} >
                                    {this.state.changeItemErrors[index] && this.state.changeItemErrors[index].length > 0 &&
                                        <Alert color="danger" className={"col-md-12"}>
                                            <p>The following are required fields:</p>
                                            {this.state.changeItemErrors[index] &&
                                                this.state.changeItemErrors[index].map(error => <p>{error.name}</p>)}
                                        </Alert>
                                    }
                                    <ChangeItem
                                        index={index}
                                        deleteItem={this.deleteItem}
                                        data={data}
                                        handleEditItem={this.handleEditItem}
                                    />
                                </div>
                            )
                        })
                    }
                </Col>
            </Row>
        )
    }
}
const ChangeItemModal = (props) => {
    return (
        <div>
            <Modal isOpen={props.isOpen} toggle={props.toggle}>
                <ModalHeader toggle={props.toggle}>{props.title}</ModalHeader>
                <ModalBody>
                    {props.fields.map((field, index) => {
                        return (
                            <FormGroup key={index} row>
                                <Label for={field.id} sm={4}>{field.name}</Label>
                                <Col sm={8}>
                                    <Input type="text" name={field.id} id={field.id} onChange={props.handleInput} />
                                </Col>
                            </FormGroup>
                        )
                    })}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={props.addItem}>Add</Button>{' '}
                    <Button color="secondary" onClick={props.toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    )
};
class ChangeItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: true
        };
        this.handleCollapse = this.handleCollapse.bind(this);
    }
    handleCollapse() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }
    shouldComponentUpdate(nextProps, nextState) {
        return (nextProps.data !== this.props.data || this.state.collapsed !== nextState.collapsed);
    }
    render() {
        return (
            <Card className="card-accent-primary card">
                <CardHeader>
                    <div>{ChangeItemLabels[this.props.data.get('type')]}</div>
                    <div className="card-actions clearfix">
                        <Button color="warning" className={"text-dark"} onClick={this.handleCollapse}>{!this.state.collapsed ? <i className="icon-arrow-down"></i> : <i className="icon-arrow-up"></i>}</Button>
                    </div>
                    <div className="card-actions clearfix mr-5">
                        <Button color="warning" className={"text-dark"} onClick={this.props.deleteItem}><i className="icon-trash"></i></Button>
                    </div>
                </CardHeader>
                <Collapse isOpen={this.state.collapsed}>
                    <CardBody>
                        {ChangeItemFields[this.props.data.get('type')].map((itemField) => {
                            if (itemField.id !== 'type') {
                                return (
                                    <FormGroup key={itemField.id + this.props.index} row>
                                        <Label for={itemField.id + this.props.index} sm={4}>{itemField.name}</Label>
                                        <Col sm={8}>
                                            <Input type="text" name={itemField.id + this.props.index} id={itemField.id + this.props.index} value={this.props.data.get(itemField.id) ? this.props.data.get(itemField.id).value : ''} onChange={this.props.handleEditItem.bind(null, this.props.index, itemField.id, itemField.name)} />
                                        </Col>
                                    </FormGroup>
                                )
                            }
                            return '';
                        })}
                        <div className="clearfix" style={{ marginTop: 10 + 'px', marginBottom: 20 + 'px' }}>
                            <Button type="button" color="primary" className="float-right" style={{ minWidth: 113 + 'px', minHeight: 35 + 'px' }} onClick={this.handleCollapse}>Save</Button>
                        </div>
                    </CardBody>
                </Collapse>
            </Card>
        )
    }
}
export default VoiceChange;
