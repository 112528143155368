import { apiRequest } from '../Api/ApiRequest';
import { TPortalSetting } from '../../store/user/types';

// eslint-disable-next-line @typescript-eslint/naming-convention
export function api_updatePortalSetting(settings: TPortalSetting[]) {

  return apiRequest({
    url: '/settings',
    method: 'put',
    headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
    data: { settings },
  }).then(
    (resp) => resp,
  ).catch(
    (error) => error,
  );

}

export function getSettingValue(settings, name) {

  return settings.filter((setting) => setting.name === name)[0].value === '1';

}
