import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { init as initApm } from '@elastic/apm-rum';
import { createRoot } from 'react-dom/client';
import createStore from './configureStore';
import 'animate.css';
import './scss/style.scss';
import App from './App';

const { store, persistor } = createStore();
const apm = initApm({
  serviceName: 'hso-customer-portal',
  serverUrl: 'https://apm.hso-group.net',
  serviceVersion: process.env.REACT_APP_GIT_SHA,
  environment: process.env.REACT_APP_ENV,
  distributedTracingOrigins: [
    process.env.REACT_APP_API_URL || '',
    process.env.REACT_APP_NETAPI_URL || '',
    process.env.REACT_APP_SOCKETIO || '',
    process.env.REACT_APP_COMPARE_API_URL || '',
  ],
});
const container = document.getElementById('root') as NonNullable<HTMLElement>;
const root = createRoot(container);
root.render((
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>
));

export { store, apm };
