import React from 'react';

interface IProps {
  cssClass?: string;
  cssClassTop?: string;
  title?: string;
}

const Mail: React.FC<IProps> = ({ cssClassTop, cssClass, title }) => (
  <svg
    className={cssClassTop || 'cls-1 blue-svg'}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22 16.74"
  >
    <defs>{/* <style>.cls-1{fill:#6ba4b8;}</style> */}</defs>
    <title>{title || ''}</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Design">
        <path
          className={cssClass || 'cls-1 blue-svg'}
          d="M19.31,0H2.69A2.7,2.7,0,0,0,0,2.69V14.05a2.69,2.69,0,0,0,2.69,2.69H19.31A2.69,2.69,0,0,0,22,14.05V2.69A2.69,2.69,0,0,0,19.31,0Zm1.47,14.05a1.46,1.46,0,0,1-1.47,1.46H2.69a1.46,1.46,0,0,1-1.46-1.46V2.69A1.46,1.46,0,0,1,2.69,1.23H19.31a1.46,1.46,0,0,1,1.46,1.46V14.05Z"
        />
        <path
          className={cssClass || 'cls-1 blue-svg'}
          d="M13.87,8.23l5.39-4.82a.62.62,0,0,0-.83-.92L11,9.15,9.56,7.86s0,0,0,0a.39.39,0,0,0-.1-.08L3.56,2.49a.61.61,0,0,0-.87,0,.63.63,0,0,0,.05.87L8.19,8.27,2.77,13.35a.62.62,0,0,0,0,.87.65.65,0,0,0,.45.19.61.61,0,0,0,.42-.16L9.11,9.1l1.5,1.33a.57.57,0,0,0,.41.16.66.66,0,0,0,.41-.16L13,9.05l5.47,5.2a.58.58,0,0,0,.42.17.6.6,0,0,0,.45-.19.62.62,0,0,0,0-.87Z"
        />
      </g>
    </g>
  </svg>
);

export default Mail;
