import React, {
  useEffect, useState, Fragment, useRef, FunctionComponent,
} from 'react';
import { connect } from 'react-redux';
import 'rc-steps/assets/index.css';
import 'rc-steps/assets/iconfont.css';
import {
  Badge, Button,
  Card,
  CardBody, CardText,
  CardTitle,
  Col, Form, FormGroup, Input, Label,
  ListGroup,
  ListGroupItem,
  Row, UncontrolledTooltip,
} from 'reactstrap';
// import DomainContact from "./../../views/Services/Domains/DomainContact";
import numeral from 'numeral';
import { Link } from 'react-router-dom';
import Steps from 'rc-steps';
import DomainContact from '../Contact/DomainContact';
import { getRegistrar, getTerms, validateContact } from '../../helpers/domains/domains';
import { removeFromDomainCart, resetDomainCart, updateDomainCart } from '../../actions/domainCart';
import {
  initDomainCheckoutContact,
  setDomainCheckoutCompletedItems,
  setDomainCheckoutErrors, setDomainCheckoutFailedItems, setDomainCheckoutPurchaseToken,
  setDomainCheckoutStep,
  setDomainCheckoutSteps,
  toggleDomainCheckoutTerms,
  updateDomainCheckoutContact, updateDomainCheckoutOrderNumber,
  updateDomainCheckoutStatus,
  updateDomainCheckoutStep,
} from '../../actions/domainCheckout';
import { placeOrder } from '../../utils/Services/ServiceOrders';
import { getPurchaseToken } from '../../utils/Orders/Orders';
import { IDomainCheckout, IDomainContact } from '../../store/domainCheckout/types';
import { IAccountState } from '../../store/account/types';
import SimpleLoader from '../../components/SimpleLoader';
import TitleYellowDashCentered from '../../components/TitleYellowDashCentered';

type TOwnProps = {
  domainCheckout: IDomainCheckout;
  dispatch: any;
  user: IDomainContact;
  account: IAccountState;
  cart: object;
};
const DomainCheckout: FunctionComponent<TOwnProps> = (props) => {

  useEffect(() => {

    if (!props.domainCheckout.contact.firstName) {

      props.dispatch(initDomainCheckoutContact(props.user, props.account));
    
    }
    if (props.domainCheckout.steps.length === 0) {

      props.dispatch(setDomainCheckoutSteps(getSteps()));
    
    }
  
  });

  const getSteps = () => {

    const steps = [
      { name: 'Your cart', icon: 'icon-basket' },
    ];
    if (withContactStep()) {

      steps.push({ name: 'Contact details', icon: 'icon-user' });
    
    }
    steps.push(
      { name: 'Terms & Conditions', icon: 'icon-doc' },
      { name: 'Payment', icon: 'icon-credit-card' },
    );
    return steps;
  
  };
  const removeFromCart = (id) => {

    props.dispatch(removeFromDomainCart(id));
  
  };
  const withContactStep = () => {

    const filtered = Object.keys(props.cart).filter((key) => (props.cart[key].action === 'transfer' && getRegistrar(props.cart[key].name) === 'opensrs') || props.cart[key].action === 'register');
    return filtered.length > 0;
  
  };
  const getActionColor = (item) => {

    if (item.action === 'register') {

      return 'primary';
    
    }
    if (item.action === 'transfer') {

      return 'warning';
    
    }

    return 'success';
  
  };
  const getTotal = () => Object.keys(props.cart).reduce((total, key) => {

    const item = props.cart[key];
    // return parseFloat(item.years * this.getDefaultPrice(item)) + total
    return (item.years * getDefaultPrice(item)) + total;
  
  }, 0);
  const getDefaultPrice = (item) => item.tld.price[item.action];
  const getNextStep = () => {

    if (!validateStep()) {

      return;
    
    }

    props.dispatch(setDomainCheckoutErrors({}));
    props.dispatch(updateDomainCheckoutStep(props.domainCheckout.step, 'stepStatus', 'finish'));

    if (props.domainCheckout.steps.length === props.domainCheckout.step + 2) {

      props.dispatch(setDomainCheckoutStep(props.domainCheckout.step + 1, 'Place Order'));
    
    } else if (props.domainCheckout.steps.length === props.domainCheckout.step + 1) {

      props.dispatch(updateDomainCheckoutStep(props.domainCheckout.step, 'stepStatus', 'process'));
      processPayment();
    
    } else {

      props.dispatch(setDomainCheckoutStep(props.domainCheckout.step + 1));
    
    }
  
  };
  const processPayment = () => {

    props.dispatch(updateDomainCheckoutStatus('in progress'));
    getPurchaseToken(getTotal()).then((result) => {

      if (result.status === 200 && result.data.success) {

        props.dispatch(setDomainCheckoutPurchaseToken(result.data.token));
        props.dispatch(updateDomainCheckoutStatus('payment processed'));
        completeOrder(result.data.token);
      
      }
      if (result.status === 200 && !result.data.success) {

        props.dispatch(updateDomainCheckoutStatus('credit check failed'));
      
      }
    
    });
  
  };
  const completeOrder = (token) => {

    props.dispatch(updateDomainCheckoutStatus('in progress'));
    placeOrder('domain', {
      items: Object.keys(props.cart).map((item) => {

        const domain = { ...props.cart[item] };
        delete domain.tld;
        return domain;
      
      }),
      contact: props.domainCheckout.contact,
      total: getTotal(),
    }, token).then((result) => {

      if (result.status === 200) {

        if (result.data.failedItems.length || !result.data.items.length) {

          props.dispatch(updateDomainCheckoutStatus('completed with errors'));
        
        } else {

          props.dispatch(updateDomainCheckoutStatus('completed'));
        
        }
        props.dispatch(setDomainCheckoutFailedItems(result.data.failedItems));
        props.dispatch(setDomainCheckoutCompletedItems(result.data.items));
        props.dispatch(updateDomainCheckoutStep(props.domainCheckout.step, 'stepStatus', 'finish'));
        props.dispatch(updateDomainCheckoutOrderNumber(result.data.referenceNumber));
        props.dispatch(resetDomainCart());
      
      } else {

        props.dispatch(updateDomainCheckoutStatus('failed'));
      
      }
    
    });
  
  };
  const setStep = (step) => {

    props.dispatch(setDomainCheckoutStep(step));
  
  };
  const validateStep = () => {

    const steps = props.domainCheckout.steps[props.domainCheckout.step];
    if (steps.name === 'Contact details') {

      const errors = validateContact(props.domainCheckout.contact);
      if (Object.keys(errors).length) {

        props.dispatch(updateDomainCheckoutStep(props.domainCheckout.step, 'stepStatus', 'error'));
        props.dispatch(setDomainCheckoutErrors(errors));
        return false;
      
      }
      return true;
    
    }
    if (steps.name === 'Terms & Conditions' && !props.domainCheckout.termsAccepted) {

      props.dispatch(updateDomainCheckoutStep(props.domainCheckout.step, 'stepStatus', 'error'));
      props.dispatch(setDomainCheckoutErrors({ termsAccepted: true }));
      return false;
    
    }
    if (steps.name !== 'Payment') {

      props.dispatch(updateDomainCheckoutStep(props.domainCheckout.step, 'stepStatus', 'process'));
    
    }
    return true;
  
  };
  const handleContactInput = (event: React.ChangeEvent<HTMLInputElement>) => {

    props.dispatch(updateDomainCheckoutContact(event.target.id, event.target.value));
  
  };
  const handleCountrySelect = (value: string) => {

    props.dispatch(updateDomainCheckoutContact('country', value));
  
  };
  const handleCountySelect = (value: string) => {

    props.dispatch(updateDomainCheckoutContact('county', value));
  
  };
  const handleCartInput = (id, event: React.ChangeEvent<HTMLInputElement>) => {

    const value = event.target.name === 'autoRenew' ? !props.cart[id].autoRenew : event.target.value;
    props.dispatch(updateDomainCart(id, event.target.name, value));
  
  };
  const toggleAccept = () => {

    props.dispatch(toggleDomainCheckoutTerms());
  
  };
  const { cart } = props;
  const { steps } = props.domainCheckout;
  const hasNominet = Object.keys(cart).filter((name) => getRegistrar(name) === 'nominet').length > 0;
  const { contact } = props.domainCheckout;
  return (
    <div className="animated fadeIn">
      <Row>
        <Col>
          <Card>
            <CardBody className="d-flex flex-column justify-content-center align-items-center ">
              <CardTitle>Checkout</CardTitle>
              <CardText className="text-secondary">All our prices are exclusive of VAT.</CardText>
              {steps.length
                                && (
                                <Steps
                                  status={steps[props.domainCheckout.step].status}
                                  current={props.domainCheckout.step}
                                  labelPlacement="vertical"
                                  className={'col'
                                    + ' col-md-6 col-sm-12 mb-3 mt-3 mt-sm-5'}
                                >
                                  {steps.map((step, index) => {

                                    const processed = step.stepStatus === 'finish' && !props.domainCheckout.status;
                                    return (
                                      <Steps.Step
                                        key={`step${index}`}
                                        title={step.name}
                                        onClick={processed ? setStep.bind(null, index) : null}
                                        style={{ cursor: processed ? 'pointer' : 'not-allowed' }}
                                        status={steps[index].stepStatus}
                                      />
                                    );
                                  
                                  })}
                                </Steps>
                                )}
              <Col>
                <Card className="border-0">
                  <CardBody>
                    {props.domainCheckout.step === 0
                                            && (
                                            <ListGroup>
                                              {Object.keys(cart).map((key, domainIndex) => {

                                                const domain = cart[key];
                                                const price = getDefaultPrice(domain);
                                                return (
                                                  <ListGroupItem
                                                    key={domainIndex}
                                                    className="border-left-0 border-right-0"
                                                  >
                                                    <div className="clearfix mb-3 mt-2">
                                                      <CardTitle
                                                        className="float-left"
                                                      >
                                                        {domain.name}
                                                      </CardTitle>
                                                      <CardText
                                                        className="float-right"
                                                      >
                                                        £
                                                        {price}
                                                        /year
                                                      </CardText>
                                                    </div>
                                                    <Row>
                                                      <Col className="clearfix">
                                                        <Badge
                                                          color={getActionColor(domain)}
                                                          className="float-left p-1"
                                                        >
                                                          {domain.action}
                                                        </Badge>
                                                        <FormGroup
                                                          className="float-right form-inline"
                                                        >
                                                          <Label className="mr-3">
                                                            Contract
                                                            term:
                                                            {' '}
                                                          </Label>
                                                          <Input
                                                            type="select"
                                                            name="years"
                                                            value={domain.years ? domain.years : ''}
                                                            onChange={handleCartInput.bind(null, domain.id)}
                                                          >
                                                            {getTerms(domain, domain.tld).map((item, index) => (
                                                              <option
                                                                key={`option${domainIndex}${index}`}
                                                                value={item}
                                                              >
                                                                {item + (item === 1 ? ' year' : ' years')}
                                                              </option>
                                                            ))}
                                                          </Input>
                                                        </FormGroup>
                                                      </Col>
                                                    </Row>
                                                    <Row>
                                                      <Col className="clearfix">
                                                        <FormGroup check className="float-right">
                                                          <i
                                                            id={`tooltip${domainIndex}`}
                                                            className="icon-info mr-3"
                                                          />
                                                          <UncontrolledTooltip
                                                            placement="bottom"
                                                            target={`tooltip${domainIndex}`}
                                                          >
                                                            we need to say something about auto
                                                            renew here
                                                          </UncontrolledTooltip>
                                                          <Label className="mr-5">Auto renew:</Label>
                                                          <Label className="switch switch-text switch-primary-outline-alt">
                                                            <Input
                                                              type="checkbox"
                                                              name="autoRenew"
                                                              className="switch-input"
                                                              checked={domain.autoRenew}
                                                              onChange={handleCartInput.bind(null, domain.id)}
                                                              value={domain.autoRenew}
                                                            />
                                                            <span
                                                              className="switch-label"
                                                              data-on="On"
                                                              data-off="Off"
                                                            />
                                                            <span className="switch-handle" />
                                                          </Label>
                                                        </FormGroup>
                                                      </Col>
                                                    </Row>
                                                    <Row className="mt-3">
                                                      <Col className="clearfix">
                                                        <div className="text-danger float-left h3">
                                                          <i
                                                            id={`remove${domainIndex}`}
                                                            className="icon-trash cursor-pointer"
                                                            onClick={removeFromCart.bind(null, domain.id)}
                                                          />
                                                          <UncontrolledTooltip
                                                            placement="bottom"
                                                            target={`remove${domainIndex}`}
                                                          >
                                                            remove
                                                          </UncontrolledTooltip>
                                                        </div>
                                                        <CardTitle
                                                          className="float-right"
                                                        >
                                                          £
                                                          {numeral(domain.years * price).format('0,0.00')}
                                                        </CardTitle>
                                                      </Col>
                                                    </Row>
                                                  </ListGroupItem>
                                                );
                                              
                                              })}
                                            </ListGroup>
                                            )}
                    {steps.length && steps[props.domainCheckout.step].name === 'Contact details'
                                            && (
                                            <ListGroup>
                                              <ListGroupItem className="border-top-0 border-left-0 border-right-0">
                                                <DomainContact
                                                  contact={contact}
                                                  contactEdited={contact}
                                                  handleInput={handleContactInput}
                                                  handleCountrySelect={handleCountrySelect}
                                                  handleCountySelect={handleCountySelect}
                                                  errors={props.domainCheckout.errors}
                                                />
                                              </ListGroupItem>
                                            </ListGroup>
                                            )}
                    {steps.length && steps[props.domainCheckout.step].name === 'Terms & Conditions'
                                            && (
                                            <ListGroup className="mt-3">
                                              <ListGroupItem className="text-center border-top-0 border-left-0 border-right-0 mb-5">
                                                <Input onChange={toggleAccept} type="checkbox" checked={props.domainCheckout.termsAccepted} />
                                                By ticking this box you agree to hSo's
                                                <a className="ml-1" href="https://www.hso.co.uk/p/legal/terms-and-conditions.pdf" rel="noreferrer" target="_blank">General Terms & Conditions</a>
                                                {hasNominet && (
                                                <div>
                                                  {' '}
                                                  and
                                                  <a className="ml-1" href="https://www.hso.co.uk/legal#nominet.terms.and.conditions" rel="noreferrer" target="_blank">Nominet Terms & Conditions</a>
                                                </div>
                                                )}
                                                <p className="text-danger">
                                                  You must accept our terms before proceeding.
                                                </p>

                                              </ListGroupItem>
                                            </ListGroup>
                                            )}
                    {props.domainCheckout.status === 'credit check failed'
                                            && (
                                            <ListGroup>
                                              <ListGroupItem color="danger" className="border-top-0 border-left-0 border-right-0 text-center">
                                                <CardTitle className="mt-2">Payment failed!</CardTitle>
                                                <CardText>Unfortunately you do not have enough credit on your account to complete this purchase.</CardText>
                                                <CardText>Please contact your account manager for further information.</CardText>
                                              </ListGroupItem>
                                            </ListGroup>
                                            )}
                    {
                                            steps.length
                                            && steps[props.domainCheckout.step].name === 'Payment'
                                            && props.domainCheckout.status !== 'credit check failed'
                                            && props.domainCheckout.status !== 'completed'
                                            && props.domainCheckout.status !== 'completed with errors'
                                            && props.domainCheckout.status !== 'in progress'
                                            && (
                                            <Row>
                                              <Col className="d-flex justify-content-center">
                                                <div>
                                                  Thank you for your domain order. Once the registration is completed, the relevant charges will be added to your next hSo invoice.
                                                </div>
                                              </Col>
                                            </Row>
                                            )
                                        }
                    {props.domainCheckout.status && props.domainCheckout.status === 'in progress'
                                            && (
                                            <ListGroup>
                                              <ListGroupItem className="border-top-0 border-bottom-0 border-left-0 border-right-0">
                                                <CardTitle className="text-center">Order in progress </CardTitle>
                                                <CardText className="text-center">please do not navigate away from this page</CardText>
                                                <SimpleLoader loading />
                                              </ListGroupItem>
                                            </ListGroup>
                                            )}
                    {props.domainCheckout.status === 'completed'
                                            && (
                                            <OrderCompleted
                                              orderNumber={props.domainCheckout.orderNumber}
                                              items={props.domainCheckout.completedItems}
                                            />
                                            )}
                    {props.domainCheckout.status === 'completed with errors'
                                            && (
                                            <OrderFailed
                                              orderNumber={props.domainCheckout.orderNumber}
                                              items={props.domainCheckout.completedItems}
                                              failedItems={props.domainCheckout.failedItems}
                                            />
                                            )}
                    {props.domainCheckout.status === 'failed'
                                            && (
                                            <ListGroup>
                                              <ListGroupItem color="danger" className="border-top-0 border-left-0 border-right-0 text-center mb-3">
                                                <CardTitle className="mt-2">Order failed</CardTitle>
                                                <CardText>Unfortunately there was an unexpected error with your order.</CardText>
                                                <CardText className="mb-2">Please try again or contact support for further information.</CardText>
                                              </ListGroupItem>
                                            </ListGroup>
                                            )}
                    {(!props.domainCheckout.status || props.domainCheckout.status === 'payment failed')
                                            && (
                                            <ListGroup>
                                              <ListGroupItem className="border-0">
                                                <Row className="mt-3">
                                                  <Col className="d-flex">
                                                    <Link to="/domains" className="mr-auto"><Button color="primary" outline>Add more domains</Button></Link>
                                                    <CardTitle className="" style={{ marginTop: '6px', marginBottom: '6px', marginRight: '10px' }}>
                                                      £
                                                      {numeral(getTotal()).format('0,0.00')}
                                                      {' '}
                                                      + VAT
                                                    </CardTitle>
                                                    <Button
                                                      disabled={Object.keys(props.cart).length === 0}
                                                      color="primary"
                                                      className=""
                                                      onClick={getNextStep}
                                                    >
                                                      {props.domainCheckout.buttonText}
                                                    </Button>
                                                  </Col>
                                                </Row>
                                              </ListGroupItem>
                                            </ListGroup>
                                            )}
                  </CardBody>
                </Card>
              </Col>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );

};

const OrderCompleted = ({ orderNumber, items }) => (
    <ListGroup>
      <ListGroupItem className="border-top-0 border-left-0 border-right-0 text-center mb-3">
        <TitleYellowDashCentered title="Order Completed" />
        <CardText>
          Your order reference number:
          {orderNumber}
        </CardText>
        <ListGroup className="mb-3">
          {items.map((item, index) => (
            <ListGroupItem color="light" className="text-primary" key={index}>{item.name}</ListGroupItem>
          ))}
        </ListGroup>
        <CardText className="mb-2">Thank you for using hSo Domain services.</CardText>
      </ListGroupItem>
    </ListGroup>
);
const OrderFailed = ({ orderNumber, items, failedItems }) => (
    <ListGroup>
      <ListGroupItem className="border-top-0 border-left-0 border-right-0 text-center mb-3">
        <TitleYellowDashCentered title="Order Completed with errors" />
        <CardText>
          Your order reference number:
          {orderNumber}
        </CardText>
        {items.length > 0
                    && (
                    <>
                      <CardText>Items completed successfully:</CardText>
                      <ListGroup className="mb-3">
                        {items.map((item, index) => (
                          <ListGroupItem color="light" className="text-primary" key={index}>{item.name}</ListGroupItem>
                        ))}
                      </ListGroup>
                    </>
                    )}
        <CardText>Items failed:</CardText>
        <ListGroup className="mb-3">
          {failedItems.map((item, index) => (
            <ListGroupItem color="light" className="text-primary" key={index}>{item.name}</ListGroupItem>
          ))}
        </ListGroup>
        <CardText className="mb-2">Please contact support for further assistance and information.</CardText>
      </ListGroupItem>
    </ListGroup>
);
function mapStateToProps({
  user, domainCart, domainCheckout, account,
}) {

  return {
    cart: domainCart,
    domainCheckout,
    user,
    account,
  };

}
export default connect(mapStateToProps)(DomainCheckout);
