import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import LaddaButton, { ZOOM_OUT } from 'react-ladda';
import {
  Badge, Button, Col, Row,
} from 'reactstrap';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Section from '../../components/Section';
import { downloadSignatureDocument, getSignatures, setSignaturesSearchString } from '../../store/signatures/actions';
import { THelloSign, TSignature } from '../../store/signatures/types';
import SearchInput from '../../components/Inputs/SearchInput';
import Refresh from '../../components/SvgIcons/Refresh';
import SimpleLoader from '../../components/SimpleLoader';
import ComplexTable from '../../components/Table/ComplexTable';
import { IUserState } from '../../store/user/types';
import NoData from '../../components/Widgets/NoData';
import { AppDispatch } from '../../configureStore';
import MetaButton from "../../components/MetaButton/MetaButton";

type TOwnProps = {
  dispatch: AppDispatch;
  signatures: Array<TSignature>;
  filtered: Array<TSignature>;
  searchString: string;
  user: IUserState;
};

const Signatures: React.FC<TOwnProps> = ({
  dispatch, signatures, filtered, searchString, user,
}) => {

  const [loading, setLoading] = useState<boolean>(false);
  const [downloading, setDownloading] = useState<Array<string>>([]);

  const getData = () => {

    setLoading(true);
    dispatch(getSignatures()).then((result) => {

      setLoading(false);

    });

  };

  useEffect(() => {

    getData();

  }, []);

  const onChangeSearch = (e) => {

    const searchString = e.target.value.toString().toLowerCase();
    dispatch(setSignaturesSearchString(searchString));

  };

  const getReferenceNumber = (referenceType: string, reference) => {

    switch (referenceType) {
      case 'quote':
        return reference.quoteNumber;
      case 'incident':
        return reference.ticketNumber;
      case 'order':
        return reference.orderNumber;
      default:
        return '';
    }

  };

  const getBadgeColor = (status) => {

    switch (status) {
      case 'draft':
        return 'secondary';
      case 'declined':
        return 'danger';
      case 'signed':
        return 'success';
      case 'hso':
        return 'primary';
      default:
        return 'warning';
    }

  };

  const resolveSignatureLink = (hellosign: THelloSign) => {

    const signature = hellosign.signatures?.find((signature) => signature.statusCode === 'awaiting_signature' && signature.signerEmail.toLowerCase() === user.email.toLowerCase());
    if (signature) {

      return (
        <Link target="_blank" to={`/signature/${signature.id}`} className="btn btn-sm btn-secondary">
          <i
            className="icon-pencil"
          />
          {' '}
          Sign
        </Link>
      );

    }
    return '';

  };

  const handleDownload = (id) => {

    setDownloading((prevState) => prevState.concat(id));
    dispatch(downloadSignatureDocument(id)).then(() => {

      setDownloading((prevState) => prevState.filter((downloadingId) => downloadingId !== id));

    });

  };

  const resolveStatus = (value: string): string => {

    switch (value) {
      case 'sent':
        return 'For signature';
      case 'signed':
        return 'Signed';
      case 'hso':
        return 'To be signed by hSo';
      default:
        return value.charAt(0).toUpperCase() + value.slice(1);
    }

  };

  const columns = [
    {
      Header: 'ID',
      accessor: 'id',
      width: 60,
    },
    {
      Header: 'Subject',
      accessor: 'title',
    },
    {
      Header: 'Recipient',
      accessor: 'signatories',
      Cell: (props) => props.value.filter(signatory => signatory.type === 'contact')
          .map((signatory) => signatory.signatory.fullName)
          .filter((value, index, array) => array.indexOf(value) === index) // filter duplicates
          .join(', ')
    },
    {
      Header: 'Reference',
      accessor: 'relatedTo',
      Cell: (props) => getReferenceNumber(props.original.relatedToType, props.value),
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: (props) => (
        <MetaButton color={getBadgeColor(props.value)}>
          {resolveStatus(props.value)}
        </MetaButton>
      ),
    },
    {
      Header: 'Updated',
      accessor: 'updatedAt',
      Cell: (props) => (props.value ? moment(props.value).format('DD/MM/YYYY') : ''),
    },
    {
      Header: '',
      filterable: false,
      sortable: false,
      accessor: 'id',
      width: 120,
      Cell: (props) => (
        <LaddaButton
          className="btn btn-sm btn-primary btn-ladda"
          data-color="blue"
          data-style={ZOOM_OUT}
          loading={downloading.indexOf(props.value) !== -1}
          disabled={!props.original.isUploaded}
          onClick={() => handleDownload(props.value)}
        >
          <i className="icon-cloud-download" />
          {' '}
          Download
        </LaddaButton>
      ),
    },
    {
      Header: '',
      filterable: false,
      sortable: false,
      accessor: 'hellosign',
      width: 130,
      Cell: (props) => resolveSignatureLink(props.value),
    },
  ];

  return (
    <div className="animated fadeIn mb-3">
      <Section title="Contracts">
        <Row className="mb-4 d-flex justify-content-start">
          <Col className="d-flex flex-row" md={6}>
            <SearchInput value={searchString} onChange={onChangeSearch} />
          </Col>
          <Col md="6" className="d-flex justify-content-start justify-content-md-end mt-2 mt-md-0">
            <Button className="pt-1 px-1 ml-2" color="primary" onClick={() => getData()}>
              <Refresh title="Refresh" cssClassTop="refresh-svg" />
            </Button>
          </Col>
        </Row>
        <SimpleLoader loading={loading} text="Loading your contracts...">
          <Row className="mt-2 mr-0 ml-0">
            <Col className="p-4 bg-white rounded" style={{ minHeight: 100 }}>
              {!loading ? (
                signatures.length < 1 ? (
                  <NoData text="No contracts found." />
                ) : (
                  <ComplexTable
                    columns={columns}
                    data={searchString ? filtered : signatures}
                    defaultSorted={{ id: 'updated', desc: true }}
                  />
                )
              ) : ''}
            </Col>
          </Row>
        </SimpleLoader>
      </Section>
    </div>
  );

};

function mapStateToProps({ signatures, user }) {

  return {
    signatures: signatures.signatures,
    searchString: signatures.searchString,
    filtered: signatures.filtered,
    user,
  };

}

export default connect(mapStateToProps)(Signatures);
