import { apiRequest } from '../Api/ApiRequest';
import { TBrowserDetails, TCardAddress, TCardDetails } from '../../store/cardPayment/types';

// eslint-disable-next-line @typescript-eslint/naming-convention
export function api_cardPayForInvoice(cardDetails: TCardDetails, cardAddress: TCardAddress, browser: TBrowserDetails, amount: number, invoiceId: number) {

  return apiRequest({
    url: `/billing/${invoiceId}/pay`,
    method: 'post',
    headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
    data: {
      cardDetails,
      address: cardAddress,
      amount,
      browser,
    },
  }).then(
    (resp) => resp,
  ).catch(
    (error) => error,
  );

}
