import React from 'react';
import ReactTable, { ReactTableDefaults, SortingRule } from 'react-table-6';
import classnames from "classnames";

interface IComplexTable {
  columns: any;
  data: any;
  tableClass?: string;
  minRows?: number;
  filterable?: boolean;
  noDataText?: boolean;
  showPagination?: boolean;
  defaultPageSize?: number;
  showPageSizeOptions?: boolean;
  pageSizeOptions?: number[];
  defaultSorted?: SortingRule|SortingRule[];
}
Object.assign(ReactTableDefaults, {
  PreviousComponent: (props) => (
    <>
      {!props.disabled && (
        <span
          className="d-block p-1 cursor-pointer float-left text-light"
          onClick={props.onClick}
        >
          {' '}
          <i
            className="fa fa-arrow-left"
            style={{ WebkitTextStroke: '2px white' }}
          />
          {' '}
          PREV
        </span>
      )}
      {' '}
    </>
  ),
  NextComponent: (props) => (
    <>
      {!props.disabled && (
        <span
          className="d-block p-1 cursor-pointer float-right text-light"
          onClick={props.onClick}
        >
          NEXT
          {' '}
          <i
            className="fa fa-arrow-right"
            style={{ WebkitTextStroke: '2px white' }}
          />
        </span>
      )}
    </>
  ),
});
const ComplexTable: React.FC<IComplexTable> = (props) => {

  const {
    columns, data, tableClass, pageSizeOptions, defaultPageSize, defaultSorted,
  } = props;
  return (
    <ReactTable
      getTheadProps={() => ({
        style: { borderBottom: 'none' },
      })}
      getTdProps={() => ({
        style: { border: 'bottom', whiteSpace: 'unset' },
      })}
      getTrProps={(finalState, rowInfo) => ({
        className: classnames({ 'bg-light-nav': rowInfo?.original?.isActive === false }),
      })}
      className={`animated fadeIn complex-table ${tableClass}`}
      minRows={2}
      data={data}
      columns={columns}
      filterable={false}
      noDataText={false}
      showPagination
      pageSizeOptions={pageSizeOptions || [5, 10, 20, 25, 50, 100, 200, 500]}
      defaultPageSize={defaultPageSize || 20}
      defaultSorted={defaultSorted ? (Array.isArray(defaultSorted) ? defaultSorted : [defaultSorted]) : []}
    />
  );

};
export default ComplexTable;
