import React, { ChangeEventHandler, useEffect, useState } from 'react';
import {
  Alert, Button, Card, CardBody, Col, Form, FormGroup, Input, InputGroup, Label, Row,
} from 'reactstrap';
import Section from '../../components/Section';
import SelectInput, { ISelectInputOptions } from '../../components/Inputs/SelectInput';
import {
  IMonitoredItemNTDeviceDetails,
  IMonitoredItemNTProtocol,
  TNetworkToolsData,
} from '../../store/monitoredItem/types';
import SimpleLoader from '../../components/SimpleLoader';
import PingDataTable from './NetworkToolsTables/PingDataTable';
import TraceRouteTable from './NetworkToolsTables/TraceRouteTable';
import ShowRouteTable from './NetworkToolsTables/ShowRouteTable';
import NoData from '../../components/Widgets/NoData';

interface TData {
  command: string;
  destination: string;
  vrf?: string;
  protocol?: number;
}

const initialFormData = {
  command: 'ping',
  destination: '',
  vrf: 'default',
};

interface TOwnProps {
  deviceDetails: IMonitoredItemNTDeviceDetails;
  data: TNetworkToolsData;
  getPingData: (destination: string, vrf?: string) => Promise<boolean>;
  getTraceRouteData: (destination: string, vrf?: string) => Promise<boolean>;
  getShowRouteData: (destination: string, protocol: number, vrf?: string) => Promise<boolean>;
  getShowRouteDetails: (destination: string, protocol: number, vrf?: string) => Promise<boolean>;
}

const NetworkToolsPane: React.FC<TOwnProps> = ({
  deviceDetails,
  data,
  getPingData,
  getTraceRouteData,
  getShowRouteData,
  getShowRouteDetails,
}) => {

  const [formData, setFormData] = useState<TData>(initialFormData);
  const [errors, setErrors] = useState<Array<string>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<false | 'ping' | 'traceRoute' | 'showRoute' | 'showRouteDetails'>(false);

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {

    setFormData({
      ...formData,
      [event.target.id || event.target.name]: event.target.value,
    });
  
  };

  useEffect(() => {

    if (!formData.protocol && deviceDetails && ['showRoute', 'showRouteDetails'].indexOf(formData.command) !== -1) {

      const protocol = deviceDetails.vrfs[Object.keys(deviceDetails.vrfs)[0]][0].prot;

      setFormData({
        ...formData,
        protocol,
      });
    
    }

    if (formData.protocol && ['showRoute', 'showRouteDetails'].indexOf(formData.command) === -1) {

      const newData = { ...formData };
      delete newData.protocol;

      setFormData(newData);
    
    }
  
  }, [formData]);

  const validate = () => {

    const errorArr: Array<string> = [];
    if (!formData.destination) {

      errorArr.push('Target cannot be empty');
    
    }
    setErrors(errorArr);
    return errorArr.length === 0;
  
  };

  const submit = (e) => {

    if (validate()) {

      setLoaded(false);
      setLoading(true);
      switch (formData.command) {
        case 'ping':
          getPingData(formData.destination, formData.vrf)
            .then((result) => {

              setLoaded('ping');
              setLoading(false);
            
            });
          break;
        case 'traceRoute':
          getTraceRouteData(formData.destination, formData.vrf)
            .then((result) => {

              setLoaded('traceRoute');
              setLoading(false);
            
            });
          break;
        case 'showRoute':
          if (formData.protocol) {

            getShowRouteData(formData.destination, formData.protocol, formData.vrf)
              .then((result) => {

                setLoaded('showRoute');
                setLoading(false);
              
              });
          
          }
          break;
        case 'showRouteDetails':
          if (formData.protocol) {

            getShowRouteDetails(formData.destination, formData.protocol, formData.vrf)
              .then((result) => {

                setLoaded('showRouteDetails');
                setLoading(false);
              
              });
          
          }
          break;
        default:
          setLoading(false);
          break;
      }
    
    }
  
  };

  const getCommandOptions: Array<ISelectInputOptions> = [
    { value: 'ping', label: 'Ping' },
    { value: 'traceRoute', label: 'TraceRoute' },
    { value: 'showRoute', label: 'ShowRoute' },
    { value: 'showRouteDetails', label: 'ShowRouteDetails' },
  ];

  const getVrfOptions = (vrfs: Record<string, Array<IMonitoredItemNTProtocol>>): Array<ISelectInputOptions> => Object.entries(vrfs).map(([name, v]) => ({ label: name.charAt(0).toUpperCase() + name.slice(1), value: name }));

  const getProtocolOptions = (vrf: Array<IMonitoredItemNTProtocol>): Array<number> => vrf
    .map((vrfProtocol) => vrfProtocol.prot)
    .filter((value, index, array) => array.indexOf(value) === index);

  return (
    <Section title="Network Tools">
      {errors ? errors.map((error, index) => (
        <Alert key={`error-${index}`} className="alert-danger">{error}</Alert>
      )) : ''}
      <Form>
        <Label
          className="d-inline-block mr-1 text-light font-weight-bold"
          for="command"
          style={{ fontSize: '16px' }}
        >
          Command
        </Label>
        <Input
          className="d-inline-block mr-3  w-auto text-light mb-0"
          id="command"
          type="select"
          onChange={handleInput}
          value={formData.command}
          required
        >
          {getCommandOptions.map((option) => (

            <option key={`command-${option.label}`} value={option.value}>{option.label}</option>

          ))}
        </Input>
        <Label
          className="d-inline-block mr-1 text-light font-weight-bold"
          for="destination"
          style={{ fontSize: '16px' }}
        >
          Destination
        </Label>
        <Input
          className="d-inline-block mr-3 w-auto text-light mb-0"
          id="destination"
          type="text"
          value={formData.destination}
          onChange={handleInput}
          required
        />
        {deviceDetails.vrfs ? (
          <>
            <Label
              className="d-inline-block mr-1 text-light font-weight-bold"
              for="vrf"
              style={{ fontSize: '16px' }}
            >
              VRF
            </Label>
            <Input
              className="d-inline-block mr-3 w-auto text-light mb-0"
              id="vrf"
              type="select"
              onChange={handleInput}
              value={formData.vrf}
            >
              {getVrfOptions(deviceDetails.vrfs).map((option) => (

                <option key={`vrf-${option.label}`} value={option.value}>{option.label}</option>

              ))}
            </Input>
          </>
        ) : ''}
        {formData.vrf && deviceDetails.vrfs[formData.vrf] && ['showRoute', 'showRouteDetails'].indexOf(formData.command) !== -1 ? (
          <>
            <Label
              className="d-inline-block mr-1 text-light font-weight-bold"
              for="protocol"
              style={{ fontSize: '16px' }}
            >
              Protocol
            </Label>
            <Input
              className="d-inline-block mr-3 w-auto text-light mb-0"
              id="protocol"
              type="select"
              onChange={handleInput}
              value={formData.protocol}
            >
              {getProtocolOptions(deviceDetails.vrfs[formData.vrf]).map((option) => (

                <option key={`protocol-${option}`} value={option}>
                  IPv
                  {option}
                </option>

              ))}
            </Input>
          </>
        ) : ''}
        <Button className="mb-1" onClick={submit}>Submit</Button>
      </Form>

      <div className={`mt-3${loading && loaded === false ? ' p-4' : ''}`}>
        <SimpleLoader loading={loading}>
          {!loading && loaded === 'ping' ? (
            data?.pingData ? (
              <PingDataTable data={data.pingData} />
            ) : <NoData text="Command failed to run." />
          ) : ''}
          {!loading && loaded === 'traceRoute' ? (
            data?.traceRouteData && data.traceRouteData.hops.length ? (
              <TraceRouteTable data={data.traceRouteData} />
            ) : <NoData text="Command failed to run." />
          ) : ''}
          {!loading && loaded === 'showRoute' ? (
            data?.showRouteData && data.showRouteData.length ? (
              <ShowRouteTable data={data.showRouteData} />
            ) : <NoData text="Command failed to run." />
          ) : ''}
          {!loading && loaded === 'showRouteDetails' ? (
            data?.showRouteDetails && data.showRouteDetails.data ? (
              <pre className="card card-body card-body-dark card-outline-top my-4">
                {data.showRouteDetails.data}
              </pre>
            ) : <NoData text="Command failed to run." />
          ) : ''}
        </SimpleLoader>
      </div>

    </Section>
  );

};

export default NetworkToolsPane;
