import moment, { Moment } from 'moment';
import { TTrafficData } from '../monitoring/types';
import { TService } from '../service/types';

export const MONITORED_ITEM_SET_GRAPH_DATE_RANGE = 'MONITORED_ITEM_SET_GRAPH_DATE_RANGE';
export const MONITORED_ITEM_SET_GRAPH_DATA = 'MONITORED_ITEM_SET_GRAPH_DATA';
export const MONITORED_ITEM_TOGGLE_GRAPH_LOADING = 'MONITORED_ITEM_TOGGLE_GRAPH_LOADING';
export const MONITORED_ITEM_SET_MONITORED_ITEM = 'MONITORED_ITEM_SET_MONITORED_ITEM';
export const MONITORED_ITEM_TOGGLE_LOADING = 'MONITORED_ITEM_TOGGLE_LOADING';
export const MONITORED_ITEM_RESET_STATE = 'MONITORED_ITEM_RESET_STATE';
export const MONITORED_ITEM_TOGGLE_POPUP_GRAPH = 'MONITORED_ITEM_TOGGLE_POPUP_GRAPH';
export const MONITORED_ITEM_SET_POPUP_GRAPH_DATA = 'MONITORED_ITEM_SET_POPUP_GRAPH_DATA';
export const MONITORED_ITEM_RESET_POPUP_GRAPH = 'MONITORED_ITEM_RESET_POPUP_GRAPH';
export const MONITORED_ITEM_TOGGLE_POPUP_GRAPH_LOADING = 'MONITORED_ITEM_TOGGLE_POPUP_GRAPH_LOADING';
export const MONITORED_ITEM_TOGGLE_DESC_EDIT = 'MONITORED_ITEM_TOGGLE_DESC_EDIT';
export const MONITORED_ITEM_SET_DESCRIPTION_FORM = 'MONITORED_ITEM_SET_DESCRIPTION_FORM';
export const MONITORED_ITEM_DESC_SAVE = 'MONITORED_ITEM_DESC_SAVE';
export const MONITORED_ITEM_SET_DESC_ERROR = 'MONITORED_ITEM_SET_DESC_ERROR';
export const MONITORED_ITEM_TOGGLE_GRAPH_OPEN = 'MONITORED_ITEM_TOGGLE_GRAPH_OPEN';
export const MONITORED_ITEM_REVERT_DESC = 'MONITORED_ITEM_REVERT_DESC';
export const MONITORED_ITEM_NT_SET_PING_DATA = 'MONITORED_ITEM_NT_SET_PING_DATA';
export const MONITORED_ITEM_NT_SET_TRACE_ROUTE_DATA = 'MONITORED_ITEM_NT_SET_TRACE_ROUTE_DATA';
export const MONITORED_ITEM_NT_SET_SHOW_ROUTE_DATA = 'MONITORED_ITEM_NT_SET_SHOW_ROUTE_DATA';
export const MONITORED_ITEM_NT_SET_SHOW_ROUTE_DETAILS = 'MONITORED_ITEM_NT_SET_SHOW_ROUTE_DETAILS';
export interface IMonitoredDeviceRevertDesc {
  type: typeof MONITORED_ITEM_REVERT_DESC;
  desc: string;
  deviceId: string;
}
export interface IMonitoredItemToggleGraphOpenAction {
  type: typeof MONITORED_ITEM_TOGGLE_GRAPH_OPEN;
  payload: number | string;
}
export interface IMonitoredItemState {
  loading: boolean;
  item: TMonitoredItem;
  popUpGraph: TMonitoredItemPopUpGraph;
}
export type TMonitoredItem = {
  id: string;
  type: string;
  location: string;
  name: string;
  template: string;
  available: boolean;
  cpu: number;
  latency: number;
  error: string;
  uptime: number;
  memoryUse?: number;
  cpuRE?: number;
  memoryRE?: number;
  cpuSPU?: number;
  memorySPU?: number;
  flowSessions?: number;
  graphs: TMonitoredItemGraph[];
  services: TService[];
  description: string | null;
  descriptionEditable: boolean;
  descriptionFormField: string;
  inBound?: number;
  outBound?: number;
  host?: string;
  port?: string;
  favouriteStatusLoading?: boolean;
  tempDesc: string;
  diskUse?: number;
  swapUse?: number;
  networkToolsDeviceDetails?: IMonitoredItemNTDeviceDetails;
  networkToolsData: TNetworkToolsData;
  disks?: Array<{
    disk: string;
    diskUse: number;
  }>;
  numberOfCpus?: number;
  memoryTotal?: number;
  powerState?: number;
};
export type TMonitoredItemGraph = {
  id: string;
  name: string;
  type: string;
  loading: boolean;
  startDate: Moment;
  endDate: Moment;
  dataIn: TTrafficData;
  dataOut: TTrafficData;
  error: boolean;
  isOpen: boolean;
  unit?: string;
};
export type TMonitoredItemPopUpGraph = {
  loading: boolean;
  id: string;
  title: string;
  isOpen: boolean;
  tracker: string | null;
  max: number;
  multiplier: number;
  startDate: Moment;
  endDate: Moment;
  history: Array<THistoryItem>;
  format?: () => any | string;
};
export type THistoryItem = {
  clock: string;
  value: string | number;
};
export interface IMonitoredItemSetDescError {
  type: typeof MONITORED_ITEM_SET_DESC_ERROR;
  payload: string;
}
export interface IMonitoredItemToggleEdit {
  type: typeof MONITORED_ITEM_TOGGLE_DESC_EDIT;
}
export interface IMonitoredItemOnDescChange {
  type: typeof MONITORED_ITEM_SET_DESCRIPTION_FORM;
  payload: string;
}
export interface IMonitoredItemDescSave {
  description: any;
  deviceId: string;
  type: typeof MONITORED_ITEM_DESC_SAVE;
  payload: string;
}
export interface ISetMonitoredItemAction {
  type: typeof MONITORED_ITEM_SET_MONITORED_ITEM;
  item: TMonitoredItem;
}
export interface IResetMonitoredItemStateAction {
  type: typeof MONITORED_ITEM_RESET_STATE;
}
export interface IToggleMonitoredItemLoadingAction {
  type: typeof MONITORED_ITEM_TOGGLE_LOADING;
}
export interface IToggleMonitoredItemPopUpGraphAction {
  type: typeof MONITORED_ITEM_TOGGLE_POPUP_GRAPH;
}
export interface IResetMonitoredItemPopUpGraphAction {
  type: typeof MONITORED_ITEM_RESET_POPUP_GRAPH;
}
export interface IToggleMonitoredItemPopUpGraphLoadingAction {
  type: typeof MONITORED_ITEM_TOGGLE_POPUP_GRAPH_LOADING;
}
export interface ISetMonitoredItemPopUpGraphDataAction {
  type: typeof MONITORED_ITEM_SET_POPUP_GRAPH_DATA;
  key: string;
  value: string | number;
}
export interface ISetMonitoredITemGraphDateRangeAction {
  type: typeof MONITORED_ITEM_SET_GRAPH_DATE_RANGE;
  graphId: string | number;
  startDate: Moment;
  endDate: Moment;
}
export interface ISetMonitoredItemGraphData {
  type: typeof MONITORED_ITEM_SET_GRAPH_DATA;
  graphId: string;
  dataIn: TTrafficData;
  dataOut: TTrafficData;
}
export interface IToggleMonitoredItemGraphLoadingAction {
  type: typeof MONITORED_ITEM_TOGGLE_GRAPH_LOADING;
  graphId: string;
}
export interface TNetworkToolsData {
  deviceDetails?: IMonitoredItemNTDeviceDetails;
  pingData?: IMonitoredItemNTPingData;
  traceRouteData?: IMonitoredItemNTTraceRouteData;
  showRouteData?: Array<IMonitoredItemNTShowRouteData>;
  showRouteDetails?: IMonitoredItemNTShowRouteDetails;
}
export interface ISetMonitoredItemNTPingData {
  type: typeof MONITORED_ITEM_NT_SET_PING_DATA;
  data: IMonitoredItemNTPingData;
}
export interface ISetMonitoredItemNTTraceRouteData {
  type: typeof MONITORED_ITEM_NT_SET_TRACE_ROUTE_DATA;
  data: IMonitoredItemNTTraceRouteData;
}
export interface ISetMonitoredItemNTShowRouteData {
  type: typeof MONITORED_ITEM_NT_SET_SHOW_ROUTE_DATA;
  data: Array<IMonitoredItemNTShowRouteData>;
}
export interface ISetMonitoredItemNTShowRouteDetails {
  type: typeof MONITORED_ITEM_NT_SET_SHOW_ROUTE_DETAILS;
  data: IMonitoredItemNTShowRouteDetails;
}
export interface IMonitoredItemNTProtocol {
  prot: number;
  routes: string;
  protocols: Array<string>;
}
export interface IMonitoredItemNTDeviceDetails {
  name: string;
  model: string;
  vrfs: Record<string, Array<IMonitoredItemNTProtocol>>;
}
export interface IMonitoredItemNTPingData {
  probes: number;
  loss: number;
  rttMin: number;
  rttMax: number;
  rttAvg: number;
  rttStddev: number;
}
export interface IMonitoredItemNTTraceRouteData {
  hops: Array<{
    ip: string;
    results: Array<{
      result: boolean;
      rtt: number;
    }>;
    ttl: number;
  }>;
  result: boolean;
}
export interface IMonitoredItemNTShowRouteData {
  destination: string;
  routes: Array<{
    active: boolean;
    age: string;
    nexthops: Array<{
      to: string;
      via: string;
    }>;
    protocol: string;
  }>;
}
export interface IMonitoredItemNTShowRouteDetails {
  data: string;
}

export type MonitoredITemActionTypes =
  ISetMonitoredItemAction
  | IToggleMonitoredItemLoadingAction
  | IResetMonitoredItemStateAction
  | IToggleMonitoredItemPopUpGraphAction
  | ISetMonitoredItemPopUpGraphDataAction
  | IResetMonitoredItemPopUpGraphAction
  | IToggleMonitoredItemPopUpGraphLoadingAction
  | IMonitoredItemDescSave
  | IMonitoredItemOnDescChange
  | IMonitoredItemToggleEdit
  | IMonitoredItemSetDescError
  | ISetMonitoredITemGraphDateRangeAction
  | ISetMonitoredItemGraphData
  | IToggleMonitoredItemGraphLoadingAction
  | IMonitoredItemToggleGraphOpenAction
  | IMonitoredDeviceRevertDesc
  | ISetMonitoredItemNTPingData
  | ISetMonitoredItemNTTraceRouteData
  | ISetMonitoredItemNTShowRouteData
  | ISetMonitoredItemNTShowRouteDetails;
