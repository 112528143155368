import moment from 'moment';
import creditCardType from 'credit-card-type';
import isInt from 'validator/lib/isInt';
import { TCardAddress, TCardDetails, TCardErrors } from '../store/cardPayment/types';

export const getMonthOptions = () => [
  { value: '', label: '--' },
  { value: '01', label: '01' },
  { value: '02', label: '02' },
  { value: '03', label: '03' },
  { value: '04', label: '04' },
  { value: '05', label: '05' },
  { value: '06', label: '06' },
  { value: '07', label: '07' },
  { value: '08', label: '08' },
  { value: '09', label: '09' },
  { value: '10', label: '10' },
  { value: '11', label: '11' },
  { value: '12', label: '12' },
];

export const getYearOptions = (backwards = false) => {

  const options = [{ value: '', label: '--' }];
  const currentDate = moment();
  options.push({ value: currentDate.format('YYYY'), label: currentDate.format('YYYY') });

  if (backwards) {

    for (let i = 1; i < 5; i++) {

      currentDate.subtract(1, 'years');
      options.push({ value: currentDate.format('YYYY'), label: currentDate.format('YYYY') });
    
    }
  
  } else {

    for (let i = 1; i < 10; i++) {

      currentDate.add(1, 'years');
      options.push({ value: currentDate.format('YYYY'), label: currentDate.format('YYYY') });
    
    }
  
  }

  return options;

};

export const validateCardDetails = async (cardDetails: TCardDetails, cardAddress: TCardAddress): Promise<TCardErrors> => {

  const errors: TCardErrors = {};
  const cardType = creditCardType(parseInt(cardDetails.cardNumber).toString());
  if (cardType.length !== 1 || cardType[0].type !== cardDetails.cardType) {

    errors.cardNumber = true;
  
  }
  if (cardType[0] && (!cardDetails.cardCVC || (cardType[0].code && cardDetails.cardCVC.length !== cardType[0].code.size) || !isInt(cardDetails.cardCVC))) {

    errors.cardCVC = true;
  
  }
  if (!cardDetails.cardHolder) {

    errors.cardHolder = true;
  
  }
  if (!isInt(cardDetails.cardExpirationMonth) || !isInt(cardDetails.cardExpirationYear)) {

    errors.cardExpirationDate = true;
  
  } else {

    const currentDate = moment();
    const expiryDate = moment(`${cardDetails.cardExpirationYear}-${cardDetails.cardExpirationMonth}-01`);
    if (expiryDate.isSameOrBefore(currentDate)) {

      errors.cardExpirationDate = true;
    
    }
  
  }
  if (!cardAddress.addressLine1) {

    errors.addressLine1 = true;
  
  }
  if (!cardAddress.city) {

    errors.city = true;
  
  }
  if (!cardAddress.postcode) {

    errors.postcode = true;
  
  }
  return errors;

};

export const getCreditCards = () => [
  { value: 'visa', label: 'Visa' },
  { value: 'mastercard', label: 'Mastercard' },
  { value: 'american-express', label: 'American Express' },
  { value: 'maestro', label: 'Maestro' },
];
