import { apiRequest } from '../Api/ApiRequest';

export function api_getOrderList() {

  return apiRequest({
    url: '/orders',
    method: 'get',
    headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
  }).then(
    resp => resp,
  ).catch(
    error => error,
  );

}

export function api_getOrder(orderNumber) {

  return apiRequest({
    url: `/orders/${orderNumber}?include=lineItems,lineItems.product,projectManager`,
    method: 'get',
    headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
  }).then(
    resp => resp,
  ).catch(
    error => error,
  );

}

export function api_getOrderActivities(orderNumber) {

  return apiRequest({
    url: `/orders/${orderNumber}/activities`,
    method: 'get',
    headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
  }).then(
    resp => resp,
  ).catch(
    error => error,
  );

}

export function api_getOrderActivityContent(orderNumber, activityId) {

  return apiRequest({
    url: `/orders/${orderNumber}/activities/${activityId}`,
    method: 'get',
    headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
  }).then(
    resp => resp,
  ).catch(
    error => error,
  );

}

export function createOrderMessage(orderId, message) {

  return apiRequest({
    url: `/orders/${orderId}/message`,
    method: 'post',
    headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
    data: {
      message,
    },
  }).then(
    resp => resp.data,
  ).catch(
    error => error,
  );

}

export function getPurchaseToken(amount) {

  return apiRequest({
    url: '/account/purchaseToken',
    method: 'post',
    headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
    data: {
      amount,
    },
  }).then(
    resp => resp,
  ).catch(
    error => error,
  );

}
