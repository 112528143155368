import { apiRequest } from '../Api/ApiRequest';
import { queryStringFromFields } from '../../helpers/QueryString.ts';

export function api_getVeeamBackupService(id, include = []) {

  return apiRequest({
    url: `/services/veeam-backup/${id}${queryStringFromFields({ include: include.join(',') })}`,
    method: 'get',
    headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
  }).then(
    resp => resp,
  ).catch(
    error => error,
  );

}
