import React, { FunctionComponent } from 'react';
import { Col, Row } from 'reactstrap';

type TOwnProps = {
  seconds: number;
  minutes: number;
  hours: number;
  days: number;

};

const MonitorUpTime: FunctionComponent<TOwnProps> = (props) => {

  const {
    seconds, minutes, hours, days,
  } = props;

  return (
    <div
      className="shadow-sm pt-3 bg-white d-flex flex-column monitoring-widget w-100 h-100"
      style={{ borderRadius: '10px' }}
    >
      <Row className="mx-0  mb-3">
        <Col className="d-flex flex-column">
          <h5
            className="text-light text-uppercase font-xl text-center mb-2 "
          >
            Uptime
          </h5>
          <div className="monitoring-widget__uptime pt-0  d-table truncate">
            <div
              className="font-weight-bold text-dark monitoring-widget__uptime__number pt-0 text-left d-table-row w-100 truncate"
            >
              <span className="d-table-cell text-right">{days}</span>
              <span
                className="text-light monitoring-widget__uptime__ms d-table-cell pl-2 truncate"
              >
                Days
              </span>
            </div>

            <div
              className="font-weight-bold text-dark monitoring-widget__uptime__number pt-0 text-left d-table-row"
            >
              <span className="d-table-cell text-right truncate">{hours}</span>
              <span
                className="text-light monitoring-widget__uptime__ms d-table-cell pl-2"
              >
                hours
              </span>
            </div>
            <div
              className="font-weight-bold text-dark monitoring-widget__uptime__number pt-0 text-left d-table-row"
            >
              <span className="d-table-cell text-right">{minutes}</span>
              <span
                className="text-light monitoring-widget__uptime__ms d-table-cell pl-2"
              >
                minutes
              </span>
            </div>
            <div
              className="font-weight-bold text-dark monitoring-widget__uptime__number pt-0 text-left d-table-row"
            >
              <span className="d-table-cell text-right">{seconds}</span>
              <span
                className="text-light monitoring-widget__uptime__ms d-table-cell pl-2"
              >
                seconds
              </span>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );

};

export default MonitorUpTime;
