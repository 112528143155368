import { ISetContactErrorsAction } from '../contact/types';
import { TAccountCustomer } from '../account/types';
import { TFavourite } from '../user/types';

export const SET_SYSTEM_ERROR = 'SET_SYSTEM_ERROR';
export const RESET_SYSTEM_ERROR = 'RESET_SYSTEM_ERROR';
export const SYSTEM_SET_SUCCESS_MESSAGE = 'SYSTEM_SET_SUCCESS_MESSAGE';
export const SYSTEM_RESET_SUCCESS_MESSAGE = 'SYSTEM_RESET_SUCCESS_MESSAGE';
export const SYSTEM_ERROR_EMAIL_SENT = 'SYSTEM_ERROR_EMAIL_SENT';
export const SYSTEM_TOGGLE_STATUS_LOADING = 'SYSTEM_TOGGLE_STATUS_LOADING';
export const SYSTEM_SET_FORBIDDEN_ERROR = 'SYSTEM_SET_FORBIDDEN_ERROR';
export const SYSTEM_RESET_FORBIDDEN_ERROR = 'SYSTEM_RESET_FORBIDDEN_ERROR';
export const SYSTEM_SET_CONNECTION_ERROR = 'SYSTEM_SET_CONNECTION_ERROR';
export const SYSTEM_RESET_CONNECTION_ERROR = 'SYSTEM_RESET_CONNECTION_ERROR';
export const SYSTEM_SET_PARTNER_CUSTOMER = 'SYSTEM_SET_PARTNER_CUSTOMER';
export const SYSTEM_RESET_PARTNER_CUSTOMER = 'SYSTEM_RESET_PARTNER_CUSTOMER';
export const SYSTEM_SET_CONFIRMATION = 'SYSTEM_SET_CONFIRMATION';
export const SYSTEM_RESET_CONFIRMATION = 'SYSTEM_RESET_CONFIRMATION';
export const SYSTEM_TOGGLE_TUTORIAL_MODAL = 'SYSTEM_TOGGLE_TUTORIAL_MODAL';
export const SYSTEM_SET_SETTINGS = 'SYSTEM_SET_SETTINGS';

export interface ISystemSetForbiddenError {
  type: typeof SYSTEM_SET_FORBIDDEN_ERROR;
}
export interface ISystemResetForbiddenError {
  type: typeof SYSTEM_RESET_FORBIDDEN_ERROR;
}
export interface ISystemSetConnectionError {
  type: typeof SYSTEM_SET_CONNECTION_ERROR;
}
export interface ISystemResetConnectionError {
  type: typeof SYSTEM_RESET_CONNECTION_ERROR;
}
export interface ISuccess {
  isSet: boolean;
  message: string;
}
export interface ISystemSendErrorReportEmailAction {
  type: typeof SYSTEM_ERROR_EMAIL_SENT;
  error: string;
}
export interface ISystemState {
  status: string;
  success: ISuccess;
  errorEmailSent: boolean;
  loading: boolean;
  errorId: string;
  forbiddenError: boolean;
  connectionError: boolean;
  activePartnerCustomer: TAccountCustomer;
  confirmation: IConfirmation;
  hideOverviewModal: boolean;
}

export type TSystemSettings = {
  hideTutorialModal: boolean;
};

export interface IConfirmation {
  text: string;
  isOpen: boolean;
  color: string;
  cancel: () => any;
  proceed: () => any;
}

export type THSOContact = {
  firstName: string;
  lastName: string;
  name: string;
  email: string;
  telephone: string;
};

export interface ISystemSetSuccessMessageAction {
  type: typeof SYSTEM_SET_SUCCESS_MESSAGE;
  message: string;
}
export interface ISetSystemErrorAction {
  type: typeof SET_SYSTEM_ERROR;
  errorId: string;
}
export interface IResetSystemErrorAction {
  type: typeof RESET_SYSTEM_ERROR;

}
export interface IResetSystemSetSuccessMessageAction {
  type: typeof SYSTEM_RESET_SUCCESS_MESSAGE;
}
export interface ISystemToggleStatusLoadingAction {
  type: typeof SYSTEM_TOGGLE_STATUS_LOADING;
}
export interface ISystemSetPartnerCustomerAction {
  type: typeof SYSTEM_SET_PARTNER_CUSTOMER;
  customer: TAccountCustomer;
  userFavourites: TFavourite[];
}
export interface ISystemResetPartnerCustomerAction {
  type: typeof SYSTEM_RESET_PARTNER_CUSTOMER;
}
export interface ISystemSetConfirmationAction {
  type: typeof SYSTEM_SET_CONFIRMATION;
  confirmation: IConfirmation;
}
export interface ISystemReSetConfirmationAction {
  type: typeof SYSTEM_RESET_CONFIRMATION;
}
export interface ISystemToggleTutorialModalAction {
  type: typeof SYSTEM_TOGGLE_TUTORIAL_MODAL;
}

export interface ISystemSetSettingsAction {
  type: typeof SYSTEM_SET_SETTINGS;
  settings: TSystemSettings;
}

export type SystemActionTypes =
    | ISetSystemErrorAction
    | IResetSystemErrorAction
    | ISystemSetSuccessMessageAction
    | IResetSystemSetSuccessMessageAction
    | ISystemSendErrorReportEmailAction
    | ISystemToggleStatusLoadingAction
    | ISystemSetForbiddenError
    | ISystemResetForbiddenError
    | ISystemSetConfirmationAction
    | ISystemReSetConfirmationAction
    | ISystemSetPartnerCustomerAction
    | ISystemResetPartnerCustomerAction
    | ISystemSetSettingsAction
    | ISystemToggleTutorialModalAction
    | ISystemSetConnectionError
    | ISystemResetConnectionError;
