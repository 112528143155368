import React, {DetailedHTMLProps, FunctionComponent, PropsWithChildren} from "react";

type TProps = {
  color?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info';
} & PropsWithChildren & DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>;
const MetaButton: FunctionComponent<TProps> = (props) => {
  const {children, color = 'primary', className = ''} = props
  return (
    <span  className={`btn-sm d-inline-block bg-transparent text-${color} border border-${color} align-content-center text-center ${className}`}>
      {children}
    </span>
  )
}

export default MetaButton;
