/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Alert, Button,
  CardText,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from 'reactstrap';
import classnames from 'classnames';
import toastr from 'toastr';
import * as ipRegex from 'ip-regex';
import {Navigate, useParams} from 'react-router-dom'
import {
  createNewZoneRecord,
  deleteZone,
  deleteZoneRecord,
  getZoneRecord,
  updateZoneRecord
} from '../../../utils/DNS/DNS';
import {SpinnerInfo} from '../../../components/Spinner/Spinner';
import RecordTable, {RecordTypes} from './Records/RecordTable';
import SOATable from './Records/SOATable';
import NewRecord, {typeMap} from './Records/NewRecord';
import Section from '../../../components/Section';
import {connect} from "react-redux";
import {checkPermission} from "../../../utils/Auth/AuthService";

class DNSZone extends React.Component {

  static contextTypes = {
    router: PropTypes.shape({
      history: PropTypes.shape({
        push: PropTypes.func.isRequired,
        replace: PropTypes.func.isRequired
      }).isRequired,
      staticContext: PropTypes.object
    }).isRequired
  };

  state = {
    activeTab: 0,
    loading: true,
    zone: {},
    records: {},
    error: {},
    updating: {},
    creating: {},
    newRecord: {},
    deleted: false
  };


  componentDidMount(){

    getZoneRecord(this.props.params.id).then((result) => {

      const state = { ...this.state};
      if(result.status === 200){

        result.data.records.forEach((record) => {

          if(!state.records[record.type]){

            state.records[record.type] = [];

          }
          state.records[record.type].push(record);

        });
        delete(result.data.records);
        state.zone = result.data;

      } else{

        state.error.fetch = true;

      }
      state.loading = false;
      this.setState(state);

    });

  }

  toggle = (tab) => {

    if (this.state.activeTab !== tab) {

      this.setState({
        activeTab: tab
      });

    }

  };

  isReversedZone = () => {

    const regex = new RegExp('\.arpa$');
    return regex.test(this.state.zone.name);

  };

  handleRecordInput = (type, event) =>{

    const records = { ...this.state.records};
    if(type === 'SOA'){

      records.SOA[0][event.target.id] = event.target.value;

    }

    this.setState(records);

  };

  handleUpdate = (type) =>{

    const error = { ...this.state.error};
    const updating = { ...this.state.updating};
    updating[type] = true;
    this.setState({updating});
    if(!checkPermission('dns.write', this.props.user.permissions)){
      toastr.error('Unfortunately you are not authorized to do this!');
      return
    }
    if(type === 'SOA'){

      const record = this.state.records.SOA[0];
      updateZoneRecord(this.state.zone.id, record.id, {minimum: record.minimum, expire: record.expire}).then((result) =>{

        if(result.status === 200){

          toastr.success('Record successfully updated');

        } else if(result.status === 422){

          error.update = true;
          error.message = result.data;

        } else{

          toastr.error('There was an error with your request');

        }
        updating[type] = false;
        this.setState({error, updating});

      });

    }

  };

  handleDeleteRecord = (type, recordId) => {

    if(!checkPermission('dns.write', this.props.user.permissions)){
      toastr.error('Unfortunately you are not authorized to do this!');
      return
    }
    const updating = { ...this.state.updating};
    updating[type] = true;
    this.setState({updating});
    deleteZoneRecord(this.state.zone.id, recordId).then((result) => {

      if(result.status === 200){

        this.removeRecordFromState(type, recordId);
        toastr.success('Record successfully deleted');

      } else{

        toastr.error('There was an error with your request');

      }
      updating[type] = false;
      this.setState({updating});

    });



  };

  handleDeleteZone = () => {

    if(!checkPermission('dns.write', this.props.user.permissions)){
      toastr.error('Unfortunately you are not authorized to do this!');
      return
    }
    const updating = { ...this.state.updating};
    updating.SOA = true;
    this.setState({updating});
    deleteZone(this.state.zone.id).then((result) => {

      let deleted = false;
      if(result.status === 200){

        toastr.success('Zone successfully deleted');
        deleted = true;

      } else{

        toastr.error('There was an error with your request');

      }
      updating.SOA = false;
      this.setState({updating, deleted});

    });



  };

  removeRecordFromState = (type, id) => {

    const records = { ...this.state.records};
    records[type].forEach((record, index) => {

      if(record.id === id){

        records[type].splice(index,1);

      }

    });
    this.setState({records});

  };

  openNewRecordForm = (type) => {

    const newRecord = {};
    newRecord[type] = {};
    this.setState({newRecord});

  };

  cancelAddRecord = (type) => {

    const newRecord = { ...this.state.newRecord};
    delete newRecord[type];
    this.setState({newRecord});

  };

  createNewRecord = () =>{

    if(!checkPermission('dns.write', this.props.user.permissions)){
      toastr.error('Unfortunately you are not authorized to do this!');
      return
    }
    const key = Object.keys(this.state.newRecord);
    let creating = {};
    if(this.validateNewRecordInput(key[0])){

      creating[key[0]] = true;
      this.setState({creating});
      createNewZoneRecord(this.state.zone.id, key[0], this.state.newRecord[key[0]]).then((result) => {

        creating = {};
        if(result.status === 200){

          const records = { ...this.state.records};
          if(!records[key[0]]){

            records[key[0]] = [];

          }
          records[key[0]].unshift(result.data);
          this.setState({records});
          toastr.success('New record successfully created');
          this.cancelAddRecord(key[0]);

        } else{

          toastr.error('There was an error with your request');

        }
        this.setState({creating});

      });

    }


  };

  validateNewRecordInput = (type) => {

    const newRecord = { ...this.state.newRecord};
    const recordTemplate = typeMap[type].slice(0);
    const errors = {};
    errors[type] = {};
    Object.keys(recordTemplate).forEach((key) =>{

      const {id} = recordTemplate[key];
      const value = newRecord[type][id];
      if(!value){

        errors[type][id] = 'You must provide a value';

      } else if(type === 'A' && id === 'content' && !ipRegex.v4({exact:true}).test(value)){

        errors.A.content = 'You must provide a valid Ipv4 address';

      } else if(type === 'AAAA' && id === 'content' && !ipRegex.v6({exact:true}).test(value)){

        errors.AAAA.content = 'You must provide a valid Ipv6 address';

      } else if(type === 'NS' && id === 'content'){

        const regex = new RegExp('^[\*]{0,1}[\.a-z0-9\-]{3,200}$');
        if(!regex.test(value)) {

          errors[type][id] = 'A valid nameserver address must be supplied';

        }

      } else if(id === 'prio'){

        const regex = new RegExp('^[0-9]+$');
        if(!regex.test(value)){

          errors[type][id] = 'Priority must be a whole number where a lower number indicates a higher preference.';

        }

      } else if (id === 'host' || (type === 'CNAME' && id === 'content') || (type === 'MX' && id === 'content')){

        const regex = new RegExp('^[\.a-z0-9\-\_\*]+$');
        if(!regex.test(value)) {

          errors[type][id] = 'Invalid characters were found in the host. Please use only a-z, 0-9, -, _, * and . (period).';

        }

      } else if(type === 'PTR' && id === 'content'){

        const regex = new RegExp('^[0-9\.a-fA-F]+$');
        if(!regex.test(value)) {

          errors[type][id] = 'A valid IN_ADDR address must be supplied';

        }

      }

    });
    newRecord.errors = errors;
    this.setState({newRecord});
    return Object.keys(errors[type]).length === 0;

  };

  handleUpdateNewRecord = (type, event) =>{

    const newRecord = { ...this.state.newRecord};
    newRecord[type][event.target.name] = event.target.value;
    this.setState({newRecord});

  };

  render (){

    if(this.state.loading){

      return (
                <div className="app flex-row align-items-center animated fadeIn">
                    <div className="container">
                        <div className="row justify-content-center">
                            <SpinnerInfo/>
                        </div>
                    </div>
                </div>
      );

    }
    if(this.state.error.fetch){

      return (
                <div className="row d-flex justify-content-center">
                    <Alert color="danger" className="text-center">
                        <CardText>Unfortunately we are having difficulties with gathering information related to your DNS service.</CardText>
                        <CardText>Please contact support for further assistance.</CardText>
                    </Alert>
                </div>
      );

    }
    if(this.state.deleted){

      return <Navigate to="/services/dns"/>;

    }

    return (
                <div className="fadeIn">
                    <Section title={this.state.zone.name}>
                        <Row>
                            <Col className="p-4 bg-white rounded">
                            <Nav tabs className="d-flex justify-content-center">
                                <NavItem>
                                    <NavLink href="#"
                                             className={classnames({active: this.state.activeTab === 0})}
                                             onClick={() => {

                                               this.toggle(0);

                                             }}>SOA
                                    </NavLink>
                                </NavItem>
                                {Object.keys(this.isReversedZone() ? RecordTypes.reverse : RecordTypes.normal).map((key, index) => {

                                  return (
                                        <NavItem>
                                            <NavLink href="#"
                                                     className={classnames({active: this.state.activeTab === index + 1})}
                                                     onClick={() => {

                                                       this.toggle(index + 1);

                                                     }}>{key}
                                            </NavLink>
                                        </NavItem>
                                  );

                                })}
                            </Nav>
                            <TabContent className="col-md-12" activeTab={this.state.activeTab}>
                                <TabPane tabId={0}>
                                    <Row className="d-flex justify-content-center">
                                        <Col md={8} sm={12} className="pt-3">
                                            {this.state.records.SOA &&
                                            <SOATable
                                                data={this.state.records.SOA[0]}
                                                handleInput={this.handleRecordInput.bind(null, 'SOA')}
                                                handleUpdate={this.handleUpdate.bind(null, 'SOA')}
                                                handleDelete={this.handleDeleteZone}
                                                updating={this.state.updating.SOA}
                                                user={this.props.user}
                                            />
                                            }
                                            {!this.state.records.SOA && <NoDataAlert type="SOA"/>}
                                        </Col>
                                    </Row>
                                </TabPane>
                                {Object.keys(this.isReversedZone() ? RecordTypes.reverse : RecordTypes.normal).map((key, index) => {

                                  return (
                                        <TabPane tabId={index + 1} key={`tab${  index}`}>
                                            <Row className="d-flex justify-content-center">
                                                <Col md={8} sm={12} className="pt-3">
                                                    {this.state.newRecord[key] && !this.state.creating[key] &&
                                                    <div className="border border-secondary p-3 mb-3">
                                                        <NewRecord
                                                            type={key}
                                                            data={this.state.newRecord}
                                                            cancel={this.cancelAddRecord.bind(null, key)}
                                                            updateInput={this.handleUpdateNewRecord.bind(null, key)}
                                                            create={this.createNewRecord}
                                                            name={this.state.zone.name}
                                                        />
                                                    </div>
                                                    }
                                                    {this.state.creating[key] &&
                                                    <div className="border border-secondary p-3 mb-3">
                                                        <SpinnerInfo/>
                                                    </div>
                                                    }
                                                    {this.state.records[key] && this.state.records[key].length > 0 && this.state.activeTab === (index + 1) &&
                                                    <RecordTable
                                                        type={key}
                                                        data={this.state.records[key]}
                                                        handleDelete={this.handleDeleteRecord.bind(null, key)}
                                                        updating={this.state.updating.NS}
                                                        openNewRecordForm={this.openNewRecordForm.bind(null, key)}
                                                        reverse={this.isReversedZone()}
                                                    />
                                                    }
                                                    {(!this.state.records[key] || !this.state.records[key].length) && !this.state.newRecord[key] && <NoDataAlert openNewRecordForm={this.openNewRecordForm.bind(null, key)} type={key}/>}

                                                </Col>
                                            </Row>
                                        </TabPane>
                                  );

                                })}
                            </TabContent>
                            </Col>
                        </Row>
                    </Section>
                </div>
    );

  }
}

const NoDataAlert = ({type, openNewRecordForm}) =>{

  return (
        <div className="text-center">
            <Alert color="warning" className="text-center">You have no {type} record for this zone</Alert>
            <Button color="danger" outline onClick={openNewRecordForm}>Add new record</Button>
        </div>

  );

};
const DNSZoneWrapper = (props) => {
  const params = useParams();
  return <DNSZone params={params} {...props}/>
}
function mapStateToProps ({ user }) {

  return {
    user
  };

}
export default connect(mapStateToProps)(DNSZoneWrapper);
