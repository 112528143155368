import * as React from 'react';
import isEmpty from 'lodash.isempty';
import TextInput from '../../Inputs/TextInput';
import {TContact, TPortalUserRole} from '../../../store/contact/types';
import CheckBoxList, {ICheckBoxOption} from '../../Inputs/CheckBoxList';
import SelectInput, {ISelectInputOptions} from '../../Inputs/SelectInput';
import {IFormError} from '../types';
import 'react-phone-input-2/lib/style.css';
import {
  USER_TYPE_ADMINISTRATOR,
  USER_TYPE_READ_ONLY,
  USER_TYPE_SUPPORT_USER,
  USER_TYPE_TECHNICAL_USER,
} from '../../../store/user/types';
import CheckBox from '../../Inputs/CheckBox';

type TOwnProps = {
  newContact?: boolean;
  contact: TContact;
  contactTypes: Array<ICheckBoxOption>;
  portalAccessOption?: ICheckBoxOption;
  portalUserRole?: TPortalUserRole;
  portalUserTypes: Array<ISelectInputOptions>;
  onPortalAccessChange: (event: React.ChangeEvent<HTMLInputElement>) => any;
  onPortalUserRoleChange: (event: React.ChangeEvent<HTMLInputElement>) => any;
  onFieldChange: (event: React.ChangeEvent<HTMLInputElement>) => any;
  errors: Array<IFormError>;
  userId: string;
  username: string;
  canEditAccess: boolean;
};
const EditContactForm: React.FC<TOwnProps> = (props) => {

  const {
    contact, portalAccessOption, portalUserRole, newContact, userId, canEditAccess,
  } = props;
  const [statusAlertChecked, setStatusAlertChecked] = React.useState(false);
  const getFieldError = (key: string) => {

    const found = props.errors.filter((error) => error.id === key);
    return found.length >= 1 ? found[0] : undefined;

  };
  const portalUserTypeOptions = [
    {label: 'Administrator', value: USER_TYPE_ADMINISTRATOR},
    {label: 'Technical User', value: USER_TYPE_TECHNICAL_USER},
    {label: 'Read Only', value: USER_TYPE_READ_ONLY},
    {label: 'Support User', value: USER_TYPE_SUPPORT_USER},
  ];

  React.useEffect(() => {

    setStatusAlertChecked(Boolean(contact.statusNotificationEmail || contact.statusNotificationMobile));

  }, []);

  return (
    <>
      <TextInput
        id="firstName"
        label="First Name"
        value={contact.firstName}
        readOnly={false}
        onChange={props.onFieldChange}
        error={getFieldError('firstName')}
      />
      <TextInput
        id="lastName"
        label="Last Name"
        value={contact.lastName}
        readOnly={false}
        onChange={props.onFieldChange}
        error={getFieldError('lastName')}
      />
      <TextInput
        id="email"
        label="Email"
        value={contact.email}
        readOnly={false}
        onChange={props.onFieldChange}
        error={getFieldError('email')}
      />
      <TextInput
        type="tel"
        id="phone"
        label="Phone"
        value={contact.phone}
        readOnly={false}
        onChange={props.onFieldChange}
        error={getFieldError('phone')}
      />
      {!isEmpty(contact.portalUser) && !newContact && contact.isActive && (
        <>
          <CheckBoxList
            horizontal={props.username !== contact.portalUser?.username}
            options={portalAccessOption ? [portalAccessOption] : []}
            label="Portal Access"
            readOnly={!canEditAccess}
            onChange={props.onPortalAccessChange}
          />
          <TextInput
            id="portalUsername"
            label="Portal User Name"
            value={contact.portalUser?.username || ''}
            readOnly
          />
          <SelectInput
            disabled={userId === contact.id}
            id="Portal User Type"
            value={portalUserRole ? portalUserRole.roleId : ''}
            onChange={props.onPortalUserRoleChange}
            label="Portal User Role"
            options={portalUserTypeOptions}
          />
        </>
      )}
      {contact.isActive && (
        <>
          <CheckBox
            id="statusAlerts"
            label="Status Alerts"
            checked={statusAlertChecked}
            horizontal
            name="Receive updates on status information during major service outages."
            onChange={(event) => {

              setStatusAlertChecked(event.target.checked);
              props.onFieldChange(event);

            }}
            readOnly={false}
          />
          {statusAlertChecked && (
            <>
              <TextInput
                id="statusNotificationEmail"
                value={contact.statusNotificationEmail || ''}
                onChange={props.onFieldChange}
                error={getFieldError('statusNotificationEmail')}
              >
                Email
                <div className="small">Emails will be sent for all new status messages and updates</div>
              </TextInput>
              <TextInput
                id="statusNotificationMobile"
                value={contact.statusNotificationMobile || ''}
                onChange={props.onFieldChange}
                error={getFieldError('statusNotificationMobile')}
              >
                Mobile
                <div className="small">SMS will only be sent for new Major Outages, updates will be sent via email</div>
              </TextInput>
            </>
          )}
        </>
      )}
    </>
  )
}

export default EditContactForm;
