import { AxiosResponse } from 'axios';
import { api_getAccountContacts } from '../../utils/Contacts/Contacts';
import { CONTACT_TOGGLE_LOADING, IToggleContactLoadingAction, TContact } from '../contact/types';
import {
  CONTACTS_SET_CONTACTS,
  CONTACTS_SET_SEARCH_STRING,
  CONTACTS_TOGGLE_LOADING,
  CONTACTS_RESET_STORE,
  IContactsResetState,
  ISetContactsAction,
  ISetContactsSearchAction,
  IToggleContactsLoadingAction,
  CONTACTS_NEW_CONTACT,
  IPopulateContactTypesAction,
  CONTACTS_POPULATE_CONTACT_TYPES, CONTACTS_UPDATE_CONTACT,

} from './types';
import { setSystemError } from '../system/actions';
import { defaultErrorFeedback } from '../../actions/feedback';

function setAccountContacts(contacts: TContact[]): ISetContactsAction {

  return {
    type: CONTACTS_SET_CONTACTS,
    contacts,
  };

}
export const resetContactsStore = (): IContactsResetState => ({ type: CONTACTS_RESET_STORE });
export function setContactsSearchString(search: string): ISetContactsSearchAction {

  return {
    type: CONTACTS_SET_SEARCH_STRING,
    search,
  };

}
export function toggleContactsLoading(): IToggleContactsLoadingAction {

  return {
    type: CONTACTS_TOGGLE_LOADING,
  };

}
export function getAccountContacts() {

  return (dispatch) => api_getAccountContacts().then((result: AxiosResponse) => {

    if (result.status === 200) {

      dispatch(setAccountContacts(result.data));
      return true;
    
    }
    if (result.data.error && result.data.error.id) {

      dispatch(setSystemError(result.data.error.id));
      return false;
    
    }

    dispatch(defaultErrorFeedback());
    return false;
  
  });

}

export const newContact = (data) => ({ type: CONTACTS_NEW_CONTACT, data });

export const updateContact = (data) => ({ type: CONTACTS_UPDATE_CONTACT, data });

export const populateContactTypes = (contactTypes): IPopulateContactTypesAction => ({ type: CONTACTS_POPULATE_CONTACT_TYPES, contactTypes });
