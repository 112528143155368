import {
  IOrdersState,
  ORDERS_SET_ORDERS,
  ORDERS_SET_SEARCH_STRING,
  ORDERS_TOGGLE_LOADING,
  OrdersActionTypes,
  ORDERS_RESET_STORE,
} from './types';

const initialState: IOrdersState = {
  loading: false,
  searchString: '',
  filtered: [],
  orders: [],
};

export function ordersReducer(state = initialState, action: OrdersActionTypes) {

  switch (action.type) {
    case ORDERS_SET_ORDERS:
      return {
        ...state,
        orders: action.orders,
      };
    case ORDERS_SET_SEARCH_STRING:
      const filtered = state.orders.filter((order) => String(order.orderNumber).toLowerCase().indexOf(action.search.toLowerCase()) !== -1
                        || String(order.name).toLowerCase().indexOf(action.search.toLowerCase()) !== -1
                        || String(order.status).toLowerCase().indexOf(action.search.toLowerCase()) !== -1
                        || String(order.orderType).toLowerCase().indexOf(action.search.toLowerCase()) !== -1);
      return {
        ...state,
        searchString: action.search,
        filtered,
      };
    case ORDERS_TOGGLE_LOADING:
      return {
        ...state,
        loading: !state.loading,
      };
    case ORDERS_RESET_STORE:
      return {
        ...initialState,
      };
    default:
      return {
        ...state,
      };
  }

}
