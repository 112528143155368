/* eslint-disable */
import React from 'react';
import { FormGroup, Label, Col, Input} from 'reactstrap';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import PhoneInput from 'react-phone-input-2';
import { hasCountryCode } from '../../../helpers/phoneNumber/phoneNumber';
import 'react-phone-input-2/lib/style.css';

const DomainContact = ({contactEdited, errors, handleInput, handleCountrySelect, handleCountySelect, handlePhoneInput }) => {

  const fields = [
    { field: 'firstName', name: 'First Name' },
    { field: 'lastName', name: 'Last Name' },
    { field: 'email', name: 'Email' },
    { field: 'company', name: 'Company' },
    { field: 'addressLine1', name: 'Address Line 1' },
    { field: 'addressLine2', name: 'Address Line 2' },
    { field: 'city', name: 'City' },
    { field: 'county', name: 'County' },
    { field: 'country', name: 'Country' },
    { field: 'postcode', name: 'Postcode' },
    { field: 'phone', name: 'Phone' },
  ];
  return (
        <>
            {fields.map((field, index) => {

              if (field.field === 'country') {

                return <FormGroup row key={field.field + index}>
                        <Col md="4">
                            <Label className="text-secondary col-form-label"><strong>{field.name}</strong></Label>
                        </Col>
                        <Col md="8">
                            <CountryDropdown
                                valueType="short"
                                priorityOptions={['GB']}
                                value={contactEdited.country ? contactEdited.country : 'GB'}
                                classes={`form-control ${  errors[field.field] ? 'is-invalid' : ''}`}
                                onChange={handleCountrySelect}                              
                            />
                            {errors[field.field] &&
                                <div className="invalid-feedback">
                                    Please provide a valid {field.name}
                                </div>
                            }
                        </Col>
                    </FormGroup>;
                
              }
              if (field.field === 'county') {

                return <FormGroup row key={field.field + index + errors[field.field]}>
                        <Col md="4">
                            <Label className="text-secondary col-form-label"><strong>{field.name}</strong></Label>
                        </Col>
                        <Col md="8">
                            <RegionDropdown
                                key={`${errors[field.field]  }`}
                                value={contactEdited.county ? contactEdited.county : ''}
                                country={contactEdited.country ? contactEdited.country : 'GB'}
                                classes={`form-control ${  errors[field.field] ? 'is-invalid' : ''}`}
                                onChange={handleCountySelect}
                                countryValueType="short"
                            />
                            {errors[field.field] &&
                                <div className="invalid-feedback">
                                    Please provide a valid {field.name}
                                </div>
                            }
                        </Col>
                    </FormGroup>;
                
              }
              return (
                    <FormGroup row key={field.field + index}>
                        <Col md="4">
                            <Label
                                className="text-secondary col-form-label"
                            >
                                <strong>{field.name}</strong>
                            </Label>
                        </Col>
                        <Col md="8">
                            {field.field === 'phone' && (
                                <div className="form-group">
                                    <PhoneInput
                                        preferredCountries={['gb']}
                                        inputClass={errors[field.field] ? 'is-invalid w-100 phone-input-border-error' : 'w-100 form-control phone-input-border'}
                                        country="gb"
                                        label="Phone Number"
                                        value={contactEdited[field.field] && hasCountryCode(contactEdited[field.field]) ? contactEdited[field.field] : ''}
                                        readOnly={false}
                                        onChange={(phone) => {

                                          handlePhoneInput(phone); 

                                        }}
                                        error={errors[field.field]}
                                    />
                                    {errors[field.field] && (<span className="text-danger"><small>Invalid phone number</small></span>)}
                                </div>
                            )}
                            {field.field !== 'phone' && (
                                <>
                                    {' '}
                                  
                                    <Input
                                        className={`form-control ${  errors[field.field] ? 'is-invalid' : ''}`}
                                        id={field.field}
                                        value={contactEdited[field.field] ? contactEdited[field.field] : ''}
                                        onChange={handleInput}
                                    />
                                    {errors[field.field] && (
                                        <div className="invalid-feedback">
                                            Please provide a valid{' '}
                                            {field.name}
                                        </div>
                                    )}
                                </>
                            )}
                        </Col>
                    </FormGroup>
              );
            
            })}
        </>
  );

};
export default DomainContact;
