import moment from 'moment';
import {
  IServiceConnectivityState,
  SERVICE_CONNECTIVITY_RESET_DATA,
  SERVICE_CONNECTIVITY_SET_DATA,
  SERVICE_CONNECTIVITY_SET_GRAPH_DATA,
  SERVICE_CONNECTIVITY_SET_GRAPH_DATES,
  SERVICE_CONNECTIVITY_TOGGLE_GRAPH_DATA_LOADING,
  SERVICE_CONNECTIVITY_TOGGLE_LOADING,
  ServiceConnectivityReducerActions,
} from './types';

const initialState: IServiceConnectivityState = {
  loading: false,
  data: {
    id: '',
    name: '',
    type: '',
    aEnd: '',
    aEndLocation: '',
    bEnd: '',
    bEndLocation: '',
    bEndDeviceId: '',
    bearer: '',
    bandwidth: '',
    graphs: [],
    goLiveDate: '',
    billing: {
      install: 0,
      rental: 0,
      billedTo: '',
    },
  },
};

export function serviceConnectivityReducer(state = initialState, action: ServiceConnectivityReducerActions) {

  switch (action.type) {
    case SERVICE_CONNECTIVITY_SET_DATA:
      const graphs = action.data.graphs.map((graph) => ({
        ...graph,
        loading: false,
        dataIn: { label: 'In', data: [] },
        dataOut: { label: 'Out', data: [] },
        startDate: moment().startOf('day'),
        endDate: moment(),
        error: false,
      }));
      return {
        ...state,
        data: { ...action.data, graphs },
      };
    case SERVICE_CONNECTIVITY_TOGGLE_LOADING:
      return {
        ...state,
        loading: !state.loading,
      };
    case SERVICE_CONNECTIVITY_SET_GRAPH_DATA:
      const graphsUpdated = state.data.graphs.map((graph) => {

        if (graph.id === action.graphId) {

          graph.dataIn = action.dataIn;
          graph.dataOut = action.dataOut;
        
        }
        return graph;
      
      });
      return {
        ...state,
        data: { ...state.data, graphs: graphsUpdated },
      };
    case SERVICE_CONNECTIVITY_TOGGLE_GRAPH_DATA_LOADING:
      const graphsLoading = state.data.graphs.map((graph) => {

        if (graph.id === action.graphId) {

          graph.loading = !graph.loading;
        
        }
        return graph;
      
      });
      return {
        ...state,
        data: { ...state.data, graphs: graphsLoading },
      };
    case SERVICE_CONNECTIVITY_SET_GRAPH_DATES:
      const graphsMapped = state.data.graphs.map((graph) => {

        if (graph.id === action.graphId) {

          return { ...graph, startDate: action.startDate, endDate: action.endDate };
        
        }
        return { ...graph };
      
      });
      return {
        ...state,
        data: { ...state.data, graphs: graphsMapped },
      };
    case SERVICE_CONNECTIVITY_RESET_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }

}
