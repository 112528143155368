import React, { FunctionComponent } from 'react';
import ReactTooltip from 'react-tooltip';
import ButtonLink from '../../Buttons/ButtonLink';
import Section from '../../Section';
import SimpleTable from '../../Table/SimpleTable';
import { TCase } from '../../../store/case/types';
import SimpleLoader from '../../SimpleLoader';
import NoData from '../NoData';

interface IOwnProps {
  cases: TCase[];
  loading: boolean;
}
const OpenCasesTable: FunctionComponent<IOwnProps> = ({ cases, loading }) => {

  const rows = cases.map((item, index) => (
    <tr key={`Account Open Cases${index}`} className={`text-light${index < cases.length - 1 ? ' border-bottom' : ''}`}>
      <td className="pl-4" style={{ verticalAlign: 'middle', minWidth: '200px' }}>{item.ticketNumber}</td>
      <ReactTooltip />
      <td className="pl-4" style={{ verticalAlign: 'middle' }} data-tip={item.title.length > 50 ? item.title : ''} data-delay-show="250">{item.title.length > 50 ? `${item.title.substring(0, 50)}...` : item.title}</td>
      <td className="d-flex justify-content-end pr-4" style={{ minWidth: '150px' }}><ButtonLink color="light" text="View Case" textColor="white" url={`/cases/${item.id}`} /></td>
    </tr>
  ));
  return (
    <div>
      <Section
        title="Open Cases"
        children={(
          <SimpleLoader loading={loading}>
            {cases.length < 1 && (<NoData text="No open cases at present" />)}
            {cases.length > 0 && (<SimpleTable rows={rows} />)}
          </SimpleLoader>
)}
      />
    </div>
  );

};
export default OpenCasesTable;
