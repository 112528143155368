import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { Card, CardBody } from 'reactstrap';
import queryString from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';
import SimpleLoader from '../../components/SimpleLoader';
import { USER_LOGOUT } from '../../store/user/types';
import { setUserData } from '../../store/user/actions';
import { apm } from '../../index';
import { AppDispatch } from '../../configureStore';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const jwtDecode = require('jwt-decode');

type TParams = { token: string; refresh: string };
type TProps = {
  user: any;
  dispatch: AppDispatch;
};
type Props = TProps;

const Emulation: React.FC<Props> = (props) => {

  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = queryString.parse(location.search);
  const accessToken = jwtDecode(queryParams.token);
  const idToken = jwtDecode(queryParams.id_token);
  const [verified, setVerified] = useState(false);

  useEffect(() => {

    localStorage.removeItem('hso-api-token');
    localStorage.removeItem('hso-api-refresh-token');
    props.dispatch({ type: USER_LOGOUT });
    if (accessToken && idToken) {

      localStorage.setItem('hso-api-token', String(queryParams.token));
      localStorage.setItem('hso-api-id-token', String(queryParams.id_token));
      setVerified(true);
      props.dispatch(setUserData({
        username: idToken.name,
        email: idToken.email,
        permissions: accessToken.scopes,
      }));
      if (idToken.email) {

        apm.setUserContext({
          username: idToken.preferred_username,
          email: idToken.email.toLowerCase(),
        });
      
      }
      apm.setCustomContext({
        permissions: accessToken.scopes,
        role: accessToken.role,
      });
    
    }
  
  }, []);

  if (verified) {

    navigate('/');
  
  }
  return (
    <div className="animated fadeIn">
      <Card>
        <CardBody>
          <div className="app flex-row align-items-center animated fadeIn">
            <div className="container">
              <SimpleLoader loading>
                <div className="row flex-column justify-content-center align-items-center" style={{ height: '50%' }} />
              </SimpleLoader>
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );

};

function mapStateToProps({
  contact, account, user, system, contacts,
}) {

  return {
    user,
  };

}
export default connect(mapStateToProps)(Emulation);
