import { WEATHERMAPS_SET_WEATHERMAPS, TWeatherMapsActionTypes, IWeatherMapsState } from './types';

const initialState: IWeatherMapsState = {
  weatherMaps: [],
};

export const weatherMapsReducer = (state: IWeatherMapsState = initialState, action: TWeatherMapsActionTypes) => {

  switch (action.type) {
    case WEATHERMAPS_SET_WEATHERMAPS:
      return {
        ...state,
        weatherMaps: action.data,
      };
    default:
      return {
        ...state,
      };
  }

};
