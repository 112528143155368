/* eslint-disable */
import React, {Component} from 'react';
import {Card, CardBody, Col, Row} from "reactstrap";

class GenericNotFound extends Component {

  render() {

    return (
      <div className="app flex-row align-items-center">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6">
              <Card>
                <CardBody>
                  <Row>
                    <Col className='p-3 mb-4'>
                      <img className="img-fluid" alt='' src='../img/hso-email-header.png'/>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="mb-4">
                        <div style={{fontWeight: 600}} className="h4 text-dark text-center">Ooops! It seems you're
                          lost...
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="mb-4">
                        <div style={{fontWeight: 600}} className="h4 text-dark text-center">The page you are looking for
                          doesn't exist!
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

            </div>
          </div>
        </div>
      </div>
    );

  }
}

class ForbiddenError extends Component {
  render() {

    return (
      <div className="app flex-row align-items-center">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6">
              <div className="clearfix">
                <h1 className="float-left display-3 mr-4"><i className="fa fa-lock text-warning"/> 403</h1>
                <h4 className="pt-3">Forbidden</h4>
                <p className="text-muted">You are not allowed to see this page.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

  }
}

class ConnectionError extends Component {
  render() {

    return (
      <div className="app flex-row align-items-center">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6">
              <div className="clearfix">
                <h1 className="float-left display-3 mr-4"><i className="fa fa-chain-broken text-warning"/></h1>
                <h4 className="pt-3">Connection Error</h4>
                <p className="text-muted">There was an error connecting to a third party service. Please try again in a
                  while.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

  }
}

class FatalError extends Component {
  render() {

    return (
      <div className="app flex-row align-items-center">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6">
              <div className="clearfix">
                <h1 className="float-left display-3 mr-4"><i className="fa fa-warning text-danger"/></h1>
                <h4 className="pt-3">Fatal Error</h4>
                <p className="text-muted">Error Message: {this.props.message}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

  }
}

class TooManyRequests extends Component {
  render() {

    return (
      <div className="app flex-row align-items-center">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6">
              <div className="clearfix">
                <h1 className="float-left display-3 mr-4"><i className="fa fa-lock text-warning"/> 403</h1>
                <h4 className="pt-3">Too many requests</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

  }
}

export {ForbiddenError, ConnectionError, GenericNotFound, FatalError, TooManyRequests};
