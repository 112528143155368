import { connect } from 'react-redux';
import Header from './index';

function mapStateToProps({
  header, account, user, system,
}) {

  return {
    account,
    header,
    user,
    system,
  };

}

export default connect(mapStateToProps)(Header);
