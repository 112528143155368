import Push from 'push.js';
import toastr from 'toastr';
import { v4 } from 'uuid';
import update from 'immutability-helper';
import { ISocketIOToastState, SocketIOToastActionTypes, TOAST } from './types';

const initialState: ISocketIOToastState = {
  messages: [],
};

export const socketioToastReducer = (state: ISocketIOToastState = initialState, action: SocketIOToastActionTypes) => {

  switch (action.type) {
    case TOAST:
      const newMessage = state.messages;
      action.timestamp = new Date();
      newMessage.push(action);
      if (action.link) {

        toastr.options.onclick = () => {

          window.location = action.link;
        
        };
      
      } else {

        toastr.options.onclick = null;
      
      }
      toastr.options.positionClass = 'toast-bottom-right';
      const pushopts = {
        tag: v4(),
        body: action.msg,
        timeout: 10000,
        icon: '',
      };
      switch (action.msgtype) {
        case 'success':
          pushopts.icon = '/img/notifications/success.png';
          toastr.success(action.msg, action.title);
          break;
        case 'warning':
          pushopts.icon = '/img/notifications/warning.png';
          toastr.warning(action.msg, action.title);
          break;
        case 'error':
          pushopts.icon = '/img/notifications/error.png';
          toastr.error(action.msg, action.title);
          break;
        default:
          pushopts.icon = '/img/notifications/info.png';
          toastr.info(action.msg, action.title);
          break;
      }
      if (!action.disablePush) {

        Push.create(action.title, pushopts);
      
      }
      return update(
        state,
        {
          messages: { $set: newMessage },
        },
      );
    default:
      return state;
  }

};
