import {
  ISignatureSetDataAction,
  IToggleSignatureLoadingAction,
  SIGNATURE_SET_DATA,
  SIGNATURE_TOGGLE_LOADING,
} from './types';
import { api_getSignature } from '../../utils/Signature/Signature';
import { setSystemError } from '../system/actions';
import { defaultErrorFeedback } from '../../actions/feedback';

export function toggleSignatureLoading(): IToggleSignatureLoadingAction {

  return {
    type: SIGNATURE_TOGGLE_LOADING,
  };

}
function setSignature(data): ISignatureSetDataAction {

  return {
    type: SIGNATURE_SET_DATA,
    data,
  };

}
export function getSignature(signatureId) {

  return (dispatch) => api_getSignature(signatureId).then((result) => {

    if (result.status === 200) {

      dispatch(setSignature(result.data));
    
    } else if (result.data.error && result.data.error.id) {

      dispatch(setSystemError(result.data.error.id));
    
    } else {

      dispatch(defaultErrorFeedback());
    
    }
  
  });

}
