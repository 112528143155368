import {
  BILLING_SET_ARTIFACT_MODAL_BILL,
  BILLING_SET_BILLS, BILLING_SET_SEARCH_STRING,
  BILLING_TOGGLE_ARTIFACT_MODAL,
  BILLING_TOGGLE_LOADING,
  TBill,
} from './types';
import { setSystemError } from '../system/actions';
import { api_getBills } from '../../utils/Billing';
import { defaultErrorFeedback } from '../../actions/feedback';

export function toggleBillingLoading() {

  return {
    type: BILLING_TOGGLE_LOADING,
  };

}
export function toggleArtifactsModal() {

  return {
    type: BILLING_TOGGLE_ARTIFACT_MODAL,
  };

}

export function setArtifactModalBill(id: number) {

  return {
    type: BILLING_SET_ARTIFACT_MODAL_BILL,
    id,
  };

}

export function setBillingSearchString(search: string) {

  return {
    type: BILLING_SET_SEARCH_STRING,
    search,
  };

}

function setBills(bills: TBill) {

  return {
    type: BILLING_SET_BILLS,
    bills,
  };

}

export function getAccountBills() {

  return (dispatch) => api_getBills().then((result) => {

    if (result.status === 200) {

      dispatch(setBills(result.data));
    
    } else if (result.data.error && result.data.error.id) {

      dispatch(setSystemError(result.data.error.id));
    
    } else {

      dispatch(defaultErrorFeedback());
    
    }
  
  });

}
