import React from 'react';
import SimpleTable from '../../../components/Table/SimpleTable';
import { IMonitoredItemNTPingData, IMonitoredItemNTTraceRouteData } from '../../../store/monitoredItem/types';

interface TOwnProps {
  data: IMonitoredItemNTTraceRouteData;
}

const TraceRouteTable: React.FC<TOwnProps> = ({ data }) => {

  const header = (
    <tr className="text-light font-weight-bold h5">
      <th>TTL</th>
      <th>IP</th>
      <th>RTT 1</th>
      <th>RTT 2</th>
      <th>RTT 3</th>
    </tr>
  );

  const rows = data.hops.map((hop, index) => {

    const hops = hop.results.map((result, i) => (
      <td key={`row-${i}`}>{(result.result && result.rtt) ? `${result.rtt / 1000}ms` : '*' }</td>
    ));

    while (hops.length < 3) {

      hops.push(<td />);
    
    }

    return (
      <tr key={`hop-${index}`} className="text-light pl-1 pr-2 b-t-1">
        <td>{hop.ttl}</td>
        <td>{hop.ip}</td>
        {hops}
      </tr>
    );
  
  });

  return (
    <SimpleTable rows={rows} withHeader header={header} bordered />
  );

};

export default TraceRouteTable;
