import React, { FunctionComponent } from 'react'
import { Link, matchPath, useLocation, useMatch } from 'react-router-dom'
import { Breadcrumb, BreadcrumbItem } from 'reactstrap'
import routes from '../../routes'

const findRouteName = (url: string): string|undefined => routes[url]

const getPaths = (pathname: string): string[] => {

    const paths = ['/']

    if (pathname === '/') return paths

    pathname.split('/')
        .reduce((prev, curr, index) => {

            const currPath = `${prev}/${curr}`
            paths.push(currPath)
            return currPath

        })
    return paths

}

type TBreadcrumbsItemProps = {
    path: string
}
const BreadcrumbsItem: FunctionComponent<TBreadcrumbsItemProps> = ({ path }) => {

    const { pathname } = useLocation()
    const routeName = findRouteName(path)
    const matches = matchPath(path, pathname)

    if (routeName) {

        return (
            matches ?
                (
                    <BreadcrumbItem active>{routeName}</BreadcrumbItem>
                ) :
                (
                    <BreadcrumbItem>
                        <Link to={path || ''}>
                            {routeName}
                        </Link>
                    </BreadcrumbItem>
                )
        )

    }
    return null

}

const Breadcrumbs = () => {

    const location = useLocation()
    const paths = getPaths(location.pathname)

    return (
        <Breadcrumb>
            {paths.map((path, i) => (
                <BreadcrumbsItem key={i} path={path} />
            ))}
        </Breadcrumb>
    )

}

export default Breadcrumbs
