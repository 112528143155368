import { connect } from 'react-redux';
import Services from './index';

function mapStateToProps({ services, user, account }) {

  return {
    services,
    user,
    account,
  };

}
export default connect(mapStateToProps)(Services);
