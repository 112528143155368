import { IFormError } from '../../components/Forms/types';

export const CONTACT_SET_CONTACT = 'CONTACT_SET_CONTACT';
export const CONTACT_SET_ORIGINAL_DATA = 'CONTACT_SET_ORIGINAL_DATA';
export const CONTACT_RESET_CONTACT = 'CONTACT_RESET_CONTACT';
export const CONTACT_INIT_CONTACT = 'CONTACT_INIT_CONTACT';
export const CONTACT_UPDATE_CONTACT = 'CONTACT_UPDATE_CONTACT';
export const CONTACT_TOGGLE_READ_WRITE = 'CONTACT_TOGGLE_READ_WRITE';
export const CONTACT_UPDATE_PORTAL_USER = 'CONTACT_UPDATE_PORTAL_USER';
export const CONTACT_SET_ERRORS = 'CONTACT_SET_ERRORS';
export const CONTACT_TOGGLE_LOADING = 'CONTACT_TOGGLE_LOADING';
export const CONTACT_TOGGLE_INVITE_MODAL = 'CONTACT_TOGGLE_INVITE_MODAL';
export const CONTACT_SET_ERROR_MESSAGE = 'CONTACT_SET_ERROR_MESSAGE';
export const CONTACT_RESET_ERROR_MESSAGE = 'CONTACT_RESET_ERROR_MESSAGE';
export const CONTACT_SET_FORM_READ_ONLY = 'CONTACT_SET_FORM_READ_ONLY';
export const CONTACT_ADD_SITE_TYPE = 'CONTACT_ADD_SITE_TYPE';
export const CONTACT_REMOVE_SITE_TYPE = 'CONTACT_REMOVE_SITE_TYPE';
export const CONTACT_SYNC_TYPE_TO_ALL_SITE = 'CONTACT_SYNC_TYPE_TO_ALL_SITE';
export const CONTACT_SET_SITES = 'CONTACT_SET_SITES';
export const CONTACT_SET_SITE = 'CONTACT_SET_SITE';

export interface IContactState {
  loading: boolean;
  readOnly: boolean;
  inviteModalOpen: boolean;
  contactTypes: Array<TContactType>;
  portalUserRoles: Array<TPortalUserRole>;
  data: TContact;
  dataOriginal: TContact;
  errors: Array<IFormError>;
  errorMessage: string | null;

}

export type TContact = {
  name: string;
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  phone: string;
  contactTypes: Array<number>;
  portalUser?: TPortalUser;
  id?: string | null;
  contactTypeNames?: Array<string>;
  statusNotificationEmail?: string;
  statusNotificationMobile?: string;
  statusAlerts?: boolean;
  sites: TContactSite[];
  isActive: boolean
};

export type TPortalUser = {
  username: string;
  email: string;
  active: boolean;
  role: string;
  roleId: number;
  lastLogin: string | null;
};

export type TPortalUserRole = {
  roleId: number;
  role: string;
};

export type TContactType = {
  id: number;
  name: string;
};

export type TContactSite = {
  id: string;
  type: number;
};

export interface ISetContactAction {
  type: typeof CONTACT_SET_CONTACT;
  data: TContact;
}

export interface ISetContactOriginalDataAction {
  type: typeof CONTACT_SET_ORIGINAL_DATA;
}

export interface IInitContactAction {
  type: typeof CONTACT_INIT_CONTACT;
}

export interface IToggleContactReadWriteAction {
  type: typeof CONTACT_TOGGLE_READ_WRITE;
}

export interface IUpdateContactDataAction {
  type: typeof CONTACT_UPDATE_CONTACT;
  data: object;
}

export interface IResetContactDataAction {
  type: typeof CONTACT_RESET_CONTACT;
}

type TUserRoleAction = {
  roleId: number;
  role: string;
};
export interface IUpdatePortalUserAction {
  type: typeof CONTACT_UPDATE_PORTAL_USER;
  data: TUserRoleAction;
}

export interface ISetContactErrorsAction {
  type: typeof CONTACT_SET_ERRORS;
  errors: Array<IFormError>;
}

export interface IToggleContactLoadingAction {
  type: typeof CONTACT_TOGGLE_LOADING;
}

export interface IToggleInviteModalAction {
  type: typeof CONTACT_TOGGLE_INVITE_MODAL;
}
export interface ISetContactErrorMessageAction {
  type: typeof CONTACT_SET_ERROR_MESSAGE;
  error: string;
}

export interface IResetContactErrorMessageAction {
  type: typeof CONTACT_RESET_ERROR_MESSAGE;
}
export interface ISetFormReactOnlyAction {
  type: typeof CONTACT_SET_FORM_READ_ONLY;
  payload: boolean;
}

export interface IAddContactSiteType {
  type: typeof CONTACT_ADD_SITE_TYPE;
  data: TContactSite;

}

export interface IRemoveContactSiteType {
  type: typeof CONTACT_REMOVE_SITE_TYPE;
  data: TContactSite;
}

export interface ISyncTypeToAllSites {
  type: typeof CONTACT_SYNC_TYPE_TO_ALL_SITE;
  sites: TContactSite[];
}

export interface ISetAllContactSites {
  type: typeof CONTACT_SET_SITES;
  sites: [];
}

export interface ISetContactSiteWithTypes {
  type: typeof CONTACT_SET_SITE;
  site: string;
  types: [];
}

export type ContactActionTypes =

    ISetContactAction |
    IInitContactAction |
    IToggleContactReadWriteAction |
    IUpdateContactDataAction |
    IResetContactDataAction |
    IUpdatePortalUserAction |
    ISetContactErrorsAction |
    IToggleContactLoadingAction |
    IToggleInviteModalAction |
    ISetContactErrorMessageAction |
    IResetContactErrorMessageAction |
    ISetContactOriginalDataAction |
    ISetFormReactOnlyAction |
    IAddContactSiteType |
    IRemoveContactSiteType |
    ISyncTypeToAllSites |
    ISetAllContactSites |
    ISetContactSiteWithTypes;
