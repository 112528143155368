import {
  ISignatureState, SIGNATURE_SET_DATA, SIGNATURE_TOGGLE_LOADING, SignatureActionTypes,
} from './types';

const initialState: ISignatureState = {
  loading: false,
  data: {
    signUrl: '',
    expiresAt: undefined,
  },
};

export function signatureReducer(state = initialState, action: SignatureActionTypes) {

  switch (action.type) {
    case SIGNATURE_SET_DATA:
      return {
        ...state,
        data: action.data,
      };
    case SIGNATURE_TOGGLE_LOADING:
      return {
        ...state,
        loading: !state.loading,
      };
    default:
      return state;
  }

}
