export const SERVICE_VEEAM_BACKUP_RESET_DATA = 'SERVICE_VEEAM_BACKUP_RESET_DATA';
export const SERVICE_VEEAM_BACKUP_SET_DATA = 'SERVICE_VEEAM_BACKUP_SET_DATA';

export type TServiceVeeamBackup = {
  id: string;
  productCode: string;
  name: string;
  storageQuota: number;
  usedStorageQuota: number;
  vms: number;
  rentalPeriod: number;
  billing: {
    install: number;
    rental: number;
    billedTo: string;
  };
};

export interface IResetVeeamBackupDataAction {
  type: typeof SERVICE_VEEAM_BACKUP_RESET_DATA;
}

export interface ISetVeeamBackupDataAction {
  type: typeof SERVICE_VEEAM_BACKUP_SET_DATA;
  data: TServiceVeeamBackup;
}

export interface IServiceVeeamBackupState {
  data: TServiceVeeamBackup;
}

export type VeeamBackupReducerActions = IResetVeeamBackupDataAction | ISetVeeamBackupDataAction;
