import React from 'react';
import { Badge, Button, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import moment from 'moment';
import { updatedDiff } from 'deep-object-diff';
import { isExpiring, isExpired } from '../../../helpers/domains/domains';

const DomainInfo = ({ domain, domainEdited, account, toggleRenew, save, restore }) => {

  const expiring = isExpiring(domain);
  const changed = Object.keys(updatedDiff(domain, domainEdited)).length > 0;
  return (
    <>
      <FormGroup row className="mb-1">
        <Col md="4">
          <Label className="text-secondary col-form-label"><strong>Status</strong></Label>
        </Col>
        <Col md="8" className="d-flex flex-row-reverse">
          <h4><Badge color={expiring ? 'danger' : 'success'} className="text-white" style={{ padding: '5px' }}>{expiring ? 'Expiring' : 'Active'}</Badge></h4>
        </Col>
      </FormGroup>
      <FormGroup row className="mb-1">
        <Col md="4">
          <Label className="text-secondary col-form-label"><strong>Registered</strong></Label>
        </Col>
        <Col md="8" className="d-flex flex-row-reverse">
          <p className="form-control border-0 pr-0 mb-0 text-right">{moment(domain.createdAt).format('DD/MM/YYYY')}</p>
        </Col>
      </FormGroup>
      <FormGroup row className="mb-1">
        <Col md="4">
          <Label className="text-secondary col-form-label"><strong>Expires</strong></Label>
        </Col>
        <Col md="8" className="d-flex flex-row-reverse">
          <p className="form-control border-0 pr-0 mb-0 text-right">{moment(domain.expireAt).format('DD/MM/YYYY')}</p>
        </Col>
      </FormGroup>
      {!account.cardPayer
            && (
            <>

              {!isExpired(domain) && (
              <FormGroup row className="mb-1">
                <Col md="4">
                  <Label className="text-secondary col-form-label"><strong>Auto Renew</strong></Label>
                </Col>
                <Col md="8" className="d-flex flex-row-reverse">
                  <Label
                    className="switch switch-text switch-lg switch-pill switch-primary-outline-alt"
                  >
                    <Input type="checkbox" className="switch-input" checked={domainEdited.autoRenew} onChange={toggleRenew} />
                    <span
                      className="switch-label"
                      data-on="On"
                      data-off="Off"
                    />
                    <span className="switch-handle" />
                  </Label>
                </Col>
              </FormGroup>
              )}
              <Row className="mt-3">
                <Col md="4">
                  <Button className={changed ? 'mr-3' : ''} disabled={!changed} onClick={save}>Update</Button>
                  {changed && <Button outline color="warning" onClick={restore}>Cancel</Button>}
                </Col>
              </Row>
            </>
            )}

    </>
  );

};

export default DomainInfo;
