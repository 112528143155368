import { defaultErrorFeedback, successFeedback } from '../../actions/feedback';
import { api_deleteReport, api_getReports } from '../../utils/Reports';
import {
  REPORTS_ADD_REPORT_TO_LIST,
  REPORTS_REMOVE_REPORT_FROM_LIST,
  REPORTS_SET_LIST,
  REPORTS_UPDATE_REPORT_IN_LIST,
} from './types';
import { api_updateDeviceReport } from '../../utils/Monitoring/Monitoring';

export const TEST = 'TEST';

const setReports = (list) => ({
  type: REPORTS_SET_LIST,
  list,
});

const removeReportFromList = (id) => ({
  type: REPORTS_REMOVE_REPORT_FROM_LIST,
  id,
});

export const updateReportInList = (report) => ({
  type: REPORTS_UPDATE_REPORT_IN_LIST,
  report,
});

export function getReports() {

  return (dispatch) => api_getReports().then((result) => {

    if (result.status === 200) {

      dispatch(setReports(result.data));
    
    } else {

      dispatch(defaultErrorFeedback());
    
    }
  
  });

}

export function deleteReport(id) {

  return (dispatch) => api_deleteReport(id).then((result) => {

    if (result.status === 200) {

      dispatch(removeReportFromList(id));
      dispatch(successFeedback('Report deleted.'));
    
    } else {

      dispatch(defaultErrorFeedback());
    
    }
  
  });

}
