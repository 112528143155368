import React from 'react';

interface IProps {
  cssClass?: string;
  cssClassTop?: string;
  title?: string;
}

const BillingIcon: React.FC<IProps> = ({ cssClass, cssClassTop, title }) => (
  <svg
    className={cssClassTop || 'cls-1'}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 19.93 14.95"
  >
    <title>{title || ''}</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Design">
        <path
          className={cssClass || 'cls-1'}
          d="M19.45.49A1.6,1.6,0,0,0,18.27,0H1.66A1.56,1.56,0,0,0,.49.49,1.56,1.56,0,0,0,0,1.66V13.29a1.59,1.59,0,0,0,.49,1.17A1.6,1.6,0,0,0,1.66,15H18.27a1.66,1.66,0,0,0,1.66-1.66V1.66A1.59,1.59,0,0,0,19.45.49Zm-.85,12.8a.33.33,0,0,1-.09.23.37.37,0,0,1-.24.1H1.66a.33.33,0,0,1-.23-.1.3.3,0,0,1-.1-.23V6.91H18.6v6.38Zm0-9.3H1.33V1.66a.29.29,0,0,1,.1-.23.29.29,0,0,1,.23-.1H18.27a.33.33,0,0,1,.24.1.32.32,0,0,1,.09.23V4Z"
        />
        <rect
          className={cssClass || 'cls-1'}
          x="2.66"
          y="10.96"
          width="2.66"
          height="1.33"
        />
        <rect
          className={cssClass || 'cls-1'}
          x="6.64"
          y="10.96"
          width="3.99"
          height="1.33"
        />
      </g>
    </g>
  </svg>
);

export default BillingIcon;
