import { apiRequest } from '../Api/ApiRequest';

export function api_getZones() {

  return apiRequest({
    url: '/services/dns',
    method: 'get',
    headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
  }).then(
    resp => resp,
  ).catch(
    error => error,
  );

}

export function api_createNewZone(name, type) {

  return apiRequest({
    url: '/services/dns',
    method: 'post',
    headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
    data: { name, type },
  }).then(
    resp => resp,
  ).catch(
    error => error,
  );

}

export function getZoneRecord(id) {

  return apiRequest({
    url: `/services/dns/${id}`,
    method: 'get',
    headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
  }).then(
    resp => resp,
  ).catch(
    error => error,
  );

}

export function updateZoneRecord(zoneId, recordId, data) {

  return apiRequest({
    url: `/services/dns/${zoneId}/record/${recordId}`,
    method: 'put',
    headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
    data: { ...data },
  }).then(
    resp => resp,
  ).catch(
    error => error,
  );

}

export function deleteZoneRecord(zoneId, recordId) {

  return apiRequest({
    url: `/services/dns/${zoneId}/record/${recordId}`,
    method: 'delete',
    headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
  }).then(
    resp => resp,
  ).catch(
    error => error,
  );

}

export function deleteZone(zoneId) {

  return apiRequest({
    url: `/services/dns/${zoneId}`,
    method: 'delete',
    headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
  }).then(
    resp => resp,
  ).catch(
    error => error,
  );

}

export function createNewZoneRecord(zoneId, type, record) {

  return apiRequest({
    url: `/services/dns/${zoneId}/record`,
    method: 'post',
    headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
    data: { type, ...record },
  }).then(
    resp => resp,
  ).catch(
    error => error,
  );

}
