import React from 'react';

interface IProps {
  cssClass?: string;
  title?: string;
}

const CloudUp: React.FC<IProps> = ({ cssClass, title }) => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 500 625"
    enableBackground="new 0 0 500 500"
    className={cssClass}
  >
    <g>
      <g>
        <path d="M405.1,250c-0.3,64.2-40.9,123.8-101.6,145.8c-30.7,11.1-64.3,12.5-95.7,3.7    c-30.7-8.6-57.9-26.8-78.2-51.3c-41.4-50-46-123.3-12.5-178.6c33.4-55.1,99.8-84.7,163.1-71.9c63.6,12.9,113,64.7,123.2,128.7    C404.6,234.2,405.1,242.1,405.1,250c0.1,12.9,20.1,12.9,20,0c-0.3-73.1-46.6-139.2-115.1-164.6c-68.5-25.4-148.8-3.6-195.1,52.8    c-23,28.1-36.9,62.3-39.8,98.5c-2.9,36,5.9,72.9,24.6,103.8c38.2,63.2,113.5,95.7,185.7,81.1c70.1-14.2,126.7-73.2,137.4-144    c1.4-9.1,2.2-18.3,2.2-27.6C425.1,237.1,405.1,237.1,405.1,250z" />
      </g>
    </g>
    <g>
      <g>
        <polygon points="353.1,188.6 230.4,311.3 209.2,332.6 187.9,311.3 166.7,290.1 146.9,270.3 168.1,249.1     209.2,290.1 331.9,167.4   " />
        <g>
          <path d="M346,181.6c-18,18-36,36-54,54c-22.1,22.1-44.3,44.3-66.4,66.4c-7.9,7.9-15.7,15.7-23.6,23.6     c4.7,0,9.4,0,14.1,0c-15.5-15.6-31.1-31.1-46.6-46.7c-5.2-5.2-10.4-10.4-15.6-15.6c0,4.7,0,9.4,0,14.1     c7.1-7.1,14.1-14.1,21.2-21.2c-4.7,0-9.4,0-14.1,0c13.7,13.7,27.3,27.3,41,41c3.8,3.8,10.3,3.8,14.1,0     c13.9-13.9,27.7-27.7,41.6-41.6c22-22,43.9-43.9,65.9-65.9c5.1-5.1,10.2-10.2,15.2-15.2c-4.7,0-9.4,0-14.1,0     c7.1,7.1,14.1,14.1,21.2,21.2c9.1,9.1,23.3-5,14.1-14.1c-7.1-7.1-14.1-14.1-21.2-21.2c-3.8-3.8-10.3-3.8-14.1,0     c-13.9,13.9-27.7,27.7-41.6,41.6c-22,22-43.9,43.9-65.9,65.9c-5.1,5.1-10.2,10.2-15.2,15.2c4.7,0,9.4,0,14.1,0     c-13.7-13.7-27.3-27.3-41-41c-3.8-3.8-10.3-3.8-14.1,0c-7.1,7.1-14.1,14.1-21.2,21.2c-3.8,3.8-3.8,10.3,0,14.1     c15.7,15.8,31.5,31.5,47.2,47.2c5,5,10,10,15,15c3.8,3.8,10.3,3.8,14.1,0c15.1-15.1,30.1-30.1,45.2-45.2     c26.1-26.1,52.2-52.2,78.3-78.3c6.8-6.8,13.6-13.6,20.4-20.4C369.2,186.6,355.1,172.5,346,181.6z" />
        </g>
      </g>
      <g>
        <g>
          <path d="M146.9,260.3C146.9,260.3,146.9,260.3,146.9,260.3c-1.4-0.1-2.7,0.2-3.9,0.9c-1.2,0.4-2.3,1.1-3.2,2.1     c-1,0.9-1.7,2-2.1,3.2c-0.6,1.2-0.9,2.5-0.9,3.9c0.1,0.9,0.2,1.8,0.4,2.7c0.5,1.7,1.3,3.2,2.6,4.4c2,1.8,4.4,2.9,7.1,2.9     c0,0,0,0,0,0c1.4,0.1,2.7-0.2,3.9-0.9c1.2-0.4,2.3-1.1,3.2-2.1c1-0.9,1.7-2,2.1-3.2c0.6-1.2,0.9-2.5,0.9-3.9     c-0.1-0.9-0.2-1.8-0.4-2.7c-0.5-1.7-1.3-3.2-2.6-4.4C152.1,261.5,149.6,260.3,146.9,260.3L146.9,260.3z" />
        </g>
      </g>
      <g>
        <g>
          <path d="M146.9,260.3C146.9,260.3,146.9,260.3,146.9,260.3c-1.4-0.1-2.7,0.2-3.9,0.9c-1.2,0.4-2.3,1.1-3.2,2.1     c-1,0.9-1.7,2-2.1,3.2c-0.6,1.2-0.9,2.5-0.9,3.9c0.1,0.9,0.2,1.8,0.4,2.7c0.5,1.7,1.3,3.2,2.6,4.4c2,1.8,4.4,2.9,7.1,2.9     c0,0,0,0,0,0c1.4,0.1,2.7-0.2,3.9-0.9c1.2-0.4,2.3-1.1,3.2-2.1c1-0.9,1.7-2,2.1-3.2c0.6-1.2,0.9-2.5,0.9-3.9     c-0.1-0.9-0.2-1.8-0.4-2.7c-0.5-1.7-1.3-3.2-2.6-4.4C152.1,261.5,149.6,260.3,146.9,260.3L146.9,260.3z" />
        </g>
      </g>
    </g>
  </svg>
);

export default CloudUp;
