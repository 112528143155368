/* eslint-disable */
import React from 'react';
import {PropTypes} from 'prop-types';
import {Card, CardHeader, CardBody, Button, FormGroup, Label, FormFeedback, Row, Col} from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import TextEditor from '../../components/TextEditor/TextEditor';
import Section from '../../components/Section';

const GenericCase = (props) => {

  return (
        <Section title="Generic Case">
            <Row className="mx-0">
                <Col className="p-4 bg-white rounded">
                <AvForm onValidSubmit={props.handleValidSubmit} onInvalidSubmit={props.onInvalidSubmit}>
                    <FormGroup>
                        <Label for="caseTitle">Title</Label>
                        <AvField type="text" name="caseTitle" id="caseTitle" placeholder="Title of the case" required validate={{maxLength: {value: 200}}}/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="caseCategory">Category</Label>
                        <AvField type="select" name="caseCategory" id="caseCategory" required>
                            <option value="">-- Select</option>
                            <option value="Service Affecting">Service Affecting</option>
                            <option value="Non Service Affecting">Non Service Affecting</option>
                        </AvField>
                    </FormGroup>
                    <FormGroup>
                        <Label for="casePriority">Priority</Label>
                        <AvField type="select" name="casePriority" id="casePriority" required>
                            <option value="">-- Select</option>
                            <option value="low">Low</option>
                            <option value="medium">Medium</option>
                            <option value="high">High</option>
                        </AvField>
                    </FormGroup>
                    <FormGroup>
                        <Label for="caseDevice">Device ID</Label>
                        <AvField type="text" name="caseDevice" id="caseDevice" placeholder="The device ID if known (e.g. cr1.123456.uk)" />
                    </FormGroup>
                    <FormGroup>
                        <Label for="caseSite">Site</Label>
                        <AvField type="text" name="caseSite" id="caseSite" placeholder="The site relevant to this query" />
                    </FormGroup>
                    <FormGroup color={props.descError ? 'danger' : null}>
                        <Label>Description</Label>
                        <TextEditor callback={props.callback} color={props.descError ? 'danger' : null}/>
                        {props.descError && <FormFeedback>Description is a required field</FormFeedback>}
                    </FormGroup>
                    <div className="clearfix" style={{marginTop: `${10}px`, marginBottom: `${20}px`}}>
                        {/* <Button type="submit" color="primary" className="float-right" style={{minWidth: 113+'px', minHeight: 35+'px'}} disabled={props.processing}>{props.processing ?
                            <div style={{marginTop: 2+'px'}}><Loader color="#ffffff" size="8px" margin="0px"/></div> : "Create Case"}
                            </Button> */}
                        <LaddaButton
                            style={{minWidth: `${113}px`, minHeight: `${35}px`}}
                            className="btn btn-primary btn-ladda px-4 float-right"
                            disabled={props.processing}
                            loading={props.processing}
                            data-color="blue"
                            data-style={ZOOM_OUT}
                        >
                            Create Case
                        </LaddaButton>
                    </div>
                </AvForm>
                </Col>
            </Row>
        </Section>
  );

};
GenericCase.propTypes = {
  handleValidSubmit: PropTypes.func.isRequired,
  onInvalidSubmit: PropTypes.func.isRequired,
  callback: PropTypes.func.isRequired,
  descError: PropTypes.bool.isRequired,
  processing: PropTypes.bool.isRequired,
};
export default GenericCase;
