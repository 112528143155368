import React from 'react';

interface INoData {
  text: string;
}
const noData: React.FC<INoData> = ({ text }) => (
  <div className="no-data-widget align-items-center justify-content-center d-flex">
    <span className="p-2">{text}</span>
  </div>
);
export default noData;
