import {
  SERVICE_ORDERS_ADD_ITEM, SERVICE_ORDERS_REMOVE_ITEM,
  SERVICE_ORDERS_RESET,
  SERVICE_ORDERS_RESET_CURRENT,
  SERVICE_ORDERS_SET_CURRENT,
  SERVICE_ORDERS_SET_CURRENT_FIELD,
  SERVICE_ORDERS_SET_FIELD,
  ServiceOrderActionTypes,
  TPreSalesServiceOrderState,
} from './types';

const initialState: TPreSalesServiceOrderState = {
  type: 'services',
  purchaseToken: '',
  referenceNumber: undefined,
  billingContactId: '',
  signatoryContactId: '',
  items: [],
  status: 'in progress',
  currentItem: undefined,
  originalCurrentItem: undefined,
};

export const serviceOrderReducer = (state: TPreSalesServiceOrderState = initialState, action: ServiceOrderActionTypes) => {

  switch (action.type) {
    case SERVICE_ORDERS_SET_FIELD:
      return {
        ...state,
        [action.field]: action.value,
      };
    case SERVICE_ORDERS_ADD_ITEM:
      return {
        ...state,
        items: state.items.concat(action.data),
      };
    case SERVICE_ORDERS_REMOVE_ITEM:
      return {
        ...state,
        items: state.items.filter((item, i) => i !== action.index),
      };
    case SERVICE_ORDERS_SET_CURRENT:
      const currentItem = { ...action.data };
      if (currentItem.type === 'unit') {

        currentItem.quantity = (currentItem.quantity || 0) + 1;
      
      }
      return {
        ...state,
        currentItem,
        originalCurrentItem: { ...action.data },
      };
    case SERVICE_ORDERS_RESET_CURRENT:
      return {
        ...state,
        currentItem: undefined,
        originalCurrentItem: undefined,
      };
    case SERVICE_ORDERS_SET_CURRENT_FIELD:
      return {
        ...state,
        currentItem: {
          ...state.currentItem,
          [action.field]: action.value,
        },
      };
    case SERVICE_ORDERS_RESET:
      return {
        ...initialState,
      };
    default:
      return {
        ...state,
      };
  }

};
