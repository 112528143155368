import moment from 'moment';
import {
  ISignaturesState,
  SIGNATURES_SET_DATA,
  SIGNATURES_SET_SEARCH_STRING,
  SIGNATURES_UPDATE,
  SignaturesActionTypes,
} from './types';

const initialState: ISignaturesState = {
  filtered: [],
  searchString: '',
  signatures: [],
};

export const signaturesReducer = (state = initialState, action: SignaturesActionTypes) => {

  switch (action.type) {
    case SIGNATURES_SET_SEARCH_STRING:
      return {
        ...state,
        searchString: action.searchString,
        filtered: state.signatures.filter((signature) => {

          const relatedTo = ('title' in signature.relatedTo) ? signature.relatedTo.title : signature.relatedTo.name;
          return String(signature.title).toLowerCase().indexOf(action.searchString.toLowerCase()) !== -1
            || signature.signatories.find(signatory => String(signatory.signatory.name).toLowerCase().indexOf(action.searchString.toLowerCase()) !== -1)
            || String(relatedTo).toLowerCase().indexOf(action.searchString.toLowerCase()) !== -1
            || String(signature.status).toLowerCase().indexOf(action.searchString.toLowerCase()) !== -1
            || moment(signature.updatedAt).format('DD/MM/YYYY H:m:s').indexOf(action.searchString.toLowerCase()) !== -1;

        }),
      };
    case SIGNATURES_UPDATE:
      return {
        ...state,
        signatures: state.signatures.map((signature) => {

          if (signature.id === action.data.id) {

            return {
              ...signature,
              ...action.data
            };

          }
          return signature;

        }),
      };
    case SIGNATURES_SET_DATA:
      return {
        ...state,
        signatures: action.data,
      };
    default:
      return { ...state };
  }

};
