import { TDomain } from '../domain/types';

export const DOMAINS_SET_ACCOUNT_DOMAINS = 'DOMAINS_SET_ACCOUNT_DOMAINS';
export const DOMAINS_TOGGLE_LOADING = 'DOMAINS_TOGGLE_LOADING';
export const DOMAINS_SET_SEARCH_STRING = 'DOMAINS_SET_SEARCH_STRING';
export const DOMAINS_SET_LOOKUP_STRING = 'DOMAINS_SET_LOOKUP_STRING';
export const DOMAINS_RESET_STORE = 'DOMAINS_RESET_STORE';

export interface IDomainsState {
  loading: boolean;
  searchString: string;
  lookupString: string;
  domains: TDomain[];
  filtered: TDomain[];

}
export interface IDomainsResetStore {
  type: typeof DOMAINS_RESET_STORE;
}
export interface ISetAccountDomainsAction {
  type: typeof DOMAINS_SET_ACCOUNT_DOMAINS;
  domains: TDomain[];
}

export interface IToggleDomainsLoadingAction {
  type: typeof DOMAINS_TOGGLE_LOADING;
}

export interface ISetDomainsSearchStringAction {
  type: typeof DOMAINS_SET_SEARCH_STRING;
  searchString: string;
}

export interface ISetDomainLookupStringAction {
  type: typeof DOMAINS_SET_LOOKUP_STRING;
  lookupString: string;
}

export type DomainsActionTypes = ISetAccountDomainsAction | IToggleDomainsLoadingAction | ISetDomainsSearchStringAction | ISetDomainLookupStringAction | IDomainsResetStore;
