import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {Alert, Button, Col, Row} from 'reactstrap';
import { detailedDiff, diff } from 'deep-object-diff';
import { isEmail } from 'validator';
import isEmpty from 'lodash.isempty';
import { useNavigate, useParams } from 'react-router-dom';
import { IContactState, TContactSite } from '../../store/contact/types';

import {
  createContact,
  getContactData,
  initContact,
  inviteContact,
  resetContactData,
  saveContactData,
  setContactErrors,
  toggleContactLoading,
  toggleContactReadWrite,
  togglePortalInviteModal,
  updateContactData,
  updatePortalUserData,
  setContactErrorMessage,
  resetContactErrorMessage,
  deleteContact,
  syncTypeToAllSites,
  updateContactSites,
  setSites,
  setSite, restoreContact,
} from '../../store/contact/actions';
import { isUkPhoneNumber } from '../../helpers/phoneNumber/phoneNumber';
import Section from '../../components/Section';
import DeleteIconButton from '../../components/Buttons/DeleteIconButton';
import EditIconButton from '../../components/Buttons/EditIconButton';
import SaveIconButton from '../../components/Buttons/SaveIconButton';
import CancelIconButton from '../../components/Buttons/CancelIconButton';
import { ICheckBoxOption } from '../../components/Inputs/CheckBoxList';
import { IAccountState, TAccountSite } from '../../store/account/types';
import { ISelectInputOptions } from '../../components/Inputs/SelectInput';
import ReadContactForm from '../../components/Forms/Contact/ReadContactForm';
import EditContactForm from '../../components/Forms/Contact/EditContactForm';
import { IFormError } from '../../components/Forms/types';
import SimpleLoader from '../../components/SimpleLoader';
import { checkPermission } from '../../utils/Auth/AuthService';
import { ForbiddenError } from '../../components/Errors/Errors';
import PortalInviteModal from '../../components/Modals/PortalInviteModal';
import { resetSystemSuccessMessage, setSystemConfirmation, resetSystemConfirmation } from '../../store/system/actions';
import DismissableMessage from '../../components/DismissableMessage';
import { IContactsState } from '../../store/contacts/types';
import ComplexTable from '../../components/Table/ComplexTable';
import { getSites } from '../../store/account/actions';
import SearchIcon from '../../components/SvgIcons/SearchIcon';
import SiteContact from '../../components/Modals/SiteContact';
import PlusIconButton from '../../components/Buttons/PlusIconButton';
import RevertIconButton from '../../components/Buttons/RevertIconButton';
import { AppDispatch } from '../../configureStore';
import MetaButton from "../../components/MetaButton/MetaButton";

type TParams = { id: string };
type ContactProps = {
  contacts: IContactsState;
  dispatch: AppDispatch;
  contact: IContactState;
  account: IAccountState;
  user: any;
};
type Props = ContactProps;
type TField = { field: string; name: string; mandatory?: boolean };
type TSiteContact = { id: string; name: string; types: number[]; new: boolean; syncToAll: boolean };
const initSiteContact = {
  id: '', name: '', types: [], new: true, syncToAll: false,
};
const fields: TField[] = [
  { field: 'firstName', name: 'First Name', mandatory: true },
  { field: 'lastName', name: 'Last Name', mandatory: true },
  { field: 'email', name: 'Email', mandatory: true },
  { field: 'phone', name: 'Phone', mandatory: true },
  { field: 'statusAlerts', name: 'Status Alerts' },
  { field: 'statusNotificationEmail', name: 'Status Notifications Email' },
  { field: 'statusNotificationMobile', name: 'Status Notifications Mobile Number' },
];
const Contact: React.FC<Props> = (props) => {

  const params = useParams();
  const navigate = useNavigate();
  const {
    data, dataOriginal, loading, contactTypes, readOnly, inviteModalOpen, errorMessage,
  } = props.contact;
  const { contacts } = props.contacts;
  const { dispatch, account, user } = props;
  const { username } = user;
  const { sites: accountSites } = account;
  const { id: userId } = user.contact;
  const isNewContact = params.id === 'new';
  const editingSelf = props.user.contact.id === data.id;
  const canEditAccess = props.contact.data.id !== user.contact.id;
  const [showSiteContact, setShowSiteContact] = useState(false);
  const [sitesLoading, setSitesLoading] = useState(false);
  const [siteContact, setSiteContact] = useState<TSiteContact>(initSiteContact);

  const deleteContactHandler = () => {

    dispatch(setSystemConfirmation(
      {
        text: 'This will deactivate the contact and remove portal access if applicable.',
        isOpen: true,
        color: 'danger',
        proceed: () => {
          dispatch(toggleContactLoading());
          dispatch(deleteContact(data)).then((result) => {
            if (!result) {
              dispatch(setContactErrorMessage('Could not deactivate contact'));
            } else {
              toggleReadWrite()
            }
            dispatch(toggleContactLoading());
          });
          dispatch(resetSystemConfirmation());
        },
        cancel: () => {
          dispatch(resetSystemConfirmation());
        },
      },
    ));

  };
  const restoreContactHandler = () => {

    dispatch(setSystemConfirmation(
      {
        text: 'This will reactivate the contact and portal access if applicable.',
        isOpen: true,
        color: 'danger',
        proceed: () => {

          dispatch(toggleContactLoading());
          dispatch(restoreContact(data)).then((result) => {

            if (!result) {

              dispatch(setContactErrorMessage('Could not reactivate contact'));

            }
            dispatch(toggleContactLoading());

          });
          dispatch(resetSystemConfirmation());

        },
        cancel: () => {

          dispatch(resetSystemConfirmation());

        },
      },
    ));

  };

  useEffect(() => {

    dispatch(initContact());
    if (!isNewContact) {

      dispatch(toggleContactLoading());

      const promises: Promise<any>[] = [];

      if (isEmpty(account.sites)) {

        promises.push(dispatch(getSites()));

      }
      promises.push(dispatch(getContactData(params.id)));

      Promise.all(promises).then(() => dispatch(toggleContactLoading()));

    } else {

      dispatch(toggleContactReadWrite());

    }

  }, []);

  const toggleReadWrite = () => {

    dispatch(toggleContactReadWrite());

  };
  const toggleModal = () => {

    dispatch(togglePortalInviteModal());

  };
  const cancelEdit = () => {

    dispatch(resetContactData());
    toggleReadWrite();

  };
  const handlePhoneFieldChange = (phone) => {

    dispatch(updateContactData({ phone }));

  };

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    if (event.target.id === 'statusAlerts') {

      if (event.target.checked) {

        dispatch(updateContactData({
          statusNotificationEmail: dataOriginal.statusNotificationEmail || data.email,
          statusNotificationMobile: dataOriginal.statusNotificationMobile || '',
        }));

      } else {

        dispatch(updateContactData({
          statusNotificationEmail: '',
          statusNotificationMobile: '',
        }));

      }

    } else {

      dispatch(updateContactData({ [event.target.id]: event.target.value }));

    }

  };

  const handlePortalAccess = (event: React.ChangeEvent<HTMLInputElement>) => {

    dispatch(updatePortalUserData({ active: event.target.checked }));

  };
  const handlePortalUserRoleChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    dispatch(updatePortalUserData({ roleId: parseInt(event.target.value) }));

  };
  const saveContact = async () => {

    const valid = validate();
    const { dispatch, contact } = props;
    if (valid) {

      dispatch(toggleContactLoading());
      const toUpdate: any = diff(contact.dataOriginal, data);
      toUpdate.id = data.id;
      if (toUpdate.contactTypes) {

        toUpdate.contactTypes = [...data.contactTypes];

      }
      if (isNewContact) {

        dispatch(resetSystemSuccessMessage);
        dispatch(resetContactErrorMessage);
        const payload = { ...data };
        delete payload.portalUser;
        if (!payload.statusNotificationMobile) {

          delete payload.statusNotificationMobile;

        }
        if (!payload.statusNotificationEmail) {

          delete payload.statusNotificationEmail;

        }
        dispatch(createContact(payload))
          .then((result) => {

            if (result) {

              navigate('/contacts');

            }
            dispatch(toggleContactLoading());

          });

      } else {

        dispatch(saveContactData(toUpdate)).then((result) => {

          dispatch(toggleContactLoading());
          if (result) {

            toggleReadWrite();

          }

        });

      }

    }

  };
  const emailExists = (email) => {

    const contactsMatchingEmails = contacts.filter((contact) => contact.email === email);
    return contactsMatchingEmails.length > 0;

  };
  const sendPortalInvite = (roleId) => {

    toggleModal();
    dispatch(toggleContactLoading());
    dispatch(inviteContact(data, roleId)).then(() => dispatch(toggleContactLoading()));

  };
  const validate = (): boolean => {

    const errors = fields.reduce((carry: Array<IFormError>, field: TField) => {

      if (field.mandatory && String(data[field.field]).trim().length === 0) {

        carry.push({ id: field.field, message: `Invalid ${field.name}` });

      }
      if (field.field === 'email' && data.email.length > 0 && emailExists(data.email) && isNewContact) {

        carry.push({ id: field.field, message: 'Email already exists in contacts.' });

      }
      if ((field.field === 'email' || field.field === 'statusNotificationEmail') && data.email.length > 0) {

        if (!isEmail(data.email)) {

          carry.push({ id: field.field, message: `Invalid ${field.name}` });

        } else if (contacts.find((contact) => contact.id !== data.id && contact[field.field] === data[field.field]) !== undefined) {

          carry.push({ id: field.field, message: `${field.name} already exists on another contact in this account.` });

        }

      }
      if (field.field === 'phone' && data.phone.length > 0 && !isUkPhoneNumber(data.phone) && data.phone.substring(0, 3) === '+44') {

        carry.push({ id: field.field, message: 'Invalid phone' });

      }
      if (field.field === 'statusNotificationMobile' && data.statusNotificationMobile && data.statusNotificationMobile.length > 0 && !/^\+[0-9]+/.test(data.statusNotificationMobile)) {

        carry.push({ id: field.field, message: 'Invalid phone or mobile. Please include the country code (e.g. +44) and no spaces.' });

      }

      return carry;

    }, []);
    dispatch(setContactErrors(errors));
    return Object.keys(errors).length === 0;

  };
  const ownContactTypes: Array<ICheckBoxOption> = contactTypes.map((type) => {

    const found = data.contactTypes.filter((ownType) => ownType === type.id);
    return { id: type.id, name: type.name, checked: found.length > 0 };

  });
  const portalUserTypes: Array<ISelectInputOptions> = props.contact.portalUserRoles.map((role) => ({ value: role.roleId, label: role.role }));
  const portalUserRole = data.portalUser ? props.contact.portalUserRoles.filter((role) => role.roleId === data.portalUser?.roleId).shift() : undefined;
  const portalAccessOption = data.portalUser ? { id: 'portalAccess', name: data.portalUser.active ? 'Active' : 'Disabled', checked: data.portalUser.active } : undefined;
  const siteContactsColumns = [
    {
      Header: 'Site',
      accessor: 'name',
      filterable: false,
    },
    {
      Header: 'Contact Type',
      accessor: 'types',
      filterable: false,
      Cell: (props) => {


        if (props.value) {

          return props.value.map((val) => {

            const typeFound = contactTypes.find((type) => type.id === val);
            if (typeFound) {

              return (
                <MetaButton key={`${props.original.name}${val}`} className={'mr-2'}>
                  {typeFound.name}
                </MetaButton>
              );

            }

          });

        }
        return <></>;

      },
    },
    {
      Header: '',
      accessor: 'id',
      filterable: false,
      maxWidth: 100,
      Cell: (props) => (
        <div
          className="grey-icon rounded float-right"
          style={{ cursor: 'pointer' }}
          onClick={() => {

            setShowSiteContact(true);
            setSiteContact({ ...props.original, new: false });

          }}
        >
          <SearchIcon title="edit" />
        </div>
      ),
    },
  ];

  const resolveTableData = () => Object.values(data.sites.reduce((carry: object, contactSite: TContactSite) => {

    if (!carry[contactSite.id]) {

      const site = accountSites.find((site) => site.id === contactSite.id);
      if (site) {

        carry[contactSite.id] = {
          id: contactSite.id,
          name: site.name,
          types: [],
        };

      } else {

        return carry;

      }

    }
    carry[contactSite.id].types.push(contactSite.type);
    return carry;

  }, {}));

  const getSiteContactTypeOptions = () => contactTypes.map((type) => ({
    id: type.id,
    name: type.name,
    checked: siteContact ? siteContact.types.includes(type.id) : false,
  }));

  const commitToState = () => {

    if (siteContact.syncToAll) {

      const allSites = accountSites.reduce((carry: TContactSite[], site: TAccountSite) => {

        const siteTypes: TContactSite[] = siteContact?.types.map((type) => ({ id: site.id, type })) ?? [];

        return carry.concat(siteTypes);

      }, []);

      dispatch(syncTypeToAllSites(allSites));

    } else {

      dispatch(setSite(siteContact.id, siteContact.types));

    }

    setShowSiteContact(false);

  };

  const addContactSite = (contactType: number) => {

    setSiteContact({ ...siteContact, types: [...siteContact.types, contactType] });

  };

  const removeContactSite = (contactType: number) => {

    setSiteContact({ ...siteContact, types: siteContact.types.filter((type) => type !== contactType) });

  };

  const updateSite = (siteId: string) => {

    const site = accountSites.find((accountSite) => siteId === accountSite.id);
    setSiteContact({ ...siteContact, id: site?.id ?? '', name: site?.name ?? '' });

  };

  const syncToAll = (checked: boolean) => {

    setSiteContact({ ...siteContact, syncToAll: checked });

  };

  const hasSiteChanges = () => {

    const diff = detailedDiff(dataOriginal.sites, data.sites) as { added: any; deleted: any; updated: any };
    return !isEmpty(diff.added) || !isEmpty(diff.deleted) || !isEmpty(diff.updated);

  };
  if (isNewContact && !checkPermission('contacts.write', props.user.permissions)) {

    return <ForbiddenError />;

  }

  const saveContactSites = () => {

    const payloadToAdd = data.sites.reduce((carry: { id: string; contactType: number; action: 'add' | 'remove' }[], site: TContactSite) => {

      const inOriginal = dataOriginal.sites.find((origSite) => origSite.id === site.id && origSite.type === site.type);
      if (!inOriginal) {

        carry.push({ id: site.id, contactType: site.type, action: 'add' });

      }
      return carry;

    }, []);

    const payloadToRemove = dataOriginal.sites.reduce((carry: { id: string; contactType: number; action: 'add' | 'remove' }[], site: TContactSite) => {

      const inModified = data.sites.find((origSite) => origSite.id === site.id && origSite.type === site.type);
      if (!inModified) {

        carry.push({ id: site.id, contactType: site.type, action: 'remove' });

      }
      return carry;

    }, []);

    setSitesLoading(true);
    dispatch(updateContactSites(dataOriginal, payloadToAdd.concat(payloadToRemove))).then(() => setSitesLoading(false));

  };

  const getRemainingSites = () => {

    const sites = resolveTableData();

    return accountSites.filter((accountSite) => !data.sites.find((contactSite) => contactSite.id === accountSite.id));

  };

  return (
    <div className="animated fadeIn mb-5">
      {errorMessage && (<DismissableMessage color="danger" text={errorMessage || ''} />)}
      <SimpleLoader loading={loading}>
        <div>
          <Section title={isNewContact ? 'New Contact' : data.name}>
            {!dataOriginal.isActive && (
              <Alert color={'warning'} className={`d-flex justify-content-between align-items-center`}>
                <p className="m-0">This contact is deactivated.</p>
                {!editingSelf && (
                  <Button className="btn" onClick={restoreContactHandler}>Reactivate</Button>
                )}
              </Alert>
            )}
            <Row className="bg-white pt-4 pl-4 m-0" style={{ borderRadius: '10px' }}>
              <Col md={8}>
                {readOnly
                        && (
                        <ReadContactForm
                          contact={data}
                          onClickInvite={toggleModal}
                          contactTypes={ownContactTypes}
                          portalAccessOption={portalAccessOption}
                          portalUserRole={portalUserRole}
                          permissions={props.user.permissions}
                        />
                        )}
                {(!readOnly)
                        && (
                        <EditContactForm
                          canEditAccess={canEditAccess}
                          newContact={isNewContact}
                          contact={data}
                          contactTypes={ownContactTypes}
                          portalAccessOption={portalAccessOption}
                          portalUserRole={portalUserRole}
                          portalUserTypes={portalUserTypes}
                          onPortalAccessChange={handlePortalAccess}
                          onPortalUserRoleChange={handlePortalUserRoleChange}
                          onFieldChange={handleOnChange}
                          errors={props.contact.errors}
                          userId={userId}
                          username={username}
                        />
                        )}
              </Col>
              {checkPermission('contacts.write', props.user.permissions)
                      && (
                      <Col md={4} className="d-flex flex-row align-items-start justify-content-end">
                        {readOnly && dataOriginal.isActive
                          && (
                          <div className="ml-2">
                            <EditIconButton onClick={toggleReadWrite} />
                          </div>
                          )}
                        {isNewContact
                          && (
                          <div className="ml-2">
                            <SaveIconButton onClick={saveContact} />
                          </div>
                          )}
                        {!readOnly && !isNewContact
                          && (
                          <>
                            {!editingSelf && dataOriginal.isActive
                              && (
                                <div>
                                  <DeleteIconButton onClick={deleteContactHandler}/>
                                </div>
                              )}
                            {dataOriginal.isActive && (
                              <>
                                <div className="ml-2">
                                  <SaveIconButton onClick={saveContact}/>
                                </div>
                                <div className="ml-2">
                                  <CancelIconButton onClick={cancelEdit}/>
                                </div>
                              </>
                            )}
                          </>
                          )}
                      </Col>
                      )}
            </Row>
          </Section>
        </div>

        {!loading && dataOriginal.isActive && (
          <div className="mt-4">
            <SimpleLoader loading={sitesLoading}>
              <Section title="Sites & Contact Types">
                <Row className="bg-white pt-4 pl-4 m-0" style={{borderRadius: '10px'}}>
                  <Col>
                    <Row>
                      <Col className="d-flex justify-content-end">
                        {hasSiteChanges()
                          && (
                            <>
                              <span className="text-danger mr-2">You have unsaved changes!</span>
                              <span className="mr-2"><RevertIconButton
                                onClick={() => dispatch(setSites(dataOriginal.sites))}/></span>
                            </>
                          )}
                        <span className="mr-2">
                        <PlusIconButton
                          disabled={isEmpty(getRemainingSites())}
                          onClick={() => {

                            setShowSiteContact(true);

                          }}
                        />
                      </span>
                        <SaveIconButton disabled={!hasSiteChanges()} onClick={saveContactSites}/>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <ComplexTable
                          columns={siteContactsColumns}
                          data={resolveTableData()}
                          defaultSorted={{id: 'name', desc: false}}
                        />
                      </Col>
                    </Row>

                  </Col>
                </Row>

              </Section>
            </SimpleLoader>
          </div>
        )}

      </SimpleLoader>
      <PortalInviteModal
        text={`You are about to send a portal invite to ${data.email}.`}
        isOpen={inviteModalOpen}
        cancel={toggleModal}
        proceed={sendPortalInvite}
      />
      <SiteContact
        isOpen={showSiteContact}
        cancel={() => setShowSiteContact(false)}
        data={siteContact}
        contact={data}
        add={addContactSite}
        remove={removeContactSite}
        siteChange={updateSite}
        syncToAll={syncToAll}
        commit={() => commitToState()}
        accountSites={getRemainingSites()}
        options={getSiteContactTypeOptions()}
        onClosed={() => setSiteContact(initSiteContact)}
      />
    </div>
  );

};
function mapStateToProps({
  contact, account, user, system, contacts,
}) {

  return {
    contact,
    account,
    user,
    contacts,
  };

}
export default connect(mapStateToProps)(Contact);
