import { TCase } from '../case/types';
import { MonitoredDeviceWidget, MonitoredPortWidget } from '../monitoring/types';
import { TBill } from '../billing/types';
import { ISystemSetPartnerCustomerAction } from '../system/types';

export const DASHBOARD_SET_OPEN_CASES = 'DASHBOARD_SET_OPEN_CASES';
export const DASHBOARD_SET_BILLS = 'DASHBOARD_SET_BILLS';
export const DASHBOARD_ADD_FAVOURITE = 'DASHBOARD_ADD_FAVOURITE';
export const DASHBOARD_TOGGLE_LOADING = 'DASHBOARD_TOGGLE_LOADING';
export const DASHBOARD_RESET = 'DASHBOARD_RESET';
export const DASHBOARD_TOGGLE_LOADING_CASES = 'DASHBOARD_TOGGLE_LOADING_CASES';
export const DASHBOARD_TOGGLE_LOADING_SUMMARY = 'DASHBOARD_TOGGLE_LOADING_SUMMARY';
export const DASHBOARD_TOGGLE_LOADING_FAVOURITES = 'DASHBOARD_TOGGLE_LOADING_FAVOURITES';
export const DASHBOARD_TOGGLE_LOADING_BILLS = 'DASHBOARD_TOGGLE_LOADING_BILLS';
export const DASHBOARD_SET_FAVOURITES_ERROR = 'DASHBOARD_SET_FAVOURITES_ERROR';
export const DASHBOARD_SET_BILLS_ERROR = 'DASHBOARD_SET_BILLS_ERROR';
export const DASHBOARD_REMOVE_FAVOURITE = 'DASHBOARD_REMOVE_FAVOURITE';
export interface IDashboardRemoveFavouriteAction {
  itemId: string;
  type: typeof DASHBOARD_REMOVE_FAVOURITE;
}
export type TDashboardUplinkDataPoint = [string, string];
export type TDashboardUpLinkDataPoints = {
  label: string;
  data: TDashboardUplinkDataPoint[];
};
export type DashboardFavouriteUplink = {
  currentUsage: string;
  lastHour: TDashboardUpLinkDataPoints[];
};
export interface IDashboardToggleLoadingCases {
  type: typeof DASHBOARD_TOGGLE_LOADING_CASES;
}
export interface IDashboardToggleLoadingSummary {
  type: typeof DASHBOARD_TOGGLE_LOADING_SUMMARY;
}
export interface IDashboardToggleLoadingFavourites {
  type: typeof DASHBOARD_TOGGLE_LOADING_FAVOURITES;
}
export interface IDashboardToggleLoadingBills {
  type: typeof DASHBOARD_TOGGLE_LOADING_BILLS;
}

export interface IDashboardState {
  loading: boolean;
  loadingFavourites: boolean;
  loadingCases: boolean;
  loadingBills: boolean;
  loadingSummary: boolean;
  favourites: (MonitoredDeviceWidget | MonitoredPortWidget)[];
  activity: [];
  bills: TBill[];
  cases: TCase[];
}

interface ISetFavouritesErrorAction {
  type: typeof DASHBOARD_SET_FAVOURITES_ERROR;
  payload: string;
}
interface ISetBillsErrorAction {
  type: typeof DASHBOARD_SET_BILLS_ERROR;
  payload: string;
}
interface IAddFavouriteAction {
  type: typeof DASHBOARD_ADD_FAVOURITE;
  widget: MonitoredDeviceWidget | MonitoredPortWidget;
}
interface ISetDashboardBillsAction {
  type: typeof DASHBOARD_SET_BILLS;
  bills: TBill[];
}
interface ToggleDashboardLoadingAction {
  type: typeof DASHBOARD_TOGGLE_LOADING;
}
interface ISetDashboardOpenCasesAction {
  type: typeof DASHBOARD_SET_OPEN_CASES;
  data: TCase[];
}
interface IResetDashboardAction {
  type: typeof DASHBOARD_RESET;
}
export type DashboardActionTypes =
    | IAddFavouriteAction
    | ISetDashboardOpenCasesAction
    | ToggleDashboardLoadingAction
    | ISetDashboardBillsAction
    | IResetDashboardAction
    | IDashboardToggleLoadingBills
    | IDashboardToggleLoadingCases
    | IDashboardToggleLoadingFavourites
    | IDashboardToggleLoadingSummary
    | ISetBillsErrorAction
    | ISetFavouritesErrorAction
    | IDashboardRemoveFavouriteAction
    | ISystemSetPartnerCustomerAction;
