import React from 'react';

interface IProps {
  cssClass?: string;
  cssClassTop?: string;
  title?: string;
}

const DocumentsIcon: React.FC<IProps> = ({ cssClass, cssClassTop, title }) => (
  <i title={title || ''} className={cssClassTop || 'icon-docs'} />
);

export default DocumentsIcon;
