import React from 'react';
import {
  Card,
  CardBody,
  Row,
  Col,
  Button,
} from 'reactstrap';
import { connect } from 'react-redux';
import Section from '../Section';
import Error from '../SvgIcons/Error';
import SimpleLoader from '../SimpleLoader';
import { checkPermission } from '../../utils/Auth/AuthService';

interface IProps {
  error?: string;
  errorEmailSent?: boolean;
  createCaseFromError?: (error: string) => any;
  loading?: boolean;
  user;
}

const ErrorGeneric: React.FC<IProps> = ({
  error = '', loading, createCaseFromError, user,
}) => (
  <Row className="d-flex justify-content-center">
    <Col md={7}>
      <Card className="mb-0">
        <CardBody>
          <div className="d-flex justify-content-center align-items-center py-4">
            <Col className="d-flex align-items-end flex-column">
              <Section title="Error">
                { createCaseFromError
                  ? (
                    <SimpleLoader loading={loading || false}>
                      <p>{error}</p>
                      <div className="align-items-start d-flex">
                        { checkPermission('cases.write', user.permissions)
                          ? <Button onClick={() => createCaseFromError(error)} color="primary">Report</Button> : ''}
                      </div>
                    </SimpleLoader>
                  )
                  : <p>{error}</p>}

              </Section>
            </Col>
            <Col className="d-flex justify-content-center">
              <Error cssClassTop="error-icon--top" cssClass="error-icon" />
            </Col>
          </div>
        </CardBody>
      </Card>
    </Col>
  </Row>
);
function mapStateToProps({ user }) {

  return {
    user,
  };

}
export default connect(mapStateToProps)(ErrorGeneric);
