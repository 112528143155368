import toastr from 'toastr';
import { SHOW_ERROR_FEEDBACK, SHOW_SUCCESS_FEEDBACK } from '../actions/feedback';

export default function cases(state = null, action) {

  toastr.options.positionClass = 'toast-bottom-right';
  switch (action.type) {
    case SHOW_ERROR_FEEDBACK:
      return toastr.error(action.message);
    case SHOW_SUCCESS_FEEDBACK:
      return toastr.success(action.message);
    default:
      return null;
  }

}
