import {
  api_createContact,
  api_deleteContact,
  api_getContact, api_restoreContact,
  api_sendPortalInvite,
  api_updateContact, api_updateContactSites,
} from '../../utils/Contacts/Contacts';
import { defaultErrorFeedback, errorFeedback, successFeedback } from '../../actions/feedback';
import {
  CONTACT_ADD_SITE_TYPE,
  CONTACT_INIT_CONTACT,
  CONTACT_REMOVE_SITE_TYPE,
  CONTACT_RESET_CONTACT,
  CONTACT_RESET_ERROR_MESSAGE,
  CONTACT_SET_CONTACT,
  CONTACT_SET_ERROR_MESSAGE,
  CONTACT_SET_ERRORS,
  CONTACT_SET_FORM_READ_ONLY,
  CONTACT_SET_ORIGINAL_DATA, CONTACT_SET_SITE, CONTACT_SET_SITES,
  CONTACT_SYNC_TYPE_TO_ALL_SITE,
  CONTACT_TOGGLE_INVITE_MODAL,
  CONTACT_TOGGLE_LOADING,
  CONTACT_TOGGLE_READ_WRITE,
  CONTACT_UPDATE_CONTACT,
  CONTACT_UPDATE_PORTAL_USER,
  IAddContactSiteType,
  IInitContactAction,
  IRemoveContactSiteType,
  IResetContactDataAction,
  IResetContactErrorMessageAction,
  ISetContactAction,
  ISetContactErrorMessageAction,
  ISetContactErrorsAction,
  ISetContactOriginalDataAction,
  ISetFormReactOnlyAction,
  ISyncTypeToAllSites,
  IToggleContactLoadingAction,
  IToggleContactReadWriteAction,
  IToggleInviteModalAction,
  IUpdateContactDataAction,
  IUpdatePortalUserAction,
  TContact,
} from './types';
import { IFormError } from '../../components/Forms/types';
import { setSystemError } from '../system/actions';
import { CONTACTS_REMOVE_CONTACT } from '../contacts/types';
import { newContact, updateContact } from '../contacts/actions';
import { resolve422Errors } from '../../helpers/transform';

export const setFormReadOnly = (payload): ISetFormReactOnlyAction => ({ type: CONTACT_SET_FORM_READ_ONLY, payload });
export const setContactErrorMessage = (error): ISetContactErrorMessageAction => ({
  type: CONTACT_SET_ERROR_MESSAGE,
  error,
});
export const resetContactErrorMessage = (): IResetContactErrorMessageAction => ({
  type: CONTACT_RESET_ERROR_MESSAGE,
});
function setContact(data): ISetContactAction {

  return {
    type: CONTACT_SET_CONTACT,
    data,
  };

}
export function initContact(): IInitContactAction {

  return {
    type: CONTACT_INIT_CONTACT,
  };

}
export function resetContactData(): IResetContactDataAction {

  return {
    type: CONTACT_RESET_CONTACT,
  };

}

export function updateContactOriginalData(): ISetContactOriginalDataAction {

  return {
    type: CONTACT_SET_ORIGINAL_DATA,
  };

}
export function toggleContactReadWrite(): IToggleContactReadWriteAction {

  return {
    type: CONTACT_TOGGLE_READ_WRITE,
  };

}
export function updateContactData(data): IUpdateContactDataAction {

  return {
    type: CONTACT_UPDATE_CONTACT,
    data,
  };

}

export function updatePortalUserData(data): IUpdatePortalUserAction {

  return {
    type: CONTACT_UPDATE_PORTAL_USER,
    data,
  };

}
export function setContactErrors(errors: Array<IFormError>): ISetContactErrorsAction {

  return {
    type: CONTACT_SET_ERRORS,
    errors,
  };

}
export function toggleContactLoading(): IToggleContactLoadingAction {

  return {
    type: CONTACT_TOGGLE_LOADING,
  };

}
export function togglePortalInviteModal(): IToggleInviteModalAction {

  return {
    type: CONTACT_TOGGLE_INVITE_MODAL,
  };

}

export function addContactSiteType(data): IAddContactSiteType {

  return {
    type: CONTACT_ADD_SITE_TYPE,
    data,
  };

}

export function removeContactSiteType(data): IRemoveContactSiteType {

  return {
    type: CONTACT_REMOVE_SITE_TYPE,
    data,
  };

}

export function syncTypeToAllSites(sites): ISyncTypeToAllSites {

  return {
    type: CONTACT_SYNC_TYPE_TO_ALL_SITE,
    sites,
  };

}

export function setSites(sites) {

  return {
    type: CONTACT_SET_SITES,
    sites,
  };

}

export function setSite(site, types) {

  return {
    type: CONTACT_SET_SITE,
    site,
    types,
  };

}

export function getContactData(contactId) {

  return (dispatch) => api_getContact(contactId).then((result) => {

    if (result.status === 200) {

      dispatch(setContact(result.data));

    } else if (result.data.error && result.data.error.id) {

      dispatch(setSystemError(result.data.error.id));

    } else {

      dispatch(defaultErrorFeedback());

    }

  });

}
export function saveContactData(contact: TContact) {

  return (dispatch) => api_updateContact(contact).then((result) => {

    if (result.status === 204) {

      dispatch(successFeedback('Contact updated!'));
      dispatch(updateContact(contact));
      dispatch(updateContactOriginalData());
      return true;

    }

    dispatch(resetContactData());
    dispatch(errorFeedback('Could not update contact!'));
    return false;

  });

}
export function createContact(contact: TContact) {

  return (dispatch) => api_createContact(contact).then((result) => {

    if (result.status === 200) {

      dispatch(newContact({ ...result.data, contactTypes: contact.contactTypes }));
      return true;

    } if (result.status === 422) {

      dispatch(setContactErrors(resolve422Errors(result.data)));

    }

    dispatch(errorFeedback('Could not create contact!'));
    return false;

  });

}
export function deleteContact(contact: TContact) {

  return (dispatch) => api_deleteContact(contact.id).then((result) => {

    if (result.status === 200) {

      dispatch(setContact({...contact, isActive: false, statusNotificationEmail: null, statusNotificationMobile: null }));
      dispatch(updateContact({...contact, isActive: false, statusNotificationEmail: null, statusNotificationMobile: null }));
      dispatch(successFeedback('Contact deleted!'));
      return true;

    }

    dispatch(errorFeedback('Could not delete contact!'));
    return false;

  });

}
export function restoreContact(contact: TContact) {

  return (dispatch) => api_restoreContact(contact.id).then((result) => {

    if (result.status === 200) {

      dispatch(setContact({...contact, ...result.data }));
      dispatch(updateContact({...contact, ...result.data }));
      dispatch(successFeedback('Contact restored!'));
      return true;

    }

    dispatch(errorFeedback('Could not restore contact!'));
    return false;

  });

}
export function removeContact(contact: TContact) {

  return {
    type: CONTACTS_REMOVE_CONTACT,
    contact,
  };

}
export function inviteContact(contact: TContact, roleId: number) {

  return (dispatch) => api_sendPortalInvite(contact.id, roleId).then((result) => {

    if (result.status === 200) {

      dispatch(successFeedback('Invite sent!'));

    } else {

      dispatch(errorFeedback('Could not invite contact!'));

    }

  });

}

export function updateContactSites(contact: TContact, sites: { id: string; contactType: number; action: 'add' | 'remove' }[]) {

  return (dispatch) => api_updateContactSites(contact.id, sites).then((result) => {

    if (result.status === 200) {

      dispatch(successFeedback('Contact updated!'));
      dispatch(setSites(result.data));

    } else {

      dispatch(errorFeedback('Could not update contact!'));

    }

  });

}
