import React, { useEffect, useState } from 'react';
import {
  Alert, Button, Col, FormGroup, Input, Modal, ModalBody, ModalFooter, Row, Table,
} from 'reactstrap';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import { detailedDiff } from 'deep-object-diff';
import cloneDeep from 'lodash.clonedeep';
import isEmpty from 'validator/lib/isEmpty';
import Section from '../../Section';
import SimpleLoader from '../../SimpleLoader';
import { IMonitoredItemState, TMonitoredItemGraph } from '../../../store/monitoredItem/types'
import { IContactsState } from '../../../store/contacts/types';
import {
  ReportConfigFrequency,
  TDeviceUsageReport,
  TDeviceUsageReportPort,
  TDeviceUsageReportUpdate,
} from '../../../store/reports/types';
import {
  createMonitoredDeviceReport,
  downloadMonitoredDeviceReport,
  updateMonitoredDeviceReport,
} from '../../../store/monitoredItem/actions';
import { MultiSelect } from '../../Inputs/MultiSelect';
import { api_getMonitoredItem } from '../../../utils/Monitoring/Monitoring';
import { AppDispatch } from '../../../configureStore';
import { IFormError } from '../../Forms/types'

type TOwnProps = {
  isOpen: boolean;
  dispatch: AppDispatch;
  cancel: () => any;
  contacts: IContactsState;
  monitoredItem: IMonitoredItemState;
  report?: TDeviceUsageReport;
};

const reportFrequency = [{ label: 'one-off', value: ReportConfigFrequency.oneOff }, { label: 'daily', value: ReportConfigFrequency.daily }];

const DeviceUsageReportWizard: React.FC<TOwnProps> = (props) => {

  const {
    isOpen, cancel, monitoredItem, contacts, report,
  } = props;
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<Array<string>>([]);
  const [created, setCreated] = useState(false);
  const [deviceReport, setDeviceReport] = useState<Partial<TDeviceUsageReport>>({ name: '', config: { contacts: [], ports: [] } });
  const [newReport, setNewReport] = useState<Partial<TDeviceUsageReport>>({ name: '', config: { contacts: [], ports: [] } });
  const [isNew, setIsNew] = useState(false);

  useEffect(() => {

    if (isOpen) {

      setCreated(false);
      if (report) {

        console.log({report})
        setDeviceReport(cloneDeep(report));

      } else {

        setIsNew(true);
        setDeviceReport({
          ...deviceReport,
          frequency: ReportConfigFrequency.oneOff,
          name: monitoredItem.item.type === 'port' ? `${monitoredItem.item.host} - ${monitoredItem.item.port?.replaceAll(/\/|\\/g, '-')} - Usage Report` : `${monitoredItem.item.name} - Usage Report`,
          config: {
            ...deviceReport.config,
            contacts: [],
            ports: monitoredItem.item.graphs.reduce((carry : Array<TDeviceUsageReportPort>, graph) => {

              if(monitoredItem.item.type === 'device' || (monitoredItem.item.type === 'port' && graph.name === 'Traffic')){

                carry.push({
                  id: graph.id, name: graph.name, in: true, out: true,
                });

              }

              return carry;
            
            }, []),
          },
        });

      }

    }

  }, [isOpen]);
  useEffect(() => {

    if (report) {

      setLoading(true);
      setIsNew(false);
      api_getMonitoredItem(report.config.deviceId).then((result) => {

        if (result.status === 200) {

          setDeviceReport({
            ...report,
            config: {
              ...report.config,
              ports: result.data.graphs.reduce((carry : Array<TDeviceUsageReportPort>, graph) => {

                const configPort = report.config.ports!.find((port) => port.id === graph.id);
                if(result.data.type === 'device' || (result.data.type === 'port' && graph.name === 'Traffic')){

                  carry.push({
                    id: graph.id, name: configPort ? configPort.name : graph.name, in: configPort ? configPort.in : false, out: configPort ? configPort.out : false,
                  });

                }

                return carry;

              }, []),
            },
          });

        }
        setLoading(false);

      });

    }

  }, [report]);

  const handleCancel = () => {

    cancel();
    setDeviceReport({ name: '', frequency: ReportConfigFrequency.oneOff, config: { contacts: [], ports: [] } });

  };

  const updatePort = (id, value) => {

    setDeviceReport({
      ...deviceReport,
      config: {
        ...deviceReport.config,
        ports: deviceReport.config!.ports!.map(
          (port) => {

            if (port.id === id) {

              return { ...port, ...value };

            }
            return port;

          },
        ),
      },
    });

  };

  const getContactOptions = () => contacts.contacts.map((contact) => ({ label: `${contact.name} (${contact.email})`, value: contact.id || '' }));

  const getSelectedContacts = () => deviceReport.config?.contacts!.filter((reportContact) => contacts.contacts.find((contact) => contact.id === reportContact)).map((selectedContact) => {

    const found = contacts.contacts.find((contact) => contact.id === selectedContact);
    return { label: `${found!.name} (${found!.email})`, value: found!.id || '' };

  });

  const handleContactSelect = (selected) => {

    setDeviceReport({
      ...deviceReport,
      config: { ...deviceReport.config, contacts: selected ? selected.map((contact) => contact.value) : [] },
    });

  };

  const handleFrequencySelect = (selected) => {

    setDeviceReport({
      ...deviceReport,
      frequency: selected.value,
      config: {
        ...deviceReport.config,
        contacts: selected.value === ReportConfigFrequency.oneOff ? [] : deviceReport.config!.contacts,
      },
    });

  };

  const getSelectedFrequency = () => reportFrequency.find((item) => item.value === deviceReport.frequency);

  const download = () => {

    setLoading(true);
    props.dispatch(downloadMonitoredDeviceReport(deviceReport.config?.deviceId || monitoredItem.item.id, deviceReport.id)).then(() => {

      setLoading(false);

    });

  };

  const save = () => {

    setCreated(false);
    if (valid()) {

      if (isNew) {

        setLoading(true);
        props.dispatch(createMonitoredDeviceReport(monitoredItem.item.id, deviceReport)).then((result) => {

          if (result) {

            setDeviceReport({
              ...deviceReport,
              id: result.id,
            });
            setNewReport({
              ...result,
            });
            setCreated(true);
            setIsNew(false);

          }
          setLoading(false);

        });

      } else if (report || newReport) {

        const update: TDeviceUsageReportUpdate = {};
        const diff = detailedDiff(report || newReport, deviceReport) as { added: TDeviceUsageReport; deleted: TDeviceUsageReport; updated: TDeviceUsageReport };
        const { added, deleted, updated } = diff;

        if (added.config?.ports || deleted.config?.ports || updated.config?.ports) {

          update.ports = [...(deviceReport.config ? deviceReport.config.ports : [])!];

        }
        if (added.config?.contacts || deleted?.config?.contacts || updated.config?.contacts) {

          update.contacts = [...(deviceReport.config ? deviceReport.config.contacts : [])!];

        }
        if (updated.frequency) {

          update.frequency = updated.frequency;

        }
        if (updated && updated.name) {

          update.name = updated.name;

        }

        if (Object.keys(update).length) {

          setLoading(true);
          props.dispatch(updateMonitoredDeviceReport(deviceReport.config?.deviceId || newReport.config?.deviceId, deviceReport.id, update)).then(() => {

            setLoading(false);

          });

        }

      }

    }

  };

  const valid = () => {

    const results: string[] = [];

    const slashes = /\/|\\/;
    if (!deviceReport.name) {

      results.push('Must set a name');

    }
    if (deviceReport.name!.search(slashes) !== -1) {

      results.push('Name must not have / or \\');

    }
    if (deviceReport.config?.ports!.filter((port) => port.in || port.out).length === 0) {

      results.push('Must have at least one active port');

    }
    if (deviceReport.frequency !== ReportConfigFrequency.oneOff && deviceReport.config?.contacts!.length === 0) {

      results.push('Scheduled reports must have at least one selected contact');

    }

    setErrors(results);
    return results.length === 0;

  };

  const getButtonText = () => {

    let text = 'Update';
    if (isNew && deviceReport.frequency === ReportConfigFrequency.oneOff) {

      text = 'Save';

    } else if (isNew && deviceReport.frequency !== ReportConfigFrequency.oneOff) {

      text = 'Save & Schedule';

    }

    return text;

  };

  return (

    <Modal isOpen={isOpen} toggle={handleCancel} className="modal-warning" backdrop="static" size="xlg">
      <SimpleLoader loading={loading}>
        <ModalBody>
          {created
                    && (
                    <Alert color="success">
                      Your report has been successfully created. See all your reports
                      <span><Link to="/reports"> here</Link></span>
                      .
                    </Alert>
                    )}
          {errors.length > 0
                    && (
                    <Alert color="danger">
                      {errors.map((error, index) => <p className="mb-0" key={`error-${index}`}>{error}</p>)}
                    </Alert>
                    )}
          <Row>
            <Col md={4}>
              <Section title="Name">
                <Row className="mb-4">
                  <Col>
                    <Input
                      value={deviceReport.name}
                      onChange={(event) => setDeviceReport({ ...deviceReport, name: event.target.value })}
                    />
                  </Col>
                </Row>
              </Section>
              <Section title="Schedule">
                <Row className="mb-4">
                  <Col md={6}>
                    <Select
                      value={getSelectedFrequency()}
                      options={reportFrequency}
                      onChange={handleFrequencySelect}
                    />
                  </Col>
                </Row>
              </Section>
              <Section title="Contacts">
                <Row>
                  <Col>
                    <FormGroup>
                      <MultiSelect
                        label="Contacts"
                        id="contacts"
                        isMulti
                        options={getContactOptions()}
                        value={getSelectedContacts()}
                        onChange={handleContactSelect}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        closeMenuOnSelect={false}
                        isDisabled={deviceReport.frequency === ReportConfigFrequency.oneOff}
                      />
                    </FormGroup>

                  </Col>
                </Row>
              </Section>
            </Col>
            <Col>
              <Section title="Ports">
                <div>
                  <Table bordered>
                    <thead>
                      <tr>
                        <th>name</th>
                        <th>in</th>
                        <th>out</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(deviceReport.config ? deviceReport.config.ports : [])!.map((port) => (
                        <tr key={port.id}>
                          <td style={{ minWidth: '500px' }}>
                            <Input
                              value={port.name}
                              onChange={(event) => updatePort(port.id, { name: event.target.value })}
                            />
                          </td>
                          <td className="pt-3">
                            <label className="text-light homeage-to-the-square-checkbox">
                              <input
                                checked={port.in}
                                className="form-check-input"
                                type="checkbox"
                                onChange={(event) => updatePort(port.id, { in: event.target.checked })}
                              />
                              <span className="checkmark" />
                            </label>
                          </td>
                          <td className="pt-3">
                            <label className="text-light homeage-to-the-square-checkbox">
                              <input
                                checked={port.out}
                                className="form-check-input"
                                type="checkbox"
                                onChange={(event) => updatePort(port.id, { out: event.target.checked })}
                              />
                              <span className="checkmark" />
                            </label>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </Section>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="border-top-0">
          {!isNew && <Button color="dark" onClick={download}>Download Latest Report</Button>}
          <Button color="primary" onClick={save}>{getButtonText()}</Button>
          {' '}
          <Button color="warning" onClick={handleCancel}>Close</Button>
        </ModalFooter>
      </SimpleLoader>
    </Modal>
  );

};
function mapStateToProps({ user, monitoredItem, contacts }) {

  return {
    user,
    monitoredItem,
    contacts,
  };

}

export default connect(mapStateToProps)(DeviceUsageReportWizard);
