import React from 'react'
import { connect } from 'react-redux'
import { Link, Navigate } from 'react-router-dom'
import { Card, CardBody, CardText, Col, Row } from 'reactstrap'
import LaddaButton, { ZOOM_OUT } from 'react-ladda'
import { isLoggedIn as authLoggedIn } from '../../utils/Auth/AuthService'
import { setUserData } from '../../store/user/actions'
import Section from '../../components/Section'
import SVG from '!file-loader!../../scss/img/hso_network.svg'
import { apm } from '../../index'
import { TAuthTokenResult, useOAuth2 } from '../../utils/OAuth2/useOAuth2'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const jwtDecode = require('jwt-decode')

const Login = ({ dispatch }) => {

    const { data, loading, error, getAuth } = useOAuth2({
        authorizeUrl: `${process.env.REACT_APP_HSO_ID_URL}/authorization`,
        clientId: process.env.REACT_APP_HSO_ID_CLIENT_ID || '',
        redirectUri: `${document.location.origin}/callback`,
        scope: 'openid offline_access',
        responseType: 'code',
        exchangeCodeForTokenServerURL: `${process.env.REACT_APP_HSO_ID_URL}/token/`,
        onSuccess: (result: TAuthTokenResult) => {

            const tokenDecoded = jwtDecode(result.access_token)
            const idTokenDecoded = jwtDecode(result.id_token)
            if (tokenDecoded) {

                localStorage.setItem('hso-api-token', result.access_token)
                localStorage.setItem('hso-api-refresh-token', result.refresh_token)

                dispatch(setUserData({
                    username: idTokenDecoded.preferred_username,
                    email: idTokenDecoded.email,
                    permissions: tokenDecoded.scopes,
                }))
                apm.setUserContext({
                    username: idTokenDecoded.preferred_username,
                    email: idTokenDecoded.email,
                })
                apm.setCustomContext({
                    permissions: tokenDecoded.scopes,
                    role: tokenDecoded.role,
                })

            }

        },
        onError: (error_) => console.error(error_),
    })

    const isLoggedIn = Boolean(data?.access_token) && authLoggedIn()

    if (isLoggedIn) {

        return <Navigate to={'/'} />

    }

    return (
        <div className='app flex-row align-items-center animated fadeIn'>
            <div className='container d-flex flex-column'>
                <div className='row justify-content-center'>
                    <div className='col-md-6 py-5 d-md-down-none'>
                        <img src={SVG} />
                    </div>
                    <div className='col-md-8 col-lg-6'>
                        <div className='card-body d-flex flex-column h-100 justify-content-center'>
                            <Row className='d-lg-none'>
                                <Col className='p-3 mb-4'>
                                    <img className='img-fluid' alt='' src='../img/hso-email-header.png' />
                                </Col>
                            </Row>
                            <Section title='Portal Login'>
                                {error && (
                                    <Card
                                        color='danger'
                                        className='animated zoomIn'
                                    >
                                        <CardBody>
                                            <CardText>{error}</CardText>
                                        </CardBody>
                                    </Card>
                                )}

                                <Row>
                                    <Col xs={12} className='d-flex justify-content-start'>
                                        <LaddaButton
                                            type='submit'
                                            className='btn btn-primary btn-ladda w-25'
                                            loading={loading}
                                            data-color='blue'
                                            data-style={ZOOM_OUT}
                                            onClick={() => getAuth()}
                                        >
                                            Login
                                        </LaddaButton>
                                    </Col>
                                    <Col xs={12} className={'mt-3'}>
                                        <Link to="/forgot-password">Forgot password?</Link>
                                    </Col>
                                    <Col xs={12}>
                                        <Link to="/forgot-username">Forgot username?</Link>
                                    </Col>
                                </Row>
                            </Section>
                        </div>
                    </div>
                </div>
                <Link to='/terms' className='text-warning font-weight-bold align-self-end pr-3'>Terms &
                    Conditions</Link>
            </div>
        </div>
    )

}
export default connect()(Login)
