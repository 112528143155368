import React from 'react';

interface IProps {
  cssClass?: string;
  title?: string;
}

const WeatherMapsIcon: React.FC<IProps> = ({ cssClass, title }) => (
  <i title={title || ''} className={cssClass || 'icon-chart'} />
);

export default WeatherMapsIcon;
