import React, { FunctionComponent } from 'react';
import GaugeChart from 'react-gauge-chart';
import { Col, Row } from 'reactstrap';
import Graph from '../../../SvgIcons/Graph';

type TOwnProps = {
  title: string;
  value: number;
  index: number | string;
  clickHandler?: (event: React.MouseEvent<HTMLDivElement>) => void;
};

const getColor = (value) => {

  if (value < 66) {

    return '#80cbc4';
  
  }
  if (value < 86) {

    return '#FF9E1B';
  
  }
  return '#e57373';

};

const GaugeWidget: FunctionComponent<TOwnProps> = (props) => {

  const { value, title, clickHandler } = props;
  return (
    <div className="shadow-sm px-3 pt-3 bg-white w-100 h-100" style={{ borderRadius: '10px' }}>
      <Row className="d-flex h-100">
        <Col className="d-flex flex-column justify-content-between align-items-center">
          <div className="d-flex w-100 justify-content-between">
            <h5 className="text-light text-uppercase font-xl text-left">
              {title}
            </h5>
            <h5 style={{ color: getColor(value) }}>
              <strong>
                {value && (
                <>
                  {value}
                  %
                </>
                )}
              </strong>
            </h5>
          </div>
          <GaugeChart
            id={`gauge-${props.index}`}
            nrOfLevels={3}
            percent={value / 100}
            arcsLength={[0.66, 0.20, 0.14]}
            arcPadding={0}
            colors={['#80cbc4', '#FF9E1B', '#e57373']}
            cornerRadius={0}
          />
          {clickHandler
                    && (
                    <div onClick={clickHandler}>
                      <Graph />
                    </div>
                    )}
        </Col>
      </Row>
    </div>
  );

};

export default GaugeWidget;
