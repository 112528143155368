import React from 'react';
import ReactTable from 'react-table-6';
import ReactTooltip from 'react-tooltip';

export const RecordTypes = {
  normal: {
    NS: [
      { header: 'Zone', accessor: 'name' },
      { header: 'Nameserver', accessor: 'content' },
    ],
    MX: [
      { header: 'Zone', accessor: 'name' },
      { header: 'Handled By Host', accessor: 'content' },
      { header: 'Preference', accessor: 'prio' },
    ],
    A: [
      { header: 'Host', accessor: 'name' },
      { header: 'IP Address', accessor: 'content' },
    ],
    AAAA: [
      { header: 'Host', accessor: 'name' },
      { header: 'IP Address', accessor: 'content' },
    ],
    CNAME: [
      { header: 'Host', accessor: 'name' },
      { header: 'Points to Host', accessor: 'content' },
    ],
    TXT: [
      { header: 'Host', accessor: 'name' },
      { header: 'Record', accessor: 'content' },
    ],
    SRV: [
      { header: 'Host', accessor: 'name' },
      { header: 'Priority', accessor: 'prio' },
      { header: 'Data', accessor: 'content' },
    ],
  },
  reverse: {
    NS: [
      { header: 'Zone', accessor: 'name' },
      { header: 'Nameserver', accessor: 'content' },
    ],
    PTR: [
      { header: 'Host', accessor: 'content' },
      { header: 'IP Address', accessor: 'name' },
    ],
    CNAME: [
      { header: 'Host', accessor: 'name' },
      { header: 'Points to Host', accessor: 'content' },
    ],
    TXT: [
      { header: 'Host', accessor: 'name' },
      { header: 'Record', accessor: 'content' },
    ],
    SRV: [
      { header: 'Host', accessor: 'name' },
      { header: 'Priority', accessor: 'prio' },
      { header: 'Data', accessor: 'content' },
    ],
  },
};

const RecordTable = ({
  type,
  data,
  handleDelete,
  openNewRecordForm,
  updating,
  reverse,
}) => {

  const types = reverse ? RecordTypes.reverse : RecordTypes.normal;
  const columns = types[type].map(column => ({
    Header: column.header,
    accessor: column.accessor,
    sortable: false,
    style: { whiteSpace: 'unset', wordWrap: 'break-word' },
  }));
  columns.push({
    // eslint-disable-next-line react/no-unstable-nested-components
    Header: () => (
      <div
        className="h4 text-danger float-right mb-0"
        onClick={openNewRecordForm}
        style={{ cursor: 'pointer' }}
        id={`add${type}`}
      >
        <ReactTooltip />
        <i className="icon-plus" data-tip="add new record" />
      </div>
    ),
    filterable: false,
    sortable: false,
    accessor: 'id',
    width: 150,
    // eslint-disable-next-line react/no-unstable-nested-components
    Cell: props => (
      <div
        className="h4 text-danger float-right"
        style={{ cursor: 'pointer' }}
        onClick={handleDelete.bind(null, props.value)}
      >
        <ReactTooltip />
        <i className="fa fa-trash" data-tip="remove record" />
      </div>
    ),
  });
  return (
    <ReactTable
      getTheadProps={() => ({
        style: { border: 'none' },
      })}
      getTheadTrProps={() => ({
        style: { border: 'none' },
      })}
      getTheadThProps={() => ({
        style: { border: 'none' },
      })}
      className="animated fadeIn"
      noDataText={`You have no ${{ type }} records`}
      loading={updating}
      loadingText="Processing..."
      data={data}
      columns={columns}
      minRows="1"
      defaultPageSize={10}
      showPagination={data.length > 10}
      filterable={data.length > 10}
    />
  );

};

export default RecordTable;
