import React, { FunctionComponent } from 'react';
import { Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';

const MonitoredItemEmpty: FunctionComponent = () => (
  <div
    className="shadow-sm bg-white d-flex  pt-3  flex-column justify-content-between monitoring-widget w-100"
    style={{ borderRadius: '10px' }}
  >
    <Row className="m-0">
      <Col className="text-center">
        <h6
          data-testid="title"
          className="text-uppercase font-weight-bold truncate"
          style={{ letterSpacing: '0px' }}
        >
          MONITORING
        </h6>
      </Col>
    </Row>
    <Row>
      <Col>
        <Link to="/monitoring">
          <div
            className="text-light position-relative"
            style={{
              margin: '50px auto',
              width: '100px',
              height: '100px',
              border: '3px dashed #9da0a8',
            }}
          >
            <span className="text-light cross" />
          </div>
        </Link>
      </Col>

    </Row>

    <Row className="m-0 text-center">
      <Col>
        <div className="text-center p-2">
          ADD FAVOURITE
        </div>
      </Col>
    </Row>
  </div>
);
export default MonitoredItemEmpty;
