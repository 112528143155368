import * as React from 'react';
import { Button } from 'reactstrap';
import { IButtonClickable } from './types';

const SaveIconButton: React.FC<IButtonClickable> = (props) => {

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {

    props.onClick(event);
  
  };
  return (
    <Button color="warning" disabled={props.disabled} onClick={handleClick}><i title="revert" className="fa fa-2x fa-undo" /></Button>
  );

};

export default SaveIconButton;
