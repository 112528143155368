import React from 'react';
import { Col, Row } from 'reactstrap';
import { IMonitoredItemNTShowRouteData } from '../../../store/monitoredItem/types';
import SimpleTable from '../../../components/Table/SimpleTable';

interface TOwnProps {
  data: Array<IMonitoredItemNTShowRouteData>;
}

const ShowRouteTable: React.FC<TOwnProps> = ({ data }) => {

  const header = (
    <tr className="text-light font-weight-bold h5">
      <th>Active</th>
      <th>Protocol</th>
      <th>Age</th>
      <th>Nexthops</th>
    </tr>
  );

  return (
    <>
      {data.map((datum, index) => {

        const rows = datum.routes.map((route, i) => (
          <tr key={`row-${i}`}>
            <td>{route.active ? 'True' : 'False'}</td>
            <td>{route.protocol}</td>
            <td>{route.age}</td>
            <td>
              {route.nexthops.map((nexthop, j) => (
                <div key={`row-${i}-nexthop-${j}`}>
                  To:
                  {nexthop.to}
                  <br />
                  Via:
                  {' '}
                  {nexthop.via}
                  <br />
                </div>
              ))}
            </td>
          </tr>
        ));

        return (
          <Row key={`route-${index}`}>
            <Col>
              <p>
                <strong>Destination:</strong>
                {' '}
                {datum.destination}
              </p>
              <SimpleTable rows={rows} withHeader header={header} bordered />
            </Col>
          </Row>
        );
      
      })}
    </>
  );

};

export default ShowRouteTable;
