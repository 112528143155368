import React, { FunctionComponent, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as Immutable from 'immutable';
import { TrafficMap, MapLegend } from 'react-network-diagrams-hso';
import { TimeEvent, TimeSeries } from 'pondjs';
import moment from 'moment/moment';
import _ from 'underscore';
import {
  Button,
  Card, CardBody,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle, Modal, ModalBody, ModalHeader,
  Row,
  UncontrolledButtonDropdown,
} from 'reactstrap';
import { useNavigate, useParams } from 'react-router-dom';
import {
  IWeatherMap,
  IWeatherMapEdge,
  IWeatherMapNode,
  TWeatherMapTraffic,
} from '../../store/weatherMap/types';
import { TWeatherMapsList } from '../../store/weatherMaps/types';
import {
  getWeatherMap,
  getWeatherMapEdge,
  getWeatherMapTraffic, resetWeatherMap, setWeatherMapEdge,
  subscribeWeatherMap,
  unsubscribeWeatherMap,
} from '../../store/weatherMap/actions';
import {
  stylesMap, nodeLegendData, nodeSizeMap, edgeThicknessMap, edgeColorMap, bounds,
} from './styles';
import Section from '../../components/Section';
import SimpleLoader from '../../components/SimpleLoader';
import WeatherMapEdge from './WeatherMapEdge';
import MonitoredItem from '../MonitoredItem';
import { AppDispatch } from '../../configureStore';

interface TOwnProps {
  weatherMapsList: TWeatherMapsList;
  weatherMap: IWeatherMap;
  edge: IWeatherMapEdge;
  weatherMapTraffic: TWeatherMapTraffic;
  timestamp: string;
  jtiData; // todo
  dispatch: AppDispatch;
}

type TProps = TOwnProps;

let lastUpdateDate;

const WeatherMap: FunctionComponent<TProps> = ({
  weatherMapsList,
  weatherMap,
  weatherMapTraffic,
  edge,
  jtiData,
  dispatch,
}) => {

  const [loading, setLoading] = useState<boolean>(true);
  const [trafficLoading, setTrafficLoading] = useState<boolean>(true);
  const [, setLastFetch] = useState<string>('');
  const [showLegend, setShowLegend] = useState<boolean>(true);
  const [selectedEdgeId, setSelectedEdgeId] = useState<string>('');
  const [selectedNode, setSelectedNode] = useState<IWeatherMapNode | undefined>();

  const params = useParams();
  const navigate = useNavigate();

  const getInfluxTraffic = () => dispatch(getWeatherMapTraffic(weatherMap.id)).then((result) => {

    setLastFetch(new Date().toLocaleString());
    if (result) {

      setTrafficLoading(false);
      const dateNow = new Date();
      lastUpdateDate = new Date(dateNow.getTime() - 5000);

    }

  });

  useEffect(() => {

    lastUpdateDate = new Date();

  });

  useEffect(() => {

    if (params.weatherMapID) {

      setLoading(true);
      setTrafficLoading(true);

      dispatch(getWeatherMap(params.weatherMapID));

    }

    return () => {

      dispatch(resetWeatherMap());

    };

  }, [params.weatherMapID]);

  useEffect(() => {

    if (!weatherMap.id) {

      return;

    }
    setLoading(false);

    const dateNow = new Date();
    lastUpdateDate = new Date(dateNow.getTime() - 5000);

    const usejti = weatherMap.edges.some((edge) => edge.data_source === 'jti');
    const useinflux = weatherMap.edges.some((edge) => edge.data_source === 'influx' || edge.data_source === undefined);

    let timer;

    if (useinflux) {

      getInfluxTraffic();
      timer = setInterval(() => getInfluxTraffic(), 30000);

    } else {

      setTrafficLoading(false);

    }
    if (usejti) {

      dispatch(subscribeWeatherMap(weatherMap.id));

    }

    return () => {

      if (timer) {

        clearInterval(timer);

      }
      dispatch(unsubscribeWeatherMap());

    };

  }, [weatherMap.id]);

  const edgeTypes = Object.entries(edgeThicknessMap).map(([name, width]) => ({
    text: name,
    strokeWidth: width,
  }));

  const nodeTypes = nodeLegendData.map((node) => ({
    text: node.label,
    stroke: node.color,
    fill: node.color,
    radius: node.radius,
    classed: node.classed,
  }));

  const colorSwatches = edgeColorMap.map((color) => ({
    text: color.label,
    stroke: color.color,
    fill: color.color,
  }));

  const nodeShapeMap = Object.fromEntries(weatherMap.nodes.filter((node) => node.name?.match(/^to/))
    .map((node) => [node.name, 'square']));

  const mapSelection = {
    nodes: selectedNode ? [selectedNode.id] : [],
    edges: selectedEdgeId ? [selectedEdgeId] : [],
  };

  const unsetNodeId = () => {

    setSelectedNode(undefined);

  };

  const handleSelectionChanged = (selectionType, selection) => {

    if (selectionType === 'edge') {

      dispatch(getWeatherMapEdge(weatherMap.id, selection));
      unsetNodeId();
      setSelectedEdgeId(selection);

    }
    if (selectionType === 'node') {

      setSelectedEdgeId('');
      const node = weatherMap.nodes.find((node) => node.id === selection);
      if (node && (node.type === 'hub' || !node.location)) {

        return;

      }
      setSelectedNode(node);

    }

  };

  const findBEnd = (host, inter) => {

    if (weatherMap.edges !== undefined) {

      for (let i = 0; i < weatherMap.edges.length; i++) {

        const pl = weatherMap.edges[i].source_int.split(',');
        for (let j = 0; j < pl.length; j++) {

          if (weatherMap.edges[i].source === host && pl[j] === inter) {

            return weatherMap.edges[i].target;

          }

        }

      }

    }
    return null;

  };

  const getEdgeTrafficFromEventSeries = (events) => {

    const eventSeries = new TimeSeries({
      name: 'raw',
      events: events.toArray(),
    });
    const rate = eventSeries.rate({ fieldSpec: ['octets'] }).atLast();
    if (rate !== undefined) {

      const last = moment(eventSeries.end());
      const duration = moment.duration(moment(new Date()).diff(last));

      return (duration.asSeconds() <= 45) ? rate.get('octets_rate') * 8 : undefined;

    }

  };

  const getEdgeTraffic = () => {

    const edgeTraffic = {};

    if (Object.keys(jtiData).length > 0) {

      Object.keys(jtiData).forEach((r) => {

        Object.keys(jtiData[r]).forEach((pl) => {

          pl.split(',').forEach((p) => {

            const target = findBEnd(r, p);

            if (target !== null) {

              if (edgeTraffic[`${r}--${target}`] === undefined) {

                edgeTraffic[`${r}--${target}`] = 0;

              }
              if (edgeTraffic[`${target}--${r}`] === undefined) {

                edgeTraffic[`${target}--${r}`] = 0;

              }

              edgeTraffic[`${r}--${target}`] = getEdgeTrafficFromEventSeries(jtiData[r][p].egress);
              edgeTraffic[`${target}--${r}`] = getEdgeTrafficFromEventSeries(jtiData[r][p].ingress);

            }

          });

        });

      });

    }
    return { ...weatherMapTraffic, ...edgeTraffic };

  };
  const edgeTraffic = getEdgeTraffic();
  const traffic = new TimeEvent(1431649302 * 1000, Immutable.Map(edgeTraffic));

  const edgeModeMap = {};
  weatherMap.edges.forEach((edge) => {

    if (edgeTraffic[`${edge.source}--${edge.target}`] === undefined) {

      edgeModeMap[`${edge.source}--${edge.target}`] = 'nodata';

    }
    if (edge.source_int === undefined) {

      edgeModeMap[`${edge.source}--${edge.target}`] = 'dashed';

    }

  });

  const removeEdge = () => {

    dispatch(setWeatherMapEdge({}));

  };

  return (
    <>
      <Row>
        <Col className="d-flex justify-content-end">
          <UncontrolledButtonDropdown size="sm" className="mt-n4">
            <DropdownToggle caret color="secondary">
              Choose Weather Map
            </DropdownToggle>
            <DropdownMenu>
              {weatherMapsList.map((weatherMapItem) => (
                <DropdownItem
                  key="none"
                  style={{ fontWeight: (weatherMap && weatherMapItem.id === weatherMap.id) ? 'bold' : 'normal' }}
                  onClick={() => navigate(`/weather-maps/${weatherMapItem.id}`)}
                >
                  {weatherMapItem.name}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </Col>
      </Row>
      <SimpleLoader loading={loading || trafficLoading}>
        {!loading && !trafficLoading ? (
          <Section title={weatherMap.name}>
            <Row className="mb-2">
              <Col className="d-flex justify-content-end">
                <Button
                  size="sm"
                  color="info"
                  onClick={() => setShowLegend(!showLegend)}
                >
                  {showLegend ? 'Hide' : 'Show'}
                  {' '}
                  Legend
                </Button>
              </Col>
            </Row>
            <Row>
              <Col lg={showLegend ? 9 : 12} xl={showLegend ? 10 : 12}>
                <Card>
                  <CardBody style={{ borderRadius: '10px' }}>
                    <TrafficMap
                      bounds={bounds}
                      topology={weatherMap}
                      traffic={traffic}
                      edgeColorMap={edgeColorMap}
                      edgeColorMode="percent"
                      edgeDrawingMethod="bidirectionalArrow"
                      edgeThicknessMap={edgeThicknessMap}
                      nodeShapeMap={nodeShapeMap}
                      nodeSizeMap={nodeSizeMap}
                      stylesMap={stylesMap}
                      selection={mapSelection}
                      onSelectionChange={handleSelectionChanged}
                      edgeModeMap={edgeModeMap}
                      labels={{}}
                    />
                  </CardBody>
                </Card>
              </Col>
              {showLegend ? (
                <Col lg={3} xl={2}>
                  <Card>
                    <CardBody>
                      <Row style={{ maxWidth: '500px' }}>
                        <Col xs={4} lg={12}>
                          <svg width="100%" height="175" className="map-legend">
                            <MapLegend
                              x={8}
                              itemsPerColumn={100}
                              nodeTypes={nodeTypes}
                              edgeColor="#6D6E71"
                              exampleWidth={15}
                              columns={false}
                            />
                          </svg>
                        </Col>
                        <Col xs={4} lg={12}>
                          <svg width="100%" height="190" className="map-legend">
                            <MapLegend
                              itemsPerColumn={100}
                              edgeTypes={edgeTypes}
                              edgeColor="#6D6E71"
                              exampleWidth={15}
                              columns={false}
                            />
                          </svg>
                        </Col>
                        <Col xs={4} lg={12}>
                          <svg width="100%" height="170" className="map-legend">
                            <MapLegend
                              itemsPerColumn={100}
                              colorSwatches={colorSwatches}
                              edgeColor="#6D6E71"
                              exampleWidth={15}
                              columns={false}
                            />
                          </svg>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              ) : ''}
            </Row>
            <Modal size="xlg" isOpen={selectedEdgeId !== ''} toggle={() => setSelectedEdgeId('')} onClosed={removeEdge}>
              <ModalHeader toggle={() => setSelectedEdgeId('')} />
              <ModalBody className="bg-light-nav" style={{ minHeight: '100px' }}>
                <SimpleLoader loading={_.isEmpty(edge)}>
                  {!_.isEmpty(edge) ? (
                    <WeatherMapEdge
                      edge={edge}
                      traffic={edgeTraffic}
                      jtiData={jtiData}
                    />
                  ) : ''}
                </SimpleLoader>
              </ModalBody>
            </Modal>
            <Modal size="xlg" isOpen={Boolean(selectedNode && selectedNode.monitoredItemId)} toggle={unsetNodeId}>
              <ModalHeader toggle={unsetNodeId} />
              <ModalBody className="bg-light-nav" style={{ minHeight: '100px' }}>
                {selectedNode && selectedNode.monitoredItemId ? (
                  <MonitoredItem id={selectedNode.monitoredItemId} />
                ) : ''}
              </ModalBody>
            </Modal>
            <Modal
              size="sm"
              isOpen={selectedNode && (!selectedNode.monitoredItemId && selectedNode.location !== '')}
              toggle={unsetNodeId}
            >
              <ModalHeader toggle={unsetNodeId} className="border-bottom-0" />
              <ModalBody style={{ minHeight: '50px' }}>
                {selectedNode && selectedNode.location ? (
                  <>
                    <p className="text-center">
                      hSo Node -
                      {selectedNode.name}
                    </p>
                    <p className="text-center">{selectedNode.location}</p>
                  </>
                ) : ''}
              </ModalBody>
            </Modal>
          </Section>
        ) : ''}
      </SimpleLoader>
    </>
  );

};

function mapStateToProps({ weatherMaps, weatherMap, JTI }) {

  return {
    weatherMapsList: weatherMaps.weatherMaps,
    weatherMap: weatherMap.weatherMap,
    weatherMapTraffic: weatherMap.traffic,
    edge: weatherMap.edge,
    timestamp: weatherMap.timestamp,
    jtiData: JTI.data,
  };

}

const shouldNotUpdate = (prevProps, nextProps) => {

  if (prevProps.jtiData !== nextProps.jtiData) {

    const now = new Date();
    const seconds = (now.getTime() - lastUpdateDate.getTime()) / 1000;
    if (seconds < 1) {

      return true;

    }

  }
  return prevProps === nextProps;

};

export default connect(mapStateToProps)(React.memo(WeatherMap, shouldNotUpdate));
