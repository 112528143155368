import { apiRequest } from '../Api/ApiRequest';

export function api_createNameserver(domainId, nameserver) {

  return apiRequest({
    url: `/services/domains/${domainId}/nameservers`,
    method: 'post',
    headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
    data: {
      nameserver,
    },
  }).then(
    resp => resp,
  ).catch(
    error => error,
  );

}

export function api_deleteNameserver(domainId, nameserver) {

  return apiRequest({
    url: `/services/domains/${domainId}/nameservers`,
    method: 'delete',
    headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
    data: {
      nameserver,
    },
  }).then(
    resp => resp,
  ).catch(
    error => error,
  );

}

export function api_assignNameservers(domainId, nameservers) {

  return apiRequest({
    url: `/services/domains/${domainId}/nameservers/assign`,
    method: 'post',
    headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
    data: {
      nameservers,
    },
  }).then(
    resp => resp,
  ).catch(
    error => error,
  );

}

export function modifyNameserver(domainId, nameserver) {

  return apiRequest({
    url: `/services/domains/${domainId}/nameservers`,
    method: 'put',
    headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
    data: {
      nameserver,
    },
  }).then(
    resp => resp,
  ).catch(
    error => error,
  );

}
