import React, { FunctionComponent, PropsWithChildren } from 'react';
import Header from './Header';

type IOwnProps = {
  title: string;
  item: IItem;
  descriptionEditable: boolean;
  descriptionFormField: string;
  toggleEditDesc: () => any;
  saveDesc: () => any;
  onDescChange: (event: React.ChangeEvent<HTMLInputElement>) => any;
  userCanEdit: boolean;
  buttons?: Array<JSX.Element>;
};
type IItem = {
  name: string;
  description: string;
};
const Section: FunctionComponent<IOwnProps & PropsWithChildren> = ({
  title,
  children,
  item,
  descriptionEditable,
  descriptionFormField,
  toggleEditDesc,
  saveDesc,
  onDescChange,
  userCanEdit,
  buttons,
}) => (
  <div>
    <Header
      item={item}
      descriptionEditable={descriptionEditable}
      descriptionFormField={descriptionFormField}
      toggleEditDesc={toggleEditDesc}
      saveDesc={saveDesc}
      onDescChange={onDescChange}
      userCanEdit={userCanEdit}
      title={title}
      buttons={buttons}
    />
    {children}
  </div>
);

export default Section;
