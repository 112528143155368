import { Moment } from 'moment';
import fileDownload from 'js-file-download';
import {
  IMonitoredDeviceRevertDesc,
  IMonitoredItemOnDescChange,
  IMonitoredItemSetDescError,
  IMonitoredItemToggleEdit,
  IMonitoredItemToggleGraphOpenAction,
  ISetMonitoredITemGraphDateRangeAction,
  MONITORED_ITEM_DESC_SAVE,
  MONITORED_ITEM_NT_SET_PING_DATA,
  MONITORED_ITEM_NT_SET_SHOW_ROUTE_DATA,
  MONITORED_ITEM_NT_SET_SHOW_ROUTE_DETAILS,
  MONITORED_ITEM_NT_SET_TRACE_ROUTE_DATA,
  MONITORED_ITEM_RESET_POPUP_GRAPH,
  MONITORED_ITEM_RESET_STATE,
  MONITORED_ITEM_REVERT_DESC,
  MONITORED_ITEM_SET_DESC_ERROR,
  MONITORED_ITEM_SET_DESCRIPTION_FORM,
  MONITORED_ITEM_SET_GRAPH_DATA,
  MONITORED_ITEM_SET_GRAPH_DATE_RANGE,
  MONITORED_ITEM_SET_MONITORED_ITEM,
  MONITORED_ITEM_SET_POPUP_GRAPH_DATA,
  MONITORED_ITEM_TOGGLE_DESC_EDIT,
  MONITORED_ITEM_TOGGLE_GRAPH_LOADING,
  MONITORED_ITEM_TOGGLE_GRAPH_OPEN,
  MONITORED_ITEM_TOGGLE_LOADING,
  MONITORED_ITEM_TOGGLE_POPUP_GRAPH,
  MONITORED_ITEM_TOGGLE_POPUP_GRAPH_LOADING,
} from './types';
import {
  api_createMonitoredDeviceReport, api_downloadMonitoredDeviceReport,
  api_getMonitoredGraphData,
  api_getMonitoredItem,
  api_getMonitoredItemHistory,
  api_getNetworkToolsPing,
  api_getNetworkToolsShowRoute,
  api_getNetworkToolsShowRouteDetails,
  api_getNetworkToolsTraceRoute, api_updateDeviceReport,
  api_updateMonitoredDeviceDesc,
} from '../../utils/Monitoring/Monitoring';
import { defaultErrorFeedback, errorFeedback, successFeedback } from '../../actions/feedback';
import { setSystemError } from '../system/actions';
import { TTrafficData } from '../monitoring/types';
import { ReportConfigFrequency, TDeviceUsageReport } from '../reports/types';
import { updateReportInList } from '../reports/actions';

export function toggleMonitoredItemLoading() {

  return {
    type: MONITORED_ITEM_TOGGLE_LOADING,
  };

}
export function resetMonitoredItemState() {

  return {
    type: MONITORED_ITEM_RESET_STATE,
  };

}
export function toggleMonitoredItemPopUpGraph() {

  return {
    type: MONITORED_ITEM_TOGGLE_POPUP_GRAPH,
  };

}
export function setPopUpGraphData(key, value) {

  return {
    type: MONITORED_ITEM_SET_POPUP_GRAPH_DATA,
    key,
    value,
  };

}
export function toggleMonitoredItemPopUpGraphLoading() {

  return {
    type: MONITORED_ITEM_TOGGLE_POPUP_GRAPH_LOADING,
  };

}
export function resetMonitoredItemPopUpGraph() {

  return {
    type: MONITORED_ITEM_RESET_POPUP_GRAPH,
  };

}
export function setMonitoredItemGraphDateRange(graphId, startDate: Moment, endDate: Moment): ISetMonitoredITemGraphDateRangeAction {

  return {
    type: MONITORED_ITEM_SET_GRAPH_DATE_RANGE,
    graphId,
    startDate,
    endDate,
  };

}
export function setMonitoredItemGraphData(graphId, dataIn: TTrafficData, dataOut: TTrafficData) {

  return {
    type: MONITORED_ITEM_SET_GRAPH_DATA,
    dataIn,
    dataOut,
    graphId,
  };

}
export function toggleMonitoredItemGraphLoading(graphId) {

  return {
    type: MONITORED_ITEM_TOGGLE_GRAPH_LOADING,
    graphId,
  };

}
function setMonitoredItem(item) {

  return {
    type: MONITORED_ITEM_SET_MONITORED_ITEM,
    item,
  };

}
export function getMonitoredItem(id, include: string[] = []) {

  return (dispatch) => api_getMonitoredItem(id, include).then((result) => {

    if (result.status === 200) {

      dispatch(setMonitoredItem(result.data));
      return result;
    
    }

    if (result.data.error && result.data.error.id) {

      dispatch(setSystemError(result.data.error.id));
    
    }
    return false;
  
  });

}
export function getMonitoredDeviceItemHistory(deviceId, item, multiplier, from, to) {

  return (dispatch) => api_getMonitoredItemHistory(deviceId, item, from.valueOf(), to.valueOf()).then((result) => {

    if (result.status === 200) {

      dispatch(setPopUpGraphData('history', result.data));
    
    } else {

      dispatch(errorFeedback(`Could not fetch graph item: ${item}`));
    
    }
  
  });

}
export function getMonitoredItemGraph(itemId, graphId, from, to) {

  return (dispatch) => api_getMonitoredGraphData(itemId, graphId, from, to).then((result) => {

    if (result.status === 200) {

      dispatch(setMonitoredItemGraphData(graphId, result.data[0], result.data[1]));
    
    } else {

      dispatch(errorFeedback(`Could not fetch graph item: ${graphId}`));
    
    }
  
  });

}
export const toggleDescEditMonitoredItem = (): IMonitoredItemToggleEdit => ({ type: MONITORED_ITEM_TOGGLE_DESC_EDIT });
export const onDescChangeMonitoredItem = (payload): IMonitoredItemOnDescChange => ({ type: MONITORED_ITEM_SET_DESCRIPTION_FORM, payload });
export const setMonitoredItemErrors = (payload): IMonitoredItemSetDescError => ({ type: MONITORED_ITEM_SET_DESC_ERROR, payload });
export const saveMonitoredItemDesc = (deviceId, description) => (dispatch) => {

  dispatch({ type: MONITORED_ITEM_DESC_SAVE, description, deviceId });
  return api_updateMonitoredDeviceDesc(deviceId, description).then((result) => {

    if (result.status !== 200) {

      dispatch(revertMonitoredDeviceDesc(deviceId, description));
      dispatch(defaultErrorFeedback());
    
    }
  
  });

};
export function toggleGraphOpen(payload): IMonitoredItemToggleGraphOpenAction {

  return {
    type: MONITORED_ITEM_TOGGLE_GRAPH_OPEN,
    payload,
  };

}
export const revertMonitoredDeviceDesc = (deviceId, desc): IMonitoredDeviceRevertDesc => ({ type: MONITORED_ITEM_REVERT_DESC, desc, deviceId });

export const resetMonitoredItemNTPingData = () => ({
  type: MONITORED_ITEM_NT_SET_PING_DATA,
});

export const getMonitoredItemNTPingData = (deviceId: string, destination: string, vrf?: string) => (dispatch) => api_getNetworkToolsPing(deviceId, destination, vrf)
  .then((result) => {

    if (result.status === 200) {

      dispatch({
        type: MONITORED_ITEM_NT_SET_PING_DATA,
        data: result.data,
      });
      return result.data;
    
    }
    dispatch(defaultErrorFeedback());
    return false;
  
  });

export const resetMonitoredItemNTTraceRouteData = () => ({
  type: MONITORED_ITEM_NT_SET_TRACE_ROUTE_DATA,
});

export const getMonitoredItemNTTraceRouteData = (deviceId: string, destination: string, vrf?: string) => (dispatch) => api_getNetworkToolsTraceRoute(deviceId, destination, vrf)
  .then((result) => {

    if (result.status === 200) {

      dispatch({
        type: MONITORED_ITEM_NT_SET_TRACE_ROUTE_DATA,
        data: result.data,
      });
      return result.data;
    
    }
    dispatch(defaultErrorFeedback());
    return false;
  
  });

export const resetMonitoredItemNTShowRouteData = () => ({
  type: MONITORED_ITEM_NT_SET_SHOW_ROUTE_DATA,
});

export const getMonitoredItemNTShowRouteData = (deviceId: string, destination: string, protocol: number, vrf?: string) => (dispatch) => api_getNetworkToolsShowRoute(deviceId, destination, protocol, vrf)
  .then((result) => {

    if (result.status === 200) {

      dispatch({
        type: MONITORED_ITEM_NT_SET_SHOW_ROUTE_DATA,
        data: result.data,
      });
      return result.data;
    
    }
    dispatch(defaultErrorFeedback());
    return false;
  
  });

export const resetMonitoredItemNTShowRouteDetails = () => ({
  type: MONITORED_ITEM_NT_SET_SHOW_ROUTE_DETAILS,
});

export const getMonitoredItemNTShowRouteDetails = (deviceId: string, destination: string, protocol: number, vrf?: string) => (dispatch) => api_getNetworkToolsShowRouteDetails(deviceId, destination, protocol, vrf)
  .then((result) => {

    if (result.status === 200) {

      dispatch({
        type: MONITORED_ITEM_NT_SET_SHOW_ROUTE_DETAILS,
        data: result.data,
      });
      return result.data;
    
    }
    dispatch(defaultErrorFeedback());
    return false;
  
  });

export const createMonitoredDeviceReport = (deviceId: string, report: Partial<TDeviceUsageReport>) => (dispatch) => api_createMonitoredDeviceReport(deviceId, report)
  .then((result) => {

    if (result.status === 200) {

      return result.data;
    
    }
    dispatch(defaultErrorFeedback());
    return false;
  
  });

export function updateMonitoredDeviceReport(deviceId, id, data) {

  return (dispatch) => api_updateDeviceReport(deviceId, id, data).then((result) => {

    if (result.status === 200) {

      dispatch(updateReportInList(result.data));
      dispatch(successFeedback('Report updated'));
      return true;
    
    }

    dispatch(defaultErrorFeedback());
    return false;
  
  });

}

export function downloadMonitoredDeviceReport(deviceId, id) {

  return (dispatch) => api_downloadMonitoredDeviceReport(deviceId, id).then((result) => {

    if (result.status === 200) {

      const splitContentDisposition = result.headers['content-disposition'].replaceAll('"', '').split('=');
      fileDownload(result.data, splitContentDisposition[1]);
      return true;
    
    }

    dispatch(defaultErrorFeedback());
    return false;
  
  });

}
