export const PRICE_LIST_SET_PRICE_LIST = 'PRICE_LIST_SET_PRICE_LIST';

export interface IPriceListState {
  data: TPriceList | null;
}

export type TPriceList = {
  id: number;
  name: string;
  items: TPriceListItem[];
};

type TPriceListItemCommon = {
  id: number;
  name: string;
  description: string;
  productCode: string;
};
type TStandardPriceListItem = TPriceListItemCommon & {
  pricing: 'standard';
};
type TUnitPriceListItem = TPriceListItemCommon & {
  pricing: 'unit';
  unit: string;
};
export type TPriceListItem = TStandardPriceListItem | TUnitPriceListItem;

export interface ISetPriceListAction {
  type: typeof PRICE_LIST_SET_PRICE_LIST;
  data: TPriceList;
}

export type PriceListActionTypes = ISetPriceListAction;
