/* eslint-disable */
export const formatData = (bits, withSize = true) => {

  const sizes = ['b', 'Kb', 'Mb', 'Gb', 'Tb'];
  if (bits == 0) return '0 b';
  const i = parseInt(Math.floor(Math.log(bits) / Math.log(1024)));
  return `${(bits / Math.pow(1024, i)).toFixed(2)}${withSize ? ` ${sizes[i]}` : ''}`;

};

export const formatbps = (bits, dp = 4) => {

  if (bits === undefined) {

    return '-';

  }
  const sizes = ['bps', 'Kbps', 'Mbps', 'Gbps', 'Tbps'];
  if (bits === 0 || bits === '0' ) return '0 bps';
  const i = parseInt(String(Math.floor(Math.log(Math.abs(bits)) / Math.log(1000))), 10);
  if (i <= 0) return `${bits} bps`;
  return `${(bits / (1000 ** i)).toFixed(dp)} ${sizes[i]}`;

};

export const formatCompactNumber = (number) => {

  if (number === undefined) {

    return '-';

  }
  const sizes = ['', 'K', 'M', 'G', 'T'];
  if (number === 0 || number === '0' ) return '0 bps';
  const i = parseInt(String(Math.floor(Math.log(Math.abs(number)) / Math.log(1000))), 10);
  if (i <= 0) return `${number}`;
  return `${(number / (1000 ** i))}${sizes[i]}`;

}
