import Ring from 'ringjs';
import { TimeEvent } from 'pondjs';
import update, { extend } from 'immutability-helper';
import {
  JTISTATUS, JTIINGRESS, JTIEGRESS, JTIEGRESSERRORS, JTIINGRESSERRORS, RESETJTIDATA, SAVEJTISUBS,
} from './types';

const initialState = {
  data: {},
  subs: {},
};

export const JTIReducer = (state = initialState, action) => {

  extend('$auto', (value, object) => (object
    ? update(object, value)
    : update({}, value)));
  extend('$autoArray', (value, object) => (object
    ? update(object, value)
    : update([], value)));
  if (action.loopback !== undefined) {

    if (state.data[action.loopback] === undefined || state.data[action.loopback][action.interface] === undefined) {

      state = update(state, {
        data: {
          $autoArray: {
            [action.loopback]: {
              $auto: {
                [action.interface]: {
                  $set: {
                    status: {
                      time: null,
                      state: null,
                    },
                    ingress: new Ring(3),
                    egress: new Ring(3),
                    ingresserrors: new Ring(3),
                    egresserrors: new Ring(3),
                  },
                },
              },
            },
          },
        },
      });
    
    }
  
  }
  switch (action.type) {
    case SAVEJTISUBS:
      return update(state, { subs: { $set: action.payload } });
    case RESETJTIDATA:
      return initialState;
    case JTISTATUS:
      return update(state, {
        data: {
          [action.loopback]: {
            [action.interface]: {
              status: {
                $set: {
                  time: action.time,
                  state: action.status,
                },
              },
            },
          },
        },
      });
    case JTIINGRESS:
      const newStats = state.data[action.loopback][action.interface].ingress;
      const last = newStats.toArray()[newStats.toArray().length - 1];
      if (last !== undefined) {

        if (last.begin().getTime() === new Date(action.time).getTime()) {

          return state;
        
        }
      
      }
      const event = new TimeEvent(new Date(action.time), action);
      newStats.push(event);
      return update(state, {
        data: {
          [action.loopback]: {
            [action.interface]: {
              ingress: {
                $set: newStats,
              },
            },
          },
        },
      });
    case JTIINGRESSERRORS:
      const newErrorStats = state.data[action.loopback][action.interface].ingresserrors;
      const lasterr = newErrorStats.toArray()[newErrorStats.toArray().length - 1];
      if (lasterr !== undefined) {

        if (lasterr.begin().getTime() === new Date(action.time).getTime()) {

          return state;
        
        }
      
      }
      const evente = new TimeEvent(new Date(action.time), action);
      newErrorStats.push(evente);
      return update(state, {
        data: {
          [action.loopback]: {
            [action.interface]: {
              ingresserrors: {
                $set: newErrorStats,
              },
            },
          },
        },
      });
    case JTIEGRESS:
      const newEStats = state.data[action.loopback][action.interface].egress;
      const elast = newEStats.toArray()[newEStats.toArray().length - 1];
      if (elast !== undefined) {

        if (elast.begin().getTime() === new Date(action.time).getTime()) {

          return state;
        
        }
      
      }
      const eevent = new TimeEvent(new Date(action.time), action);
      newEStats.push(eevent);
      return update(state, {
        data: {
          [action.loopback]: {
            [action.interface]: {
              egress: {
                $set: newEStats,
              },
            },
          },
        },
      });
    case JTIEGRESSERRORS:
      const neweErrorStats = state.data[action.loopback][action.interface].egresserrors;
      const elasterr = neweErrorStats.toArray()[neweErrorStats.toArray().length - 1];
      if (elasterr !== undefined) {

        if (elasterr.begin().getTime() === new Date(action.time).getTime()) {

          return state;
        
        }
      
      }
      const eevente = new TimeEvent(new Date(action.time), action);
      neweErrorStats.push(eevente);
      return update(state, {
        data: {
          [action.loopback]: {
            [action.interface]: {
              egresserrors: {
                $set: neweErrorStats,
              },
            },
          },
        },
      });
    default:
      return state;
  }

};
