import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Col, Row } from 'reactstrap';
import { Bar, Pie } from 'react-chartjs-2';
import * as filesize from 'filesize';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import Section from '../../../components/Section';
import { IServiceBroadbandState } from '../../../store/serviceBroadband/types';
import SimpleLoader from '../../../components/SimpleLoader';
import {
  getBroadbandService,
  getBroadbandServiceUsage, resetServiceBroadbandData, setServiceBroadbandUsageDates,
  toggleServiceBroadbandLoading, toggleServiceBroadbandUsageLoading,
} from '../../../store/serviceBroadband/actions';
import TextInput from '../../../components/Inputs/TextInput';
import { AppDispatch } from '../../../configureStore';

type TOwnProps = {
  dispatch: AppDispatch;
  broadband: IServiceBroadbandState;
};
type TProps = TOwnProps;
const Broadband: React.FC<TProps> = (props) => {

  const { broadband, dispatch } = props;
  const params = useParams();

  useEffect(() => {

    dispatch(resetServiceBroadbandData());
    dispatch(toggleServiceBroadbandLoading());
    dispatch(getBroadbandService(params.id)).then(
      (result) => {

        dispatch(toggleServiceBroadbandLoading());
        if (result) {

          getUsage(moment().month() + 1, moment().year());
        
        }
      
      },
    );
  
  }, []);
  const getUsage = (month?, year?) => {

    const useMonth = month || broadband.usage.currentDate.month() + 1;
    const useYear = year || broadband.usage.currentDate.year();
    dispatch(toggleServiceBroadbandUsageLoading());
    dispatch(getBroadbandServiceUsage(params.id, useMonth, useYear)).then(() => dispatch(toggleServiceBroadbandUsageLoading()));
  
  };
  const getPrevMonth = () => {

    const currDate = broadband.usage.prevMonth.clone();
    const prevMonth = broadband.usage.prevMonth.clone().subtract(1, 'months');
    const nextMonth = broadband.usage.nextMonth.clone().subtract(1, 'months');
    dispatch(setServiceBroadbandUsageDates(currDate, prevMonth, nextMonth));
    getUsage(currDate.month() + 1, currDate.year());
  
  };
  const getNextMonth = () => {

    const currDate = broadband.usage.nextMonth.clone();
    const prevMonth = broadband.usage.prevMonth.clone().add(1, 'months');
    const nextMonth = broadband.usage.nextMonth.clone().add(1, 'months');
    dispatch(setServiceBroadbandUsageDates(currDate, prevMonth, nextMonth));
    getUsage(currDate.month() + 1, currDate.year());
  
  };
  const isNextDisabled = () => moment().isBefore(broadband.usage.nextMonth);
  const pieChart = { datasets: [{ data: [broadband.usage.pieChart.download, broadband.usage.pieChart.upload], backgroundColor: ['#6BA4B8', '#FF9E1B'] }], labels: ['Download', 'Upload'] };
  const barChart = {
    labels: broadband.usage.barChart.labels,
    datasets: [
      { data: broadband.usage.barChart.download, label: 'Download', backgroundColor: '#6BA4B8' },
      { data: broadband.usage.barChart.upload, label: 'Upload', backgroundColor: '#FF9E1B' },
    ],
  };
  return (
    <div className="animated fadeIn mb-5">
      <SimpleLoader loading={broadband.loading}>
        <Row>
          <Col>
            <Section title={broadband.data.name}>
              <Row>
                <Col md={8} className="d-flex">
                  <div className="bg-white rounded shadow-sm pt-4 pl-4 pb-4 pr-3 d-flex flex-column w-100" style={{ borderRadius: '10px' }}>
                    <SimpleLoader loading={broadband.usage.loading}>
                      <div>
                        <Row>
                          <Col md={6}>
                            <div className="text-light h2 mb-3">
                              Traffic report
                              {broadband.usage.currentDate.format('MM/YYYY')}
                            </div>
                            <TextInput id="download" label="Download" value={filesize(broadband.usage.pieChart.download)} readOnly textAlignRight textColor="primary" />
                            <TextInput id="upload" label="Upload" value={filesize(broadband.usage.pieChart.upload)} readOnly textAlignRight textColor="warning" />
                            <TextInput id="total" label="Total Transfer" value={filesize(broadband.usage.pieChart.download + broadband.usage.pieChart.upload)} readOnly textAlignRight />
                          </Col>
                          <Col md={6} className="chart-wrapper" style={{ height: `${30}vh`, marginTop: `${20}px` }}>
                            <Pie
                              data={pieChart}
                              options={{
                                maintainAspectRatio: false,
                                legend: {
                                  display: false,
                                },
                                tooltips: {
                                  callbacks: {
                                    label(tooltipItems, data) {

                                      const label = tooltipItems.index === 0 ? 'Download' : 'Upload';
                                      return `${label}: ${filesize(data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index])}`;
                                    
                                    },
                                  },
                                },
                              }}
                            />
                          </Col>
                        </Row>
                      </div>
                      <div className="chart-wrapper" style={{ height: `${40}vh`, marginTop: `${20}px` }}>
                        <Bar
                          data={barChart}
                          options={{
                            maintainAspectRatio: false,
                            legend: {
                              display: true,
                              position: 'bottom',
                            },
                            scales: {
                              xAxes: [{
                                stacked: false,
                              }],
                              yAxes: [{
                                stacked: false,
                                ticks: {
                                  precision: 0,
                                  beginAtZero: true,
                                  maxTicksLimit: 6,
                                  stepSize: 0.5,
                                  callback(value, index, values) {

                                    if (value === 1000000000) {

                                      return '1 GB';
                                    
                                    }
                                    return filesize(value, { base: 10 });
                                  
                                  },
                                },
                              }],
                            },
                            tooltips: {
                              callbacks: {
                                label(tooltipItems, data) {

                                  const label = tooltipItems.datasetIndex === 0 ? 'Download' : 'Upload';
                                  return `${label}: ${filesize(tooltipItems.yLabel)}`;
                                
                                },
                              },
                            },
                          }}
                        />
                      </div>
                      <div className="d-flex justify-content-between">
                        <Button
                          disabled={broadband.usage.loading}
                          color="primary"
                          className="mr-1"
                          onClick={getPrevMonth}
                        >
                          <i className="icon-arrow-left mr-2" />
                          {broadband.usage.prevMonth.format('MM/YYYY')}
                        </Button>
                        {!isNextDisabled()
                                                    && (
                                                    <Button
                                                      disabled={broadband.usage.loading}
                                                      color="primary"
                                                      onClick={getNextMonth}
                                                    >
                                                      {broadband.usage.nextMonth.format('MM/YYYY')}
                                                      <i className="icon-arrow-right ml-2" />
                                                    </Button>
                                                    )}
                      </div>
                    </SimpleLoader>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="bg-white rounded shadow-sm pt-4 pl-4 pb-4 pr-3 d-flex flex-column w-100" style={{ borderRadius: '10px' }}>
                    <TextInput id="status" label="Status" value={broadband.data.status} readOnly textAlignRight textColor={broadband.data.status === 'online' ? 'success' : 'danger'} />
                    <TextInput id="product" label="Product Code" value={broadband.data.productCode} readOnly textAlignRight />
                    <TextInput id="transferLimit" label="Transfer Limit" value={broadband.data.transferLimit > 0 ? `${broadband.data.transferLimit}GB` : 'Unlimited'} readOnly textAlignRight />
                    <TextInput id="username" label="Username" value={broadband.data.username} readOnly textAlignRight />
                    <TextInput id="pstn" label="PSTN" value={broadband.data.pstn} readOnly textAlignRight />
                    {broadband.data.ips.ipv4
                                            && <TextInput id="ipv4" label="Ipv4" value={broadband.data.ips.ipv4} readOnly textAlignRight />}
                    {broadband.data.ips.ipv4Route
                                            && <TextInput id="ipv4Route" label="Ipv4Route" value={broadband.data.ips.ipv4Route} readOnly textAlignRight />}
                    {broadband.data.ips.ipv6
                                            && <TextInput id="ipv6" label="Ipv6" value={broadband.data.ips.ipv6} readOnly textAlignRight />}
                  </div>
                </Col>
              </Row>
            </Section>
          </Col>
        </Row>
      </SimpleLoader>
    </div>
  );

};
function mapStateToProps({ serviceBroadband }) {

  return {
    broadband: serviceBroadband,
  };

}
export default connect(mapStateToProps)(Broadband);
