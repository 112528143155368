import { useLocation } from 'react-router-dom';
import React from 'react';

type TFields = {
  [key: string]: string | Array<string>;
};

export function queryStringFromFields(fields: TFields): string {

  const fieldsStrings = Object.entries(fields).map(([key, value]) => {

    if (Array.isArray(value)) {

      return value.map((v) => `${key}[]=${v}`).join('&');
    
    }
    return `${key}=${value}`;
  
  });
  const queryString = fieldsStrings.join('&');

  return queryString.length ? `?${queryString}` : '';

}

export function useQuery() {

  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);

}
