import React, { FunctionComponent } from 'react';
import { FormGroup, Label } from 'reactstrap';
import { RegionDropdown } from 'react-country-region-selector';

type Props = {
  onChange: (value: string) => any;
  value?: string;
  country: string;
};

const RegionSelect: FunctionComponent<Props> = (props) => {

  const handleChange = (value: string) => {

    props.onChange(value);
  
  };

  return (
    <FormGroup row>
      <Label className="col-sm-4 text-light col-form-label font-weight-bold" for="Region" style={{ fontSize: '16px' }}>State</Label>
      <div className="col-sm-8">
        <RegionDropdown
          valueType="short"
          value={props.value || 'AL'}
          classes="form-control"
          onChange={(val) => handleChange(val)}
          countryValueType="short"
          country={props.country}
        />
      </div>
    </FormGroup>
  );

};
export default RegionSelect;
