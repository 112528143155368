import cloneDeep from 'lodash.clonedeep';
import {
  CONTACT_ADD_SITE_TYPE,
  CONTACT_SET_SITES,
  CONTACT_INIT_CONTACT,
  CONTACT_REMOVE_SITE_TYPE,
  CONTACT_RESET_CONTACT,
  CONTACT_RESET_ERROR_MESSAGE,
  CONTACT_SET_CONTACT,
  CONTACT_SET_ERROR_MESSAGE,
  CONTACT_SET_ERRORS,
  CONTACT_SET_FORM_READ_ONLY,
  CONTACT_SET_ORIGINAL_DATA,
  CONTACT_SYNC_TYPE_TO_ALL_SITE,
  CONTACT_TOGGLE_INVITE_MODAL,
  CONTACT_TOGGLE_LOADING,
  CONTACT_TOGGLE_READ_WRITE,
  CONTACT_UPDATE_CONTACT,
  CONTACT_UPDATE_PORTAL_USER,
  ContactActionTypes,
  IContactState, CONTACT_SET_SITE, TContactSite,
} from './types';

export const initialState: IContactState = {
  loading: false,
  readOnly: true,
  inviteModalOpen: false,
  contactTypes: [
    { name: 'Sales', id: 1 },
    { name: 'Technical', id: 4 },
    { name: 'Building Manager', id: 2 },
    { name: '3rd Party Consultant', id: 7 },
    { name: 'Billing', id: 5 },
    { name: 'Out Of Hours', id: 6 },
    { name: 'Service Delivery', id: 3 },
    { name: 'Primary', id: 8 },
    { name: 'Planned Works', id: 279640000 },
  ],
  portalUserRoles: [
    { role: 'Administrator', roleId: 864640000 },
    { role: 'Technical User', roleId: 864640001 },
    { role: 'Read Only', roleId: 864640002 },
    { role: 'Support User', roleId: 864640003 },
  ],
  data: {
    id: null,
    name: '',
    firstName: '',
    lastName: '',
    fullName: '',
    email: '',
    phone: '',
    contactTypes: [],
    contactTypeNames: [],
    portalUser: {
      username: '',
      email: '',
      active: false,
      role: '',
      roleId: 0,
      lastLogin: null,
    },
    statusNotificationEmail: '',
    statusNotificationMobile: '',
    sites: [],
    isActive: true
  },
  dataOriginal: {
    id: null,
    name: '',
    firstName: '',
    lastName: '',
    fullName: '',
    email: '',
    phone: '',
    contactTypes: [],
    contactTypeNames: [],
    portalUser: {
      username: '',
      email: '',
      active: false,
      role: '',
      roleId: 0,
      lastLogin: null,
    },
    statusNotificationEmail: '',
    statusNotificationMobile: '',
    sites: [],
    isActive: true
  },

  errorMessage: null,
  errors: [],

};
export function contactReducer(state = initialState, action: ContactActionTypes) {

  switch (action.type) {
    case CONTACT_SET_CONTACT:
      return {
        ...state,
        data: action.data,
        dataOriginal: action.data,
      };
    case CONTACT_SET_ORIGINAL_DATA:
      return {
        ...state,
        dataOriginal: cloneDeep(state.data),
      };
    case CONTACT_INIT_CONTACT:
      return {
        ...initialState,
      };
    case CONTACT_RESET_CONTACT:
      return {
        ...state,
        data: { ...state.dataOriginal },
      };
    case CONTACT_SET_FORM_READ_ONLY:
      return {
        ...state,
        readOnly: action.payload,
      };
    case CONTACT_TOGGLE_READ_WRITE:
      return {
        ...state,
        readOnly: !state.readOnly,
      };
    case CONTACT_UPDATE_CONTACT:
      return {
        ...state,
        data: { ...state.data, ...action.data },
      };
    case CONTACT_UPDATE_PORTAL_USER:
      return {
        ...state,
        data: { ...state.data, portalUser: { ...state.data.portalUser, ...action.data } },
      };
    case CONTACT_SET_ERRORS:
      return {
        ...state,
        errors: [...action.errors],
      };
    case CONTACT_SET_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: action.error,
      };
    case CONTACT_RESET_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: null,
      };
    case CONTACT_TOGGLE_LOADING:
      return {
        ...state,
        loading: !state.loading,
      };
    case CONTACT_TOGGLE_INVITE_MODAL:
      return {
        ...state,
        inviteModalOpen: !state.inviteModalOpen,
      };
    case CONTACT_ADD_SITE_TYPE:
      return {
        ...state,
        data: { ...state.data, sites: [...state.data.sites, action.data] },
      };
    case CONTACT_REMOVE_SITE_TYPE:
      return {
        ...state,
        data: {
          ...state.data,
          sites: state.data.sites.filter((site) => site.id !== action.data.id || (site.id === action.data.id && site.type !== action.data.type)),
        },
      };
    case CONTACT_SYNC_TYPE_TO_ALL_SITE:
      return {
        ...state,
        data: {
          ...state.data,
          sites: action.sites,
        },
      };
    case CONTACT_SET_SITES:
      return {
        ...state,
        data: { ...state.data, sites: action.sites },
        dataOriginal: { ...state.dataOriginal, sites: action.sites },
      };
    case CONTACT_SET_SITE:
      const newTypes: TContactSite[] = action.types.map((type) => ({ id: action.site, type }));
      return {
        ...state,
        data: { ...state.data, sites: newTypes.concat(state.data.sites.filter((site) => site.id !== action.site)) },
      };
    default:
      return state;
  }

}
