import React from 'react';

interface IProps {
  cssClass?: string;
  cssClassTop?: string;
  title?: string;
}

const Graph: React.FC<IProps> = ({ cssClass, cssClassTop, title }) => (
  <svg
    className={cssClassTop || 'analytics'}
    width="40px"
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 100 125"
    enableBackground="new 0 0 100 100"
  >
    <title>{title || ''}</title>
    <path
      className={cssClass || ''}
      fill="#ccc"
      d="M88.841,20.399H11.159c-0.856,0-1.551,0.697-1.551,1.553v56.097c0,0.856,0.695,1.552,1.551,1.552h77.682  c0.855,0,1.552-0.695,1.552-1.552V21.952C90.393,21.096,89.696,20.399,88.841,20.399z M74.017,44.947  c-0.752,0-1.454-0.192-2.084-0.505l-9.705,9.618c0.303,0.623,0.486,1.312,0.486,2.048c0,2.602-2.108,4.711-4.708,4.711  c-2.601,0-4.71-2.109-4.71-4.711c0-0.317,0.034-0.628,0.095-0.928l-8.358-4.917c-0.822,0.699-1.873,1.136-3.037,1.136  c-0.911,0-1.754-0.269-2.473-0.718l-9.134,7.458c0.188,0.508,0.306,1.051,0.306,1.625c0,2.599-2.108,4.706-4.708,4.706  c-2.601,0-4.709-2.107-4.709-4.706c0-2.602,2.109-4.711,4.709-4.711c0.998,0,1.919,0.314,2.681,0.844l9.002-7.35  c-0.244-0.571-0.383-1.197-0.383-1.856c0-2.6,2.109-4.709,4.709-4.709s4.708,2.109,4.708,4.709c0,0.41-0.068,0.8-0.167,1.18  l8.225,4.837c0.845-0.807,1.983-1.31,3.244-1.31c0.83,0,1.599,0.234,2.275,0.612l9.617-9.532c-0.366-0.668-0.593-1.424-0.593-2.239  c0-2.601,2.109-4.71,4.711-4.71c2.6,0,4.707,2.109,4.707,4.71C78.724,42.84,76.616,44.947,74.017,44.947z"
    />
  </svg>
);

export default Graph;
