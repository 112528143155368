import axios, { AxiosResponse } from 'axios';
import { apiRequest } from '../Api/ApiRequest';
import { TWeatherMapsList } from '../../store/weatherMaps/types';
import { IWeatherMap } from '../../store/weatherMap/types';

type ApiListWeatherMaps<T = any, R = AxiosResponse<TWeatherMapsList>> = () => Promise<R>;
type ApiGetWeatherMap<T = any, R = AxiosResponse<IWeatherMap>> = (mapId: string) => Promise<R>;
type ApiGetWeatherMapTraffic<T = any, R = AxiosResponse<T>> = (mapId: string) => Promise<R>;
type ApiGetWeatherMapEdge<T = any, R = AxiosResponse<T>> = (mapId: string, edgeId: string) => Promise<R>;
type ApiGetWeatherMapNode<T = any, R = AxiosResponse<T>> = (nodeId: string) => Promise<R>;

// eslint-disable-next-line @typescript-eslint/naming-convention
export const api_listWeatherMaps: ApiListWeatherMaps = () => apiRequest({
  url: '/weather-maps',
  method: 'get',
  headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
}).then(
  (resp) => resp,
).catch(
  (error) => error,
);
// eslint-disable-next-line @typescript-eslint/naming-convention
export const api_getWeatherMap: ApiGetWeatherMap = (mapId) => apiRequest({
  url: `/weather-maps/${mapId}?include=nodes,edges`,
  method: 'get',
  headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
}).then(
  (resp) => resp,
).catch(
  (error) => error,
);
// eslint-disable-next-line @typescript-eslint/naming-convention
export const api_getWeatherMapTraffic: ApiGetWeatherMapTraffic = (mapId) => apiRequest({
  url: `/weather-maps/${mapId}/traffic`,
  method: 'get',
  headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
}).then(
  (resp) => resp,
).catch(
  (error) => error,
);

// eslint-disable-next-line @typescript-eslint/naming-convention
export const api_getWeatherMapEdge: ApiGetWeatherMapEdge = (mapId, edgeId) => apiRequest({
  url: `/weather-maps/${mapId}/edges/${edgeId}`,
  method: 'get',
  headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
}).then(
  (resp) => resp,
).catch(
  (error) => error,
);
