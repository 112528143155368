import { apiRequest } from '../Api/ApiRequest';

export function api_getDNSSec(domainId) {

  return apiRequest({
    url: `/services/domains/${domainId}/dnssec`,
    method: 'get',
    headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
  }).then(
    resp => resp,
  ).catch(
    error => error,
  );

}

export function api_createDNSSec(domainId, data) {

  return apiRequest({
    url: `/services/domains/${domainId}/dnssec`,
    method: 'post',
    headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
    data,
  }).then(
    resp => resp,
  ).catch(
    error => error,
  );

}

export function api_deleteDNSSec(domainId, data) {

  return apiRequest({
    url: `/services/domains/${domainId}/dnssec`,
    method: 'delete',
    headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
    data,
  }).then(
    resp => resp,
  ).catch(
    error => error,
  );

}
