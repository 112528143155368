import React from 'react';

interface IProps {
  cssClass?: string;
  cssClassTop?: string;
  title?: string;
}

const OrdersIcon: React.FC<IProps> = ({ cssClassTop, cssClass, title }) => (
  <svg
    className={cssClassTop || 'cls-1'}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20.93 22.52"
  >
    <defs>{/* <style>.cls-1{fill:#858c91;}</style> */}</defs>
    <title>{title || ''}</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Design">
        <path
          className={cssClass || 'cls-1'}
          d="M6,14.83H18.37a2.56,2.56,0,0,0,2.56-2.56V6.91a0,0,0,0,1,0,0,324263034881.12,324263034881.12,0,0,1,0-.06l0,0v0l0,0,0,0s0,0,0,0,0,0,0,0l0,0,0,0,0,0,0,0,0,0h0l0,0,0,0h0l0,0h-.14L4.66,4.26V2s0,0,0,0a0,0,0,0,0,0,0v0l0,0v0l0,0a0,0,0,0,1,0,0s0,0,0,0,0,0,0,0l0,0s0,0,0,0h0l0,0h0l0,0h0l0,0,0,0a0,0,0,0,1,0,0l-.06,0L.87.05A.63.63,0,0,0,.38,1.2l3,1.28V16.17A2.55,2.55,0,0,0,5.64,18.7,2.52,2.52,0,1,0,10.32,20,2.4,2.4,0,0,0,10,18.73h5.62A2.49,2.49,0,0,0,15.25,20a2.52,2.52,0,1,0,2.52-2.52H6a1.31,1.31,0,0,1-1.3-1.31V14.48a2.61,2.61,0,0,0,1.3.35ZM9.08,20A1.28,1.28,0,1,1,7.8,18.72,1.28,1.28,0,0,1,9.08,20Zm10,0a1.28,1.28,0,1,1-1.28-1.27A1.28,1.28,0,0,1,19.05,20Zm-.68-6.41H6a1.31,1.31,0,0,1-1.3-1.31V5.52l15,2.07v4.68a1.31,1.31,0,0,1-1.31,1.31Z"
        />
      </g>
    </g>
  </svg>
);

export default OrdersIcon;
