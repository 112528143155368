import {
  BILLING_SET_ARTIFACT_MODAL_BILL,
  BILLING_SET_BILLS, BILLING_SET_SEARCH_STRING,
  BILLING_TOGGLE_ARTIFACT_MODAL,
  BILLING_TOGGLE_LOADING,
  BillingActions,
  IBillingState,
} from './types';

const initialState: IBillingState = {
  loading: false,
  bills: [],
  filtered: [],
  searchString: '',
  artifactModal: {
    show: false,
    bill: {
      id: 0,
      totalValue: 0,
      date: '',
      paymentMethod: '',
      artifacts: [],
      credit: 0,
      toPay: 0,
      payment: {
        paid: false,
        date: '',
      },
    },
  },
};

export function billingReducer(state = initialState, action: BillingActions) {

  switch (action.type) {
    case BILLING_SET_BILLS:
      return {
        ...state,
        bills: action.bills,
      };
    case BILLING_TOGGLE_LOADING:
      return {
        ...state,
        loading: !state.loading,
      };
    case BILLING_TOGGLE_ARTIFACT_MODAL:
      return {
        ...state,
        artifactModal: { ...state.artifactModal, show: !state.artifactModal.show },
      };
    case BILLING_SET_ARTIFACT_MODAL_BILL:
      const bill = state.bills.filter((billItem) => billItem.id === action.id);
      return {
        ...state,
        artifactModal: { ...state.artifactModal, bill: bill[0] },
      };
    case BILLING_SET_SEARCH_STRING:
      const filtered = state.bills.filter((billItem) => String(billItem.id).toLowerCase().indexOf(action.search.toLowerCase()) !== -1
                    || String(billItem.date).toLowerCase().indexOf(action.search.toLowerCase()) !== -1);
      return {
        ...state,
        searchString: action.search,
        filtered,
      };
    default:
      return {
        ...state,
      };
  }

}
