import {
  IMonitoringState,
  MONITORING_SET_MONITORED_ITEMS,
  MONITORING_SET_SEARCH_STRINGS,
  MONITORING_TOGGLE_LOADING,
  MonitoringActionTypes,
  MONITORING_RESET_STORE, MONITORING_TOGGLE_STATUS_LOADING, MONITORING_SET_MONITORED_ITEM_STATUSES,
} from './types';
import { MONITORED_ITEM_DESC_SAVE, MONITORED_ITEM_REVERT_DESC } from '../monitoredItem/types';

const initialState: IMonitoringState = {
  loading: false,
  loadingStatuses: false,
  searchString: '',
  items: [],
  filtered: [],
};
export function monitoringReducer(state = initialState, action: MonitoringActionTypes) {

  switch (action.type) {
    case MONITORED_ITEM_DESC_SAVE: {

      const { items } = state;
      const newItems = items.map((item) => {

        if (action.deviceId === item.id) {

          return { ...item, description: action.description, tempDesc: item.description };
        
        }

        return item;
      
      });
      return {
        ...state,
        items: [...newItems],
      };
    
    }
    case MONITORED_ITEM_REVERT_DESC: {

      const { items } = state;
      const newItems = items.map((item) => {

        if (action.deviceId === item.id) {

          return { ...item, description: item.tempDesc };
        
        }

        return item;
      
      });
      return {
        ...state,
        items: [...newItems],
      };
    
    }
    case MONITORING_SET_MONITORED_ITEMS: {

      return {
        ...state,
        items: action.items.map(((item) => ({ ...item, favouriteStatusLoading: false }))),
      };
    
    }
    case MONITORING_SET_MONITORED_ITEM_STATUSES:
      const items = state.items.map((item) => {

        item.available = action.items[item.id] ? action.items[item.id] : false;
        return item;
      
      });
      return {
        ...state,
        items,
      };
    case MONITORING_TOGGLE_LOADING:
      return {
        ...state,
        loading: !state.loading,
      };
    case MONITORING_TOGGLE_STATUS_LOADING:
      return {
        ...state,
        loadingStatuses: !state.loadingStatuses,
      };
    case MONITORING_SET_SEARCH_STRINGS:
      const filtered = state.items.filter((item) => String(item.name).toLowerCase().indexOf(action.search.toLowerCase()) !== -1
                    || String(item.location).toLowerCase().indexOf(action.search.toLowerCase()) !== -1
                    || String(JSON.stringify(item.services)).toLowerCase().indexOf(action.search.toLowerCase()) !== -1
                    || String(item.type).toLowerCase().indexOf(action.search.toLowerCase()) !== -1);
      return {
        ...state,
        searchString: action.search,
        filtered,
      };
    case MONITORING_RESET_STORE:
      return {
        ...initialState,
      };
    default: return state;
  }

}
