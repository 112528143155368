/* eslint-disable */
import React, { Component } from 'react';
import {connect} from 'react-redux';

import {Card, CardBody, CardTitle, Col, Row, Button, UncontrolledTooltip, CardText} from 'reactstrap';
import numeral from 'numeral';
import { Navigate } from 'react-router-dom'
import {removeFromDomainCart} from '../../../actions/domainCart';
import {resetDomainCheckout} from '../../../actions/domainCheckout';


class DomainCart extends Component {

  state = {
    doCheckout: false
  };

  removeFromCart = (id) =>{

    this.props.dispatch(removeFromDomainCart(id));

  };

  getTotal = () => {

    return Object.keys(this.props.cart).reduce((total, key) => {

      const domain = this.props.cart[key];
      return parseFloat(domain.years * domain.tld.price[domain.action]) + total;

    }, 0);

  };

  doCheckout = () =>{

    this.props.dispatch(resetDomainCheckout());
    this.setState({doCheckout: true});

  };

  render(){

    if(this.state.doCheckout){

      return <Navigate to="/domains/checkout" push/>;

    }

    return (
            <Card>
                <CardBody className="clearfix">
                    <CardTitle className="float-left">Cart</CardTitle>
                    <div className="h1 text-muted text-right mb-2">
                        <i className="icon-basket" />
                    </div>
                    <Row>
                        <Col>
                            {Object.keys(this.props.cart).map((item, index) => {

                              const domain = this.props.cart[item];
                              if(domain.action === 'renew'){

                                return (
                                        <RenewItem
                                            key={index}
                                            item = {domain}
                                            index = {index}
                                            removeFromCart = {this.removeFromCart}
                                        />
                                );

                              }
                              if(domain.action === 'transfer'){

                                return (
                                        <TransferItem
                                            key={index}
                                            item = {domain}
                                            index = {index}
                                            removeFromCart = {this.removeFromCart}
                                        />
                                );

                              }
                              return (
                                    <div className="callout callout-primary py-3 mb-2" key={index}>
                                        <div className="float-right">
                                            £{numeral(domain.tld.price[domain.action] * domain.years).format('0,0.00')}
                                            <i onClick={this.removeFromCart.bind(null, domain.id)} id={`remove${index}`} style={{cursor: 'pointer'}} className="icon icon-trash text-danger ml-3" />
                                        </div>
                                        <div><strong>{domain.name}</strong></div>
                                        <div><small className="text-muted">{domain.action} domain for {domain.years} year(s)</small></div>

                                    </div>
                              );

                            })}
                        </Col>

                    </Row>
                    <Row className="clearfix mt-3">
                        <Col className="float-left"><CardText>Total: <strong>£{numeral(this.getTotal()).format('0,0.00')}</strong></CardText></Col>
                        <Col className="float-right"><Button color="primary" size="sm" outline className="float-right" onClick={this.doCheckout}>Checkout</Button></Col>
                    </Row>
                </CardBody>
            </Card>
    );

  }


}
function mapStateToProps ({ domainCart }) {

  return {
    cart: domainCart
  };

}

const RenewItem = ({item, index, removeFromCart }) => {

  return (
        <div className="callout callout-success py-3 mb-2" key={index}>
            <div className="float-right">
                £{numeral(item.total).format('0,0.00')}
                <i onClick={removeFromCart.bind(null, item.id)} id={`remove${index}`} style={{cursor: 'pointer'}} className="icon icon-trash text-danger ml-3" />
                <UncontrolledTooltip target={`remove${  index}`}>Remove</UncontrolledTooltip>
            </div>
            <div><strong>{item.name}</strong></div>
            <div><small className="text-muted">{item.action} domain for {item.years} year(s)</small></div>

        </div>
  );

};

const TransferItem = ({item, index, removeFromCart }) => {

  return (
        <div className="callout callout-warning py-3 mb-2" key={index}>
            <div className="float-right">
                £{numeral(item.tld.price[item.action]).format('0,0.00')}
                <i onClick={removeFromCart.bind(null, item.id)} id={`remove${index}`} style={{cursor: 'pointer'}} className="icon icon-trash text-danger ml-3" />
                <UncontrolledTooltip target={`remove${  index}`}>Remove</UncontrolledTooltip>
            </div>
            <div><strong>{item.name}</strong></div>
            <div><small className="text-muted">{item.action} domain for 1 year</small></div>

        </div>
  );

};

export default connect(mapStateToProps)(DomainCart);
