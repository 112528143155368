import { TContact } from '../contact/types';
import { TOrder } from '../order/types';
import { TCase } from '../case/types';
import { TQuote } from '../quote/types';

export const SIGNATURES_SET_DATA = 'SIGNATURES_SET_DATA';
export const SIGNATURES_SET_SEARCH_STRING = 'SIGNATURES_SET_SEARCH_STRING';
export const SIGNATURES_UPDATE = 'SIGNATURES_UPDATE';

export interface ISignaturesState {
  signatures: Array<TSignature>;
  searchString: string;
  filtered: Array<TSignature>;
}

export interface ISetSignaturesAction {
  type: typeof SIGNATURES_SET_DATA;
  data: Array<TSignature>;
}

export interface ISetSignaturesSearchStringAction {
  type: typeof SIGNATURES_SET_SEARCH_STRING;
  searchString: string;
}

export interface ISignaturesUpdateAction {
  type: typeof SIGNATURES_UPDATE;
  data: TSignature;
}

export type THelloSignSignature = {
  id: string;
  signerName: string;
  signerEmail: string;
  statusCode: string;
};

export type THelloSign = {
  id: string;
  signatures: Array<THelloSignSignature>;
};

export type TSignature = {
  id: number;
  title: string;
  message: string;
  hsSignatureId: string;
  status: string;
  isUploaded: boolean;
  relatedToType: string;
  docCount: number;
  createdAt: string;
  updatedAt: string;

  // signUrl: string; // where are these from?
  // expiresAt?: number; // where are these from?

  helloSign?: Array<THelloSign>;

  signatories: {
    id: number;
    document_tag: number;
    signatory: TContact
  }[];
  relatedTo: TOrder | TCase | TQuote;
};

export type SignaturesActionTypes = ISetSignaturesAction | ISetSignaturesSearchStringAction | ISignaturesUpdateAction;
