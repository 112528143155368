export const ACCOUNT_SET_ACCOUNT_DATA = 'ACCOUNT_SET_ACCOUNT_DATA';
export const ACCOUNT_SET_ACCOUNT_SITES = 'ACCOUNT_SET_ACCOUNT_SITES';
export const ACCOUNT_TYPE_RESALE_PARTNER = 9;

export interface IAccountState {
  name: string;
  accountNumber: string;
  accountId: string;
  accountTypeCode: number;
  hsoSupport: string;
  accountManager: TAccountManager;
  openCases: number;
  activeOrders: number;
  activeServices: number;
  sites: Array<TAccountSite>;
  customers: Array<TAccountCustomer>;
  cardPayer?: string;
  addressLine1?: string;
  addressLine2?: string;
  addressLine3?: string;
  city?: string;
  county?: string;
  country?: string;
  postcode?: string;
  telephone?: string;
  serviceGroups: string[];
}

type TAccountManager = {
  firstName: string;
  lastName: string;
  name: string;
  email: string;
  telephone: string;
};

export type TAccountSite = {
  id: string;
  name: string;
};

export type TAccountCustomer = {
  name: string;
  accountNumber: string;
  accountId: string;
};

export interface ISetAccountDataAction {
  type: typeof ACCOUNT_SET_ACCOUNT_DATA;
  data: IAccountState;
}

export interface ISetAccountSitesAction {
  type: typeof ACCOUNT_SET_ACCOUNT_SITES;
  sites: TAccountSite[];
}

export type AccountActionTypes = ISetAccountDataAction | ISetAccountSitesAction;
