import { apiRequest } from '../Api/ApiRequest';

// eslint-disable-next-line @typescript-eslint/naming-convention
export function api_getCase(caseId) {

  return apiRequest({
    url: `/cases/${caseId}`,
    method: 'get',
    headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
  }).then(
    (resp) => resp,
  ).catch(
    (error) => error,
  );

}
// eslint-disable-next-line @typescript-eslint/naming-convention
export function api_resolveCase(caseId, description, resolutionType) {

  return apiRequest({
    url: `/cases/${caseId}/resolve`,
    method: 'put',
    headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
    data: { description, resolutionType },
  }).then(
    (resp) => resp,
  ).catch(
    (error) => error,
  );

}

// eslint-disable-next-line @typescript-eslint/naming-convention
export function api_escalateCase(caseId, note) {

  return apiRequest({
    url: `/cases/${caseId}/escalate`,
    method: 'post',
    headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
    data: { note },
  }).then(
    (resp) => resp,
  ).catch(
    (error) => error,
  );

}
