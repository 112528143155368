export const ADD_TO_DOMAIN_CART = 'ADD_TO_DOMAIN_CART';
export const RESET_DOMAIN_CART = 'RESET_DOMAIN_CART';
export const REMOVE_FROM_DOMAIN_CART = 'REMOVE_FROM_DOMAIN_CART';
export const UPDATE_DOMAIN_CART = 'UPDATE_DOMAIN_CART';

export function addToDomainCart(item) {

  return {
    type: ADD_TO_DOMAIN_CART,
    item,
  };

}

export function resetDomainCart(item) {

  return {
    type: RESET_DOMAIN_CART,
  };

}

export function removeFromDomainCart(id) {

  return {
    type: REMOVE_FROM_DOMAIN_CART,
    id,
  };

}

export function updateDomainCart(id, attr, value) {

  return {
    type: UPDATE_DOMAIN_CART,
    id,
    attr,
    value,
  };

}
