import * as React from 'react';
import { Col, Row } from 'reactstrap';
import isEmpty from 'lodash.isempty';
import TextInput from '../../Inputs/TextInput';
import { TContact, TPortalUserRole } from '../../../store/contact/types';
import { ICheckBoxOption } from '../../Inputs/CheckBoxList';
import FormButton from '../../Buttons/FormButton';
import { TUserPermission } from '../../../store/user/types';
import { checkPermission } from '../../../utils/Auth/AuthService';

type TOwnProps = {
  contact: TContact;
  onClickInvite: () => any;
  contactTypes: Array<ICheckBoxOption>;
  portalAccessOption?: ICheckBoxOption;
  portalUserRole?: TPortalUserRole;
  permissions: TUserPermission[];
};

const ReadContactForm: React.FC<TOwnProps> = (props) => {

  const {
    contact, contactTypes, portalAccessOption, portalUserRole, onClickInvite, permissions,
  } = props;
  return (
    <>
      <TextInput id="firstName" label="First Name" value={contact.firstName} readOnly />
      <TextInput id="lastName" label="Last Name" value={contact.lastName} readOnly />
      <TextInput id="email" label="Email" value={contact.email} readOnly />
      <TextInput id="phone" label="Phone Number" value={contact.phone} readOnly />
      {!isEmpty(contact.portalUser) && contact.isActive
      && (
      <>
        <TextInput
          id="portalAccess"
          label="Portal Access"
          value={portalAccessOption ? portalAccessOption.name : ''}
          readOnly
        />
        <TextInput
          id="portalUsername"
          label="Portal User Name"
          value={contact.portalUser?.username || ''}
          readOnly
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onChange={() => {}}
        />
        <TextInput
          id="portalUser-role"
          label="Portal User Role"
          value={portalUserRole ? portalUserRole.role : ''}
          readOnly
        />
      </>
      )}

      {contact.isActive && (
        <>
          {isEmpty(contact.portalUser) && checkPermission('contacts.write', permissions) && (
            <FormButton value="Send Invite" onClick={onClickInvite} label="Portal Access" color="info" />
          )}
          {!contact.statusNotificationEmail && !contact.statusNotificationMobile
            ? (
              <Row className="my-4 text-light">
                <Col md={4} className="font-weight-bold" style={{fontSize: '16px'}}>Status Alerts</Col>
                <Col md={8}>This contact will not receive email or SMS alerts during major outages.</Col>
              </Row>
            )
            : <h5 className="mt-5 text-light font-weight-bold">Status Alerts</h5>}
          {contact.statusNotificationEmail || contact.statusNotificationMobile ? (
            <>
              <TextInput id="statusNotificationEmail" value={contact.statusNotificationEmail || ''} readOnly>
                Email
                <div className="small">Emails will be sent for all new status messages and all updates</div>
              </TextInput>
              <TextInput id="statusNotificationMobile" value={contact.statusNotificationMobile || ''} readOnly>
                Mobile
                <div className="small">SMS will only be sent for new Major Outages, updates will be sent via email</div>
              </TextInput>
            </>
          ) : ''}
        </>
      )}
    </>
  );

};

export default ReadContactForm;
