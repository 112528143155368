import { TBill } from '../billing/types';

export const CARD_PAYMENT_UPDATE_CARD_DETAILS = 'CARD_PAYMENT_UPDATE_CARD_DETAILS';
export const CARD_PAYMENT_UPDATE_CARD_ADDRESS = 'CARD_PAYMENT_UPDATE_CARD_ADDRESS';
export const CARD_PAYMENT_SET_CARD_ERRORS = 'CARD_PAYMENT_SET_CARD_ERRORS';
export const CARD_PAYMENT_TOGGLE_PROCESSING = 'CARD_PAYMENT_TOGGLE_PROCESSING';
export const CARD_PAYMENT_TOGGLE_LOADING = 'CARD_PAYMENT_TOGGLE_LOADING';
export const CARD_PAYMENT_SET_BILL = 'CARD_PAYMENT_SET_BILL';
export const CARD_PAYMENT_SET_ERROR = 'CARD_PAYMENT_SET_ERROR';
export const CARD_PAYMENT_SET_BILL_PAID = 'CARD_PAYMENT_SET_BILL_PAID';
export const CARD_PAYMENT_SET_CARD_REDIRECT = 'CARD_PAYMENT_SET_CARD_REDIRECT';

export interface ICardPaymentState {
  loading: boolean;
  processing: boolean;
  error: string;
  showThankYou: boolean;
  bill: TBill;
  cardDetails: TCardDetails;
  cardAddress: TCardAddress;
  cardErrors: TCardErrors;
  cardRedirect: TCardPaymentRedirect;

}

export type TBrowserDetails = {
  javascriptEnabled: boolean;
  javaEnabled: boolean;
  colorDepth: number;
  screenHeight: number;
  screenWidth: number;
  tz: number;
  language: string;
};

export type TCardDetails = {
  cardType: string;
  cardNumber: string;
  cardHolder: string;
  cardExpirationMonth: string;
  cardExpirationYear: string;
  cardIssueMonth: string;
  cardIssueYear: string;
  cardIssueNumber: string;
  cardCVC: string;
  cardAutoCharge: boolean;
};

export type TCardAddress = {
  addressLine1: string;
  addressLine2: string;
  city: string;
  postcode: string;
  countryCode: string;
  stateCode?: string;
};

export type TCardErrors = {
  cardNumber?: boolean;
  cardHolder?: boolean;
  cardExpirationDate?: boolean;
  cardIssueDate?: boolean;
  cardCVC?: boolean;
  addressLine1?: boolean;
  city?: boolean;
  postcode?: boolean;
  stateCode?: boolean;
};

export type TCardPaymentRedirect = {
  url: string;
  pareq?: string;
  md?: string;
  creq?: string;
  threeDSSessionData?: string;
};

interface IToggleCardPaymentLoadingAction {
  type: typeof CARD_PAYMENT_TOGGLE_LOADING;
}

interface IUpdateCardPaymentCardDetailsAction {
  type: typeof CARD_PAYMENT_UPDATE_CARD_DETAILS;
  field: string;
  value: string;
}

interface IUpdateCardPaymentCardAddressAction {
  type: typeof CARD_PAYMENT_UPDATE_CARD_ADDRESS;
  field: string;
  value: string;
}

interface ISetCardPaymentCardErrorsAction {
  type: typeof CARD_PAYMENT_SET_CARD_ERRORS;
  errors: TCardErrors;
}

interface IToggleCardPaymentProcessingAction {
  type: typeof CARD_PAYMENT_TOGGLE_PROCESSING;
}

interface ICardPaymentBillAction {
  type: typeof CARD_PAYMENT_SET_BILL;
  bill: TBill;
}

interface ICardPaymentErrorAction {
  type: typeof CARD_PAYMENT_SET_ERROR;
  error: string;
}

interface ICardPaymentSetBillPaidAction {
  type: typeof CARD_PAYMENT_SET_BILL_PAID;
  date: string;
}

interface ICardPaymentSetCardRedirectAction {
  type: typeof CARD_PAYMENT_SET_CARD_REDIRECT;
  redirect: TCardPaymentRedirect;
}

export type CardPaymentActions =
    IUpdateCardPaymentCardDetailsAction |
    ISetCardPaymentCardErrorsAction |
    IToggleCardPaymentProcessingAction |
    ICardPaymentBillAction |
    IToggleCardPaymentLoadingAction |
    ICardPaymentErrorAction |
    ICardPaymentSetBillPaidAction |
    ICardPaymentSetCardRedirectAction |
    IUpdateCardPaymentCardAddressAction;
