import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import moment, { Moment } from 'moment';
import { useParams } from 'react-router-dom';
import Section from '../../../components/Section';
import SimpleLoader from '../../../components/SimpleLoader';
import OneUpOneDown from '../../../components/Widgets/OneUpOneDown';
import ButtonLink from '../../../components/Buttons/ButtonLink';
import { IUserState } from '../../../store/user/types';
import { IServiceState } from '../../../store/service/types';
import FavouriteStar from '../../../components/FavouriteStar';
import { checkPermission } from '../../../utils/Auth/AuthService';
import { favouriteItem, unFavouriteItem } from '../../../store/user/actions';
import { IServiceConnectivityState } from '../../../store/serviceConnectivity/types';
import { IAccountState } from '../../../store/account/types';
import { ISystemState } from '../../../store/system/types';
import {
  getServiceConnectivityData,
  getServiceConnectivityPortGraph, resetConnectivityService,
  setServiceConnectivityGraphDates,
  toggleServiceConnectivityGraphLoading,
  toggleServiceConnectivityLoading,
} from '../../../store/serviceConnectivity/actions';
import TrafficGraph from '../../../components/TrafficGraph';
import { AppDispatch } from '../../../configureStore';
import MetaButton from "../../../components/MetaButton/MetaButton";

type TParams = { id: string };
type TOwnProps = {
  dispatch: AppDispatch;
  connectivity: IServiceConnectivityState;
  user: IUserState;
  service: IServiceState;
  system: ISystemState;
  account: IAccountState;

};
type Props = TOwnProps;
const ServiceConnectivity: React.FC<Props> = (props) => {

  const { data, loading } = props.connectivity;
  const {
    graphs, bEndDeviceId, billing, name, bEndLocation, type: serviceType,
  } = data;
  const { user, dispatch } = props;

  const params = useParams();

  useEffect(() => {

    props.dispatch(resetConnectivityService());
    props.dispatch(toggleServiceConnectivityLoading());
    props.dispatch(getServiceConnectivityData(params.id, checkPermission('billing.read', user.permissions))).then(
      (result) => {

        if (result) {

          result.data.graphs.map((graph) => {

            getGraph(graph.id);

          });

        }
        props.dispatch(toggleServiceConnectivityLoading());

      },
    );

  }, []);
  const getGraph = (graphId, startDate?: Moment, endDate?: Moment) => {

    const start = startDate ? startDate.format('X') : moment().startOf('day').format('X');
    const end = endDate ? endDate.format('X') : moment().format('X');
    props.dispatch(toggleServiceConnectivityGraphLoading(graphId));
    props.dispatch(getServiceConnectivityPortGraph(params.id, graphId, start, end)).then(
      () => props.dispatch(toggleServiceConnectivityGraphLoading(graphId)),
    );

  };
  const dateRangeHandler = (graphId, event, picker) => {

    if (event.type === 'apply') {

      props.dispatch(setServiceConnectivityGraphDates(graphId, picker.startDate, picker.endDate));
      getGraph(graphId, picker.startDate, picker.endDate);

    }

  };
  const userFavorite = (itemId) => user.favourites.filter((favourite) => itemId === favourite.itemId).length !== 0;

  const useGraphId = () => !bEndDeviceId && (serviceType === 'Data Centre Connect' || serviceType === 'Ip Transit');
  return (
    <div className="animated fadeIn mb-5">
      <SimpleLoader loading={loading} text="Loading your service information...">
        <Row className="d-flex flex-md-row-reverse" style={{ minHeight: '300px' }}>
          {!loading
                    && (
                    <Col md={2}>
                      <div className="mb-3">
                        <MetaButton className="font-lg d-md-flex flex-nowrap float-lg-right">
                          {serviceType}
                        </MetaButton>
                        <br />
                      </div>
                      <div className="float-lg-right mt-2">
                        <ButtonLink
                          color="danger"
                          text="Report Fault"
                          url="/cases/new/generic"
                        />
                      </div>
                    </Col>
                    )}
          <Col md={10}>
            {!loading
                        && (
                        <Section title={data.name}>
                          <Row className="d-flex">
                            <Col md={6} className="mb-2">
                              <OneUpOneDown
                                upTitle="Bearer"
                                upValue={data.bearer}
                                downTitle="bandwidth"
                                downValue={data.bandwidth}
                              />
                            </Col>
                            <Col md={6} className="mb-2">
                              <OneUpOneDown
                                upTitle="Active Since"
                                upValue={moment(data.goLiveDate).format('DD-MM-YYYY')}
                                downTitle={checkPermission('services.write', user.permissions) ? 'Monthly Rental' : ''}
                                downValue={checkPermission('services.write', user.permissions) ? `£${billing.rental}` : ''}
                              />
                            </Col>
                          </Row>
                          <Row className="d-flex">
                            <Col md={6}>
                              <OneUpOneDown
                                upTitle="A End"
                                upValue={data.aEnd}
                                downTitle="Location"
                                downValue={data.aEndLocation}
                              />
                            </Col>
                            <Col md={6}>
                              <OneUpOneDown
                                upTitle="B End"
                                upValue={data.bEnd || 'Wires Only'}
                                downTitle="Location"
                                downValue={data.bEndLocation}
                                upValueUrl={data.bEndDeviceId && serviceType === 'Cloud Connect' ? `/monitoring/${data.bEndDeviceId}` : ''}
                              />
                            </Col>
                          </Row>
                        </Section>
                        )}
          </Col>
        </Row>
        <Row className="mt-3">
          {!loading && graphs.length > 0
                    && (
                    <Col>
                      <Section title="Monitored Devices/Ports">
                        {graphs.map((graph) => {

                          const id = useGraphId() ? graph.id : bEndDeviceId || graph.id;
                          const action = userFavorite(id) ? () => dispatch(unFavouriteItem(id, name, bEndLocation)) : () => {

                            dispatch(favouriteItem(id, name, bEndLocation));

                          };
                          return (
                            <div className="mt-3" key={graph.name}>
                              <Row>
                                <Col>
                                  <h5 className="font-weight-bold float-left d-block">
                                    {graph.name}
                                  </h5>
                                  <div
                                    style={{ height: '40px', width: '40px' }}
                                    className="d-inline-block mt-n1 ml-2"
                                  >
                                    <FavouriteStar
                                      filled={userFavorite(id)}
                                      onClick={action}
                                      loaderClass="lds-services"
                                      servicesStarClass="star--services"
                                    />
                                  </div>
                                </Col>
                              </Row>
                              {
                                            graph.dataIn && (
                                            <Row key={graph.id}>
                                              <Col>
                                                <TrafficGraph
                                                  name={graph.name}
                                                  loading={graph.loading}
                                                  dateRangeHandler={dateRangeHandler.bind(null, graph.id)}
                                                  startDate={graph.startDate}
                                                  endDate={graph.endDate}
                                                  dataIn={graph.dataIn}
                                                  dataOut={graph.dataOut}
                                                />
                                              </Col>
                                            </Row>
                                            )
                                        }
                            </div>
                          );

                        })}
                      </Section>
                    </Col>
                    )}
        </Row>
      </SimpleLoader>
    </div>
  );

};
function mapStateToProps({
  serviceConnectivity, user, service, system, account,
}) {

  return {
    connectivity: serviceConnectivity,
    user,
    service,
    system,
    account,
  };

}
export default connect(mapStateToProps)(ServiceConnectivity);
