import React, { FunctionComponent, JSX, PropsWithChildren } from 'react';
import {
  Navigate, RouteObject, useParams, useRoutes,
} from 'react-router-dom';
// eslint-disable-next-line
import Full from './containers/Full/Full';
import Signature from './containers/Signature';
import Login from './views/Login/Login';
import ForgotPassword from './containers/ForgotPassword';
import ResetPasswordWithToken from './views/Password/ResetPasswordWithToken';
import AccountRegistration from './views/Registration/AccountRegistration';
import UserRegistration from './views/Registration/UserRegistration';
import Emulation from './containers/Emulation';
import Card3DSecure from './views/CardPay/Card3dSecure';
import Card3dSecureCallback from './views/CardPay/Card3dSecureCallback';
import Terms from './containers/Terms';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import Dashboard from './containers/Dashboard';
import Billing from './containers/Billing';
import Pay from './containers/Pay';
import NewCase from './views/Cases/NewCase';
import Case from './containers/Case';
import Cases from './containers/Cases';
import MonitoredItem from './containers/MonitoredItem';
import Monitoring from './containers/Monitoring';
import Orders from './containers/Orders';
import NewOrder from './containers/NewOrder';
import Order from './containers/Order';
import Contacts from './containers/Contacts';
import Contact from './containers/Contact';
import Services from './containers/Services/mapper';
import DNS from './containers/Service/DNS';
import DNSZone from './views/Services/DNS/DNSZone';
import ServiceConnectivity from './containers/Service/Connectivity';
import Broadband from './containers/Service/Broadband';
import VeeamBackup from './containers/Service/VeeamBackup';
import Domains from './containers/Domains';
import DomainLookup from './views/Services/Domains/DomainLookup';
import DomainCheckout from './containers/DomainCheckout';
import Domain from './views/Services/Domains/Domain';
import WeatherMap from './containers/WeatherMap';
import WeatherMaps from './containers/WeatherMaps';
import Documents from './containers/Documents';
import Signatures from './containers/Signatures';
import ResetPassword from './containers/ResetPassword';
import Tutorials from './containers/Tutorials';
import Reports from './containers/Reports';
import { GenericNotFound } from './components/Errors/Errors';
import OAuthPopup from './containers/Oauth2/OAuth2Popup'
import ForgotUsername from "./containers/ForgotUsername";

const Home: FunctionComponent = (): JSX.Element => <Navigate to="/dashboard" />;
const PassIdContainer = ({ Route }: { Route: FunctionComponent<{ id: string }> }) => {

  const params = useParams();
  return <Route id={params.id || ''} />;

};
const App: FunctionComponent<PropsWithChildren> = (): JSX.Element => {

  const routes: RouteObject[] = (process.env.REACT_APP_SIGN_ONLY === 'true') ? [
    { path: '/signature/:signatureid', element: <Signature /> },
  ] : [
    { path: '/login', element: <Login /> },
    { path: '/callback', element: <OAuthPopup /> },
    { path: '/forgot-username', element: <ForgotUsername /> },
    { path: '/forgot-password', element: <ForgotPassword /> },
    { path: '/reset-password-token/:token', element: <ResetPasswordWithToken /> },
    { path: '/account-registration/:token', element: <AccountRegistration /> },
    { path: '/user-registration/:token', element: <UserRegistration /> },
    { path: '/emulate', element: <Emulation /> },
    { path: '/card3dSecure', element: <Card3DSecure /> },
    { path: '/cardSecureCallback', element: <Card3dSecureCallback /> },
    { path: '/signature/:signatureid', element: <Signature /> },
    { path: '/terms', element: <Terms /> },
    {
      path: '/',
      element: <PrivateRoute />,
      children: [
        {
          path: '/',
          element: <Full />,
          children: [
            { path: '/dashboard', element: <Dashboard /> },
            { path: '/billing', element: <Billing /> },
            { path: '/billing/pay', element: <Pay /> },
            { path: '/cases/new/:type', element: <NewCase /> },
            { path: '/cases/:id', element: <Case /> },
            { path: '/cases', element: <Cases /> },
            { path: '/monitoring/:id', element: <PassIdContainer Route={MonitoredItem} /> },
            { path: '/monitoring', element: <Monitoring /> },
            { path: '/orders', element: <Orders /> },
            { path: '/orders/new', element: <NewOrder /> },
            { path: '/orders/:id', element: <Order /> },
            { path: '/contacts', element: <Contacts /> },
            { path: '/contacts/:id', element: <Contact /> },
            { path: '/services', element: <Services /> },
            { path: '/services/dns', element: <DNS /> },
            { path: '/services/dns/:id', element: <DNSZone /> },
            { path: '/services/cloud-connect/:id', element: <ServiceConnectivity /> },
            { path: '/services/cloud-direct/:id', element: <ServiceConnectivity /> },
            { path: '/services/dc-connect/:id', element: <ServiceConnectivity /> },
            { path: '/services/dc-port/:id', element: <ServiceConnectivity /> },
            { path: '/services/ip-transit/:id', element: <ServiceConnectivity /> },
            { path: '/services/broadband/:id', element: <Broadband /> },
            { path: '/services/veeam-backup/:id', element: <VeeamBackup /> },
            { path: '/domains', element: <Domains /> },
            { path: '/domains/lookup', element: <DomainLookup /> },
            { path: '/domains/checkout', element: <DomainCheckout /> },
            { path: '/domains/:DomainID', element: <Domain /> },
            { path: '/weather-maps/:weatherMapID', element: <WeatherMap /> },
            { path: '/weather-maps', element: <WeatherMaps /> },
            { path: '/documents', element: <Documents /> },
            { path: '/signatures', element: <Signatures /> },
            { path: '/reset-password', element: <ResetPassword /> },
            { path: '/tutorials', element: <Tutorials /> },
            { path: '/reports', element: <Reports /> },
            { path: '/', element: <Home /> },
            { element: <GenericNotFound /> },
          ],
        },
      ],
    },
  ];

  const routing = useRoutes(routes);

  return (
  // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{routing}</>
  );

};

export default App;
