import React from 'react';
import SimpleTable from '../../../components/Table/SimpleTable';
import { IMonitoredItemNTPingData } from '../../../store/monitoredItem/types';

interface TOwnProps {
  data: IMonitoredItemNTPingData;
}

const PingDataTable: React.FC<TOwnProps> = ({ data }) => {

  const header = (
    <tr className="text-light font-weight-bold h5">
      <th>Probes</th>
      <th>Loss</th>
      <th>RTT Min</th>
      <th>RTT Max</th>
      <th>RTT Avg</th>
      <th>RTT Stddev</th>
    </tr>
  );

  const rows = [(
    <tr className="text-light pl-1 pr-2 b-t-1" key="1">
      <td>{data.probes}</td>
      <td className={Number(data.loss) === 0 ? 'bg-success' : (Number(data.loss) === 100 ? 'bg-danger' : 'bg-warning')}>
        {data.loss}
        %
      </td>
      <td>{data.rttMin ? `${data.rttMin / 1000}ms` : 'N/A'}</td>
      <td>{data.rttMax ? `${data.rttMax / 1000}ms` : 'N/A'}</td>
      <td>{data.rttAvg ? `${data.rttAvg / 1000}ms` : 'N/A'}</td>
      <td>{data.rttStddev ? `${data.rttStddev / 1000}ms` : 'N/A'}</td>
    </tr>
  )];

  return (
    <SimpleTable rows={rows} withHeader header={header} bordered />
  );

};

export default PingDataTable;
