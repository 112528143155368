import { combineReducers } from 'redux';
import { purgeStoredState } from 'redux-persist';
import reducers from '../store/domainLookup/reducers';
import domainCart from './domainCart';
import domainCheckout from './domainCheckout';
import system from '../store/system/reducers';
import vps from './VPS';
import feedback from './feedback';
import { persistConfig } from '../configureStore';
import domain from './domain';
import { dashboardReducer } from '../store/dashboard/reducers';
import { contactReducer } from '../store/contact/reducers';
import { userReducer } from '../store/user/reducers';
import { monitoringReducer } from '../store/monitoring/reducers';
import { contactsReducer } from '../store/contacts/reducers';
import { servicesReducer } from '../store/services/reducers';
import { ordersReducer } from '../store/orders/reducers';
import { casesReducer } from '../store/cases/reducers';
import { caseReducer } from '../store/case/reducers';
import { headerReducer } from '../store/header/reducers';
import { domainsReducer } from '../store/domains/reducers';
import { orderReducer } from '../store/order/reducers';
import { signatureReducer } from '../store/signature/reducers';
import { serviceReducer } from '../store/service/reducers';
import { serviceDNSReducer } from '../store/serviceDNS/reducers';
import { monitoredItemReducer } from '../store/monitoredItem/reducers';
import { accountReducer } from '../store/account/reducers';
import { serviceBroadbandReducer } from '../store/serviceBroadband/reducers';
import { serviceVeeamBackupReducer } from '../store/serviceVeeamBackup/reducers';
import { billingReducer } from '../store/billing/reducers';
import { serviceConnectivityReducer } from '../store/serviceConnectivity/reducers';
import { cardPaymentReducer } from '../store/cardPayment/reducers';
import { documentsReducer } from '../store/documents/reducers';
import { signaturesReducer } from '../store/signatures/reducers';
import { weatherMapReducer } from '../store/weatherMap/reducers';
import { weatherMapsReducer } from '../store/weatherMaps/reducers';
import { socketioToastReducer } from '../store/socketioToast/reducers';
import { socketioReducer } from '../store/socketio/reducers';
import { JTIReducer } from '../store/JTI/reducers';
import { serviceOrderReducer } from '../store/serviceOrder/reducers';
import { reportsReducer } from '../store/reports/reducers';
import { priceListReducer } from '../store/priceList/reducers';

const appReducer = combineReducers({
  domain,
  domainLookup: reducers,
  domainCart,
  domainCheckout,
  system,
  vps,
  feedback,
  account: accountReducer,
  billing: billingReducer,
  cardPayment: cardPaymentReducer,
  case: caseReducer,
  cases: casesReducer,
  contact: contactReducer,
  contacts: contactsReducer,
  dashboard: dashboardReducer,
  documents: documentsReducer,
  domains: domainsReducer,
  header: headerReducer,
  JTI: JTIReducer,
  monitoring: monitoringReducer,
  monitoredItem: monitoredItemReducer,
  order: orderReducer,
  orders: ordersReducer,
  reports: reportsReducer,
  service: serviceReducer,
  serviceConnectivity: serviceConnectivityReducer,
  serviceBroadband: serviceBroadbandReducer,
  serviceDNS: serviceDNSReducer,
  services: servicesReducer,
  serviceOrder: serviceOrderReducer,
  serviceVeeamBackup: serviceVeeamBackupReducer,
  user: userReducer,
  signature: signatureReducer,
  signatures: signaturesReducer,
  priceList: priceListReducer,
  socketio: socketioReducer,
  socketioToast: socketioToastReducer,
  weatherMap: weatherMapReducer,
  weatherMaps: weatherMapsReducer,
});
const rootReducer = (state, action) => {

  if (action.type === 'USER_LOGOUT') {

    purgeStoredState(persistConfig);
    state = undefined;
  
  }
  return appReducer(state, action);

};

export default rootReducer;
