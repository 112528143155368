import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Col, Row, Button } from 'reactstrap';
import moment from 'moment';
import { IUserState } from '../../store/user/types';
import { IDomainsState } from '../../store/domains/types';
import Section from '../../components/Section';
import SearchInput from '../../components/Inputs/SearchInput';
import {
  getAccountDomains,
  setDomainLookupString,
  setDomainSearchString,
  toggleDomainsLoading,
  resetDomainsStore,
} from '../../store/domains/actions';
import ComplexTable from '../../components/Table/ComplexTable';
import { isExpiring, isExpired } from '../../helpers/domains/domains';
import SearchIcon from '../../components/SvgIcons/SearchIcon';
import SimpleLoader from '../../components/SimpleLoader';
import Refresh from '../../components/SvgIcons/Refresh';
import DomainCart from '../../views/Services/Domains/DomainCart';
import { AppDispatch } from '../../configureStore';
import MetaButton from "../../components/MetaButton/MetaButton";

type TOwnProps = {
  user: IUserState;
  domains: IDomainsState;
  dispatch: AppDispatch;
  domainCart: IDomainsState[];
};
type TProps = TOwnProps;
const Domains: React.FC<TProps> = (props) => {

  const { dispatch, domainCart } = props;
  const {
    searchString, lookupString, loading, filtered, domains,
  } = props.domains;
  const navigate = useNavigate();

  useEffect(() => {

    refreshStore();

  }, []);
  const refreshStore = () => {

    dispatch(resetDomainsStore());
    dispatch(toggleDomainsLoading());
    props.dispatch(getAccountDomains()).then(() => props.dispatch(toggleDomainsLoading()));

  };
  const onChangeSearch = (e) => {

    const searchString = e.target.value.toString().toLowerCase();
    dispatch(setDomainSearchString(searchString));

  };
  const onChangeLookup = (e) => {

    const searchString = e.target.value.toString().toLowerCase();
    dispatch(setDomainLookupString(searchString));

  };
  const onClickLookup = () => {

    if (lookupString.length > 0) {

      navigate(`/domains/lookup?search=${lookupString}`);

    }

  };
  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
      minWidth: 100,
      Cell: (props) => (
        <div className="d-flex flex-row ">
          {props.value}
          {isExpiring(props.original) && <MetaButton color={'warning'} className="py-0 ml-3 mr-2 mb-0">Expiring</MetaButton>}
          {isExpired(props.original) && <MetaButton color={'danger'} className="py-0 ml-3 mr-2 mb-0">Expired</MetaButton>}
        </div>
      ),
    },
    {
      Header: 'Registered',
      accessor: 'createdAt',
      minWidth: 100,
      Cell: (props) => moment(props.value).format('DD/MM/YYYY'),
    },
    {
      Header: 'Expires',
      accessor: 'expireAt',
      minWidth: 100,
      Cell: (props) => {

        if (isExpired(props.original)) {

          return (<span className="text-danger">{moment(props.value).format('DD/MM/YYYY')}</span>);

        }
        if (isExpiring(props.original)) {

          return (<span style={{ opacity: 0.6 }} className="text-warning">{moment(props.value).format('DD/MM/YYYY')}</span>);

        }
        return moment(props.value).format('DD/MM/YYYY');

      },
    },
    {
      Header: 'Auto renew',
      accessor: 'autoRenew',
      minWidth: 100,
      Cell: (props) => (props.value === true ? 'Yes' : 'No'),
    },
    {
      Header: '',
      filterable: false,
      sortable: false,
      accessor: 'id',
      width: 100,
      Cell: (props) => (
        <div className="d-flex flex-row justify-content-end">
          <Link className="grey-icon rounded" to={`/domains/${props.value}`}>
            <SearchIcon title="details" />
          </Link>
        </div>
      ),
    },
  ];
  return (
    <div className="animated fadeIn mb-3">
      <Section title="Your Domains">
        <Row className="mb-4">
          <Col md="5">
            <SearchInput value={searchString} onChange={onChangeSearch} />
          </Col>
          <Col md="7" className="d-flex justify-content-end">
            <Button className="pt-1 px-1" color="primary" onClick={() => refreshStore()}>
              <Refresh title="Refresh" cssClassTop="refresh-svg" />
            </Button>
          </Col>
        </Row>
        <Row className="d-flex flex-xl-row flex-column-reverse row mt-2 mr-0 ml-0" style={{ minHeight: '400px' }}>
          <Col className="p-4 bg-white rounded">
            <Row className="mb-4 d-flex flex-row-reverse">
              <Col md={5}>
                <SearchInput hasButton value={lookupString} placeHolder="Transfer or search for your next domain" onChange={onChangeLookup} onClick={onClickLookup} />
              </Col>
            </Row>
            <SimpleLoader loading={loading} text="Loading your domains...">
              <ComplexTable
                columns={columns}
                data={searchString ? filtered : domains}
                defaultSorted={{ id: 'expireAt', desc: false }}
              />
            </SimpleLoader>
          </Col>
          {Object.keys(domainCart).length > 0 && (
          <Col className="p-0 pl-xl-3" xl={3}>
            <DomainCart />
          </Col>
          )}
        </Row>
      </Section>
    </div>
  );

};
function mapStateToProps({
  user, domains, domainChekout, domainCart,
}) {

  return {
    domains,
    user,
    domainChekout,
    domainCart,
  };

}
export default connect(mapStateToProps)(Domains);
