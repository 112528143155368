import React from 'react';

interface IProps {
  cssClass?: string;
  cssClassTop?: string;
  title?: string;
}

const ContractsIcon: React.FC<IProps> = ({ cssClass, cssClassTop, title }) => (
  <i title={title || ''} className={cssClassTop || 'icon-pencil'} />
);

export default ContractsIcon;
