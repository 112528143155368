import { AxiosResponse } from 'axios';
import {
  DASHBOARD_ADD_FAVOURITE,
  DASHBOARD_RESET,
  DASHBOARD_SET_BILLS,
  DASHBOARD_SET_OPEN_CASES,
  IDashboardToggleLoadingCases,
  DASHBOARD_TOGGLE_LOADING_CASES,
  IDashboardToggleLoadingBills,
  DASHBOARD_TOGGLE_LOADING_BILLS,
  DASHBOARD_TOGGLE_LOADING_SUMMARY,
  DASHBOARD_REMOVE_FAVOURITE,
  IDashboardRemoveFavouriteAction,
} from './types';
import { defaultErrorFeedback, errorFeedback } from '../../actions/feedback';
import { api_getCaseList } from '../../utils/Cases/Cases';
import {
  api_getMonitoredItem,
  api_getMonitoredItemUplink,
} from '../../utils/Monitoring/Monitoring';
import { MonitoredItemWidgetTypes } from '../monitoring/types';
import { api_getBills } from '../../utils/Billing';
import { setSystemError } from '../system/actions';

function setDashboardOpenCases(cases: []) {

  return {
    type: DASHBOARD_SET_OPEN_CASES,
    data: cases,
  };

}
function addDashboardFavourite(widget: MonitoredItemWidgetTypes) {

  return {
    type: DASHBOARD_ADD_FAVOURITE,
    widget,
  };

}
function setDashboardBills(bills) {

  return {
    type: DASHBOARD_SET_BILLS,
    bills,
  };

}
export function resetDashboard() {

  return {
    type: DASHBOARD_RESET,
  };

}
export function getOpenCases() {

  return (dispatch) => api_getCaseList(0).then((result: AxiosResponse) => {

    if (result.status === 200) {

      dispatch(setDashboardOpenCases(result.data));
    
    } else if (result.data.error && result.data.error.id) {

      dispatch(setSystemError(result.data.error.id));
    
    } else {

      dispatch(defaultErrorFeedback());
    
    }
  
  });

}
export function toggleCasesLoading(): IDashboardToggleLoadingCases {

  return {
    type: DASHBOARD_TOGGLE_LOADING_CASES,
  };

}
export function toggleBillsLoading(): IDashboardToggleLoadingBills {

  return {
    type: DASHBOARD_TOGGLE_LOADING_BILLS,
  };

}
export function toggleAccountSummaryLoading() {

  return {
    type: DASHBOARD_TOGGLE_LOADING_SUMMARY,
  };

}

export function getDashboardFavourites(userFavourites, accountId) {

  return (dispatch) => {

    userFavourites.map((item) => {

      const UnResolved = {
        hidden: accountId && item.accountId !== accountId,
        type: item.type,
        deviceType: '',
        id: item.itemId,
        description: item.description,
        available: true,
        location: item.location,
        name: item.name,
        latency: '',
        cpu: '',
        link: `/monitoring/${item.itemId}`,
        favouriteActive: item.active,
      };
      dispatch(addDashboardFavourite({ ...UnResolved, loading: item.active }));
      if (!item.active) {

        return;
      
      }
      api_getMonitoredItem(item.itemId).then((result1: AxiosResponse) => {

        const { data, status } = result1;
        if (status === 200) {

          if (data.type === 'device') {

            if (data.available) {

              api_getMonitoredItemUplink(item.itemId).then(
                (result: AxiosResponse) => {

                  if (result.status === 200) {

                    const resolved = {
                      hidden: accountId && item.accountId !== accountId,
                      uplink: result.data,
                      type: data.template,
                      deviceType: 'device',
                      id: item.itemId,
                      description: data.description,
                      available: data.available,
                      location: data.location,
                      name: data.name,
                      latency: data.latency,
                      cpu: data.cpu,
                      link: `/monitoring/${item.itemId}`,
                      favouriteActive: item.active,
                    };
                    dispatch(
                      addDashboardFavourite({ ...resolved, loading: false }),
                    );
                  
                  } else if (result.data?.error) {

                    if (result.status === 400) {

                      const resolved = {
                        hidden: accountId && item.accountId !== accountId,
                        uplinkError: true,
                        type: data.template,
                        deviceType: 'device',
                        id: item.itemId,
                        description: data.description,
                        available: data.available,
                        location: data.location,
                        name: data.name,
                        latency: data.latency,
                        cpu: data.cpu,
                        link: `/monitoring/${item.itemId}`,
                        favouriteActive: item.active,
                      };

                      dispatch(
                        addDashboardFavourite({ ...resolved, loading: false }),
                      );
                    
                    } else {

                      dispatch(
                        errorFeedback(
                          `Error fetching traffic data for device id ${
                            item.itemId
                          }, ${data.description || data.location}, ${
                            data.name
                          } message from server: '${
                            result.data.error.message
                          }', status code: '${result.data.error.status}'`,
                        ),
                      );
                    
                    }
                  
                  }
                
                },
              );
            
            } else {

              const resolved = {
                hidden: accountId && item.accountId !== accountId,
                uplink: undefined,
                type: data.template,
                deviceType: 'device',
                id: item.itemId,
                description: data.description,
                available: data.available,
                location: data.location,
                name: data.name,
                latency: data.latency,
                cpu: data.cpu,
                link: `/monitoring/${item.itemId}`,
                favouriteActive: item.active,
              };

              dispatch(addDashboardFavourite({ ...resolved, loading: false }));
            
            }
          
          }

          if (data.type === 'port') {

            if (data.available) {

              api_getMonitoredItemUplink(item.itemId).then(
                (result: AxiosResponse) => {

                  if (result.status === 200) {

                    const resolved = {
                      hidden: accountId && item.accountId !== accountId,
                      uplink: result.data,
                      id: item.itemId,
                      description: data.description,
                      available: data.available,
                      location: data.location,
                      name: data.name,
                      outBound: data.outBound,
                      inBound: data.inBound,
                      link: `/monitoring/${item.itemId}`,
                      type: data.type,
                      favouriteActive: item.active,
                    };

                    dispatch(
                      addDashboardFavourite({ ...resolved, loading: false }),
                    );
                  
                  } else if (result.data?.error) {

                    if (result.status === 400) {

                      const resolved = {
                        hidden: accountId && item.accountId !== accountId,
                        uplinkError: true,
                        id: item.itemId,
                        description: data.description,
                        available: data.available,
                        location: data.location,
                        name: data.name,
                        outBound: data.outBound,
                        inBound: data.inBound,
                        link: `/monitoring/${item.itemId}`,
                        type: data.type,
                        favouriteActive: item.active,
                      };
                      dispatch(
                        addDashboardFavourite({ ...resolved, loading: false }),
                      );
                    
                    } else {

                      dispatch(
                        errorFeedback(
                          `Error fetching traffic data for device id ${
                            item.itemId
                          }, ${data.description || data.location}, ${
                            data.name
                          } message from server: '${
                            result.data.error.message
                          }', status code: '${result.data.error.status}'`,
                        ),
                      );
                    
                    }
                  
                  }
                
                },

              );
            
            } else {

              const resolved = {
                hidden: accountId && item.accountId !== accountId,
                uplink: undefined,
                type: data.template,
                deviceType: 'device',
                id: item.itemId,
                description: data.description,
                available: data.available,
                location: data.location,
                name: data.name,
                latency: data.latency,
                cpu: data.cpu,
                link: `/monitoring/${item.itemId}`,
                favouriteActive: item.active,
              };

              dispatch(addDashboardFavourite({ ...resolved, loading: false }));
            
            }
          
          }
        
        } else {

          const resolved = {
            hidden: accountId && item.accountId !== accountId,
            id: item.itemId,
            description: item.description,
            error: 'Unable to contact device',
            available: false,
            location: item.location,
            name: item.name,
            outBound: '',
            inBound: ' ',
            link: `/monitoring/${item.itemId}`,
            type: '',
            favouriteActive: item.active,
          };
          dispatch(addDashboardFavourite({ ...resolved, loading: false }));
          dispatch(
            errorFeedback(`Could not fetch monitored item id:${item.itemId}`),
          );
        
        }
      
      });
    
    });
  
  };

}

export function getDashboardBills() {

  return (dispatch) => api_getBills().then((result: AxiosResponse) => {

    if (result.status === 200) {

      dispatch(setDashboardBills(result.data.slice(0, 4)));
    
    } else if (result.data?.error && result.data?.error?.id) {

      dispatch(setSystemError(result.data.error.id));
    
    } else {

      dispatch(defaultErrorFeedback());
    
    }
  
  });

}
export const removeFavouriteFromDashboard = (
  itemId,
): IDashboardRemoveFavouriteAction => ({
  type: DASHBOARD_REMOVE_FAVOURITE,
  itemId,
});
