/* eslint-disable */
import React, { Component, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { PropTypes } from 'prop-types'
import { Row, Col, Alert, FormFeedback } from 'reactstrap'
import { connect } from 'react-redux'
import Select from 'react-select'
import momentLocalizer from 'react-widgets-moment'
import Moment from 'moment'
import { checkPermission } from '../../utils/Auth/AuthService'
import { ForbiddenError, GenericNotFound } from '../../components/Errors/Errors'
import { createNewCase } from '../../store/case/actions'
import 'react-widgets/dist/css/react-widgets.css'
import GenericCase from './GenericCase'
import OtherChange from './OtherChange'
import ADSLFault from './ADSLFault'
import VoiceFault from './VoiceFault'
import VoiceChange from './VoiceChange'
import NewVPN from './NewVPN'
import { ACCOUNT_TYPE_RESALE_PARTNER } from '../../store/account/types'
// import { threadId } from 'worker_threads';
momentLocalizer(Moment)


const PartnerSelect = (props) => {

    return (
        <div
            className='mr-3 ml-auto'
            style={{ width: '400px' }}
        >
            {props.customers.length > 0 && (
                <div
                    className={
                        props.system
                            .activePartnerCustomer.name
                            ? 'd-none'
                            : 'd-block'
                    }
                >
                    <Select
                        value={props.getSelectValue()}
                        options={props.partnerCustomerOptions}
                        isSearchable
                        isClearable
                        placeholder='Select a customer account for the case'
                        onChange={
                            props.handlePartnerSelect
                        }

                    />
                    {props.parnterSelectError && (
                        <FormFeedback className='d-block'>
                            Please select a customer
                            account for the case
                        </FormFeedback>
                    )}
                </div>
            )}
        </div>

    )

}
const NewCase = (props) => {

    const [processing, setProcessing] = useState(false)
    const [caseDescriptionValue, setCaseDescriptionValue] = useState(null)
    const [descError, setDescError] = useState(false)
    const [parnterSelectError, setParnterSelectError] = useState(false)
    const [error, setError] = useState(false)
    const [implTime, setImplTime] = useState(Moment().add(1, 'days').toDate())
    const [implTimeDisabled, setImplTimeDisabled] = useState(false)
    const [partnerCustomerError, setPartnerCustomerError] = useState(false)
    const [partnerCustomer, setPartnerCustomer] = useState('')

    const navigate = useNavigate()
    const params = useParams()

    const handleValidSubmit = (event, values) => {
        const { type } = params
        if (caseDescriptionValue === null) {
            setDescError(true)
            return false
        }
        if (partnerCustomer === '' && props.account.accountTypeCode === ACCOUNT_TYPE_RESALE_PARTNER) {
            setParnterSelectError(true);
            return false
        }
        setProcessing(true);
        let theHTML = `<h6>Type</h6><p>${type}</p>`
        for (const prop in values) {
            if (!values.hasOwnProperty(prop)) continue
            theHTML += `<h6>${prop.replace(/^case/, '')}</h6><p>${values[prop]}</p>`
        }
        if (type === 'change') {
            if (implTimeDisabled === false) {
                theHTML +=
                    `<h6>Preferred Implementation Time:</h6><p>${
                        implTime.toString()
                    }</p>`
            } else {
                theHTML += '<h6>Preferred Implementation Time:</h6><p>ASAP</p>'
            }
        }
        theHTML += `<h4>Description</h4>${caseDescriptionValue}`
        const category = values.caseCategory === 'Non Service Affecting' ? 'non_service_affecting' : 'service_affecting'
        props.dispatch(createNewCase({
            title: values.caseTitle,
            priority: values.casePriority,
            category,
            formData: theHTML,
            partnerCustomerId: partnerCustomer,
        })).then((result) => {
            if (result.caseId) {
                navigate('/cases')
            } else {
                setError(true);
                setProcessing(false);
                window.scrollTo(0, 0)
            }
        })
    }

    const handleInvalidSubmit = (event, errors, values) => {

        if (caseDescriptionValue === null) {

            setDescError(true);
            return false

        }

        if (partnerCustomer === '' && props.account.accountTypeCode === ACCOUNT_TYPE_RESALE_PARTNER) {

            setParnterSelectError(true);
            return false

        }
        setError(true);

    }

    const datacallback = (fromChild) => {

        setCaseDescriptionValue(fromChild.substring(0, 2000));

    }

    const handlePartnerSelect = (selected) => {

        if (selected) {

            const partnerCustomer = props.account.customers.filter(
                (customer) => customer.accountNumber === selected.value,
            )
            setPartnerCustomer(selected.value);

        } else {

            setPartnerCustomer(null);

        }

    }

    const getSelectValue = () => {

        const {
            account,
            system,
        } = props
        const { customers } = account
        const partnerCustomerOptions = customers.map((partner) => {

            return {
                label: partner.name,
                value: partner.accountNumber,
            }

        })

        if (partnerCustomer) {

            return partnerCustomer.name

        }
        if (system.activePartnerCustomer.accountNumber) {

            return partnerCustomerOptions.filter(
                (option) => option.label === system.activePartnerCustomer.name,
            )

        }
        return null

    }

    useEffect(() => {
        const { system } = props
        if (system.activePartnerCustomer.accountNumber) {

            setPartnerCustomer(system.activePartnerCustomer.accountId)

        }
    }, [])


    const { account } = props
    const { customers } = account
    const partnerCustomerOptions = customers.map((partner) => {

        return {
            label: partner.name,
            value: partner.accountNumber,
        }

    })
    const { type } = params
    if (!checkPermission('cases.write', props.user.permissions)) {

        return <ForbiddenError />

    }

    switch (type) {
        case 'generic':
            return (
                <Row>
                    <Col>
                        <div className='animated fadeIn'>
                            {error && (
                                <Alert color='danger'>
                                    <strong>Oh snap!</strong> Something went
                                    seriously wrong with this one!
                                </Alert>
                            )}
                            <PartnerSelect
                                parnterSelectError={parnterSelectError}
                                handlePartnerSelect={handlePartnerSelect}
                                getSelectValue={getSelectValue}
                                system={props.system}
                                partnerCustomerOptions={partnerCustomerOptions}
                                customers={customers}
                            />
                            <GenericCase
                                error={error}
                                handleValidSubmit={handleValidSubmit}
                                onInvalidSubmit={handleInvalidSubmit}
                                descError={descError}
                                callback={datacallback}
                                processing={processing}
                            />
                        </div>
                    </Col>
                </Row>
            )
        case 'adslfault':
            return (
                <Row>
                    <Col>
                        <div className='animated fadeIn'>
                            {error && (
                                <Alert color='danger'>
                                    <strong>Oh snap!</strong> Something went
                                    seriously wrong with this one!
                                </Alert>
                            )}
                            <PartnerSelect
                                parnterSelectError={parnterSelectError}
                                handlePartnerSelect={handlePartnerSelect}
                                getSelectValue={getSelectValue}
                                system={props.system}
                                partnerCustomerOptions={partnerCustomerOptions}
                                customers={customers}
                            />
                            <ADSLFault
                                isResalePartner={props.account.accountTypeCode === ACCOUNT_TYPE_RESALE_PARTNER}
                                setCustomerSelectError={() => setParnterSelectError(true)}
                                partnerCustomer={partnerCustomer}
                                onInvalidSubmit={handleInvalidSubmit}
                                parnterSelectError={parnterSelectError}
                                navigate={navigate}
                            />
                        </div>
                    </Col>
                </Row>
            )
        case 'voicefault':
            return (
                <div className='animated fadeIn'>
                    {error && (
                        <Alert color='danger'>
                            <strong>Oh snap!</strong> Something went
                            seriously wrong with this one!
                        </Alert>
                    )}
                    <PartnerSelect
                        parnterSelectError={parnterSelectError}
                        handlePartnerSelect={handlePartnerSelect}
                        getSelectValue={getSelectValue}
                        system={props.system}
                        partnerCustomerOptions={partnerCustomerOptions}
                        customers={customers}
                    />
                    <VoiceFault
                        dispatch={props.dispatch}
                        isResalePartner={props.account.accountTypeCode === ACCOUNT_TYPE_RESALE_PARTNER}
                        setCustomerSelectError={() => setParnterSelectError(true)}
                        partnerCustomer={partnerCustomer}
                        onInvalidSubmit={handleInvalidSubmit}
                        parnterSelectError={parnterSelectError}
                        navigate={navigate}
                    />
                </div>
            )
        case 'voicechange':
            return (
                <div className='animated fadeIn'>
                    {error && (
                        <Alert color='danger'>
                            <strong>Oh snap!</strong> Something went
                            seriously wrong with this one!
                        </Alert>
                    )}
                    <PartnerSelect
                        parnterSelectError={parnterSelectError}
                        handlePartnerSelect={handlePartnerSelect}
                        getSelectValue={getSelectValue}
                        system={props.system}
                        partnerCustomerOptions={partnerCustomerOptions}
                        customers={customers}
                    />
                    <VoiceChange
                        dispatch={props.dispatch}
                        onInvalidSubmit={handleInvalidSubmit}
                        inputOnChange={() => {

                            setImplTimeDisabled(!implTimeDisabled)

                        }}
                        implTime={implTime}
                        implTimeDisabled={implTimeDisabled}
                        datetimeOnChange={(theDate) => {

                            setImplTime(theDate)

                        }}

                        isResalePartner={props.account.accountTypeCode === ACCOUNT_TYPE_RESALE_PARTNER}
                        setCustomerSelectError={() => setParnterSelectError(true)}
                        partnerCustomer={partnerCustomer}
                        navigate={navigate}

                    />
                </div>
            )
        case 'vpn':
            return (
                <div className='animated fadeIn'>
                    {error && (
                        <Alert color='danger'>
                            <strong>Oh snap!</strong> Something went
                            seriously wrong with this one!
                        </Alert>
                    )}
                    <PartnerSelect
                        parnterSelectError={parnterSelectError}
                        handlePartnerSelect={handlePartnerSelect}
                        getSelectValue={getSelectValue}
                        system={props.system}
                        partnerCustomerOptions={partnerCustomerOptions}
                        customers={customers}
                    />
                    <NewVPN
                        dispatch={props.dispatch}
                        onInvalidSubmit={handleInvalidSubmit}
                        parnterSelectError={parnterSelectError}
                        isResalePartner={props.account.accountTypeCode === ACCOUNT_TYPE_RESALE_PARTNER}
                        setCustomerSelectError={() => setParnterSelectError(true)}
                        partnerCustomer={partnerCustomer}
                        navigate={navigate}
                    />
                </div>
            )
        case 'change':
            return (
                <Row>
                    <Col>
                        <div className='animated fadeIn'>
                            {error && (
                                <Alert color='danger'>
                                    <strong>Oh snap!</strong> Something went
                                    seriously wrong with this one!
                                </Alert>
                            )}
                            <PartnerSelect
                                parnterSelectError={parnterSelectError}
                                handlePartnerSelect={handlePartnerSelect}
                                getSelectValue={getSelectValue}
                                system={props.system}
                                partnerCustomerOptions={partnerCustomerOptions}
                                customers={customers}
                            />
                            <OtherChange
                                dispatch={props.dispatch}
                                handleValidSubmit={handleValidSubmit}
                                onInvalidSubmit={handleInvalidSubmit}
                                descError={descError}
                                parnterSelectError={parnterSelectError}
                                processing={processing}
                                callback={datacallback}
                                inputOnChange={() => {

                                    setImplTimeDisabled(!implTimeDisabled)

                                }}
                                implTime={implTime}
                                implTimeDisabled={
                                    implTimeDisabled
                                }
                                datetimeOnChange={(theDate) => {

                                    setImplTime(theDate)

                                }}
                            />
                        </div>
                    </Col>
                </Row>
            )

        default:
            return <GenericNotFound />
    }

}

function mapStateToProps({
                             user,
                             account,
                             system,
                         }) {

    return {
        user,
        account,
        system,
    }

}

export default connect(mapStateToProps)(NewCase)
