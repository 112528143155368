import { IWeatherMap } from '../weatherMap/types';

export const WEATHERMAPS_SET_WEATHERMAPS = 'WEATHERMAPS_SET_WEATHERMAPS';

export interface IWeatherMapsState {
  weatherMaps: TWeatherMapsList;
}

export type TWeatherMapsList = Array<IWeatherMap>;

export interface IWeatherMapsSetWeatherMapsAction {
  type: typeof WEATHERMAPS_SET_WEATHERMAPS;
  data: TWeatherMapsList;
}
export type TWeatherMapsSetWeatherMaps = (data: TWeatherMapsList) => (IWeatherMapsSetWeatherMapsAction);

export type TWeatherMapsGetWeatherMaps = () => (dispatch: any) => Promise<boolean>;

export type TWeatherMapsActionTypes = IWeatherMapsSetWeatherMapsAction;
