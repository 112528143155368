import React from 'react';
import {
  Dropdown, DropdownItem, DropdownMenu, DropdownToggle,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import Gravatar from 'react-gravatar';

interface IProps {
  companyName: string;
  logout: () => any;
  username: string;
  userEmail: string;
  handleToggle?: (event: React.KeyboardEvent<HTMLInputElement>) => any;
  dropdownOpen: boolean;
}
const UserDropdown: React.FC<IProps> = (props) => {

  const {
    userEmail, logout, companyName, username, dropdownOpen, handleToggle,
  } = props;
  const handleChange = (event: React.KeyboardEvent<HTMLInputElement>) => {

    if (handleToggle) {

      handleToggle(event);

    }

  };
  const handleLogout = () => {

    logout();

  };
  return (
    <Dropdown direction="down" isOpen={dropdownOpen} toggle={handleChange} className="d-lg-block mr-sm-5 text-dark">
      <DropdownToggle nav>
        <Gravatar email={userEmail} size={45} className="img-avatar" />
        <div className="d-none d-sm-block float-right">
          <div className="d-table  ml-3 caret">
            <div className="d-table-row font-lg text-dark text-left"><strong>{username}</strong></div>
            <div className="d-table-row"><small className="d-block mt-n1 text-light">{companyName}</small></div>
          </div>
        </div>
      </DropdownToggle>
      <DropdownMenu right className="logindropdown">
        <Link to="/reset-password">
          <DropdownItem
            className="d-flex justify-content-between text-light"
          >
            <span><i className="icon icon-key text-light ml-2 " /></span>
            <span className="d-xs-none d-none d-sm-block">Reset Password</span>
          </DropdownItem>
        </Link>
        <a href={process.env.REACT_APP_HSO_ID_URL}>
          <DropdownItem
              className="d-flex justify-content-between text-light"
          >
            <span><i className="icon icon-user text-light ml-2 " /></span>
            <span className="d-xs-none d-none d-sm-block">Manage MFA</span>
          </DropdownItem>
        </a>
        <Link to="/tutorials">
          <DropdownItem
            className="d-flex justify-content-between text-light"
          >
            <span><i className="icon icon-question text-light ml-2 " /></span>
            <span className="d-xs-none d-none d-sm-block">Tutorials</span>
          </DropdownItem>
        </Link>
        <DropdownItem onClick={handleLogout} className="d-flex justify-content-between text-light">
          <span>
            {' '}
            <i className="icon icon-logout text-light ml-2" />
          </span>
          <span>Log out</span>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );

};
export default UserDropdown;
