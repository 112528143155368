import React, { FunctionComponent, PropsWithChildren } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import { IFormError } from '../../Forms/types';

type Props = {
  label?: string;
  id: string;
  name?: string;
  value: string | number;
  readOnly?: boolean;
  onChange? : (event: React.ChangeEvent<HTMLInputElement>) => any;
  error?: IFormError;
  truncate?: boolean;
  textAlignRight?: boolean;
  textColor?: 'danger' | 'primary' | 'warning' | 'success';
  horizontal?: boolean;
  min?: number;
  max?: number;
  small?: boolean;
  placeholder?: string;
  type?: | 'text'
  | 'email'
  | 'select'
  | 'file'
  | 'radio'
  | 'checkbox'
  | 'textarea'
  | 'button'
  | 'reset'
  | 'submit'
  | 'date'
  | 'datetime-local'
  | 'hidden'
  | 'image'
  | 'month'
  | 'number'
  | 'range'
  | 'search'
  | 'tel'
  | 'url'
  | 'week'
  | 'password'
  | 'datetime'
  | 'time'
  | 'color';
};
const TextInput: FunctionComponent<Props & PropsWithChildren> = (props) => {

  const horizontal = typeof props.horizontal === 'undefined' ? true : props.horizontal;
  return (
    <FormGroup className="row">
      <Label className={`col-12 ${!horizontal ? '' : 'col-md-4'} text-light col-form-label font-weight-bold`} for={props.label} style={{ fontSize: '16px' }}>{props.label || props.children}</Label>
      <div className={`col-12 ${!horizontal ? '' : 'col-md-8'}`}>
        {props.readOnly
                    && (
                    <span
                      className={
                            `${props.truncate ? 'truncate ' : ''}
                            form-control border-0 pl-0 mb-0 
                            ${props.textAlignRight ? 'text-right ' : ''}
                            ${props.textColor ? `text-${props.textColor}` : 'text-light'}`
                        }
                      style={{ fontSize: '16px' }}
                    >
                      {props.value}
                    </span>
                    )}
        {!props.readOnly
                    && (
                    <>
                      <Input
                        type={props.type}
                        id={props.id}
                        name={props.name}
                        className={`form-control text-light mb-0${props.error ? ' is-invalid' : ''}`}
                        style={{ fontSize: '16px' }}
                        value={props.value}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {

                          props.onChange ? props.onChange(event) : {};
                        
                        }}
                        min={props.min}
                        max={props.max}
                        placeholder={props.placeholder}
                      />
                      {props.error
                        && (
                        <div className="invalid-feedback">
                          {props.error.message}
                        </div>
                        )}
                    </>
                    )}
      </div>
    </FormGroup>
  );

};
export default TextInput;
