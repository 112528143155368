/* eslint-disable */
import React, { Component } from 'react';
import {Badge} from 'reactstrap';
import {getApiData} from '../../utils/Api/ApiRequest';

class Footer extends Component {

  constructor(props) {

    super(props);
    this.state = {
      api: {}
    };
    
  }

  getApiDetails() {

    getApiData().then((result) => {

      this.setState({api: result});
        
    });
    
  }

  componentDidMount() {

    this.getApiDetails();
    
  }

  render() {

    return (
      <footer className="app-footer text-light">
          <span style={{flexGrow: 2}}> &copy; <a href="https://www.hso.co.uk">HighSpeed Office Limited</a></span>
          {/* <span style={{flexGrow: 1, textAlign: 'right'}}>API Build: {this.state.api.version} {process.env.NODE_ENV !== 'production' && <Badge color="danger">{this.state.api.version}</Badge>}</span>
          <span style={{flexGrow: 1, textAlign: 'right'}}>Portal Build: {process.env.REACT_APP_GIT_SHA}</span> */}
      </footer>
    );
  
  }
}

export default Footer;
