import { IFormError } from '../../components/Forms/types';

export const CASE_GET_CASE = 'CASE_GET_CASE';
export const CASE_SET_CASE = 'CASE_SET_CASE';
export const CASE_SET_ERRORS = 'CASE_SET_ERRORS';
export const CASE_SET_ACTIVITY = 'CASE_SET_ACTIVITY';
export const CASE_TOGGLE_LOADING = 'CASE_TOGGLE_LOADING';
export const CASE_ASSIGN_ACTIVITY_IS_OPEN_FIELDS = 'CASE_ASSIGN_ACTIVITY_IS_OPEN_FIELDS';
export const CASE_TOGGLE_ACTIVITY_IS_OPEN = 'CASE_TOGGLE_ACTIVITY_IS_OPEN';
export const CASE_TOGGLE_ACTIVITY_CONTENT_LOADING = 'CASE_TOGGLE_ACTIVITY_CONTENT_LOADING';
export const CASE_SET_REPLY_VALUE = 'CASE_SET_REPLY_VALUE';
export const CASE_SET_SHOW_REPLY_FORM = 'CASE_SET_SHOW_REPLY_FORM';
export const CASE_SET_REPLY_PROCESSING = 'CASE_SET_REPLY_PROCESSING';
export const CASE_SET_DESC_ERROR = 'CASE_SET_DESC_ERROR';
export const CASE_UPDATE_RESOLVE_REASON = 'CASE_UPDATE_RESOLVE_REASON';
export const CASE_UPDATE_ESCALATION_NOTE = 'CASE_UPDATE_ESCALATION_NOTE';
export const CASE_UPDATE_RESOLVE_REASON_DROPDOWN = 'CASE_UPDATE_RESOLVE_REASON_DROPDOWN';
export const CASE_TOGGLE_MODAL = 'CASE_TOGGLE_MODAL';
export const CASE_ESCALATION_TOGGLE_MODAL = 'CASE_ESCALATION_TOGGLE_MODAL';
export const CASE_RESOLVE_CASE = 'CASE_RESOLVE_CASE';
export const CASE_ESCALATE_CASE = 'CASE_ESCALATE_CASE';
export const CASE_SET_RESOLVE_MODAL_ERROR_TEXT = 'CASE_SET_RESOLVE_MODAL_ERROR_TEXT';
export const CASE_SET_ESCALATION_MODAL_ERROR_TEXT = 'CASE_SET_ESCALATION_MODAL_ERROR_TEXT';
export const TOGGLE_CASE_REASON_DROPDOWN = 'TOGGLE_CASE_REASON_DROPDOWN';
export const CASE_TOGGLE_MODAL_LOADING = 'CASE_TOGGLE_MODAL_LOADING';
export const CASE_TOGGLE_ESCALATION_MODAL_LOADING = 'CASE_TOGGLE_ESCALATION_MODAL_LOADING';

export interface IToggleCaseModalLoadingAction {
  type: typeof CASE_TOGGLE_MODAL_LOADING;
}
export interface IToggleCaseEscalationModalLoadingAction {
  type: typeof CASE_TOGGLE_ESCALATION_MODAL_LOADING;
}
export interface ITogglCaseReasonDropdownAction {
  type: typeof TOGGLE_CASE_REASON_DROPDOWN;
}
export interface ISetResolveCaseErrorText {
  type: typeof CASE_SET_RESOLVE_MODAL_ERROR_TEXT;
  payload: string;
}
export interface ISetEscalateCaseErrorText {
  type: typeof CASE_SET_ESCALATION_MODAL_ERROR_TEXT;
  payload: string;
}
export interface IResolveCase {
  type: typeof CASE_RESOLVE_CASE;
}
export interface IEscalateCase {
  type: typeof CASE_ESCALATE_CASE;
}
export interface ICaseToggleModal {
  type: typeof CASE_TOGGLE_MODAL;
}
export interface ICaseEscalationToggleModal {
  type: typeof CASE_ESCALATION_TOGGLE_MODAL;
}
export interface IUpdateResolveReason {
  type: typeof CASE_UPDATE_RESOLVE_REASON;
  payload: string;
}
export interface IUpdateEscalationNote {
  type: typeof CASE_UPDATE_ESCALATION_NOTE;
  payload: string;
}
export interface IUpdateResolveReasonDropdown {
  type: typeof CASE_UPDATE_RESOLVE_REASON_DROPDOWN;
  payload: string;
}
export interface ISetCaseReplyProcessing {
  type: typeof CASE_SET_REPLY_PROCESSING;
  payload: boolean;
}
export interface ISetCaseDescError {
  type: typeof CASE_SET_DESC_ERROR;
  payload: boolean;
}
export interface ISetCaseReplyValue {
  type: typeof CASE_SET_REPLY_VALUE;
  value: string;
}
export interface ISetCaseShowReplyForm {
  type: typeof CASE_SET_SHOW_REPLY_FORM;
}
export interface IAssignActivityIsOpenFields {
  type: typeof CASE_ASSIGN_ACTIVITY_IS_OPEN_FIELDS;
}
export interface ISetCaseAction {
  type: typeof CASE_GET_CASE;
  data: object;
}
export interface ISetCaseErrorsAction {
  type: typeof CASE_SET_ERRORS;
  errors: Array<IFormError>;
}
export interface IToggleCaseLoadingAction {
  type: typeof CASE_TOGGLE_LOADING;
}
export interface IToggleCaseActivityContentLoading {
  type: typeof CASE_TOGGLE_ACTIVITY_CONTENT_LOADING;
  id: string;
}

export interface ISetCaseActivity {
  type: typeof CASE_SET_ACTIVITY;
  data: IActivity;
}
export interface IActivity {
  id: string;
  type: string;
  createdOn: Record<string, any>;
  modifiedOn: Record<string, any>;
  subject: string;
  status: string;
  content: string;
  createdBy: string;
  emailAddress: Record<string, any>;
  isOpen?: boolean;
  emailAddresses: IEmailAddresses;
  showFullMessage: boolean;
  contentLoading: boolean;

}
export interface IEmailAddresses {
  from: string;
  to: string;
}
export interface IToggleActivity {
  type: typeof CASE_TOGGLE_ACTIVITY_IS_OPEN;
  id: string;
}
export interface ICaseState {
  caseModalLoading: boolean;
  escalationcaseModalLoading: boolean;
  descError: boolean;
  replyProcessing: boolean;
  showReplyForm: boolean;
  replyValue: string;
  loading: boolean;
  readOnly: boolean;
  data: TCase;
  errors: Array<IFormError>;
  reasonDropdownOpen: boolean;
}
export type TCase = {
  assignedTo: string;
  id: string;
  ticketNumber: string;
  title: string;
  status: string;
  state: string;
  escalationState: string;
  createdOn: object;
  modifiedOn: object;
  origin: string;
  priority: string;
  openedBy: string;
  activities: Array<IActivity>;
  caseId: string;
  resolveReason: string;
  escalationNote: string;
  resolveReasonDropdown: number;
  modalOpen: boolean;
  escalationModalOpen: boolean;
  resolveModalErrorText: string;
  escalateModalErrorText: string;
  caseWorker: TcaseWorker;
};

export type TcaseWorker = {
  firstName: string;
  lastName: string;
  name: string;
  email: string;
  phone: string | null;
};

export type CaseActionTypes =
    ISetCaseAction |
    ISetCaseErrorsAction |
    IToggleCaseLoadingAction |
    IAssignActivityIsOpenFields |
    IToggleActivity |
    ISetCaseShowReplyForm |
    ISetCaseReplyValue |
    ISetCaseDescError |
    ISetCaseReplyProcessing |
    IUpdateResolveReason |
    IUpdateEscalationNote |
    IUpdateResolveReasonDropdown |
    ITogglCaseReasonDropdownAction |
    ICaseToggleModal |
    ICaseEscalationToggleModal |
    IResolveCase |
    IEscalateCase |
    ISetResolveCaseErrorText |
    ISetEscalateCaseErrorText |
    IToggleCaseEscalationModalLoadingAction |
    IToggleCaseActivityContentLoading |
    ISetCaseActivity |
    IToggleCaseModalLoadingAction;
