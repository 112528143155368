import React, { FunctionComponent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Alert, Modal, ModalBody, ModalHeader,
} from 'reactstrap';
import UnitBasedOrderFormWizard from './UnitBasedOrderFormWizard';
import { TPreSalesServiceOrderItem } from '../../../store/serviceOrder/types';
import { TPricingData } from '../../../utils/Pricing/Pricing';
import SimpleLoader from '../../SimpleLoader';
import Header from '../../Section/Header';
import StandardOrderFormWizard from './StandardOrderFormWizard';
import { serviceOrderActions } from '../../../store/serviceOrder/actions';
import { TPriceListItem } from '../../../store/priceList/types';
import { getVeeamBackupService } from '../../../store/serviceVeeamBackup/actions';
import { formatData } from '../../../helpers/FormatData/formatData';
import { IAccountState } from '../../../store/account/types';
import { AppDispatch } from '../../../configureStore';

type TOwnProps = {
  dispatch: AppDispatch;
  priceListItem: TPriceListItem;
  close: () => any;
  account: IAccountState;
  item?: TPreSalesServiceOrderItem;
  original?: TPreSalesServiceOrderItem;
  service?: any;
  onSubmitted?: () => any;
};
const NewServiceOrderItemModal: FunctionComponent<TOwnProps> = ({
  dispatch,
  account,
  priceListItem,
  service,
  item,
  original,
  close,
  onSubmitted,
}) => {

  const [open, setOpen] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [systemError, setSystemError] = useState<boolean>(false);

  const resolveItem = async () => {

    const commonFields = {
      id: priceListItem.id,
      name: priceListItem.name,
      productCode: priceListItem.productCode,
    };
    if (priceListItem.pricing === 'unit') {

      let currentQuota = 0;

      if (service && priceListItem.productCode === 'HSO50-211') {

        service = await dispatch(getVeeamBackupService(service.id, ['billing']));
        currentQuota = Number(formatData(service.storageQuota, false));
      
      }

      dispatch(serviceOrderActions.setCurrentItem({
        ...commonFields,
        type: 'unit',
        quantity: currentQuota,
        unit: priceListItem.unit,
        contractLength: service?.rentalPeriod || 12,
        install: service?.billing?.install,
        rental: service?.billing?.rental,
      }));
    
    } else {

      dispatch(serviceOrderActions.setCurrentItem({
        ...commonFields,
        type: 'standard',
        contractLength: 12,
      }));
    
    }
  
  };

  useEffect(() => {

    setLoading(true);
    resolveItem().then(() => {

      setLoading(false);
    
    });

    return () => dispatch(serviceOrderActions.resetCurrentItem());
  
  }, []);

  const getPricing = (data: object): Promise<boolean | TPricingData> => {

    if (item) {

      return dispatch(serviceOrderActions.getPricing(item.id, data))
        .then((result) => {

          if (result) {

            dispatch(serviceOrderActions.setCurrentItemField('install', result.nonRecurring));
            dispatch(serviceOrderActions.setCurrentItemField('rental', result.recurring));
          
          } else {

            setSystemError(true);
          
          }
        
        });
    
    }
    return new Promise(() => false);
  
  };

  const onSubmit = () => {

    if (item) {

      dispatch(serviceOrderActions.addServiceOrderItem(item));
      if (onSubmitted) {

        setTimeout(onSubmitted, 500);
      
      }
      toggle();
    
    }
  
  };

  const toggle = () => {

    setOpen(false);
    return new Promise(() => setTimeout(close, 500)); // let animation show
  
  };

  const setField = (key: string, value: string) => dispatch(serviceOrderActions.setCurrentItemField(key, value));

  return (
    <Modal size="lg" isOpen={open}>
      <ModalHeader toggle={toggle} className="border-bottom-0 pt-4 px-4">
        <Header title={priceListItem.name || ''} />
        <p className="font-sm grey">{priceListItem.description}</p>
      </ModalHeader>
      <ModalBody
        className="pt-0 px-4 pb-4"
        style={{ minHeight: loading ? '100px' : '', transition: 'min-height 1s ease-out' }}
      >
        <SimpleLoader loading={loading} text="Loading item details...">
          {systemError ? (
            <Alert color="warning">
              There was an issue with your order.
              <a href={`mailto:${account.accountManager.email}`}>
                Please
                contact your account manager.
              </a>
            </Alert>
          ) : (
            <>
              {item?.type === 'standard' && original?.type === 'standard' ? (
                <StandardOrderFormWizard
                  item={item}
                  original={original}
                  onSubmit={onSubmit}
                  setField={setField}
                  getPricing={getPricing}
                />
              ) : ''}
              {item?.type === 'unit' && original?.type === 'unit' ? (
                <UnitBasedOrderFormWizard
                  item={item}
                  original={original}
                  onSubmit={onSubmit}
                  setField={setField}
                  getPricing={getPricing}
                />
              ) : ''}
            </>
          )}
        </SimpleLoader>
      </ModalBody>
    </Modal>
  );

};

const mapStateToProps = ({ account, serviceOrder }) => ({
  account,
  item: serviceOrder.currentItem,
  original: serviceOrder.originalCurrentItem,
});

export default connect(mapStateToProps)(NewServiceOrderItemModal);
