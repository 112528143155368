import React from 'react';

interface IProps {
  cssClass?: string;
  cssClassTop?: string;
  title?: string;
}
const Error: React.FC<IProps> = ({ cssClass, cssClassTop, title }) => (
  <svg
    className={cssClassTop || 'cls-2'}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 125"
  >
    <title>{title || ''}</title>
    <g id="Layer_2" data-name="Layer 2" className={cssClass || ''}>
      <g id="Design">
        <polygon points="64.9,29.7 50,44.6 35.1,29.7 29.7,35.1 44.6,50 29.7,64.9 35.1,70.3 50,55.4 64.9,70.3 70.3,64.9 55.4,50 ,     70.3,35.1    " />
        <path d="M50,2.5C23.8,2.5,2.5,23.8,2.5,50S23.8,97.5,50,97.5S97.5,76.2,97.5,50S76.2,2.5,50,2.5z M50,89.9     C28,89.9,10.1,72,10.1,50S28,10.1,50,10.1S89.9,28,89.9,50S72,89.9,50,89.9z" />
      </g>
    </g>
  </svg>
);

export default Error;
