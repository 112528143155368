import React, { FunctionComponent } from 'react';
import YellowDashCentered from '../YellowDashCentered';

interface IOwnProps {
  title: string;
}
const TitleYellowDashCentered: FunctionComponent<IOwnProps> = ({ title }) => (
  <div className="mb-4">
    <div style={{ fontWeight: 600 }} className="h4 text-dark text-center">{title}</div>
    <YellowDashCentered />
  </div>
);
export default TitleYellowDashCentered;
