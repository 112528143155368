import React, { useEffect } from 'react';
import HelloSign from 'hellosign-embedded';

const HS_CONTAINER_ID = 'hellosign-embedded';

type hsSettings = {
  allowCancel?: boolean;
  debug?: boolean;
  container?: HTMLElement | null;
  skipDomainVerification?: boolean;
  locale?: string;
  redirectTo?: string;
  messageListener?: (status: string) => void;
  height?: number;
};
type HelloSignEmbeddedProps = {
  clientId: string;
  url: string;
  inContainer?: boolean;
} & hsSettings;
const HelloSignEmbedded: React.FC<HelloSignEmbeddedProps> = ({
  inContainer,
  clientId,
  url,
  allowCancel,
  skipDomainVerification,
  debug,
  locale,
  redirectTo,
  messageListener,
  height,
}) => {

  useEffect(() => {

    // Get the settings and add the container if needed
    const options = getHsSettings();

    const client = new HelloSign({ clientId });
    client.open(url, options);

    if (messageListener) {

      client.on(HelloSign.events.CANCEL, () => {

        messageListener('cancelled');
      
      });

      client.on(HelloSign.events.DECLINE, () => {

        messageListener('declined');
      
      });

      client.on(HelloSign.events.REASSIGN, () => {

        messageListener('reassigned');
      
      });

      client.on(HelloSign.events.SIGN, () => {

        messageListener('signed');
      
      });
    
    }
  
  }, []);

  const getHsSettings = (): hsSettings => {

    const hs_settings: hsSettings = {
      // Required or default settings
      allowCancel,
      debug: Boolean(debug),
      skipDomainVerification,
    };

    // Optional settings
    if (inContainer) {

      hs_settings.container = document.getElementById(HS_CONTAINER_ID);
    
    }
    if (locale) {

      hs_settings.locale = locale;
    
    }
    if (redirectTo) {

      hs_settings.redirectTo = redirectTo;
    
    }
    if (height) {

      hs_settings.height = height;
    
    }

    return hs_settings;
  
  };

  const container = inContainer ? <div id={HS_CONTAINER_ID} /> : '';

  return <div>{container}</div>;

};

export default HelloSignEmbedded;
