import {
  Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row,
} from 'reactstrap';
import React from 'react';
import Section from '../../Section';
import { TBill, TBillArtifact } from '../../../store/billing/types';
import SimpleLoader from '../../SimpleLoader';

type TOwnProps = {
  isOpen: boolean;
  loading: boolean;
  cancel: () => any;
  bill: TBill;
  handleDownload: (id: number, documentId: number) => any;
};

const BillArtifactsModal: React.FC<TOwnProps> = (props) => {

  const {
    isOpen, cancel, bill, loading,
  } = props;
  const handleCancel = () => {

    cancel();
  
  };
  const handleDownload = (id) => {

    props.handleDownload(bill.id, id);
  
  };
  return (
    <Modal isOpen={isOpen} toggle={handleCancel} className="modal-warning" backdrop="static" size="lg">
      <ModalBody>
        <Section title="Select Document">
          <SimpleLoader loading={loading}>
            <Row>
              <Col>
                {bill.artifacts.map((artifact, index) => (
                  <Button onClick={handleDownload.bind(null, artifact.id)} outline color="primary" className="mr-3" key={index}>
                    {artifact.name}
                  </Button>
                ))}
              </Col>
            </Row>
          </SimpleLoader>

        </Section>
      </ModalBody>
      <ModalFooter className="border-top-0">
        <Button color="warning" onClick={handleCancel}>Cancel</Button>
      </ModalFooter>
    </Modal>
  );

};

export default BillArtifactsModal;
