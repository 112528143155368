import { apiRequest } from '../Api/ApiRequest';

export function api_getAccountDomains() {

  return apiRequest({
    url: '/services/domains',
    method: 'get',
    headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
  }).then(
    resp => resp,
  ).catch(
    error => error,
  );

}

export function api_getAccountDomain(id) {

  return apiRequest({
    url: `/services/domains/${id}?include=contact,nameservers,tld`,
    method: 'get',
    headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
  }).then(
    resp => resp,
  ).catch(
    error => error,
  );

}

export function api_updateDomainContact(domainId, contact) {

  return apiRequest({
    url: `/services/domains/${domainId}/contact`,
    method: 'put',
    headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
    data: {
      contact,
    },
  }).then(
    resp => resp,
  ).catch(
    error => error,
  );

}

export function getTopLevelDomain(tld) {

  return apiRequest({
    url: `/services/domains/tld?name=${tld}`,
    method: 'get',
    headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
  }).then(
    resp => resp,
  ).catch(
    error => error,
  );

}

export function lookupDomain(domain) {

  return apiRequest({
    url: `/services/domains/lookup?name=${domain}`,
    method: 'get',
    headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
  }).then(
    resp => resp,
  ).catch(
    error => error,
  );

}

export function api_requestAuthCode(domainId) {

  return apiRequest({
    url: `/services/domains/${domainId}/authcode`,
    method: 'get',
    headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
  }).then(
    resp => resp,
  ).catch(
    error => error,
  );

}

export function api_releaseDomain(domainId, releaseTAG) {

  return apiRequest({
    url: `/services/domains/${domainId}/release`,
    method: 'post',
    headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
    data: {
      releaseTAG,
    },
  }).then(
    resp => resp,
  ).catch(
    error => error,
  );

}
export function api_updateDomainAutoRenew(domainId, autoRenew) {

  return apiRequest({
    url: `/services/domains/${domainId}/auto-renew`,
    method: 'put',
    headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
    data: { autoRenew },
  }).then(
    resp => resp,
  ).catch(
    error => error,
  );

}
