import { TService } from '../service/types';

export const SERVICES_SET_SERVICES = 'SERVICES_SET_SERVICES';
export const SERVICES_SET_SEARCH_STRING = 'SERVICES_SET_SEARCH_STRING';
export const SERVICES_SET_SERVICE_GROUPS = 'SERVICES_SET_SERVICE_GROUPS';
export const SERVICES_TOGGLE_LOADING = 'SERVICES_TOGGLE_LOADING';
export const SERVICES_RESET_STORE = 'RESET_SERVICES_STORE';
export const SERVICES_TOGGLE_FILTER_DROPDOWN_OPEN = 'SERVICES_TOGGLE_FILTER_DROPDOWN_OPEN';
export const SERVICES_SET_FILTER_STATE = 'SERVICES_SET_FILTER_STATE';
export const SERVICES_SET_CUSTOMER_SERVICE_GROUPS = 'SERVICES_SET_CUSTOMER_SERVICE_GROUPS ';
export interface IServicesState {
  visibleState: TServiceGroup | null;
  loading: boolean;
  searchString: string;
  filtered: TService[];
  services: TService[];
  customerServiceGroups: TServiceGroup[];
  serviceGroups: TServiceGroup[];
  openFilterServicesDropdown: boolean;
}
export interface ISetCustomerServiceGroupsAction {
  type: typeof SERVICES_SET_CUSTOMER_SERVICE_GROUPS;
}
export interface ISetServicesFilterAction {
  type: typeof SERVICES_SET_FILTER_STATE;
  group: TServiceGroup;
}
export interface IResetServicesStore {
  type: typeof SERVICES_RESET_STORE;
}
export type TServiceGroup = {
  id: number;
  name: string;
};
export interface IToggleServicesFilterDropdownAction {
  type: typeof SERVICES_TOGGLE_FILTER_DROPDOWN_OPEN;
}
interface ISetAccountServicesAction {
  type: typeof SERVICES_SET_SERVICES;
  services: TService[];
}
interface ISetAccountServicesSearchAction {
  type: typeof SERVICES_SET_SEARCH_STRING;
  search: string;
}
interface IToggleAccountServicesLoadingAction {
  type: typeof SERVICES_TOGGLE_LOADING;
}
interface ISetServiceGroupsAction {
  type: typeof SERVICES_SET_SERVICE_GROUPS;
  serviceGroups: [];
}
export type ServicesActionTypes = ISetAccountServicesAction | ISetAccountServicesSearchAction | IToggleAccountServicesLoadingAction | ISetServiceGroupsAction | IResetServicesStore | IToggleServicesFilterDropdownAction | ISetServicesFilterAction | ISetCustomerServiceGroupsAction;
