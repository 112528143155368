import {
  ACCOUNT_SET_ACCOUNT_DATA,
  ACCOUNT_SET_ACCOUNT_SITES,
  ACCOUNT_TYPE_RESALE_PARTNER,
  AccountActionTypes,
  IAccountState,
} from './types';

const initialState: IAccountState = {
  name: '',
  accountNumber: '',
  accountId: '',
  accountTypeCode: 0,
  hsoSupport: '',
  accountManager: {
    firstName: '',
    lastName: '',
    name: '',
    email: '',
    telephone: '',
  },
  openCases: 0,
  activeOrders: 0,
  activeServices: 0,
  sites: [],
  customers: [],
  serviceGroups: [],
};
export function accountReducer(state = initialState, action: AccountActionTypes) {

  switch (action.type) {
    case ACCOUNT_SET_ACCOUNT_DATA:
      if (action.data.accountTypeCode === ACCOUNT_TYPE_RESALE_PARTNER) {

        action.data.customers.unshift({ accountNumber: action.data.accountNumber, name: action.data.name, accountId: action.data.accountId });
      
      }
      return {
        ...state,
        ...action.data,
      };

    case ACCOUNT_SET_ACCOUNT_SITES:
      return {
        ...state,
        sites: [...action.sites],
      };

    default:
      return { ...state };
  }

}
