import React from 'react';

interface IProps {
  cssClass?: string;
  cssClassTop?: string;
  title?: string;
}
const Phone: React.FC<IProps> = ({ cssClass, cssClassTop, title }) => (
  <svg
    className={cssClassTop || 'cls-1 blue-svg'}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 19.11 19.13"
  >
    <title>{title || ''}</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Design">
        <path
          className={cssClass || 'cls-1 blue-svg'}
          d="M15.11,11.85a1.88,1.88,0,0,0-1.37-.62,2,2,0,0,0-1.38.62l-1.27,1.27L10.77,13a3.78,3.78,0,0,1-.4-.22,13.69,13.69,0,0,1-3.32-3A8.74,8.74,0,0,1,6,8c.33-.31.64-.62.94-.92.11-.12.22-.24.34-.35a1.86,1.86,0,0,0,0-2.79L6.13,2.84l-.37-.39c-.24-.25-.5-.51-.76-.75a1.89,1.89,0,0,0-1.35-.59,2,2,0,0,0-1.38.59h0L.89,3.09A3,3,0,0,0,0,5,7.16,7.16,0,0,0,.53,8a17.7,17.7,0,0,0,3.09,5.15,19,19,0,0,0,6.33,5,9.52,9.52,0,0,0,3.55,1.05h.26a3,3,0,0,0,2.33-1s0,0,0,0c.21-.25.46-.48.71-.73l.53-.52a2,2,0,0,0,.61-1.4,1.93,1.93,0,0,0-.63-1.38Zm1.44,4.25h0c-.15.17-.32.33-.49.5s-.53.51-.78.8a2,2,0,0,1-1.52.65h-.18a8.58,8.58,0,0,1-3.15-.94,17.88,17.88,0,0,1-6-4.67A16.31,16.31,0,0,1,1.56,7.59a5.72,5.72,0,0,1-.45-2.53,1.89,1.89,0,0,1,.55-1.2L3,2.48a.94.94,0,0,1,.61-.28.83.83,0,0,1,.59.28h0q.36.34.72.72l.39.4,1.1,1.1A.77.77,0,0,1,6.47,6l-.35.35c-.34.35-.66.67-1,1l0,0a.83.83,0,0,0-.21.92v0A8.86,8.86,0,0,0,6.2,10.39h0a14.88,14.88,0,0,0,3.58,3.26,5,5,0,0,0,.5.27,3,3,0,0,1,.4.22l.05,0a.89.89,0,0,0,.4.11.86.86,0,0,0,.61-.28l1.38-1.38a.93.93,0,0,1,.61-.31.85.85,0,0,1,.59.3h0l2.23,2.22a.8.8,0,0,1,0,1.26Z"
        />
        <path
          className={cssClass || 'cls-1 blue-svg'}
          d="M10.33,4.55a5.23,5.23,0,0,1,4.23,4.23.54.54,0,0,0,.54.46h.09a.55.55,0,0,0,.45-.63,6.29,6.29,0,0,0-5.12-5.12.55.55,0,0,0-.63.44.53.53,0,0,0,.44.63Z"
        />
        <path
          className={cssClass || 'cls-1 blue-svg'}
          d="M19.1,8.44A10.35,10.35,0,0,0,10.67,0,.55.55,0,0,0,10,.45a.55.55,0,0,0,.45.63A9.23,9.23,0,0,1,18,8.62a.56.56,0,0,0,.54.45h.09a.54.54,0,0,0,.45-.62Z"
        />
      </g>
    </g>
  </svg>
);

export default Phone;
