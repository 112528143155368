export const SERVICE_TOGGLE_LOADING = 'SERVICE_TOGGLE_LOADING';
export const SERVICE_SET_SERVICE = 'SERVICE_SET_SERVICE';
export type IServiceBilling = {
  install: number;
  rental: number;
  billedTo: string;
};
export interface IServiceState {
  loading: boolean;
  data: TService;
}
export type TService = {
  name: string;
  id: string;
  productCode: string;
  goLiveDate?: string;
  orderNumber?: string;
  group?: number;
  url: string | null;
};
export interface IToggleServiceLoading {
  type: typeof SERVICE_TOGGLE_LOADING;
}
export interface ISetServiceDataAction {
  type: typeof SERVICE_SET_SERVICE;
  service: TService;
}
export type ServiceActionTypes = IToggleServiceLoading | ISetServiceDataAction;
