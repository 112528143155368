import React from 'react';

interface IProps {
  cssClass?: string;
  cssClassTop?: string;
  title?: string;
}

const CasesIcon: React.FC<IProps> = ({ cssClass, cssClassTop, title }) => (
  <svg
    className={cssClassTop || 'cls-1'}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 23 20.41"
  >
    <defs>{/* <style>.cls-1{fill:#858c91;}</style> */}</defs>
    <title>{title || ''}</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Design">
        <path
          className={cssClass || 'cls-1'}
          d="M21.85,3.32H15.23V1.15A1.15,1.15,0,0,0,14.08,0H8.92A1.14,1.14,0,0,0,7.77,1.15V3.32H1.15A1.15,1.15,0,0,0,0,4.47V19.26a1.15,1.15,0,0,0,1.15,1.15h20.7A1.15,1.15,0,0,0,23,19.26V4.47a1.14,1.14,0,0,0-1.15-1.15Zm-13-.07V1.14a.06.06,0,0,1,.06-.06H14.1a.06.06,0,0,1,.06.06V3.25a.06.06,0,0,1-.06.06H8.92a.05.05,0,0,1-.06-.06ZM1.15,4.41h20.7c-.51,1-3.18,5.55-8.13,6.65v-.63a1.15,1.15,0,0,0-1.13-1.15H10.41a1.16,1.16,0,0,0-1.15,1.15v.65C4.31,10,1.63,5.38,1.13,4.42Zm11.48,6V13.6a.05.05,0,0,1-.06.06H10.41a.06.06,0,0,1-.06-.06V10.43a.06.06,0,0,1,.06-.06h2.18a.05.05,0,0,1,0,.06Zm9.26,8.83a.05.05,0,0,1-.06.06H1.15a.06.06,0,0,1-.06-.06V6.44a13.43,13.43,0,0,0,8.17,5.75V13.6a1.16,1.16,0,0,0,1.15,1.15h2.18a1.16,1.16,0,0,0,1.15-1.15V12.19a13.47,13.47,0,0,0,8.17-5.75Z"
        />
      </g>
    </g>
  </svg>
);

export default CasesIcon;
