import React from 'react';
import { Col, Row } from 'reactstrap';
import MonitoringHost from '../../../components/Widgets/MonitoringHost';
import MonitorUpTime from '../../../components/Widgets/Monitoring/MonitorUptime';
import GaugeWidget from '../../../components/Widgets/Monitoring/GaugeWidget';
import { TMonitoredItem } from '../../../store/monitoredItem/types';
import LinkedServices from '../../../components/Widgets/LinkedServices';
import { IUserState } from '../../../store/user/types';

type TOwnProps = {
  item: TMonitoredItem;
  popUpHandler: (key: string, multiplier: number, format?: string, event?: React.MouseEvent<HTMLDivElement>) => any;
  user: IUserState;
};
const calculateUptime = (seconds: number) => {

  const output = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  };
  if ((seconds / 86400) >= 1) {

    output.days = Math.floor(seconds / 86400);
    seconds -= Math.floor((seconds / 86400)) * 86400;
  
  }
  if ((seconds / 3600) >= 1) {

    output.hours = Math.floor(seconds / 3600);
    seconds -= Math.floor((seconds / 3600)) * 3600;
  
  }
  if ((seconds / 60) >= 1) {

    output.minutes = Math.floor(seconds / 60);
    seconds -= Math.floor((seconds / 60)) * 60;
  
  }
  if (seconds > 0) {

    output.seconds = seconds;
  
  }

  return output;

};

const Windows: React.FC<TOwnProps> = (props) => {

  const { item, popUpHandler, user } = props;
  return (
    <Row className="d-flex align-items-stretch">

      <Col className="mb-2" md={4} lg={4}>
        <Row className="h-100">
          <Col className="mb-2" md={12}>
            <MonitoringHost
              name={item.name}
              location={item.location}
            />
          </Col>
          <Col className="mb-2" md={12}>
            <MonitorUpTime {...calculateUptime(item.uptime)} />
          </Col>
          <Col className="mb-2 mb-lg-0" md={12}>
            <LinkedServices
              services={item.services}
              user={user}
            />
          </Col>

        </Row>
      </Col>

      <Col className="mb-2" sm={12} md={8} lg={8}>
        <Row className="h-100">
          <Col lg={4} className="mb-2">
            <GaugeWidget value={item.cpu ? item.cpu : 0} title="CPU Utilisation" index={`${item.id}cpu`} clickHandler={() => popUpHandler('cpuUtil', 1, '%')} />
          </Col>
          <Col lg={4} className="mb-2">
            <GaugeWidget value={item.memoryUse ? item.memoryUse : 0} title="Memory" index={`${item.id}memory`} clickHandler={() => popUpHandler('memoryUsed', 1, 'B')} />
          </Col>
          <Col lg={4} className="mb-2">
            <GaugeWidget value={item.diskUse ? item.diskUse : 0} title="Disk C:" index={`${item.id}disk`} clickHandler={() => popUpHandler('diskCUsed', 1, 'B')} />
          </Col>
        </Row>
      </Col>
    </Row>
  );

};

export default Windows;
