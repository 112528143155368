import { getCode } from 'country-list';
import {
  SET_DOMAIN_CHECKOUT_STEP_STATUS,
  SET_DOMAIN_CHECKOUT_STEP,
  SET_DOMAIN_CHECKOUT_STEPS,
  INIT_DOMAIN_CHECKOUT_CONTACT,
  UPDATE_DOMAIN_CHECKOUT_CONTACT,
  UPDATE_DOMAIN_CHECKOUT_STEP,
  SET_DOMAIN_CHECKOUT_ERRORS,
  RESET_DOMAIN_CHECKOUT,
  TOGGLE_DOMAIN_CHECKOUT_TERMS,
  SET_DOMAIN_CHECKOUT_TO_CARD_PAYMENT,
  UPDATE_DOMAIN_CHECKOUT_CARD_DETAILS,
  TOGGLE_DOMAIN_CHECKOUT_ORDER_IN_PROGRESS,
  SET_DOMAIN_CHECKOUT_CARD_REDIRECT,
  UPDATE_DOMAIN_CHECKOUT_ORDER_NUMBER,
  SET_DOMAIN_CHECKOUT_PURCHASE_TOKEN,
  SET_DOMAIN_CHECKOUT_STATUS,
  SET_DOMAIN_CHECKOUT_FAILED_ITEMS,
  SET_DOMAIN_CHECKOUT_COMPLETED_ITEMS,
  UPDATE_DOMAIN_CHECKOUT_CONTACT_PHONE,
} from '../actions/domainCheckout';

const initialState = {
  status: null,
  orderNumber: null,
  step: 0,
  steps: [],
  stepStatus: 'process',
  contact: {
    country: 'GB',
  },
  errors: {},
  termsAccepted: false,
  buttonText: 'Continue',
  cardPayment: false,
  purchaseToken: null,
  cardErrors: {},
  orderDetails: {},
  cardRedirect: {},
  failedItems: [],
  completedItems: [],
};

export default function domainCheckout(state = initialState, action) {

  switch (action.type) {
    case SET_DOMAIN_CHECKOUT_STATUS:
      return {
        ...state,
        status: action.status,
      };
    case SET_DOMAIN_CHECKOUT_STEP_STATUS:
      return {
        ...state,
        stepStatus: action.status,
      };
    case SET_DOMAIN_CHECKOUT_STEPS:
      return {
        ...state,
        steps: action.steps,
      };
    case RESET_DOMAIN_CHECKOUT:
      return {
        ...initialState,
      };

    case SET_DOMAIN_CHECKOUT_STEP:
      return {
        ...state,
        step: action.step,
        buttonText: action.buttonText ? action.buttonText : 'Continue',
      };
    case UPDATE_DOMAIN_CHECKOUT_STEP:
      const newSteps = state.steps.slice(0);
      newSteps[action.index] = {
        ...newSteps[action.index],
        [action.attr]: action.value,
      };
      return {
        ...state,
        steps: newSteps,
      };
    case INIT_DOMAIN_CHECKOUT_CONTACT:
      const contact = {
        firstName: action.user.contact.firstName,
        lastName: action.user.contact.lastName,
        email: action.user.email,
        company: action.account.name,
        addressLine1: action.account.addressLine1,
        addressLine2: action.account.addressLine2,
        city: action.account.city,
        county: action.account.county,
        country: action.account.country && getCode(action.account.country) ? getCode(action.account.country) : initialState.contact.country,
        postcode: action.account.postcode,
        phone: action.account.telephone,
      };
      return {
        ...state,
        contact,
      };
    case UPDATE_DOMAIN_CHECKOUT_CONTACT:
      return {
        ...state,
        contact: { ...state.contact, [action.attr]: action.value },
      };
    case UPDATE_DOMAIN_CHECKOUT_CONTACT_PHONE: {

      const oldContact = { ...state.contact };
      oldContact.phone = action.phone;
      return {
        ...state,
        contact: oldContact,
      };

    }
    case SET_DOMAIN_CHECKOUT_ERRORS:
      return {
        ...state,
        errors: action.errors,
      };
    case TOGGLE_DOMAIN_CHECKOUT_TERMS:
      return {
        ...state,
        termsAccepted: !state.termsAccepted,
      };
    case TOGGLE_DOMAIN_CHECKOUT_ORDER_IN_PROGRESS:
      return {
        ...state,
        orderInProgress: !state.orderInProgress,
      };
    case SET_DOMAIN_CHECKOUT_TO_CARD_PAYMENT:
      return {
        ...state,
        cardPayment: true,
      };
    case SET_DOMAIN_CHECKOUT_CARD_REDIRECT:
      return {
        ...state,
        cardRedirect: action.redirect,
      };
    case SET_DOMAIN_CHECKOUT_PURCHASE_TOKEN:
      return {
        ...state,
        purchaseToken: action.token,
      };
    case UPDATE_DOMAIN_CHECKOUT_ORDER_NUMBER:
      return {
        ...state,
        orderNumber: action.orderNumber,
      };
    case SET_DOMAIN_CHECKOUT_FAILED_ITEMS:
      return {
        ...state,
        failedItems: action.failedItems,
      };
    case SET_DOMAIN_CHECKOUT_COMPLETED_ITEMS:
      return {
        ...state,
        completedItems: action.completedItems,
      };
    default:
      return state;
  }

}
