import * as React from 'react';
import { Button } from 'reactstrap';
import { IButtonClickable } from './types';

const EditIconButton: React.FC<IButtonClickable> = (props) => {

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {

    props.onClick(event);
  
  };
  return (
    <Button color="info" disabled={props.disabled} onClick={handleClick}><i title="Add" className="fa fa-2x fa-plus" /></Button>
  );

};

export default EditIconButton;
