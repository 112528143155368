import { THSOContact } from '../system/types';
// import createTransitionManager from "history/createTransitionManager";
export const ORDER_SET_DATA = 'ORDER_SET_DATA';
export const ORDER_RESET_DATA = 'ORDER_RESET_DATA';
export const ORDER_TOGGLE_LOADING = 'ORDER_TOGGLE_LOADING';
export const ORDER_TOGGLE_LOADING_ACTIVITIES = 'ORDER_TOGGLE_LOADING_ACTIVITIES';
export const ORDER_SET_ORDER_ACTIVITIES = 'ORDER_SET_ORDER_ACTIVITIES';
export const ORDER_SET_ORDER_ACTIVITIES_TOGGLE_STATE = 'ORDER_SET_ORDER_ACTIVITIES_TOGGLE_STATE';
export const ORDER_TOGGLE_ACTIVITY = 'ORDER_TOGGLE_ACTIVITY';
export const ORDER_SET_ACTIVITY_CONTENT = 'ORDER_SET_ACTIVITY_CONTENT';
export const ORDER_TOGGLE_LOADING_ACTIVITY_CONTENT = 'ORDER_TOGGLE_LOADING_ACTIVITY_CONTENT';

export interface IOrderState {
  loading: boolean;
  loadingActivities: boolean;
  data: TOrder;
  activities: TOrderActivity[];
}
export type TOrder = {
  orderNumber: string;
  name: string;
  status: string;
  createdOn: string;
  modifiedOn: string;
  orderType: string;
  projectManager: THSOContact;
  lineItems: TLineItem[];
};
export type TLineItem = {
  product: TProduct;
  status: string;
  createdOn: string;
};
export type TProduct = {
  name: string;
  id: string;
  productCode: string;
  billingCycle: number;
};
export type TOrderActivity = {
  id: string;
  type: string;
  createdOn: Record<string, any>;
  modifiedOn: Record<string, any>;
  subject: string;
  status: string;
  content: string;
  createdBy: string;
  emailAddresses: IEmailAddresses;
  showFullMessage?: boolean;
  loadingContent?: boolean;
};
export type TEmailAddress = string;
export interface IResetOrderDataAction {
  type: typeof ORDER_RESET_DATA;
}
export interface IEmailAddresses {
  to: TEmailAddress;
  from: any; // TEmailAddress[]
}
export interface ISetOrderDataAction {
  type: typeof ORDER_SET_DATA;
  data: object;
}
export interface IToggleOrderLoading {
  type: typeof ORDER_TOGGLE_LOADING;
}
export interface IToggleOrderActivitiesLoadingAction {
  type: typeof ORDER_TOGGLE_LOADING_ACTIVITIES;
}
export interface ISetOrderActivitiesAction {
  type: typeof ORDER_SET_ORDER_ACTIVITIES;
  activities: [];
}
export interface ISetActivityState {
  type: typeof ORDER_SET_ORDER_ACTIVITIES_TOGGLE_STATE;
}
export interface IToggleActivityState {
  type: typeof ORDER_TOGGLE_ACTIVITY;
  id: string;
}
export interface IToggleActivityContentLoading {
  type: typeof ORDER_TOGGLE_LOADING_ACTIVITY_CONTENT;
  id: string;
}
export interface ISetOrderActivityContent {
  type: typeof ORDER_SET_ACTIVITY_CONTENT;
  id: string;
  data: string;
}
export type OrderActionTypes =
    IToggleActivityState |
    ISetActivityState |
    ISetOrderDataAction |
    IToggleOrderLoading |
    IToggleOrderActivitiesLoadingAction |
    ISetOrderActivitiesAction |
    IResetOrderDataAction |
    ISetOrderActivityContent |
    IToggleActivityContentLoading;
