import {
  SERVICES_SET_SEARCH_STRING,
  SERVICES_SET_SERVICE_GROUPS,
  SERVICES_SET_SERVICES,
  SERVICES_TOGGLE_LOADING,
  SERVICES_RESET_STORE,
  SERVICES_TOGGLE_FILTER_DROPDOWN_OPEN,
  SERVICES_SET_FILTER_STATE,
  IToggleServicesFilterDropdownAction,
  IResetServicesStore,
  ISetServicesFilterAction,
  ISetCustomerServiceGroupsAction,
  SERVICES_SET_CUSTOMER_SERVICE_GROUPS,
} from './types';
import { api_getAccountServices, api_getServiceGroups } from '../../utils/Services/Services';
import { defaultErrorFeedback, errorFeedback } from '../../actions/feedback';
import { setSystemError } from '../system/actions';

export const toggleServicesFilterDropdown = (): IToggleServicesFilterDropdownAction => ({ type: SERVICES_TOGGLE_FILTER_DROPDOWN_OPEN });
export const resetServicesStore = (): IResetServicesStore => ({ type: SERVICES_RESET_STORE });
export const setServicesFilterAction = (group) => ({ type: SERVICES_SET_FILTER_STATE, group });
export const getCustomerServiceGroups = (): ISetCustomerServiceGroupsAction => ({ type: SERVICES_SET_CUSTOMER_SERVICE_GROUPS });
function setAccountServices(services) {

  return {
    type: SERVICES_SET_SERVICES,
    services,
  };

}
function setServiceGroups(serviceGroups) {

  return {
    type: SERVICES_SET_SERVICE_GROUPS,
    serviceGroups,
  };

}
export function toggleServicesLoading() {

  return {
    type: SERVICES_TOGGLE_LOADING,
  };

}
export function setServicesSearchString(search: string) {

  return {
    type: SERVICES_SET_SEARCH_STRING,
    search,
  };

}
export function getAccountServices() {

  return (dispatch) => api_getAccountServices().then((result) => {

    if (result.status === 200) {

      dispatch(setAccountServices(result.data));
    
    } else if (result.data.error && result.data.error.id) {

      dispatch(setSystemError(result.data.error.id));
    
    } else {

      dispatch(defaultErrorFeedback());
    
    }
  
  });

}
export function getServiceGroups() {

  return (dispatch) => api_getServiceGroups().then((result) => {

    if (result.status === 200) {

      dispatch(setServiceGroups(result.data));
      return true;
    
    }

    dispatch(errorFeedback('Could not fetch service groups'));
    return false;
  
  });

}
