import {
  DOMAIN_ADD_GLUE_RECORD,
  DOMAIN_DELETE_GLUE_RECORD,
  DOMAIN_RESET,
  DOMAIN_RESTORE_CONTACT,
  DOMAIN_RESTORE_INFO,
  DOMAIN_RESTORE_NAMESERVERS,
  DOMAIN_SET_ACTIVE_TAB,
  DOMAIN_SET_DOMAIN_DNSSEC,
  DOMAIN_SET_DOMAIN_CONTACT,
  DOMAIN_SET_DOMAIN_CONTACT_FIELD,
  DOMAIN_SET_DOMAIN_ERRORS,
  DOMAIN_SET_DOMAIN_GLUE_RECORDS,
  DOMAIN_SET_DOMAIN_INFO,
  DOMAIN_SET_DOMAIN_LOADING_TEXT,
  DOMAIN_SET_DOMAIN_NAMESERVERS,
  DOMAIN_SET_DOMAIN_TLD,
  DOMAIN_SET_NEW_GLUE_RECORD_ERRORS,
  DOMAIN_SET_RELEASE_SUCCESSFUL,
  DOMAIN_SET_RELEASE_TAG,
  DOMAIN_SET_RENEW_TOTAL_PRICE,
  DOMAIN_SET_RENEW_YEARS,
  DOMAIN_SHOW_ERROR_ALERT,
  DOMAIN_TOGGLE_AUTO_RENEW,
  DOMAIN_TOGGLE_LOADING,
  DOMAIN_TOGGLE_NEW_GLUE_RECORD_LOADING,
  DOMAIN_TOGGLE_SHOW_NEW_GLUE_RECORD,
  DOMAIN_UPDATE_NAMESERVER_FIELD,
  DOMAIN_UPDATE_NEW_GLUE_RECORD_FIELD,
  DOMAIN_SET_DOMAIN_DNSSEC_FORM_FIELD,
  DOMAIN_ADD_DOMAIN_DNSSEC,
  DOMAIN_REMOVE_DOMAIN_DNSSEC,
  DOMAIN_RESET_DOMAIN_DNSSEC_FORM,
} from '../actions/domain';
import { validateContact } from '../helpers/domains/domains';

const initialState = {
  activeTab: '1',
  info: {},
  infoEdited: {},
  contact: {},
  contactEdited: {},
  nameservers: {},
  nameserversEdited: {},
  glueRecords: [],
  tld: {
    price: {
      renew: 0,
    },
  },
  loading: false,
  loadingText: 'Loading domain info...',
  errors: {},
  alert: {
    show: false,
    text: '',
  },
  newGlueRecord: {
    show: false,
    data: {
      sub: '',
      ipv4: '',
      ipv6: '',
    },
    loading: false,
    errors: {},
  },
  renew: {
    years: 1,
    totalPrice: 0,
  },
  releaseTAG: '',
  releaseSuccess: false,
  dnsSec: [],
  newDNSSec: {
    algorithm: '',
    keyTag: '',
    digestType: '',
    digest: '',
  },
};

export default function domain(state = initialState, action) {

  switch (action.type) {
    case DOMAIN_RESET:
      return {
        ...initialState,
      };
    case DOMAIN_SET_DOMAIN_INFO:
      return {
        ...state,
        info: { ...action.info },
        infoEdited: { ...action.info },
      };
    case DOMAIN_SET_DOMAIN_CONTACT:
      return {
        ...state,
        contact: action.contact,
        contactEdited: action.contact,
      };
    case DOMAIN_SET_DOMAIN_NAMESERVERS:
      return {
        ...state,
        nameservers: action.nameservers,
        nameserversEdited: action.nameservers,
      };
    case DOMAIN_SET_DOMAIN_GLUE_RECORDS:
      return {
        ...state,
        glueRecords: action.glueRecords,
      };
    case DOMAIN_SET_DOMAIN_TLD:
      return {
        ...state,
        tld: action.tld,
      };
    case DOMAIN_TOGGLE_LOADING:
      return {
        ...state,
        loading: !state.loading,
      };
    case DOMAIN_SET_DOMAIN_LOADING_TEXT:
      return {
        ...state,
        loadingText: action.text,
      };
    case DOMAIN_SET_DOMAIN_ERRORS:
      return {
        ...state,
        errors: action.errors,
      };
    case DOMAIN_SHOW_ERROR_ALERT:
      return {
        ...state,
        alert: { show: true, text: action.text },
      };
    case DOMAIN_SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.tab,
      };
    case DOMAIN_SET_DOMAIN_CONTACT_FIELD:
      const errors = validateContact({ ...state.contactEdited, ...action.field });
      return {
        ...state,
        contactEdited: { ...state.contactEdited, ...action.field },
        errors: { ...errors },
      };
    case DOMAIN_UPDATE_NAMESERVER_FIELD:
      const nameservers = { ...state.nameserversEdited };
      nameservers[action.index] = { name: action.value, ipv4: null, ipv6: null, active: false };
      return {
        ...state,
        nameserversEdited: nameservers,
      };
    case DOMAIN_ADD_GLUE_RECORD:
      const records = [...state.glueRecords];
      records.push(action.glueRecord);
      return {
        ...state,
        glueRecords: records,
      };
    case DOMAIN_TOGGLE_SHOW_NEW_GLUE_RECORD:
      return {
        ...state,
        newGlueRecord: { ...state.newGlueRecord, show: !state.newGlueRecord.show },
      };
    case DOMAIN_UPDATE_NEW_GLUE_RECORD_FIELD:
      return {
        ...state,
        newGlueRecord: { ...state.newGlueRecord, data: { ...state.newGlueRecord.data, ...action.field } },
      };
    case DOMAIN_SET_NEW_GLUE_RECORD_ERRORS:
      return {
        ...state,
        newGlueRecord: { ...state.newGlueRecord, errors: action.errors },
      };
    case DOMAIN_TOGGLE_NEW_GLUE_RECORD_LOADING:
      return {
        ...state,
        newGlueRecord: { ...state.newGlueRecord, loading: !state.newGlueRecord.loading },
      };
    case DOMAIN_DELETE_GLUE_RECORD:
      return {
        ...state,
        glueRecords: state.glueRecords.filter((record, index) => index !== action.index),
      };
    case DOMAIN_SET_RELEASE_TAG:
      return {
        ...state,
        releaseTAG: action.tag,
      };
    case DOMAIN_SET_RELEASE_SUCCESSFUL:
      return {
        ...state,
        releaseSuccess: true,
      };

    case DOMAIN_SET_RENEW_YEARS:
      return {
        ...state,
        renew: { ...state.renew, years: action.years },
      };
    case DOMAIN_SET_RENEW_TOTAL_PRICE:
      return {
        ...state,
        renew: { ...state.renew, totalPrice: action.price },
      };
    case DOMAIN_TOGGLE_AUTO_RENEW:
      return {
        ...state,
        infoEdited: { ...state.infoEdited, autoRenew: !state.infoEdited.autoRenew },
      };
    case DOMAIN_RESTORE_INFO: {

      return {
        ...state,
        infoEdited: state.info,
      };

    }
    case DOMAIN_RESTORE_CONTACT: {

      return {
        ...state,
        contactEdited: state.contact,
      };

    }
    case DOMAIN_RESTORE_NAMESERVERS: {

      return {
        ...state,
        nameserversEdited: state.nameservers,
      };

    }

    case DOMAIN_SET_DOMAIN_DNSSEC: {

      return {
        ...state,
        dnsSec: action.data,
      };

    }

    case DOMAIN_SET_DOMAIN_DNSSEC_FORM_FIELD:
      return {
        ...state,
        newDNSSec: { ...state.newDNSSec, [action.id]: action.value },
      };

    case DOMAIN_RESET_DOMAIN_DNSSEC_FORM:

      return {
        ...state,
        newDNSSec: initialState.newDNSSec,
      };

    case DOMAIN_ADD_DOMAIN_DNSSEC:

      return {
        ...state,
        dnsSec: [...state.dnsSec, action.data],
      };

    case DOMAIN_REMOVE_DOMAIN_DNSSEC:

      return {
        ...state,
        dnsSec: state.dnsSec.filter((value) => value !== action.data),
      };

    default:
      return state;
  }

}
