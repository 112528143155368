import { FunctionComponent } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import * as React from 'react';
import { IFormError } from '../../Forms/types';

type Props = {
  label: string;
  id: string;
  name: string | number;
  checked: boolean;
  readOnly: boolean;
  horizontal: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => any;
  error?: IFormError;
};

const CheckBox: FunctionComponent<Props> = (props) => {

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    props.onChange ? props.onChange(event) : {};
  
  };

  return (
    <FormGroup className="row">
      <Label
        className="col-md-4 text-light font-weight-bold"
        for={props.label}
        style={{ fontSize: '16px' }}
      >
        {props.label}
      </Label>
      <div className="col-md-8">
        {!props.readOnly && props.horizontal ? (
          <label className="text-light homeage-to-the-square-checkbox" key={props.id}>
            <input
              checked={props.checked}
              className="form-check-input"
              type="checkbox"
              id={props.id}
              onChange={handleChange}
            />
            <span className="checkmark" />
            <label className="form-check-label" htmlFor={props.id}>{props.name}</label>
          </label>
        ) : ''}
        {!props.readOnly && !props.horizontal ? (
          <label className="form-check text-light homeage-to-the-square-checkbox" key={props.id}>
            <Input
              checked={props.checked}
              className={`form-check-input${props.error ? ' is-invalid' : ''}`}
              type="checkbox"
              id={props.id}
              onChange={handleChange}
            />
            <span className="checkmark" />
            <label className="form-check-label" htmlFor={props.id}>{props.name}</label>
          </label>
        ) : ''}
        {props.readOnly && props.horizontal ? (
          <div className="d-flex flex-row justify-content-start flex-wrap">
            {props.readOnly && props.checked
              ? (
                <div
                  key={props.label}
                  className="form-control border border-info pl-3 pr-3 pt-2 text-info mr-2 mb-2"
                  style={{ borderRadius: '5px', width: 'auto', whiteSpace: 'nowrap' }}
                >
                  {props.name}
                </div>
              )
              : ''}
          </div>
        ) : ''}
        {props.readOnly && !props.horizontal ? (
          <div className="d-flex flex-column">
            {props.readOnly && props.checked ? (
              <p style={{ fontSize: '18px' }} key={props.label} className="form-control border-0 text-light m-0 pl-0">{props.name}</p>
            ) : ''}
          </div>
        ) : ''}
        {props.error ? (
          <div className="text-danger">
            {props.error.message}
          </div>
        ) : ''}
      </div>
    </FormGroup>
  );

};

export default CheckBox;
