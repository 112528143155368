import {
  CARD_PAYMENT_SET_BILL, CARD_PAYMENT_SET_BILL_PAID,
  CARD_PAYMENT_SET_CARD_ERRORS, CARD_PAYMENT_SET_CARD_REDIRECT, CARD_PAYMENT_SET_ERROR, CARD_PAYMENT_TOGGLE_LOADING,
  CARD_PAYMENT_TOGGLE_PROCESSING, CARD_PAYMENT_UPDATE_CARD_ADDRESS,
  CARD_PAYMENT_UPDATE_CARD_DETAILS, TBrowserDetails, TCardAddress, TCardDetails,
  TCardErrors, TCardPaymentRedirect,
} from './types';
import { api_getBill } from '../../utils/Billing';
import { defaultErrorFeedback } from '../../actions/feedback';
import { TBill } from '../billing/types';
import { api_cardPayForInvoice } from '../../utils/CardPay';

export function toggleCardPaymentProcessing() {

  return {
    type: CARD_PAYMENT_TOGGLE_PROCESSING,
  };

}

export function toggleCardPaymentLoading() {

  return {
    type: CARD_PAYMENT_TOGGLE_LOADING,
  };

}

export function updateCardDetails(field: string, value: string | boolean) {

  return {
    type: CARD_PAYMENT_UPDATE_CARD_DETAILS,
    field,
    value,
  };

}

export function updateCardAddress(field: string, value: string) {

  return {
    type: CARD_PAYMENT_UPDATE_CARD_ADDRESS,
    field,
    value,
  };

}

export function setCardErrors(errors: TCardErrors) {

  return {
    type: CARD_PAYMENT_SET_CARD_ERRORS,
    errors,
  };

}

export function setCardPaymentError(error: string) {

  return {
    type: CARD_PAYMENT_SET_ERROR,
    error,
  };

}

export function setBillToPaid() {

  return {
    type: CARD_PAYMENT_SET_BILL_PAID,
    date: new Date(),
  };

}

export function setCardPaymentRedirect(redirect: TCardPaymentRedirect) {

  return {
    type: CARD_PAYMENT_SET_CARD_REDIRECT,
    redirect,

  };

}

export function getCardPaymentBill(id) {

  return (dispatch) => api_getBill(id).then((result) => {

    if (result.status === 200) {

      dispatch({ type: CARD_PAYMENT_SET_BILL, bill: result.data });
    
    } else if (result.status === 404) {

      dispatch(setCardPaymentError('Invoice not found'));
    
    } else {

      dispatch(defaultErrorFeedback());
    
    }
  
  });

}

export function processCardPayment(bill: TBill, cardDetails: TCardDetails, cardAddress: TCardAddress, browser: TBrowserDetails) {

  return (dispatch) => api_cardPayForInvoice(cardDetails, cardAddress, browser, bill.toPay, bill.id).then((result) => {

    if (result.status === 200) {

      return result.data;
    
    }
    if (result.status === 400 && result.data.error) {

      dispatch(setCardPaymentError(result.data.error));
      return false;
    
    }
    dispatch(setCardPaymentError('Payment failed.'));
    return false;
  
  });

}
