import {
  WEATHERMAPS_SET_WEATHERMAPS,
  IWeatherMapsSetWeatherMapsAction,
  TWeatherMapsSetWeatherMaps,
  TWeatherMapsList,
  TWeatherMapsGetWeatherMaps,
} from './types';
import { api_listWeatherMaps } from '../../utils/WeatherMaps/WeatherMaps';
import { defaultErrorFeedback } from '../../actions/feedback';

export const setWeatherMaps: TWeatherMapsSetWeatherMaps = (data: TWeatherMapsList): IWeatherMapsSetWeatherMapsAction => ({

  type: WEATHERMAPS_SET_WEATHERMAPS,
  data,

});

export const getWeatherMaps: TWeatherMapsGetWeatherMaps = () => (dispatch) => api_listWeatherMaps()
  .then((result) => {

    if (result.status === 200) {

      dispatch(setWeatherMaps(result.data));
      return true;

    }
    dispatch(defaultErrorFeedback());
    return false;

  });
