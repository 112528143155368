import { DashboardFavouriteUplink } from '../dashboard/types';
import { TMonitoredItem, IMonitoredDeviceRevertDesc, IMonitoredItemDescSave } from '../monitoredItem/types';

export const MONITORING_SET_MONITORED_ITEMS = 'MONITORING_SET_MONITORED_ITEMS';
export const MONITORING_SET_SEARCH_STRINGS = 'MONITORING_SET_SEARCH_STRINGS';
export const MONITORING_TOGGLE_LOADING = 'MONITORING_TOGGLE_LOADING';
export const MONITORING_RESET_STORE = 'MONITORING_RESET_STORE';
export const MONITORING_TOGGLE_STATUS_LOADING = 'MONITORING_TOGGLE_STATUS_LOADING';
export const MONITORING_SET_MONITORED_ITEM_STATUSES = 'DASHBOARD_SET_MONITORED_ITEMS_DELETE_LOADING_STATUSES';
export interface IMonitoringResetStore {
  type: typeof MONITORING_RESET_STORE;
}
export interface IMonitoringState {
  loading: boolean;
  loadingStatuses: boolean;
  searchString: string;
  items: TMonitoredItem[];
  filtered: TMonitoredItem[];
}
export type MonitoredDeviceWidget = {
  latency: string | number;
  uplinkError? : boolean;
  uplink? : DashboardFavouriteUplink;
  id: string;
  description: string;
  location: string;
  name: string;
  cpu: string;
  link: string;
  favouriteActive: boolean;
  type: string;
  available: boolean;
  error?: string;
  loading: boolean;
  hidden: boolean;
};
export type MonitoredPortWidget = {
  latency?: string | number;
  cpu?: string;
  uplinkError? : boolean;
  uplink? : DashboardFavouriteUplink;
  id: string;
  description: string;
  location: string;
  name: string;
  link: string;
  favouriteActive: boolean;
  type: string;
  inBound: string;
  outBound: string;
  available: boolean;
  error?: string;
  loading: boolean;
  hidden: boolean;
};
export type TTrafficData = {
  label: string;
  data: number[][];
};
interface ISetMonitoredItemsAction {
  type: typeof MONITORING_SET_MONITORED_ITEMS;
  items: TMonitoredItem[];
}
interface ISetMonitoredItemStatusesAction {
  type: typeof MONITORING_SET_MONITORED_ITEM_STATUSES;
  items: any;
}
interface IToggleMonitoredItemsLoadingAction {
  type: typeof MONITORING_TOGGLE_LOADING;
}
interface IToggleMonitoredItemsStatusLoadingAction {
  type: typeof MONITORING_TOGGLE_STATUS_LOADING;
}
interface ISetMonitoringSearchStringAction {
  type: typeof MONITORING_SET_SEARCH_STRINGS;
  search: string;
}

export type MonitoringActionTypes = ISetMonitoredItemsAction | IToggleMonitoredItemsLoadingAction |
ISetMonitoringSearchStringAction | IMonitoringResetStore | IToggleMonitoredItemsStatusLoadingAction |
ISetMonitoredItemStatusesAction | IMonitoredItemDescSave | IMonitoredDeviceRevertDesc;
export type MonitoredItemWidgetTypes = MonitoredDeviceWidget | MonitoredPortWidget;
