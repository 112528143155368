import { AxiosResponse } from 'axios';
import {
  IResetNewZoneAction,
  ISetDNSZonesAction,
  ISetDNSZoneSearchAction,
  IToggleDNSNewZoneModalAction,
  IToggleDNSZonesLoadingAction,
  IUpdateDNSZoneDataAction,
  SERVICE_DNS_ADD_ZONE,
  SERVICE_DNS_RESET_NEW_ZONE,
  SERVICE_DNS_SET_SEARCH_STRING,
  SERVICE_DNS_SET_ZONES,
  SERVICE_DNS_TOGGLE_LOADING,
  SERVICE_DNS_TOGGLE_NEW_ZONE_MODAL,
  SERVICE_DNS_UPDATE_ZONE_DATA,
  TDNSZone,
} from './types';
import { errorFeedback } from '../../actions/feedback';
import { api_createNewZone, api_getZones } from '../../utils/DNS/DNS';
import { setSystemError } from '../system/actions';

function setDNSZones(zones: TDNSZone[]): ISetDNSZonesAction {

  return {
    type: SERVICE_DNS_SET_ZONES,
    zones,
  };

}
function addDNSZone(data) {

  return {
    type: SERVICE_DNS_ADD_ZONE,
    zone: data,
  };

}
export function setDNSZoneSearchString(search: string): ISetDNSZoneSearchAction {

  return {
    type: SERVICE_DNS_SET_SEARCH_STRING,
    search,
  };

}
export function toggleDNSZonesLoading(): IToggleDNSZonesLoadingAction {

  return {
    type: SERVICE_DNS_TOGGLE_LOADING,
  };

}
export function toggleDNSNewZoneModal(): IToggleDNSNewZoneModalAction {

  return {
    type: SERVICE_DNS_TOGGLE_NEW_ZONE_MODAL,
  };

}
export function updateDNSZoneData(data): IUpdateDNSZoneDataAction {

  return {
    type: SERVICE_DNS_UPDATE_ZONE_DATA,
    data,
  };

}
export function resetDSNNewZone(): IResetNewZoneAction {

  return {
    type: SERVICE_DNS_RESET_NEW_ZONE,
  };

}
export function getAccountDNSZones() {

  return (dispatch) => api_getZones().then((result: AxiosResponse) => {

    if (result.status === 200) {

      dispatch(setDNSZones(result.data));
    
    } else if (result.data.error && result.data.error.id) {

      dispatch(setSystemError(result.data.error.id));
    
    }
  
  });

}
export function createDNSZone(zone: TDNSZone) {

  return (dispatch) => api_createNewZone(zone.name, zone.type).then((result: AxiosResponse) => {

    if (result.status === 200) {

      dispatch(addDNSZone(result.data));
      return true;
    
    }
    if (result.status === 422) {

      const error = result.data.name ? result.data.name : result.data.type;
      dispatch(errorFeedback(`Could not create new zone: ${error}`));
      return false;
    
    }

    dispatch(errorFeedback(`Could not create new zone: ${zone.name}`));
    return false;
  
  });

}
