import React, { FunctionComponent } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { TCardAddress, TCardDetails, TCardErrors } from '../../../store/cardPayment/types';
import SelectInput from '../../Inputs/SelectInput';
import TextInput from '../../Inputs/TextInput';
import { getCreditCards, getMonthOptions, getYearOptions } from '../../../helpers/creditcards';
import CheckBoxList from '../../Inputs/CheckBoxList';
import Section from '../../Section';
import CountrySelect from '../../Inputs/CountrySelect';
import RegionSelect from '../../Inputs/RegionSelect';

type CardPayment = {
  cardDetails: TCardDetails;
  cardAddress: TCardAddress;
  handleInput: (field: string, value: string | boolean) => any;
  handleAddressInput: (field: string, value: string) => any;
  handlePay: () => any;
  errors: TCardErrors;
  disabled: boolean;
};

const CardPayment: FunctionComponent<CardPayment> = ({
  cardDetails,
  cardAddress,
  handleInput,
  handleAddressInput,
  handlePay,
  errors,
  disabled,
}) => {

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    if (event.target.id === 'cardType' && event.target.value === 'maestro') {

      handleInput('cardAutoCharge', false);
    
    }
    const value = event.target.id === 'cardAutoCharge' ? event.target.checked : event.target.value;
    handleInput(event.target.id, value);
  
  };

  const handleAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    handleAddressInput(event.target.id, event.target.value);
  
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {

    handlePay();
  
  };
  return (
    <>
      <SelectInput
        label="Card Type"
        id="cardType"
        options={getCreditCards()}
        value={cardDetails.cardType || '0'}
        onChange={handleChange}
      />
      <TextInput
        label="Card Number"
        id="cardNumber"
        value={cardDetails.cardNumber || ''}
        readOnly={false}
        onChange={handleChange}
        error={errors.cardNumber ? { id: 'cardNumber', message: 'Invalid card number' } : undefined}
      />
      <TextInput
        label="Card Holder"
        id="cardHolder"
        value={cardDetails.cardHolder || ''}
        readOnly={false}
        onChange={handleChange}
        error={errors.cardHolder ? { id: 'cardHolder', message: 'Invalid name' } : undefined}
      />

      <SelectInput
        label="Expiry Month"
        id="cardExpirationMonth"
        options={getMonthOptions()}
        value={cardDetails.cardExpirationMonth || ''}
        onChange={handleChange}
        error={errors.cardExpirationDate ? { id: 'cardExpirationMonth', message: 'Invalid expiry date' } : undefined}
      />
      <SelectInput
        label="Expiry Year"
        id="cardExpirationYear"
        options={getYearOptions()}
        value={cardDetails.cardExpirationYear || ''}
        onChange={handleChange}
        error={errors.cardExpirationDate ? { id: 'cardExpirationYear', message: 'Invalid expiry date' } : undefined}
      />
      <SelectInput
        label="Issue Month"
        id="cardIssueMonth"
        options={getMonthOptions()}
        value={cardDetails.cardIssueMonth || ''}
        onChange={handleChange}
        error={errors.cardIssueDate ? { id: 'cardExpirationMonth', message: 'Invalid issue date' } : undefined}
      />
      <SelectInput
        label="Issue Year"
        id="cardIssueYear"
        options={getYearOptions(true)}
        value={cardDetails.cardIssueYear || ''}
        onChange={handleChange}
        error={errors.cardIssueDate ? { id: 'cardExpirationMonth', message: 'Invalid issue date' } : undefined}
      />
      <TextInput
        label="CVV/CV2"
        id="cardCVC"
        value={cardDetails.cardCVC || ''}
        readOnly={false}
        onChange={handleChange}
        error={errors.cardCVC ? { id: 'cardCVC', message: 'Invalid CVC/CV2' } : undefined}
      />
      <TextInput
        label="Issue Number"
        id="cardIssueNumber"
        value={cardDetails.cardIssueNumber || ''}
        readOnly={false}
        onChange={handleChange}
      />
      {cardDetails.cardType !== 'maestro' ? (
        <CheckBoxList
          label="Enable auto charge"
          options={[{ id: 'cardAutoCharge', name: '', checked: cardDetails.cardAutoCharge }]}
          readOnly={false}
          onChange={handleChange}
          horizontal
        />
      ) : ''}
      <Section title="Billing Address">
        <TextInput
          label="Address Line 1"
          id="addressLine1"
          value={cardAddress.addressLine1 || ''}
          readOnly={false}
          onChange={handleAddressChange}
          error={errors.addressLine1 ? { id: 'addressLine1', message: 'Invalid address' } : undefined}
        />
        <TextInput
          label="Address Line 2"
          id="addressLine2"
          value={cardAddress.addressLine2 || ''}
          readOnly={false}
          onChange={handleAddressChange}
        />
        <TextInput
          label="City"
          id="city"
          value={cardAddress.city || ''}
          readOnly={false}
          onChange={handleAddressChange}
          error={errors.city ? { id: 'city', message: 'Invalid city' } : undefined}
        />
        <TextInput
          label="Postcode"
          id="postcode"
          value={cardAddress.postcode || ''}
          readOnly={false}
          onChange={handleAddressChange}
          error={errors.postcode ? { id: 'postcode', message: 'Invalid postcode' } : undefined}
        />
        {cardAddress.countryCode === 'US' ? (
          <RegionSelect
            onChange={(value: string) => handleAddressInput('stateCode', value)}
            value={cardAddress.stateCode}
            country={cardAddress.countryCode}
          />
        ) : ''}
        <CountrySelect onChange={(value: string) => handleAddressInput('countryCode', value)} value={cardAddress.countryCode} />
      </Section>
      <Row className="mt-4">
        <Col className="d-flex justify-content-end">
          <Button disabled={disabled} color="secondary" onClick={handleClick}>Pay</Button>
        </Col>
      </Row>
    </>
  );

};

export default CardPayment;
