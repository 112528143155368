import React from 'react';

interface IProps {
  cssClass?: string;
  cssClassTop?: string;
  title?: string;
}
const Hamburger: React.FC<IProps> = ({ cssClassTop, cssClass, title }) => (
  <svg
    className={cssClassTop || ''}
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 91 113.75"
    enableBackground="new 0 0 91 91"
  >
    <title>{title || ''}</title>
    <g>
      <rect
        className={cssClass || 'cls-1'}
        x="28.023"
        y="30.468"
        fill="#000000"
        width="36.602"
        height="5.1"
      />
      <rect
        className={cssClass || 'cls-1'}
        x="28.023"
        y="44.067"
        fill="#000000"
        width="36.602"
        height="5.102"
      />
      <rect
        className={cssClass || 'cls-1'}
        x="28.023"
        y="57.669"
        fill="#000000"
        width="36.602"
        height="5.1"
      />
    </g>
  </svg>
);

export default Hamburger;
