/* eslint-disable */
import React, {Component} from 'react';
import {
  FormGroup,
  Label,
  Row,
  Col,
  Input,
  InputGroup,
  Button,
} from 'reactstrap';
import {connect} from 'react-redux';
import {
  createGlueRecord,
  setDomainNewGlueRecordErrors,
  toggleNewGlueRecordLoading,
  toggleShowNewGlueRecord,
  updateNewGlueRecordField
} from '../../../actions/domain';
import {validateGlueRecord} from '../../../helpers/domains/domains';


class NewGlueRecord extends Component{

  handleInput = (event) =>{

    this.props.dispatch(updateNewGlueRecordField(event.target.id, event.target.value));
    
  };

  createRecord = () => {

    this.props.dispatch(toggleNewGlueRecordLoading());
    const {newGlueRecord, info} = this.props.domain;
    const errors = validateGlueRecord(newGlueRecord.data);
    this.props.dispatch(setDomainNewGlueRecordErrors(errors));
    if(Object.keys(errors).length === 0){

      newGlueRecord.data.name = `${newGlueRecord.data.sub  }.${  info.name}`;
      if(!newGlueRecord.data.ipv4){

        delete newGlueRecord.data.ipv4;
            
      }
      if(!newGlueRecord.data.ipv6){

        delete newGlueRecord.data.ipv6;
            
      }
      this.props.dispatch(createGlueRecord(info.id, newGlueRecord.data)).then(
        (result) =>{

          if(result){

            this.props.dispatch(toggleShowNewGlueRecord());
                    
          }
          this.props.dispatch(toggleNewGlueRecordLoading());
                
        }
      );
        
    } else{

      this.props.dispatch(toggleNewGlueRecordLoading());
        
    }

  };

  render(){

    const {newGlueRecord, info} = this.props.domain;
    return (
            <>
                <FormGroup row>
                    <Col md="3">
                        <Label className="text-secondary col-form-label"><strong>Sub</strong></Label>
                    </Col>
                    <Col md="9">
                        <InputGroup>
                            <Input className={`form-control ${  newGlueRecord.errors.sub ?  'is-invalid' : ''}`} type="text" id="sub" value={newGlueRecord.data.sub} onChange={this.handleInput}/>
                            <div className="input-group-append">
                                <Button type="button" color="primary" style={{cursor: 'auto'}} disabled>.{info.name}</Button>
                            </div>
                        </InputGroup>
                        {newGlueRecord.errors.sub &&
                        <div className="text-danger mt-1" style={{fontSize: '80%'}}>
                            {newGlueRecord.errors.sub}
                        </div>
                        }
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col md="3">
                        <Label className="text-secondary col-form-label"><strong>IPv4</strong></Label>
                    </Col>
                    <Col md="9">
                        <Input className={`form-control ${  newGlueRecord.errors.ipv4 ?  'is-invalid' : ''}`} type="text" id="ipv4" value={newGlueRecord.data.ipv4} onChange={this.handleInput}/>
                        {newGlueRecord.errors.ipv4 &&
                        <div className="invalid-feedback">
                            {newGlueRecord.errors.ipv4}
                        </div>
                        }
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col md="3">
                        <Label className="text-secondary col-form-label"><strong>IPv6</strong></Label>
                    </Col>
                    <Col md="9">
                        <Input className={`form-control ${  newGlueRecord.errors.ipv6 ?  'is-invalid' : ''}`} type="text" id="ipv6" value={newGlueRecord.data.ipv6} onChange={this.handleInput}/>
                        {newGlueRecord.errors.ipv6 &&
                        <div className="invalid-feedback">
                            {newGlueRecord.errors.ipv6}
                        </div>
                        }
                    </Col>
                </FormGroup>
                <Row>
                    <Col className="d-flex flex-row-reverse">
                        <Button color="secondary" onClick={this.createRecord}>Create</Button>
                    </Col>
                </Row>
            </>
    );
    
  }
}

function mapStateToProps ({domain }) {

  return {
    domain
  };

}

export default connect(mapStateToProps)(NewGlueRecord);