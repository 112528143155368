import { TContact, TContactType } from '../contact/types';

export const CONTACTS_SET_CONTACTS = 'CONTACTS_SET_CONTACTS';
export const CONTACTS_SET_SEARCH_STRING = 'CONTACTS_SET_SEARCH_STRING';
export const CONTACTS_TOGGLE_LOADING = 'CONTACTS_TOGGLE_LOADING';
export const CONTACTS_RESET_STORE = 'CONTACTS_RESET_STORE';
export const CONTACTS_REMOVE_CONTACT = 'CONTACTS_REMOVE_CONTACT';
export const CONTACTS_NEW_CONTACT = 'CONTACTS_NEW_CONTACT';
export const CONTACTS_UPDATE_CONTACT = 'CONTACTS_UPDATE_CONTACT';
export const CONTACTS_POPULATE_CONTACT_TYPES = ' CONTACTS_POPULATE_CONTACT_TYPES';
export interface IContactsResetState {
  type: typeof CONTACTS_RESET_STORE;
}
export interface IContactsState {
  loading: boolean;
  searchString: string;
  filtered: TContact[];
  contacts: TContact[];
  successMessage: string | null;
}
export interface ISetContactsAction {
  type: typeof CONTACTS_SET_CONTACTS;
  contacts: TContact[];
}
export interface IToggleContactsLoadingAction {
  type: typeof CONTACTS_TOGGLE_LOADING;
}
export interface ISetContactsSearchAction {
  type: typeof CONTACTS_SET_SEARCH_STRING;
  search: string;
}
export interface IRemoveContactAction {
  type: typeof CONTACTS_REMOVE_CONTACT;
  contact: TContact;
}
export interface INewContactAction {
  type: typeof CONTACTS_NEW_CONTACT;
  data: TContact;
}

export interface IUpdateContactAction {
  type: typeof CONTACTS_UPDATE_CONTACT;
  data: TContact;
}

export interface IPopulateContactTypesAction {
  type: typeof CONTACTS_POPULATE_CONTACT_TYPES;
  contactTypes: Array<TContactType>;
}
export type ContactsActionTypes =
    ISetContactsAction |
    ISetContactsSearchAction |
    IToggleContactsLoadingAction |
    IContactsResetState |
    IRemoveContactAction |
    INewContactAction |
    IPopulateContactTypesAction |
    IUpdateContactAction;
