import { Moment } from 'moment';
import { TTrafficData } from '../monitoring/types';
import { api_getConnectivityService, api_getConnectivityServiceGraph } from '../../utils/Services/Services';
import { setSystemError } from '../system/actions';
import { defaultErrorFeedback } from '../../actions/feedback';
import {
  IReSetServiceConnectivityDataAction,
  ISetServiceConnectivityDataAction,
  ISetServiceConnectivityGraphDataAction,
  IToggleServiceConnectivityGraphDataLoadingAction,
  IToggleServiceConnectivityLoadingAction,
  SERVICE_CONNECTIVITY_RESET_DATA,
  SERVICE_CONNECTIVITY_SET_DATA,
  SERVICE_CONNECTIVITY_SET_GRAPH_DATA,
  SERVICE_CONNECTIVITY_SET_GRAPH_DATES,
  SERVICE_CONNECTIVITY_TOGGLE_GRAPH_DATA_LOADING,
  SERVICE_CONNECTIVITY_TOGGLE_LOADING,
} from './types';

function setServiceConnectivityData(data): ISetServiceConnectivityDataAction {

  return {
    type: SERVICE_CONNECTIVITY_SET_DATA,
    data,
  };

}
function setServiceConnectivityPortData(graphId: string, dataIn: TTrafficData, dataOut: TTrafficData): ISetServiceConnectivityGraphDataAction {

  return {
    type: SERVICE_CONNECTIVITY_SET_GRAPH_DATA,
    graphId,
    dataIn,
    dataOut,
  };

}
export function toggleServiceConnectivityLoading(): IToggleServiceConnectivityLoadingAction {

  return {
    type: SERVICE_CONNECTIVITY_TOGGLE_LOADING,
  };

}
export function toggleServiceConnectivityGraphLoading(graphId: string): IToggleServiceConnectivityGraphDataLoadingAction {

  return {
    type: SERVICE_CONNECTIVITY_TOGGLE_GRAPH_DATA_LOADING,
    graphId,
  };

}
export function setServiceConnectivityGraphDates(graphId: string, startDate: Moment, endDate: Moment) {

  return {
    type: SERVICE_CONNECTIVITY_SET_GRAPH_DATES,
    graphId,
    startDate,
    endDate,
  };

}

export function resetConnectivityService(): IReSetServiceConnectivityDataAction {

  return {
    type: SERVICE_CONNECTIVITY_RESET_DATA,
  };

}
export function getServiceConnectivityData(id, withBilling = false) {

  return (dispatch) => api_getConnectivityService(id, withBilling).then((result) => {

    if (result.status === 200) {

      dispatch(setServiceConnectivityData(result.data));
      return result;
    
    }
    if (result.data.error && result.data.error.id) {

      dispatch(setSystemError(result.data.error.id));
    
    } else {

      dispatch(defaultErrorFeedback());
    
    }
    return false;
  
  });

}
export function getServiceConnectivityPortGraph(id, graphId, from, to) {

  return (dispatch) => api_getConnectivityServiceGraph(id, graphId, from, to).then((result) => {

    if (result.status === 200) {

      dispatch(setServiceConnectivityPortData(graphId, result.data[0], result.data[1]));
      return true;
    
    }

    dispatch(defaultErrorFeedback());

    return false;
  
  });

}
