import React from 'react';
import { Alert, Col, Row } from 'reactstrap';
import { DateTimePicker } from 'react-widgets';
import Section from '../../components/Section';
import SRXTemplate from './Templates/SRXTemplate';
import CiscoTemplate from './Templates/CiscoTemplate';
import { TMonitoredItem } from '../../store/monitoredItem/types';
import NetscreenTemplate from './Templates/NetscreenTemplate';
import HuaweiTemplate from './Templates/HuaweiTemplate';
import EXTemplate from './Templates/EXTemplate';
import MXTemplate from './Templates/MXTemplate';
import ZyxelTemplate from './Templates/ZyxelTemplate';
import MitelTemplate from './Templates/MitelTemplate';
import LinuxTemplate from './Templates/LinuxTemplate';
import FortinetTemplate from './Templates/FortinetTemplate';
import { IUserState } from '../../store/user/types';
import Windows from './Templates/Windows';
import NetworkToolsPane from './NetworkToolsPane';
import VMware from './Templates/VMware';
import TrafficGraph from '../../components/TrafficGraph';

type TOwnProps = {
  user: IUserState;
  dateHandler: (graphId: string, event: React.ChangeEvent<HTMLInputElement>, picker: DateTimePicker) => any;
  toggleGraphOpen: (id: string) => any;
  item: TMonitoredItem;
  popupGraphHandler: (key: string, multiplier: number, format?: string, event?: React.MouseEvent<HTMLDivElement>) => void;
  getNTPingData: (destination: string, vrf?: string) => Promise<boolean>;
  getNTTraceRouteData: (destination: string, vrf?: string) => Promise<boolean>;
  getNTShowRouteData: (destination: string, protocol: number, vrf?: string) => Promise<boolean>;
  getNTShowRouteDetails: (destination: string, protocol: number, vrf?: string) => Promise<boolean>;
};

const MonitoredDevice: React.FC<TOwnProps> = ({
  item,
  popupGraphHandler,
  dateHandler,
  toggleGraphOpen,
  user,
  getNTPingData,
  getNTTraceRouteData,
  getNTShowRouteData,
  getNTShowRouteDetails,
}) => (
  <>
    <div>
      {!item.available && <Alert color="danger">This device seems to be offline.</Alert>}
      {item.template === 'srx'
          && <SRXTemplate user={user} item={item} popUpHandler={popupGraphHandler} />}
      {' '}
      {/* tested */}
      {item.template === 'cisco'
          && <CiscoTemplate user={user} item={item} popUpHandler={popupGraphHandler} />}
      {' '}
      {/* tested */}
      {item.template === 'netscreen'
          && <NetscreenTemplate user={user} item={item} popUpHandler={popupGraphHandler} />}
      {' '}
      {/* tested */}
      {item.template === 'huawei'
          && <HuaweiTemplate user={user} item={item} popUpHandler={popupGraphHandler} />}
      {' '}
      {/* tested */}
      {item.template === 'ex'
          && <EXTemplate user={user} item={item} popUpHandler={popupGraphHandler} />}
      {' '}
      {/* tested */}
      {item.template === 'mitel' && (
        <MitelTemplate
          user={user}
          item={item}
          popUpHandler={popupGraphHandler}
        />
      )}
      {' '}
      {/* cant test just now  just 4 customers - one device each */}
      {item.template === 'fortinet' && (
        <FortinetTemplate
          user={user}
          item={item}
          popUpHandler={popupGraphHandler}
        />
      )}
      {' '}
      {/* cant test just now  - just one customer - Unit 9 Limited  cf1.331842.uk */}
      {item.template === 'linux' && (
        <LinuxTemplate
          user={user}
          item={item}
          popUpHandler={popupGraphHandler}
        />
      )}
      {' '}
      {/* cant test just now  - just one customer Jaywing plc  - vm1.263089.uk */}
      {item.template === 'mx' && (
        <MXTemplate
          user={user}
          item={item}
          popUpHandler={popupGraphHandler}
        />
      )}
      {' '}
      {/* cant test just now  - all hmrc and hso service partners */}
      {item.template === 'zyxel'
          && <ZyxelTemplate user={user} item={item} popUpHandler={popupGraphHandler} />}
      {' '}
      {/* cant test just now */}
      {item.template === 'vmware'
          && <VMware user={user} item={item} popUpHandler={popupGraphHandler} />}
      {' '}
      {/* tested */}
      {item.template === 'windows'
          && <Windows user={user} item={item} popUpHandler={popupGraphHandler} />}
      {' '}
      {/* cant test just now */}
    </div>

    {item.networkToolsDeviceDetails ? (
      <div className="card p-5 accord" style={{ borderRadius: '10px' }}>
        <NetworkToolsPane
          deviceDetails={item.networkToolsDeviceDetails}
          data={item.networkToolsData}
          getPingData={getNTPingData}
          getTraceRouteData={getNTTraceRouteData}
          getShowRouteData={getNTShowRouteData}
          getShowRouteDetails={getNTShowRouteDetails}
        />
      </div>
    ) : ''}

    {item.graphs.length > 4 && (
    <div className="card p-5 accord" style={{ borderRadius: '10px' }}>
      <Section title="Graphs">
        <Row className="mt-4">
          <Col className="d-flex flex-column">
            {item.graphs.map((graph) => (
              <div className="mt-1" key={graph.name}>
                <Row
                  onClick={() => toggleGraphOpen(graph.id)}
                  className="accord__bar p-3  accord--email-outbound row "
                >
                  <Col md={10}>
                    <h4 className="font-weight-bold">
                      {graph.name}
                    </h4>
                  </Col>
                  <Col className="d-flex align-items-center text-light" md="2">
                    {graph.isOpen && (<i className="fa fa-minus text-light ml-auto" />)}
                    {!graph.isOpen && (<i className="fa fa-plus text-light  ml-auto" />)}
                  </Col>
                </Row>
                {graph.isOpen && graph.dataIn && (
                <Row key={graph.id}>
                  <Col>
                    <TrafficGraph
                      name={graph.name}
                      loading={graph.loading}
                      dateRangeHandler={dateHandler.bind(null, graph.id)}
                      startDate={graph.startDate}
                      endDate={graph.endDate}
                      dataIn={graph.dataIn}
                      dataOut={graph.dataOut}
                      unit={graph.unit}
                    />
                  </Col>
                </Row>
                )}
              </div>
            ))}
          </Col>
        </Row>
      </Section>
    </div>
    )}
    {item.graphs.length < 5 && (
    <Row className="mt-4">
      <Col className="d-flex flex-column">
        {item.graphs.map((graph) => (
          <div className="mt-3" key={graph.name}>
            <Section title={graph.name}>
              {
                      graph.dataIn && (
                        <Row key={graph.id}>
                          <Col>
                            <TrafficGraph
                              name={graph.name}
                              loading={graph.loading}
                              dateRangeHandler={dateHandler.bind(null, graph.id)}
                              startDate={graph.startDate}
                              endDate={graph.endDate}
                              dataIn={graph.dataIn}
                              dataOut={graph.dataOut}
                              unit={graph.unit}
                            />
                          </Col>
                        </Row>
                      )
                    }
            </Section>
          </div>
        ))}
      </Col>
    </Row>
    )}
  </>
);
export default MonitoredDevice;
