import React, { useEffect } from 'react';
import {
  Row,
  Col,
} from 'reactstrap';
import connect from 'react-redux/es/connect/connect';
import { checkPermission } from '../../utils/Auth/AuthService';
import CalloutWidget from '../../components/Widgets/Callout';
import {
  getDashboardBills,
  getDashboardFavourites,
  getOpenCases,
  removeFavouriteFromDashboard,
  toggleAccountSummaryLoading,
  toggleBillsLoading,
  toggleCasesLoading,
} from '../../store/dashboard/actions';
import { IDashboardState } from '../../store/dashboard/types';
import { ACCOUNT_TYPE_RESALE_PARTNER, IAccountState } from '../../store/account/types';
import ContactCard from '../../components/Widgets/ContactCard';
import BillingTable from '../../components/Widgets/BillingTable';
import OpenCasesTable from '../../components/Widgets/CasesTable';
import { IMonitoringState } from '../../store/monitoring/types';
import MonitoringFavourites from '../../components/MonitoringFavourites';
import MonitoredItemEmpty from '../../components/Widgets/MonitoredItemEmpty';
import Section from '../../components/Section';
import { getAccountSummary } from '../../store/account/actions';
import { IUserState } from '../../store/user/types';
import { unFavouriteItem } from '../../store/user/actions';
import { resetSystemConfirmation, setSystemConfirmation } from '../../store/system/actions';

import { ISystemState } from '../../store/system/types';
import { AppDispatch } from '../../configureStore';

type DashboardProps = {
  dispatch: AppDispatch;
  account: IAccountState;
  dashboard: IDashboardState;
  monitoring: IMonitoringState;
  user: IUserState;
  system: ISystemState;
};
const Dashboard: React.FC<DashboardProps> = (props) => {

  const {
    account, dashboard: {
      loadingBills, loadingCases, favourites, loadingSummary, bills, cases,
    }, user: { favourites: userFavourites }, dispatch, system,
  } = props;
  const isResalePartnerAccount = account.accountTypeCode === ACCOUNT_TYPE_RESALE_PARTNER;
  const accountId = isResalePartnerAccount ? system.activePartnerCustomer.accountId : account.accountId;
  useEffect(() => {

    if (
      (
        checkPermission('billing.read', props.user.permissions)
        || checkPermission('billing.write', props.user.permissions)
      )
          && bills.length === 0
    ) {

      dispatch(toggleBillsLoading());
      dispatch(getDashboardBills()).then(() => dispatch(toggleBillsLoading()));

    }
    if (cases.length === 0) {

      dispatch(toggleCasesLoading());
      dispatch(getOpenCases()).then(() => dispatch(toggleCasesLoading()));

    }
    if (!account.accountNumber) {

      dispatch(toggleAccountSummaryLoading());
      dispatch(getAccountSummary()).then(() => {

        dispatch(toggleAccountSummaryLoading());

      });

    }

    if (checkPermission('monitoring.read', props.user.permissions)) {

      dispatch(getDashboardFavourites(userFavourites, accountId));

    }

  }, []);
  const removeWidget = (id: string, name: string, location: string) => {

    dispatch(setSystemConfirmation(
      {
        text: 'It will remove this item from your dashboard.',
        isOpen: true,
        color: 'danger',
        proceed: () => {

          dispatch(removeFavouriteFromDashboard(id));
          dispatch(unFavouriteItem(id, name, location));
          dispatch(resetSystemConfirmation());

        },
        cancel: () => {

          dispatch(resetSystemConfirmation());

        },
      },
    ));

  };
  return (
    <div className="animated fadeIn">
      <Row>
        <Col className="d-flex">
          <CalloutWidget
            loading={loadingSummary}
            color="warning"
            value={account.activeServices}
            title="Live Services"
            link={checkPermission('services.read', props.user.permissions) ? '/services' : '#'}
          />
        </Col>
        <Col className="d-flex">
          <CalloutWidget
            loading={loadingCases}
            color="info"
            value={account.openCases}
            title="Active Cases"
            link={checkPermission('cases.read', props.user.permissions) ? '/cases' : '#'}
          />
        </Col>
        <Col className="d-flex">
          <CalloutWidget
            loading={loadingSummary}
            color="dark"
            value={account.activeOrders}
            title="Open Orders"
            link={checkPermission('orders.read', props.user.permissions) ? '/orders' : '#'}
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col md={7}>
          {checkPermission('monitoring.read', props.user.permissions)
                        && (
                        <Row className="d-flex align-items-stretch">
                          <MonitoringFavourites favourites={favourites} deleteFavourite={removeWidget} />
                          <Col lg={6} md={6} sm={6} className="mb-4 d-flex col-xl-3 col-xxl-3">
                            <MonitoredItemEmpty />
                          </Col>
                        </Row>
                        )}
          {(checkPermission('billing.read', props.user.permissions) || checkPermission('billing.write', props.user.permissions))
                        && (
                        <Row className="mt-3">
                          <Col>
                            <BillingTable bills={bills} loading={loadingBills} />
                          </Col>
                        </Row>
                        )}
        </Col>
        <Col md={5}>
          <Row className="mb-3">
            <Col>
              <Section title="Account manager">
                <ContactCard
                  loading={loadingSummary}
                  dropShadow
                  firstName={account.accountManager && account.accountManager.firstName}
                  lastName={account.accountManager && account.accountManager.lastName}
                  email={account.accountManager && account.accountManager.email}
                  phone={account.accountManager && account.accountManager.telephone}
                  jobTitle="Account Manager"
                />
              </Section>
            </Col>
          </Row>
          <Row className="mt-3 mb-5">
            <Col>
              <OpenCasesTable loading={loadingCases} cases={cases} />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );

};
function mapStateToProps({
  account, dashboard, monitoring, user, system,
}) {

  return {
    account,
    dashboard,
    monitoring,
    user,
    system,
  };

}
export default connect(mapStateToProps)(Dashboard);
