import { apiRequest } from '../Api/ApiRequest';

// eslint-disable-next-line @typescript-eslint/naming-convention
export function api_getDocuments() {

  return apiRequest({
    url: '/documents',
    method: 'get',
    headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
  }).then(
    (resp) => resp,
  ).catch(
    (error) => error,
  );

}

// eslint-disable-next-line @typescript-eslint/naming-convention
export function api_downloadDocument(id) {

  return apiRequest({
    url: `/documents/${id}/download`,
    method: 'get',
    headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
    responseType: 'blob',
  }).then(
    (resp) => resp,
  ).catch(
    (error) => error,
  );

}
