/* eslint-disable */
import React from 'react';
import { PropTypes } from 'prop-types';
import { Card, CardSubtitle, CardHeader, CardBody, Button, FormGroup, Label, CardText, Row, Col } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import LaddaButton, { ZOOM_OUT } from 'react-ladda';
import { createNewCase } from '../../store/case/actions';
import Section from '../../components/Section';

class NewVPN extends React.Component {

  static contextTypes = {
    router: PropTypes.shape({
      navigate: PropTypes.func.isRequired,
      staticContext: PropTypes.object
    }).isRequired
  };

  constructor(props) {

    super(props);
    this.state = {
      _faultType: null,
      formData: {},
      processing: false,
      error: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.handleInputWithTag = this.handleInputWithTag.bind(this);

  }

  handleInput(event, value) {

    event.persist();
    this.setState((prevState) => {

      return {
        formData: Object.assign(prevState.formData, {
          [event.target.id]: {
            question: event.target.parentNode.previousSibling.textContent,
            value
          }
        })
      };

    });

  }

  handleInputWithTag(tag, event, value) {

    event.persist();
    this.setState((prevState) => {

      return {
        formData: Object.assign(prevState.formData, {
          [event.target.id]: {
            question: `${tag  } - ${  event.target.parentNode.previousSibling.textContent}`,
            value
          }
        })
      };

    });

  }

  handleSubmit() {

    if (this.props.partnerCustomer === '' && this.props.isResalePartner) {

      this.props.setCustomerSelectError();
      return false;

    }
    this.setState({ processing: true });
    const title = this.state.formData.title.value;
    let theHTML = '<h2>New VPN</h2><br>';
    theHTML += `<h6>Title</h6><p>${  title  }</p>`;
    delete this.state.formData.title;

    for (const prop in this.state.formData) {

      if (!this.state.formData.hasOwnProperty(prop)) continue;
      theHTML += `<h6>${  this.state.formData[prop].question  }</h6><p>${  this.state.formData[prop].value  }</p>`;

    }

    this.props.dispatch(createNewCase({
      title,
      priority: this.state.formData.priority.value,
      category: 'service_affecting',
      formData: theHTML,
      partnerCustomerId: this.props.partnerCustomer,
    }))
      .then((result) => {

        if (result.caseId) {

          this.props.navigate('/cases');

        }
        this.setState({ error: true });
        window.scrollTo(0, 0);

      });

  }

  render() {

    return (
            <Section title="New VPN">
                <Row className="mx-0">
                    <Col className="p-4 bg-white rounded">
                        <AvForm onValidSubmit={this.handleSubmit} onInvalidSubmit={this.props.onInvalidSubmit}>
                            <FormGroup>
                                <Label for="title">Title</Label>
                                <AvField type="text" name="title" id="title" placeholder="Title of the case" required onChange={this.handleInput} validate={{maxLength: {value: 200}}}/>
                            </FormGroup>
                            <FormGroup>
                                <Label for="priority">Priority</Label>
                                <AvField type="select" name="priority" id="priority" required onChange={this.handleInput}>
                                    <option value="">-- Select</option>
                                    <option value="low">Low</option>
                                    <option value="medium">Medium</option>
                                    <option value="high">High</option>
                                </AvField>
                            </FormGroup>
                            <FormGroup>
                                <Label for="caseSite">Site</Label>
                                <AvField type="text" name="caseSite" id="caseSite" placeholder="The site relevant to this query" required onChange={this.handleInput} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="remoteDeviceIp">Remote VPN Device IP</Label>
                                <AvField type="text" name="remoteDeviceIp" id="remoteDeviceIp" required onChange={this.handleInput} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="preSharedKey">Pre-shared Key</Label>
                                <AvField type="text" name="preSharedKey" id="preSharedKey" placeholder="We recommend a long pre-shared key that comprises random numbers and letters" required onChange={this.handleInput} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="phase1Prop">Phase 1 Proposal</Label>
                                <AvField type="textarea" name="phase1Prop" id="phase1Prop" placeholder="We will configure pre-g2-3des-sha if not specified" onChange={this.handleInput} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="phase2Prop">Phase 2 Proposal</Label>
                                <AvField type="textarea" name="phase2Prop" id="phase2Prop" placeholder="We will configure pre-g2-3des-sha if not specified" onChange={this.handleInput} />
                            </FormGroup>
                            <CardSubtitle style={{ marginTop: '10px', marginBottom: '10px' }}>Local range to have access and be accessed via the VPN</CardSubtitle>
                            <FormGroup>
                                <Label for="localRangeIp">IP address</Label>
                                <AvField type="text" name="localRangeIp" id="localRangeIp" required onChange={this.handleInputWithTag.bind(null, 'Local range')} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="localRangeSubnet">Subnet Mask</Label>
                                <AvField type="text" name="localRangeSubnet" id="localRangeSubnet" required onChange={this.handleInputWithTag.bind(null, 'Local range')} />
                            </FormGroup>
                            <CardText style={{ marginTop: '10px', marginBottom: '10px' }}>In case of Local range is on another VPN ( our device will act like a HUB ) specify the IP address of the gateway</CardText>
                            <FormGroup>
                                <Label for="localGateway">Local Gateway</Label>
                                <AvField type="text" name="localGateway" id="localGateway" required onChange={this.handleInputWithTag.bind(null, 'Local range')} />
                            </FormGroup>
                            <CardSubtitle style={{ marginTop: '10px', marginBottom: '10px' }}>Remote range to have access and be accessed via the VPN</CardSubtitle>
                            <FormGroup>
                                <Label for="remoteRangeIp">IP address</Label>
                                <AvField type="text" name="remoteRangeIp" id="remoteRangeIp" required onChange={this.handleInputWithTag.bind(null, 'Remote range')} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="remoteRangeSubnet">Subnet Mask</Label>
                                <AvField type="text" name="remoteRangeSubnet" id="remoteRangeSubnet" required onChange={this.handleInputWithTag.bind(null, 'Remote range')} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="direction">VPN Direction</Label>
                                <AvField type="select" name="direction" id="direction" required onChange={this.handleInput}>
                                    <option value="">-- Select</option>
                                    <option value="local to remote">Local to Remote</option>
                                    <option value="remote to local">Remote to Local</option>
                                    <option value="bidirectional">Bidirectional</option>
                                </AvField>
                            </FormGroup>
                            <CardSubtitle style={{ marginTop: '10px', marginBottom: '10px' }}>Specify if the Remote VPN is configured as POLICY-BASED or ROUTED-BASED</CardSubtitle>
                            <CardText style={{ marginTop: '10px', marginBottom: '10px' }}>(In case of VPN SPOKE-HUB-SPOKE specify both Remote VPNs configuration’s type)</CardText>
                            <FormGroup>
                                <Label for="remoteConfig">Remote Config</Label>
                                <AvField type="text" name="remoteConfig" id="remoteConfig" required onChange={this.handleInput} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="otherInf">Other information</Label>
                                <AvField type="textarea" name="otherInf" id="otherInf" onChange={this.handleInput} />
                            </FormGroup>
                            <div className="clearfix" style={{ marginTop: `${10  }px`, marginBottom: `${20  }px` }}>
                                {/* <Button type="submit" color="primary" className="float-right" style={{minWidth: 113+'px', minHeight: 35+'px'}} disabled={this.state.processing}>{this.state.processing ? <div style={{marginTop: 2+'px'}}>
                                <Loader color="#ffffff" size="8px" margin="0px"/></div> : "Create Case"}
                                </Button> */}
                                <LaddaButton
                                    style={{ minWidth: `${113  }px`, minHeight: `${35  }px` }}
                                    className="btn btn-primary btn-ladda px-4 float-right"
                                    disabled={this.state.processing}
                                    loading={this.state.processing}
                                    data-color="blue"
                                    data-style={ZOOM_OUT}
                                >
                                    Create Case
                            </LaddaButton>
                            </div>
                        </AvForm>
                    </Col>
                </Row>
            </Section>

    );

  }

}


export default NewVPN;
