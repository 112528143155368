/* eslint-disable */
import axios     from 'axios';
import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import {FatalError, TooManyRequests} from '../../components/Errors/Errors';
import {refreshTokens} from '../../store/user/actions';
import {store} from '../../index';

const jwtDecode = require('jwt-decode');

const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

let activePartnerCustomer = '';

export function setActivePartnerCustomer(accountNumber){

  activePartnerCustomer = accountNumber;

}


client.interceptors.request.use(
  function(config) {

    if(localStorage.getItem('hso-api-token')){

      const token = jwtDecode(localStorage.getItem('hso-api-token'));
      if(token.exp < Math.ceil(new Date().getTime()/1000)) {

        return config;

      }
      if(config.url !== `${config.baseURL}/auth/refresh` && config.url !== `${config.baseURL}/logout`) {

        if((token.exp - Math.ceil(new Date().getTime()/1000)) <= 120 ) {

          store.dispatch(refreshTokens());

        }

      }

    }

    if(activePartnerCustomer){

      config.headers['hso-request-partner-customer'] = activePartnerCustomer;

    }
    return config;

  }
);

const DEBUG = process.env.REACT_APP_DEBUG;
export const getAuthedHeader = () => {

  return {
    Authorization: `bearer ${localStorage.getItem('hso-api-token')}`,
    'x-custom-linked-api' : 'REACT_APP_API_URL'
  };

};

function apiRequest(options) {

  const onSuccess = function(response) {

    console.debug('Request Successful!', response);
    return response;

  };

  const onError = function(error) {

    if(DEBUG){

      console.error('Request Failed:', error.config);

    }

    if (error.response) {

      if(DEBUG){

        console.error('Status:',  error.response.status);
        console.error('Data:',    error.response.data);
        console.error('Headers:', error.response.headers);

      }
      if (error.response.status === 401) {

        localStorage.removeItem('hso-api-token');
        const history = createBrowserHistory({forceRefresh: true});
        history.push('/login?exp=1');

      } else if (error.response.status === 429) {

        ReactDOM.render(<TooManyRequests/>, document.getElementById('root'));

      } else {

        return Promise.reject(error.response || error.message);

      }

    } else {

      if(DEBUG){

        console.error('Error Message:', error.message);

      }
      return Promise.reject(error.message);

    }
    return Promise.reject(error.response || error.message);

  };

  return client(options)
    .then(onSuccess)
    .catch(onError);

}

export {apiRequest};

export function getApiData() {

  return apiRequest({
    url: '/',
    method: 'get',
  }).then(resp => resp.data).catch(error => error);

}
