/* eslint-disable */
import React, { Component } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';

class Aside extends Component {
  constructor(props) {

    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: '1'
    };
    
  }

  toggle(tab) {

    if (this.state.activeTab !== tab) {

      this.setState({
        activeTab: tab
      });
        
    }
    
  }

  render() {

    return (
        <aside className="aside-menu">
          <Nav tabs>
            <NavItem>
              <NavLink className={classnames({ active: this.state.activeTab === '1' })} onClick={() => {

                this.toggle('1'); 

              }}>
                <i className="icon-settings" />
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={this.state.activeTab}>

            <TabPane tabId="1" className="p-1">

              <div className="text-uppercase mb-1 mt-4"><small><b>API Token</b></small></div>
              <small className="text-muted">{localStorage.getItem('hso-api-token')}</small>

              <div className="text-uppercase mb-1 mt-2"><small><b>API EndPoint</b></small></div>
              <small className="text-muted">{process.env.REACT_APP_API_URL}</small>


            </TabPane>
          </TabContent>
        </aside>
    );
  
  }
}

export default Aside;
