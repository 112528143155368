import React from 'react';

interface IProps {
  cssClass?: string;
  cssClassTop?: string;
  title?: string;
}

const Download: React.FC<IProps> = ({ cssClassTop, cssClass, title }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.28 14.16" className={cssClassTop || 'cls-1'}>
    <defs>
      {/* <style>.cls-1{fill:#fff;}</style> */}
    </defs>
    <title>{title || ''}</title>
    <g id="Layer_2" data-name="Layer 2" className={cssClassTop || 'cls-1'}>
      <g id="Design">
        <path
          className={cssClass || 'cls-1'}
          d="M14.84,6.9a.44.44,0,0,0-.44.44v4a2,2,0,0,1-2,2H2.84a2,2,0,0,1-2-2v-4a.44.44,0,0,0-.44-.44A.43.43,0,0,0,0,7.28v4a2.84,2.84,0,0,0,2.84,2.84h9.6a2.84,2.84,0,0,0,2.84-2.84v-4a.44.44,0,0,0-.44-.44Z"
        />
        <path
          className={cssClass || 'cls-1'}
          d="M7.33,10.68a.43.43,0,0,0,.62,0L10.73,7.9a.44.44,0,1,0-.62-.62l-2,2V.44a.44.44,0,0,0-.88,0V9.32l-2-2a.44.44,0,0,0-.62,0,.42.42,0,0,0,0,.62Z"
        />
      </g>
    </g>
  </svg>
);

export default Download;
