import { SET_VPS_RECORDS, UPDATE_VPS_RECORD } from '../actions/VPS';

export default function vps(state = [], action) {

  switch (action.type) {
    case SET_VPS_RECORDS:
      return action.vpsRecords;
    case UPDATE_VPS_RECORD:
      return { ...state, [action.vpsRecord.id]: action.vpsRecord };
    default:
      return state;
  }

}
