import React, { FunctionComponent } from 'react';
import {
  Col, FormGroup, Input, Label,
} from 'reactstrap';
import { CountryDropdown } from 'react-country-region-selector';

type Props = {
  onChange: (value: string) => any;
  value: string;
};

const CountrySelect: FunctionComponent<Props> = (props) => {

  const handleChange = (value: string) => {

    props.onChange(value);
  
  };

  return (
    <FormGroup row>
      <Label className="col-sm-4 text-light col-form-label font-weight-bold" for="Country" style={{ fontSize: '16px' }}>Country</Label>
      <div className="col-sm-8">
        <CountryDropdown
          valueType="short"
          priorityOptions={['GB']}
          value={props.value || 'GB'}
          classes="form-control"
          onChange={(val) => handleChange(val)}
        />
      </div>
    </FormGroup>
  );

};
export default CountrySelect;
