import { AUTHENTICATE_SOCKET, JOIN_ROOMS, PING } from '../store/socketio/types';

const activityLogger = (store) => (next) => (action) => {

  const currentTime = Math.ceil(new Date().getTime() / 1000);
  if (
    localStorage.getItem('hso-api-token')
    && action.type !== PING
    && action.type !== AUTHENTICATE_SOCKET && action.type !== 'authenticated'
    && action.type !== JOIN_ROOMS
    && /SOCKET/.test(action.type) === false
    && /TOAST/.test(action.type) === false
  ) {

    localStorage.setItem('last-activity', `${currentTime}`);

  }
  return next(action);

};

export default activityLogger;
