import { defaultErrorFeedback } from '../../actions/feedback';
import { ISetPriceListAction, PRICE_LIST_SET_PRICE_LIST } from './types';
import { api_getPriceList } from '../../utils/PriseList/PriceList';

export const setPriceList = (priceList): ISetPriceListAction => ({
  type: PRICE_LIST_SET_PRICE_LIST,
  data: priceList,
});

export function getAccountPriceList() {

  return (dispatch) => api_getPriceList().then((result) => {

    if (result.status === 200) {

      dispatch(setPriceList(result.data));
      return result.data;
    
    }
    if (result.status !== 404) {

      dispatch(defaultErrorFeedback());
    
    }

    return false;
  
  });

}
