const routes = {
  '/': 'Home',
  '/dashboard': 'Dashboard',
  '/account': 'Account',
  '/account/statement': 'Statement',
  '/cases': 'Cases',
  '/monitoring': 'Monitoring',
  '/orders': 'Orders',
  '/contacts': 'Contacts',
  '/contacts/new': 'New Contact',
  '/billing': 'Billing',
  '/products': 'Products',
  '/services': 'Services',
  '/domains': 'Domains',
  '/domains/lookup': 'Search',
  '/reset-password': 'Reset Password',
  '/tutorials': 'Tutorials',
  '/weather-maps': 'Weather Maps',
  '/reports': 'Reports',
};
export default routes;
