import {
  IReportsState,
  REPORTS_REMOVE_REPORT_FROM_LIST,
  REPORTS_SET_LIST,
  REPORTS_UPDATE_REPORT_IN_LIST,
  ReportsActionTypes,
} from './types';

const initialState: IReportsState = {
  list: [],
};
export function reportsReducer(state = initialState, action: ReportsActionTypes) {

  switch (action.type) {
    case REPORTS_SET_LIST:
      return {
        ...state,
        list: [...action.list],
      };
    case REPORTS_REMOVE_REPORT_FROM_LIST:
      return {
        ...state,
        list: state.list.filter((item) => item.id !== action.id),
      };
    case REPORTS_UPDATE_REPORT_IN_LIST:
      return {
        ...state,
        list: state.list.map((item) => {

          if (item.id === action.report.id) {

            return action.report;
          
          }
          return item;
        
        }),
      };
    default:
      return {
        ...state,
      };
  }

}
