import React from 'react';
import {
  Button, Modal, ModalBody, ModalFooter, ModalHeader, FormGroup, Label, Input, Form,
} from 'reactstrap';
import Section from '../../Section';
import SimpleLoader from '../../SimpleLoader';

type TOwnProps = {
  isOpen: boolean;
  escalationcaseModalLoading: boolean;
  cancel: () => any;
  proceed: () => any;
  changeHandler: ((event: React.ChangeEvent<HTMLInputElement>) => void) | undefined;
  escalationNote: string;
  error: string;
};
const EscalateCaseModal: React.FC<TOwnProps> = (props) => {

  const {
    isOpen, proceed, cancel, changeHandler, escalationNote, error, escalationcaseModalLoading,
  } = props;
  const handleCancel = () => {

    cancel();
  
  };
  const handleProceed = () => {

    proceed();
  
  };
  return (

    <Modal isOpen={isOpen} toggle={handleCancel} className="modal-warning" backdrop="static">
      <SimpleLoader loading={escalationcaseModalLoading}>
        <ModalBody>
          <Section title="Escalate case">
            <Form>
              <FormGroup>
                <Label for="escalateCase">Please describe the case you are escalating</Label>
                <Input type="textarea" name="text" id="EscalateCase" value={escalationNote} onChange={changeHandler} />
                <p className="text-danger">{error}</p>
              </FormGroup>
            </Form>
          </Section>
        </ModalBody>
        <ModalFooter className="border-top-0">
          <Button color="warning" onClick={handleCancel}>Cancel</Button>
          <Button color="primary" className="text-white" onClick={handleProceed}>Escalate case</Button>
          {' '}
        </ModalFooter>
      </SimpleLoader>
    </Modal>
  );

};
export default EscalateCaseModal;
