export const SIGNATURE_SET_DATA = 'SIGNATURE_SET_DATA';
export const SIGNATURE_TOGGLE_LOADING = 'SIGNATURE_TOGGLE_LOADING';

export interface IToggleSignatureLoadingAction {
  type: typeof SIGNATURE_TOGGLE_LOADING;
}

export interface ISignatureState {
  loading: boolean;
  data: TEmbeddedSignature;
}

export type TEmbeddedSignature = {
  signUrl: string;
  expiresAt?: number;
};

export interface ISignatureSetDataAction {
  type: typeof SIGNATURE_SET_DATA;
  data: object;
}

export type SignatureActionTypes = ISignatureSetDataAction | IToggleSignatureLoadingAction;
