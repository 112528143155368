import React from 'react';

interface IProps {
  cssClass?: string;
  cssClassTop?: string;
  title?: string;
}
const ContactsIcon: React.FC<IProps> = ({ cssClass, cssClassTop, title }) => (
  <svg
    className={cssClassTop || 'cls-1'}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20.42 24"
  >
    <defs>{/* <style>.cls-1{fill:#858c91;}</style> */}</defs>
    <title>{title || ''}</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Design" className={cssClass || ''}>
        <path d="M10.12,12.93h.16a4.57,4.57,0,0,0,3.51-1.51c1.91-2.16,1.59-5.86,1.56-6.21A5.09,5.09,0,0,0,12.94.7,5.56,5.56,0,0,0,10.26,0h-.08A5.58,5.58,0,0,0,7.5.68,5.09,5.09,0,0,0,5.06,5.21c0,.35-.35,4.05,1.56,6.21a4.56,4.56,0,0,0,3.5,1.51ZM6.39,5.33v0c.17-3.56,2.7-3.94,3.78-3.94h.06c1.34,0,3.63.57,3.78,3.94v0s.36,3.42-1.22,5.2a3.28,3.28,0,0,1-2.56,1.06h-.05a3.26,3.26,0,0,1-2.56-1.06C6.05,8.76,6.38,5.36,6.39,5.33Z" />
        <path d="M20.42,19.07v0s0-.08,0-.12c0-1-.09-3.29-2.25-4l-.05,0A14.22,14.22,0,0,1,14,13a.66.66,0,0,0-.93.17.67.67,0,0,0,.16.93,15.69,15.69,0,0,0,4.54,2.08c1.16.41,1.29,1.65,1.33,2.78v.12a9.57,9.57,0,0,1-.1,1.54,18.31,18.31,0,0,1-8.77,2,18.35,18.35,0,0,1-8.77-2.05,8.37,8.37,0,0,1-.1-1.53V19c0-1.13.17-2.37,1.33-2.78A15.81,15.81,0,0,0,7.2,14.11a.68.68,0,0,0,.17-.94A.68.68,0,0,0,6.43,13a14.22,14.22,0,0,1-4.12,1.88H2.26C.1,15.64,0,18,0,18.92a.53.53,0,0,1,0,.13H0a8,8,0,0,0,.25,2.26.7.7,0,0,0,.26.31A18.87,18.87,0,0,0,10.22,24a18.93,18.93,0,0,0,9.7-2.37.77.77,0,0,0,.26-.31,8.19,8.19,0,0,0,.24-2.25Z" />
      </g>
    </g>
  </svg>
);

export default ContactsIcon;
