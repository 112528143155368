import update from 'immutability-helper';
import {
  SET_SOCKET_AUTHENTICATED,
  SOCKET_CONNECT,
  SOCKET_DISCONNECT,
  ISocketIOState,
  SocketIOActionTypes,
} from './types';

const initialState: ISocketIOState = {
  status: 'unknown',
};

export const socketioReducer = (state: ISocketIOState = initialState, action: SocketIOActionTypes) => {

  switch (action.type) {
    case SET_SOCKET_AUTHENTICATED:
      return update(state, {
        status: { $set: 'authenticated' },
      });
    case SOCKET_DISCONNECT:
      return update(state, {
        status: { $set: 'disconnected' },
      });
    case SOCKET_CONNECT:
      return update(state, {
        status: { $set: 'unknown' },
      });
    default:
      return state;
  }

};
