import {
  DOMAIN_LOOKUP_RESET_RESULTS,
  DOMAIN_LOOKUP_SET_DOMAIN_YEARS,
  DOMAIN_LOOKUP_SET_RESULTS,
  DOMAIN_LOOKUP_SET_SEARCH_STRING,
  DOMAIN_LOOKUP_TOGGLE_LOADING,
} from './actions';
import { IDomainsState } from '../domains/types';
import { IDomainLookupState } from './types';

const initialState: IDomainLookupState = {
  searchString: '',
  results: {},
  loading: false,
};

export default function reducers(state: IDomainLookupState = initialState, action) {

  switch (action.type) {
    case DOMAIN_LOOKUP_SET_RESULTS:
      return {
        ...state,
        results: action.domains,
      };
    case DOMAIN_LOOKUP_RESET_RESULTS:
      return {
        ...state,
        results: {},
      };
    case DOMAIN_LOOKUP_TOGGLE_LOADING:
      return {
        ...state,
        loading: !state.loading,
      };
    case DOMAIN_LOOKUP_SET_SEARCH_STRING:
      return {
        ...state,
        searchString: action.searchString,
      };
    case DOMAIN_LOOKUP_SET_DOMAIN_YEARS:
      return {
        ...state,
        results: { ...state.results, [action.domain]: { ...state.results[action.domain], years: action.years } },
      };
    default:
      return state;
  }

}
