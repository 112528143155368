import {
  IAddServiceOrderItemAction, IRemoveServiceOrderItemAction,
  IResetCurrentServiceOrderItemAction,
  IResetServiceOrder,
  ISetCurrentServiceOrderItemAction, ISetCurrentServiceOrderItemFieldAction,
  ISetServiceOrderFieldAction,
  SERVICE_ORDERS_ADD_ITEM, SERVICE_ORDERS_REMOVE_ITEM,
  SERVICE_ORDERS_RESET, SERVICE_ORDERS_RESET_CURRENT,
  SERVICE_ORDERS_SET_CURRENT, SERVICE_ORDERS_SET_CURRENT_FIELD,
  SERVICE_ORDERS_SET_FIELD,
  TPreSalesServiceOrderItem,
  TServiceOrderFailedItem,
} from './types';
import { api_getPricing, TPricingData } from '../../utils/Pricing/Pricing';
import { defaultErrorFeedback } from '../../actions/feedback';
import { getPurchaseToken } from '../../utils/Orders/Orders';
import { placeOrder } from '../../utils/Services/ServiceOrders';

export const serviceOrderActions = {

  setServiceOrderField: (field: string, value: any): ISetServiceOrderFieldAction => ({
    type: SERVICE_ORDERS_SET_FIELD,
    field,
    value,
  }),

  addServiceOrderItem: (data: TPreSalesServiceOrderItem): IAddServiceOrderItemAction => ({
    type: SERVICE_ORDERS_ADD_ITEM,
    data,
  }),

  removeServiceOrderItem: (index: number): IRemoveServiceOrderItemAction => ({
    type: SERVICE_ORDERS_REMOVE_ITEM,
    index,
  }),

  setServiceOrderItemField: (index: number, field: string, value: any): ISetCurrentServiceOrderItemFieldAction => ({
    type: SERVICE_ORDERS_SET_CURRENT_FIELD,
    field,
    value,
  }),

  resetServiceOrder: (): IResetServiceOrder => ({
    type: SERVICE_ORDERS_RESET,
  }),

  updateServiceOrderStatus: (status: string) => serviceOrderActions.setServiceOrderField('status', status),

  updateServiceOrderFailedItems: (failedItems: Array<TServiceOrderFailedItem>) => serviceOrderActions.setServiceOrderField('failedItems', failedItems),

  getPricing: (priceListItemId: number, data: object) => (dispatch): Promise<boolean | TPricingData> => api_getPricing(priceListItemId, data)
    .then((result) => {

      if (result.status === 200) {

        return result.data;
      
      }

      dispatch(defaultErrorFeedback());
      return false;
    
    }),

  getOrderPurchaseToken: (amount: number) => (dispatch): Promise<boolean> => getPurchaseToken(amount)
    .then((result) => {

      if (result.status === 200) {

        if (result.data.success) {

          dispatch(serviceOrderActions.setServiceOrderField('purchaseToken', result.data.token));
          return result.data;
        
        }

        dispatch(serviceOrderActions.setServiceOrderField('status', 'purchase token failed'));
        return false;
      
      }

      dispatch(serviceOrderActions.setServiceOrderField('status', 'failed'));
      dispatch(defaultErrorFeedback());
      return false;
    
    }),

  submitServiceOrder: (type, data: object, purchaseToken: string) => (dispatch): Promise<boolean> => placeOrder(type, data, purchaseToken)
    .then((result) => {

      if (result.status === 200) {

        if (result.data.failedItems.length || !result.data.items.length) {

          dispatch(serviceOrderActions.updateServiceOrderStatus('completed with errors'));
        
        } else {

          dispatch(serviceOrderActions.setServiceOrderField('referenceNumber', result.data.referenceNumber));
          dispatch(serviceOrderActions.updateServiceOrderStatus('completed'));
        
        }
        dispatch(serviceOrderActions.updateServiceOrderFailedItems(result.data.failedItems));

        return true;
      
      }

      dispatch(serviceOrderActions.updateServiceOrderStatus('failed'));

      return false;
    
    }),

  setCurrentItem: (data: TPreSalesServiceOrderItem): ISetCurrentServiceOrderItemAction => ({
    type: SERVICE_ORDERS_SET_CURRENT,
    data,
  }),

  resetCurrentItem: (): IResetCurrentServiceOrderItemAction => ({
    type: SERVICE_ORDERS_RESET_CURRENT,
  }),

  setCurrentItemField(field: string, value: string) {

    return {
      type: SERVICE_ORDERS_SET_CURRENT_FIELD,
      field,
      value,
    };
  
  },
};
