import React, { FunctionComponent, Fragment } from 'react';

type IOwnProps = {
  title: string;
};

const Header: FunctionComponent<IOwnProps> = ({ title }) => (
  <div className="mb-4">
    <div style={{ fontWeight: 600 }} className="h4 mb-1 text-dark">{title}</div>
    <div style={{ height: '5px', width: '35px' }} className="bg-warning" />
  </div>
);

export default Header;
