import React, { FunctionComponent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import Section from '../../components/Section';
import { TPriceList, TPriceListItem } from '../../store/priceList/types';
import AddSelectInputGroup from '../../components/Forms/AddSelectInputGroup';
import { serviceOrderActions } from '../../store/serviceOrder/actions';
import { getAccountServices } from '../../store/services/actions';
import { SERVICE_ORDERS_SINGLE_ONLY_ITEMS, TPreSalesServiceOrderState } from '../../store/serviceOrder/types';
import SimpleLoader from '../../components/SimpleLoader';
import NewServiceOrderItemModal from '../../components/Modals/ServiceOrders/NewServiceOrderItemModal';
import { IFormError } from '../../components/Forms/types';
import { TContact } from '../../store/contact/types';
import { IUserState } from '../../store/user/types';
import NewOrderContactFields from './NewOrderContactFields';
import NewOrderTable from './NewOrderTable';
import { IAccountState } from '../../store/account/types';
import NewOrderStatusMessage from './NewOrderStatusMessage';
import { AppDispatch } from '../../configureStore';

type TOwnProps = {
  dispatch: AppDispatch;
  account: IAccountState;
  user: IUserState;
  priceList: TPriceList;
  serviceOrder: TPreSalesServiceOrderState;
  services: Array<any>;
  contacts: Array<TContact>;
};
const NewOrder: FunctionComponent<TOwnProps> = ({
  dispatch,
  account,
  user,
  services,
  contacts,
  priceList,
  serviceOrder,
}) => {

  const [loading, setLoading] = useState<boolean>(false);
  const [priceListItem, setPriceListItem] = useState<TPriceListItem | null>(null);
  const [errors, setErrors] = useState<IFormError[]>([]);

  const calcTotalField = (field: string): number => serviceOrder.items.reduce((previousValue, currentValue) => previousValue + (currentValue[field] || 0), 0);

  useEffect(() => {

    if (services.length === 0) {

      dispatch(getAccountServices());
    
    }

    return () => dispatch(serviceOrderActions.resetServiceOrder());
  
  }, []);

  useEffect(() => {

    if (contacts.length && (!serviceOrder.billingContactId || !serviceOrder.signatoryContactId)) {

      const currentUsersContact = contacts.find((contact: TContact) => contact.id === user.contact.id);

      dispatch(serviceOrderActions.setServiceOrderField('billingContactId', currentUsersContact?.id));
      dispatch(serviceOrderActions.setServiceOrderField('signatoryContactId', currentUsersContact?.id));
    
    }
  
  }, [contacts]);

  const handleAddServiceInput = async (value) => setPriceListItem(priceList.items.find((item) => Number(item.id) === Number(value)) || null);

  const disabledSubmit = serviceOrder.items.length === 0 || !serviceOrder.billingContactId || !serviceOrder.signatoryContactId;

  const submit = async () => {

    if (errors.length === 0) {

      setLoading(true);
      const purchaseToken = await dispatch(serviceOrderActions.getOrderPurchaseToken(calcTotalField('install') + calcTotalField('rental')));
      if (purchaseToken) {

        await dispatch(serviceOrderActions.submitServiceOrder(serviceOrder.type, {
          items: serviceOrder.items,
          billingContactId: serviceOrder.billingContactId,
          signatoryContactId: serviceOrder.signatoryContactId,
        }, purchaseToken.token));
      
      }
      setLoading(false);
    
    }
  
  };

  return (
    <div className="animated fadeIn mb-3">
      <Section title="New Order">
        <SimpleLoader loading={!priceList || !services || loading} text="Loading...">
          {serviceOrder.status === 'in progress' ? (
            <>
              <div className="mb-4 d-flex justify-content-between">
                <div>
                  {priceList ? (
                    <AddSelectInputGroup
                      defaultEmpty="Add Product"
                      options={priceList.items
                        .filter((item) => !(SERVICE_ORDERS_SINGLE_ONLY_ITEMS.includes(item.productCode)
                          && !!serviceOrder.items.find((serviceOrderItem) => serviceOrderItem.id === item.id)))
                        .map((item) => ({
                          value: item.id,
                          label: item.name,
                        }))}
                      onSubmit={handleAddServiceInput}
                    />
                  ) : ''}
                </div>
                <div>
                  <Button disabled={disabledSubmit} onClick={submit}>
                    Submit Order
                    <i
                      className="fa fa-chevron-right"
                    />
                  </Button>
                </div>
              </div>
              <NewOrderTable
                data={serviceOrder.items}
                handleDelete={(index: number) => dispatch(serviceOrderActions.removeServiceOrderItem(index))}
              />
              <NewOrderContactFields
                contacts={contacts}
                onChange={(name, value) => dispatch(serviceOrderActions.setServiceOrderField(name, value))}
                data={serviceOrder}
                setErrors={setErrors}
                errors={errors}
              />
            </>
          ) : (
            <NewOrderStatusMessage
              accountManagerEmail={account.accountManager.email}
              status={serviceOrder.status}
              referenceNumber={serviceOrder.referenceNumber}
            />
          )}
        </SimpleLoader>
      </Section>
      {priceListItem ? (
        <NewServiceOrderItemModal
          priceListItem={priceListItem}
          service={services.find((service) => service.productCode?.startsWith(priceListItem.productCode))}
          close={() => setPriceListItem(null)}
        />
      ) : ''}
    </div>
  );

};

const mapStateToProps = ({
  account, user, contacts, serviceOrder, services, priceList,
}) => ({
  account,
  user,
  contacts: contacts.contacts,
  serviceOrder,
  services: services.services,
  priceList: priceList.data,
});

export default connect(mapStateToProps)(NewOrder);
