import React, { useEffect } from 'react';
import {
  Button, Col, Modal, ModalBody, ModalHeader, Row,
} from 'reactstrap';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import {
  LineChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis,
} from 'recharts';
import { format as d3Format } from 'd3-format';
import GraphCal from '../../components/SvgIcons/GraphCal';
import { THistoryItem, TMonitoredItemPopUpGraph } from '../../store/monitoredItem/types';
import Section from '../../components/Section';
import SimpleLoader from '../../components/SimpleLoader';

type TOwnProps = {
  graphHandler: () => any;
  trackerHandler: (tracker: string) => any;
  dateHandler: (startDate: number, endDate: number) => any;
  getData: (id: string, multiplier: number, startDate?: number, endDate?: number) => any;
  details: TMonitoredItemPopUpGraph;
  reset: () => any;
};

const PopUpGraph: React.FC<TOwnProps> = ({
  graphHandler, details, dateHandler, reset,
}) => {

  const {
    isOpen,
    loading,
    title,
    startDate,
    endDate,
    history,
    format,
    multiplier,
  } = details;

  useEffect(() => () => reset(), []);
  const toggle = () => {

    graphHandler();
  
  };
  const ranges = {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
  };

  const handleEvent = (event, picker) => {

    if (event.type === 'apply') {

      dateHandler(picker.startDate, picker.endDate);
    
    }
  
  };

  const startDateFormatted = startDate.format('DD/MM/YY');
  const endDateFormatted = endDate.format('DD/MM/YY');
  let label = `${startDateFormatted} - ${endDateFormatted}`;
  const numberOfDays = startDate && endDate && (endDate.diff(startDate, 'days'));
  if (startDateFormatted === endDateFormatted) {

    label = startDateFormatted;
  
  }

  type TItem = {
    timestamp: number;
    value?: number;
  };
  const data: TItem[] = history.map((item: THistoryItem) => {

    const historyItem: TItem = {
      timestamp: Number(item.clock),
    };
    if (item.value !== null) {

      historyItem.value = Number(item.value) * multiplier;
    
    }
    return historyItem;
  
  });

  const handleFormat = (value): string => {

    if (!format) {

      return `${d3Format('.2s')(value)}`;
    
    }
    if (value < 1000) {

      return `${Number(value).toFixed(1)}${format}`;
    
    }
    if (value < 1000000) {

      return `${Number(value / 1024).toFixed(1)}k${format}`;
    
    }
    if (value < 1000000000) {

      return `${Number(value / 1000000).toFixed(1)}M${format}`;
    
    }
    return `${Number(value / 1000000000).toFixed(1)}G${format}`;
  
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} backdrop="static" size="xlg" onClosed={() => reset()}>
      <SimpleLoader loading={loading}>
        <ModalHeader toggle={toggle} className="border-bottom-0">
          <Section title={title} />
        </ModalHeader>
        <ModalBody>
          <div className="bg-white rounded">
            <Row>
              <Col>
                {data.length > 0
                  && (
                  <ResponsiveContainer width="100%" height={250}>
                    <LineChart
                      data={data}
                    >
                      <XAxis
                        type="number"
                        domain={[startDate.unix(), endDate.unix()]}
                        dataKey="timestamp"
                        scale="linear"
                        minTickGap={30}
                        tickFormatter={(timestamp) => (timestamp ? moment(timestamp, 'X').format(numberOfDays && numberOfDays > 1 ? 'DD/MM hA' : 'HH:mm') : ' -- ')}
                      />
                      <YAxis
                        dataKey="value"
                        label=""
                        orientation="right"
                        tickFormatter={(value) => value && handleFormat(value)}
                        allowDecimals={false}
                      />
                      <Legend />
                      <Tooltip
                        labelFormatter={(label) => moment(label, 'X').format('DD/MM/YY HH:mm:ss')}
                        formatter={(value) => handleFormat(value)}
                      />
                      <Line
                        name={title}
                        dataKey="value"
                        type="linear"
                        dot={false}
                        stroke="#FF9E1B"
                      />
                    </LineChart>
                  </ResponsiveContainer>
                  )}
              </Col>
            </Row>
            <Row className="d-flex flex-row-reverse mt-auto mr-0 ml-0">
              <Col md={4} className="d-flex justify-content-end">
                <DateRangePicker
                  startDate={startDate}
                  endDate={endDate}
                  opens="left"
                  drops="down"
                  ranges={ranges}
                  onEvent={handleEvent}
                >
                  <Button className="btn-datepicker bg-white d-flex justify-content-between">
                    <span>{label}</span>
                    <div style={{ height: '20px', width: '20px' }} className="ml-2">
                      <GraphCal />
                    </div>
                  </Button>
                </DateRangePicker>
              </Col>
            </Row>
          </div>
        </ModalBody>
      </SimpleLoader>
    </Modal>
  );

};

export default PopUpGraph;
