import {
  ADD_LISTENERS_TO, AUTHENTICATE_SOCKET, JOIN_ROOMS, PING,
} from './types';

export function joinRooms(payload) {

  return {
    type: JOIN_ROOMS,
    payload,
  };

}

export function authenticateSocket(payload) {

  return {
    type: AUTHENTICATE_SOCKET,
    payload,
  };

}

export function addListeners(payload) {

  return {
    type: ADD_LISTENERS_TO,
    payload,
  };

}

export function ping() {

  return {
    type: PING,
  };

}
