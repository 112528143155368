import React, { FunctionComponent } from 'react';
import {
  Col, Row, FormGroup, Input,
} from 'reactstrap';
import { MonitoredDeviceWidget } from '../../../store/monitoring/types';
import TextInput from '../../Inputs/TextInput';
import SimpleLoader from '../../SimpleLoader';

type TOwnProps = {
  name: string;
  location: string;
};
const MonitoringHost: FunctionComponent<TOwnProps> = (props) => {

  const {
    name,
    location,
  } = props;
  return (
    <div
      className="shadow-sm pt-3 bg-white d-flex flex-column monitoring-widget w-100 h-100"
      style={{ borderRadius: '10px' }}
    >
      <Row className="mx-0  mb-3 px-2">
        <Col className="d-flex flex-column light-border--bottom pb-2">
          <h5 className="text-light text-uppercase font-xl text-left mb-0 ">
            host
          </h5>
          <div className="pt-0">
            <div
              className="font-weight-bold text-dark pt-0 text-left"
            >
              {name}
              <span
                className="text-light monitoring-widget__latency__ms"
              />
            </div>
          </div>
        </Col>
      </Row>
      <Row className="mx-0  mb-3 px-2">
        <Col className="d-flex flex-column ">
          <h5 className="text-light text-uppercase font-xl text-left mb-0 ">
            location
          </h5>
          <div className=" pt-0">
            <div
              className="font-weight-bold text-dark pt-0 text-left"
            >
              {location}
              <span
                className="text-light monitoring-widget__latency__ms"
              />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );

};
export default MonitoringHost;
