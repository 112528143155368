import {
  Button, Col, Modal, ModalBody, Row,
} from 'reactstrap';
import React, { useEffect, useState } from 'react';
import isEmpty from 'lodash.isempty';
import Section from '../../Section';
import { TContact, TContactSite } from '../../../store/contact/types';
import CheckBoxList, { ICheckBoxOption } from '../../Inputs/CheckBoxList';
import SelectInput from '../../Inputs/SelectInput';
import { TAccountSite } from '../../../store/account/types';

type TOwnProps = {
  isOpen: boolean;
  cancel: () => any;
  data: { id: string; name: string; types: number[]; new: boolean; syncToAll: boolean };
  contact: TContact;
  add: (contactType: number) => any;
  remove: (contactType: number) => any;
  siteChange: (id: string) => any;
  syncToAll: (checked: boolean) => any;
  commit: () => any;
  onClosed: () => any;
  accountSites: TAccountSite[];
  options: ICheckBoxOption[];

};
const SiteContact: React.FC<TOwnProps> = (props) => {

  const {
    isOpen,
    cancel,
    add,
    remove,
    commit,
    data,
    options,
    contact,
    syncToAll,
    accountSites,
    siteChange,
  } = props;

  const getSiteOptions = () => {

    const options = [{ value: '', label: 'Select a site...' }];
    const sites = accountSites.map((accountSite) => ({ value: accountSite.id, label: accountSite.name }));

    return options.concat(sites);
  
  };

  return (
    <Modal isOpen={isOpen} toggle={cancel} onClosed={() => props.onClosed()}>
      <ModalBody>
        <Section title={`${contact.fullName} ${(!data.new ? data.name : '')}`}>
          {data.new
            && <SelectInput label="Site" id="site" options={getSiteOptions()} value={data.id} onChange={(event) => siteChange(event.target.value)} />}

          <CheckBoxList
            horizontal={false}
            options={options}
            label="Contact Types"
            readOnly={false}
            onChange={(event) => (event.target.checked ? add(parseInt(event.target.id)) : remove(parseInt(event.target.id)))}
          />
          <Row className="d-flex">
            <Col className="d-flex justify-content-between">
              <label className="text-light homeage-to-the-square-checkbox">
                <input id="applyAll" checked={data.syncToAll} className="form-check-input" type="checkbox" onChange={(event) => syncToAll(event.target.checked)} />
                <span className="checkmark" />
                <label htmlFor="applyAll" className="form-check-label">Apply to all sites</label>
              </label>
              <Button size="small" color="warning" disabled={!data.id} onClick={() => commit()}>{data.new ? 'Add' : 'Update'}</Button>
            </Col>
          </Row>
        </Section>
      </ModalBody>
    </Modal>
  );

};
export default SiteContact;
