import { apiRequest } from '../Api/ApiRequest';

export function api_getAccountContacts() {

  return apiRequest({
    url: '/contacts?include=portalUser',
    method: 'get',
    headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
  }).then(
    resp => resp,
  ).catch(
    error => error,
  );

}

export function api_getContact(contactId) {

  return apiRequest({
    url: `/contacts/${contactId}?include=portalUser,sites`,
    method: 'get',
    headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
  }).then(
    resp => resp,
  ).catch(
    error => error,
  );

}

export function api_createContact(data) {

  return apiRequest({
    url: '/contacts',
    method: 'post',
    headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
    data,
  }).then(
    resp => resp,
  ).catch(
    error => error,
  );

}

export function api_updateContact(contact) {

  return apiRequest({
    url: `/contacts/${contact.id}`,
    method: 'put',
    headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
    data: { ...contact },
  }).then(
    resp => resp,
  ).catch(
    error => error,
  );

}

export function api_deleteContact(contactId) {

  return apiRequest({
    url: `/contacts/${contactId}`,
    method: 'delete',
    headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
  }).then(
    resp => resp,
  ).catch(
    error => error,
  );

}

export function api_restoreContact(contactId) {

  return apiRequest({
    url: `/contacts/${contactId}/restore`,
    method: 'put',
    headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
  }).then(
    resp => resp,
  ).catch(
    error => error,
  );

}

export function api_sendPortalInvite(contactId, roleId) {

  return apiRequest({
    url: `/contacts/${contactId}/invite`,
    method: 'post',
    headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
    data: { role: roleId },
  }).then(
    resp => resp,
  ).catch(
    error => error,
  );

}

export function api_updateContactSites(contactId, sites) {

  return apiRequest({
    url: `/contacts/${contactId}/sites`,
    method: 'put',
    headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
    data: { sites },
  }).then(
    resp => resp,
  ).catch(
    error => error,
  );

}
