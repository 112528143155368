import {
  TWeatherMapGetWeatherMap,
  TWeatherMapGetWeatherMapEdge,
  TWeatherMapGetWeatherMapTraffic,
  TWeatherMapResetWeatherMap,
  TWeatherMapSetWeatherMap,
  TWeatherMapSetWeatherMapEdge,
  TWeatherMapSetWeatherMapEdgeHistory,
  TWeatherMapSetWeatherMapTraffic,
  TWeatherMapSubscribeWeatherMap,
  TWeatherMapUnsubscribeWeatherMap,
  WEATHERMAP_RESET_WEATHERMAP,
  WEATHERMAP_SET_EDGE,
  WEATHERMAP_SET_EDGE_HISTORY,
  WEATHERMAP_SET_TRAFFIC,
  WEATHERMAP_SET_WEATHERMAP,
  WEATHERMAP_STOP,
  WEATHERMAP_SUBSCRIBE,
} from './types';
import { defaultErrorFeedback } from '../../actions/feedback';
import {
  api_getWeatherMap,
  api_getWeatherMapEdge,
  api_getWeatherMapTraffic,
} from '../../utils/WeatherMaps/WeatherMaps';

export const subscribeWeatherMap: TWeatherMapSubscribeWeatherMap = (mapId) => ({

  type: WEATHERMAP_SUBSCRIBE,
  payload: { mapId },

});

export const unsubscribeWeatherMap: TWeatherMapUnsubscribeWeatherMap = () => ({

  type: WEATHERMAP_STOP,

});

export const setWeatherMap: TWeatherMapSetWeatherMap = (data) => ({

  type: WEATHERMAP_SET_WEATHERMAP,
  data,

});

export const resetWeatherMap: TWeatherMapResetWeatherMap = () => ({

  type: WEATHERMAP_RESET_WEATHERMAP,

});

export const getWeatherMap: TWeatherMapGetWeatherMap = (id) => (dispatch) => api_getWeatherMap(id)
  .then((result) => {

    if (result.status === 200) {

      dispatch(setWeatherMap(result.data));
      return result.data;
    
    }
    dispatch(defaultErrorFeedback());
    return null;
  
  });

export const getWeatherMapTraffic: TWeatherMapGetWeatherMapTraffic = (id) => (dispatch) => api_getWeatherMapTraffic(id)
  .then((result) => {

    if (result.status === 200) {

      dispatch(setWeatherMapTraffic(result.data.traffic, result.data.timestamp));
      return true;
    
    }
    dispatch(defaultErrorFeedback());
    return false;
  
  });

export const setWeatherMapTraffic: TWeatherMapSetWeatherMapTraffic = (traffic, timestamp) => ({

  type: WEATHERMAP_SET_TRAFFIC,
  traffic,
  timestamp,

});

export const getWeatherMapEdge: TWeatherMapGetWeatherMapEdge = (id, edgeId) => (dispatch) => api_getWeatherMapEdge(id, edgeId)
  .then((result) => {

    if (result.status === 200) {

      dispatch(setWeatherMapEdge(result.data));
      return true;
    
    }
    dispatch(defaultErrorFeedback());
    return false;
  
  });

export const setWeatherMapEdge: TWeatherMapSetWeatherMapEdge = (edge) => ({

  type: WEATHERMAP_SET_EDGE,
  edge,

});

export const setWeatherMapEdgeHistory: TWeatherMapSetWeatherMapEdgeHistory = (history) => ({

  type: WEATHERMAP_SET_EDGE_HISTORY,
  history,

});
