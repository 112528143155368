import React, { FunctionComponent, PropsWithChildren } from 'react';
import Header from './Header';

type Props = {
  title: string;

};
const Section: FunctionComponent<Props & PropsWithChildren> = ({ title, children }) => (
  <div>
    <Header title={title} />
    {children}
  </div>
);

export default Section;
