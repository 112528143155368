import { apiRequest } from '../Api/ApiRequest';

export function api_getAccountServiceGroups() {

  return apiRequest({
    url: '/services',
    method: 'get',
    headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
  }).then(
    resp => resp,
  ).catch(
    error => error,
  );

}
export function api_getAccountServices() {

  return apiRequest({
    url: '/services',
    method: 'get',
    headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
  }).then(
    resp => resp,
  ).catch(
    error => error,
  );

}
export function api_getServiceGroups() {

  return apiRequest({
    url: '/services/groups',
    method: 'get',
    headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
  }).then(
    resp => resp,
  ).catch(
    error => error,
  );

}
export function api_getService(id) {

  return apiRequest({
    url: `/services/${id}`,
    method: 'get',
    headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
  }).then(
    resp => resp,
  ).catch(
    error => error,
  );

}

export function api_getConnectivityService(id, withBilling) {

  return apiRequest({
    url: `/services/connectivity/${id}?${withBilling ? 'include=billing' : ''}`,
    method: 'get',
    headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
  }).then(
    resp => resp,
  ).catch(
    error => error,
  );

}
export function api_getConnectivityServiceGraph(id, graphId, from, to) {

  return apiRequest({
    url: `/services/connectivity/${id}/graph/${graphId}/${from}/${to}`,
    method: 'get',
    headers: { Authorization: `bearer ${localStorage.getItem('hso-api-token')}` },
  }).then(
    resp => resp,
  ).catch(
    error => error,
  );

}
