export const TOAST = 'TOAST';

export interface ISocketIOToastState {
  messages: Array<SocketIOToastAction>;
}

export type SocketIOToastAction = {
  type: typeof TOAST;
  timestamp: Date;
  link: any | string;
  msg: string;
  msgtype: string;
  title: string;
  disablePush: boolean;
};

export type SocketIOToastActionTypes = SocketIOToastAction;
