import { Moment } from 'moment';

export const SERVICE_BROADBAND_SET_DATA = 'SERVICE_BROADBAND_SET_DATA';
export const SERVICE_BROADBAND_TOGGLE_LOADING = 'SERVICE_BROADBAND_TOGGLE_LOADING';
export const SERVICE_BROADBAND_SET_USAGE = 'SERVICE_BROADBAND_SET_USAGE';
export const SERVICE_BROADBAND_TOGGLE_USAGE_LOADING = 'SERVICE_BROADBAND_TOGGLE_USAGE_LOADING';
export const SERVICE_BROADBAND_SET_USAGE_PIE_CHART = 'SERVICE_BROADBAND_SET_USAGE_PIE_CHART';
export const SERVICE_BROADBAND_SET_USAGE_BAR_CHART = 'SERVICE_BROADBAND_SET_USAGE_BAR_CHART';
export const SERVICE_BROADBAND_SET_USAGE_DATES = 'SERVICE_BROADBAND_SET_USAGE_DATES';
export const SERVICE_BROADBAND_RESET_DATA = 'SERVICE_BROADBAND_RESET_DATA';

export interface IServiceBroadbandState {
  loading: boolean;
  data: TServiceBroadband;
  usage: TBroadbandUsage;
}

export type TServiceBroadband = {
  id: string;
  name: string;
  status: string;
  productCode: string;
  transferLimit: number;
  pstn: string;
  username: string;
  bearer: string;
  bandwidth: string;
  ips: TBroadbandIps;
};

export type TBroadbandUsage = {
  loading: boolean;
  currentDate: Moment;
  nextMonth: Moment;
  prevMonth: Moment;
  data: TBroadbandUsageData[];
  barChart: TBroadbandUsageBarChart;
  pieChart: TBroadbandUsagePieChart;
};

type TBroadbandUsageData = {
  day: string;
  download: number;
  upload: number;
};

type TBroadbandIps = {
  ipv4?: string;
  ipv6?: string;
  ipv4Route?: string;
};

export type TBroadbandUsageBarChart = {
  labels: number[];
  download: number[];
  upload: number[];
};

export type TBroadbandUsagePieChart = {
  upload: number;
  download: number;
};

export interface ISetBroadbandDataAction {
  type: typeof SERVICE_BROADBAND_SET_DATA;
  data: TServiceBroadband;
}

export interface IResetBroadbandDataAction {
  type: typeof SERVICE_BROADBAND_RESET_DATA;
}

export interface IToggleBroadbandLoadingAction {
  type: typeof SERVICE_BROADBAND_TOGGLE_LOADING;
}

export interface ISetBroadbandUsageDataAction {
  type: typeof SERVICE_BROADBAND_SET_USAGE;
  data: TBroadbandUsageData;
}

export interface IToggleBroadbandUsageLoadingAction {
  type: typeof SERVICE_BROADBAND_TOGGLE_USAGE_LOADING;
}

export interface ISetBroadbandUsagePieChartAction {
  type: typeof SERVICE_BROADBAND_SET_USAGE_PIE_CHART;
  data: TBroadbandUsagePieChart;
}

export interface ISetBroadbandUsageBarChartAction {
  type: typeof SERVICE_BROADBAND_SET_USAGE_BAR_CHART;
  data: TBroadbandUsageBarChart;
}

export interface ISetBroadbandUsageDatesAction {
  type: typeof SERVICE_BROADBAND_SET_USAGE_DATES;
  currentDate: Moment;
  prevMonth: Moment;
  nextMonth: Moment;
}

export type BroadbandReducerActions = ISetBroadbandDataAction | IToggleBroadbandLoadingAction | ISetBroadbandUsageDataAction
| IToggleBroadbandUsageLoadingAction | ISetBroadbandUsagePieChartAction | ISetBroadbandUsageBarChartAction | ISetBroadbandUsageDatesAction
| IResetBroadbandDataAction;
