import {
  Button, Modal, ModalBody, ModalFooter, ModalHeader,
} from 'reactstrap';
import React from 'react';
import Section from '../../Section';
import { IConfirmation } from '../../../store/system/types';

type TOwnProps = {
  confirmation: IConfirmation;
};
const ConfirmationModal: React.FC<TOwnProps> = (props) => {

  const {
    isOpen, color, cancel, proceed, text,
  } = props.confirmation;
  const handleCancel = () => {

    cancel();
  
  };
  const handleProceed = () => {

    proceed();
  
  };
  return (
    <Modal isOpen={isOpen} toggle={handleCancel} className={`modal-${color}`} backdrop="static">
      <ModalBody>
        <Section title="Are you sure?">
          <p>{text}</p>
        </Section>
      </ModalBody>
      <ModalFooter className="border-top-0">
        <Button color="primary" className="text-white" onClick={handleProceed}>Yes</Button>
        {' '}
        <Button color="warning" onClick={handleCancel}>Cancel</Button>
      </ModalFooter>
    </Modal>
  );

};
export default ConfirmationModal;
