import {
  DOMAINS_SET_ACCOUNT_DOMAINS, DOMAINS_SET_LOOKUP_STRING,
  DOMAINS_SET_SEARCH_STRING,
  DOMAINS_TOGGLE_LOADING,
  DomainsActionTypes,
  IDomainsState,
  DOMAINS_RESET_STORE,
} from './types';

const initialState: IDomainsState = {
  loading: false,
  searchString: '',
  lookupString: '',
  domains: [],
  filtered: [],
};

export function domainsReducer(state = initialState, action: DomainsActionTypes) {

  switch (action.type) {
    case DOMAINS_SET_ACCOUNT_DOMAINS:
      return {
        ...state,
        domains: action.domains,
      };
    case DOMAINS_SET_SEARCH_STRING:
      const filtered = state.domains.filter((domain) => String(domain.name).toLowerCase().indexOf(action.searchString.toLowerCase()) !== -1);
      return {
        ...state,
        searchString: action.searchString,
        filtered,
      };
    case DOMAINS_SET_LOOKUP_STRING:
      return {
        ...state,
        lookupString: action.lookupString,
      };

    case DOMAINS_TOGGLE_LOADING:
      return {
        ...state,
        loading: !state.loading,
      };
    case DOMAINS_RESET_STORE:
      return {
        ...initialState,
      };
    default:
      return {
        ...state,
      };
  }

}
