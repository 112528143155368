import {
  IServiceState, SERVICE_SET_SERVICE, SERVICE_TOGGLE_LOADING, ServiceActionTypes,
} from './types';

const initialState: IServiceState = {
  loading: false,
  data: {
    name: '',
    id: '',
    productCode: '',
    goLiveDate: '',
    orderNumber: '',
    group: 0,
    url: null,
  },
};
export function serviceReducer(state = initialState, action: ServiceActionTypes) {

  switch (action.type) {
    case SERVICE_TOGGLE_LOADING:
      return {
        ...state,
        loading: !state.loading,
      };
    case SERVICE_SET_SERVICE:
      return {
        ...state,
        data: action.service,
      };
    default:
      return {
        ...state,
      };
  }

}
