import moment from 'moment';
import {
  DOCUMENTS_SET_DOCUMENTS, DOCUMENTS_SET_SEARCH_STRING, DocumentsActionTypes, IDocumentsState,
} from './types';

const initialState: IDocumentsState = {
  documents: [],
  filtered: [],
  searchString: '',
};

export function documentsReducer(state = initialState, action: DocumentsActionTypes) {

  switch (action.type) {
    case DOCUMENTS_SET_DOCUMENTS:
      return {
        ...state,
        documents: action.data,
      };
    case DOCUMENTS_SET_SEARCH_STRING:
      return {
        ...state,
        searchString: action.searchString,
        filtered: state.documents.filter((document) => String(document.name).toLowerCase().indexOf(action.searchString.toLowerCase()) !== -1
          || String(document.type).toLowerCase().indexOf(action.searchString.toLowerCase()) !== -1
          || moment(document.updatedAt).format('DD/MM/YYYY H:m:s').indexOf(action.searchString.toLowerCase()) !== -1),
      };
    default:
      return {
        ...state,
      };
  }

}
