import React, { useState, useEffect, FunctionComponent } from 'react';
import {
  Card,
  CardBody,
  Row,
  Col,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { SpinnerInfo } from '../../components/Spinner/Spinner';
import { getWeatherMaps } from '../../store/weatherMaps/actions';
import { TWeatherMapsList } from '../../store/weatherMaps/types';
import { AppDispatch } from '../../configureStore';

type TOwnProps = {
  weatherMaps: TWeatherMapsList;
  dispatch: AppDispatch;
};
type TProps = TOwnProps;

const WeatherMaps: FunctionComponent<TProps> = ({ weatherMaps, dispatch }) => {

  const [loading, setLoading] = useState<boolean>(true);
  const [loadingError, setLoadingError] = useState<boolean>(false);

  const getData = () => {

    setLoading(true);
    dispatch(getWeatherMaps()).then((success) => {

      setLoading(false);
      setLoadingError(!success);

    });

  };

  useEffect(() => {

    if (!weatherMaps.length) {

      getData();

    } else {

      setLoading(false);

    }

  }, []);

  const refresh = (e) => {

    e.preventDefault();
    getData();

  };

  return (
    <div>
      {loading ? (
        <div className="row justify-content-center">
          <SpinnerInfo />
        </div>
      ) : ''}
      {!loading && loadingError ? (
        <div className="row justify-content-center">
          <p>
            There was an error retrieving the weather maps.
            <a onClick={refresh}>Click here</a>
            {' '}
            to try again
          </p>
        </div>
      ) : ''}
      {!loading && weatherMaps.length > 0 ? (
        <Row>
          {weatherMaps.map((weatherMap) => (
            <Col sm={6} lg={4} xl={3} key={weatherMap.id}>
              <Card>
                <CardBody>
                  <Row>
                    <Col className="text-center">
                      <h6 className="text-uppercase font-weight-bold truncate" style={{ letterSpacing: '0px' }}>
                        {weatherMap.name}
                      </h6>
                    </Col>
                  </Row>
                  <Row>
                    <Col>{weatherMap.description}</Col>
                  </Row>
                  <Row>
                    <Col className="text-right">
                      <Link className="btn btn-info btn-sm" to={`/weather-maps/${weatherMap.id}`}>View</Link>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
      ) : ''}
    </div>
  );

};

function mapStateToProps({ weatherMaps }) {

  return {
    weatherMaps: weatherMaps.weatherMaps,
  };

}

export default connect(mapStateToProps)(WeatherMaps);
