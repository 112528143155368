import { SYSTEM_RESET_PARTNER_CUSTOMER, SYSTEM_SET_PARTNER_CUSTOMER } from '../store/system/types';
import { setActivePartnerCustomer } from '../utils/Api/ApiRequest';
import { USER_LOGOUT } from '../store/user/types';

const partnerCustomer = (store) => (next) => (action) => {

  if (action.type === SYSTEM_SET_PARTNER_CUSTOMER) {

    setActivePartnerCustomer(action.customer.accountNumber);

  } else if (action.type === SYSTEM_RESET_PARTNER_CUSTOMER || action.type === USER_LOGOUT) {

    setActivePartnerCustomer('');

  }
  return next(action);

};

export default partnerCustomer;
